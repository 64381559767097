import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useDebounce } from "tomato";
import { Flex } from "../Flex";
import { Text } from "../Text";
import { UI } from "../../constants";

const TextEdit = styled(Text)`
  display: flex;          
  outline: 0;
  background-color: transparent;

  ${(props) => {
    if (props.enabled && props.editing) {
      return "border: 1px solid #0055E6;";
    }

    if (props.value || !props.enabled) {
      return "border: 1px solid transparent;";
    } else {
      return "border: 1px solid lightGrey;";
    }
  }}  

  &:hover {
    ${(props) => {
      if (props.enabled && !props.editing) {
        return `
           border: 1px solid lightGrey;
        `;
      }
    }}

`;

export const ClickToEdit = ({
  value = "",
  autoFocus = false,
  onBlur,
  onChange,
  maxLength = 191,
  enabled = false,
  enableEmpty = false,
  enableCounter = true,
  placeholder,
  ...props
}) => {
  const editable = useRef();

  const [editing, setEditing] = useState(false);
  const [remaining, setRemaining] = useState("");

  // const debounceValue = useDebounce(content, 5000);

  // useEffect(() => {
  //   if (!(autoSave && enabled)) return;
  //   if (JSON.stringify(value) === JSON.stringify(content)) return;
  //   setSaving(true);
  //   autoSave(debounceValue);
  // }, [debounceValue]);

  useEffect(() => {
    if (value) {
      editable.current.innerText = value;
    } else editable.current.innerText = placeholder;
    setRemaining(maxLength - value.length);
  }, [value]);

  useEffect(() => {
    enabled && autoFocus && editable.current.focus();
  }, [autoFocus]);

  useEffect(() => {
    if (editing) {
      editable.current.focus();
    } else editable.current.blur();
  }, [editing]);

  const startEdit = (e) => {
    e.stopPropagation();
    if (enabled) {
      setEditing(true);
    }
  };

  const cancelEdit = () => {
    setEditing(false);
    editable.current.innerText = value;
  };

  const saveEdit = () => {
    setEditing(false);

    if (!enableEmpty && !editable.current.innerText)
      editable.current.innerText = value;

    if (editable.current.innerText != value && typeof onBlur === "function")
      onBlur(editable.current.innerText);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();

    const { key } = e;

    switch (key) {
      case "Enter":
        saveEdit();
        break;
      case "Escape":
        cancelEdit();
        return;
    }

    let isSpecialKey = [
      "Backspace",
      "Shift",
      "Control",
      "Meta",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight"
    ].includes(key);

    let len = e.target.innerText.trim().length;

    if (!isSpecialKey && len < maxLength) setRemaining(remaining - 1);
    if (key === "Backspace" && remaining <= maxLength)
      setRemaining(remaining + 1);
    if (len >= maxLength) {
      if (isSpecialKey || ["Backspace"].includes(key)) return;

      e.preventDefault();
    }
  };

  const handleKeyUp = (e) => {
    e.stopPropagation();
    let len = e.target.innerText.trim().length;
    setRemaining(maxLength - len);
    onChange && onChange(editable.current.innerText);
  };

  let style = {};
  if (remaining === maxLength) style.color = "t4";

  return (
    <Flex alignItems="center">
      <TextEdit
        ref={editable}
        enabled={enabled}
        value={value}
        editing={editing}
        contentEditable={editing}
        borderRadius={2}
        minWidth={16}
        onClick={(e) => startEdit(e)}
        onBlur={saveEdit}
        {...props}
        {...style}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        fontFamily={UI.DEFAULT_FONT_FAMILY}
      />

      {editing && enableCounter && (
        <Text fontSize={12} ml={1} mr={2} minWidth={36}>
          {remaining}
        </Text>
      )}
    </Flex>
  );
};
