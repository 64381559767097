import { ajax } from "rxjs/ajax";
import {
  catchError,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  tap,
  withLatestFrom
} from "rxjs/operators";

import { AMPLITUDE_API_KEY, MODE, SEND_EVENTS } from "config";

let defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "text/plain" // "application/json"
};

let amplitudeUrl = "https://api.amplitude.com/2/httpapi";
let amplitude = {
  api_key: AMPLITUDE_API_KEY,
  events: []
};

let PIPZ_KEY =
  MODE === "PROD" ? "660ac09f9a47fffe168801" : "a7a837f95b86fffe18d";
let PIPZ_URL =
  MODE === "PROD"
    ? "https://tasks.events.pipz.io/v1/event/"
    : "http://localhost:7070/v1/event";

export const redirect = (action$) => {
  return action$.ofType("REDIRECT").pipe(
    tap((action) => {
      action.payload.route.history.push(action.payload.url);
    }),
    ignoreElements()
  );
};

export const sendEvent = (action$, state$) => {
  return action$.ofType("events::SEND").pipe(
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      if (!AMPLITUDE_API_KEY || MODE !== "PROD") return [[action, state]];

      if (!SEND_EVENTS) {
        return [[action, state]];
      }

      let event = {
        user_id: state.account.user.email,
        user_name: state.account.user.name,
        event_type: action.payload.name,
        event_properties: action.payload.attributes || {}
      };
      let amplitudeEvent = { ...amplitude, events: [event] };

      return ajax({
        url: amplitudeUrl,
        crossDomain: true,
        withCredentials: false,
        method: "POST",
        headers: defaultHeaders,
        body: JSON.stringify(amplitudeEvent)
      }).pipe(
        pluck("response"),
        map((response) => {
          return [action, state];
        }),
        catchError((error) => {
          console.log("error", error);
          return [[action, state]];
        })
      );
    }),

    mergeMap(([action, state]) => {
      let pipzEvent = {
        type: "identify",
        writeKey: PIPZ_KEY,
        userId: state.account.user.email,
        traits: {
          name: state.account.user.name,
          email: state.account.user.email,
          event: {}
        }
      };
      let eventAttributes = action.payload.attributes || {};
      pipzEvent.traits.event[action.payload.name] = { ...eventAttributes };

      if (!SEND_EVENTS) {
        return [[]];
      }

      return ajax({
        url: PIPZ_URL,
        crossDomain: true,
        withCredentials: false,
        method: "POST",
        headers: defaultHeaders,
        body: JSON.stringify(pipzEvent)
      }).pipe(
        pluck("response"),
        map((response) => {
          return [];
        }),
        catchError((error) => {
          console.log("error");
          return [];
        })
      );
    }),

    ignoreElements()
  );
};
