import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import { LAYOUTS } from "layouts";
import { ROUTES } from "./routes";
import { VIEWS } from "views";

const SYSTEM_ROUTES = Object.values(ROUTES).map((module) => module);

const AppRoute = ({ adminOnly, moduleId, view, layout, ...props }) => {
  // let permissions = useSelector((state) => state["account"].permissions);
  let user = useSelector((state) => state["account"].user);

  let Layout = LAYOUTS[layout];
  let View = VIEWS[view];

  // if (!permissions[modelId] && typeof permissions[modelId] !== "undefined") {
  //   return <Forbidden />;
  // }

  if (adminOnly && !user.is_admin) {
    View = VIEWS["Forbidden"];
  }

  return (
    <Route
      strict
      render={() => <Layout moduleId={moduleId} View={View} {...props} />}
    />
  );
};

export const Routes = () => {
  return (
    <Switch>
      {SYSTEM_ROUTES.map((route, index) => (
        <AppRoute key={index} {...route} />
      ))}
      <Route component={VIEWS["Page404"]} />
    </Switch>
  );
};
