import React, { useEffect, useState } from "react";
import Transition from "react-transition-group/Transition";
import styled from "styled-components";

import { Flex, FlexHover, Icon } from "..";
import { Portal } from "../Portal";

import { disableShortcuts, enableShortcuts } from "../../hooks/useShortcut";

const OverlayWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  cursor: default;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ANIMATION_DURATION = 100;

const defaultStyle = {
  transition: `opacity ${ANIMATION_DURATION}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const defaultInnerStyle = {
  zIndex: 75,
  transform: "scale(0.8)"
};

const transitionInnerStyles = {
  entering: {
    transform: "scale(0.8)",
    transition: `all ${ANIMATION_DURATION}ms ease-out`
  },
  entered: {
    transform: "scale(1)",
    transition: `all ${ANIMATION_DURATION}ms ease-out`
  },
  exiting: {
    transform: "scale(0.8)",
    transition: `all ${ANIMATION_DURATION}ms ease-in`
  },
  exited: {
    transform: "scale(0.8)",
    transition: `all ${ANIMATION_DURATION}ms ease-in`
  }
};

export const Overlay = ({
  blocked = false,
  children,
  onExit,
  open,
  setOpen,
  fill = "black",
  opacity = "70%",
  ...props
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    //   window.addEventListener("keydown", handleClick);
    //   return () => {
    //     window.removeEventListener("keydown", handleClick);
    return () => {
      onExit && onExit();
    };
  }, []);

  const handleExit = () => {};
  const handleExiting = () => {};
  const handleExited = () => {
    if (!blocking) enableShortcuts();
    onExit && onExit();
    setMounted(false);
  };
  const handleEnter = () => {};
  const handleEntering = () => {
    disableShortcuts();
    setMounted(true);
  };
  const handleEntered = () => {};

  const blocking = typeof setOpen !== "function" || blocked;

  const handleClick = () => {
    if (!blocking && open) setOpen(false);
  };

  let style = blocking
    ? { style: { background: fill, opacity } }
    : {
        style: {
          cursor: "pointer",
          background: fill,
          opacity
        }
      };

  return (
    <Portal id="Overlay">
      <Transition
        appear
        unmountOnExit
        timeout={ANIMATION_DURATION}
        in={open}
        onExit={handleExit}
        onExiting={handleExiting}
        onExited={handleExited}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onEntered={handleEntered}
      >
        {(state) => (
          <OverlayWrapper
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            state={state}
            onClick={(e) => e.stopPropagation()}
          >
            <Flex
              position="relative"
              width={1}
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Background
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClick(e);
                }}
                {...style}
              />

              {mounted && (
                <FlexHover
                  {...props}
                  style={{
                    ...defaultInnerStyle,
                    ...transitionInnerStyles[state]
                  }}
                >
                  {children}

                  {!blocking && (
                    <Flex
                      borderRadius="2rem"
                      position="absolute"
                      right="-2rem"
                      top="0.5rem"
                      zIndex="10"
                      bg="l0"
                      p="0.25rem"
                      onClick={() => setOpen(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon.Close />
                    </Flex>
                  )}
                </FlexHover>
              )}
            </Flex>
          </OverlayWrapper>
        )}
      </Transition>
    </Portal>
  );
};
