import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Dropdown, Flex, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

const Who = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const task = useSelector((state) => state["tasks"].obj);
  const users = useSelector((state) => state["spaces"].users);

  const setSelectedUserId = (user) => {
    dispatch(
      ACTIONS.tasks.updateAttribute(task.id, "owner_id", user ? user.id : null)
    );
    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { owner_id: user ? user.id : null }
      )
    );
  };

  let members =
    spaces[projects[lists[task.list_id].project_id].space_id].members;

  let membersOptions = members
    .filter(
      (member) => users[member.id].is_active && !users[member.id].is_deleted
    )
    .map((member) => {
      return {
        id: member.id,
        value: users[member.id].name,
        avatar: users[member.id].avatar
      };
    });

  if (task.done_date) return null;

  return (
    <Flex flexDirection="column" mt="2rem">
      <Flex style={{ cursor: "pointer" }} alignItems="center">
        <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
          {t("Assignee")}
        </Text>

        <Dropdown
          width={1}
          options={membersOptions}
          onSelect={setSelectedUserId}
          placeholder={t("Set assignee")}
          enableClear={true}
          selected={
            task.owner_id
              ? { id: task.owner_id, value: users[task.owner_id].name }
              : null
          }
        />
      </Flex>
    </Flex>
  );
};

export default Who;
