import COLORS from "../constants/colors";
import { UI } from "../constants";

// Base fontSize: 16px

export const fontSizes = {
  title: "1.75rem", // 32px
  subtitle: "1.25rem", // 18px
  subsubtitle: "1rem", // 16px
  default: "1rem", // 16px
  primary: "1rem", // 16px
  regular: "0.875rem", // 14px
  secondary: "0.875rem", // 14px
  tertiary: "0.750rem", // 12px
  small: "0.625rem", //10px
  xsmall: "0.5rem", // 8px

  h1: "2rem", // 32px
  h2: "1.75rem", // 28px
  h3: "1.5rem", // 24px
  h4: "1.25rem", // 20px
  h5: "1.125rem", // 18px
  h6: "0.875rem", // 14px
  h7: "0.75rem", // 12px
  h8: "0.625rem", // 10px
  h9: "0.5rem", //8px

  caption: "0.5rem",
  tableHead: 14,
  label: "0.825rem",
  headline: 26,
  section: "1rem",
  subsection: "0.625rem",
  tag: "0.875rem"
};

export const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
  extrabold: 900
};

export const space = [0, 4, 8, 12, 16, 20, 24, 28, 32];

// const heights = {
//   button: 30,
//   input: 30,
//   appHeader: 46
// };

export const THEMES = (variant, primaryColor = 240, defaultSaturation = 10) => {
  // console.log(variant, primaryColor, defaultSaturation);
  // console.log(COLORS(variant, primaryColor, defaultSaturation));

  return {
    colors: COLORS(variant, primaryColor, defaultSaturation),

    fontSizes: fontSizes,

    fontWeights: fontWeights,

    space: space,

    breakpoints: {
      xs: "0em",
      sm: "40em", // 640px
      md: "48em", // 768px
      lg: "64em", // 1024px
      xl: "80em" // 1.280px
    },

    colorStyles: {
      warning: {
        color: "black",
        backgroundColor: "orange"
      },
      error: {
        color: "white",
        backgroundColor: "red"
      }
    },

    textInput: {
      default: {
        // height: UI.INPUT_HEIGHT,
        // minHeight: UI.INPUT_HEIGHT,
        // lineHeight: UI.INPUT_HEIGHT,
        // border: `1px solid ${
        //   COLORS(variant, primaryColor, defaultSaturation).t5
        // }`,
        // color: COLORS(variant, primaryColor, defaultSaturation).t1,
        // ":focus": {
        //   border: `1px solid ${
        //     COLORS(variant, primaryColor, defaultSaturation).focus
        //   }`,
        //   boxShadow: `0px 0px 0px 1px ${
        //     COLORS(variant, primaryColor, defaultSaturation).focus
        //   }`,
        //   transition: "all 250ms"
        // },
        // ":hover": {
        //   backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        // },
        // lineHeight: 1.5,
        // minHeight: "24px",
        // outline: 0,
        // overflow: "hidden"
        // resize: "none",
        // padding: "4px 8px",
        // backgroundColor: "white",
        // borderRadius: "4px",
        // border: `1px solid ${
        //   COLORS(variant, primaryColor, defaultSaturation).l3
        // }`,
        // ":focus": { border: "1px solid #0055E6" },
        // "::placeholder": {
        //   color: COLORS(variant, primaryColor, defaultSaturation).t5
        // },
        // ":hover": {
        //   backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        // },
        // wordWrap: "break-word",
        // whiteSpace: "normal",
        // display: "inline-block",
        // transition: "all .1s ease"
      },
      clean: {
        lineHeight: 1.5,
        outline: 0,
        overflow: "hidden",
        resize: "none",
        padding: "4px 8px",
        backgroundColor: "white",
        borderRadius: "4px",
        fontWeight: fontWeights.regular,
        border: `1px solid transparent`,
        // ":focus": { border: "1px solid #0055E6" },
        "::placeholder": {
          color: COLORS(variant, primaryColor, defaultSaturation).t5
        },
        ":hover": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        },
        wordWrap: "break-word",
        whiteSpace: "normal",
        display: "inline-block",
        transition: "all .1s ease"
      },
      note: {
        lineHeight: 1.5,
        outline: 0,
        overflow: "hidden",
        resize: "none",
        padding: "0",
        borderRadius: "4px",
        color: COLORS(variant, primaryColor, defaultSaturation).t3,
        cursor: "pointer",
        backgroundColor: "transparent",
        border: `1px solid transparent`,
        ":focus": { border: "1px solid #0055E6", cursor: "text" },
        "::placeholder": {
          color: COLORS(variant, primaryColor, defaultSaturation).t5
        },
        wordWrap: "break-word",
        whiteSpace: "normal",
        display: "inline-block",
        transition: "all .1s ease"
      }
    },

    inputs: {
      default: {
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l0,
        border: `1px solid ${
          COLORS(variant, primaryColor, defaultSaturation).t5
        }`,
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        cursor: "pointer",
        ":focus": {
          border: `1px solid ${
            COLORS(variant, primaryColor, defaultSaturation).focus
          }`,
          boxShadow: "0px 0px 0px 1px hsla(230, 100%, 47%)",
          transition: "all 250ms"
        },
        "::placeholder": {
          color: COLORS(variant, primaryColor, defaultSaturation).t4
        },
        ":hover": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        },
        wordWrap: "break-word",
        outline: 0,
        whiteSpace: "normal",
        display: "inline-block",
        boxShadow: "none",
        transition: "all .3s ease"
      },

      clickToEdit: {
        backgroundColor: "transparent",
        padding: 0,
        border: "1px solid transparent",
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        cursor: "pointer",
        ":focus": {
          border: `2px solid ${
            COLORS(variant, primaryColor, defaultSaturation).focus
          }`,
          transition: "all 250ms"
        },
        ":hover": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        },
        wordWrap: "break-word",
        whiteSpace: "normal",
        display: "inline-block",
        boxShadow: "none",
        transition: "all .1s ease"
      },

      enabled: {
        border: `1px solid ${
          COLORS(variant, primaryColor, defaultSaturation).t5
        }`,
        "::placeholder": {
          color: COLORS(variant, primaryColor, defaultSaturation).t4
        },
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        ":focus": {
          border: `1px solid ${
            COLORS(variant, primaryColor, defaultSaturation).focus
          }`,
          boxShadow: `0px 0px 0px 1px ${
            COLORS(variant, primaryColor, defaultSaturation).focus
          }`,
          transition: "all 250ms"
        },
        ":hover": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        }
      },

      disabled: {
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1,
        border: "1px solid transparent",
        cursor: "not-allowed",
        color: COLORS(variant, primaryColor, defaultSaturation).t1
      },

      tags: {
        backgroundColor: "transparent",
        height: "fit-content",
        minHeight: "fit-content",
        border: 0,
        fontSize: fontSizes.tag,
        outline: 0,
        "::placeholder": {
          color: COLORS(variant, primaryColor, defaultSaturation).t4
        }
      }
    },

    sideOptions: {
      active: {
        fontWeight: fontWeights.bold,
        fontSize: fontSizes.h6,
        height: "30px",
        paddingLeft: fontSizes.h6,
        paddingRight: fontSizes.h6,
        backgroundColor: "#f2f2f2",
        boxShadow: "0px 3px 6px -4px rgba(199,199,199,1)",
        borderBottom: "1px solid lightGrey"
      },
      normal: {
        fontWeight: fontWeights.bold,
        fontSize: fontSizes.h6,
        height: "30px",
        paddingLeft: fontSizes.h6,
        paddingRight: fontSizes.h6,
        color: "lightGrey",
        "&:hover": {
          color: COLORS(variant, primaryColor, defaultSaturation).regular
        }
      }
    },

    tabs: {
      normal: {
        color: "lightGrey",
        margin: "1px 0 -1px",
        "&:hover": {
          color: COLORS(variant, primaryColor, defaultSaturation).hover
        }
      },
      active: {
        paddingTop: "1px",
        borderBottom:
          "3px solid " + COLORS(variant, primaryColor, defaultSaturation).blue
      }
    },

    buttons: {
      normal: {
        padding: "0.5rem 1rem",
        borderRadius: "0.5rem",
        fontWeight: fontWeights.semibold,
        color: "white",
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).blue,
        "&:hover:enabled": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l4
        },
        "&:disabled": {
          opacity: 0.5
        }
      },    
       actionEnabled: {
        padding: "0.25rem 0.5rem",
        borderRadius: "0.25rem",
        color: "white",
        fontSize: '0.75rem',
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).userActive,
        transition: "all 150ms",
        "&:hover": {
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).userHover,
        },
        "&:disabled": {
          // backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l4
        }
      },    
       actionDisabled: {
        padding: "0.25rem 0.5rem",
        borderRadius: "0.25rem",
        color: "white",
           fontSize: '0.75rem',
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).userInactive,
        transition: "all 150ms",
        "&:hover": {
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).userHover,
        },
        "&:disabled": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l4
        }
      },         
      approval: {
        padding: "0.25rem 1rem",
        color: COLORS(variant, primaryColor, defaultSaturation).t3,
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l2,
        borderRadius: "0.25rem",
        transition: "all 150ms",
        "&:hover:enabled": {
          boxShadow: "3px 3px 6px 0px rgba(0,0,0,0.3)"
        },
        "&:disabled": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l4
        }
      },
      primary: {
        padding: "0.5rem 1rem",
        borderRadius: "0.25rem",
        color: "white",
        backgroundColor: COLORS(variant, primaryColor, defaultSaturation).blue,
        transition: "all 150ms",
        "&:hover:enabled": {
          boxShadow: "3px 3px 6px 0px rgba(0,0,0,0.3)"
        },
        "&:disabled": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l4
        }
      },
      secondary: {
        padding: "0.5rem 1rem",
        borderRadius: "0.25rem",
        color: COLORS(variant, primaryColor, defaultSaturation).t3,
        border: "1px solid transparent",
        backgroundColor: "white",
        "&:hover": {
          textDecoration: "underline"
        }
      }
    },

    selects: {
      backgroundColor: "red",
      clean: {
        "&:hover": {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation).l1
        }
      }
    },

    selectsOld: {
      default: {
        title: {
          height: "46px"
        },
        content: {
          display: "none",
          position: "absolute",
          backgroundColor: "#E0E0E0",
          minWidth: "200px",
          boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
          zIndex: "5"
        },
        item: {
          backgroundColor: "#E0E0E0",
          padding: "12px",
          "&:hover": {
            transition: "all 350ms",
            backgroundColor: "#E0E0E0"
          },
          float: "none",
          color: "black"
        }
      }
    },

    dropdowns: {
      profile: {
        title: {
          height: "46px"
        },
        content: {
          position: "absolute",
          backgroundColor: "#E0E0E0",
          minWidth: "200px",
          boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
          zIndex: "5"
        },
        item: {
          backgroundColor: "#E0E0E0",
          padding: "12px",
          "&:hover": {
            transition: "all 350ms",
            backgroundColor: "#E0E0E0"
          },
          float: "none",
          color: "black"
        }
      },
      nav: {
        wrapper: {
          float: "left",
          overflow: "hidden"
        },
        title: {
          height: "46px",
          padding: "19px 12px"
        },
        content: {
          position: "absolute",
          backgroundColor: "#E0E0E0",
          minWidth: "200px",
          boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
          zIndex: "5"
        },
        item: {
          backgroundColor: COLORS(variant, primaryColor, defaultSaturation)
            .mainNavBackground,
          padding: "12px",
          "&:hover": {
            transition: "all 350ms",
            backgroundColor: "#C0C0C0",
            color: "black"
          },
          float: "none",
          color: "black"
        },
        subMenu: {
          position: "absolute",
          backgroundColor: "#E0E0E0",
          padding: 0,
          top: 0,
          left: "160px",
          minWidth: "200px"
        }
      },
      listAction: {
        wrapper: {
          float: "left",
          overflow: "hidden",
          width: "100%"
        },
        title: {
          margin: "auto"
        },
        content: {
          display: "none",
          position: "absolute",
          backgroundColor: "white",
          minWidth: "200px",
          boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
          zIndex: "5",
          right: "0"
        },
        item: {
          backgroundColor: "white",
          padding: "12px",
          "&:hover": {
            backgroundColor: "#EBEBEB"
          },
          float: "none",
          textAlign: "left",
          color: "black"
        },
        subMenu: {
          position: "absolute",
          display: "none",
          backgroundColor: "#263D46",
          padding: 0,
          top: 0,
          left: "160px",
          minWidth: "200px"
        }
      }
    },

    textStyles: {
      // O attributo dummy existe por conta de um bug no styled-system que não estava
      // renderizando todos os attributos
      label: {
        dummy: "none",
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        fontSize: fontSizes.label
      },
      title: {
        dummy: "none",
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        fontSize: fontSizes.title,
        fontWeight: fontWeights.bold
      },
      subtitle: {
        dummy: "none",
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        fontSize: fontSizes.subtitle,
        fontWeight: fontWeights.bold
      },
      subsubtitle: {
        fontSize: fontSizes.subsubtitle,
        fontWeight: fontWeights.bold,
        color: COLORS(variant, primaryColor, defaultSaturation).t2
      },
      tableHead: {
        fontSize: fontSizes.tableHead,
        fontWeight: fontWeights.bold
      },
      section: {
        dummy: "none",
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        fontSize: fontSizes.section,
        fontWeight: fontWeights.bold,
        marginTop: "40px",
        marginBottom: "16px"
      },
      subsection: {
        dummy: "none",
        textTransform: "uppercase",
        color: COLORS(variant, primaryColor, defaultSaturation).t1,
        fontSize: fontSizes.h8,
        fontWeight: fontWeights.semibold
      },
      navMenu: {
        fontSize: "14px",
        fontWeight: fontWeights.regular
      },
      sidebarFilter: {
        textTransform: "uppercase",
        fontSize: "11px",
        fontWeight: fontWeights.semibold
      },
      menuSeparator: {
        color: COLORS(variant, primaryColor, defaultSaturation).t5,
        textTransform: "uppercase",
        fontSize: "10px",
        fontWeight: fontWeights.bold
      }
    }
  };
};
