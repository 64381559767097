import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Icon, Input, orderObjectsList, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import Admin from "./Admin";

import Header from "./Header";
import Members from "./Members";

const Reveal = () => {
  return null;
};

const RenderList = ({ projectId }) => {
  const dispatch = useDispatch();

  let project = useSelector((state) => state["tasks"].projects[projectId]);
  let groups = useSelector((state) => state["tasks"].lists);

  const deleteList = (listId) => {
    dispatch(
      ACTIONS.fetch.delete(
        "tasks",
        `${API_ROOT}/projectlist/${listId}/delete`,
        ACTIONS.tasks.deleteList
      )
    );
  };

  let orderedLists = project.lists_ids.map((projectId) => {
    return groups[projectId];
  });

  orderedLists = orderObjectsList(orderedLists, "order", "desc");

  return (
    <Droppable droppableId={`project.${projectId}`} type="GROUP">
      {(provided, snapshot) => (
        <Flex
          ref={provided.innerRef}
          flexDirection="column"
          minHeight={20}
          borderRadius={4}
          bg={snapshot.isDraggingOver ? "#ddf5ff" : "l2"}
          p={4}
          overflow="auto"
          border="1px solid blue"
        >
          {orderedLists.map((list, index) => {
            return (
              <Draggable
                draggableId={`list.${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <Flex
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    borderRadius={4}
                    mb={2}
                    py={2}
                    bg="white"
                    alignItems="center"
                    justifyContent="space-between"
                    height={24}
                    {...provided.dragHandleProps}
                  >
                    <Text ml={2}>{list.name}</Text>

                    <Icon.Trash
                      style={{ cursor: "pointer" }}
                      mr={2}
                      width={14}
                      height={14}
                      minWidth={14}
                      minHeight={14}
                      onClick={() => deleteList(list.id)}
                    />
                  </Flex>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </Flex>
      )}
    </Droppable>
  );
};

const Lists = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [adding, setAdding] = useState(false);

  const addList = (name) => {
    setAdding(false);
    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ROOT}/projectlist`,
        ACTIONS.tasks.addList,
        {
          name,
          project_id: project.id
        }
      )
    );
  };

  return (
    <Flex flexDirection="column" width={1}>
      <Flex flexDirection="column">
        <Header
          adding={adding}
          setAdding={setAdding}
          title={t("Lists")}
          addText={t("New list")}
        />

        <Reveal height={44} width="100%" opened={adding}>
          <Input
            name="New list"
            value=""
            placeholder={t("Add new tasks list")}
            onEnter={addList}
            opened={adding}
            changeOpened={setAdding}
            mb={4}
            rightIcon="ReturnLeft"
          />
        </Reveal>
      </Flex>

      <RenderList projectId={project.id} />
    </Flex>
  );
};

const ProjectAdmin = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let project = useSelector((state) => state["tasks"].projects[id]);
  // let members = useSelector((state) => state["tasks"].projects[id].members);
  let spaceMembers = useSelector(
    (state) => state["spaces"].spaces[project.space_id].members
  );
  let users = useSelector((state) => state["spaces"].users);

  let space = useSelector((state) => state["spaces"][project.space_id]);
  console.log(space);

  const addMember = (newUser, newUserRole) => {
    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ROOT}/project/add_user`,
        ACTIONS.tasks.addProjectUser,
        { user_id: newUser, type_id: newUserRole, project_id: project.id }
      )
    );
  };

  let user;
  let spaceUsers = {};

  spaceMembers.forEach((index) => {
    user = users[index.id];
    if (!user.is_deleted) spaceUsers[index.id] = user.name;
  });

  let members = {};
  const ADMIN = {
    icon: Icon.Project,
    title: project.name,
    options: [
      { icon: "List", title: t("Lists"), view: Lists, props: { project } },
      {
        icon: "User",
        title: t("Members"),
        view: Members,
        props: { addMember, members, users: spaceUsers }
      }
    ]
  };

  return <Admin admin={ADMIN} />;
};

export default ProjectAdmin;
