import React from "react";
import { useSelector } from "react-redux";

import { Flex, Text } from "tomato";

import { User } from "./User";

export const Users = ({ activeUsers }) => {
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const remainingUsers = Object.values(users).filter((usr) => {
    let userId = usr.id;
    return (
      userId !== user.id &&
      !activeUsers.includes(userId) &&
      !usr.is_deleted &&
      usr.is_active
    );
  });

  return (
    <Flex flexDirection="column" mt="1rem">
      <Text
        mb="0.5rem"
        fontSize="0.625rem"
        fontWeight="bold"
        style={{ textTransform: "uppercase" }}
      >
        Direct messages
      </Text>

      {activeUsers.map((userId, index) => (
        <User key={index} user={users[userId]} />
      ))}

      {remainingUsers.length !== 0 && (
        <Flex height="1px" my="0.5rem" borderBottom="1px solid lightGrey" />
      )}

      {remainingUsers.map((user, index) => (
        <User key={index} user={user} />
      ))}
    </Flex>
  );
};
