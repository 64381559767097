import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ClickToEdit, Flex, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { ColorPicker } from "components";
import { Item } from "./Item";
import { Page } from "./Page";

const Type = ({ type }) => {
  let color = "green";
  return (
    <Flex
      mr="1rem"
      borderRadius="0.25rem"
      width="2rem"
      height="2.75rem"
      bg={color}
      alignItems="center"
      justifyContent="center"
    >
      <Text color="white" fontSize="1rem" fontWeight="bold">
        {type.toUpperCase()}
      </Text>
    </Flex>
  );
};

const AfterPage = ({ pageIndex }) => {
  const form = useSelector((state) => state["forms"].form);

  return (
    <>
      {pageIndex + 1 < form.content.pages.length &&
        form.content.pages.length > 2 && (
          <Flex mb="1rem">After page {pageIndex + 1}</Flex>
        )}
    </>
  );
};

export const Forms = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const form = useSelector((state) => state["forms"].form);
  const network = useSelector((state) => state["network"].activity);

  const [saving, setSaving] = useState(false);

  const match = useRouteMatch(`/files/forms/:uuid`);

  const uuid = match.params.uuid;
  // const url = `${API_ROOT}${module.endpoint}/${match.params.id}`;

  // const [editing, setEditing] = useState({ pageIndex: null, itemIndex: null });

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "forms",
        `${API_ROOT}/file/${uuid}`,
        ACTIONS.state.loadObject,
        "form"
      )
    );
  }, []);

  useEffect(() => {
    if (!saving) return;
    if (!network) setSaving(false);
  }, [network, saving]);

  const saveForm = () => {
    setSaving(true);
    dispatch(
      ACTIONS.fetch.patch("forms", `${API_ROOT}/file/${uuid}`, [], {
        name: form.content.name,
        content: JSON.stringify(form.content)
      })
    );
  };

  const updateName = (name) => {
    setSaving(true);
    dispatch(
      ACTIONS.fetch.patch("forms", `${API_ROOT}/file/${uuid}`, [], {
        name
      })
    );
  };

  if (!form) return null;

  const setColor = (color) => {
    dispatch(ACTIONS.forms.updateSettings("color", color));
  };

  let bg = `hsl(${form.content.settings.color.h}, ${form.content.settings.color.s}%, 95%)`;
  let color = `hsl(${form.content.settings.color.h}, ${form.content.settings.color.s}%, 50%)`;

  return (
    <Flex
      height="100%"
      alignItems="center"
      overflow="auto"
      flexDirection="column"
      bg={bg}
      width={1}
    >
      <Flex
        bg="l0"
        height="5rem"
        minHeight="5rem"
        width={1}
        position="fixed"
        zIndex="10"
        borderBottom="1px solid lightGrey"
      >
        <Flex alignItems="center" width={1} p="1.5rem">
          <Type type="f" />

          <ClickToEdit
            fontWeight="bold"
            color="t1"
            fontSize="1rem"
            enabled={true}
            value={form.name}
            placeholder={t("Enter form name...")}
            onBlur={updateName}
          />

          {saving && <Text ml="3rem">saving...</Text>}

          <Flex width="fit-content" ml="auto" mr={0}>
            <Icon.Save
              mr="1rem"
              style={{ cursor: "pointer" }}
              onClick={saveForm}
            />
            <ColorPicker
              setColor={setColor}
              color={form.content.settings.color}
            />
            <Text ml="0.25rem">{t("Set color")}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        mt="7rem"
        minWidth="10rem"
        width="48rem"
        maxWidth="48rem"
        height="100%"
        flexDirection="column"
      >
        {form.content.pages.map((page, pageIndex) => {
          return (
            <Flex key={pageIndex} flexDirection="column" width={1} mb="2rem">
              <Page color={color} pageIndex={pageIndex} page={page} />

              {page.items.map((item, itemIndex) => {
                return (
                  <Item
                    key={itemIndex}
                    item={item}
                    color={color}
                    pageIndex={pageIndex}
                    itemIndex={itemIndex}
                  />
                );
              })}

              <AfterPage pageIndex={pageIndex} />
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
