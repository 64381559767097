import produce from "immer";

import { REDUCERS } from "tomato";

import { ALL_MODULES } from "../state";

import appReducer from "modules/App/reducers";
import conversationsReducer from "../../modules/Conversations/reducers";
import eventReducer from "../reducers/event";
import { reducer as documents } from "modules/Documents/reducers";
import { reducer as files } from "modules/Files/reducers";
import { reducer as forms } from "modules/Forms/reducers";
import { reducer as reminders } from "modules/Reminders/reducers";
import { reducer as spaces } from "modules/Spaces/reducers";
import { reducer as tasks } from "../../modules/Tasks/reducers";
import { reducer as topics } from "modules/Topics/reducers";
import { reducer as updates } from "modules/Updates/reducers";

const ALL_REDUCERS = {
  ...REDUCERS,
  appReducer,
  conversationsReducer,
  documents,
  eventReducer,
  files,
  forms,
  reminders,
  spaces,
  tasks,
  topics,
  updates
};

const combineReducers = (reducers, allModules) => {
  const finalReducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    let hasChanged = false;
    const nextState = {};

    if (!action.payload) return state;

    let moduleId = action.payload.module;

    if (typeof moduleId === "undefined") {
      return state;
    }

    allModules.forEach((module) => {
      if (moduleId === module) {
        nextState[module] = state[module];

        for (let i = 0; i < finalReducerKeys.length; i++) {
          const key = finalReducerKeys[i];

          const reducer = reducers[key];

          nextState[module] = produce(nextState[module], (draftState) =>
            reducer(draftState, action)
          );

          hasChanged = hasChanged || nextState[moduleId] !== state[module];
        }
      } else {
        nextState[module] = state[module];
      }
    });

    return hasChanged ? nextState : state;
  };
};

const rootReducer = combineReducers(ALL_REDUCERS, ALL_MODULES);

export { rootReducer as REDUCERS };
