import React from "react";

import { Flex, getShadow, Text } from "tomato";

export const Widget = ({ children, titleLeft, titleRight, ...props }) => {
  return (
    <Flex
      className="Widget"
      height="fit-content"
      position="relative"
      bg="l0"
      borderRadius="0.75em"
      p="1rem"
      overflow="auto"
      flexDirection="column"
      {...getShadow("s0")}
      {...props}
    >
      {titleLeft && (
        <Flex alignItems="center" justifyContent="space-between" mb="1rem">
          <Text textStyle="subtitle">{titleLeft}</Text>

          {(titleRight || titleRight === 0) && (
            <Text color="t3" fontSize="0.875rem" fontWeight="semibold">
              {titleRight}
            </Text>
          )}
        </Flex>
      )}

      {children}
    </Flex>
  );
};
