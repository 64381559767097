import { useEffect } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ children, id }) => {
  let portal = document.getElementById(id);

  if (!portal) {
    portal = document.createElement("div");
    portal.setAttribute("id", id);
    portal.setAttribute("height", "100%");
    portal.setAttribute("width", "1");
    portal.setAttribute("overflow", "auto");
    document.body.appendChild(portal);
  }

  // useEffect(() => {
  //   let portal = document.getElementById(id);
  //   return () => document.body.removeChild(portal);
  // }, []);

  return createPortal(children, portal);
};
