import React, { useEffect, useMemo, useState } from "react";
import { isBefore, isToday, getHours, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { utcToZonedTime } from "date-fns-tz";

import {
  Avatar,
  Counter,
  Column,
  Flex,
  formatDate,
  Image,
  Reactions,
  SimpleEditor,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, ROOT_URL } from "config";
import { DEFAULT_MARGIN } from "constant";
import { Sidebar } from "modules/Tasks/Task/Sidebar";
import { MainWindow, MembersList, SideMenu } from "components";
import { t } from "i18n";
import { TASKS } from "crud";
import { Timeline } from "modules";
import { Widgets } from "modules/Tasks/Widgets";

const getPeriod = () => {
  let hour = getHours(new Date());

  let period = { id: "MORNING", value: t("Good morning") };

  if (hour > 18) {
    period = { id: "NIGHT", value: t("Good night") };
  } else if (hour > 12)
    period = { id: "AFTERNOON", value: t("Good afternoon") };

  return period;
};

const Poll = ({ message }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state["account"].user);

  const saveAnswer = (id) => {
    dispatch(
      ACTIONS.fetch.patch(
        "billboard",
        `${API_ENDPOINT}/topicreply/${message.id}/answer`,
        ACTIONS.state.loadObject,
        { answer_id: id },
        (state, response) => {
          state.objs[response.id] = response;
          return state;
        }
      )
    );
  };

  const totalQuestion = Object.keys(message.poll).length;
  let totalAnswers = 0;

  Object.values(message.poll).forEach((question) => {
    totalAnswers += Object.keys(question.answers).length;
  });

  return (
    <>
      <Flex mt="1rem">
        {Object.keys(message.poll).map((id, index) => {
          return (
            <Column
              onClick={() => saveAnswer(id)}
              borderTopLeftRadius={!index ? "0.5rem" : ""}
              borderBottomLeftRadius={!index ? "0.5rem" : ""}
              borderTopRightRadius={index + 1 === totalQuestion ? "0.5rem" : ""}
              borderBottomRightRadius={
                index + 1 === totalQuestion ? "0.5rem" : ""
              }
              borderLeft={!index ? "1px solid lightGrey" : ""}
              borderRight="1px solid lightGrey"
              borderTop="1px solid lightGrey"
              borderBottom="1px solid lightGrey"
              alignItems="center"
              px="1rem"
              bg={message.poll[id]["answers"][user.id] ? "lightRed" : "l0"}
              style={{ cursor: "pointer" }}
              hoverColor="userLight"
            >
              <Text pt="0.5rem" fontSize="1.25rem" fontWeight="semibold">
                {totalAnswers > 0
                  ? (
                      (Object.values(message.poll[id].answers).length * 100) /
                      totalAnswers
                    ).toFixed(1)
                  : 0}
                %
              </Text>
              <Text py="0.5rem">{message.poll[id].label}</Text>
            </Column>
          );
        })}
      </Flex>
      <Text mt="0.5rem">
        {t("Total answers")}: {totalAnswers}
      </Text>
    </>
  );
};

const Message = ({ index, message }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const markAsRead = (messageId) => {
    dispatch(
      ACTIONS.fetch.patch(
        "billboard",
        `${API_ENDPOINT}/topicreply/${messageId}/read`,
        ACTIONS.state.loadObject,
        {},
        (state, response) => {
          state.objs[response.id] = response;
          return state;
        }
      )
    );
  };

  const saveReactions = (replyId, reactions) => {
    dispatch(
      ACTIONS.fetch.patch(
        "billboard",
        `${API_ENDPOINT}/topicreply/${replyId}`,
        ACTIONS.state.loadObject,
        {
          reactions
        },
        (state, response) => {
          state.objs[response.id] = response;
          return state;
        }
      )
    );
  };

  return (
    <Flex
      key={message.id}
      mt={index ? "0.75rem" : ""}
      bg="lightGreen"
      borderRadius="0.5rem"
      position="relative"
    >
      {message.confirm_read && !message.read_by[user.id] && (
        <Column
          width={1}
          height="100%"
          position="absolute"
          zIndex="99999"
          justifyContent="center"
          alignItems="center"
          p="0.5rem"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ backdropFilter: "blur(6px)" }}
            width={1}
            height="100%"
          >
            <Text
              onClick={() => markAsRead(message.id)}
              fontWeight="semibold"
              style={{ cursor: "pointer" }}
              bg="lightRed"
              p="1rem"
              borderRadius="0.5rem"
            >
              {t(
                "You should read this message carefully! To read it click here!"
              )}
            </Text>
          </Flex>
        </Column>
      )}

      <Flex p="1rem" width={1}>
        <Avatar
          size="large"
          mr="0.5rem"
          src={users[message.created_by_id].avatar}
        />

        <Column width={1}>
          <SimpleEditor
            key={message.id}
            borderRadius={0}
            style={{ workBreak: "break-all" }}
            flexGrow="1"
            lineHeight={1.25}
            autofocus={false}
            value={message.content ? message.content : null}
            fileRootUrl={`${ROOT_URL}/shared/`}
            renderMode={true}
            removePadding={true}
          />
          {message.type_id === 1 && (
            <Reactions
              user={user}
              users={users}
              reactions={message.reactions}
              onSelect={(reactions) => saveReactions(message.id, reactions)}
              size={14}
              showReactions={false}
            />
          )}

          {message.confirm_read && (
            <Flex mt="0.5rem" alignItems="center">
              <Text fontSize="0.75rem">{t("Read by")}:</Text>
              {Object.keys(message.read_by).map((userId) => (
                <Avatar size="medium" ml="0.5rem" src={users[userId].avatar} />
              ))}
            </Flex>
          )}

          {message.type_id === 2 && <Poll message={message} />}
        </Column>
        {message.created_by_id === user.id && <></>}
      </Flex>
    </Flex>
  );
};

const FrontPage = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state["billboard"].objs);

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "billboard",
        `${API_ENDPOINT}/topicreply?front_page=True&limit=0`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          let messages = {};
          response.objects.forEach((message) => {
            messages[message.id] = message;
          });
          state.objs = messages;
          return state;
        }
      )
    );
  }, [dispatch]);

  if (!messages || !Object.values(messages).length) return null;

  return (
    <Flex flexDirection="column" width={1} my="1rem">
      {Object.values(messages).map((message, index) => (
        <Message key={message.id} index={index} message={message} />
      ))}
    </Flex>
  );
};

const TaskLine = ({ task, due = false }) => {
  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);

  const space = task.space_id ? spaces[task.space_id] : null;
  const taskOpen = useSelector((state) => state["tasks"].obj);

  const loadTask = (taskId) => {
    TASKS.getOne(taskId);
  };

  return (
    <Flex
      width={1}
      alignItems="center"
      borderRadius="0.25rem"
      px="0.25rem"
      py="0.25rem"
      bg={taskOpen && taskOpen.id === task.id ? "userBold" : ""}
      hoverColor="userHover"
      onClick={() => loadTask(task.id)}
      style={{ cursor: "pointer" }}
    >
      <Text
        color="t2"
        fontWeight="semibold"
        fontSize="0.875rem"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {task.subject}
      </Text>

      {false && due && (
        <Text
          color="red"
          ml="0.5rem"
          fontWeight="semibold"
          fontSize="0.75rem"
          onClick={() => loadTask(task.id)}
          style={{
            cursor: "pointer",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          ({formatDate(task.deadline, user.locale, user.timezone).distance()})
        </Text>
      )}

      {false && (
        <Flex
          ml="0.5rem"
          bg="l2"
          style={{ cursor: "pointer" }}
          border="1px solid lightGrey"
          borderRadius="0.25rem"
          fontSize="0.55rem"
          px="0.25rem"
          py="0.125rem"
          width="fit-content"
        >
          {space ? space.name : "Sem nome do espaço"}
        </Flex>
      )}
    </Flex>
  );
};

export const Welcome = () => {
  const { t } = useTranslation();

  const tasks = useSelector((state) => state["tasks"].objs);
  const user = useSelector((state) => state["account"].user);

  // No react 17 o clean up das tasks é assíncrono, precisamos terminar ele executar para recarregar
  // o sidebar
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true), []);

  const [today, due, doneToday] = useMemo(() => {
    let today = new Date();

    let dueToday = [];
    let doneToday = [];
    let due = [];

    Object.values(tasks).forEach((task) => {
      if (task.owner_id !== user.id) return;

      // Ao trocar a data a mesma vem em Datetime, se vem do banco vem em string
      let deadline =
        typeof task.deadline === "string"
          ? utcToZonedTime(parseISO(task.deadline), user.timezone)
          : task.deadline;

      let doneDate =
        typeof task.done_date === "string"
          ? utcToZonedTime(parseISO(task.done_date), user.timezone)
          : task.done_date;

      if (task.done_date && isToday(doneDate)) {
        doneToday.push(task);
        return;
      }

      if (!deadline || (deadline && doneDate)) return;

      if (isToday(deadline)) {
        dueToday.push(task);
        return;
      }

      if (isBefore(deadline, today)) {
        due.push(task);
        return;
      }
    });
    return [dueToday, due, doneToday];
  }, [tasks, user]);

  return (
    <>
      <Flex width={1} overflow="auto" height="100%">
        <SideMenu>
          <Column
            p={DEFAULT_MARGIN}
            // width={1 / 3}
            // bg="l0"
            // borderRadius="0.5rem"
            // mt="1rem"
            // mx="1rem"
            // px="1rem"
            // py="1rem"
            overflow="auto"
            // boxShadow="0px 0px 4px 0px rgba(0,0,0,0.18)"
          >
            {false && (
              <Flex
                height="2.5rem"
                justifyContent="space-between"
                alignItems="center"
                width={1}
              >
                <Text textStyle="title">
                  {t(getPeriod().value)} {user.nickname}
                </Text>
              </Flex>
            )}

            <Flex alignItems="center" mb="1rem">
              <Text textStyle="subtitle">{t("Today tasks")}</Text>
              {today.length > 0 && <Counter ml="0.5rem" count={today.length} />}
            </Flex>

            {today.length === 0 ? (
              <Text fontSize="0.875rem" fontWeight="semibold" color="t1">
                Você não tem nenhuma tarefa para fazer hoje!
              </Text>
            ) : (
              <Flex
                flexDirection="column"
                justifyContent="center"
                height="100%"
                width={1}
              >
                {today.map((task) => (
                  <TaskLine key={task.id} task={task} />
                ))}
              </Flex>
            )}
          </Column>
        </SideMenu>

        <MainWindow bg="l1">
          <FrontPage />
          <Timeline />
        </MainWindow>

        <SideMenu>
          <Column
            // width={1 / 3}
            // mt="1rem"
            // bg="l0"
            // mx="1rem"
            // borderRadius="0.5rem"
            p={DEFAULT_MARGIN}
            overflow="auto"
            // boxShadow="0px 0px 4px 0px rgba(0,0,0,0.18)"
          >
            {false && <Widgets.Time />}
            <Flex alignItems="center" mb="1rem">
              <Text textStyle="subtitle">{t("Tasks due")}</Text>
              <Counter ml="0.5rem" count={due.length} />
            </Flex>

            <Column overflow="auto">
              {due.length !== 0 && (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  height="100%"
                  width={1}
                >
                  {due.map((task) => (
                    <TaskLine key={task.id} task={task} due={true} />
                  ))}
                </Flex>
              )}
            </Column>
          </Column>
        </SideMenu>
      </Flex>
      {loaded && <Sidebar />}
    </>
  );
};
