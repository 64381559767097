import React, { useState } from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dropdown,
  Flex,
  Input,
  Row,
  SimpleEditor,
  strip,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT, API_ENDPOINT, ROOT_URL } from "config";

const New = ({ categoryId, spaceId, open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const categories = useSelector((state) => state["topic"].categories);
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [category, setCategory] = useState(
    categoryId && categories.find((category) => category.id === categoryId)
  );
  const [space, setSpace] = useState(spaces[spaceId]);
  const [content, setContent] = useState();

  const [title, setTitle] = useState();

  const summary = content
    ? strip(content.map((n) => Node.string(n)).join(" ")).slice(0, 50)
    : null;

  const addMessage = () => {
    const message = {
      title,
      category_id: category.id,
      content,
      space_id: spaceId,
      summary
    };
    dispatch(
      ACTIONS.fetch.post(
        "topic",
        `${API_ENDPOINT}/topic/`,
        ACTIONS.state.loadObject,
        message,
        (state, response) => {
          state.obj = response;
          state.objs[response.id] = response;
          state.feed && state.feed.unshift(response);
          return state;
        }
      )
    );

    setOpen(false);
  };

  let style = { disabled: "disabled" };
  if (title && title.length > 0 && spaceId && summary && category) style = {};
  let spacesOptions = Object.values(spaces).map((space) => {
    return { id: space.id, value: space.name };
  });

  const options = categories.map((category) => {
    return { id: category.id, value: category.title };
  });

  return (
    <>
      <Text textStyle="subtitle" mb={4}>
        {t("New post")}
      </Text>

      <Row label={t("Title")}>
        <Input
          width={1}
          focus={true}
          mb={2}
          placeholder={t("Message title...")}
          onChange={setTitle}
        />
      </Row>

      {false && (
        <Row mt="1rem" label={t("Where")}>
          <Dropdown
            minWidth="8rem"
            options={spacesOptions}
            onSelect={(option) => {
              setSpace(option);
            }}
            selected={space ? { id: space.id, value: space.name } : null}
            placeholder={t("Choose space")}
          />
        </Row>
      )}

      <Flex mt="1rem" flexDirection="column" overflow="auto" height="100%">
        {false && (
          <Row mb="2rem" label={t("Category")}>
            <Dropdown
              options={options}
              onSelect={setCategory}
              selected={
                category ? { id: category.id, value: category.name } : null
              }
              placeholder={t("Select category")}
            />
          </Row>
        )}

        <SimpleEditor
          width={1}
          placeholder={t("Write a new message...")}
          minHeight={32}
          lineHeight={1.5}
          enabled={true}
          height="100%"
          onChange={setContent}
          toolbarPosition="TOP"
          enableToolbar={true}
          fileRootUrl={`${ROOT_URL}/shared/`}
          uploadEndpoint={`${API_ROOT}/file/upload?space_id=${space.id}`}
        />
      </Flex>

      <Flex mb={0} mt="1rem" alignItems="center" justifyContent="flex-end">
        <Button
          ml={2}
          variant="secondary"
          onClick={() => setOpen(false)}
          value={t("Cancel")}
        />
        <Button
          ml={2}
          {...style}
          variant="primary"
          onClick={addMessage}
          value={t("Add message")}
        />
      </Flex>
    </>
  );
};

export default New;
