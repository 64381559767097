import React, { useEffect, useMemo } from "react";
import Fuse from "fuse.js";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, orderObjects, Spinner } from "tomato";

import { DEFAULT_MARGIN } from "constant";
import { EmptyProject } from "./EmptyProject";
import { filter } from "./filter";
import { Grouped, Pinned } from "./TaskGroup";

let searchOptions = {
  shouldSort: false,
  threshold: 0.1,
  ignoreLocation: true,
  maxPatternLength: 100,
  minMatchCharLength: 1,
  keys: ["id", "subject", "tags", "owner", "creator"]
};

const Tasks = () => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state["tasks"].filters);
  const orderedLists = useSelector((state) => state["tasks"].orderedLists);
  const projects = useSelector((state) => state["tasks"].projects);
  const tasks = useSelector((state) => state["tasks"].objs);
  const users = useSelector((state) => state["spaces"].users);
  const user = useSelector((state) => state["account"].user);

  useEffect(() => {
    document.title = t("Tasks");
    return () => (document.title = "Elefante");
  }, [t]);

  let fuse = useMemo(() => {
    return new Fuse(
      Object.keys(tasks).map((taskId) => {
        let owner,
          creator = "";

        if (tasks[taskId].created_by_id) {
          creator = users[tasks[taskId].created_by_id].name;
        }
        if (tasks[taskId].owner_id) owner = users[tasks[taskId].owner_id].name;

        return {
          subject: tasks[taskId].subject,
          id: taskId,
          tags: tasks[taskId].tags,
          owner,
          creator
        };
      }),
      searchOptions
    );
  }, [tasks, users]);

  const filtered = useMemo(() => {
    let orderedTasks = orderObjects(tasks, "order", "asc");
    return filter(filters, orderedTasks, user, fuse);
  }, [filters, fuse, tasks, user]);

  const lists = useMemo(
    () =>
      orderedLists.filter((list) => {
        if (filters.listId) {
          return list.id === filters.listId;
        }

        if (filters.projectId) {
          return list.project_id === filters.projectId;
        }

        if (filters.spaceId) {
          return (
            parseInt(filters.spaceId) ===
            parseInt(projects[list.project_id].space_id)
          );
        }

        // Se não tem filtro retorna todas as tasks
        return true;
      }),
    [filters, orderedLists, projects]
  );

  if (!filtered) return <Spinner size="large" />;

  return (
    <Flex
      px={DEFAULT_MARGIN}
      overflow="auto"
      height="100%"
      flexDirection="column"
    >
      <Pinned tasksIds={filtered.pinned} />

      <Grouped groups={filtered.grouped} lists={lists} />
    </Flex>
  );
};

export default Tasks;
