import React, { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Action, Flex } from "../Flex";
import { Icon } from "../Icon";
import { Image } from "../Image";
import { MIME_TYPES } from "../../constants/mimeTypes";
import { Text } from "../Text";
import { ACTIONS } from "../../store";

export const Dropzone = ({
  files,
  setFiles,
  maxFiles,
  maxFileSize,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isOver, setIsOver] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const inputRef = useRef();

  const maxSize = useMemo(() => 1024 * 1024 * maxFileSize, [maxFileSize]);

  const countSize = () => {
    let size = 0;
    for (var i = 0; i < files.length; i++) {
      size += files[i].size;
    }
    return size;
  };

  const checkSize = (file) => {
    if (file.size > maxSize) {
      showError(
        t(
          "{{fileName}} exceeded the maximum size allowed, I will not upload it",
          { fileName: file.name }
        )
      );
      return true;
    }
    return false;
  };

  const checkType = (file) => {
    return false;
    if (MIME_TYPES.includes(file.type) || file.type.split("/")[0] === "text")
      return false;

    showError(
      t("{{fileType}} of {{fileName}} is not allowed, I will not upload it", {
        fileType: file.type,
        fileName: file.name
      })
    );
    return true;
  };

  const checkCount = (array, file) => {
    if (array.length + files.length < maxFiles) {
      return false;
    }

    showError(
      ("{{file.name}} exceeded the maximum quantity allowed, I will not upload it",
      { fileName: file.name })
    );

    return true;
  };

  let count = files.length;
  let totalSize = countSize();

  const showError = (message) => {
    dispatch(
      ACTIONS.toast.addToast({
        message: message,
        type: "error",
        autoClose: 3000
      })
    );
  };

  const fileListToArray = (files) => {
    const array = [];
    for (var i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (checkSize(file)) continue;
      if (checkType(file)) continue;
      if (checkCount(array, file)) continue;

      array.push(files.item(i));
    }
    return array;
  };

  const openFileDialog = () => {
    if (isDisabled) return;
    inputRef.current.click();
  };

  const onFilesAdded = (e) => {
    e.preventDefault();
    if (isDisabled) return;

    const array = fileListToArray(e.target.files);
    setFiles(array.concat(files));
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (isDisabled) return;
    const array = fileListToArray(e.dataTransfer.files);
    setFiles(array.concat(files));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsOver(true);
  };

  return (
    <>
      <Flex
        width={1}
        alignItems="center"
        justifyContent="center"
        onDragLeave={() => setIsOver(false)}
        onDrop={onDrop}
        onDragOver={onDragOver}
        bg={isOver ? "l2" : "l0"}
        border="2px dashed lightGrey"
        borderRadius="0.5rem"
        p="0.75rem"
        height="fit-content"
        {...props}
        flexDirection="column"
      >
        <Flex>
          <Icon.UploadToCloud minWidth="2.5rem" minHeight="2.5rem" />

          <Flex
            ml="1rem"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={openFileDialog}
          >
            <input
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
              multiple
              onChange={onFilesAdded}
            />

            <Text color="t2">{t("Drag and drop files here or")}</Text>
            <Action mt="0.25rem" fontSize="0.9375rem">
              {t("select files")}
            </Action>
          </Flex>
        </Flex>
        <Text mb={0} mt={1} fontSize="11px" color="t3">
          {t("Maximum {{maxFiles}} files with {{maxFileSize}}M each", {
            maxFiles,
            maxFileSize
          })}
        </Text>
      </Flex>

      {(false || files.length > maxFiles) && (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          border="1px solid red"
        >
          <Text color="t3" fontWeight="bold" mt={0} mb="auto">
            {t("You reached the {{maxFiles}} files limit!", {
              maxFiles: maxFiles
            })}
          </Text>

          <Image maxWidth={200} src="images/max_files_reached.png" />

          <Text fontSize="11px" color="t3" mb="0" mt="auto">
            {t(
              "send them now or delete some files if you want to upload others..."
            )}
          </Text>
        </Flex>
      )}
    </>
  );
};
