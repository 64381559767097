import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  ChangeTheme,
  Flex,
  Icon,
  Image,
  Link,
  Pill,
  Text,
  Tippy,
  useLocalStorage
} from "tomato";

import { ACTIONS } from "store";
import { MODE } from "config";
import { ROOT_URL } from "config";
import { Presence, ProfileMenu } from "modules";
import { QuickAccess } from "./QuickAccess.js";

const MENU = [
  {
    id: "root",
    label: "Home",
    linkTo: "/",
    icon: "Home",
    enabled: true
  },
  {
    id: "updates",
    label: "Updates",
    linkTo: "/updates",
    icon: "Company",
    enabled: true
  },
  {
    id: "conversations",
    label: "Chat",
    linkTo: "/conversations",
    icon: "Graph",
    enabled: true
  },
  {
    id: "tasks",
    label: "Tasks",
    linkTo: "/tasks",
    icon: "Tasks",
    enabled: true
  },
  {
    id: "topic",
    label: "Message board",
    linkTo: "/topic",
    icon: "User",
    enabled: true
  },
  {
    id: "pages",
    label: "Wiki",
    linkTo: "/wiki",
    icon: "Document",
    enabled: true
  },
  {
    id: "files",
    label: "Files",
    linkTo: "/files",
    icon: "",
    enabled: true
  },
  {
    id: "calendar",
    label: "Calendar",
    linkTo: "/calendar",
    icon: "Calendar",
    enabled: true
  },
  {
    id: "virtualroom",
    label: "People",
    linkTo: "/people",
    icon: "Eye",
    enabled: true
  }
];

const Conversations = ({ moduleId }) => {
  const { t } = useTranslation();

  const conversations = useSelector(
    (state) => state["conversations"].conversations
  );
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  let unreadTotal = 0;

  Object.values(conversations).forEach((conversation) => {
    let userId = conversation.last_message_from_id;
    console.log(Object.keys(conversation.messages).length);
    console.log(conversation);

    if (
      Object.keys(conversation.messages).length &&
      conversation.last_message_timestamp >
        conversation.last_message_timestamp_read_by_you &&
      users[userId] &&
      userId !== user.id &&
      !users[userId].is_deleted &&
      users[userId].is_active
    ) {
      unreadTotal += 1;
    }
  });

  return (
    <Link to={`/conversations`}>
      <Tippy content={t("Conversations")} placement="right">
        <Flex
          bg={moduleId === "conversations" ? "l0" : "transparent"}
          p="0.5rem"
          borderRadius="0.5rem"
          hoverColor="l4"
          mt="1rem"
          position="relative"
        >
          {unreadTotal !== 0 && (
            <Pill
              position="absolute"
              right={0}
              top="-6px"
              count={unreadTotal}
            />
          )}

          <Icon.MessageCircle
            strokeWidth="2px"
            size="medium"
            stroke={moduleId === "conversations" ? "black" : " black"}
          />
        </Flex>
      </Tippy>
    </Link>
  );
};

const RenderUpdates = ({ moduleId }) => {
  const { t } = useTranslation();

  const updates = useSelector((state) => state["updates"].updates);

  if (!updates) return null;

  const count = Object.keys(updates).length;

  return (
    <Link to="/updates">
      <Tippy content={t("Updates")} placement="right">
        <Flex
          position="relative"
          mt="1rem"
          p="0.5rem"
          borderRadius="0.5rem"
          hoverColor="l4"
          style={{ cursor: "pointer" }}
          bg={moduleId === "updates" ? "l0" : "transparent"}
        >
          <Flex>
            {count !== 0 && (
              <Pill position="absolute" right="0px" top="-2px" count={count} />
            )}

            <Icon.Inbox strokeWidth="2px" size="medium" stroke="black" />
          </Flex>
        </Flex>
      </Tippy>
    </Link>
  );
};

const User = () => {
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  return (
    <Avatar
      size="large"
      src={users[user.id].avatar}
      style={{ cursor: "pointer" }}
    />
  );
};

const Favicon = () => {
  const conversations = useSelector(
    (state) => state["conversations"].conversations
  );
  const updates = useSelector((state) => state["updates"].updates);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  if (!updates) return;

  let count = Object.keys(updates).length;

  const favicon = document.getElementById("favicon");

  Object.values(conversations).forEach((conversation) => {
    let userId = conversation.last_message_from_id;
    if (
      conversation.last_message_timestamp >
        conversation.update_date_timestamp &&
      users[userId] &&
      userId !== user.id &&
      !users[userId].is_deleted &&
      users[userId].is_active
    ) {
      count += 1;
    }
  });

  if (false && count !== 0) {
    count = count > 10 ? "+10" : `-${count}`;
    favicon.href = `/images/favicon/elefante${count}.png`;
  } else {
    favicon.href = "/favicon-32x32.png";
  }
};

const Menu = ({ moduleId, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);

  const [spaceId, setSpaceId] = useLocalStorage("spaceId", 1);
  const [preferredSpaceId, setPreferredSpaceId] = useLocalStorage(
    "preferredSpaceId",
    1
  );
  const doLogout = () => {
    dispatch(
      ACTIONS.fetch.get(
        "account",
        `${ROOT_URL}/logout/`,
        ACTIONS.account.logout
      )
    );
  };

  const match = useRouteMatch("/space/:spaceId");
  let id = spaceId;
  if (match) {
    id = match.params.spaceId;
  }

  useEffect(() => {
    if (id !== spaceId) setSpaceId(id);
  }, [id, spaceId, setSpaceId]);

  Favicon();

  return (
    <Flex
      px="0.5em"
      py="1rem"
      bg="l3"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
      overflow="auto"
      borderRight="1px solid lightGrey"
    >
      <Link to="/">
        <Avatar
          size="large"
          src="/images/logo/face.jpg"
          style={{ cursor: "pointer" }}
        />
      </Link>

      <Flex mt="2rem" height="100%" flexDirection="column">
        {false && (MODE === "DEV" || user.id === 2) && (
          <ChangeTheme mx="auto" mb="2rem" />
        )}

        <QuickAccess />

        <Link to="/desk">
          <Tippy content={t("Working desk")} placement="right">
            <Flex
              bg={moduleId === "virtualroom" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              mt="2rem"
              hoverColor="l4"
            >
              <Icon.Eye
                strokeWidth="2px"
                size="medium"
                stroke={moduleId === "virtualroom" ? "black" : " black"}
              />
            </Flex>
          </Tippy>
        </Link>

        <Link to="/">
          <Tippy content={t("Start here")} placement="right">
            <Flex
              bg={moduleId === "root" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              mt="1rem"
              hoverColor="l4"
            >
              <Icon.Home
                strokeWidth="2px"
                size="medium"
                stroke={moduleId === "root" ? "black" : " black"}
              />
            </Flex>
          </Tippy>
        </Link>

        <Link to={`/topic`}>
          <Tippy content={t("Message board")} placement="right">
            <Flex
              bg={moduleId === "topics" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mt="1rem"
              flexDirection="column"
              alignItems="center"
            >
              <Icon.MessageSquare
                strokeWidth="2px"
                size="medium"
                stroke={moduleId === "messages" ? "l0" : " black"}
              />
            </Flex>
          </Tippy>
        </Link>

        <RenderUpdates moduleId={moduleId} />

        <Link to="/calendar">
          <Tippy content={t("Calendar")} placement="right">
            <Flex
              bg={moduleId === "calendar" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mt="1rem"
            >
              <Icon.Calendar
                strokeWidth="2px"
                size="medium"
                stroke={moduleId === "calendar" ? "black" : " black"}
              />
            </Flex>
          </Tippy>
        </Link>

        <Link to={"/tasks"}>
          <Tippy content={t("Tasks")} placement="right">
            <Flex
              bg={moduleId === "tasks" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mt="1rem"
            >
              <Icon.Task
                strokeWidth="2px"
                size="medium"
                stroke={moduleId === "tasks" ? "black" : " black"}
              />
            </Flex>
          </Tippy>
        </Link>

        <Conversations moduleId={moduleId} />

        <Link to="/wiki">
          <Tippy content={t("Wiki")} placement="right">
            <Flex
              bg={moduleId === "pages" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mt="1rem"
            >
              <Icon.Document strokeWidth="2px" size="medium" stroke="black" />
            </Flex>
          </Tippy>
        </Link>

        {true && (
          <Link to="/files">
            <Tippy content={t("Files")} placement="right">
              <Flex
                bg={moduleId === "files" ? "l0" : "transparent"}
                p="0.5rem"
                borderRadius="0.5rem"
                hoverColor="l4"
                mt="1rem"
              >
                <Icon.Folder strokeWidth="2px" size="medium" stroke="black" />
              </Flex>
            </Tippy>
          </Link>
        )}

        <Tippy content={t("Refresh all data")} placement="right">
          <Flex
            p="0.5rem"
            borderRadius="0.5rem"
            hoverColor="l4"
            mt="3rem"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                ACTIONS.state.setAttribute("app", "loadedResources", [])
              );
              dispatch(
                ACTIONS.state.setAttribute("app", "shouldRefresh", true)
              );
            }}
          >
            <Icon.Refresh
              strokeWidth="2px"
              size="medium"
              stroke={moduleId === "tasks" ? "black" : " black"}
            />
          </Flex>
        </Tippy>

        {(MODE === "DEV" || user.id === 2) && (
          <>
            <Flex
              bg={moduleId === "dashboard" ? "pink" : "l2"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mb="1rem"
              mt="auto"
            >
              <Link to={`/dashboard`}>
                <Icon.Graph
                  strokeWidth="2px"
                  size="medium"
                  stroke={moduleId === "dashboard" ? "l0" : " black"}
                />
              </Link>
            </Flex>

            <Flex
              bg={moduleId === "admin" ? "pink" : "l2"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mb="1rem"
            >
              <Link to="/admin">
                <Icon.Config
                  strokeWidth="2px"
                  size="medium"
                  stroke={moduleId === "admin" ? "l0" : " black"}
                />
              </Link>
            </Flex>
          </>
        )}

        {false && (
          <Flex mb="1rem">
            <Link to={`/settingss`}>
              <Icon.Config size="medium" />
            </Link>
          </Flex>
        )}
      </Flex>

      {false && (
        <Flex alignItems="center" flexDirection="column">
          <Tippy content={t("Logout")} placement="right">
            <Flex>
              <Icon.Off
                onClick={doLogout}
                style={{ cursor: "pointer" }}
                size="medium"
              />
            </Flex>
          </Tippy>
        </Flex>
      )}

      <Tippy
        content={<ProfileMenu />}
        // trigger="click"
        color="l0"
        borderRadius="0.75rem"
        placement="left-end"
        interactive={true}
      >
        <Flex mb="2rem">
          <User />
        </Flex>
      </Tippy>
    </Flex>
  );
};

const MenuItemHorizontal = ({ item, moduleId, count }) => {
  const { t } = useTranslation();

  const active = moduleId === item.id;
  const RenderIcon = Icon[item.icon];

  return (
    <Link to={item.linkTo}>
      <Flex
        ml="0.5rem"
        alignItems="center"
        enableHover={true}
        borderRadius="0.25rem"
        mx="0.5rem"
        px="0.5rem"
        py="0.25rem"
        hoverColor="l2"
        position="relative"
      >
        {false && (
          <RenderIcon
            strokeWidth="2px"
            size="medium"
            stroke={active ? "white" : "#595959"}
          />
        )}
        <Text
          fontSize="0.9375rem"
          fontWeight="bold"
          color={active ? "t1" : "t4"}
        >
          {t(item.label)}
        </Text>

        {count && count !== 0 ? (
          <Flex
            // border="2px solid white"
            width="0.675rem"
            height="0.675rem"
            bg="red"
            borderRadius="50%"
            position="absolute"
            right={-4}
            top="-2px"
            count={count}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Link>
  );
};

const HorizontalMenu = ({ moduleId, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const updates = useSelector((state) => state["updates"].updates);
  const conversations = useSelector(
    (state) => state["conversations"].conversations
  );

  const [spaceId, setSpaceId] = useLocalStorage("spaceId", 1);

  const doLogout = () => {
    dispatch(
      ACTIONS.fetch.get(
        "account",
        `${ROOT_URL}/logout/`,
        ACTIONS.account.logout
      )
    );
  };

  const match = useRouteMatch("/space/:spaceId");
  let id = spaceId;
  if (match) {
    id = match.params.spaceId;
  }

  useEffect(() => {
    if (id !== spaceId) setSpaceId(id);
  }, [id, spaceId, setSpaceId]);

  Favicon();

  let unreadTotal = 0;

  Object.values(conversations).forEach((conversation) => {
    let userId = conversation.last_message_from_id;
    if (
      Object.keys(conversation.messages).length &&
      conversation.last_message_timestamp >
        conversation.last_message_timestamp_read_by_you &&
      users[userId] &&
      userId !== user.id &&
      !users[userId].is_deleted &&
      users[userId].is_active
    ) {
      unreadTotal += 1;
    }
  });

  const count = {
    conversations: unreadTotal,
    updates: updates && Object.keys(updates).length
  };

  return (
    <Flex
      px="1.5rem"
      // pt="0.5rem"
      mx="auto"
      width={1}
      // maxWidth="90rem"
      alignItems="center"
      justifyContent="space-between"
      height="3rem"
      minHeight="3rem"
      bg="l0"
      boxShadow="0px 0px 6px 0px rgba(0,0,0,0.22)"
      zIndex={99}
    >
      <Flex overflow="auto" width="100%">
        <Link to="/">
          <Image
            width={36}
            src="/images/logo/logo.svg"
            style={{ cursor: "pointer" }}
          />
        </Link>

        <Flex alignItems="center">
          {MENU.filter((item) => item.enabled).map((item, index) => (
            <MenuItemHorizontal
              key={item.id}
              item={item}
              moduleId={moduleId}
              count={count[item.id]}
            />
          ))}
          <Presence />
        </Flex>

        <Flex alignItems="center">
          {false && (MODE === "DEV" || user.id === 2) && (
            <ChangeTheme mx="auto" />
          )}

          <Tippy content={t("Refresh all data")}>
            <Flex
              ml="1rem"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(
                  ACTIONS.state.setAttribute("app", "loadedResources", [])
                );
                dispatch(
                  ACTIONS.state.setAttribute("app", "shouldRefresh", true)
                );
              }}
            >
              <Icon.Refresh
                strokeWidth="2px"
                size="16px"
                stroke={moduleId === "tasks" ? "black" : " black"}
              />
            </Flex>
          </Tippy>

          {(MODE === "DEV" || user.id === 2) && (
            <Link to="/admin">
              <Icon.Config
                ml="1rem"
                strokeWidth="2px"
                size="medium"
                stroke="black"
              />
            </Link>
          )}

          {false && (
            <Flex alignItems="center" flexDirection="column">
              <Tippy content={t("Logout")} placement="right">
                <Flex>
                  <Icon.Off
                    onClick={doLogout}
                    style={{ cursor: "pointer" }}
                    size="medium"
                  />
                </Flex>
              </Tippy>
            </Flex>
          )}

          <Tippy
            content={<ProfileMenu />}
            // trigger="click"
            color="l0"
            borderRadius="0.75rem"
            // placement="left-end"
            placement="bottom"
            interactive={true}
            theme="light"
          >
            <Flex ml="1rem">
              <User />
            </Flex>
          </Tippy>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Default = ({ View, ...props }) => {
  const verticalMenu = false;

  return (
    <Flex
      bg="l2"
      width={1}
      height="100%"
      overflow="auto"
      flexDirection={verticalMenu ? "row" : "column"}
    >
      {verticalMenu ? <Menu {...props} /> : <HorizontalMenu {...props} />}

      <Flex
        width="100%"
        height="100%"
        overflow="auto"
        // px="2rem"
        justifyContent="center"
        // mt="0.5rem"
      >
        <Flex
          // bg="l0"
          // borderTopLeftRadius="0.5rem"
          // borderTopRightRadius="0.5rem"
          flexDirection="row"
          width={1}
          height="100%"
          minWidth="0px" // evita task expandir parent
          overflow="auto"
          maxWidth={1440}
          mx="auto"
          // px="0.5rem"
          // boxShadow="0px 0px 8px 0px rgba(0,0,0,0.12)"
        >
          <View />
        </Flex>
      </Flex>
    </Flex>
  );
};
