import React from "react";

import { Flex, Icon, Image } from "../";
import { SIZES } from "../../constants";

export const Avatar = ({ online, size, src, showOnline = false, ...props }) => {
  let sizeAvatar = size ? SIZES[size] : SIZES.medium;

  if (!src)
    return (
      <Icon.User
        borderRadius="50%"
        border="1px solid lightGrey"
        {...sizeAvatar}
      />
    );

  return (
    <Flex
      width="fit-content"
      height="fit-content"
      position="relative"
      m={props.m}
      p={props.p}
      ml={props.ml}
      mr={props.mr}
      {...sizeAvatar}
    >
      {showOnline && (
        <Flex
          position="absolute"
          right="-15%"
          bottom={-2}
          width="40%"
          height="40%"
          bg={online ? "green" : "l4"}
          borderRadius="50%"
          border="2px solid white"
        />
      )}

      <Image borderRadius="50%" width="100%" height="100%" {...size} src={src}  />
    </Flex>
  );
};
