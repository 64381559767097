import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  ClickToEdit,
  DatePicker,
  Dropdown,
  Flex,
  Icon,
  Switch,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { ShortAnswer, LongAnswer } from "./Answer";
import { QUESTION_TYPE, QUESTION_OPTIONS } from "constant";

const Drop = ({ item }) => {
  const changeDropdown = () => {};

  console.log(item.questions);

  return (
    <Flex flexDirection="column">
      <Dropdown
        enableClear={true}
        options={item.questions}
        onSelect={changeDropdown}
        // selected={{
        //   id: item.questionTypeId,
        //   value: QUESTION_OPTIONS[item.questionTypeId]
        // }}
      />
    </Flex>
  );
};

const Checkbox = ({ item }) => {
  return (
    <Flex flexDirection="column">
      {item.questions.map((question) => (
        <Flex alignItems="center" mb="1rem">
          <Flex
            width="1rem"
            height="1rem"
            borderRadius="2rem"
            border="1px solid grey"
            mr="0.5rem"
          />
          <Text>{question.value}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

const Date = () => {
  return <Icon.Calendar />;
  return (
    <DatePicker
      width={280}
      // startDate={task.start_date}
      // saveDate={setDate}
      // setOpen={setShowCalendar}
    />
  );
};

const MultipleChoices = ({ item }) => {
  return (
    <Flex flexDirection="column">
      {item.questions.map((question) => (
        <Flex alignItems="center" mb="1rem">
          <Flex
            width="1rem"
            height="1rem"
            borderRadius="0.25rem"
            border="1px solid grey"
            mr="0.5rem"
          />
          <Text>{question.value}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

export const Question = ({ itemIndex, pageIndex, isEditing, item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateItem = (key, value) => {
    dispatch(ACTIONS.forms.updateItem(itemIndex, pageIndex, key, value));
  };

  const options = Object.keys(QUESTION_OPTIONS).map((id) => {
    return { id, value: QUESTION_OPTIONS[id] };
  });

  return (
    <>
      <Flex mb="1rem" alignItems="center">
        <ClickToEdit
          fontWeight="bold"
          color="t1"
          fontSize="1rem"
          enabled={true}
          value={item.title}
          placeholder={t("Enter title...")}
          onBlur={(title) => updateItem("title", title)}
        />
        {item.required && (
          <Text
            fontWeight="semibold"
            color="red"
            fontSize="0.75rem"
            ml="0.25rem"
          >
            ({t("required")})
          </Text>
        )}
      </Flex>

      {item.showDescription && <Text mb="1rem">{item.description}</Text>}

      {item.questionTypeId === QUESTION_TYPE.SHORT_ANSWER && (
        <ShortAnswer item={item}>1</ShortAnswer>
      )}
      {item.questionTypeId === QUESTION_TYPE.LONG_ANSWER && (
        <LongAnswer item={item} />
      )}
      {item.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICES && (
        <MultipleChoices item={item} />
      )}
      {item.questionTypeId === QUESTION_TYPE.CHECKBOX && (
        <Checkbox item={item} />
      )}
      {item.questionTypeId === QUESTION_TYPE.DROPDOWN && <Drop item={item} />}
      {item.questionTypeId === QUESTION_TYPE.DATE && <Date item={item} />}

      {isEditing && (
        <Flex alignItems="center" mt="0.5rem">
          <Dropdown
            options={options}
            onSelect={(type) => updateItem("questionTypeId", parseInt(type.id))}
            selected={{
              id: item.questionTypeId,
              value: QUESTION_OPTIONS[item.questionTypeId]
            }}
          />
          <Switch
            ml="2rem"
            checked={item.required}
            text={t("Required")}
            onCheck={(value) => updateItem("required", value)}
          />
          <Switch
            ml="2rem"
            checked={item.shuffleOrder}
            text={t("Shuffle order")}
            onCheck={(value) => updateItem("shuffleOrder", value)}
          />
          <Switch
            ml="2rem"
            checked={item.showDescription}
            text={t("Show description")}
            onCheck={(value) => updateItem("showDescription", value)}
          />
        </Flex>
      )}
    </>
  );
};
