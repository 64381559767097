import Activity from "./Svg/Activity";
import Add from "./Svg/Add";
import Admin from "./Svg/Admin";
import Alert from "./Svg/Alert";
import AlertCircle from "./Svg/AlertCircle";
import AlignLeft from "./Svg/AlignLeft";
import Apps from "./Svg/Apps";
import Arrow from "./Svg/Arrow";
import Archive from "./Svg/Archive";
import Automation from "./Svg/Automation";
import Billing from "./Svg/Billing";
import Board from "./Svg/Board";
import BoardSales from "./Svg/BoardSales";
import Bell from "./Svg/Bell";
import BellOff from "./Svg/BellOff";
import Bold from "./Svg/Bold";
import Box from "./Svg/Box";
import Building from "./Svg/Building";
import Button from "./Svg/Button";
import Calendar from "./Svg/Calendar";
import Cart from "./Svg/Cart";
import Checkmark from "./Svg/Checkmark";
import ChevronDown from "./Svg/ChevronDown";
import ChevronLeft from "./Svg/ChevronLeft";
import ChevronRight from "./Svg/ChevronRight";
import ChevronUp from "./Svg/ChevronUp";
import ChevronUpDown from "./Svg/ChevronUpDown";
import Circle from "./Svg/Circle";
import Clock from "./Svg/Clock";
import Clone from "./Svg/Clone";
import Close from "./Svg/Close";
import Code from "./Svg/Code";
import CornerDownRight from "./Svg/CornerDownRight";
import CornerUpLeft from "./Svg/CornerUpLeft";
import Company from "./Svg/Company";
import Condition from "./Svg/Condition";
import Config from "./Svg/Config";
import Dashboard from "./Svg/Dashboard";
import Desktop from "./Svg/Desktop";
import Document from "./Svg/Document";
import Domain from "./Svg/Domain";
import Down from "./Svg/Down";
import Download from "./Svg/Download";
import DragHandle from "./Svg/DragHandle";
import Edit from "./Svg/Edit";
import Email from "./Svg/Email";
import Error from "./Svg/Error";
import ExternalLink from "./Svg/ExternalLink";
import Eye from "./Svg/Eye";
import EyeOff from "./Svg/EyeOff";
import File from "./Svg/File";
import FileText from "./Svg/FileText";
import Filter from "./Svg/Filter";
import Flag from "./Svg/Flag";
import Folder from "./Svg/Folder";
import FolderPlus from "./Svg/FolderPlus";
import Form from "./Svg/Form";
import Grid from "./Svg/Grid";
import Graph from "./Svg/Graph";
import Hash from "./Svg/Hash";
import History from "./Svg/History";
import Home from "./Svg/Home";
import Italic from "./Svg/Italic";
import Image from "./Svg/Image";
import Inbox from "./Svg/Inbox";
import Info from "./Svg/Info";
import Layers from "./Svg/Layers";
import Left from "./Svg/Left";
import LineSpaceLarge from "./Svg/LineSpaceLarge";
import LineSpaceMedium from "./Svg/LineSpaceMedium";
import LineSpaceSmall from "./Svg/LineSpaceSmall";
import Link from "./Svg/Link";
import List from "./Svg/List";
import ListClosed from "./Svg/ListClosed";
import ListOpen from "./Svg/ListOpen";
import Logo from "./Svg/Logo";
import Lock from "./Svg/Lock";
import Menu from "./Svg/Menu";
import MessageBoard from "./Svg/MessageBoard";
import MessageCircle from "./Svg/MessageCircle";
import MessageSquare from "./Svg/MessageSquare";
import Mic from "./Svg/Mic";
import Mobile from "./Svg/Mobile";
import MoreVertical from "./Svg/MoreVertical";
import MoreHorizontal from "./Svg/MoreHorizontal";
import Moon from "./Svg/Moon";
import Move from "./Svg/Move";
import Note from "./Svg/Note";
import NumberedList from "./Svg/NumberedList";
import Off from "./Svg/Off";
import OrderDown from "./Svg/OrderDown";
import OrderUp from "./Svg/OrderUp";
import Ok from "./Svg/Ok";
import Paperclip from "./Svg/Paperclip";
import Pause from "./Svg/Pause";
import Pen from "./Svg/Pen";
import Pin from "./Svg/Pin";
import Play from "./Svg/Play";
import Preview from "./Svg/Preview";
import Project from "./Svg/Project";
import Plus from "./Svg/Plus";
import Question from "./Svg/Question";
import Quote from "./Svg/Quote";
import Redo from "./Svg/Redo";
import Refresh from "./Svg/Refresh";
import Reply from "./Svg/Reply";
import ReturnLeft from "./Svg/ReturnLeft";
import Repeat from "./Svg/Repeat";
import Save from "./Svg/Save";
import Search from "./Svg/Search";
import Send from "./Svg/Send";
import Settings from "./Svg/Settings";
import Slash from "./Svg/Slash";
import Smile from "./Svg/Smile";
import Square from "./Svg/Square";
import Spacer from "./Svg/Spacer";
import Spaces from "./Svg/Spaces";
import Star from "./Svg/Star";
import Stop from "./Svg/Stop";
import Strike from "./Svg/Strike";
import Sun from "./Svg/Sun";
import Tag from "./Svg/Tag";
import Task from "./Svg/Task";
import Text from "./Svg/Text";
import ThumbsUp from "./Svg/ThumbsUp";
import ThumbsDown from "./Svg/ThumbsDown";
import Tracker from "./Svg/Tracker";
import Trash from "./Svg/Trash";
import Triangle from "./Svg/Triangle";
import Underline from "./Svg/Underline";
import Undo from "./Svg/Undo";
import Unlock from "./Svg/Unlock";
import Up from "./Svg/Up";
import Upload from "./Svg/Upload";
import UploadToCloud from "./Svg/UploadToCloud";
import User from "./Svg/User";
import Users from "./Svg/Users";
import Video from "./Svg/Video";
import Volume from "./Svg/Volume";
import Volume2 from "./Svg/Volume2";
import Warning from "./Svg/Warning";
import Webhook from "./Svg/Webhook";
import WhatsApp from "./Svg/WhatsApp";
import ZoomIn from "./Svg/ZoomIn";
import ZoomOut from "./Svg/ZoomOut";

export const Icon = {};

Icon.Activity = Activity;
Icon.Add = Add;
Icon.Admin = Admin;
Icon.Archive = Archive;
Icon.Alert = Alert;
Icon.AlertCircle = AlertCircle;
Icon.AlignLeft = AlignLeft;
Icon.Apps = Apps;
Icon.Arrow = Arrow;
Icon.Automation = Automation;
Icon.Bell = Bell;
Icon.BellOff = BellOff;
Icon.Billing = Billing;
Icon.Board = Board;
Icon.BoardSales = BoardSales;
Icon.Bold = Bold;
Icon.Box = Box;
Icon.Building = Building;
Icon.Button = Button;
Icon.Cart = Cart;
Icon.Calendar = Calendar;
Icon.Checkmark = Checkmark;
Icon.Code = Code;
Icon.Company = Company;
Icon.Condition = Condition;
Icon.ChevronDown = ChevronDown;
Icon.ChevronLeft = ChevronLeft;
Icon.ChevronRight = ChevronRight;
Icon.ChevronUp = ChevronUp;
Icon.ChevronUpDown = ChevronUpDown;
Icon.Circle = Circle;
Icon.Clock = Clock;
Icon.Clone = Clone;
Icon.Close = Close;
Icon.Config = Config;
Icon.CornerDownRight = CornerDownRight;
Icon.CornerUpLeft = CornerUpLeft;
Icon.Dashboard = Dashboard;
Icon.Desktop = Desktop;
Icon.Domain = Domain;
Icon.Document = Document;
Icon.Down = Down;
Icon.Download = Download;
Icon.DragHandle = DragHandle;
Icon.Edit = Edit;
Icon.Email = Email;
Icon.Error = Error;
Icon.ExternalLink = ExternalLink;
Icon.Eye = Eye;
Icon.EyeOff = EyeOff;
Icon.File = File;
Icon.FileText = FileText;
Icon.Filter = Filter;
Icon.Flag = Flag;
Icon.Folder = Folder;
Icon.FolderPlus = FolderPlus;
Icon.Form = Form;
Icon.Graph = Graph;
Icon.Grid = Grid;
Icon.Hash = Hash;
Icon.History = History;
Icon.Home = Home;
Icon.Image = Image;
Icon.Inbox = Inbox;
Icon.Info = Info;
Icon.Italic = Italic;
Icon.Layers = Layers;
Icon.Left = Left;
Icon.LineSpaceLarge = LineSpaceLarge;
Icon.LineSpaceMedium = LineSpaceMedium;
Icon.LineSpaceSmall = LineSpaceSmall;
Icon.List = List;
Icon.ListClosed = ListClosed;
Icon.ListOpen = ListOpen;
Icon.Link = Link;
Icon.Lock = Lock;
Icon.Logo = Logo;
Icon.Menu = Menu;
Icon.MessageBoard = MessageBoard;
Icon.MessageCircle = MessageCircle;
Icon.MessageSquare = MessageSquare;
Icon.Mic = Mic;
Icon.Mobile = Mobile;
Icon.Moon = Moon;
Icon.MoreHorizontal = MoreHorizontal;
Icon.MoreVertical = MoreVertical;
Icon.Move = Move;
Icon.Note = Note;
Icon.NumberedList = NumberedList;
Icon.Off = Off;
Icon.OrderDown = OrderDown;
Icon.OrderUp = OrderUp;
Icon.Ok = Ok;
Icon.Paperclip = Paperclip;
Icon.Pause = Pause;
Icon.Pen = Pen;
Icon.Pin = Pin;
Icon.Plus = Plus;
Icon.Play = Play;
Icon.Preview = Preview;
Icon.Project = Project;
Icon.Question = Question;
Icon.Quote = Quote;
Icon.Redo = Redo;
Icon.Refresh = Refresh;
Icon.ReturnLeft = ReturnLeft;
Icon.Repeat = Repeat;
Icon.Reply = Reply;
Icon.Save = Save;
Icon.Search = Search;
Icon.Send = Send;
Icon.Settings = Settings;
Icon.Slash = Slash;
Icon.Smile = Smile;
Icon.Square = Square;
Icon.Spacer = Spacer;
Icon.Spaces = Spaces;
Icon.Star = Star;
Icon.Stop = Stop;
Icon.Strike = Strike;
Icon.Success = Ok;
Icon.Sun = Sun;
Icon.Tag = Tag;
Icon.Task = Task;
Icon.Text = Text;
Icon.ThumbsUp = ThumbsUp;
Icon.ThumbsDown = ThumbsDown;
Icon.Tracker = Tracker;
Icon.Trash = Trash;
Icon.Triangle = Triangle;
Icon.Underline = Underline;
Icon.Undo = Undo;
Icon.Unlock = Unlock;
Icon.Up = Up;
Icon.Upload = Upload;
Icon.UploadToCloud = UploadToCloud;
Icon.User = User;
Icon.Users = Users;
Icon.Video = Video;
Icon.Volume = Volume;
Icon.Volume2 = Volume2;
Icon.Warning = Warning;
Icon.Webhook = Webhook;
Icon.WhatsApp = WhatsApp;
Icon.ZoomIn = ZoomIn;
Icon.ZoomOut = ZoomOut;
