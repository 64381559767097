import React from "react";
import styled, { css, keyframes } from "styled-components";

import { Flex, Text } from "tomato";

const mixin = ({ property, content }) => {
  return `-webkit-${property}: ${content} 
      -moz-${property}: ${content} 
      -o-${property}: ${content} 
      -ms-${property}: ${content} 
      ${property}: ${content}`;
};

const transform = mixin({
  name: "transform",
  property: "transform",
  content: "scale(5);"
});

const scale = keyframes`
{
  to {
    ${transform};
    opacity: 0;
  }
}
`;

const animation = (props) => css`0.5s ${scale} ease-in 1 backwards `;

const Animate = styled(Flex)`
  position: absolute;
  animation: ${animation};
`;

export const AnimatedText = ({ content = "content", ...props }) => {
  return (
    <Animate {...props}>
      <Text fontSize="0.5rem">{content}</Text>
    </Animate>
  );
};
