import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Flex, formatDate, Text } from "tomato";

import { ACTIONS } from "store";
import { Dots } from "./Input";
import { PulsingDot } from "components";

export const User = ({ user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );

  const locale = useSelector((state) => state["account"].user.locale);
  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const timezone = useSelector((state) => state["account"].user.timezone);
  const typing = useSelector((state) => state["conversations"].typing);
  const userId = useSelector((state) => state["account"].user.id);
  const users = useSelector((state) => state["spaces"].users);

  let acc =
    user.id < userId ? `D.${user.id}.${userId}` : `D.${userId}.${user.id}`;

  const [isTyping, setIsTyping] = useState(false);
  const timer = useRef();

  useEffect(() => {
    if (!typing || typing.acc[0] !== "D") return;
    if (typing.fromId !== parseInt(user.id)) return;

    setIsTyping(true);
    timer.current = window.setTimeout(() => {
      window.clearTimeout(timer);
      setIsTyping(false);
    }, 6000);
  }, [activeConversation, typing, user.id]);

  const conversation = useSelector(
    (state) => state["conversations"].conversations[acc]
  );

  let active = activeConversation === acc;

  let unreadMessages =
    !active && conversation && Object.keys(conversation.messages).length
      ? conversation.last_message_timestamp >
        conversation.last_message_timestamp_read_by_you
      : false;

  const startConversation = () => {
    dispatch(
      ACTIONS.events.send("Start dm conversation", {
        acc,
        fromId: userId,
        toId: user.id
      })
    );

    dispatch(
      ACTIONS.conversations.startConversation({
        acc,
        toId: user.id,
        type: "D"
      })
    );
  };

  let statusMessage = user.status_message || t("I'm here");

  let online = onlineUsersIds.includes(user.id);

  if (!online && user.last_interaction_date) {
    statusMessage = formatDate(
      user.last_interaction_date,
      locale,
      timezone
    ).distance;
  } else if (online) {
    if (users[user.id].status) {
      statusMessage = users[user.id].status.message;
    } else {
      statusMessage = t("Online");
    }
  } else if (!user.last_interaction_date) {
    statusMessage = t("Never seen");
  }

  let last_message = conversation && conversation.last_message_summary;

  return (
    <Flex
      borderRadius="0.25rem"
      hoverColor="l3"
      bg={active ? "l3" : ""}
      style={{ cursor: "pointer" }}
      onClick={startConversation}
      alignItems="center"
      my="0.125rem"
      pl="0.5rem"
      pr="0.25rem"
      py="0.25rem"
      label="userConversation"
    >
      <Flex width="80%" alignItems="center">
        <Avatar
          showOnline={true}
          online={online}
          size="large"
          border="2px solid white"
          src={user.avatar}
          boxShadow="0px 0px 4px 0px hsla(240, 0%, 70%, 1)"
        />

        <Text
          ml="0.5rem"
          fontWeight="semibold"
          // fontSize="0.925rem"
          color={active ? "t1" : "t3"}
          // fontWeight={active ? "bold" : "semibold"}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          {user.nickname}
        </Text>
      </Flex>

      {false && isTyping && (
        <Flex alignItems="center">
          <Text color="green" fontSize="0.75rem" fontWeight="bold" mr="0.25rem">
            {t("typing")}
          </Text>
          <Dots />
          <Dots />
          <Dots />
        </Flex>
      )}

      {unreadMessages && <PulsingDot mr={0} ml="auto" />}
    </Flex>
  );
};
