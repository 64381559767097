import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";

import { GlobalStyle } from "../GlobalStyle";
import { LoadingBar } from "../LoadingBar";
import { NetworkActivity } from "../NetworkActivity";
import { THEMES } from "../../themes";
import { Toast } from "../Toast";

const Context = React.createContext({
  color: { h: 240, s: 10 },
  locale: "pt-BR",
  tz: "America/Sao_Paulo",
  theme: "light"
});

export const useProvider = () => useContext(Context);

const ThemeWrapper = ({ children }) => {
  let context = useProvider();
  let theme = THEMES(context.theme, context.color.h, context.color.s + "%");

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const Provider = ({ children, ...props }) => {
  let previousContext = useProvider();

  let context = { ...previousContext, ...props };

  return (
    <Context.Provider value={context}>
      <GlobalStyle />
      <ThemeWrapper>
        <LoadingBar />
        {children}
        <Toast />
      </ThemeWrapper>
    </Context.Provider>
  );
};
