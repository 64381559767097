import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Confirm,
  DateTimePicker,
  Dialog,
  Flex,
  formatDate,
  Icon,
  Input,
  Options,
  orderObjects,
  Text,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";

export const Reminder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notify = useSelector((state) => state["reminders"].notify);
  const user = useSelector((state) => state["account"].user);
  const ws = useSelector((state) => state["ws"].ws);

  const addReminder = (reminder) => {
    ws.send(
      JSON.stringify({
        type: "reminders",
        action: "add",
        data: reminder
      })
    );
  };

  const handleExpired = (close, reminder) => {
    if (close) {
      deleteExpired(reminder);
    } else {
      // Snooze padrão de 9 minutos
      addReminder({
        ...reminder,
        expireDate: Date.now() + 540000
      });
      deleteExpired(reminder);
    }
  };

  const deleteExpired = (reminder) => {
    dispatch(ACTIONS.reminders.delete({ uuid: reminder.uuid }));
  };

  let reminder = notify.length !== 0 ? notify[0] : null;
  if (!reminder) return null;

  return (
    <Confirm
      open={notify.length !== 0}
      setOpen={() => handleExpired(false, reminder)}
      onConfirm={() => handleExpired(true, reminder)}
      title={t("Reminder")}
      yesText="Ok"
      noText={t("Snooze")}
      blocked={true}
    >
      <Flex flexDirection="column" width={1} alignItems="center">
        <Text textStyle="subtitle">{reminder.title}</Text>

        <Text mt="0.5rem">
          {formatDate(reminder.expireDate, user.locale, user.timezone).distance}
        </Text>
      </Flex>
    </Confirm>
  );
};

export const Reminders = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reminders = useSelector((state) => state["reminders"].reminders);
  const user = useSelector((state) => state["account"].user);
  const ws = useSelector((state) => state["ws"].ws);

  const [instance, setInstance] = useState();
  const [title, setTitle] = useState();

  const [date, setDate] = useState();
  const [timeout, setTimeout] = useState({
    id: 10,
    value: "10 " + t("minutes")
  });

  useEffect(() => {
    // dispatch(ACTIONS.events.send("Reminder view", {}));
  }, [dispatch]);

  useEffect(() => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        type: "reminders",
        action: "list"
      })
    );
  }, [ws]);

  const addReminder = () => {
    if (!title) return;

    let uuid = uuidv4();
    let expireDate;

    if (timeout) {
      expireDate = Date.now();
      expireDate += parseInt(timeout.id) * 60 * 1000;
    }

    if (date) expireDate = date.getTime();

    ws.send(
      JSON.stringify({
        type: "reminders",
        action: "add",
        data: {
          uuid,
          title,
          expireDate,
          userId: user.id
        }
      })
    );
    setTitle("");

    ACTIONS.events.send("Added reminder", {
      title,
      userId: user.id
    });
  };

  const options = [
    {
      id: 1,
      value: "1 " + t("minute")
    },
    {
      id: 5,
      value: "5 " + t("minutes")
    },
    {
      id: 10,
      value: "10 " + t("minutes")
    },
    {
      id: 15,
      value: "15 " + t("minutes")
    },
    {
      id: 60,
      value: "1 " + t("hour")
    }
  ];

  const deleteReminder = (reminder) => {
    ws.send(
      JSON.stringify({
        type: "reminders",
        action: "delete",
        data: {
          ...reminder
        }
      })
    );
  };

  const update = () => {};

  let style = { disabled: "disabled" };
  if (title) style = {};

  const onSelect = (startDate, endDate) => {
    setDate(startDate);
    setTimeout(null);
    instance.hide();
  };

  return (
    <Dialog open={open} setOpen={setOpen} title={t("New reminder")}>
      <Flex flexDirection="column" height="100%" overflow="auto">
        <Input
          placeholder={t("Type a new reminder")}
          focus={true}
          clearOnEnter={true}
          onChange={setTitle}
          onEsc={() => setOpen(false)}
          onEnter={addReminder}
          enableClear={true}
        />

        <Flex alignItems="center" mt="1.5rem" mb="1rem" mx="auto">
          <Options
            options={options}
            selected={timeout}
            onSelect={(timeout) => {
              setTimeout(timeout);
              setDate();
            }}
          />
          <Tippy
            placement="right-start"
            trigger="click"
            interactive={true}
            onCreate={setInstance}
            offset={[0, 4]}
            render={(attrs) => (
              <>
                {instance || true ? (
                  <Flex
                    bg="l0"
                    p="2rem"
                    borderRadius="0.5rem"
                    flexDirection="column"
                    boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
                  >
                    <DateTimePicker
                      onSelect={onSelect}
                      onCancel={() => instance.hide()}
                      blockPast={true}
                    />
                  </Flex>
                ) : (
                  ""
                )}
              </>
            )}
          >
            <Flex
              ml="1rem"
              borderRadius="2rem"
              px="1rem"
              py="0.375rem"
              hoverColor="lightBlue"
              bg={date ? "l2" : "l0"}
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <Icon.Calendar
                mr={date ? "0.25rem" : "0px"}
                style={{ cursor: "pointer" }}
                stroke="black"
              />

              {date && formatDate(date, user.locale, user.timezone).distance}
            </Flex>
          </Tippy>
        </Flex>

        <Flex flexDirection="column" height="10rem" mb="1rem" overflow="auto">
          {orderObjects(reminders, "expireDate", "asc").map((reminder) => (
            <Flex
              key={reminder.uuid}
              width={1}
              borderRadius="0.375rem"
              hoverColor="l2"
              px="1rem"
              py="0.5rem"
            >
              <Text width="10rem">
                {
                  formatDate(reminder.expireDate, user.locale, user.timezone)
                    .distance
                }
              </Text>
              <Text ml="1rem">{reminder.title}</Text>
              <Icon.Trash
                ml="auto"
                mr={0}
                style={{ cursor: "pointer" }}
                onClick={() => deleteReminder(reminder)}
              />
            </Flex>
          ))}

          {reminders.length === 0 && (
            <Text m="auto" fontSize="1rem">
              {t("You don't have any reminder set")}
            </Text>
          )}
        </Flex>

        <Flex justifyContent="flex-end" mb={0} mt="auto">
          <Button
            variant="secondary"
            onClick={() => setOpen(false)}
            mr="1rem"
            value={t("Cancel")}
          />

          <Button
            variant="primary"
            px="1rem"
            onEnter={addReminder}
            onEsc={() => setOpen(false)}
            onClick={addReminder}
            {...style}
            value={t("Create reminder")}
          />
        </Flex>
      </Flex>
    </Dialog>
  );
};
