import { t } from "i18n";

import { uuidv4 } from "tomato";

export const DEFAULT_FORM = {
  uuid: null,
  name: t("Untitled form"),
  settings: {
    color: { h: 270, s: 50, l: 95 }
  },
  pages: [
    {
      title: t("Untitled page"),
      description: null,
      image: null,
      nextPage: null,
      items: []
    }
  ]
};

export const QUESTION_TYPE = {
  SHORT_ANSWER: 1,
  LONG_ANSWER: 2,
  MULTIPLE_CHOICES: 3,
  CHECKBOX: 4,
  DROPDOWN: 5,
  LINEAR_SCALE: 6,
  MULTIPLE_CHOICES_GRID: 7,
  CHECKBOX_GRID: 8,
  DATE: 9,
  TIME: 10
};

export const ITEM_TYPE = {
  QUESTION: 1,
  TITLE: 2,
  IMAGE: 3,
  VIDEO: 4,
  PAGE: 5
};

export const QUESTION_OPTIONS = {
  1: t("Short answer"),
  2: t("Long answer"),
  3: t("Multiple choices"),
  4: t("Checkbox"),
  5: t("Dropdown"),
  6: t("Linear scale"),
  7: t("Multiple choices grid"),
  8: t("Checkbox grid"),
  9: t("Date"),
  10: t("Time")
};

const PAGE_ITEM_TYPE = {
  1: "Question",
  TITLE: 2,
  3: "Image",
  4: "Video"
};

export const DEFAULT_ITEMS = {
  1: {
    id: uuidv4(),
    title: "O que vc acha?",
    description: "Description da questao",
    placeholder: "Question",
    typeId: 1,
    questionTypeId: QUESTION_TYPE.CHECKBOX,
    questions: [
      { id: 1, value: "Option 1" },
      { id: 2, value: "teste" }
    ],
    image: "",
    required: false
  },
  2: {
    id: uuidv4(),
    title: "Title",
    description: "Description",
    typeId: PAGE_ITEM_TYPE.TITLE,
    image: ""
  },
  3: {
    id: uuidv4(),
    title: "Title",
    description: "Description",
    typeId: PAGE_ITEM_TYPE.IMAGE,
    image: ""
  },
  4: {
    id: uuidv4(),
    title: "Title",
    description: "Description",
    typeId: PAGE_ITEM_TYPE.VIDEO,
    image: ""
  },
  5: {
    title: "Title",
    description: "Description da secao",
    color: "",
    backgroundColor: "",
    image: "",
    afterPage: "",
    items: []
  }
};
