export const EMOJIS_GROUPS = {
  "smileys_&_emotion": [
    "1f600",
    "1f603",
    "1f604",
    "1f601",
    "1f606",
    "1f605",
    "1f923",
    "1f602",
    "1f642",
    "1f643",
    "1f609",
    "1f60a",
    "1f607",
    "1f970",
    "1f60d",
    "1f929",
    "1f618",
    "1f617",
    "263a",
    "1f61a",
    "1f619",
    "1f972",
    "1f60b",
    "1f61b",
    "1f61c",
    "1f92a",
    "1f61d",
    "1f911",
    "1f917",
    "1f92d",
    "1f92b",
    "1f914",
    "1f910",
    "1f928",
    "1f610",
    "1f611",
    "1f636",
    "1f60f",
    "1f612",
    "1f644",
    "1f62c",
    "1f925",
    "1f60c",
    "1f614",
    "1f62a",
    "1f924",
    "1f634",
    "1f637",
    "1f912",
    "1f915",
    "1f922",
    "1f92e",
    "1f927",
    "1f975",
    "1f976",
    "1f974",
    "1f635",
    "1f92f",
    "1f920",
    "1f973",
    "1f978",
    "1f60e",
    "1f913",
    "1f9d0",
    "1f615",
    "1f61f",
    "1f641",
    "2639",
    "1f62e",
    "1f62f",
    "1f632",
    "1f633",
    "1f97a",
    "1f626",
    "1f627",
    "1f628",
    "1f630",
    "1f625",
    "1f622",
    "1f62d",
    "1f631",
    "1f616",
    "1f623",
    "1f61e",
    "1f613",
    "1f629",
    "1f62b",
    "1f971",
    "1f624",
    "1f621",
    "1f620",
    "1f92c",
    "1f608",
    "1f47f",
    "1f480",
    "2620",
    "1f4a9",
    "1f921",
    "1f479",
    "1f47a",
    "1f47b",
    "1f47d",
    "1f47e",
    "1f916",
    "1f63a",
    "1f638",
    "1f639",
    "1f63b",
    "1f63c",
    "1f63d",
    "1f640",
    "1f63f",
    "1f63e",
    "1f648",
    "1f649",
    "1f64a",
    "1f48b",
    "1f48c",
    "1f498",
    "1f49d",
    "1f496",
    "1f497",
    "1f493",
    "1f49e",
    "1f495",
    "1f49f",
    "2763",
    "1f494",
    "2764",
    "1f9e1",
    "1f49b",
    "1f49a",
    "1f499",
    "1f49c",
    "1f90e",
    "1f5a4",
    "1f90d",
    "1f4af",
    "1f4a2",
    "1f4a5",
    "1f4ab",
    "1f4a6",
    "1f4a8",
    "1f573",
    "1f4a3",
    "1f4ac",
    "1f5e8",
    "1f5ef",
    "1f4ad",
    "1f4a4"
  ],
  "people_&_body": [
    "1f44b",
    "1f91a",
    "1f590",
    "270b",
    "1f596",
    "1f44c",
    "1f90c",
    "1f90f",
    "270c",
    "1f91e",
    "1f91f",
    "1f918",
    "1f919",
    "1f448",
    "1f449",
    "1f446",
    "1f595",
    "1f447",
    "261d",
    "1f44d",
    "1f44e",
    "270a",
    "1f44a",
    "1f91b",
    "1f91c",
    "1f44f",
    "1f64c",
    "1f450",
    "1f932",
    "1f91d",
    "1f64f",
    "270d",
    "1f485",
    "1f933",
    "1f4aa",
    "1f9be",
    "1f9bf",
    "1f9b5",
    "1f9b6",
    "1f442",
    "1f9bb",
    "1f443",
    "1f9e0",
    "1fac0",
    "1fac1",
    "1f9b7",
    "1f9b4",
    "1f440",
    "1f441",
    "1f445",
    "1f444",
    "1f476",
    "1f9d2",
    "1f466",
    "1f467",
    "1f9d1",
    "1f471",
    "1f468",
    "1f9d4",

    "1f468_200d_1f9b0",
    "1f468_200d_1f9b1",
    "1f468_200d_1f9b3",
    "1f468_200d_1f9b2",
    "1f469",
    "1f469_200d_1f9b0",
    "1f9d1_200d_1f9b0",
    "1f469_200d_1f9b1",
    "1f9d1_200d_1f9b1",
    "1f469_200d_1f9b3",
    "1f9d1_200d_1f9b3",
    "1f469_200d_1f9b2",
    "1f9d1_200d_1f9b2",
    "1f471_200d_2640_fe0f",
    "1f471_200d_2642_fe0f",
    "1f9d3",
    "1f474",
    "1f475",
    "1f64d",
    "1f64d_200d_2642_fe0f",
    "1f64d_200d_2640_fe0f",
    "1f64e",
    "1f64e_200d_2642_fe0f",
    "1f64e_200d_2640_fe0f",
    "1f645",
    "1f645_200d_2642_fe0f",
    "1f645_200d_2640_fe0f",
    "1f646",
    "1f646_200d_2642_fe0f",
    "1f646_200d_2640_fe0f",
    "1f481",
    "1f481_200d_2642_fe0f",
    "1f481_200d_2640_fe0f",
    "1f64b",
    "1f64b_200d_2642_fe0f",
    "1f64b_200d_2640_fe0f",
    "1f9cf",
    "1f9cf_200d_2642_fe0f",
    "1f9cf_200d_2640_fe0f",
    "1f647",
    "1f647_200d_2642_fe0f",
    "1f647_200d_2640_fe0f",
    "1f926",
    "1f926_200d_2642_fe0f",
    "1f926_200d_2640_fe0f",
    "1f937",
    "1f937_200d_2642_fe0f",
    "1f937_200d_2640_fe0f",
    "1f9d1_200d_2695_fe0f",
    "1f468_200d_2695_fe0f",
    "1f469_200d_2695_fe0f",
    "1f9d1_200d_1f393",
    "1f468_200d_1f393",
    "1f469_200d_1f393",
    "1f9d1_200d_1f3eb",
    "1f468_200d_1f3eb",
    "1f469_200d_1f3eb",
    "1f9d1_200d_2696_fe0f",
    "1f468_200d_2696_fe0f",
    "1f469_200d_2696_fe0f",
    "1f9d1_200d_1f33e",
    "1f468_200d_1f33e",
    "1f469_200d_1f33e",
    "1f9d1_200d_1f373",
    "1f468_200d_1f373",
    "1f469_200d_1f373",
    "1f9d1_200d_1f527",
    "1f468_200d_1f527",
    "1f469_200d_1f527",
    "1f9d1_200d_1f3ed",
    "1f468_200d_1f3ed",
    "1f469_200d_1f3ed",
    "1f9d1_200d_1f4bc",
    "1f468_200d_1f4bc",
    "1f469_200d_1f4bc",
    "1f9d1_200d_1f52c",
    "1f468_200d_1f52c",
    "1f469_200d_1f52c",
    "1f9d1_200d_1f4bb",
    "1f468_200d_1f4bb",
    "1f469_200d_1f4bb",
    "1f9d1_200d_1f3a4",
    "1f468_200d_1f3a4",
    "1f469_200d_1f3a4",
    "1f9d1_200d_1f3a8",
    "1f468_200d_1f3a8",
    "1f469_200d_1f3a8",
    "1f9d1_200d_2708_fe0f",
    "1f468_200d_2708_fe0f",
    "1f469_200d_2708_fe0f",
    "1f9d1_200d_1f680",
    "1f468_200d_1f680",
    "1f469_200d_1f680",
    "1f9d1_200d_1f692",
    "1f468_200d_1f692",
    "1f469_200d_1f692",
    "1f46e",
    "1f46e_200d_2642_fe0f",
    "1f46e_200d_2640_fe0f",
    "1f575",
    "1f575_fe0f_200d_2642_fe0f",
    "1f575_fe0f_200d_2640_fe0f",
    "1f482",
    "1f482_200d_2642_fe0f",
    "1f482_200d_2640_fe0f",
    "1f977",
    "1f477",
    "1f477_200d_2642_fe0f",
    "1f477_200d_2640_fe0f",
    "1f934",
    "1f478",
    "1f473",
    "1f473_200d_2642_fe0f",
    "1f473_200d_2640_fe0f",
    "1f472",
    "1f9d5",
    "1f935",
    "1f935_200d_2642_fe0f",
    "1f935_200d_2640_fe0f",
    "1f470",
    "1f470_200d_2642_fe0f",
    "1f470_200d_2640_fe0f",
    "1f930",
    "1f931",
    "1f469_200d_1f37c",
    "1f468_200d_1f37c",
    "1f9d1_200d_1f37c",
    "1f47c",
    "1f385",
    "1f936",
    "1f9d1_200d_1f384",
    "1f9b8",
    "1f9b8_200d_2642_fe0f",
    "1f9b8_200d_2640_fe0f",
    "1f9b9",
    "1f9b9_200d_2642_fe0f",
    "1f9b9_200d_2640_fe0f",
    "1f9d9",
    "1f9d9_200d_2642_fe0f",
    "1f9d9_200d_2640_fe0f",
    "1f9da",
    "1f9da_200d_2642_fe0f",
    "1f9da_200d_2640_fe0f",
    "1f9db",
    "1f9db_200d_2642_fe0f",
    "1f9db_200d_2640_fe0f",
    "1f9dc",
    "1f9dc_200d_2642_fe0f",
    "1f9dc_200d_2640_fe0f",
    "1f9dd",
    "1f9dd_200d_2642_fe0f",
    "1f9dd_200d_2640_fe0f",
    "1f9de",
    "1f9de_200d_2642_fe0f",
    "1f9de_200d_2640_fe0f",
    "1f9df",
    "1f9df_200d_2642_fe0f",
    "1f9df_200d_2640_fe0f",
    "1f486",
    "1f486_200d_2642_fe0f",
    "1f486_200d_2640_fe0f",
    "1f487",
    "1f487_200d_2642_fe0f",
    "1f487_200d_2640_fe0f",
    "1f6b6",
    "1f6b6_200d_2642_fe0f",
    "1f6b6_200d_2640_fe0f",
    "1f9cd",
    "1f9cd_200d_2642_fe0f",
    "1f9cd_200d_2640_fe0f",
    "1f9ce",
    "1f9ce_200d_2642_fe0f",
    "1f9ce_200d_2640_fe0f",
    "1f9d1_200d_1f9af",
    "1f468_200d_1f9af",
    "1f469_200d_1f9af",
    "1f9d1_200d_1f9bc",
    "1f468_200d_1f9bc",
    "1f469_200d_1f9bc",
    "1f9d1_200d_1f9bd",
    "1f468_200d_1f9bd",
    "1f469_200d_1f9bd",
    "1f3c3",
    "1f3c3_200d_2642_fe0f",
    "1f3c3_200d_2640_fe0f",
    "1f483",
    "1f57a",
    "1f574",
    "1f46f",
    "1f46f_200d_2642_fe0f",
    "1f46f_200d_2640_fe0f",
    "1f9d6",
    "1f9d6_200d_2642_fe0f",
    "1f9d6_200d_2640_fe0f",
    "1f9d7",
    "1f9d7_200d_2642_fe0f",
    "1f9d7_200d_2640_fe0f",
    "1f93a",
    "1f3c7",
    "26f7",
    "1f3c2",
    "1f3cc",
    "1f3cc_fe0f_200d_2642_fe0f",
    "1f3cc_fe0f_200d_2640_fe0f",
    "1f3c4",
    "1f3c4_200d_2642_fe0f",
    "1f3c4_200d_2640_fe0f",
    "1f6a3",
    "1f6a3_200d_2642_fe0f",
    "1f6a3_200d_2640_fe0f",
    "1f3ca",
    "1f3ca_200d_2642_fe0f",
    "1f3ca_200d_2640_fe0f",
    "26f9",
    "26f9_fe0f_200d_2642_fe0f",
    "26f9_fe0f_200d_2640_fe0f",
    "1f3cb",
    "1f3cb_fe0f_200d_2642_fe0f",
    "1f3cb_fe0f_200d_2640_fe0f",
    "1f6b4",
    "1f6b4_200d_2642_fe0f",
    "1f6b4_200d_2640_fe0f",
    "1f6b5",
    "1f6b5_200d_2642_fe0f",
    "1f6b5_200d_2640_fe0f",
    "1f938",
    "1f938_200d_2642_fe0f",
    "1f938_200d_2640_fe0f",
    "1f93c",
    "1f93c_200d_2642_fe0f",
    "1f93c_200d_2640_fe0f",
    "1f93d",
    "1f93d_200d_2642_fe0f",
    "1f93d_200d_2640_fe0f",
    "1f93e",
    "1f93e_200d_2642_fe0f",
    "1f93e_200d_2640_fe0f",
    "1f939",
    "1f939_200d_2642_fe0f",
    "1f939_200d_2640_fe0f",
    "1f9d8",
    "1f9d8_200d_2642_fe0f",
    "1f9d8_200d_2640_fe0f",
    "1f6c0",
    "1f6cc",
    "1f9d1_200d_1f91d_200d_1f9d1",
    "1f46d",
    "1f46b",
    "1f46c",
    "1f48f",
    "1f469_200d_2764_fe0f_200d_1f48b_200d_1f468",
    "1f468_200d_2764_fe0f_200d_1f48b_200d_1f468",
    "1f469_200d_2764_fe0f_200d_1f48b_200d_1f469",
    "1f491",
    "1f469_200d_2764_fe0f_200d_1f468",
    "1f468_200d_2764_fe0f_200d_1f468",
    "1f469_200d_2764_fe0f_200d_1f469",
    "1f46a",
    "1f468_200d_1f469_200d_1f466",
    "1f468_200d_1f469_200d_1f467",
    "1f468_200d_1f469_200d_1f467_200d_1f466",
    "1f468_200d_1f469_200d_1f466_200d_1f466",
    "1f468_200d_1f469_200d_1f467_200d_1f467",
    "1f468_200d_1f468_200d_1f466",
    "1f468_200d_1f468_200d_1f467",
    "1f468_200d_1f468_200d_1f467_200d_1f466",
    "1f468_200d_1f468_200d_1f466_200d_1f466",
    "1f468_200d_1f468_200d_1f467_200d_1f467",
    "1f469_200d_1f469_200d_1f466",
    "1f469_200d_1f469_200d_1f467",
    "1f469_200d_1f469_200d_1f467_200d_1f466",
    "1f469_200d_1f469_200d_1f466_200d_1f466",
    "1f469_200d_1f469_200d_1f467_200d_1f467",
    "1f468_200d_1f466",
    "1f468_200d_1f466_200d_1f466",
    "1f468_200d_1f467",
    "1f468_200d_1f467_200d_1f466",
    "1f468_200d_1f467_200d_1f467",
    "1f469_200d_1f466",
    "1f469_200d_1f466_200d_1f466",
    "1f469_200d_1f467",
    "1f469_200d_1f467_200d_1f466",
    "1f469_200d_1f467_200d_1f467",
    "1f5e3",
    "1f464",
    "1f465",
    "1fac2",
    "1f463"
  ],
  "animals_&_nature": [
    "1f435",
    "1f412",
    "1f98d",
    "1f9a7",
    "1f436",
    "1f415",
    "1f9ae",
    "1f415_200d_1f9ba",
    "1f429",
    "1f43a",
    "1f98a",
    "1f99d",
    "1f431",
    "1f408",
    "1f408_200d_2b1b",
    "1f981",
    "1f42f",
    "1f405",
    "1f406",
    "1f434",
    "1f40e",
    "1f984",
    "1f993",
    "1f98c",
    "1f9ac",
    "1f42e",
    "1f402",
    "1f403",
    "1f404",
    "1f437",
    "1f416",
    "1f417",
    "1f43d",
    "1f40f",
    "1f411",
    "1f410",
    "1f42a",
    "1f42b",
    "1f999",
    "1f992",
    "1f418",
    "1f9a3",
    "1f98f",
    "1f99b",
    "1f42d",
    "1f401",
    "1f400",
    "1f439",
    "1f430",
    "1f407",
    "1f43f",
    "1f9ab",
    "1f994",
    "1f987",
    "1f43b",
    "1f43b_200d_2744_fe0f",
    "1f428",
    "1f43c",
    "1f9a5",
    "1f9a6",
    "1f9a8",
    "1f998",
    "1f9a1",
    "1f43e",
    "1f983",
    "1f414",
    "1f413",
    "1f423",
    "1f424",
    "1f425",
    "1f426",
    "1f427",
    "1f54a",
    "1f985",
    "1f986",
    "1f9a2",
    "1f989",
    "1f9a4",
    "1fab6",
    "1f9a9",
    "1f99a",
    "1f99c",
    "1f438",
    "1f40a",
    "1f422",
    "1f98e",
    "1f40d",
    "1f432",
    "1f409",
    "1f995",
    "1f996",
    "1f433",
    "1f40b",
    "1f42c",
    "1f9ad",
    "1f41f",
    "1f420",
    "1f421",
    "1f988",
    "1f419",
    "1f41a",
    "1f40c",
    "1f98b",
    "1f41b",
    "1f41c",
    "1f41d",
    "1fab2",
    "1f41e",
    "1f997",
    "1fab3",
    "1f577",
    "1f578",
    "1f982",
    "1f99f",
    "1fab0",
    "1fab1",
    "1f9a0",
    "1f490",
    "1f338",
    "1f4ae",
    "1f3f5",
    "1f339",
    "1f940",
    "1f33a",
    "1f33b",
    "1f33c",
    "1f337",
    "1f331",
    "1fab4",
    "1f332",
    "1f333",
    "1f334",
    "1f335",
    "1f33e",
    "1f33f",
    "2618",
    "1f340",
    "1f341",
    "1f342",
    "1f343"
  ],
  "food_&_drink": [
    "1f347",
    "1f348",
    "1f349",
    "1f34a",
    "1f34b",
    "1f34c",
    "1f34d",
    "1f96d",
    "1f34e",
    "1f34f",
    "1f350",
    "1f351",
    "1f352",
    "1f353",
    "1fad0",
    "1f95d",
    "1f345",
    "1fad2",
    "1f965",
    "1f951",
    "1f346",
    "1f954",
    "1f955",
    "1f33d",
    "1f336",
    "1fad1",
    "1f952",
    "1f96c",
    "1f966",
    "1f9c4",
    "1f9c5",
    "1f344",
    "1f95c",
    "1f330",
    "1f35e",
    "1f950",
    "1f956",
    "1fad3",
    "1f968",
    "1f96f",
    "1f95e",
    "1f9c7",
    "1f9c0",
    "1f356",
    "1f357",
    "1f969",
    "1f953",
    "1f354",
    "1f35f",
    "1f355",
    "1f32d",
    "1f96a",
    "1f32e",
    "1f32f",
    "1fad4",
    "1f959",
    "1f9c6",
    "1f95a",
    "1f373",
    "1f958",
    "1f372",
    "1fad5",
    "1f963",
    "1f957",
    "1f37f",
    "1f9c8",
    "1f9c2",
    "1f96b",
    "1f371",
    "1f358",
    "1f359",
    "1f35a",
    "1f35b",
    "1f35c",
    "1f35d",
    "1f360",
    "1f362",
    "1f363",
    "1f364",
    "1f365",
    "1f96e",
    "1f361",
    "1f95f",
    "1f960",
    "1f961",
    "1f980",
    "1f99e",
    "1f990",
    "1f991",
    "1f9aa",
    "1f366",
    "1f367",
    "1f368",
    "1f369",
    "1f36a",
    "1f382",
    "1f370",
    "1f9c1",
    "1f967",
    "1f36b",
    "1f36c",
    "1f36d",
    "1f36e",
    "1f36f",
    "1f37c",
    "1f95b",
    "2615",
    "1fad6",
    "1f375",
    "1f376",
    "1f37e",
    "1f377",
    "1f378",
    "1f379",
    "1f37a",
    "1f37b",
    "1f942",
    "1f943",
    "1f964",
    "1f9cb",
    "1f9c3",
    "1f9c9",
    "1f9ca",
    "1f962",
    "1f37d",
    "1f374",
    "1f944",
    "1f52a",
    "1f3fa"
  ],
  "travel_&_places": [
    "1f30d",
    "1f30e",
    "1f30f",
    "1f310",
    "1f5fa",
    "1f5fe",
    "1f9ed",
    "1f3d4",
    "26f0",
    "1f30b",
    "1f5fb",
    "1f3d5",
    "1f3d6",
    "1f3dc",
    "1f3dd",
    "1f3de",
    "1f3df",
    "1f3db",
    "1f3d7",
    "1f9f1",
    "1faa8",
    "1fab5",
    "1f6d6",
    "1f3d8",
    "1f3da",
    "1f3e0",
    "1f3e1",
    "1f3e2",
    "1f3e3",
    "1f3e4",
    "1f3e5",
    "1f3e6",
    "1f3e8",
    "1f3e9",
    "1f3ea",
    "1f3eb",
    "1f3ec",
    "1f3ed",
    "1f3ef",
    "1f3f0",
    "1f492",
    "1f5fc",
    "1f5fd",
    "26ea",
    "1f54c",
    "1f6d5",
    "1f54d",
    "26e9",
    "1f54b",
    "26f2",
    "26fa",
    "1f301",
    "1f303",
    "1f3d9",
    "1f304",
    "1f305",
    "1f306",
    "1f307",
    "1f309",
    "2668",
    "1f3a0",
    "1f3a1",
    "1f3a2",
    "1f488",
    "1f3aa",
    "1f682",
    "1f683",
    "1f684",
    "1f685",
    "1f686",
    "1f687",
    "1f688",
    "1f689",
    "1f68a",
    "1f69d",
    "1f69e",
    "1f68b",
    "1f68c",
    "1f68d",
    "1f68e",
    "1f690",
    "1f691",
    "1f692",
    "1f693",
    "1f694",
    "1f695",
    "1f696",
    "1f697",
    "1f698",
    "1f699",
    "1f6fb",
    "1f69a",
    "1f69b",
    "1f69c",
    "1f3ce",
    "1f3cd",
    "1f6f5",
    "1f9bd",
    "1f9bc",
    "1f6fa",
    "1f6b2",
    "1f6f4",
    "1f6f9",
    "1f6fc",
    "1f68f",
    "1f6e3",
    "1f6e4",
    "1f6e2",
    "26fd",
    "1f6a8",
    "1f6a5",
    "1f6a6",
    "1f6d1",
    "1f6a7",
    "2693",
    "26f5",
    "1f6f6",
    "1f6a4",
    "1f6f3",
    "26f4",
    "1f6e5",
    "1f6a2",
    "2708",
    "1f6e9",
    "1f6eb",
    "1f6ec",
    "1fa82",
    "1f4ba",
    "1f681",
    "1f69f",
    "1f6a0",
    "1f6a1",
    "1f6f0",
    "1f680",
    "1f6f8",
    "1f6ce",
    "1f9f3",
    "231b",
    "23f3",
    "231a",
    "23f0",
    "23f1",
    "23f2",
    "1f570",
    "1f55b",
    "1f567",
    "1f550",
    "1f55c",
    "1f551",
    "1f55d",
    "1f552",
    "1f55e",
    "1f553",
    "1f55f",
    "1f554",
    "1f560",
    "1f555",
    "1f561",
    "1f556",
    "1f562",
    "1f557",
    "1f563",
    "1f558",
    "1f564",
    "1f559",
    "1f565",
    "1f55a",
    "1f566",
    "1f311",
    "1f312",
    "1f313",
    "1f314",
    "1f315",
    "1f316",
    "1f317",
    "1f318",
    "1f319",
    "1f31a",
    "1f31b",
    "1f31c",
    "1f321",
    "2600",
    "1f31d",
    "1f31e",
    "1fa90",
    "2b50",
    "1f31f",
    "1f320",
    "1f30c",
    "2601",
    "26c5",
    "26c8",
    "1f324",
    "1f325",
    "1f326",
    "1f327",
    "1f328",
    "1f329",
    "1f32a",
    "1f32b",
    "1f32c",
    "1f300",
    "1f308",
    "1f302",
    "2602",
    "2614",
    "26f1",
    "26a1",
    "2744",
    "2603",
    "26c4",
    "2604",
    "1f525",
    "1f4a7",
    "1f30a"
  ],
  activities: [
    "1f383",
    "1f384",
    "1f386",
    "1f387",
    "1f9e8",
    "2728",
    "1f388",
    "1f389",
    "1f38a",
    "1f38b",
    "1f38d",
    "1f38e",
    "1f38f",
    "1f390",
    "1f391",
    "1f9e7",
    "1f380",
    "1f381",
    "1f397",
    "1f39f",
    "1f3ab",
    "1f396",
    "1f3c6",
    "1f3c5",
    "1f947",
    "1f948",
    "1f949",
    "26bd",
    "26be",
    "1f94e",
    "1f3c0",
    "1f3d0",
    "1f3c8",
    "1f3c9",
    "1f3be",
    "1f94f",
    "1f3b3",
    "1f3cf",
    "1f3d1",
    "1f3d2",
    "1f94d",
    "1f3d3",
    "1f3f8",
    "1f94a",
    "1f94b",
    "1f945",
    "26f3",
    "26f8",
    "1f3a3",
    "1f93f",
    "1f3bd",
    "1f3bf",
    "1f6f7",
    "1f94c",
    "1f3af",
    "1fa80",
    "1fa81",
    "1f3b1",
    "1f52e",
    "1fa84",
    "1f9ff",
    "1f3ae",
    "1f579",
    "1f3b0",
    "1f3b2",
    "1f9e9",
    "1f9f8",
    "1fa85",
    "1fa86",
    "2660",
    "2665",
    "2666",
    "2663",
    "265f",
    "1f0cf",
    "1f004",
    "1f3b4",
    "1f3ad",
    "1f5bc",
    "1f3a8",
    "1f9f5",
    "1faa1",
    "1f9f6",
    "1faa2"
  ],
  objects: [
    "1f453",
    "1f576",
    "1f97d",
    "1f97c",
    "1f9ba",
    "1f454",
    "1f455",
    "1f456",
    "1f9e3",
    "1f9e4",
    "1f9e5",
    "1f9e6",
    "1f457",
    "1f458",
    "1f97b",
    "1fa71",
    "1fa72",
    "1fa73",
    "1f459",
    "1f45a",
    "1f45b",
    "1f45c",
    "1f45d",
    "1f6cd",
    "1f392",
    "1fa74",
    "1f45e",
    "1f45f",
    "1f97e",
    "1f97f",
    "1f460",
    "1f461",
    "1fa70",
    "1f462",
    "1f451",
    "1f452",
    "1f3a9",
    "1f393",
    "1f9e2",
    "1fa96",
    "26d1",
    "1f4ff",
    "1f484",
    "1f48d",
    "1f48e",
    "1f507",
    "1f508",
    "1f509",
    "1f50a",
    "1f4e2",
    "1f4e3",
    "1f4ef",
    "1f514",
    "1f515",
    "1f3bc",
    "1f3b5",
    "1f3b6",
    "1f399",
    "1f39a",
    "1f39b",
    "1f3a4",
    "1f3a7",
    "1f4fb",
    "1f3b7",
    "1fa97",
    "1f3b8",
    "1f3b9",
    "1f3ba",
    "1f3bb",
    "1fa95",
    "1f941",
    "1fa98",
    "1f4f1",
    "1f4f2",
    "260e",
    "1f4de",
    "1f4df",
    "1f4e0",
    "1f50b",
    "1f50c",
    "1f4bb",
    "1f5a5",
    "1f5a8",
    "2328",
    "1f5b1",
    "1f5b2",
    "1f4bd",
    "1f4be",
    "1f4bf",
    "1f4c0",
    "1f9ee",
    "1f3a5",
    "1f39e",
    "1f4fd",
    "1f3ac",
    "1f4fa",
    "1f4f7",
    "1f4f8",
    "1f4f9",
    "1f4fc",
    "1f50d",
    "1f50e",
    "1f56f",
    "1f4a1",
    "1f526",
    "1f3ee",
    "1fa94",
    "1f4d4",
    "1f4d5",
    "1f4d6",
    "1f4d7",
    "1f4d8",
    "1f4d9",
    "1f4da",
    "1f4d3",
    "1f4d2",
    "1f4c3",
    "1f4dc",
    "1f4c4",
    "1f4f0",
    "1f5de",
    "1f4d1",
    "1f516",
    "1f3f7",
    "1f4b0",
    "1fa99",
    "1f4b4",
    "1f4b5",
    "1f4b6",
    "1f4b7",
    "1f4b8",
    "1f4b3",
    "1f9fe",
    "1f4b9",
    "2709",
    "1f4e7",
    "1f4e8",
    "1f4e9",
    "1f4e4",
    "1f4e5",
    "1f4e6",
    "1f4eb",
    "1f4ea",
    "1f4ec",
    "1f4ed",
    "1f4ee",
    "1f5f3",
    "270f",
    "2712",
    "1f58b",
    "1f58a",
    "1f58c",
    "1f58d",
    "1f4dd",
    "1f4bc",
    "1f4c1",
    "1f4c2",
    "1f5c2",
    "1f4c5",
    "1f4c6",
    "1f5d2",
    "1f5d3",
    "1f4c7",
    "1f4c8",
    "1f4c9",
    "1f4ca",
    "1f4cb",
    "1f4cc",
    "1f4cd",
    "1f4ce",
    "1f587",
    "1f4cf",
    "1f4d0",
    "2702",
    "1f5c3",
    "1f5c4",
    "1f5d1",
    "1f512",
    "1f513",
    "1f50f",
    "1f510",
    "1f511",
    "1f5dd",
    "1f528",
    "1fa93",
    "26cf",
    "2692",
    "1f6e0",
    "1f5e1",
    "2694",
    "1f52b",
    "1fa83",
    "1f3f9",
    "1f6e1",
    "1fa9a",
    "1f527",
    "1fa9b",
    "1f529",
    "2699",
    "1f5dc",
    "2696",
    "1f9af",
    "1f517",
    "26d3",
    "1fa9d",
    "1f9f0",
    "1f9f2",
    "1fa9c",
    "2697",
    "1f9ea",
    "1f9eb",
    "1f9ec",
    "1f52c",
    "1f52d",
    "1f4e1",
    "1f489",
    "1fa78",
    "1f48a",
    "1fa79",
    "1fa7a",
    "1f6aa",
    "1f6d7",
    "1fa9e",
    "1fa9f",
    "1f6cf",
    "1f6cb",
    "1fa91",
    "1f6bd",
    "1faa0",
    "1f6bf",
    "1f6c1",
    "1faa4",
    "1fa92",
    "1f9f4",
    "1f9f7",
    "1f9f9",
    "1f9fa",
    "1f9fb",
    "1faa3",
    "1f9fc",
    "1faa5",
    "1f9fd",
    "1f9ef",
    "1f6d2",
    "1f6ac",
    "26b0",
    "1faa6",
    "26b1",
    "1f5ff",
    "1faa7"
  ],
  symbols: [
    "1f3e7",
    "1f6ae",
    "1f6b0",
    "267f",
    "1f6b9",
    "1f6ba",
    "1f6bb",
    "1f6bc",
    "1f6be",
    "1f6c2",
    "1f6c3",
    "1f6c4",
    "1f6c5",
    "26a0",
    "1f6b8",
    "26d4",
    "1f6ab",
    "1f6b3",
    "1f6ad",
    "1f6af",
    "1f6b1",
    "1f6b7",
    "1f4f5",
    "1f51e",
    "2622",
    "2623",
    "2b06",
    "2197",
    "27a1",
    "2198",
    "2b07",
    "2199",
    "2b05",
    "2196",
    "2195",
    "2194",
    "21a9",
    "21aa",
    "2934",
    "2935",
    "1f503",
    "1f504",
    "1f519",
    "1f51a",
    "1f51b",
    "1f51c",
    "1f51d",
    "1f6d0",
    "269b",
    "1f549",
    "2721",
    "2638",
    "262f",
    "271d",
    "2626",
    "262a",
    "262e",
    "1f54e",
    "1f52f",
    "2648",
    "2649",
    "264a",
    "264b",
    "264c",
    "264d",
    "264e",
    "264f",
    "2650",
    "2651",
    "2652",
    "2653",
    "26ce",
    "1f500",
    "1f501",
    "1f502",
    "25b6",
    "23e9",
    "23ed",
    "23ef",
    "25c0",
    "23ea",
    "23ee",
    "1f53c",
    "23eb",
    "1f53d",
    "23ec",
    "23f8",
    "23f9",
    "23fa",
    "23cf",
    "1f3a6",
    "1f505",
    "1f506",
    "1f4f6",
    "1f4f3",
    "1f4f4",
    "2640",
    "2642",
    "26a7",
    "2716",
    "2795",
    "2796",
    "2797",
    "267e",
    "203c",
    "2049",
    "2753",
    "2754",
    "2755",
    "2757",
    "3030",
    "1f4b1",
    "1f4b2",
    "2695",
    "267b",
    "269c",
    "1f531",
    "1f4db",
    "1f530",
    "2b55",
    "2705",
    "2611",
    "2714",
    "274c",
    "274e",
    "27b0",
    "27bf",
    "303d",
    "2733",
    "2734",
    "2747",
    "00a9",
    "00ae",
    "2122",
    "0023_fe0f_20e3",
    "002a_fe0f_20e3",
    "0030_fe0f_20e3",
    "0031_fe0f_20e3",
    "0032_fe0f_20e3",
    "0033_fe0f_20e3",
    "0034_fe0f_20e3",
    "0035_fe0f_20e3",
    "0036_fe0f_20e3",
    "0037_fe0f_20e3",
    "0038_fe0f_20e3",
    "0039_fe0f_20e3",
    "1f51f",
    "1f520",
    "1f521",
    "1f522",
    "1f523",
    "1f524",
    "1f170",
    "1f18e",
    "1f171",
    "1f191",
    "1f192",
    "1f193",
    "2139",
    "1f194",
    "24c2",
    "1f195",
    "1f196",
    "1f17e",
    "1f197",
    "1f17f",
    "1f198",
    "1f199",
    "1f19a",
    "1f201",
    "1f202",
    "1f237",
    "1f236",
    "1f22f",
    "1f250",
    "1f239",
    "1f21a",
    "1f232",
    "1f251",
    "1f238",
    "1f234",
    "1f233",
    "3297",
    "3299",
    "1f23a",
    "1f235",
    "1f534",
    "1f7e0",
    "1f7e1",
    "1f7e2",
    "1f535",
    "1f7e3",
    "1f7e4",
    "26ab",
    "26aa",
    "1f7e5",
    "1f7e7",
    "1f7e8",
    "1f7e9",
    "1f7e6",
    "1f7ea",
    "1f7eb",
    "2b1b",
    "2b1c",
    "25fc",
    "25fb",
    "25fe",
    "25fd",
    "25aa",
    "25ab",
    "1f536",
    "1f537",
    "1f538",
    "1f539",
    "1f53a",
    "1f53b",
    "1f4a0",
    "1f518",
    "1f533",
    "1f532"
  ],
  flags: [
    "1f3c1",
    "1f6a9",
    "1f38c",
    "1f3f4",
    "1f3f3",
    "1f3f3_fe0f_200d_1f308",
    "1f3f3_fe0f_200d_26a7_fe0f",
    "1f3f4_200d_2620_fe0f",
    "1f1e6_1f1e8",
    "1f1e6_1f1e9",
    "1f1e6_1f1ea",
    "1f1e6_1f1eb",
    "1f1e6_1f1ec",
    "1f1e6_1f1ee",
    "1f1e6_1f1f1",
    "1f1e6_1f1f2",
    "1f1e6_1f1f4",
    "1f1e6_1f1f6",
    "1f1e6_1f1f7",
    "1f1e6_1f1f8",
    "1f1e6_1f1f9",
    "1f1e6_1f1fa",
    "1f1e6_1f1fc",
    "1f1e6_1f1fd",
    "1f1e6_1f1ff",
    "1f1e7_1f1e6",
    "1f1e7_1f1e7",
    "1f1e7_1f1e9",
    "1f1e7_1f1ea",
    "1f1e7_1f1eb",
    "1f1e7_1f1ec",
    "1f1e7_1f1ed",
    "1f1e7_1f1ee",
    "1f1e7_1f1ef",
    "1f1e7_1f1f1",
    "1f1e7_1f1f2",
    "1f1e7_1f1f3",
    "1f1e7_1f1f4",
    "1f1e7_1f1f6",
    "1f1e7_1f1f7",
    "1f1e7_1f1f8",
    "1f1e7_1f1f9",
    "1f1e7_1f1fb",
    "1f1e7_1f1fc",
    "1f1e7_1f1fe",
    "1f1e7_1f1ff",
    "1f1e8_1f1e6",
    "1f1e8_1f1e8",
    "1f1e8_1f1e9",
    "1f1e8_1f1eb",
    "1f1e8_1f1ec",
    "1f1e8_1f1ed",
    "1f1e8_1f1ee",
    "1f1e8_1f1f0",
    "1f1e8_1f1f1",
    "1f1e8_1f1f2",
    "1f1e8_1f1f3",
    "1f1e8_1f1f4",
    "1f1e8_1f1f5",
    "1f1e8_1f1f7",
    "1f1e8_1f1fa",
    "1f1e8_1f1fb",
    "1f1e8_1f1fc",
    "1f1e8_1f1fd",
    "1f1e8_1f1fe",
    "1f1e8_1f1ff",
    "1f1e9_1f1ea",
    "1f1e9_1f1ec",
    "1f1e9_1f1ef",
    "1f1e9_1f1f0",
    "1f1e9_1f1f2",
    "1f1e9_1f1f4",
    "1f1e9_1f1ff",
    "1f1ea_1f1e6",
    "1f1ea_1f1e8",
    "1f1ea_1f1ea",
    "1f1ea_1f1ec",
    "1f1ea_1f1ed",
    "1f1ea_1f1f7",
    "1f1ea_1f1f8",
    "1f1ea_1f1f9",
    "1f1ea_1f1fa",
    "1f1eb_1f1ee",
    "1f1eb_1f1ef",
    "1f1eb_1f1f0",
    "1f1eb_1f1f2",
    "1f1eb_1f1f4",
    "1f1eb_1f1f7",
    "1f1ec_1f1e6",
    "1f1ec_1f1e7",
    "1f1ec_1f1e9",
    "1f1ec_1f1ea",
    "1f1ec_1f1eb",
    "1f1ec_1f1ec",
    "1f1ec_1f1ed",
    "1f1ec_1f1ee",
    "1f1ec_1f1f1",
    "1f1ec_1f1f2",
    "1f1ec_1f1f3",
    "1f1ec_1f1f5",
    "1f1ec_1f1f6",
    "1f1ec_1f1f7",
    "1f1ec_1f1f8",
    "1f1ec_1f1f9",
    "1f1ec_1f1fa",
    "1f1ec_1f1fc",
    "1f1ec_1f1fe",
    "1f1ed_1f1f0",
    "1f1ed_1f1f2",
    "1f1ed_1f1f3",
    "1f1ed_1f1f7",
    "1f1ed_1f1f9",
    "1f1ed_1f1fa",
    "1f1ee_1f1e8",
    "1f1ee_1f1e9",
    "1f1ee_1f1ea",
    "1f1ee_1f1f1",
    "1f1ee_1f1f2",
    "1f1ee_1f1f3",
    "1f1ee_1f1f4",
    "1f1ee_1f1f6",
    "1f1ee_1f1f7",
    "1f1ee_1f1f8",
    "1f1ee_1f1f9",
    "1f1ef_1f1ea",
    "1f1ef_1f1f2",
    "1f1ef_1f1f4",
    "1f1ef_1f1f5",
    "1f1f0_1f1ea",
    "1f1f0_1f1ec",
    "1f1f0_1f1ed",
    "1f1f0_1f1ee",
    "1f1f0_1f1f2",
    "1f1f0_1f1f3",
    "1f1f0_1f1f5",
    "1f1f0_1f1f7",
    "1f1f0_1f1fc",
    "1f1f0_1f1fe",
    "1f1f0_1f1ff",
    "1f1f1_1f1e6",
    "1f1f1_1f1e7",
    "1f1f1_1f1e8",
    "1f1f1_1f1ee",
    "1f1f1_1f1f0",
    "1f1f1_1f1f7",
    "1f1f1_1f1f8",
    "1f1f1_1f1f9",
    "1f1f1_1f1fa",
    "1f1f1_1f1fb",
    "1f1f1_1f1fe",
    "1f1f2_1f1e6",
    "1f1f2_1f1e8",
    "1f1f2_1f1e9",
    "1f1f2_1f1ea",
    "1f1f2_1f1eb",
    "1f1f2_1f1ec",
    "1f1f2_1f1ed",
    "1f1f2_1f1f0",
    "1f1f2_1f1f1",
    "1f1f2_1f1f2",
    "1f1f2_1f1f3",
    "1f1f2_1f1f4",
    "1f1f2_1f1f5",
    "1f1f2_1f1f6",
    "1f1f2_1f1f7",
    "1f1f2_1f1f8",
    "1f1f2_1f1f9",
    "1f1f2_1f1fa",
    "1f1f2_1f1fb",
    "1f1f2_1f1fc",
    "1f1f2_1f1fd",
    "1f1f2_1f1fe",
    "1f1f2_1f1ff",
    "1f1f3_1f1e6",
    "1f1f3_1f1e8",
    "1f1f3_1f1ea",
    "1f1f3_1f1eb",
    "1f1f3_1f1ec",
    "1f1f3_1f1ee",
    "1f1f3_1f1f1",
    "1f1f3_1f1f4",
    "1f1f3_1f1f5",
    "1f1f3_1f1f7",
    "1f1f3_1f1fa",
    "1f1f3_1f1ff",
    "1f1f4_1f1f2",
    "1f1f5_1f1e6",
    "1f1f5_1f1ea",
    "1f1f5_1f1eb",
    "1f1f5_1f1ec",
    "1f1f5_1f1ed",
    "1f1f5_1f1f0",
    "1f1f5_1f1f1",
    "1f1f5_1f1f2",
    "1f1f5_1f1f3",
    "1f1f5_1f1f7",
    "1f1f5_1f1f8",
    "1f1f5_1f1f9",
    "1f1f5_1f1fc",
    "1f1f5_1f1fe",
    "1f1f6_1f1e6",
    "1f1f7_1f1ea",
    "1f1f7_1f1f4",
    "1f1f7_1f1f8",
    "1f1f7_1f1fa",
    "1f1f7_1f1fc",
    "1f1f8_1f1e6",
    "1f1f8_1f1e7",
    "1f1f8_1f1e8",
    "1f1f8_1f1e9",
    "1f1f8_1f1ea",
    "1f1f8_1f1ec",
    "1f1f8_1f1ed",
    "1f1f8_1f1ee",
    "1f1f8_1f1ef",
    "1f1f8_1f1f0",
    "1f1f8_1f1f1",
    "1f1f8_1f1f2",
    "1f1f8_1f1f3",
    "1f1f8_1f1f4",
    "1f1f8_1f1f7",
    "1f1f8_1f1f8",
    "1f1f8_1f1f9",
    "1f1f8_1f1fb",
    "1f1f8_1f1fd",
    "1f1f8_1f1fe",
    "1f1f8_1f1ff",
    "1f1f9_1f1e6",
    "1f1f9_1f1e8",
    "1f1f9_1f1e9",
    "1f1f9_1f1eb",
    "1f1f9_1f1ec",
    "1f1f9_1f1ed",
    "1f1f9_1f1ef",
    "1f1f9_1f1f0",
    "1f1f9_1f1f1",
    "1f1f9_1f1f2",
    "1f1f9_1f1f3",
    "1f1f9_1f1f4",
    "1f1f9_1f1f7",
    "1f1f9_1f1f9",
    "1f1f9_1f1fb",
    "1f1f9_1f1fc",
    "1f1f9_1f1ff",
    "1f1fa_1f1e6",
    "1f1fa_1f1ec",
    "1f1fa_1f1f2",
    "1f1fa_1f1f3",
    "1f1fa_1f1f8",
    "1f1fa_1f1fe",
    "1f1fa_1f1ff",
    "1f1fb_1f1e6",
    "1f1fb_1f1e8",
    "1f1fb_1f1ea",
    "1f1fb_1f1ec",
    "1f1fb_1f1ee",
    "1f1fb_1f1f3",
    "1f1fb_1f1fa",
    "1f1fc_1f1eb",
    "1f1fc_1f1f8",
    "1f1fd_1f1f0",
    "1f1fe_1f1ea",
    "1f1fe_1f1f9",
    "1f1ff_1f1e6",
    "1f1ff_1f1f2",
    "1f1ff_1f1fc",
    "1f3f4_e0067_e0062_e0065_e006e_e0067_e007f",
    "1f3f4_e0067_e0062_e0073_e0063_e0074_e007f",
    "1f3f4_e0067_e0062_e0077_e006c_e0073_e007f"
  ]
};

export const EMOJIS = {
  "1f600": {
    name: "grinning_face",
    code: "1f600",
    keywords: ["face", "grin", "grinning face"]
  },
  "1f603": {
    name: "grinning_face_with_big_eyes",
    code: "1f603",
    keywords: ["face", "grinning face with big eyes", "mouth", "open", "smile"]
  },
  "1f604": {
    name: "grinning_face_with_smiling_eyes",
    code: "1f604",
    keywords: [
      "eye",
      "face",
      "grinning face with smiling eyes",
      "mouth",
      "open",
      "smile"
    ]
  },
  "1f601": {
    name: "beaming_face_with_smiling_eyes",
    code: "1f601",
    keywords: ["beaming face with smiling eyes", "eye", "face", "grin", "smile"]
  },
  "1f606": {
    name: "grinning_squinting_face",
    code: "1f606",
    keywords: [
      "face",
      "grinning squinting face",
      "laugh",
      "mouth",
      "satisfied",
      "smile"
    ]
  },
  "1f605": {
    name: "grinning_face_with_sweat",
    code: "1f605",
    keywords: [
      "cold",
      "face",
      "grinning face with sweat",
      "open",
      "smile",
      "sweat"
    ]
  },
  "1f923": {
    name: "rolling_on_the_floor_laughing",
    code: "1f923",
    keywords: [
      "face",
      "floor",
      "laugh",
      "rolling",
      "rolling on the floor laughing"
    ]
  },
  "1f602": {
    name: "face_with_tears_of_joy",
    code: "1f602",
    keywords: ["face", "face with tears of joy", "joy", "laugh", "tear"]
  },
  "1f642": {
    name: "slightly_smiling_face",
    code: "1f642",
    keywords: ["face", "slightly smiling face", "smile"]
  },
  "1f643": {
    name: "upside-down_face",
    code: "1f643",
    keywords: ["face", "upside-down"]
  },
  "1f609": {
    name: "winking_face",
    code: "1f609",
    keywords: ["face", "wink", "winking face"]
  },
  "1f60a": {
    name: "smiling_face_with_smiling_eyes",
    code: "1f60a",
    keywords: [
      "blush",
      "eye",
      "face",
      "smile",
      "smiling face with smiling eyes"
    ]
  },
  "1f607": {
    name: "smiling_face_with_halo",
    code: "1f607",
    keywords: [
      "angel",
      "face",
      "fantasy",
      "halo",
      "innocent",
      "smiling face with halo"
    ]
  },
  "1f970": {
    name: "smiling_face_with_hearts",
    code: "1f970",
    keywords: [
      "adore",
      "crush",
      "hearts",
      "in love",
      "smiling face with hearts"
    ]
  },
  "1f60d": {
    name: "smiling_face_with_heart-eyes",
    code: "1f60d",
    keywords: ["eye", "face", "love", "smile", "smiling face with heart-eyes"]
  },
  "1f929": {
    name: "star-struck",
    code: "1f929",
    keywords: ["eyes", "face", "grinning", "star", "star-struck", ""]
  },
  "1f618": {
    name: "face_blowing_a_kiss",
    code: "1f618",
    keywords: ["face", "face blowing a kiss", "kiss"]
  },
  "1f617": {
    name: "kissing_face",
    code: "1f617",
    keywords: ["face", "kiss", "kissing face"]
  },
  "263a": {
    name: "smiling_face",
    code: "263a",
    keywords: ["face", "outlined", "relaxed", "smile", "smiling face"]
  },
  "1f61a": {
    name: "kissing_face_with_closed_eyes",
    code: "1f61a",
    keywords: ["closed", "eye", "face", "kiss", "kissing face with closed eyes"]
  },
  "1f619": {
    name: "kissing_face_with_smiling_eyes",
    code: "1f619",
    keywords: ["eye", "face", "kiss", "kissing face with smiling eyes", "smile"]
  },
  "1f972": {
    name: "\u229b_smiling_face_with_tear",
    code: "1f972",
    keywords: [
      "grateful",
      "proud",
      "relieved",
      "smiling",
      "smiling face with tear",
      "tear",
      "touched"
    ]
  },
  "1f60b": {
    name: "face_savoring_food",
    code: "1f60b",
    keywords: [
      "delicious",
      "face",
      "face savoring food",
      "savouring",
      "smile",
      "yum"
    ]
  },
  "1f61b": {
    name: "face_with_tongue",
    code: "1f61b",
    keywords: ["face", "face with tongue", "tongue"]
  },
  "1f61c": {
    name: "winking_face_with_tongue",
    code: "1f61c",
    keywords: [
      "eye",
      "face",
      "joke",
      "tongue",
      "wink",
      "winking face with tongue"
    ]
  },
  "1f92a": {
    name: "zany_face",
    code: "1f92a",
    keywords: ["eye", "goofy", "large", "small", "zany face"]
  },
  "1f61d": {
    name: "squinting_face_with_tongue",
    code: "1f61d",
    keywords: [
      "eye",
      "face",
      "horrible",
      "squinting face with tongue",
      "taste",
      "tongue"
    ]
  },
  "1f911": {
    name: "money-mouth_face",
    code: "1f911",
    keywords: ["face", "money", "money-mouth face", "mouth"]
  },
  "1f917": {
    name: "hugging_face",
    code: "1f917",
    keywords: ["face", "hug", "hugging"]
  },
  "1f92d": {
    name: "face_with_hand_over_mouth",
    code: "1f92d",
    keywords: ["face with hand over mouth", "whoops", ""]
  },
  "1f92b": {
    name: "shushing_face",
    code: "1f92b",
    keywords: ["quiet", "shush", "shushing face"]
  },
  "1f914": {
    name: "thinking_face",
    code: "1f914",
    keywords: ["face", "thinking"]
  },
  "1f910": {
    name: "zipper-mouth_face",
    code: "1f910",
    keywords: ["face", "mouth", "zipper", "zipper-mouth face"]
  },
  "1f928": {
    name: "face_with_raised_eyebrow",
    code: "1f928",
    keywords: ["distrust", "face with raised eyebrow", "skeptic", ""]
  },
  "1f610": {
    name: "neutral_face",
    code: "1f610",
    keywords: ["deadpan", "face", "meh", "neutral"]
  },
  "1f611": {
    name: "expressionless_face",
    code: "1f611",
    keywords: ["expressionless", "face", "inexpressive", "meh", "unexpressive"]
  },
  "1f636": {
    name: "face_without_mouth",
    code: "1f636",
    keywords: ["face", "face without mouth", "mouth", "quiet", "silent"]
  },
  "1f60f": {
    name: "smirking_face",
    code: "1f60f",
    keywords: ["face", "smirk", "smirking face"]
  },
  "1f612": {
    name: "unamused_face",
    code: "1f612",
    keywords: ["face", "unamused", "unhappy"]
  },
  "1f644": {
    name: "face_with_rolling_eyes",
    code: "1f644",
    keywords: ["eyeroll", "eyes", "face", "face with rolling eyes", "rolling"]
  },
  "1f62c": {
    name: "grimacing_face",
    code: "1f62c",
    keywords: ["face", "grimace", "grimacing face"]
  },
  "1f925": {
    name: "lying_face",
    code: "1f925",
    keywords: ["face", "lie", "lying face", "pinocchio"]
  },
  "1f60c": {
    name: "relieved_face",
    code: "1f60c",
    keywords: ["face", "relieved"]
  },
  "1f614": {
    name: "pensive_face",
    code: "1f614",
    keywords: ["dejected", "face", "pensive"]
  },
  "1f62a": {
    name: "sleepy_face",
    code: "1f62a",
    keywords: ["face", "sleep", "sleepy face"]
  },
  "1f924": {
    name: "drooling_face",
    code: "1f924",
    keywords: ["drooling", "face"]
  },
  "1f634": {
    name: "sleeping_face",
    code: "1f634",
    keywords: ["face", "sleep", "sleeping face", "zzz"]
  },
  "1f637": {
    name: "face_with_medical_mask",
    code: "1f637",
    keywords: [
      "cold",
      "doctor",
      "face",
      "face with medical mask",
      "mask",
      "sick"
    ]
  },
  "1f912": {
    name: "face_with_thermometer",
    code: "1f912",
    keywords: ["face", "face with thermometer", "ill", "sick", "thermometer"]
  },
  "1f915": {
    name: "face_with_head-bandage",
    code: "1f915",
    keywords: ["bandage", "face", "face with head-bandage", "hurt", "injury"]
  },
  "1f922": {
    name: "nauseated_face",
    code: "1f922",
    keywords: ["face", "nauseated", "vomit"]
  },
  "1f92e": {
    name: "face_vomiting",
    code: "1f92e",
    keywords: ["face vomiting", "sick", "vomit"]
  },
  "1f927": {
    name: "sneezing_face",
    code: "1f927",
    keywords: ["face", "gesundheit", "sneeze", "sneezing face"]
  },
  "1f975": {
    name: "hot_face",
    code: "1f975",
    keywords: [
      "feverish",
      "heat stroke",
      "hot",
      "hot face",
      "red-faced",
      "sweating"
    ]
  },
  "1f976": {
    name: "cold_face",
    code: "1f976",
    keywords: [
      "blue-faced",
      "cold",
      "cold face",
      "freezing",
      "frostbite",
      "icicles"
    ]
  },
  "1f974": {
    name: "woozy_face",
    code: "1f974",
    keywords: [
      "dizzy",
      "intoxicated",
      "tipsy",
      "uneven eyes",
      "wavy mouth",
      "woozy face"
    ]
  },
  "1f635": { name: "dizzy_face", code: "1f635", keywords: ["dizzy", "face"] },
  "1f92f": {
    name: "exploding_head",
    code: "1f92f",
    keywords: ["exploding head", "mind blown", "shocked"]
  },
  "1f920": {
    name: "cowboy_hat_face",
    code: "1f920",
    keywords: ["cowboy", "cowgirl", "face", "hat"]
  },
  "1f973": {
    name: "partying_face",
    code: "1f973",
    keywords: ["celebration", "hat", "horn", "party", "partying face"]
  },
  "1f978": {
    name: "\u229b_disguised_face",
    code: "1f978",
    keywords: [
      "disguise",
      "disguised face",
      "face",
      "glasses",
      "incognito",
      "nose"
    ]
  },
  "1f60e": {
    name: "smiling_face_with_sunglasses",
    code: "1f60e",
    keywords: [
      "bright",
      "cool",
      "face",
      "smiling face with sunglasses",
      "sun",
      "sunglasses"
    ]
  },
  "1f913": {
    name: "nerd_face",
    code: "1f913",
    keywords: ["face", "geek", "nerd"]
  },
  "1f9d0": {
    name: "face_with_monocle",
    code: "1f9d0",
    keywords: ["face with monocle", "stuffy", ""]
  },
  "1f615": {
    name: "confused_face",
    code: "1f615",
    keywords: ["confused", "face", "meh"]
  },
  "1f61f": {
    name: "worried_face",
    code: "1f61f",
    keywords: ["face", "worried"]
  },
  "1f641": {
    name: "slightly_frowning_face",
    code: "1f641",
    keywords: ["face", "frown", "slightly frowning face"]
  },
  "2639": {
    name: "frowning_face",
    code: "2639",
    keywords: ["face", "frown", "frowning face"]
  },
  "1f62e": {
    name: "face_with_open_mouth",
    code: "1f62e",
    keywords: ["face", "face with open mouth", "mouth", "open", "sympathy"]
  },
  "1f62f": {
    name: "hushed_face",
    code: "1f62f",
    keywords: ["face", "hushed", "stunned", "surprised"]
  },
  "1f632": {
    name: "astonished_face",
    code: "1f632",
    keywords: ["astonished", "face", "shocked", "totally"]
  },
  "1f633": {
    name: "flushed_face",
    code: "1f633",
    keywords: ["dazed", "face", "flushed"]
  },
  "1f97a": {
    name: "pleading_face",
    code: "1f97a",
    keywords: ["begging", "mercy", "pleading face", "puppy eyes"]
  },
  "1f626": {
    name: "frowning_face_with_open_mouth",
    code: "1f626",
    keywords: [
      "face",
      "frown",
      "frowning face with open mouth",
      "mouth",
      "open"
    ]
  },
  "1f627": {
    name: "anguished_face",
    code: "1f627",
    keywords: ["anguished", "face"]
  },
  "1f628": {
    name: "fearful_face",
    code: "1f628",
    keywords: ["face", "fear", "fearful", "scared"]
  },
  "1f630": {
    name: "anxious_face_with_sweat",
    code: "1f630",
    keywords: [
      "anxious face with sweat",
      "blue",
      "cold",
      "face",
      "rushed",
      "sweat"
    ]
  },
  "1f625": {
    name: "sad_but_relieved_face",
    code: "1f625",
    keywords: [
      "disappointed",
      "face",
      "relieved",
      "sad but relieved face",
      "whew"
    ]
  },
  "1f622": {
    name: "crying_face",
    code: "1f622",
    keywords: ["cry", "crying face", "face", "sad", "tear"]
  },
  "1f62d": {
    name: "loudly_crying_face",
    code: "1f62d",
    keywords: ["cry", "face", "loudly crying face", "sad", "sob", "tear"]
  },
  "1f631": {
    name: "face_screaming_in_fear",
    code: "1f631",
    keywords: [
      "face",
      "face screaming in fear",
      "fear",
      "munch",
      "scared",
      "scream"
    ]
  },
  "1f616": {
    name: "confounded_face",
    code: "1f616",
    keywords: ["confounded", "face"]
  },
  "1f623": {
    name: "persevering_face",
    code: "1f623",
    keywords: ["face", "persevere", "persevering face"]
  },
  "1f61e": {
    name: "disappointed_face",
    code: "1f61e",
    keywords: ["disappointed", "face"]
  },
  "1f613": {
    name: "downcast_face_with_sweat",
    code: "1f613",
    keywords: ["cold", "downcast face with sweat", "face", "sweat"]
  },
  "1f629": {
    name: "weary_face",
    code: "1f629",
    keywords: ["face", "tired", "weary"]
  },
  "1f62b": { name: "tired_face", code: "1f62b", keywords: ["face", "tired"] },
  "1f971": {
    name: "yawning_face",
    code: "1f971",
    keywords: ["bored", "tired", "yawn", "yawning face"]
  },
  "1f624": {
    name: "face_with_steam_from_nose",
    code: "1f624",
    keywords: ["face", "face with steam from nose", "triumph", "won"]
  },
  "1f621": {
    name: "pouting_face",
    code: "1f621",
    keywords: ["angry", "face", "mad", "pouting", "rage", "red"]
  },
  "1f620": {
    name: "angry_face",
    code: "1f620",
    keywords: ["angry", "face", "mad"]
  },
  "1f92c": {
    name: "face_with_symbols_on_mouth",
    code: "1f92c",
    keywords: ["face with symbols on mouth", "swearing", ""]
  },
  "1f608": {
    name: "smiling_face_with_horns",
    code: "1f608",
    keywords: [
      "face",
      "fairy tale",
      "fantasy",
      "horns",
      "smile",
      "smiling face with horns"
    ]
  },
  "1f47f": {
    name: "angry_face_with_horns",
    code: "1f47f",
    keywords: [
      "angry face with horns",
      "demon",
      "devil",
      "face",
      "fantasy",
      "imp"
    ]
  },
  "1f480": {
    name: "skull",
    code: "1f480",
    keywords: ["death", "face", "fairy tale", "monster", "skull"]
  },
  "2620": {
    name: "skull_and_crossbones",
    code: "2620",
    keywords: [
      "crossbones",
      "death",
      "face",
      "monster",
      "skull",
      "skull and crossbones"
    ]
  },
  "1f4a9": {
    name: "pile_of_poo",
    code: "1f4a9",
    keywords: ["dung", "face", "monster", "pile of poo", "poo", "poop"]
  },
  "1f921": { name: "clown_face", code: "1f921", keywords: ["clown", "face"] },
  "1f479": {
    name: "ogre",
    code: "1f479",
    keywords: [
      "creature",
      "face",
      "fairy tale",
      "fantasy",
      "monster",
      "ogre",
      ""
    ]
  },
  "1f47a": {
    name: "goblin",
    code: "1f47a",
    keywords: ["creature", "face", "fairy tale", "fantasy", "goblin", "monster"]
  },
  "1f47b": {
    name: "ghost",
    code: "1f47b",
    keywords: ["creature", "face", "fairy tale", "fantasy", "ghost", "monster"]
  },
  "1f47d": {
    name: "alien",
    code: "1f47d",
    keywords: [
      "alien",
      "creature",
      "extraterrestrial",
      "face",
      "fantasy",
      "ufo"
    ]
  },
  "1f47e": {
    name: "alien_monster",
    code: "1f47e",
    keywords: [
      "alien",
      "creature",
      "extraterrestrial",
      "face",
      "monster",
      "ufo"
    ]
  },
  "1f916": {
    name: "robot",
    code: "1f916",
    keywords: ["face", "monster", "robot"]
  },
  "1f63a": {
    name: "grinning_cat",
    code: "1f63a",
    keywords: ["cat", "face", "grinning", "mouth", "open", "smile"]
  },
  "1f638": {
    name: "grinning_cat_with_smiling_eyes",
    code: "1f638",
    keywords: [
      "cat",
      "eye",
      "face",
      "grin",
      "grinning cat with smiling eyes",
      "smile"
    ]
  },
  "1f639": {
    name: "cat_with_tears_of_joy",
    code: "1f639",
    keywords: ["cat", "cat with tears of joy", "face", "joy", "tear"]
  },
  "1f63b": {
    name: "smiling_cat_with_heart-eyes",
    code: "1f63b",
    keywords: [
      "cat",
      "eye",
      "face",
      "heart",
      "love",
      "smile",
      "smiling cat with heart-eyes"
    ]
  },
  "1f63c": {
    name: "cat_with_wry_smile",
    code: "1f63c",
    keywords: ["cat", "cat with wry smile", "face", "ironic", "smile", "wry"]
  },
  "1f63d": {
    name: "kissing_cat",
    code: "1f63d",
    keywords: ["cat", "eye", "face", "kiss", "kissing cat"]
  },
  "1f640": {
    name: "weary_cat",
    code: "1f640",
    keywords: ["cat", "face", "oh", "surprised", "weary"]
  },
  "1f63f": {
    name: "crying_cat",
    code: "1f63f",
    keywords: ["cat", "cry", "crying cat", "face", "sad", "tear"]
  },
  "1f63e": {
    name: "pouting_cat",
    code: "1f63e",
    keywords: ["cat", "face", "pouting"]
  },
  "1f648": {
    name: "see-no-evil_monkey",
    code: "1f648",
    keywords: [
      "evil",
      "face",
      "forbidden",
      "monkey",
      "see",
      "see-no-evil monkey"
    ]
  },
  "1f649": {
    name: "hear-no-evil_monkey",
    code: "1f649",
    keywords: [
      "evil",
      "face",
      "forbidden",
      "hear",
      "hear-no-evil monkey",
      "monkey"
    ]
  },
  "1f64a": {
    name: "speak-no-evil_monkey",
    code: "1f64a",
    keywords: [
      "evil",
      "face",
      "forbidden",
      "monkey",
      "speak",
      "speak-no-evil monkey"
    ]
  },
  "1f48b": {
    name: "kiss_mark",
    code: "1f48b",
    keywords: ["kiss", "kiss mark", "lips"]
  },
  "1f48c": {
    name: "love_letter",
    code: "1f48c",
    keywords: ["heart", "letter", "love", "mail"]
  },
  "1f498": {
    name: "heart_with_arrow",
    code: "1f498",
    keywords: ["arrow", "cupid", "heart with arrow"]
  },
  "1f49d": {
    name: "heart_with_ribbon",
    code: "1f49d",
    keywords: ["heart with ribbon", "ribbon", "valentine"]
  },
  "1f496": {
    name: "sparkling_heart",
    code: "1f496",
    keywords: ["excited", "sparkle", "sparkling heart"]
  },
  "1f497": {
    name: "growing_heart",
    code: "1f497",
    keywords: ["excited", "growing", "growing heart", "nervous", "pulse"]
  },
  "1f493": {
    name: "beating_heart",
    code: "1f493",
    keywords: ["beating", "beating heart", "heartbeat", "pulsating"]
  },
  "1f49e": {
    name: "revolving_hearts",
    code: "1f49e",
    keywords: ["revolving", "revolving hearts"]
  },
  "1f495": {
    name: "two_hearts",
    code: "1f495",
    keywords: ["love", "two hearts"]
  },
  "1f49f": {
    name: "heart_decoration",
    code: "1f49f",
    keywords: ["heart", "heart decoration"]
  },
  "2763": {
    name: "heart_exclamation",
    code: "2763",
    keywords: ["exclamation", "heart exclamation", "mark", "punctuation"]
  },
  "1f494": {
    name: "broken_heart",
    code: "1f494",
    keywords: ["break", "broken", "broken heart"]
  },
  "2764": { name: "red_heart", code: "2764", keywords: ["heart", "red heart"] },
  "1f9e1": {
    name: "orange_heart",
    code: "1f9e1",
    keywords: ["orange", "orange heart"]
  },
  "1f49b": {
    name: "yellow_heart",
    code: "1f49b",
    keywords: ["yellow", "yellow heart"]
  },
  "1f49a": {
    name: "green_heart",
    code: "1f49a",
    keywords: ["green", "green heart"]
  },
  "1f499": {
    name: "blue_heart",
    code: "1f499",
    keywords: ["blue", "blue heart"]
  },
  "1f49c": {
    name: "purple_heart",
    code: "1f49c",
    keywords: ["purple", "purple heart"]
  },
  "1f90e": { name: "brown_heart", code: "1f90e", keywords: ["brown", "heart"] },
  "1f5a4": {
    name: "black_heart",
    code: "1f5a4",
    keywords: ["black", "black heart", "evil", "wicked"]
  },
  "1f90d": { name: "white_heart", code: "1f90d", keywords: ["heart", "white"] },
  "1f4af": {
    name: "hundred_points",
    code: "1f4af",
    keywords: ["100", "full", "hundred", "hundred points", "score"]
  },
  "1f4a2": {
    name: "anger_symbol",
    code: "1f4a2",
    keywords: ["anger symbol", "angry", "comic", "mad"]
  },
  "1f4a5": {
    name: "collision",
    code: "1f4a5",
    keywords: ["boom", "collision", "comic"]
  },
  "1f4ab": {
    name: "dizzy",
    code: "1f4ab",
    keywords: ["comic", "dizzy", "star"]
  },
  "1f4a6": {
    name: "sweat_droplets",
    code: "1f4a6",
    keywords: ["comic", "splashing", "sweat", "sweat droplets"]
  },
  "1f4a8": {
    name: "dashing_away",
    code: "1f4a8",
    keywords: ["comic", "dash", "dashing away", "running"]
  },
  "1f573": { name: "hole", code: "1f573", keywords: ["hole"] },
  "1f4a3": { name: "bomb", code: "1f4a3", keywords: ["bomb", "comic"] },
  "1f4ac": {
    name: "speech_balloon",
    code: "1f4ac",
    keywords: ["balloon", "bubble", "comic", "dialog", "speech"]
  },
  "1f441_fe0f_200d_1f5e8_fe0f": {
    name: "eye_in_speech_bubble",
    code: "1f441_fe0f_200d_1f5e8_fe0f",
    keywords: ["eye", "eye in speech bubble", "speech bubble", "witness"]
  },
  "1f5e8": {
    name: "left_speech_bubble",
    code: "1f5e8",
    keywords: ["dialog", "left speech bubble", "speech"]
  },
  "1f5ef": {
    name: "right_anger_bubble",
    code: "1f5ef",
    keywords: ["angry", "balloon", "bubble", "mad", "right anger bubble"]
  },
  "1f4ad": {
    name: "thought_balloon",
    code: "1f4ad",
    keywords: ["balloon", "bubble", "comic", "thought"]
  },
  "1f4a4": { name: "zzz", code: "1f4a4", keywords: ["comic", "sleep", "zzz"] },
  "1f44b": {
    name: "waving_hand",
    code: "1f44b",
    keywords: ["hand", "wave", "waving"]
  },
  "1f91a": {
    name: "raised_back_of_hand",
    code: "1f91a",
    keywords: ["backhand", "raised", "raised back of hand"]
  },
  "1f590": {
    name: "hand_with_fingers_splayed",
    code: "1f590",
    keywords: ["finger", "hand", "hand with fingers splayed", "splayed"]
  },
  "270b": {
    name: "raised_hand",
    code: "270b",
    keywords: ["hand", "raised hand"]
  },
  "1f596": {
    name: "vulcan_salute",
    code: "1f596",
    keywords: ["finger", "hand", "spock", "vulcan", "vulcan salute"]
  },
  "1f44c": { name: "OK_hand", code: "1f44c", keywords: ["hand", "OK"] },
  "1f90c": {
    name: "\u229b_pinched_fingers",
    code: "1f90c",
    keywords: [
      "fingers",
      "hand gesture",
      "interrogation",
      "pinched",
      "sarcastic"
    ]
  },
  "1f90f": {
    name: "pinching_hand",
    code: "1f90f",
    keywords: ["pinching hand", "small amount"]
  },
  "270c": {
    name: "victory_hand",
    code: "270c",
    keywords: ["hand", "v", "victory"]
  },
  "1f91e": {
    name: "crossed_fingers",
    code: "1f91e",
    keywords: ["cross", "crossed fingers", "finger", "hand", "luck"]
  },
  "1f91f": {
    name: "love-you_gesture",
    code: "1f91f",
    keywords: ["hand", "ILY", "love-you gesture"]
  },
  "1f918": {
    name: "sign_of_the_horns",
    code: "1f918",
    keywords: ["finger", "hand", "horns", "rock-on", "sign of the horns"]
  },
  "1f919": {
    name: "call_me_hand",
    code: "1f919",
    keywords: ["call", "call me hand", "hand"]
  },
  "1f448": {
    name: "backhand_index_pointing_left",
    code: "1f448",
    keywords: [
      "backhand",
      "backhand index pointing left",
      "finger",
      "hand",
      "index",
      "point"
    ]
  },
  "1f449": {
    name: "backhand_index_pointing_right",
    code: "1f449",
    keywords: [
      "backhand",
      "backhand index pointing right",
      "finger",
      "hand",
      "index",
      "point"
    ]
  },
  "1f446": {
    name: "backhand_index_pointing_up",
    code: "1f446",
    keywords: [
      "backhand",
      "backhand index pointing up",
      "finger",
      "hand",
      "point",
      "up"
    ]
  },
  "1f595": {
    name: "middle_finger",
    code: "1f595",
    keywords: ["finger", "hand", "middle finger"]
  },
  "1f447": {
    name: "backhand_index_pointing_down",
    code: "1f447",
    keywords: [
      "backhand",
      "backhand index pointing down",
      "down",
      "finger",
      "hand",
      "point"
    ]
  },
  "261d": {
    name: "index_pointing_up",
    code: "261d",
    keywords: ["finger", "hand", "index", "index pointing up", "point", "up"]
  },
  "1f44d": {
    name: "thumbs_up",
    code: "1f44d",
    keywords: ["+1", "hand", "thumb", "thumbs up", "up"]
  },
  "1f44e": {
    name: "thumbs_down",
    code: "1f44e",
    keywords: ["-1", "down", "hand", "thumb", "thumbs down"]
  },
  "270a": {
    name: "raised_fist",
    code: "270a",
    keywords: ["clenched", "fist", "hand", "punch", "raised fist"]
  },
  "1f44a": {
    name: "oncoming_fist",
    code: "1f44a",
    keywords: ["clenched", "fist", "hand", "oncoming fist", "punch"]
  },
  "1f91b": {
    name: "left-facing_fist",
    code: "1f91b",
    keywords: ["fist", "left-facing fist", "leftwards"]
  },
  "1f91c": {
    name: "right-facing_fist",
    code: "1f91c",
    keywords: ["fist", "right-facing fist", "rightwards"]
  },
  "1f44f": {
    name: "clapping_hands",
    code: "1f44f",
    keywords: ["clap", "clapping hands", "hand"]
  },
  "1f64c": {
    name: "raising_hands",
    code: "1f64c",
    keywords: [
      "celebration",
      "gesture",
      "hand",
      "hooray",
      "raised",
      "raising hands"
    ]
  },
  "1f450": {
    name: "open_hands",
    code: "1f450",
    keywords: ["hand", "open", "open hands"]
  },
  "1f932": {
    name: "palms_up_together",
    code: "1f932",
    keywords: ["palms up together", "prayer", ""]
  },
  "1f91d": {
    name: "handshake",
    code: "1f91d",
    keywords: ["agreement", "hand", "handshake", "meeting", "shake"]
  },
  "1f64f": {
    name: "folded_hands",
    code: "1f64f",
    keywords: ["ask", "folded hands", "hand", "please", "pray", "thanks"]
  },
  "270d": {
    name: "writing_hand",
    code: "270d",
    keywords: ["hand", "write", "writing hand"]
  },
  "1f485": {
    name: "nail_polish",
    code: "1f485",
    keywords: ["care", "cosmetics", "manicure", "nail", "polish"]
  },
  "1f933": {
    name: "selfie",
    code: "1f933",
    keywords: ["camera", "phone", "selfie"]
  },
  "1f4aa": {
    name: "flexed_biceps",
    code: "1f4aa",
    keywords: ["biceps", "comic", "flex", "flexed biceps", "muscle"]
  },
  "1f9be": {
    name: "mechanical_arm",
    code: "1f9be",
    keywords: ["accessibility", "mechanical arm", "prosthetic"]
  },
  "1f9bf": {
    name: "mechanical_leg",
    code: "1f9bf",
    keywords: ["accessibility", "mechanical leg", "prosthetic"]
  },
  "1f9b5": { name: "leg", code: "1f9b5", keywords: ["kick", "leg", "limb"] },
  "1f9b6": { name: "foot", code: "1f9b6", keywords: ["foot", "kick", "stomp"] },
  "1f442": { name: "ear", code: "1f442", keywords: ["body", "ear"] },
  "1f9bb": {
    name: "ear_with_hearing_aid",
    code: "1f9bb",
    keywords: ["accessibility", "ear with hearing aid", "hard of hearing"]
  },
  "1f443": { name: "nose", code: "1f443", keywords: ["body", "nose"] },
  "1f9e0": { name: "brain", code: "1f9e0", keywords: ["brain", "intelligent"] },
  "1fac0": {
    name: "\u229b_anatomical_heart",
    code: "1fac0",
    keywords: ["anatomical", "cardiology", "heart", "organ", "pulse"]
  },
  "1fac1": {
    name: "\u229b_lungs",
    code: "1fac1",
    keywords: [
      "breath",
      "exhalation",
      "inhalation",
      "lungs",
      "organ",
      "respiration"
    ]
  },
  "1f9b7": { name: "tooth", code: "1f9b7", keywords: ["dentist", "tooth"] },
  "1f9b4": { name: "bone", code: "1f9b4", keywords: ["bone", "skeleton"] },
  "1f440": { name: "eyes", code: "1f440", keywords: ["eye", "eyes", "face"] },
  "1f441": { name: "eye", code: "1f441", keywords: ["body", "eye"] },
  "1f445": { name: "tongue", code: "1f445", keywords: ["body", "tongue"] },
  "1f444": { name: "mouth", code: "1f444", keywords: ["lips", "mouth"] },
  "1f476": { name: "baby", code: "1f476", keywords: ["baby", "young"] },
  "1f9d2": {
    name: "child",
    code: "1f9d2",
    keywords: ["child", "gender-neutral", "unspecified gender", "young"]
  },
  "1f466": { name: "boy", code: "1f466", keywords: ["boy", "young"] },
  "1f467": {
    name: "girl",
    code: "1f467",
    keywords: ["girl", "Virgo", "young", "zodiac"]
  },
  "1f9d1": {
    name: "person",
    code: "1f9d1",
    keywords: ["adult", "gender-neutral", "person", "unspecified gender"]
  },
  "1f471": {
    name: "person:_blond_hair",
    code: "1f471",
    keywords: ["blond", "blond-haired person", "hair", "person: blond hair"]
  },
  "1f468": { name: "man", code: "1f468", keywords: ["adult", "man"] },
  "1f9d4": {
    name: "man:_beard",
    code: "1f9d4",
    keywords: ["beard", "man", "man: beard", "person", ""]
  },
  "1f468_200d_1f9b0": {
    name: "man:_red_hair",
    code: "1f468_200d_1f9b0",
    keywords: ["adult", "man", "red hair"]
  },
  "1f468_200d_1f9b1": {
    name: "man:_curly_hair",
    code: "1f468_200d_1f9b1",
    keywords: ["adult", "curly hair", "man"]
  },
  "1f468_200d_1f9b3": {
    name: "man:_white_hair",
    code: "1f468_200d_1f9b3",
    keywords: ["adult", "man", "white hair"]
  },
  "1f468_200d_1f9b2": {
    name: "man:_bald",
    code: "1f468_200d_1f9b2",
    keywords: ["adult", "bald", "man"]
  },
  "1f469": { name: "woman", code: "1f469", keywords: ["adult", "woman"] },
  "1f469_200d_1f9b0": {
    name: "woman:_red_hair",
    code: "1f469_200d_1f9b0",
    keywords: ["adult", "red hair", "woman"]
  },
  "1f9d1_200d_1f9b0": {
    name: "person:_red_hair",
    code: "1f9d1_200d_1f9b0",
    keywords: [
      "adult",
      "gender-neutral",
      "person",
      "red hair",
      "unspecified gender"
    ]
  },
  "1f469_200d_1f9b1": {
    name: "woman:_curly_hair",
    code: "1f469_200d_1f9b1",
    keywords: ["adult", "curly hair", "woman"]
  },
  "1f9d1_200d_1f9b1": {
    name: "person:_curly_hair",
    code: "1f9d1_200d_1f9b1",
    keywords: [
      "adult",
      "curly hair",
      "gender-neutral",
      "person",
      "unspecified gender"
    ]
  },
  "1f469_200d_1f9b3": {
    name: "woman:_white_hair",
    code: "1f469_200d_1f9b3",
    keywords: ["adult", "white hair", "woman"]
  },
  "1f9d1_200d_1f9b3": {
    name: "person:_white_hair",
    code: "1f9d1_200d_1f9b3",
    keywords: [
      "adult",
      "gender-neutral",
      "person",
      "unspecified gender",
      "white hair"
    ]
  },
  "1f469_200d_1f9b2": {
    name: "woman:_bald",
    code: "1f469_200d_1f9b2",
    keywords: ["adult", "bald", "woman"]
  },
  "1f9d1_200d_1f9b2": {
    name: "person:_bald",
    code: "1f9d1_200d_1f9b2",
    keywords: [
      "adult",
      "bald",
      "gender-neutral",
      "person",
      "unspecified gender"
    ]
  },
  "1f471_200d_2640_fe0f": {
    name: "woman:_blond_hair",
    code: "1f471_200d_2640_fe0f",
    keywords: [
      "blond-haired woman",
      "blonde",
      "hair",
      "woman",
      "woman: blond hair"
    ]
  },
  "1f471_200d_2642_fe0f": {
    name: "man:_blond_hair",
    code: "1f471_200d_2642_fe0f",
    keywords: ["blond", "blond-haired man", "hair", "man", "man: blond hair"]
  },
  "1f9d3": {
    name: "older_person",
    code: "1f9d3",
    keywords: [
      "adult",
      "gender-neutral",
      "old",
      "older person",
      "unspecified gender"
    ]
  },
  "1f474": {
    name: "old_man",
    code: "1f474",
    keywords: ["adult", "man", "old"]
  },
  "1f475": {
    name: "old_woman",
    code: "1f475",
    keywords: ["adult", "old", "woman"]
  },
  "1f64d": {
    name: "person_frowning",
    code: "1f64d",
    keywords: ["frown", "gesture", "person frowning"]
  },
  "1f64d_200d_2642_fe0f": {
    name: "man_frowning",
    code: "1f64d_200d_2642_fe0f",
    keywords: ["frowning", "gesture", "man"]
  },
  "1f64d_200d_2640_fe0f": {
    name: "woman_frowning",
    code: "1f64d_200d_2640_fe0f",
    keywords: ["frowning", "gesture", "woman"]
  },
  "1f64e": {
    name: "person_pouting",
    code: "1f64e",
    keywords: ["gesture", "person pouting", "pouting"]
  },
  "1f64e_200d_2642_fe0f": {
    name: "man_pouting",
    code: "1f64e_200d_2642_fe0f",
    keywords: ["gesture", "man", "pouting"]
  },
  "1f64e_200d_2640_fe0f": {
    name: "woman_pouting",
    code: "1f64e_200d_2640_fe0f",
    keywords: ["gesture", "pouting", "woman"]
  },
  "1f645": {
    name: "person_gesturing_NO",
    code: "1f645",
    keywords: [
      "forbidden",
      "gesture",
      "hand",
      "person gesturing NO",
      "prohibited"
    ]
  },
  "1f645_200d_2642_fe0f": {
    name: "man_gesturing_NO",
    code: "1f645_200d_2642_fe0f",
    keywords: [
      "forbidden",
      "gesture",
      "hand",
      "man",
      "man gesturing NO",
      "prohibited"
    ]
  },
  "1f645_200d_2640_fe0f": {
    name: "woman_gesturing_NO",
    code: "1f645_200d_2640_fe0f",
    keywords: [
      "forbidden",
      "gesture",
      "hand",
      "prohibited",
      "woman",
      "woman gesturing NO"
    ]
  },
  "1f646": {
    name: "person_gesturing_OK",
    code: "1f646",
    keywords: ["gesture", "hand", "OK", "person gesturing OK"]
  },
  "1f646_200d_2642_fe0f": {
    name: "man_gesturing_OK",
    code: "1f646_200d_2642_fe0f",
    keywords: ["gesture", "hand", "man", "man gesturing OK", "OK"]
  },
  "1f646_200d_2640_fe0f": {
    name: "woman_gesturing_OK",
    code: "1f646_200d_2640_fe0f",
    keywords: ["gesture", "hand", "OK", "woman", "woman gesturing OK"]
  },
  "1f481": {
    name: "person_tipping_hand",
    code: "1f481",
    keywords: [
      "hand",
      "help",
      "information",
      "person tipping hand",
      "sassy",
      "tipping"
    ]
  },
  "1f481_200d_2642_fe0f": {
    name: "man_tipping_hand",
    code: "1f481_200d_2642_fe0f",
    keywords: ["man", "man tipping hand", "sassy", "tipping hand"]
  },
  "1f481_200d_2640_fe0f": {
    name: "woman_tipping_hand",
    code: "1f481_200d_2640_fe0f",
    keywords: ["sassy", "tipping hand", "woman", "woman tipping hand"]
  },
  "1f64b": {
    name: "person_raising_hand",
    code: "1f64b",
    keywords: ["gesture", "hand", "happy", "person raising hand", "raised"]
  },
  "1f64b_200d_2642_fe0f": {
    name: "man_raising_hand",
    code: "1f64b_200d_2642_fe0f",
    keywords: ["gesture", "man", "man raising hand", "raising hand"]
  },
  "1f64b_200d_2640_fe0f": {
    name: "woman_raising_hand",
    code: "1f64b_200d_2640_fe0f",
    keywords: ["gesture", "raising hand", "woman", "woman raising hand"]
  },
  "1f9cf": {
    name: "deaf_person",
    code: "1f9cf",
    keywords: ["accessibility", "deaf", "deaf person", "ear", "hear"]
  },
  "1f9cf_200d_2642_fe0f": {
    name: "deaf_man",
    code: "1f9cf_200d_2642_fe0f",
    keywords: ["deaf", "man"]
  },
  "1f9cf_200d_2640_fe0f": {
    name: "deaf_woman",
    code: "1f9cf_200d_2640_fe0f",
    keywords: ["deaf", "woman"]
  },
  "1f647": {
    name: "person_bowing",
    code: "1f647",
    keywords: ["apology", "bow", "gesture", "person bowing", "sorry"]
  },
  "1f647_200d_2642_fe0f": {
    name: "man_bowing",
    code: "1f647_200d_2642_fe0f",
    keywords: ["apology", "bowing", "favor", "gesture", "man", "sorry"]
  },
  "1f647_200d_2640_fe0f": {
    name: "woman_bowing",
    code: "1f647_200d_2640_fe0f",
    keywords: ["apology", "bowing", "favor", "gesture", "sorry", "woman"]
  },
  "1f926": {
    name: "person_facepalming",
    code: "1f926",
    keywords: [
      "disbelief",
      "exasperation",
      "face",
      "palm",
      "person facepalming"
    ]
  },
  "1f926_200d_2642_fe0f": {
    name: "man_facepalming",
    code: "1f926_200d_2642_fe0f",
    keywords: [
      "disbelief",
      "exasperation",
      "facepalm",
      "man",
      "man facepalming"
    ]
  },
  "1f926_200d_2640_fe0f": {
    name: "woman_facepalming",
    code: "1f926_200d_2640_fe0f",
    keywords: [
      "disbelief",
      "exasperation",
      "facepalm",
      "woman",
      "woman facepalming"
    ]
  },
  "1f937": {
    name: "person_shrugging",
    code: "1f937",
    keywords: [
      "doubt",
      "ignorance",
      "indifference",
      "person shrugging",
      "shrug"
    ]
  },
  "1f937_200d_2642_fe0f": {
    name: "man_shrugging",
    code: "1f937_200d_2642_fe0f",
    keywords: [
      "doubt",
      "ignorance",
      "indifference",
      "man",
      "man shrugging",
      "shrug"
    ]
  },
  "1f937_200d_2640_fe0f": {
    name: "woman_shrugging",
    code: "1f937_200d_2640_fe0f",
    keywords: [
      "doubt",
      "ignorance",
      "indifference",
      "shrug",
      "woman",
      "woman shrugging"
    ]
  },
  "1f9d1_200d_2695_fe0f": {
    name: "health_worker",
    code: "1f9d1_200d_2695_fe0f",
    keywords: ["doctor", "health worker", "healthcare", "nurse", "therapist"]
  },
  "1f468_200d_2695_fe0f": {
    name: "man_health_worker",
    code: "1f468_200d_2695_fe0f",
    keywords: [
      "doctor",
      "healthcare",
      "man",
      "man health worker",
      "nurse",
      "therapist"
    ]
  },
  "1f469_200d_2695_fe0f": {
    name: "woman_health_worker",
    code: "1f469_200d_2695_fe0f",
    keywords: [
      "doctor",
      "healthcare",
      "nurse",
      "therapist",
      "woman",
      "woman health worker"
    ]
  },
  "1f9d1_200d_1f393": {
    name: "student",
    code: "1f9d1_200d_1f393",
    keywords: ["graduate", "student"]
  },
  "1f468_200d_1f393": {
    name: "man_student",
    code: "1f468_200d_1f393",
    keywords: ["graduate", "man", "student"]
  },
  "1f469_200d_1f393": {
    name: "woman_student",
    code: "1f469_200d_1f393",
    keywords: ["graduate", "student", "woman"]
  },
  "1f9d1_200d_1f3eb": {
    name: "teacher",
    code: "1f9d1_200d_1f3eb",
    keywords: ["instructor", "professor", "teacher"]
  },
  "1f468_200d_1f3eb": {
    name: "man_teacher",
    code: "1f468_200d_1f3eb",
    keywords: ["instructor", "man", "professor", "teacher"]
  },
  "1f469_200d_1f3eb": {
    name: "woman_teacher",
    code: "1f469_200d_1f3eb",
    keywords: ["instructor", "professor", "teacher", "woman"]
  },
  "1f9d1_200d_2696_fe0f": {
    name: "judge",
    code: "1f9d1_200d_2696_fe0f",
    keywords: ["judge", "scales"]
  },
  "1f468_200d_2696_fe0f": {
    name: "man_judge",
    code: "1f468_200d_2696_fe0f",
    keywords: ["justice", "man", "man judge", "scales"]
  },
  "1f469_200d_2696_fe0f": {
    name: "woman_judge",
    code: "1f469_200d_2696_fe0f",
    keywords: ["judge", "scales", "woman"]
  },
  "1f9d1_200d_1f33e": {
    name: "farmer",
    code: "1f9d1_200d_1f33e",
    keywords: ["farmer", "gardener", "rancher"]
  },
  "1f468_200d_1f33e": {
    name: "man_farmer",
    code: "1f468_200d_1f33e",
    keywords: ["farmer", "gardener", "man", "rancher"]
  },
  "1f469_200d_1f33e": {
    name: "woman_farmer",
    code: "1f469_200d_1f33e",
    keywords: ["farmer", "gardener", "rancher", "woman"]
  },
  "1f9d1_200d_1f373": {
    name: "cook",
    code: "1f9d1_200d_1f373",
    keywords: ["chef", "cook"]
  },
  "1f468_200d_1f373": {
    name: "man_cook",
    code: "1f468_200d_1f373",
    keywords: ["chef", "cook", "man"]
  },
  "1f469_200d_1f373": {
    name: "woman_cook",
    code: "1f469_200d_1f373",
    keywords: ["chef", "cook", "woman"]
  },
  "1f9d1_200d_1f527": {
    name: "mechanic",
    code: "1f9d1_200d_1f527",
    keywords: ["electrician", "mechanic", "plumber", "tradesperson"]
  },
  "1f468_200d_1f527": {
    name: "man_mechanic",
    code: "1f468_200d_1f527",
    keywords: ["electrician", "man", "mechanic", "plumber", "tradesperson"]
  },
  "1f469_200d_1f527": {
    name: "woman_mechanic",
    code: "1f469_200d_1f527",
    keywords: ["electrician", "mechanic", "plumber", "tradesperson", "woman"]
  },
  "1f9d1_200d_1f3ed": {
    name: "factory_worker",
    code: "1f9d1_200d_1f3ed",
    keywords: ["assembly", "factory", "industrial", "worker"]
  },
  "1f468_200d_1f3ed": {
    name: "man_factory_worker",
    code: "1f468_200d_1f3ed",
    keywords: ["assembly", "factory", "industrial", "man", "worker"]
  },
  "1f469_200d_1f3ed": {
    name: "woman_factory_worker",
    code: "1f469_200d_1f3ed",
    keywords: ["assembly", "factory", "industrial", "woman", "worker"]
  },
  "1f9d1_200d_1f4bc": {
    name: "office_worker",
    code: "1f9d1_200d_1f4bc",
    keywords: [
      "architect",
      "business",
      "manager",
      "office worker",
      "white-collar"
    ]
  },
  "1f468_200d_1f4bc": {
    name: "man_office_worker",
    code: "1f468_200d_1f4bc",
    keywords: [
      "architect",
      "business",
      "man",
      "man office worker",
      "manager",
      "white-collar"
    ]
  },
  "1f469_200d_1f4bc": {
    name: "woman_office_worker",
    code: "1f469_200d_1f4bc",
    keywords: [
      "architect",
      "business",
      "manager",
      "white-collar",
      "woman",
      "woman office worker"
    ]
  },
  "1f9d1_200d_1f52c": {
    name: "scientist",
    code: "1f9d1_200d_1f52c",
    keywords: ["biologist", "chemist", "engineer", "physicist", "scientist"]
  },
  "1f468_200d_1f52c": {
    name: "man_scientist",
    code: "1f468_200d_1f52c",
    keywords: [
      "biologist",
      "chemist",
      "engineer",
      "man",
      "physicist",
      "scientist"
    ]
  },
  "1f469_200d_1f52c": {
    name: "woman_scientist",
    code: "1f469_200d_1f52c",
    keywords: [
      "biologist",
      "chemist",
      "engineer",
      "physicist",
      "scientist",
      "woman"
    ]
  },
  "1f9d1_200d_1f4bb": {
    name: "technologist",
    code: "1f9d1_200d_1f4bb",
    keywords: ["coder", "developer", "inventor", "software", "technologist"]
  },
  "1f468_200d_1f4bb": {
    name: "man_technologist",
    code: "1f468_200d_1f4bb",
    keywords: [
      "coder",
      "developer",
      "inventor",
      "man",
      "software",
      "technologist"
    ]
  },
  "1f469_200d_1f4bb": {
    name: "woman_technologist",
    code: "1f469_200d_1f4bb",
    keywords: [
      "coder",
      "developer",
      "inventor",
      "software",
      "technologist",
      "woman"
    ]
  },
  "1f9d1_200d_1f3a4": {
    name: "singer",
    code: "1f9d1_200d_1f3a4",
    keywords: ["actor", "entertainer", "rock", "singer", "star"]
  },
  "1f468_200d_1f3a4": {
    name: "man_singer",
    code: "1f468_200d_1f3a4",
    keywords: ["actor", "entertainer", "man", "rock", "singer", "star"]
  },
  "1f469_200d_1f3a4": {
    name: "woman_singer",
    code: "1f469_200d_1f3a4",
    keywords: ["actor", "entertainer", "rock", "singer", "star", "woman"]
  },
  "1f9d1_200d_1f3a8": {
    name: "artist",
    code: "1f9d1_200d_1f3a8",
    keywords: ["artist", "palette"]
  },
  "1f468_200d_1f3a8": {
    name: "man_artist",
    code: "1f468_200d_1f3a8",
    keywords: ["artist", "man", "palette"]
  },
  "1f469_200d_1f3a8": {
    name: "woman_artist",
    code: "1f469_200d_1f3a8",
    keywords: ["artist", "palette", "woman"]
  },
  "1f9d1_200d_2708_fe0f": {
    name: "pilot",
    code: "1f9d1_200d_2708_fe0f",
    keywords: ["pilot", "plane"]
  },
  "1f468_200d_2708_fe0f": {
    name: "man_pilot",
    code: "1f468_200d_2708_fe0f",
    keywords: ["man", "pilot", "plane"]
  },
  "1f469_200d_2708_fe0f": {
    name: "woman_pilot",
    code: "1f469_200d_2708_fe0f",
    keywords: ["pilot", "plane", "woman"]
  },
  "1f9d1_200d_1f680": {
    name: "astronaut",
    code: "1f9d1_200d_1f680",
    keywords: ["astronaut", "rocket"]
  },
  "1f468_200d_1f680": {
    name: "man_astronaut",
    code: "1f468_200d_1f680",
    keywords: ["astronaut", "man", "rocket"]
  },
  "1f469_200d_1f680": {
    name: "woman_astronaut",
    code: "1f469_200d_1f680",
    keywords: ["astronaut", "rocket", "woman"]
  },
  "1f9d1_200d_1f692": {
    name: "firefighter",
    code: "1f9d1_200d_1f692",
    keywords: ["firefighter", "firetruck"]
  },
  "1f468_200d_1f692": {
    name: "man_firefighter",
    code: "1f468_200d_1f692",
    keywords: ["firefighter", "firetruck", "man"]
  },
  "1f469_200d_1f692": {
    name: "woman_firefighter",
    code: "1f469_200d_1f692",
    keywords: ["firefighter", "firetruck", "woman"]
  },
  "1f46e": {
    name: "police_officer",
    code: "1f46e",
    keywords: ["cop", "officer", "police"]
  },
  "1f46e_200d_2642_fe0f": {
    name: "man_police_officer",
    code: "1f46e_200d_2642_fe0f",
    keywords: ["cop", "man", "officer", "police"]
  },
  "1f46e_200d_2640_fe0f": {
    name: "woman_police_officer",
    code: "1f46e_200d_2640_fe0f",
    keywords: ["cop", "officer", "police", "woman"]
  },
  "1f575": {
    name: "detective",
    code: "1f575",
    keywords: ["detective", "sleuth", "spy"]
  },
  "1f575_fe0f_200d_2642_fe0f": {
    name: "man_detective",
    code: "1f575_fe0f_200d_2642_fe0f",
    keywords: ["detective", "man", "sleuth", "spy"]
  },
  "1f575_fe0f_200d_2640_fe0f": {
    name: "woman_detective",
    code: "1f575_fe0f_200d_2640_fe0f",
    keywords: ["detective", "sleuth", "spy", "woman"]
  },
  "1f482": { name: "guard", code: "1f482", keywords: ["guard"] },
  "1f482_200d_2642_fe0f": {
    name: "man_guard",
    code: "1f482_200d_2642_fe0f",
    keywords: ["guard", "man"]
  },
  "1f482_200d_2640_fe0f": {
    name: "woman_guard",
    code: "1f482_200d_2640_fe0f",
    keywords: ["guard", "woman"]
  },
  "1f977": {
    name: "\u229b_ninja",
    code: "1f977",
    keywords: ["fighter", "hidden", "ninja", "stealth"]
  },
  "1f477": {
    name: "construction_worker",
    code: "1f477",
    keywords: ["construction", "hat", "worker"]
  },
  "1f477_200d_2642_fe0f": {
    name: "man_construction_worker",
    code: "1f477_200d_2642_fe0f",
    keywords: ["construction", "man", "worker"]
  },
  "1f477_200d_2640_fe0f": {
    name: "woman_construction_worker",
    code: "1f477_200d_2640_fe0f",
    keywords: ["construction", "woman", "worker"]
  },
  "1f934": { name: "prince", code: "1f934", keywords: ["prince"] },
  "1f478": {
    name: "princess",
    code: "1f478",
    keywords: ["fairy tale", "fantasy", "princess"]
  },
  "1f473": {
    name: "person_wearing_turban",
    code: "1f473",
    keywords: ["person wearing turban", "turban"]
  },
  "1f473_200d_2642_fe0f": {
    name: "man_wearing_turban",
    code: "1f473_200d_2642_fe0f",
    keywords: ["man", "man wearing turban", "turban"]
  },
  "1f473_200d_2640_fe0f": {
    name: "woman_wearing_turban",
    code: "1f473_200d_2640_fe0f",
    keywords: ["turban", "woman", "woman wearing turban"]
  },
  "1f472": {
    name: "person_with_skullcap",
    code: "1f472",
    keywords: [
      "cap",
      "gua pi mao",
      "hat",
      "person",
      "person with skullcap",
      "skullcap"
    ]
  },
  "1f9d5": {
    name: "woman_with_headscarf",
    code: "1f9d5",
    keywords: [
      "headscarf",
      "hijab",
      "mantilla",
      "tichel",
      "woman with headscarf",
      ""
    ]
  },
  "1f935": {
    name: "person_in_tuxedo",
    code: "1f935",
    keywords: ["groom", "person", "person in tuxedo", "tuxedo"]
  },
  "1f935_200d_2642_fe0f": {
    name: "\u229b_man_in_tuxedo",
    code: "1f935_200d_2642_fe0f",
    keywords: ["man", "man in tuxedo", "tuxedo"]
  },
  "1f935_200d_2640_fe0f": {
    name: "\u229b_woman_in_tuxedo",
    code: "1f935_200d_2640_fe0f",
    keywords: ["tuxedo", "woman", "woman in tuxedo"]
  },
  "1f470": {
    name: "person_with_veil",
    code: "1f470",
    keywords: ["bride", "person", "person with veil", "veil", "wedding"]
  },
  "1f470_200d_2642_fe0f": {
    name: "\u229b_man_with_veil",
    code: "1f470_200d_2642_fe0f",
    keywords: ["man", "man with veil", "veil"]
  },
  "1f470_200d_2640_fe0f": {
    name: "\u229b_woman_with_veil",
    code: "1f470_200d_2640_fe0f",
    keywords: ["veil", "woman", "woman with veil"]
  },
  "1f930": {
    name: "pregnant_woman",
    code: "1f930",
    keywords: ["pregnant", "woman"]
  },
  "1f931": {
    name: "breast-feeding",
    code: "1f931",
    keywords: ["baby", "breast", "breast-feeding", "nursing"]
  },
  "1f469_200d_1f37c": {
    name: "\u229b_woman_feeding_baby",
    code: "1f469_200d_1f37c",
    keywords: ["baby", "feeding", "nursing", "woman"]
  },
  "1f468_200d_1f37c": {
    name: "\u229b_man_feeding_baby",
    code: "1f468_200d_1f37c",
    keywords: ["baby", "feeding", "man", "nursing"]
  },
  "1f9d1_200d_1f37c": {
    name: "\u229b_person_feeding_baby",
    code: "1f9d1_200d_1f37c",
    keywords: ["baby, feeding, nursing, person", "person feeding baby"]
  },
  "1f47c": {
    name: "baby_angel",
    code: "1f47c",
    keywords: ["angel", "baby", "face", "fairy tale", "fantasy"]
  },
  "1f385": {
    name: "Santa_Claus",
    code: "1f385",
    keywords: [
      "celebration",
      "Christmas",
      "claus",
      "father",
      "santa",
      "Santa Claus"
    ]
  },
  "1f936": {
    name: "Mrs._Claus",
    code: "1f936",
    keywords: [
      "celebration",
      "Christmas",
      "claus",
      "mother",
      "Mrs.",
      "Mrs. Claus"
    ]
  },
  "1f9d1_200d_1f384": {
    name: "\u229b_mx_claus",
    code: "1f9d1_200d_1f384",
    keywords: ["Claus, christmas", "mx claus"]
  },
  "1f9b8": {
    name: "superhero",
    code: "1f9b8",
    keywords: ["good", "hero", "heroine", "superhero", "superpower"]
  },
  "1f9b8_200d_2642_fe0f": {
    name: "man_superhero",
    code: "1f9b8_200d_2642_fe0f",
    keywords: ["good", "hero", "man", "man superhero", "superpower"]
  },
  "1f9b8_200d_2640_fe0f": {
    name: "woman_superhero",
    code: "1f9b8_200d_2640_fe0f",
    keywords: [
      "good",
      "hero",
      "heroine",
      "superpower",
      "woman",
      "woman superhero"
    ]
  },
  "1f9b9": {
    name: "supervillain",
    code: "1f9b9",
    keywords: ["criminal", "evil", "superpower", "supervillain", "villain"]
  },
  "1f9b9_200d_2642_fe0f": {
    name: "man_supervillain",
    code: "1f9b9_200d_2642_fe0f",
    keywords: [
      "criminal",
      "evil",
      "man",
      "man supervillain",
      "superpower",
      "villain"
    ]
  },
  "1f9b9_200d_2640_fe0f": {
    name: "woman_supervillain",
    code: "1f9b9_200d_2640_fe0f",
    keywords: [
      "criminal",
      "evil",
      "superpower",
      "villain",
      "woman",
      "woman supervillain"
    ]
  },
  "1f9d9": {
    name: "mage",
    code: "1f9d9",
    keywords: ["mage", "sorcerer", "sorceress", "witch", "wizard"]
  },
  "1f9d9_200d_2642_fe0f": {
    name: "man_mage",
    code: "1f9d9_200d_2642_fe0f",
    keywords: ["man mage", "sorcerer", "wizard"]
  },
  "1f9d9_200d_2640_fe0f": {
    name: "woman_mage",
    code: "1f9d9_200d_2640_fe0f",
    keywords: ["sorceress", "witch", "woman mage"]
  },
  "1f9da": {
    name: "fairy",
    code: "1f9da",
    keywords: ["fairy", "Oberon", "Puck", "Titania"]
  },
  "1f9da_200d_2642_fe0f": {
    name: "man_fairy",
    code: "1f9da_200d_2642_fe0f",
    keywords: ["man fairy", "Oberon", "Puck"]
  },
  "1f9da_200d_2640_fe0f": {
    name: "woman_fairy",
    code: "1f9da_200d_2640_fe0f",
    keywords: ["Titania", "woman fairy"]
  },
  "1f9db": {
    name: "vampire",
    code: "1f9db",
    keywords: ["Dracula", "undead", "vampire"]
  },
  "1f9db_200d_2642_fe0f": {
    name: "man_vampire",
    code: "1f9db_200d_2642_fe0f",
    keywords: ["Dracula", "man vampire", "undead"]
  },
  "1f9db_200d_2640_fe0f": {
    name: "woman_vampire",
    code: "1f9db_200d_2640_fe0f",
    keywords: ["undead", "woman vampire"]
  },
  "1f9dc": {
    name: "merperson",
    code: "1f9dc",
    keywords: ["mermaid", "merman", "merperson", "merwoman"]
  },
  "1f9dc_200d_2642_fe0f": {
    name: "merman",
    code: "1f9dc_200d_2642_fe0f",
    keywords: ["merman", "Triton"]
  },
  "1f9dc_200d_2640_fe0f": {
    name: "mermaid",
    code: "1f9dc_200d_2640_fe0f",
    keywords: ["mermaid", "merwoman"]
  },
  "1f9dd": { name: "elf", code: "1f9dd", keywords: ["elf", "magical", ""] },
  "1f9dd_200d_2642_fe0f": {
    name: "man_elf",
    code: "1f9dd_200d_2642_fe0f",
    keywords: ["magical", "man elf"]
  },
  "1f9dd_200d_2640_fe0f": {
    name: "woman_elf",
    code: "1f9dd_200d_2640_fe0f",
    keywords: ["magical", "woman elf"]
  },
  "1f9de": { name: "genie", code: "1f9de", keywords: ["djinn", "genie", ""] },
  "1f9de_200d_2642_fe0f": {
    name: "man_genie",
    code: "1f9de_200d_2642_fe0f",
    keywords: ["djinn", "man genie"]
  },
  "1f9de_200d_2640_fe0f": {
    name: "woman_genie",
    code: "1f9de_200d_2640_fe0f",
    keywords: ["djinn", "woman genie"]
  },
  "1f9df": {
    name: "zombie",
    code: "1f9df",
    keywords: ["undead", "walking dead", "zombie", ""]
  },
  "1f9df_200d_2642_fe0f": {
    name: "man_zombie",
    code: "1f9df_200d_2642_fe0f",
    keywords: ["man zombie", "undead", "walking dead"]
  },
  "1f9df_200d_2640_fe0f": {
    name: "woman_zombie",
    code: "1f9df_200d_2640_fe0f",
    keywords: ["undead", "walking dead", "woman zombie"]
  },
  "1f486": {
    name: "person_getting_massage",
    code: "1f486",
    keywords: ["face", "massage", "person getting massage", "salon"]
  },
  "1f486_200d_2642_fe0f": {
    name: "man_getting_massage",
    code: "1f486_200d_2642_fe0f",
    keywords: ["face", "man", "man getting massage", "massage"]
  },
  "1f486_200d_2640_fe0f": {
    name: "woman_getting_massage",
    code: "1f486_200d_2640_fe0f",
    keywords: ["face", "massage", "woman", "woman getting massage"]
  },
  "1f487": {
    name: "person_getting_haircut",
    code: "1f487",
    keywords: [
      "barber",
      "beauty",
      "haircut",
      "parlor",
      "person getting haircut"
    ]
  },
  "1f487_200d_2642_fe0f": {
    name: "man_getting_haircut",
    code: "1f487_200d_2642_fe0f",
    keywords: ["haircut", "man", "man getting haircut"]
  },
  "1f487_200d_2640_fe0f": {
    name: "woman_getting_haircut",
    code: "1f487_200d_2640_fe0f",
    keywords: ["haircut", "woman", "woman getting haircut"]
  },
  "1f6b6": {
    name: "person_walking",
    code: "1f6b6",
    keywords: ["hike", "person walking", "walk", "walking"]
  },
  "1f6b6_200d_2642_fe0f": {
    name: "man_walking",
    code: "1f6b6_200d_2642_fe0f",
    keywords: ["hike", "man", "man walking", "walk"]
  },
  "1f6b6_200d_2640_fe0f": {
    name: "woman_walking",
    code: "1f6b6_200d_2640_fe0f",
    keywords: ["hike", "walk", "woman", "woman walking"]
  },
  "1f9cd": {
    name: "person_standing",
    code: "1f9cd",
    keywords: ["person standing", "stand", "standing"]
  },
  "1f9cd_200d_2642_fe0f": {
    name: "man_standing",
    code: "1f9cd_200d_2642_fe0f",
    keywords: ["man", "standing"]
  },
  "1f9cd_200d_2640_fe0f": {
    name: "woman_standing",
    code: "1f9cd_200d_2640_fe0f",
    keywords: ["standing", "woman"]
  },
  "1f9ce": {
    name: "person_kneeling",
    code: "1f9ce",
    keywords: ["kneel", "kneeling", "person kneeling"]
  },
  "1f9ce_200d_2642_fe0f": {
    name: "man_kneeling",
    code: "1f9ce_200d_2642_fe0f",
    keywords: ["kneeling", "man"]
  },
  "1f9ce_200d_2640_fe0f": {
    name: "woman_kneeling",
    code: "1f9ce_200d_2640_fe0f",
    keywords: ["kneeling", "woman"]
  },
  "1f9d1_200d_1f9af": {
    name: "person_with_white_cane",
    code: "1f9d1_200d_1f9af",
    keywords: ["accessibility", "blind", "person with white cane"]
  },
  "1f468_200d_1f9af": {
    name: "man_with_white_cane",
    code: "1f468_200d_1f9af",
    keywords: ["accessibility", "blind", "man", "man with white cane"]
  },
  "1f469_200d_1f9af": {
    name: "woman_with_white_cane",
    code: "1f469_200d_1f9af",
    keywords: ["accessibility", "blind", "woman", "woman with white cane"]
  },
  "1f9d1_200d_1f9bc": {
    name: "person_in_motorized_wheelchair",
    code: "1f9d1_200d_1f9bc",
    keywords: ["accessibility", "person in motorized wheelchair", "wheelchair"]
  },
  "1f468_200d_1f9bc": {
    name: "man_in_motorized_wheelchair",
    code: "1f468_200d_1f9bc",
    keywords: [
      "accessibility",
      "man",
      "man in motorized wheelchair",
      "wheelchair"
    ]
  },
  "1f469_200d_1f9bc": {
    name: "woman_in_motorized_wheelchair",
    code: "1f469_200d_1f9bc",
    keywords: [
      "accessibility",
      "wheelchair",
      "woman",
      "woman in motorized wheelchair"
    ]
  },
  "1f9d1_200d_1f9bd": {
    name: "person_in_manual_wheelchair",
    code: "1f9d1_200d_1f9bd",
    keywords: ["accessibility", "person in manual wheelchair", "wheelchair"]
  },
  "1f468_200d_1f9bd": {
    name: "man_in_manual_wheelchair",
    code: "1f468_200d_1f9bd",
    keywords: ["accessibility", "man", "man in manual wheelchair", "wheelchair"]
  },
  "1f469_200d_1f9bd": {
    name: "woman_in_manual_wheelchair",
    code: "1f469_200d_1f9bd",
    keywords: [
      "accessibility",
      "wheelchair",
      "woman",
      "woman in manual wheelchair"
    ]
  },
  "1f3c3": {
    name: "person_running",
    code: "1f3c3",
    keywords: ["marathon", "person running", "running"]
  },
  "1f3c3_200d_2642_fe0f": {
    name: "man_running",
    code: "1f3c3_200d_2642_fe0f",
    keywords: ["man", "marathon", "racing", "running"]
  },
  "1f3c3_200d_2640_fe0f": {
    name: "woman_running",
    code: "1f3c3_200d_2640_fe0f",
    keywords: ["marathon", "racing", "running", "woman"]
  },
  "1f483": {
    name: "woman_dancing",
    code: "1f483",
    keywords: ["dancing", "woman"]
  },
  "1f57a": {
    name: "man_dancing",
    code: "1f57a",
    keywords: ["dance", "man", "man dancing"]
  },
  "1f574": {
    name: "person_in_suit_levitating",
    code: "1f574",
    keywords: ["business", "person", "person in suit levitating", "suit"]
  },
  "1f46f": {
    name: "people_with_bunny_ears",
    code: "1f46f",
    keywords: ["bunny ear", "dancer", "partying", "people with bunny ears"]
  },
  "1f46f_200d_2642_fe0f": {
    name: "men_with_bunny_ears",
    code: "1f46f_200d_2642_fe0f",
    keywords: ["bunny ear", "dancer", "men", "men with bunny ears", "partying"]
  },
  "1f46f_200d_2640_fe0f": {
    name: "women_with_bunny_ears",
    code: "1f46f_200d_2640_fe0f",
    keywords: [
      "bunny ear",
      "dancer",
      "partying",
      "women",
      "women with bunny ears"
    ]
  },
  "1f9d6": {
    name: "person_in_steamy_room",
    code: "1f9d6",
    keywords: ["person in steamy room", "sauna", "steam room", ""]
  },
  "1f9d6_200d_2642_fe0f": {
    name: "man_in_steamy_room",
    code: "1f9d6_200d_2642_fe0f",
    keywords: ["man in steamy room", "sauna", "steam room"]
  },
  "1f9d6_200d_2640_fe0f": {
    name: "woman_in_steamy_room",
    code: "1f9d6_200d_2640_fe0f",
    keywords: ["sauna", "steam room", "woman in steamy room"]
  },
  "1f9d7": {
    name: "person_climbing",
    code: "1f9d7",
    keywords: ["climber", "person climbing"]
  },
  "1f9d7_200d_2642_fe0f": {
    name: "man_climbing",
    code: "1f9d7_200d_2642_fe0f",
    keywords: ["climber", "man climbing"]
  },
  "1f9d7_200d_2640_fe0f": {
    name: "woman_climbing",
    code: "1f9d7_200d_2640_fe0f",
    keywords: ["climber", "woman climbing"]
  },
  "1f93a": {
    name: "person_fencing",
    code: "1f93a",
    keywords: ["fencer", "fencing", "person fencing", "sword"]
  },
  "1f3c7": {
    name: "horse_racing",
    code: "1f3c7",
    keywords: ["horse", "jockey", "racehorse", "racing"]
  },
  "26f7": { name: "skier", code: "26f7", keywords: ["ski", "skier", "snow"] },
  "1f3c2": {
    name: "snowboarder",
    code: "1f3c2",
    keywords: ["ski", "snow", "snowboard", "snowboarder"]
  },
  "1f3cc": {
    name: "person_golfing",
    code: "1f3cc",
    keywords: ["ball", "golf", "person golfing"]
  },
  "1f3cc_fe0f_200d_2642_fe0f": {
    name: "man_golfing",
    code: "1f3cc_fe0f_200d_2642_fe0f",
    keywords: ["golf", "man", "man golfing"]
  },
  "1f3cc_fe0f_200d_2640_fe0f": {
    name: "woman_golfing",
    code: "1f3cc_fe0f_200d_2640_fe0f",
    keywords: ["golf", "woman", "woman golfing"]
  },
  "1f3c4": {
    name: "person_surfing",
    code: "1f3c4",
    keywords: ["person surfing", "surfing"]
  },
  "1f3c4_200d_2642_fe0f": {
    name: "man_surfing",
    code: "1f3c4_200d_2642_fe0f",
    keywords: ["man", "surfing"]
  },
  "1f3c4_200d_2640_fe0f": {
    name: "woman_surfing",
    code: "1f3c4_200d_2640_fe0f",
    keywords: ["surfing", "woman"]
  },
  "1f6a3": {
    name: "person_rowing_boat",
    code: "1f6a3",
    keywords: ["boat", "person rowing boat", "rowboat"]
  },
  "1f6a3_200d_2642_fe0f": {
    name: "man_rowing_boat",
    code: "1f6a3_200d_2642_fe0f",
    keywords: ["boat", "man", "man rowing boat", "rowboat"]
  },
  "1f6a3_200d_2640_fe0f": {
    name: "woman_rowing_boat",
    code: "1f6a3_200d_2640_fe0f",
    keywords: ["boat", "rowboat", "woman", "woman rowing boat"]
  },
  "1f3ca": {
    name: "person_swimming",
    code: "1f3ca",
    keywords: ["person swimming", "swim"]
  },
  "1f3ca_200d_2642_fe0f": {
    name: "man_swimming",
    code: "1f3ca_200d_2642_fe0f",
    keywords: ["man", "man swimming", "swim"]
  },
  "1f3ca_200d_2640_fe0f": {
    name: "woman_swimming",
    code: "1f3ca_200d_2640_fe0f",
    keywords: ["swim", "woman", "woman swimming"]
  },
  "26f9": {
    name: "person_bouncing_ball",
    code: "26f9",
    keywords: ["ball", "person bouncing ball"]
  },
  "26f9_fe0f_200d_2642_fe0f": {
    name: "man_bouncing_ball",
    code: "26f9_fe0f_200d_2642_fe0f",
    keywords: ["ball", "man", "man bouncing ball"]
  },
  "26f9_fe0f_200d_2640_fe0f": {
    name: "woman_bouncing_ball",
    code: "26f9_fe0f_200d_2640_fe0f",
    keywords: ["ball", "woman", "woman bouncing ball"]
  },
  "1f3cb": {
    name: "person_lifting_weights",
    code: "1f3cb",
    keywords: ["lifter", "person lifting weights", "weight"]
  },
  "1f3cb_fe0f_200d_2642_fe0f": {
    name: "man_lifting_weights",
    code: "1f3cb_fe0f_200d_2642_fe0f",
    keywords: ["man", "man lifting weights", "weight lifter"]
  },
  "1f3cb_fe0f_200d_2640_fe0f": {
    name: "woman_lifting_weights",
    code: "1f3cb_fe0f_200d_2640_fe0f",
    keywords: ["weight lifter", "woman", "woman lifting weights"]
  },
  "1f6b4": {
    name: "person_biking",
    code: "1f6b4",
    keywords: ["bicycle", "biking", "cyclist", "person biking"]
  },
  "1f6b4_200d_2642_fe0f": {
    name: "man_biking",
    code: "1f6b4_200d_2642_fe0f",
    keywords: ["bicycle", "biking", "cyclist", "man"]
  },
  "1f6b4_200d_2640_fe0f": {
    name: "woman_biking",
    code: "1f6b4_200d_2640_fe0f",
    keywords: ["bicycle", "biking", "cyclist", "woman"]
  },
  "1f6b5": {
    name: "person_mountain_biking",
    code: "1f6b5",
    keywords: [
      "bicycle",
      "bicyclist",
      "bike",
      "cyclist",
      "mountain",
      "person mountain biking"
    ]
  },
  "1f6b5_200d_2642_fe0f": {
    name: "man_mountain_biking",
    code: "1f6b5_200d_2642_fe0f",
    keywords: [
      "bicycle",
      "bike",
      "cyclist",
      "man",
      "man mountain biking",
      "mountain"
    ]
  },
  "1f6b5_200d_2640_fe0f": {
    name: "woman_mountain_biking",
    code: "1f6b5_200d_2640_fe0f",
    keywords: ["bicycle", "bike", "biking", "cyclist", "mountain", "woman"]
  },
  "1f938": {
    name: "person_cartwheeling",
    code: "1f938",
    keywords: ["cartwheel", "gymnastics", "person cartwheeling"]
  },
  "1f938_200d_2642_fe0f": {
    name: "man_cartwheeling",
    code: "1f938_200d_2642_fe0f",
    keywords: ["cartwheel", "gymnastics", "man", "man cartwheeling"]
  },
  "1f938_200d_2640_fe0f": {
    name: "woman_cartwheeling",
    code: "1f938_200d_2640_fe0f",
    keywords: ["cartwheel", "gymnastics", "woman", "woman cartwheeling"]
  },
  "1f93c": {
    name: "people_wrestling",
    code: "1f93c",
    keywords: ["people wrestling", "wrestle", "wrestler"]
  },
  "1f93c_200d_2642_fe0f": {
    name: "men_wrestling",
    code: "1f93c_200d_2642_fe0f",
    keywords: ["men", "men wrestling", "wrestle"]
  },
  "1f93c_200d_2640_fe0f": {
    name: "women_wrestling",
    code: "1f93c_200d_2640_fe0f",
    keywords: ["women", "women wrestling", "wrestle"]
  },
  "1f93d": {
    name: "person_playing_water_polo",
    code: "1f93d",
    keywords: ["person playing water polo", "polo", "water"]
  },
  "1f93d_200d_2642_fe0f": {
    name: "man_playing_water_polo",
    code: "1f93d_200d_2642_fe0f",
    keywords: ["man", "man playing water polo", "water polo"]
  },
  "1f93d_200d_2640_fe0f": {
    name: "woman_playing_water_polo",
    code: "1f93d_200d_2640_fe0f",
    keywords: ["water polo", "woman", "woman playing water polo"]
  },
  "1f93e": {
    name: "person_playing_handball",
    code: "1f93e",
    keywords: ["ball", "handball", "person playing handball"]
  },
  "1f93e_200d_2642_fe0f": {
    name: "man_playing_handball",
    code: "1f93e_200d_2642_fe0f",
    keywords: ["handball", "man", "man playing handball"]
  },
  "1f93e_200d_2640_fe0f": {
    name: "woman_playing_handball",
    code: "1f93e_200d_2640_fe0f",
    keywords: ["handball", "woman", "woman playing handball"]
  },
  "1f939": {
    name: "person_juggling",
    code: "1f939",
    keywords: ["balance", "juggle", "multitask", "person juggling", "skill"]
  },
  "1f939_200d_2642_fe0f": {
    name: "man_juggling",
    code: "1f939_200d_2642_fe0f",
    keywords: ["juggling", "man", "multitask"]
  },
  "1f939_200d_2640_fe0f": {
    name: "woman_juggling",
    code: "1f939_200d_2640_fe0f",
    keywords: ["juggling", "multitask", "woman"]
  },
  "1f9d8": {
    name: "person_in_lotus_position",
    code: "1f9d8",
    keywords: ["meditation", "person in lotus position", "yoga", ""]
  },
  "1f9d8_200d_2642_fe0f": {
    name: "man_in_lotus_position",
    code: "1f9d8_200d_2642_fe0f",
    keywords: ["man in lotus position", "meditation", "yoga"]
  },
  "1f9d8_200d_2640_fe0f": {
    name: "woman_in_lotus_position",
    code: "1f9d8_200d_2640_fe0f",
    keywords: ["meditation", "woman in lotus position", "yoga"]
  },
  "1f6c0": {
    name: "person_taking_bath",
    code: "1f6c0",
    keywords: ["bath", "bathtub", "person taking bath"]
  },
  "1f6cc": {
    name: "person_in_bed",
    code: "1f6cc",
    keywords: ["hotel", "person in bed", "sleep"]
  },
  "1f9d1_200d_1f91d_200d_1f9d1": {
    name: "people_holding_hands",
    code: "1f9d1_200d_1f91d_200d_1f9d1",
    keywords: [
      "couple",
      "hand",
      "hold",
      "holding hands",
      "people holding hands",
      "person"
    ]
  },
  "1f46d": {
    name: "women_holding_hands",
    code: "1f46d",
    keywords: [
      "couple",
      "hand",
      "holding hands",
      "women",
      "women holding hands"
    ]
  },
  "1f46b": {
    name: "woman_and_man_holding_hands",
    code: "1f46b",
    keywords: [
      "couple",
      "hand",
      "hold",
      "holding hands",
      "man",
      "woman",
      "woman and man holding hands"
    ]
  },
  "1f46c": {
    name: "men_holding_hands",
    code: "1f46c",
    keywords: [
      "couple",
      "Gemini",
      "holding hands",
      "man",
      "men",
      "men holding hands",
      "twins",
      "zodiac"
    ]
  },
  "1f48f": { name: "kiss", code: "1f48f", keywords: ["couple", "kiss"] },
  "1f469_200d_2764_fe0f_200d_1f48b_200d_1f468": {
    name: "kiss:_woman,_man",
    code: "1f469_200d_2764_fe0f_200d_1f48b_200d_1f468",
    keywords: ["couple", "kiss", "man", "woman"]
  },
  "1f468_200d_2764_fe0f_200d_1f48b_200d_1f468": {
    name: "kiss:_man,_man",
    code: "1f468_200d_2764_fe0f_200d_1f48b_200d_1f468",
    keywords: ["couple", "kiss", "man"]
  },
  "1f469_200d_2764_fe0f_200d_1f48b_200d_1f469": {
    name: "kiss:_woman,_woman",
    code: "1f469_200d_2764_fe0f_200d_1f48b_200d_1f469",
    keywords: ["couple", "kiss", "woman"]
  },
  "1f491": {
    name: "couple_with_heart",
    code: "1f491",
    keywords: ["couple", "couple with heart", "love"]
  },
  "1f469_200d_2764_fe0f_200d_1f468": {
    name: "couple_with_heart:_woman,_man",
    code: "1f469_200d_2764_fe0f_200d_1f468",
    keywords: ["couple", "couple with heart", "love", "man", "woman"]
  },
  "1f468_200d_2764_fe0f_200d_1f468": {
    name: "couple_with_heart:_man,_man",
    code: "1f468_200d_2764_fe0f_200d_1f468",
    keywords: ["couple", "couple with heart", "love", "man"]
  },
  "1f469_200d_2764_fe0f_200d_1f469": {
    name: "couple_with_heart:_woman,_woman",
    code: "1f469_200d_2764_fe0f_200d_1f469",
    keywords: ["couple", "couple with heart", "love", "woman"]
  },
  "1f46a": { name: "family", code: "1f46a", keywords: ["family"] },
  "1f468_200d_1f469_200d_1f466": {
    name: "family:_man,_woman,_boy",
    code: "1f468_200d_1f469_200d_1f466",
    keywords: ["boy", "family", "man", "woman"]
  },
  "1f468_200d_1f469_200d_1f467": {
    name: "family:_man,_woman,_girl",
    code: "1f468_200d_1f469_200d_1f467",
    keywords: ["family", "girl", "man", "woman"]
  },
  "1f468_200d_1f469_200d_1f467_200d_1f466": {
    name: "family:_man,_woman,_girl,_boy",
    code: "1f468_200d_1f469_200d_1f467_200d_1f466",
    keywords: ["boy", "family", "girl", "man", "woman"]
  },
  "1f468_200d_1f469_200d_1f466_200d_1f466": {
    name: "family:_man,_woman,_boy,_boy",
    code: "1f468_200d_1f469_200d_1f466_200d_1f466",
    keywords: ["boy", "family", "man", "woman"]
  },
  "1f468_200d_1f469_200d_1f467_200d_1f467": {
    name: "family:_man,_woman,_girl,_girl",
    code: "1f468_200d_1f469_200d_1f467_200d_1f467",
    keywords: ["family", "girl", "man", "woman"]
  },
  "1f468_200d_1f468_200d_1f466": {
    name: "family:_man,_man,_boy",
    code: "1f468_200d_1f468_200d_1f466",
    keywords: ["boy", "family", "man"]
  },
  "1f468_200d_1f468_200d_1f467": {
    name: "family:_man,_man,_girl",
    code: "1f468_200d_1f468_200d_1f467",
    keywords: ["family", "girl", "man"]
  },
  "1f468_200d_1f468_200d_1f467_200d_1f466": {
    name: "family:_man,_man,_girl,_boy",
    code: "1f468_200d_1f468_200d_1f467_200d_1f466",
    keywords: ["boy", "family", "girl", "man"]
  },
  "1f468_200d_1f468_200d_1f466_200d_1f466": {
    name: "family:_man,_man,_boy,_boy",
    code: "1f468_200d_1f468_200d_1f466_200d_1f466",
    keywords: ["boy", "family", "man"]
  },
  "1f468_200d_1f468_200d_1f467_200d_1f467": {
    name: "family:_man,_man,_girl,_girl",
    code: "1f468_200d_1f468_200d_1f467_200d_1f467",
    keywords: ["family", "girl", "man"]
  },
  "1f469_200d_1f469_200d_1f466": {
    name: "family:_woman,_woman,_boy",
    code: "1f469_200d_1f469_200d_1f466",
    keywords: ["boy", "family", "woman"]
  },
  "1f469_200d_1f469_200d_1f467": {
    name: "family:_woman,_woman,_girl",
    code: "1f469_200d_1f469_200d_1f467",
    keywords: ["family", "girl", "woman"]
  },
  "1f469_200d_1f469_200d_1f467_200d_1f466": {
    name: "family:_woman,_woman,_girl,_boy",
    code: "1f469_200d_1f469_200d_1f467_200d_1f466",
    keywords: ["boy", "family", "girl", "woman"]
  },
  "1f469_200d_1f469_200d_1f466_200d_1f466": {
    name: "family:_woman,_woman,_boy,_boy",
    code: "1f469_200d_1f469_200d_1f466_200d_1f466",
    keywords: ["boy", "family", "woman"]
  },
  "1f469_200d_1f469_200d_1f467_200d_1f467": {
    name: "family:_woman,_woman,_girl,_girl",
    code: "1f469_200d_1f469_200d_1f467_200d_1f467",
    keywords: ["family", "girl", "woman"]
  },
  "1f468_200d_1f466": {
    name: "family:_man,_boy",
    code: "1f468_200d_1f466",
    keywords: ["boy", "family", "man"]
  },
  "1f468_200d_1f466_200d_1f466": {
    name: "family:_man,_boy,_boy",
    code: "1f468_200d_1f466_200d_1f466",
    keywords: ["boy", "family", "man"]
  },
  "1f468_200d_1f467": {
    name: "family:_man,_girl",
    code: "1f468_200d_1f467",
    keywords: ["family", "girl", "man"]
  },
  "1f468_200d_1f467_200d_1f466": {
    name: "family:_man,_girl,_boy",
    code: "1f468_200d_1f467_200d_1f466",
    keywords: ["boy", "family", "girl", "man"]
  },
  "1f468_200d_1f467_200d_1f467": {
    name: "family:_man,_girl,_girl",
    code: "1f468_200d_1f467_200d_1f467",
    keywords: ["family", "girl", "man"]
  },
  "1f469_200d_1f466": {
    name: "family:_woman,_boy",
    code: "1f469_200d_1f466",
    keywords: ["boy", "family", "woman"]
  },
  "1f469_200d_1f466_200d_1f466": {
    name: "family:_woman,_boy,_boy",
    code: "1f469_200d_1f466_200d_1f466",
    keywords: ["boy", "family", "woman"]
  },
  "1f469_200d_1f467": {
    name: "family:_woman,_girl",
    code: "1f469_200d_1f467",
    keywords: ["family", "girl", "woman"]
  },
  "1f469_200d_1f467_200d_1f466": {
    name: "family:_woman,_girl,_boy",
    code: "1f469_200d_1f467_200d_1f466",
    keywords: ["boy", "family", "girl", "woman"]
  },
  "1f469_200d_1f467_200d_1f467": {
    name: "family:_woman,_girl,_girl",
    code: "1f469_200d_1f467_200d_1f467",
    keywords: ["family", "girl", "woman"]
  },
  "1f5e3": {
    name: "speaking_head",
    code: "1f5e3",
    keywords: ["face", "head", "silhouette", "speak", "speaking"]
  },
  "1f464": {
    name: "bust_in_silhouette",
    code: "1f464",
    keywords: ["bust", "bust in silhouette", "silhouette"]
  },
  "1f465": {
    name: "busts_in_silhouette",
    code: "1f465",
    keywords: ["bust", "busts in silhouette", "silhouette"]
  },
  "1fac2": {
    name: "\u229b_people_hugging",
    code: "1fac2",
    keywords: ["goodbye", "hello", "hug", "people hugging", "thanks"]
  },
  "1f463": {
    name: "footprints",
    code: "1f463",
    keywords: ["clothing", "footprint", "footprints", "print"]
  },
  "1f9b0": {
    name: "red_hair",
    code: "1f9b0",
    keywords: ["ginger", "red hair", "redhead"]
  },
  "1f9b1": {
    name: "curly_hair",
    code: "1f9b1",
    keywords: ["afro", "curly", "curly hair", "ringlets"]
  },
  "1f9b3": {
    name: "white_hair",
    code: "1f9b3",
    keywords: ["gray", "hair", "old", "white"]
  },
  "1f9b2": {
    name: "bald",
    code: "1f9b2",
    keywords: ["bald", "chemotherapy", "hairless", "no hair", "shaven"]
  },
  "1f435": { name: "monkey_face", code: "1f435", keywords: ["face", "monkey"] },
  "1f412": { name: "monkey", code: "1f412", keywords: ["monkey"] },
  "1f98d": { name: "gorilla", code: "1f98d", keywords: ["gorilla"] },
  "1f9a7": { name: "orangutan", code: "1f9a7", keywords: ["ape", "orangutan"] },
  "1f436": {
    name: "dog_face",
    code: "1f436",
    keywords: ["dog", "face", "pet"]
  },
  "1f415": { name: "dog", code: "1f415", keywords: ["dog", "pet"] },
  "1f9ae": {
    name: "guide_dog",
    code: "1f9ae",
    keywords: ["accessibility", "blind", "guide", "guide dog"]
  },
  "1f415_200d_1f9ba": {
    name: "service_dog",
    code: "1f415_200d_1f9ba",
    keywords: ["accessibility", "assistance", "dog", "service"]
  },
  "1f429": { name: "poodle", code: "1f429", keywords: ["dog", "poodle"] },
  "1f43a": { name: "wolf", code: "1f43a", keywords: ["face", "wolf"] },
  "1f98a": { name: "fox", code: "1f98a", keywords: ["face", "fox"] },
  "1f99d": {
    name: "raccoon",
    code: "1f99d",
    keywords: ["curious", "raccoon", "sly"]
  },
  "1f431": {
    name: "cat_face",
    code: "1f431",
    keywords: ["cat", "face", "pet"]
  },
  "1f408": { name: "cat", code: "1f408", keywords: ["cat", "pet"] },
  "1f408_200d_2b1b": {
    name: "\u229b_black_cat",
    code: "1f408_200d_2b1b",
    keywords: ["black", "cat", "unlucky"]
  },
  "1f981": {
    name: "lion",
    code: "1f981",
    keywords: ["face", "Leo", "lion", "zodiac"]
  },
  "1f42f": { name: "tiger_face", code: "1f42f", keywords: ["face", "tiger"] },
  "1f405": { name: "tiger", code: "1f405", keywords: ["tiger"] },
  "1f406": { name: "leopard", code: "1f406", keywords: ["leopard"] },
  "1f434": { name: "horse_face", code: "1f434", keywords: ["face", "horse"] },
  "1f40e": {
    name: "horse",
    code: "1f40e",
    keywords: ["equestrian", "horse", "racehorse", "racing"]
  },
  "1f984": { name: "unicorn", code: "1f984", keywords: ["face", "unicorn"] },
  "1f993": { name: "zebra", code: "1f993", keywords: ["stripe", "zebra"] },
  "1f98c": { name: "deer", code: "1f98c", keywords: ["deer"] },
  "1f9ac": {
    name: "\u229b_bison",
    code: "1f9ac",
    keywords: ["bison", "buffalo", "herd", "wisent"]
  },
  "1f42e": { name: "cow_face", code: "1f42e", keywords: ["cow", "face"] },
  "1f402": {
    name: "ox",
    code: "1f402",
    keywords: ["bull", "ox", "Taurus", "zodiac"]
  },
  "1f403": {
    name: "water_buffalo",
    code: "1f403",
    keywords: ["buffalo", "water"]
  },
  "1f404": { name: "cow", code: "1f404", keywords: ["cow"] },
  "1f437": { name: "pig_face", code: "1f437", keywords: ["face", "pig"] },
  "1f416": { name: "pig", code: "1f416", keywords: ["pig", "sow"] },
  "1f417": { name: "boar", code: "1f417", keywords: ["boar", "pig"] },
  "1f43d": {
    name: "pig_nose",
    code: "1f43d",
    keywords: ["face", "nose", "pig"]
  },
  "1f40f": {
    name: "ram",
    code: "1f40f",
    keywords: ["Aries", "male", "ram", "sheep", "zodiac"]
  },
  "1f411": { name: "ewe", code: "1f411", keywords: ["ewe", "female", "sheep"] },
  "1f410": {
    name: "goat",
    code: "1f410",
    keywords: ["Capricorn", "goat", "zodiac"]
  },
  "1f42a": {
    name: "camel",
    code: "1f42a",
    keywords: ["camel", "dromedary", "hump"]
  },
  "1f42b": {
    name: "two-hump_camel",
    code: "1f42b",
    keywords: ["bactrian", "camel", "hump", "two-hump camel"]
  },
  "1f999": {
    name: "llama",
    code: "1f999",
    keywords: ["alpaca", "guanaco", "llama", "vicu\u00f1a", "wool"]
  },
  "1f992": { name: "giraffe", code: "1f992", keywords: ["giraffe", "spots"] },
  "1f418": { name: "elephant", code: "1f418", keywords: ["elephant"] },
  "1f9a3": {
    name: "\u229b_mammoth",
    code: "1f9a3",
    keywords: ["extinction", "large", "mammoth", "tusk", "woolly"]
  },
  "1f98f": { name: "rhinoceros", code: "1f98f", keywords: ["rhinoceros"] },
  "1f99b": {
    name: "hippopotamus",
    code: "1f99b",
    keywords: ["hippo", "hippopotamus"]
  },
  "1f42d": { name: "mouse_face", code: "1f42d", keywords: ["face", "mouse"] },
  "1f401": { name: "mouse", code: "1f401", keywords: ["mouse"] },
  "1f400": { name: "rat", code: "1f400", keywords: ["rat"] },
  "1f439": {
    name: "hamster",
    code: "1f439",
    keywords: ["face", "hamster", "pet"]
  },
  "1f430": {
    name: "rabbit_face",
    code: "1f430",
    keywords: ["bunny", "face", "pet", "rabbit"]
  },
  "1f407": {
    name: "rabbit",
    code: "1f407",
    keywords: ["bunny", "pet", "rabbit"]
  },
  "1f43f": {
    name: "chipmunk",
    code: "1f43f",
    keywords: ["chipmunk", "squirrel"]
  },
  "1f9ab": {
    name: "\u229b_beaver",
    code: "1f9ab",
    keywords: ["beaver", "dam"]
  },
  "1f994": { name: "hedgehog", code: "1f994", keywords: ["hedgehog", "spiny"] },
  "1f987": { name: "bat", code: "1f987", keywords: ["bat", "vampire"] },
  "1f43b": { name: "bear", code: "1f43b", keywords: ["bear", "face"] },
  "1f43b_200d_2744_fe0f": {
    name: "\u229b_polar_bear",
    code: "1f43b_200d_2744_fe0f",
    keywords: ["arctic", "bear", "polar bear", "white"]
  },
  "1f428": { name: "koala", code: "1f428", keywords: ["bear", "koala"] },
  "1f43c": { name: "panda", code: "1f43c", keywords: ["face", "panda"] },
  "1f9a5": {
    name: "sloth",
    code: "1f9a5",
    keywords: ["lazy", "sloth", "slow"]
  },
  "1f9a6": {
    name: "otter",
    code: "1f9a6",
    keywords: ["fishing", "otter", "playful"]
  },
  "1f9a8": { name: "skunk", code: "1f9a8", keywords: ["skunk", "stink"] },
  "1f998": {
    name: "kangaroo",
    code: "1f998",
    keywords: ["Australia", "joey", "jump", "kangaroo", "marsupial"]
  },
  "1f9a1": {
    name: "badger",
    code: "1f9a1",
    keywords: ["badger", "honey badger", "pester"]
  },
  "1f43e": {
    name: "paw_prints",
    code: "1f43e",
    keywords: ["feet", "paw", "paw prints", "print"]
  },
  "1f983": { name: "turkey", code: "1f983", keywords: ["bird", "turkey"] },
  "1f414": { name: "chicken", code: "1f414", keywords: ["bird", "chicken"] },
  "1f413": { name: "rooster", code: "1f413", keywords: ["bird", "rooster"] },
  "1f423": {
    name: "hatching_chick",
    code: "1f423",
    keywords: ["baby", "bird", "chick", "hatching"]
  },
  "1f424": {
    name: "baby_chick",
    code: "1f424",
    keywords: ["baby", "bird", "chick"]
  },
  "1f425": {
    name: "front-facing_baby_chick",
    code: "1f425",
    keywords: ["baby", "bird", "chick", "front-facing baby chick"]
  },
  "1f426": { name: "bird", code: "1f426", keywords: ["bird"] },
  "1f427": { name: "penguin", code: "1f427", keywords: ["bird", "penguin"] },
  "1f54a": {
    name: "dove",
    code: "1f54a",
    keywords: ["bird", "dove", "fly", "peace"]
  },
  "1f985": { name: "eagle", code: "1f985", keywords: ["bird", "eagle"] },
  "1f986": { name: "duck", code: "1f986", keywords: ["bird", "duck"] },
  "1f9a2": {
    name: "swan",
    code: "1f9a2",
    keywords: ["bird", "cygnet", "swan", "ugly duckling"]
  },
  "1f989": { name: "owl", code: "1f989", keywords: ["bird", "owl", "wise"] },
  "1f9a4": {
    name: "\u229b_dodo",
    code: "1f9a4",
    keywords: ["dodo", "extinction", "large", "Mauritius"]
  },
  "1fab6": {
    name: "\u229b_feather",
    code: "1fab6",
    keywords: ["bird", "feather", "flight", "light", "plumage"]
  },
  "1f9a9": {
    name: "flamingo",
    code: "1f9a9",
    keywords: ["flamboyant", "flamingo", "tropical"]
  },
  "1f99a": {
    name: "peacock",
    code: "1f99a",
    keywords: ["bird", "ostentatious", "peacock", "peahen", "proud"]
  },
  "1f99c": {
    name: "parrot",
    code: "1f99c",
    keywords: ["bird", "parrot", "pirate", "talk"]
  },
  "1f438": { name: "frog", code: "1f438", keywords: ["face", "frog"] },
  "1f40a": { name: "crocodile", code: "1f40a", keywords: ["crocodile"] },
  "1f422": {
    name: "turtle",
    code: "1f422",
    keywords: ["terrapin", "tortoise", "turtle"]
  },
  "1f98e": { name: "lizard", code: "1f98e", keywords: ["lizard", "reptile"] },
  "1f40d": {
    name: "snake",
    code: "1f40d",
    keywords: ["bearer", "Ophiuchus", "serpent", "snake", "zodiac"]
  },
  "1f432": {
    name: "dragon_face",
    code: "1f432",
    keywords: ["dragon", "face", "fairy tale"]
  },
  "1f409": {
    name: "dragon",
    code: "1f409",
    keywords: ["dragon", "fairy tale"]
  },
  "1f995": {
    name: "sauropod",
    code: "1f995",
    keywords: ["brachiosaurus", "brontosaurus", "diplodocus", "sauropod"]
  },
  "1f996": {
    name: "T-Rex",
    code: "1f996",
    keywords: ["T-Rex", "Tyrannosaurus Rex"]
  },
  "1f433": {
    name: "spouting_whale",
    code: "1f433",
    keywords: ["face", "spouting", "whale"]
  },
  "1f40b": { name: "whale", code: "1f40b", keywords: ["whale"] },
  "1f42c": { name: "dolphin", code: "1f42c", keywords: ["dolphin", "flipper"] },
  "1f9ad": {
    name: "\u229b_seal",
    code: "1f9ad",
    keywords: ["sea Lion", "seal"]
  },
  "1f41f": {
    name: "fish",
    code: "1f41f",
    keywords: ["fish", "Pisces", "zodiac"]
  },
  "1f420": {
    name: "tropical_fish",
    code: "1f420",
    keywords: ["fish", "tropical"]
  },
  "1f421": { name: "blowfish", code: "1f421", keywords: ["blowfish", "fish"] },
  "1f988": { name: "shark", code: "1f988", keywords: ["fish", "shark"] },
  "1f419": { name: "octopus", code: "1f419", keywords: ["octopus"] },
  "1f41a": {
    name: "spiral_shell",
    code: "1f41a",
    keywords: ["shell", "spiral"]
  },
  "1f40c": { name: "snail", code: "1f40c", keywords: ["snail"] },
  "1f98b": {
    name: "butterfly",
    code: "1f98b",
    keywords: ["butterfly", "insect", "pretty"]
  },
  "1f41b": { name: "bug", code: "1f41b", keywords: ["bug", "insect"] },
  "1f41c": { name: "ant", code: "1f41c", keywords: ["ant", "insect"] },
  "1f41d": {
    name: "honeybee",
    code: "1f41d",
    keywords: ["bee", "honeybee", "insect"]
  },
  "1fab2": {
    name: "\u229b_beetle",
    code: "1fab2",
    keywords: ["beetle", "bug", "insect"]
  },
  "1f41e": {
    name: "lady_beetle",
    code: "1f41e",
    keywords: ["beetle", "insect", "lady beetle", "ladybird", "ladybug"]
  },
  "1f997": {
    name: "cricket",
    code: "1f997",
    keywords: ["cricket", "grasshopper", ""]
  },
  "1fab3": {
    name: "\u229b_cockroach",
    code: "1fab3",
    keywords: ["cockroach", "insect", "pest", "roach"]
  },
  "1f577": { name: "spider", code: "1f577", keywords: ["insect", "spider"] },
  "1f578": { name: "spider_web", code: "1f578", keywords: ["spider", "web"] },
  "1f982": {
    name: "scorpion",
    code: "1f982",
    keywords: ["scorpio", "Scorpio", "scorpion", "zodiac"]
  },
  "1f99f": {
    name: "mosquito",
    code: "1f99f",
    keywords: ["disease", "fever", "malaria", "mosquito", "pest", "virus"]
  },
  "1fab0": {
    name: "\u229b_fly",
    code: "1fab0",
    keywords: ["disease", "fly", "maggot", "pest", "rotting"]
  },
  "1fab1": {
    name: "\u229b_worm",
    code: "1fab1",
    keywords: ["annelid", "earthworm", "parasite", "worm"]
  },
  "1f9a0": {
    name: "microbe",
    code: "1f9a0",
    keywords: ["amoeba", "bacteria", "microbe", "virus"]
  },
  "1f490": { name: "bouquet", code: "1f490", keywords: ["bouquet", "flower"] },
  "1f338": {
    name: "cherry_blossom",
    code: "1f338",
    keywords: ["blossom", "cherry", "flower"]
  },
  "1f4ae": {
    name: "white_flower",
    code: "1f4ae",
    keywords: ["flower", "white flower"]
  },
  "1f3f5": { name: "rosette", code: "1f3f5", keywords: ["plant", "rosette"] },
  "1f339": { name: "rose", code: "1f339", keywords: ["flower", "rose"] },
  "1f940": {
    name: "wilted_flower",
    code: "1f940",
    keywords: ["flower", "wilted"]
  },
  "1f33a": {
    name: "hibiscus",
    code: "1f33a",
    keywords: ["flower", "hibiscus"]
  },
  "1f33b": {
    name: "sunflower",
    code: "1f33b",
    keywords: ["flower", "sun", "sunflower"]
  },
  "1f33c": { name: "blossom", code: "1f33c", keywords: ["blossom", "flower"] },
  "1f337": { name: "tulip", code: "1f337", keywords: ["flower", "tulip"] },
  "1f331": { name: "seedling", code: "1f331", keywords: ["seedling", "young"] },
  "1fab4": {
    name: "\u229b_potted_plant",
    code: "1fab4",
    keywords: [
      "boring",
      "grow",
      "house",
      "nurturing",
      "plant",
      "potted plant",
      "useless"
    ]
  },
  "1f332": {
    name: "evergreen_tree",
    code: "1f332",
    keywords: ["evergreen tree", "tree"]
  },
  "1f333": {
    name: "deciduous_tree",
    code: "1f333",
    keywords: ["deciduous", "shedding", "tree"]
  },
  "1f334": { name: "palm_tree", code: "1f334", keywords: ["palm", "tree"] },
  "1f335": { name: "cactus", code: "1f335", keywords: ["cactus", "plant"] },
  "1f33e": {
    name: "sheaf_of_rice",
    code: "1f33e",
    keywords: ["ear", "grain", "rice", "sheaf of rice"]
  },
  "1f33f": { name: "herb", code: "1f33f", keywords: ["herb", "leaf"] },
  "2618": { name: "shamrock", code: "2618", keywords: ["plant", "shamrock"] },
  "1f340": {
    name: "four_leaf_clover",
    code: "1f340",
    keywords: ["4", "clover", "four", "four-leaf clover", "leaf"]
  },
  "1f341": {
    name: "maple_leaf",
    code: "1f341",
    keywords: ["falling", "leaf", "maple"]
  },
  "1f342": {
    name: "fallen_leaf",
    code: "1f342",
    keywords: ["fallen leaf", "falling", "leaf"]
  },
  "1f343": {
    name: "leaf_fluttering_in_wind",
    code: "1f343",
    keywords: ["blow", "flutter", "leaf", "leaf fluttering in wind", "wind"]
  },
  "1f347": {
    name: "grapes",
    code: "1f347",
    keywords: ["fruit", "grape", "grapes"]
  },
  "1f348": { name: "melon", code: "1f348", keywords: ["fruit", "melon"] },
  "1f349": {
    name: "watermelon",
    code: "1f349",
    keywords: ["fruit", "watermelon"]
  },
  "1f34a": {
    name: "tangerine",
    code: "1f34a",
    keywords: ["fruit", "orange", "tangerine"]
  },
  "1f34b": {
    name: "lemon",
    code: "1f34b",
    keywords: ["citrus", "fruit", "lemon"]
  },
  "1f34c": { name: "banana", code: "1f34c", keywords: ["banana", "fruit"] },
  "1f34d": {
    name: "pineapple",
    code: "1f34d",
    keywords: ["fruit", "pineapple"]
  },
  "1f96d": {
    name: "mango",
    code: "1f96d",
    keywords: ["fruit", "mango", "tropical"]
  },
  "1f34e": {
    name: "red_apple",
    code: "1f34e",
    keywords: ["apple", "fruit", "red"]
  },
  "1f34f": {
    name: "green_apple",
    code: "1f34f",
    keywords: ["apple", "fruit", "green"]
  },
  "1f350": { name: "pear", code: "1f350", keywords: ["fruit", "pear"] },
  "1f351": { name: "peach", code: "1f351", keywords: ["fruit", "peach"] },
  "1f352": {
    name: "cherries",
    code: "1f352",
    keywords: ["berries", "cherries", "cherry", "fruit", "red"]
  },
  "1f353": {
    name: "strawberry",
    code: "1f353",
    keywords: ["berry", "fruit", "strawberry"]
  },
  "1fad0": {
    name: "\u229b_blueberries",
    code: "1fad0",
    keywords: ["berry", "bilberry", "blue", "blueberries", "blueberry"]
  },
  "1f95d": {
    name: "kiwi_fruit",
    code: "1f95d",
    keywords: ["food", "fruit", "kiwi"]
  },
  "1f345": {
    name: "tomato",
    code: "1f345",
    keywords: ["fruit", "tomato", "vegetable"]
  },
  "1fad2": { name: "\u229b_olive", code: "1fad2", keywords: ["food", "olive"] },
  "1f965": {
    name: "coconut",
    code: "1f965",
    keywords: ["coconut", "palm", "pi\u00f1a colada"]
  },
  "1f951": {
    name: "avocado",
    code: "1f951",
    keywords: ["avocado", "food", "fruit"]
  },
  "1f346": {
    name: "eggplant",
    code: "1f346",
    keywords: ["aubergine", "eggplant", "vegetable"]
  },
  "1f954": {
    name: "potato",
    code: "1f954",
    keywords: ["food", "potato", "vegetable"]
  },
  "1f955": {
    name: "carrot",
    code: "1f955",
    keywords: ["carrot", "food", "vegetable"]
  },
  "1f33d": {
    name: "ear_of_corn",
    code: "1f33d",
    keywords: ["corn", "ear", "ear of corn", "maize", "maze"]
  },
  "1f336": { name: "hot_pepper", code: "1f336", keywords: ["hot", "pepper"] },
  "1fad1": {
    name: "\u229b_bell_pepper",
    code: "1fad1",
    keywords: ["bell pepper", "capsicum", "pepper", "vegetable"]
  },
  "1f952": {
    name: "cucumber",
    code: "1f952",
    keywords: ["cucumber", "food", "pickle", "vegetable"]
  },
  "1f96c": {
    name: "leafy_green",
    code: "1f96c",
    keywords: ["bok choy", "cabbage", "kale", "leafy green", "lettuce"]
  },
  "1f966": {
    name: "broccoli",
    code: "1f966",
    keywords: ["broccoli", "wild cabbage"]
  },
  "1f9c4": { name: "garlic", code: "1f9c4", keywords: ["flavoring", "garlic"] },
  "1f9c5": { name: "onion", code: "1f9c5", keywords: ["flavoring", "onion"] },
  "1f344": {
    name: "mushroom",
    code: "1f344",
    keywords: ["mushroom", "toadstool"]
  },
  "1f95c": {
    name: "peanuts",
    code: "1f95c",
    keywords: ["food", "nut", "peanut", "peanuts", "vegetable"]
  },
  "1f330": { name: "chestnut", code: "1f330", keywords: ["chestnut", "plant"] },
  "1f35e": { name: "bread", code: "1f35e", keywords: ["bread", "loaf"] },
  "1f950": {
    name: "croissant",
    code: "1f950",
    keywords: ["bread", "breakfast", "croissant", "food", "french", "roll"]
  },
  "1f956": {
    name: "baguette_bread",
    code: "1f956",
    keywords: ["baguette", "bread", "food", "french"]
  },
  "1fad3": {
    name: "\u229b_flatbread",
    code: "1fad3",
    keywords: ["arepa", "flatbread", "lavash", "naan", "pita"]
  },
  "1f968": {
    name: "pretzel",
    code: "1f968",
    keywords: ["pretzel", "twisted", ""]
  },
  "1f96f": {
    name: "bagel",
    code: "1f96f",
    keywords: ["bagel", "bakery", "breakfast", "schmear"]
  },
  "1f95e": {
    name: "pancakes",
    code: "1f95e",
    keywords: [
      "breakfast",
      "cr\u00eape",
      "food",
      "hotcake",
      "pancake",
      "pancakes"
    ]
  },
  "1f9c7": {
    name: "waffle",
    code: "1f9c7",
    keywords: ["breakfast", "indecisive", "iron", "waffle"]
  },
  "1f9c0": {
    name: "cheese_wedge",
    code: "1f9c0",
    keywords: ["cheese", "cheese wedge"]
  },
  "1f356": {
    name: "meat_on_bone",
    code: "1f356",
    keywords: ["bone", "meat", "meat on bone"]
  },
  "1f357": {
    name: "poultry_leg",
    code: "1f357",
    keywords: ["bone", "chicken", "drumstick", "leg", "poultry"]
  },
  "1f969": {
    name: "cut_of_meat",
    code: "1f969",
    keywords: ["chop", "cut of meat", "lambchop", "porkchop", "steak"]
  },
  "1f953": {
    name: "bacon",
    code: "1f953",
    keywords: ["bacon", "breakfast", "food", "meat"]
  },
  "1f354": {
    name: "hamburger",
    code: "1f354",
    keywords: ["burger", "hamburger"]
  },
  "1f35f": {
    name: "french_fries",
    code: "1f35f",
    keywords: ["french", "fries"]
  },
  "1f355": {
    name: "pizza",
    code: "1f355",
    keywords: ["cheese", "pizza", "slice"]
  },
  "1f32d": {
    name: "hot_dog",
    code: "1f32d",
    keywords: ["frankfurter", "hot dog", "hotdog", "sausage"]
  },
  "1f96a": { name: "sandwich", code: "1f96a", keywords: ["bread", "sandwich"] },
  "1f32e": { name: "taco", code: "1f32e", keywords: ["mexican", "taco"] },
  "1f32f": {
    name: "burrito",
    code: "1f32f",
    keywords: ["burrito", "mexican", "wrap"]
  },
  "1fad4": {
    name: "\u229b_tamale",
    code: "1fad4",
    keywords: ["mexican", "tamale", "wrapped"]
  },
  "1f959": {
    name: "stuffed_flatbread",
    code: "1f959",
    keywords: ["falafel", "flatbread", "food", "gyro", "kebab", "stuffed"]
  },
  "1f9c6": {
    name: "falafel",
    code: "1f9c6",
    keywords: ["chickpea", "falafel", "meatball"]
  },
  "1f95a": {
    name: "egg",
    code: "1f95a",
    keywords: ["breakfast", "egg", "food"]
  },
  "1f373": {
    name: "cooking",
    code: "1f373",
    keywords: ["breakfast", "cooking", "egg", "frying", "pan"]
  },
  "1f958": {
    name: "shallow_pan_of_food",
    code: "1f958",
    keywords: [
      "casserole",
      "food",
      "paella",
      "pan",
      "shallow",
      "shallow pan of food"
    ]
  },
  "1f372": {
    name: "pot_of_food",
    code: "1f372",
    keywords: ["pot", "pot of food", "stew"]
  },
  "1fad5": {
    name: "\u229b_fondue",
    code: "1fad5",
    keywords: ["cheese", "chocolate", "fondue", "melted", "pot", "Swiss"]
  },
  "1f963": {
    name: "bowl_with_spoon",
    code: "1f963",
    keywords: ["bowl with spoon", "breakfast", "cereal", "congee", ""]
  },
  "1f957": {
    name: "green_salad",
    code: "1f957",
    keywords: ["food", "green", "salad"]
  },
  "1f37f": { name: "popcorn", code: "1f37f", keywords: ["popcorn"] },
  "1f9c8": { name: "butter", code: "1f9c8", keywords: ["butter", "dairy"] },
  "1f9c2": {
    name: "salt",
    code: "1f9c2",
    keywords: ["condiment", "salt", "shaker"]
  },
  "1f96b": {
    name: "canned_food",
    code: "1f96b",
    keywords: ["can", "canned food"]
  },
  "1f371": { name: "bento_box", code: "1f371", keywords: ["bento", "box"] },
  "1f358": {
    name: "rice_cracker",
    code: "1f358",
    keywords: ["cracker", "rice"]
  },
  "1f359": {
    name: "rice_ball",
    code: "1f359",
    keywords: ["ball", "Japanese", "rice"]
  },
  "1f35a": { name: "cooked_rice", code: "1f35a", keywords: ["cooked", "rice"] },
  "1f35b": { name: "curry_rice", code: "1f35b", keywords: ["curry", "rice"] },
  "1f35c": {
    name: "steaming_bowl",
    code: "1f35c",
    keywords: ["bowl", "noodle", "ramen", "steaming"]
  },
  "1f35d": {
    name: "spaghetti",
    code: "1f35d",
    keywords: ["pasta", "spaghetti"]
  },
  "1f360": {
    name: "roasted_sweet_potato",
    code: "1f360",
    keywords: ["potato", "roasted", "sweet"]
  },
  "1f362": {
    name: "oden",
    code: "1f362",
    keywords: ["kebab", "oden", "seafood", "skewer", "stick"]
  },
  "1f363": { name: "sushi", code: "1f363", keywords: ["sushi"] },
  "1f364": {
    name: "fried_shrimp",
    code: "1f364",
    keywords: ["fried", "prawn", "shrimp", "tempura"]
  },
  "1f365": {
    name: "fish_cake_with_swirl",
    code: "1f365",
    keywords: ["cake", "fish", "fish cake with swirl", "pastry", "swirl"]
  },
  "1f96e": {
    name: "moon_cake",
    code: "1f96e",
    keywords: ["autumn", "festival", "moon cake", "yu\u00e8b\u01d0ng"]
  },
  "1f361": {
    name: "dango",
    code: "1f361",
    keywords: ["dango", "dessert", "Japanese", "skewer", "stick", "sweet"]
  },
  "1f95f": {
    name: "dumpling",
    code: "1f95f",
    keywords: [
      "dumpling",
      "empanada",
      "gy\u014dza",
      "jiaozi",
      "pierogi",
      "potsticker"
    ]
  },
  "1f960": {
    name: "fortune_cookie",
    code: "1f960",
    keywords: ["fortune cookie", "prophecy"]
  },
  "1f961": {
    name: "takeout_box",
    code: "1f961",
    keywords: ["oyster pail", "takeout box"]
  },
  "1f980": {
    name: "crab",
    code: "1f980",
    keywords: ["Cancer", "crab", "zodiac"]
  },
  "1f99e": {
    name: "lobster",
    code: "1f99e",
    keywords: ["bisque", "claws", "lobster", "seafood"]
  },
  "1f990": {
    name: "shrimp",
    code: "1f990",
    keywords: ["food", "shellfish", "shrimp", "small"]
  },
  "1f991": {
    name: "squid",
    code: "1f991",
    keywords: ["food", "molusc", "squid"]
  },
  "1f9aa": {
    name: "oyster",
    code: "1f9aa",
    keywords: ["diving", "oyster", "pearl"]
  },
  "1f366": {
    name: "soft_ice_cream",
    code: "1f366",
    keywords: ["cream", "dessert", "ice", "icecream", "soft", "sweet"]
  },
  "1f367": {
    name: "shaved_ice",
    code: "1f367",
    keywords: ["dessert", "ice", "shaved", "sweet"]
  },
  "1f368": {
    name: "ice_cream",
    code: "1f368",
    keywords: ["cream", "dessert", "ice", "sweet"]
  },
  "1f369": {
    name: "doughnut",
    code: "1f369",
    keywords: ["breakfast", "dessert", "donut", "doughnut", "sweet"]
  },
  "1f36a": {
    name: "cookie",
    code: "1f36a",
    keywords: ["cookie", "dessert", "sweet"]
  },
  "1f382": {
    name: "birthday_cake",
    code: "1f382",
    keywords: ["birthday", "cake", "celebration", "dessert", "pastry", "sweet"]
  },
  "1f370": {
    name: "shortcake",
    code: "1f370",
    keywords: ["cake", "dessert", "pastry", "shortcake", "slice", "sweet"]
  },
  "1f9c1": {
    name: "cupcake",
    code: "1f9c1",
    keywords: ["bakery", "cupcake", "sweet"]
  },
  "1f967": {
    name: "pie",
    code: "1f967",
    keywords: ["filling", "pastry", "pie", ""]
  },
  "1f36b": {
    name: "chocolate_bar",
    code: "1f36b",
    keywords: ["bar", "chocolate", "dessert", "sweet"]
  },
  "1f36c": {
    name: "candy",
    code: "1f36c",
    keywords: ["candy", "dessert", "sweet"]
  },
  "1f36d": {
    name: "lollipop",
    code: "1f36d",
    keywords: ["candy", "dessert", "lollipop", "sweet"]
  },
  "1f36e": {
    name: "custard",
    code: "1f36e",
    keywords: ["custard", "dessert", "pudding", "sweet"]
  },
  "1f36f": {
    name: "honey_pot",
    code: "1f36f",
    keywords: ["honey", "honeypot", "pot", "sweet"]
  },
  "1f37c": {
    name: "baby_bottle",
    code: "1f37c",
    keywords: ["baby", "bottle", "drink", "milk"]
  },
  "1f95b": {
    name: "glass_of_milk",
    code: "1f95b",
    keywords: ["drink", "glass", "glass of milk", "milk"]
  },
  "2615": {
    name: "hot_beverage",
    code: "2615",
    keywords: ["beverage", "coffee", "drink", "hot", "steaming", "tea"]
  },
  "1fad6": {
    name: "\u229b_teapot",
    code: "1fad6",
    keywords: ["drink", "pot", "tea", "teapot"]
  },
  "1f375": {
    name: "teacup_without_handle",
    code: "1f375",
    keywords: [
      "beverage",
      "cup",
      "drink",
      "tea",
      "teacup",
      "teacup without handle"
    ]
  },
  "1f376": {
    name: "sake",
    code: "1f376",
    keywords: ["bar", "beverage", "bottle", "cup", "drink", "sake"]
  },
  "1f37e": {
    name: "bottle_with_popping_cork",
    code: "1f37e",
    keywords: [
      "bar",
      "bottle",
      "bottle with popping cork",
      "cork",
      "drink",
      "popping"
    ]
  },
  "1f377": {
    name: "wine_glass",
    code: "1f377",
    keywords: ["bar", "beverage", "drink", "glass", "wine"]
  },
  "1f378": {
    name: "cocktail_glass",
    code: "1f378",
    keywords: ["bar", "cocktail", "drink", "glass"]
  },
  "1f379": {
    name: "tropical_drink",
    code: "1f379",
    keywords: ["bar", "drink", "tropical"]
  },
  "1f37a": {
    name: "beer_mug",
    code: "1f37a",
    keywords: ["bar", "beer", "drink", "mug"]
  },
  "1f37b": {
    name: "clinking_beer_mugs",
    code: "1f37b",
    keywords: ["bar", "beer", "clink", "clinking beer mugs", "drink", "mug"]
  },
  "1f942": {
    name: "clinking_glasses",
    code: "1f942",
    keywords: ["celebrate", "clink", "clinking glasses", "drink", "glass"]
  },
  "1f943": {
    name: "tumbler_glass",
    code: "1f943",
    keywords: ["glass", "liquor", "shot", "tumbler", "whisky"]
  },
  "1f964": {
    name: "cup_with_straw",
    code: "1f964",
    keywords: ["cup with straw", "juice", "soda", ""]
  },
  "1f9cb": {
    name: "\u229b_bubble_tea",
    code: "1f9cb",
    keywords: ["bubble", "milk", "pearl", "tea"]
  },
  "1f9c3": {
    name: "beverage_box",
    code: "1f9c3",
    keywords: ["beverage", "box", "juice", "straw", "sweet"]
  },
  "1f9c9": { name: "mate", code: "1f9c9", keywords: ["drink", "mate"] },
  "1f9ca": {
    name: "ice",
    code: "1f9ca",
    keywords: ["cold", "ice", "ice cube", "iceberg"]
  },
  "1f962": {
    name: "chopsticks",
    code: "1f962",
    keywords: ["chopsticks", "hashi", ""]
  },
  "1f37d": {
    name: "fork_and_knife_with_plate",
    code: "1f37d",
    keywords: ["cooking", "fork", "fork and knife with plate", "knife", "plate"]
  },
  "1f374": {
    name: "fork_and_knife",
    code: "1f374",
    keywords: ["cooking", "cutlery", "fork", "fork and knife", "knife"]
  },
  "1f944": { name: "spoon", code: "1f944", keywords: ["spoon", "tableware"] },
  "1f52a": {
    name: "kitchen_knife",
    code: "1f52a",
    keywords: ["cooking", "hocho", "kitchen knife", "knife", "tool", "weapon"]
  },
  "1f3fa": {
    name: "amphora",
    code: "1f3fa",
    keywords: ["amphora", "Aquarius", "cooking", "drink", "jug", "zodiac"]
  },
  "1f30d": {
    name: "globe_showing_Europe-Africa",
    code: "1f30d",
    keywords: [
      "Africa",
      "earth",
      "Europe",
      "globe",
      "globe showing Europe-Africa",
      "world"
    ]
  },
  "1f30e": {
    name: "globe_showing_Americas",
    code: "1f30e",
    keywords: ["Americas", "earth", "globe", "globe showing Americas", "world"]
  },
  "1f30f": {
    name: "globe_showing_Asia-Australia",
    code: "1f30f",
    keywords: [
      "Asia",
      "Australia",
      "earth",
      "globe",
      "globe showing Asia-Australia",
      "world"
    ]
  },
  "1f310": {
    name: "globe_with_meridians",
    code: "1f310",
    keywords: ["earth", "globe", "globe with meridians", "meridians", "world"]
  },
  "1f5fa": { name: "world_map", code: "1f5fa", keywords: ["map", "world"] },
  "1f5fe": {
    name: "map_of_Japan",
    code: "1f5fe",
    keywords: ["Japan", "map", "map of Japan"]
  },
  "1f9ed": {
    name: "compass",
    code: "1f9ed",
    keywords: ["compass", "magnetic", "navigation", "orienteering"]
  },
  "1f3d4": {
    name: "snow-capped_mountain",
    code: "1f3d4",
    keywords: ["cold", "mountain", "snow", "snow-capped mountain"]
  },
  "26f0": { name: "mountain", code: "26f0", keywords: ["mountain"] },
  "1f30b": {
    name: "volcano",
    code: "1f30b",
    keywords: ["eruption", "mountain", "volcano"]
  },
  "1f5fb": {
    name: "mount_fuji",
    code: "1f5fb",
    keywords: ["fuji", "mount fuji", "mountain"]
  },
  "1f3d5": { name: "camping", code: "1f3d5", keywords: ["camping"] },
  "1f3d6": {
    name: "beach_with_umbrella",
    code: "1f3d6",
    keywords: ["beach", "beach with umbrella", "umbrella"]
  },
  "1f3dc": { name: "desert", code: "1f3dc", keywords: ["desert"] },
  "1f3dd": {
    name: "desert_island",
    code: "1f3dd",
    keywords: ["desert", "island"]
  },
  "1f3de": {
    name: "national_park",
    code: "1f3de",
    keywords: ["national park", "park"]
  },
  "1f3df": { name: "stadium", code: "1f3df", keywords: ["stadium"] },
  "1f3db": {
    name: "classical_building",
    code: "1f3db",
    keywords: ["classical", "classical building"]
  },
  "1f3d7": {
    name: "building_construction",
    code: "1f3d7",
    keywords: ["building construction", "construction"]
  },
  "1f9f1": {
    name: "brick",
    code: "1f9f1",
    keywords: ["brick", "bricks", "clay", "mortar", "wall"]
  },
  "1faa8": {
    name: "\u229b_rock",
    code: "1faa8",
    keywords: ["boulder", "heavy", "rock", "solid", "stone"]
  },
  "1fab5": {
    name: "\u229b_wood",
    code: "1fab5",
    keywords: ["log", "lumber", "timber", "wood"]
  },
  "1f6d6": {
    name: "\u229b_hut",
    code: "1f6d6",
    keywords: ["house", "hut", "roundhouse", "yurt"]
  },
  "1f3d8": { name: "houses", code: "1f3d8", keywords: ["houses"] },
  "1f3da": {
    name: "derelict_house",
    code: "1f3da",
    keywords: ["derelict", "house"]
  },
  "1f3e0": { name: "house", code: "1f3e0", keywords: ["home", "house"] },
  "1f3e1": {
    name: "house_with_garden",
    code: "1f3e1",
    keywords: ["garden", "home", "house", "house with garden"]
  },
  "1f3e2": {
    name: "office_building",
    code: "1f3e2",
    keywords: ["building", "office building"]
  },
  "1f3e3": {
    name: "Japanese_post_office",
    code: "1f3e3",
    keywords: ["Japanese", "Japanese post office", "post"]
  },
  "1f3e4": {
    name: "post_office",
    code: "1f3e4",
    keywords: ["European", "post", "post office"]
  },
  "1f3e5": {
    name: "hospital",
    code: "1f3e5",
    keywords: ["doctor", "hospital", "medicine"]
  },
  "1f3e6": { name: "bank", code: "1f3e6", keywords: ["bank", "building"] },
  "1f3e8": { name: "hotel", code: "1f3e8", keywords: ["building", "hotel"] },
  "1f3e9": { name: "love_hotel", code: "1f3e9", keywords: ["hotel", "love"] },
  "1f3ea": {
    name: "convenience_store",
    code: "1f3ea",
    keywords: ["convenience", "store"]
  },
  "1f3eb": { name: "school", code: "1f3eb", keywords: ["building", "school"] },
  "1f3ec": {
    name: "department_store",
    code: "1f3ec",
    keywords: ["department", "store"]
  },
  "1f3ed": {
    name: "factory",
    code: "1f3ed",
    keywords: ["building", "factory"]
  },
  "1f3ef": {
    name: "Japanese_castle",
    code: "1f3ef",
    keywords: ["castle", "Japanese"]
  },
  "1f3f0": { name: "castle", code: "1f3f0", keywords: ["castle", "European"] },
  "1f492": {
    name: "wedding",
    code: "1f492",
    keywords: ["chapel", "romance", "wedding"]
  },
  "1f5fc": { name: "Tokyo_tower", code: "1f5fc", keywords: ["Tokyo", "tower"] },
  "1f5fd": {
    name: "Statue_of_Liberty",
    code: "1f5fd",
    keywords: ["liberty", "statue", "Statue of Liberty"]
  },
  "26ea": {
    name: "church",
    code: "26ea",
    keywords: ["Christian", "church", "cross", "religion"]
  },
  "1f54c": {
    name: "mosque",
    code: "1f54c",
    keywords: ["islam", "mosque", "Muslim", "religion"]
  },
  "1f6d5": {
    name: "hindu_temple",
    code: "1f6d5",
    keywords: ["hindu", "temple"]
  },
  "1f54d": {
    name: "synagogue",
    code: "1f54d",
    keywords: ["Jew", "Jewish", "religion", "synagogue", "temple"]
  },
  "26e9": {
    name: "shinto_shrine",
    code: "26e9",
    keywords: ["religion", "shinto", "shrine"]
  },
  "1f54b": {
    name: "kaaba",
    code: "1f54b",
    keywords: ["islam", "kaaba", "Muslim", "religion"]
  },
  "26f2": { name: "fountain", code: "26f2", keywords: ["fountain"] },
  "26fa": { name: "tent", code: "26fa", keywords: ["camping", "tent"] },
  "1f301": { name: "foggy", code: "1f301", keywords: ["fog", "foggy"] },
  "1f303": {
    name: "night_with_stars",
    code: "1f303",
    keywords: ["night", "night with stars", "star"]
  },
  "1f3d9": {
    name: "cityscape",
    code: "1f3d9",
    keywords: ["city", "cityscape"]
  },
  "1f304": {
    name: "sunrise_over_mountains",
    code: "1f304",
    keywords: [
      "morning",
      "mountain",
      "sun",
      "sunrise",
      "sunrise over mountains"
    ]
  },
  "1f305": {
    name: "sunrise",
    code: "1f305",
    keywords: ["morning", "sun", "sunrise"]
  },
  "1f306": {
    name: "cityscape_at_dusk",
    code: "1f306",
    keywords: [
      "city",
      "cityscape at dusk",
      "dusk",
      "evening",
      "landscape",
      "sunset"
    ]
  },
  "1f307": {
    name: "sunset",
    code: "1f307",
    keywords: ["dusk", "sun", "sunset"]
  },
  "1f309": {
    name: "bridge_at_night",
    code: "1f309",
    keywords: ["bridge", "bridge at night", "night"]
  },
  "2668": {
    name: "hot_springs",
    code: "2668",
    keywords: ["hot", "hotsprings", "springs", "steaming"]
  },
  "1f3a0": {
    name: "carousel_horse",
    code: "1f3a0",
    keywords: ["carousel", "horse"]
  },
  "1f3a1": {
    name: "ferris_wheel",
    code: "1f3a1",
    keywords: ["amusement park", "ferris", "wheel"]
  },
  "1f3a2": {
    name: "roller_coaster",
    code: "1f3a2",
    keywords: ["amusement park", "coaster", "roller"]
  },
  "1f488": {
    name: "barber_pole",
    code: "1f488",
    keywords: ["barber", "haircut", "pole"]
  },
  "1f3aa": { name: "circus_tent", code: "1f3aa", keywords: ["circus", "tent"] },
  "1f682": {
    name: "locomotive",
    code: "1f682",
    keywords: ["engine", "locomotive", "railway", "steam", "train"]
  },
  "1f683": {
    name: "railway_car",
    code: "1f683",
    keywords: ["car", "electric", "railway", "train", "tram", "trolleybus"]
  },
  "1f684": {
    name: "high-speed_train",
    code: "1f684",
    keywords: ["high-speed train", "railway", "shinkansen", "speed", "train"]
  },
  "1f685": {
    name: "bullet_train",
    code: "1f685",
    keywords: ["bullet", "railway", "shinkansen", "speed", "train"]
  },
  "1f686": { name: "train", code: "1f686", keywords: ["railway", "train"] },
  "1f687": { name: "metro", code: "1f687", keywords: ["metro", "subway"] },
  "1f688": {
    name: "light_rail",
    code: "1f688",
    keywords: ["light rail", "railway"]
  },
  "1f689": {
    name: "station",
    code: "1f689",
    keywords: ["railway", "station", "train"]
  },
  "1f68a": { name: "tram", code: "1f68a", keywords: ["tram", "trolleybus"] },
  "1f69d": {
    name: "monorail",
    code: "1f69d",
    keywords: ["monorail", "vehicle"]
  },
  "1f69e": {
    name: "mountain_railway",
    code: "1f69e",
    keywords: ["car", "mountain", "railway"]
  },
  "1f68b": {
    name: "tram_car",
    code: "1f68b",
    keywords: ["car", "tram", "trolleybus"]
  },
  "1f68c": { name: "bus", code: "1f68c", keywords: ["bus", "vehicle"] },
  "1f68d": {
    name: "oncoming_bus",
    code: "1f68d",
    keywords: ["bus", "oncoming"]
  },
  "1f68e": {
    name: "trolleybus",
    code: "1f68e",
    keywords: ["bus", "tram", "trolley", "trolleybus"]
  },
  "1f690": { name: "minibus", code: "1f690", keywords: ["bus", "minibus"] },
  "1f691": {
    name: "ambulance",
    code: "1f691",
    keywords: ["ambulance", "vehicle"]
  },
  "1f692": {
    name: "fire_engine",
    code: "1f692",
    keywords: ["engine", "fire", "truck"]
  },
  "1f693": {
    name: "police_car",
    code: "1f693",
    keywords: ["car", "patrol", "police"]
  },
  "1f694": {
    name: "oncoming_police_car",
    code: "1f694",
    keywords: ["car", "oncoming", "police"]
  },
  "1f695": { name: "taxi", code: "1f695", keywords: ["taxi", "vehicle"] },
  "1f696": {
    name: "oncoming_taxi",
    code: "1f696",
    keywords: ["oncoming", "taxi"]
  },
  "1f697": {
    name: "automobile",
    code: "1f697",
    keywords: ["automobile", "car"]
  },
  "1f698": {
    name: "oncoming_automobile",
    code: "1f698",
    keywords: ["automobile", "car", "oncoming"]
  },
  "1f699": {
    name: "sport_utility_vehicle",
    code: "1f699",
    keywords: ["recreational", "sport utility", "sport utility vehicle"]
  },
  "1f6fb": {
    name: "\u229b_pickup_truck",
    code: "1f6fb",
    keywords: ["pick-up", "pickup", "truck"]
  },
  "1f69a": {
    name: "delivery_truck",
    code: "1f69a",
    keywords: ["delivery", "truck"]
  },
  "1f69b": {
    name: "articulated_lorry",
    code: "1f69b",
    keywords: ["articulated lorry", "lorry", "semi", "truck"]
  },
  "1f69c": { name: "tractor", code: "1f69c", keywords: ["tractor", "vehicle"] },
  "1f3ce": { name: "racing_car", code: "1f3ce", keywords: ["car", "racing"] },
  "1f3cd": {
    name: "motorcycle",
    code: "1f3cd",
    keywords: ["motorcycle", "racing"]
  },
  "1f6f5": {
    name: "motor_scooter",
    code: "1f6f5",
    keywords: ["motor", "scooter"]
  },
  "1f9bd": {
    name: "manual_wheelchair",
    code: "1f9bd",
    keywords: ["accessibility", "manual wheelchair"]
  },
  "1f9bc": {
    name: "motorized_wheelchair",
    code: "1f9bc",
    keywords: ["accessibility", "motorized wheelchair"]
  },
  "1f6fa": {
    name: "auto_rickshaw",
    code: "1f6fa",
    keywords: ["auto rickshaw", "tuk tuk"]
  },
  "1f6b2": { name: "bicycle", code: "1f6b2", keywords: ["bicycle", "bike"] },
  "1f6f4": {
    name: "kick_scooter",
    code: "1f6f4",
    keywords: ["kick", "scooter"]
  },
  "1f6f9": {
    name: "skateboard",
    code: "1f6f9",
    keywords: ["board", "skateboard"]
  },
  "1f6fc": {
    name: "\u229b_roller_skate",
    code: "1f6fc",
    keywords: ["roller", "skate"]
  },
  "1f68f": {
    name: "bus_stop",
    code: "1f68f",
    keywords: ["bus", "busstop", "stop"]
  },
  "1f6e3": {
    name: "motorway",
    code: "1f6e3",
    keywords: ["highway", "motorway", "road"]
  },
  "1f6e4": {
    name: "railway_track",
    code: "1f6e4",
    keywords: ["railway", "railway track", "train"]
  },
  "1f6e2": { name: "oil_drum", code: "1f6e2", keywords: ["drum", "oil"] },
  "26fd": {
    name: "fuel_pump",
    code: "26fd",
    keywords: ["diesel", "fuel", "fuelpump", "gas", "pump", "station"]
  },
  "1f6a8": {
    name: "police_car_light",
    code: "1f6a8",
    keywords: ["beacon", "car", "light", "police", "revolving"]
  },
  "1f6a5": {
    name: "horizontal_traffic_light",
    code: "1f6a5",
    keywords: ["horizontal traffic light", "light", "signal", "traffic"]
  },
  "1f6a6": {
    name: "vertical_traffic_light",
    code: "1f6a6",
    keywords: ["light", "signal", "traffic", "vertical traffic light"]
  },
  "1f6d1": {
    name: "stop_sign",
    code: "1f6d1",
    keywords: ["octagonal", "sign", "stop"]
  },
  "1f6a7": {
    name: "construction",
    code: "1f6a7",
    keywords: ["barrier", "construction"]
  },
  "2693": {
    name: "anchor",
    code: "2693",
    keywords: ["anchor", "ship", "tool"]
  },
  "26f5": {
    name: "sailboat",
    code: "26f5",
    keywords: ["boat", "resort", "sailboat", "sea", "yacht"]
  },
  "1f6f6": { name: "canoe", code: "1f6f6", keywords: ["boat", "canoe"] },
  "1f6a4": {
    name: "speedboat",
    code: "1f6a4",
    keywords: ["boat", "speedboat"]
  },
  "1f6f3": {
    name: "passenger_ship",
    code: "1f6f3",
    keywords: ["passenger", "ship"]
  },
  "26f4": {
    name: "ferry",
    code: "26f4",
    keywords: ["boat", "ferry", "passenger"]
  },
  "1f6e5": {
    name: "motor_boat",
    code: "1f6e5",
    keywords: ["boat", "motor boat", "motorboat"]
  },
  "1f6a2": {
    name: "ship",
    code: "1f6a2",
    keywords: ["boat", "passenger", "ship"]
  },
  "2708": {
    name: "airplane",
    code: "2708",
    keywords: ["aeroplane", "airplane"]
  },
  "1f6e9": {
    name: "small_airplane",
    code: "1f6e9",
    keywords: ["aeroplane", "airplane", "small airplane"]
  },
  "1f6eb": {
    name: "airplane_departure",
    code: "1f6eb",
    keywords: ["aeroplane", "airplane", "check-in", "departure", "departures"]
  },
  "1f6ec": {
    name: "airplane_arrival",
    code: "1f6ec",
    keywords: [
      "aeroplane",
      "airplane",
      "airplane arrival",
      "arrivals",
      "arriving",
      "landing"
    ]
  },
  "1fa82": {
    name: "parachute",
    code: "1fa82",
    keywords: ["hang-glide", "parachute", "parasail", "skydive"]
  },
  "1f4ba": { name: "seat", code: "1f4ba", keywords: ["chair", "seat"] },
  "1f681": {
    name: "helicopter",
    code: "1f681",
    keywords: ["helicopter", "vehicle"]
  },
  "1f69f": {
    name: "suspension_railway",
    code: "1f69f",
    keywords: ["railway", "suspension"]
  },
  "1f6a0": {
    name: "mountain_cableway",
    code: "1f6a0",
    keywords: ["cable", "gondola", "mountain", "mountain cableway"]
  },
  "1f6a1": {
    name: "aerial_tramway",
    code: "1f6a1",
    keywords: ["aerial", "cable", "car", "gondola", "tramway"]
  },
  "1f6f0": {
    name: "satellite",
    code: "1f6f0",
    keywords: ["satellite", "space"]
  },
  "1f680": { name: "rocket", code: "1f680", keywords: ["rocket", "space"] },
  "1f6f8": {
    name: "flying_saucer",
    code: "1f6f8",
    keywords: ["flying saucer", "UFO"]
  },
  "1f6ce": {
    name: "bellhop_bell",
    code: "1f6ce",
    keywords: ["bell", "bellhop", "hotel"]
  },
  "1f9f3": {
    name: "luggage",
    code: "1f9f3",
    keywords: ["luggage", "packing", "travel"]
  },
  "231b": {
    name: "hourglass_done",
    code: "231b",
    keywords: ["hourglass done", "sand", "timer"]
  },
  "23f3": {
    name: "hourglass_not_done",
    code: "23f3",
    keywords: ["hourglass", "hourglass not done", "sand", "timer"]
  },
  "231a": { name: "watch", code: "231a", keywords: ["clock", "watch"] },
  "23f0": { name: "alarm_clock", code: "23f0", keywords: ["alarm", "clock"] },
  "23f1": { name: "stopwatch", code: "23f1", keywords: ["clock", "stopwatch"] },
  "23f2": { name: "timer_clock", code: "23f2", keywords: ["clock", "timer"] },
  "1f570": {
    name: "mantelpiece_clock",
    code: "1f570",
    keywords: ["clock", "mantelpiece clock"]
  },
  "1f55b": {
    name: "twelve_o\u2019clock",
    code: "1f55b",
    keywords: ["00", "12", "12:00", "clock", "o\u2019clock", "twelve"]
  },
  "1f567": {
    name: "twelve-thirty",
    code: "1f567",
    keywords: ["12", "12:30", "clock", "thirty", "twelve", "twelve-thirty"]
  },
  "1f550": {
    name: "one_o\u2019clock",
    code: "1f550",
    keywords: ["00", "1", "1:00", "clock", "o\u2019clock", "one"]
  },
  "1f55c": {
    name: "one-thirty",
    code: "1f55c",
    keywords: ["1", "1:30", "clock", "one", "one-thirty", "thirty"]
  },
  "1f551": {
    name: "two_o\u2019clock",
    code: "1f551",
    keywords: ["00", "2", "2:00", "clock", "o\u2019clock", "two"]
  },
  "1f55d": {
    name: "two-thirty",
    code: "1f55d",
    keywords: ["2", "2:30", "clock", "thirty", "two", "two-thirty"]
  },
  "1f552": {
    name: "three_o\u2019clock",
    code: "1f552",
    keywords: ["00", "3", "3:00", "clock", "o\u2019clock", "three"]
  },
  "1f55e": {
    name: "three-thirty",
    code: "1f55e",
    keywords: ["3", "3:30", "clock", "thirty", "three", "three-thirty"]
  },
  "1f553": {
    name: "four_o\u2019clock",
    code: "1f553",
    keywords: ["00", "4", "4:00", "clock", "four", "o\u2019clock"]
  },
  "1f55f": {
    name: "four-thirty",
    code: "1f55f",
    keywords: ["4", "4:30", "clock", "four", "four-thirty", "thirty"]
  },
  "1f554": {
    name: "five_o\u2019clock",
    code: "1f554",
    keywords: ["00", "5", "5:00", "clock", "five", "o\u2019clock"]
  },
  "1f560": {
    name: "five-thirty",
    code: "1f560",
    keywords: ["5", "5:30", "clock", "five", "five-thirty", "thirty"]
  },
  "1f555": {
    name: "six_o\u2019clock",
    code: "1f555",
    keywords: ["00", "6", "6:00", "clock", "o\u2019clock", "six"]
  },
  "1f561": {
    name: "six-thirty",
    code: "1f561",
    keywords: ["6", "6:30", "clock", "six", "six-thirty", "thirty"]
  },
  "1f556": {
    name: "seven_o\u2019clock",
    code: "1f556",
    keywords: ["00", "7", "7:00", "clock", "o\u2019clock", "seven"]
  },
  "1f562": {
    name: "seven-thirty",
    code: "1f562",
    keywords: ["7", "7:30", "clock", "seven", "seven-thirty", "thirty"]
  },
  "1f557": {
    name: "eight_o\u2019clock",
    code: "1f557",
    keywords: ["00", "8", "8:00", "clock", "eight", "o\u2019clock"]
  },
  "1f563": {
    name: "eight-thirty",
    code: "1f563",
    keywords: ["8", "8:30", "clock", "eight", "eight-thirty", "thirty"]
  },
  "1f558": {
    name: "nine_o\u2019clock",
    code: "1f558",
    keywords: ["00", "9", "9:00", "clock", "nine", "o\u2019clock"]
  },
  "1f564": {
    name: "nine-thirty",
    code: "1f564",
    keywords: ["9", "9:30", "clock", "nine", "nine-thirty", "thirty"]
  },
  "1f559": {
    name: "ten_o\u2019clock",
    code: "1f559",
    keywords: ["00", "10", "10:00", "clock", "o\u2019clock", "ten"]
  },
  "1f565": {
    name: "ten-thirty",
    code: "1f565",
    keywords: ["10", "10:30", "clock", "ten", "ten-thirty", "thirty"]
  },
  "1f55a": {
    name: "eleven_o\u2019clock",
    code: "1f55a",
    keywords: ["00", "11", "11:00", "clock", "eleven", "o\u2019clock"]
  },
  "1f566": {
    name: "eleven-thirty",
    code: "1f566",
    keywords: ["11", "11:30", "clock", "eleven", "eleven-thirty", "thirty"]
  },
  "1f311": {
    name: "new_moon",
    code: "1f311",
    keywords: ["dark", "moon", "new moon"]
  },
  "1f312": {
    name: "waxing_crescent_moon",
    code: "1f312",
    keywords: ["crescent", "moon", "waxing"]
  },
  "1f313": {
    name: "first_quarter_moon",
    code: "1f313",
    keywords: ["first quarter moon", "moon", "quarter"]
  },
  "1f314": {
    name: "waxing_gibbous_moon",
    code: "1f314",
    keywords: ["gibbous", "moon", "waxing"]
  },
  "1f315": { name: "full_moon", code: "1f315", keywords: ["full", "moon"] },
  "1f316": {
    name: "waning_gibbous_moon",
    code: "1f316",
    keywords: ["gibbous", "moon", "waning"]
  },
  "1f317": {
    name: "last_quarter_moon",
    code: "1f317",
    keywords: ["last quarter moon", "moon", "quarter"]
  },
  "1f318": {
    name: "waning_crescent_moon",
    code: "1f318",
    keywords: ["crescent", "moon", "waning"]
  },
  "1f319": {
    name: "crescent_moon",
    code: "1f319",
    keywords: ["crescent", "moon"]
  },
  "1f31a": {
    name: "new_moon_face",
    code: "1f31a",
    keywords: ["face", "moon", "new moon face"]
  },
  "1f31b": {
    name: "first_quarter_moon_face",
    code: "1f31b",
    keywords: ["face", "first quarter moon face", "moon", "quarter"]
  },
  "1f31c": {
    name: "last_quarter_moon_face",
    code: "1f31c",
    keywords: ["face", "last quarter moon face", "moon", "quarter"]
  },
  "1f321": {
    name: "thermometer",
    code: "1f321",
    keywords: ["thermometer", "weather"]
  },
  "2600": {
    name: "sun",
    code: "2600",
    keywords: ["bright", "rays", "sun", "sunny"]
  },
  "1f31d": {
    name: "full_moon_face",
    code: "1f31d",
    keywords: ["bright", "face", "full", "moon"]
  },
  "1f31e": {
    name: "sun_with_face",
    code: "1f31e",
    keywords: ["bright", "face", "sun", "sun with face"]
  },
  "1fa90": {
    name: "ringed_planet",
    code: "1fa90",
    keywords: ["ringed planet", "saturn", "saturnine"]
  },
  "2b50": { name: "star", code: "2b50", keywords: ["star"] },
  "1f31f": {
    name: "glowing_star",
    code: "1f31f",
    keywords: ["glittery", "glow", "glowing star", "shining", "sparkle", "star"]
  },
  "1f320": {
    name: "shooting_star",
    code: "1f320",
    keywords: ["falling", "shooting", "star"]
  },
  "1f30c": {
    name: "milky_way",
    code: "1f30c",
    keywords: ["milky way", "space"]
  },
  "2601": { name: "cloud", code: "2601", keywords: ["cloud", "weather"] },
  "26c5": {
    name: "sun_behind_cloud",
    code: "26c5",
    keywords: ["cloud", "sun", "sun behind cloud"]
  },
  "26c8": {
    name: "cloud_with_lightning_and_rain",
    code: "26c8",
    keywords: ["cloud", "cloud with lightning and rain", "rain", "thunder"]
  },
  "1f324": {
    name: "sun_behind_small_cloud",
    code: "1f324",
    keywords: ["cloud", "sun", "sun behind small cloud"]
  },
  "1f325": {
    name: "sun_behind_large_cloud",
    code: "1f325",
    keywords: ["cloud", "sun", "sun behind large cloud"]
  },
  "1f326": {
    name: "sun_behind_rain_cloud",
    code: "1f326",
    keywords: ["cloud", "rain", "sun", "sun behind rain cloud"]
  },
  "1f327": {
    name: "cloud_with_rain",
    code: "1f327",
    keywords: ["cloud", "cloud with rain", "rain"]
  },
  "1f328": {
    name: "cloud_with_snow",
    code: "1f328",
    keywords: ["cloud", "cloud with snow", "cold", "snow"]
  },
  "1f329": {
    name: "cloud_with_lightning",
    code: "1f329",
    keywords: ["cloud", "cloud with lightning", "lightning"]
  },
  "1f32a": {
    name: "tornado",
    code: "1f32a",
    keywords: ["cloud", "tornado", "whirlwind"]
  },
  "1f32b": { name: "fog", code: "1f32b", keywords: ["cloud", "fog"] },
  "1f32c": {
    name: "wind_face",
    code: "1f32c",
    keywords: ["blow", "cloud", "face", "wind"]
  },
  "1f300": {
    name: "cyclone",
    code: "1f300",
    keywords: ["cyclone", "dizzy", "hurricane", "twister", "typhoon"]
  },
  "1f308": { name: "rainbow", code: "1f308", keywords: ["rain", "rainbow"] },
  "1f302": {
    name: "closed_umbrella",
    code: "1f302",
    keywords: ["closed umbrella", "clothing", "rain", "umbrella"]
  },
  "2602": {
    name: "umbrella",
    code: "2602",
    keywords: ["clothing", "rain", "umbrella"]
  },
  "2614": {
    name: "umbrella_with_rain_drops",
    code: "2614",
    keywords: [
      "clothing",
      "drop",
      "rain",
      "umbrella",
      "umbrella with rain drops"
    ]
  },
  "26f1": {
    name: "umbrella_on_ground",
    code: "26f1",
    keywords: ["rain", "sun", "umbrella", "umbrella on ground"]
  },
  "26a1": {
    name: "high_voltage",
    code: "26a1",
    keywords: [
      "danger",
      "electric",
      "high voltage",
      "lightning",
      "voltage",
      "zap"
    ]
  },
  "2744": {
    name: "snowflake",
    code: "2744",
    keywords: ["cold", "snow", "snowflake"]
  },
  "2603": {
    name: "snowman",
    code: "2603",
    keywords: ["cold", "snow", "snowman"]
  },
  "26c4": {
    name: "snowman_without_snow",
    code: "26c4",
    keywords: ["cold", "snow", "snowman", "snowman without snow"]
  },
  "2604": { name: "comet", code: "2604", keywords: ["comet", "space"] },
  "1f525": { name: "fire", code: "1f525", keywords: ["fire", "flame", "tool"] },
  "1f4a7": {
    name: "droplet",
    code: "1f4a7",
    keywords: ["cold", "comic", "drop", "droplet", "sweat"]
  },
  "1f30a": {
    name: "water_wave",
    code: "1f30a",
    keywords: ["ocean", "water", "wave"]
  },
  "1f383": {
    name: "jack-o-lantern",
    code: "1f383",
    keywords: ["celebration", "halloween", "jack", "jack-o-lantern", "lantern"]
  },
  "1f384": {
    name: "Christmas_tree",
    code: "1f384",
    keywords: ["celebration", "Christmas", "tree"]
  },
  "1f386": {
    name: "fireworks",
    code: "1f386",
    keywords: ["celebration", "fireworks"]
  },
  "1f387": {
    name: "sparkler",
    code: "1f387",
    keywords: ["celebration", "fireworks", "sparkle", "sparkler"]
  },
  "1f9e8": {
    name: "firecracker",
    code: "1f9e8",
    keywords: ["dynamite", "explosive", "firecracker", "fireworks"]
  },
  "2728": {
    name: "sparkles",
    code: "2728",
    keywords: ["*", "sparkle", "sparkles", "star"]
  },
  "1f388": {
    name: "balloon",
    code: "1f388",
    keywords: ["balloon", "celebration"]
  },
  "1f389": {
    name: "party_popper",
    code: "1f389",
    keywords: ["celebration", "party", "popper", "tada"]
  },
  "1f38a": {
    name: "confetti_ball",
    code: "1f38a",
    keywords: ["ball", "celebration", "confetti"]
  },
  "1f38b": {
    name: "tanabata_tree",
    code: "1f38b",
    keywords: ["banner", "celebration", "Japanese", "tanabata tree", "tree"]
  },
  "1f38d": {
    name: "pine_decoration",
    code: "1f38d",
    keywords: ["bamboo", "celebration", "Japanese", "pine", "pine decoration"]
  },
  "1f38e": {
    name: "Japanese_dolls",
    code: "1f38e",
    keywords: ["celebration", "doll", "festival", "Japanese", "Japanese dolls"]
  },
  "1f38f": {
    name: "carp_streamer",
    code: "1f38f",
    keywords: ["carp", "celebration", "streamer"]
  },
  "1f390": {
    name: "wind_chime",
    code: "1f390",
    keywords: ["bell", "celebration", "chime", "wind"]
  },
  "1f391": {
    name: "moon_viewing_ceremony",
    code: "1f391",
    keywords: ["celebration", "ceremony", "moon", "moon viewing ceremony"]
  },
  "1f9e7": {
    name: "red_envelope",
    code: "1f9e7",
    keywords: [
      "gift",
      "good luck",
      "h\u00f3ngb\u0101o",
      "lai see",
      "money",
      "red envelope"
    ]
  },
  "1f380": {
    name: "ribbon",
    code: "1f380",
    keywords: ["celebration", "ribbon"]
  },
  "1f381": {
    name: "wrapped_gift",
    code: "1f381",
    keywords: ["box", "celebration", "gift", "present", "wrapped"]
  },
  "1f397": {
    name: "reminder_ribbon",
    code: "1f397",
    keywords: ["celebration", "reminder", "ribbon"]
  },
  "1f39f": {
    name: "admission_tickets",
    code: "1f39f",
    keywords: ["admission", "admission tickets", "ticket"]
  },
  "1f3ab": { name: "ticket", code: "1f3ab", keywords: ["admission", "ticket"] },
  "1f396": {
    name: "military_medal",
    code: "1f396",
    keywords: ["celebration", "medal", "military"]
  },
  "1f3c6": { name: "trophy", code: "1f3c6", keywords: ["prize", "trophy"] },
  "1f3c5": {
    name: "sports_medal",
    code: "1f3c5",
    keywords: ["medal", "sports medal"]
  },
  "1f947": {
    name: "1st_place_medal",
    code: "1f947",
    keywords: ["1st place medal", "first", "gold", "medal"]
  },
  "1f948": {
    name: "2nd_place_medal",
    code: "1f948",
    keywords: ["2nd place medal", "medal", "second", "silver"]
  },
  "1f949": {
    name: "3rd_place_medal",
    code: "1f949",
    keywords: ["3rd place medal", "bronze", "medal", "third"]
  },
  "26bd": {
    name: "soccer_ball",
    code: "26bd",
    keywords: ["ball", "football", "soccer"]
  },
  "26be": { name: "baseball", code: "26be", keywords: ["ball", "baseball"] },
  "1f94e": {
    name: "softball",
    code: "1f94e",
    keywords: ["ball", "glove", "softball", "underarm"]
  },
  "1f3c0": {
    name: "basketball",
    code: "1f3c0",
    keywords: ["ball", "basketball", "hoop"]
  },
  "1f3d0": {
    name: "volleyball",
    code: "1f3d0",
    keywords: ["ball", "game", "volleyball"]
  },
  "1f3c8": {
    name: "american_football",
    code: "1f3c8",
    keywords: ["american", "ball", "football"]
  },
  "1f3c9": {
    name: "rugby_football",
    code: "1f3c9",
    keywords: ["ball", "football", "rugby"]
  },
  "1f3be": {
    name: "tennis",
    code: "1f3be",
    keywords: ["ball", "racquet", "tennis"]
  },
  "1f94f": {
    name: "flying_disc",
    code: "1f94f",
    keywords: ["flying disc", "ultimate"]
  },
  "1f3b3": {
    name: "bowling",
    code: "1f3b3",
    keywords: ["ball", "bowling", "game"]
  },
  "1f3cf": {
    name: "cricket_game",
    code: "1f3cf",
    keywords: ["ball", "bat", "cricket game", "game"]
  },
  "1f3d1": {
    name: "field_hockey",
    code: "1f3d1",
    keywords: ["ball", "field", "game", "hockey", "stick"]
  },
  "1f3d2": {
    name: "ice_hockey",
    code: "1f3d2",
    keywords: ["game", "hockey", "ice", "puck", "stick"]
  },
  "1f94d": {
    name: "lacrosse",
    code: "1f94d",
    keywords: ["ball", "goal", "lacrosse", "stick"]
  },
  "1f3d3": {
    name: "ping_pong",
    code: "1f3d3",
    keywords: ["ball", "bat", "game", "paddle", "ping pong", "table tennis"]
  },
  "1f3f8": {
    name: "badminton",
    code: "1f3f8",
    keywords: ["badminton", "birdie", "game", "racquet", "shuttlecock"]
  },
  "1f94a": {
    name: "boxing_glove",
    code: "1f94a",
    keywords: ["boxing", "glove"]
  },
  "1f94b": {
    name: "martial_arts_uniform",
    code: "1f94b",
    keywords: [
      "judo",
      "karate",
      "martial arts",
      "martial arts uniform",
      "taekwondo",
      "uniform"
    ]
  },
  "1f945": { name: "goal_net", code: "1f945", keywords: ["goal", "net"] },
  "26f3": {
    name: "flag_in_hole",
    code: "26f3",
    keywords: ["flag in hole", "golf", "hole"]
  },
  "26f8": { name: "ice_skate", code: "26f8", keywords: ["ice", "skate"] },
  "1f3a3": {
    name: "fishing_pole",
    code: "1f3a3",
    keywords: ["fish", "fishing pole", "pole"]
  },
  "1f93f": {
    name: "diving_mask",
    code: "1f93f",
    keywords: ["diving", "diving mask", "scuba", "snorkeling"]
  },
  "1f3bd": {
    name: "running_shirt",
    code: "1f3bd",
    keywords: ["athletics", "running", "sash", "shirt"]
  },
  "1f3bf": { name: "skis", code: "1f3bf", keywords: ["ski", "skis", "snow"] },
  "1f6f7": {
    name: "sled",
    code: "1f6f7",
    keywords: ["sled", "sledge", "sleigh", ""]
  },
  "1f94c": {
    name: "curling_stone",
    code: "1f94c",
    keywords: ["curling stone", "game", "rock"]
  },
  "1f3af": {
    name: "direct_hit",
    code: "1f3af",
    keywords: ["bullseye", "dart", "direct hit", "game", "hit", "target"]
  },
  "1fa80": {
    name: "yo-yo",
    code: "1fa80",
    keywords: ["fluctuate", "toy", "yo-yo"]
  },
  "1fa81": { name: "kite", code: "1fa81", keywords: ["fly", "kite", "soar"] },
  "1f3b1": {
    name: "pool_8_ball",
    code: "1f3b1",
    keywords: ["8", "ball", "billiard", "eight", "game", "pool 8 ball"]
  },
  "1f52e": {
    name: "crystal_ball",
    code: "1f52e",
    keywords: ["ball", "crystal", "fairy tale", "fantasy", "fortune", "tool"]
  },
  "1fa84": {
    name: "\u229b_magic_wand",
    code: "1fa84",
    keywords: ["magic", "magic wand", "witch", "wizard"]
  },
  "1f9ff": {
    name: "nazar_amulet",
    code: "1f9ff",
    keywords: ["bead", "charm", "evil-eye", "nazar", "nazar amulet", "talisman"]
  },
  "1f3ae": {
    name: "video_game",
    code: "1f3ae",
    keywords: ["controller", "game", "video game"]
  },
  "1f579": {
    name: "joystick",
    code: "1f579",
    keywords: ["game", "joystick", "video game"]
  },
  "1f3b0": {
    name: "slot_machine",
    code: "1f3b0",
    keywords: ["game", "slot", "slot machine"]
  },
  "1f3b2": {
    name: "game_die",
    code: "1f3b2",
    keywords: ["dice", "die", "game"]
  },
  "1f9e9": {
    name: "puzzle_piece",
    code: "1f9e9",
    keywords: ["clue", "interlocking", "jigsaw", "piece", "puzzle"]
  },
  "1f9f8": {
    name: "teddy_bear",
    code: "1f9f8",
    keywords: ["plaything", "plush", "stuffed", "teddy bear", "toy"]
  },
  "1fa85": {
    name: "\u229b_pi\u00f1ata",
    code: "1fa85",
    keywords: ["celebration", "party", "pi\u00f1ata"]
  },
  "1fa86": {
    name: "\u229b_nesting_dolls",
    code: "1fa86",
    keywords: ["doll", "nesting", "nesting dolls", "russia"]
  },
  "2660": {
    name: "spade_suit",
    code: "2660",
    keywords: ["card", "game", "spade suit"]
  },
  "2665": {
    name: "heart_suit",
    code: "2665",
    keywords: ["card", "game", "heart suit"]
  },
  "2666": {
    name: "diamond_suit",
    code: "2666",
    keywords: ["card", "diamond suit", "game"]
  },
  "2663": {
    name: "club_suit",
    code: "2663",
    keywords: ["card", "club suit", "game"]
  },
  "265f": {
    name: "chess_pawn",
    code: "265f",
    keywords: ["chess", "chess pawn", "dupe", "expendable"]
  },
  "1f0cf": {
    name: "joker",
    code: "1f0cf",
    keywords: ["card", "game", "joker", "wildcard"]
  },
  "1f004": {
    name: "mahjong_red_dragon",
    code: "1f004",
    keywords: ["game", "mahjong", "mahjong red dragon", "red"]
  },
  "1f3b4": {
    name: "flower_playing_cards",
    code: "1f3b4",
    keywords: [
      "card",
      "flower",
      "flower playing cards",
      "game",
      "Japanese",
      "playing"
    ]
  },
  "1f3ad": {
    name: "performing_arts",
    code: "1f3ad",
    keywords: [
      "art",
      "mask",
      "performing",
      "performing arts",
      "theater",
      "theatre"
    ]
  },
  "1f5bc": {
    name: "framed_picture",
    code: "1f5bc",
    keywords: [
      "art",
      "frame",
      "framed picture",
      "museum",
      "painting",
      "picture"
    ]
  },
  "1f3a8": {
    name: "artist_palette",
    code: "1f3a8",
    keywords: ["art", "artist palette", "museum", "painting", "palette"]
  },
  "1f9f5": {
    name: "thread",
    code: "1f9f5",
    keywords: ["needle", "sewing", "spool", "string", "thread"]
  },
  "1faa1": {
    name: "\u229b_sewing_needle",
    code: "1faa1",
    keywords: [
      "embroidery",
      "needle",
      "sewing",
      "stitches",
      "sutures",
      "tailoring"
    ]
  },
  "1f9f6": {
    name: "yarn",
    code: "1f9f6",
    keywords: ["ball", "crochet", "knit", "yarn"]
  },
  "1faa2": {
    name: "\u229b_knot",
    code: "1faa2",
    keywords: ["knot", "rope", "tangled", "tie", "twine", "twist"]
  },
  "1f453": {
    name: "glasses",
    code: "1f453",
    keywords: ["clothing", "eye", "eyeglasses", "eyewear", "glasses"]
  },
  "1f576": {
    name: "sunglasses",
    code: "1f576",
    keywords: ["dark", "eye", "eyewear", "glasses", "sunglasses"]
  },
  "1f97d": {
    name: "goggles",
    code: "1f97d",
    keywords: ["eye protection", "goggles", "swimming", "welding"]
  },
  "1f97c": {
    name: "lab_coat",
    code: "1f97c",
    keywords: ["doctor", "experiment", "lab coat", "scientist"]
  },
  "1f9ba": {
    name: "safety_vest",
    code: "1f9ba",
    keywords: ["emergency", "safety", "vest"]
  },
  "1f454": {
    name: "necktie",
    code: "1f454",
    keywords: ["clothing", "necktie", "tie"]
  },
  "1f455": {
    name: "t-shirt",
    code: "1f455",
    keywords: ["clothing", "shirt", "t-shirt", "tshirt"]
  },
  "1f456": {
    name: "jeans",
    code: "1f456",
    keywords: ["clothing", "jeans", "pants", "trousers"]
  },
  "1f9e3": { name: "scarf", code: "1f9e3", keywords: ["neck", "scarf"] },
  "1f9e4": { name: "gloves", code: "1f9e4", keywords: ["gloves", "hand"] },
  "1f9e5": { name: "coat", code: "1f9e5", keywords: ["coat", "jacket"] },
  "1f9e6": { name: "socks", code: "1f9e6", keywords: ["socks", "stocking"] },
  "1f457": { name: "dress", code: "1f457", keywords: ["clothing", "dress"] },
  "1f458": { name: "kimono", code: "1f458", keywords: ["clothing", "kimono"] },
  "1f97b": {
    name: "sari",
    code: "1f97b",
    keywords: ["clothing", "dress", "sari"]
  },
  "1fa71": {
    name: "one-piece_swimsuit",
    code: "1fa71",
    keywords: ["bathing suit", "one-piece swimsuit"]
  },
  "1fa72": {
    name: "briefs",
    code: "1fa72",
    keywords: ["bathing suit", "briefs", "one-piece", "swimsuit", "underwear"]
  },
  "1fa73": {
    name: "shorts",
    code: "1fa73",
    keywords: ["bathing suit", "pants", "shorts", "underwear"]
  },
  "1f459": {
    name: "bikini",
    code: "1f459",
    keywords: ["bikini", "clothing", "swim"]
  },
  "1f45a": {
    name: "woman\u2019s_clothes",
    code: "1f45a",
    keywords: ["clothing", "woman", "woman\u2019s clothes"]
  },
  "1f45b": {
    name: "purse",
    code: "1f45b",
    keywords: ["clothing", "coin", "purse"]
  },
  "1f45c": {
    name: "handbag",
    code: "1f45c",
    keywords: ["bag", "clothing", "handbag", "purse"]
  },
  "1f45d": {
    name: "clutch_bag",
    code: "1f45d",
    keywords: ["bag", "clothing", "clutch bag", "pouch"]
  },
  "1f6cd": {
    name: "shopping_bags",
    code: "1f6cd",
    keywords: ["bag", "hotel", "shopping", "shopping bags"]
  },
  "1f392": {
    name: "backpack",
    code: "1f392",
    keywords: ["backpack", "bag", "rucksack", "satchel", "school"]
  },
  "1fa74": {
    name: "\u229b_thong_sandal",
    code: "1fa74",
    keywords: [
      "beach sandals",
      "sandals",
      "thong sandal",
      "thong sandals",
      "thongs",
      "z\u014dri"
    ]
  },
  "1f45e": {
    name: "man\u2019s_shoe",
    code: "1f45e",
    keywords: ["clothing", "man", "man\u2019s shoe", "shoe"]
  },
  "1f45f": {
    name: "running_shoe",
    code: "1f45f",
    keywords: ["athletic", "clothing", "running shoe", "shoe", "sneaker"]
  },
  "1f97e": {
    name: "hiking_boot",
    code: "1f97e",
    keywords: ["backpacking", "boot", "camping", "hiking"]
  },
  "1f97f": {
    name: "flat_shoe",
    code: "1f97f",
    keywords: ["ballet flat", "flat shoe", "slip-on", "slipper"]
  },
  "1f460": {
    name: "high-heeled_shoe",
    code: "1f460",
    keywords: ["clothing", "heel", "high-heeled shoe", "shoe", "woman"]
  },
  "1f461": {
    name: "woman\u2019s_sandal",
    code: "1f461",
    keywords: ["clothing", "sandal", "shoe", "woman", "woman\u2019s sandal"]
  },
  "1fa70": {
    name: "ballet_shoes",
    code: "1fa70",
    keywords: ["ballet", "ballet shoes", "dance"]
  },
  "1f462": {
    name: "woman\u2019s_boot",
    code: "1f462",
    keywords: ["boot", "clothing", "shoe", "woman", "woman\u2019s boot"]
  },
  "1f451": {
    name: "crown",
    code: "1f451",
    keywords: ["clothing", "crown", "king", "queen"]
  },
  "1f452": {
    name: "woman\u2019s_hat",
    code: "1f452",
    keywords: ["clothing", "hat", "woman", "woman\u2019s hat"]
  },
  "1f3a9": {
    name: "top_hat",
    code: "1f3a9",
    keywords: ["clothing", "hat", "top", "tophat"]
  },
  "1f393": {
    name: "graduation_cap",
    code: "1f393",
    keywords: ["cap", "celebration", "clothing", "graduation", "hat"]
  },
  "1f9e2": {
    name: "billed_cap",
    code: "1f9e2",
    keywords: ["baseball cap", "billed cap"]
  },
  "1fa96": {
    name: "\u229b_military_helmet",
    code: "1fa96",
    keywords: ["army", "helmet", "military", "soldier", "warrior"]
  },
  "26d1": {
    name: "rescue_worker\u2019s_helmet",
    code: "26d1",
    keywords: [
      "aid",
      "cross",
      "face",
      "hat",
      "helmet",
      "rescue worker\u2019s helmet"
    ]
  },
  "1f4ff": {
    name: "prayer_beads",
    code: "1f4ff",
    keywords: ["beads", "clothing", "necklace", "prayer", "religion"]
  },
  "1f484": {
    name: "lipstick",
    code: "1f484",
    keywords: ["cosmetics", "lipstick", "makeup"]
  },
  "1f48d": { name: "ring", code: "1f48d", keywords: ["diamond", "ring"] },
  "1f48e": {
    name: "gem_stone",
    code: "1f48e",
    keywords: ["diamond", "gem", "gem stone", "jewel"]
  },
  "1f507": {
    name: "muted_speaker",
    code: "1f507",
    keywords: ["mute", "muted speaker", "quiet", "silent", "speaker"]
  },
  "1f508": {
    name: "speaker_low_volume",
    code: "1f508",
    keywords: ["soft", "speaker low volume"]
  },
  "1f509": {
    name: "speaker_medium_volume",
    code: "1f509",
    keywords: ["medium", "speaker medium volume"]
  },
  "1f50a": {
    name: "speaker_high_volume",
    code: "1f50a",
    keywords: ["loud", "speaker high volume"]
  },
  "1f4e2": {
    name: "loudspeaker",
    code: "1f4e2",
    keywords: ["loud", "loudspeaker", "public address"]
  },
  "1f4e3": {
    name: "megaphone",
    code: "1f4e3",
    keywords: ["cheering", "megaphone"]
  },
  "1f4ef": {
    name: "postal_horn",
    code: "1f4ef",
    keywords: ["horn", "post", "postal"]
  },
  "1f514": { name: "bell", code: "1f514", keywords: ["bell"] },
  "1f515": {
    name: "bell_with_slash",
    code: "1f515",
    keywords: [
      "bell",
      "bell with slash",
      "forbidden",
      "mute",
      "quiet",
      "silent"
    ]
  },
  "1f3bc": {
    name: "musical_score",
    code: "1f3bc",
    keywords: ["music", "musical score", "score"]
  },
  "1f3b5": {
    name: "musical_note",
    code: "1f3b5",
    keywords: ["music", "musical note", "note"]
  },
  "1f3b6": {
    name: "musical_notes",
    code: "1f3b6",
    keywords: ["music", "musical notes", "note", "notes"]
  },
  "1f399": {
    name: "studio_microphone",
    code: "1f399",
    keywords: ["mic", "microphone", "music", "studio"]
  },
  "1f39a": {
    name: "level_slider",
    code: "1f39a",
    keywords: ["level", "music", "slider"]
  },
  "1f39b": {
    name: "control_knobs",
    code: "1f39b",
    keywords: ["control", "knobs", "music"]
  },
  "1f3a4": {
    name: "microphone",
    code: "1f3a4",
    keywords: ["karaoke", "mic", "microphone"]
  },
  "1f3a7": {
    name: "headphone",
    code: "1f3a7",
    keywords: ["earbud", "headphone"]
  },
  "1f4fb": { name: "radio", code: "1f4fb", keywords: ["radio", "video"] },
  "1f3b7": {
    name: "saxophone",
    code: "1f3b7",
    keywords: ["instrument", "music", "sax", "saxophone"]
  },
  "1fa97": {
    name: "\u229b_accordion",
    code: "1fa97",
    keywords: ["accordian", "accordion", "concertina", "squeeze box"]
  },
  "1f3b8": {
    name: "guitar",
    code: "1f3b8",
    keywords: ["guitar", "instrument", "music"]
  },
  "1f3b9": {
    name: "musical_keyboard",
    code: "1f3b9",
    keywords: ["instrument", "keyboard", "music", "musical keyboard", "piano"]
  },
  "1f3ba": {
    name: "trumpet",
    code: "1f3ba",
    keywords: ["instrument", "music", "trumpet"]
  },
  "1f3bb": {
    name: "violin",
    code: "1f3bb",
    keywords: ["instrument", "music", "violin"]
  },
  "1fa95": {
    name: "banjo",
    code: "1fa95",
    keywords: ["banjo", "music", "stringed"]
  },
  "1f941": {
    name: "drum",
    code: "1f941",
    keywords: ["drum", "drumsticks", "music"]
  },
  "1fa98": {
    name: "\u229b_long_drum",
    code: "1fa98",
    keywords: ["beat", "conga", "drum", "long drum", "rhythm"]
  },
  "1f4f1": {
    name: "mobile_phone",
    code: "1f4f1",
    keywords: ["cell", "mobile", "phone", "telephone"]
  },
  "1f4f2": {
    name: "mobile_phone_with_arrow",
    code: "1f4f2",
    keywords: [
      "arrow",
      "cell",
      "mobile",
      "mobile phone with arrow",
      "phone",
      "receive"
    ]
  },
  "260e": { name: "telephone", code: "260e", keywords: ["phone", "telephone"] },
  "1f4de": {
    name: "telephone_receiver",
    code: "1f4de",
    keywords: ["phone", "receiver", "telephone"]
  },
  "1f4df": { name: "pager", code: "1f4df", keywords: ["pager"] },
  "1f4e0": {
    name: "fax_machine",
    code: "1f4e0",
    keywords: ["fax", "fax machine"]
  },
  "1f50b": { name: "battery", code: "1f50b", keywords: ["battery"] },
  "1f50c": {
    name: "electric_plug",
    code: "1f50c",
    keywords: ["electric", "electricity", "plug"]
  },
  "1f4bb": {
    name: "laptop",
    code: "1f4bb",
    keywords: ["computer", "laptop", "pc", "personal"]
  },
  "1f5a5": {
    name: "desktop_computer",
    code: "1f5a5",
    keywords: ["computer", "desktop"]
  },
  "1f5a8": {
    name: "printer",
    code: "1f5a8",
    keywords: ["computer", "printer"]
  },
  "2328": {
    name: "keyboard",
    code: "2328",
    keywords: ["computer", "keyboard"]
  },
  "1f5b1": {
    name: "computer_mouse",
    code: "1f5b1",
    keywords: ["computer", "computer mouse"]
  },
  "1f5b2": {
    name: "trackball",
    code: "1f5b2",
    keywords: ["computer", "trackball"]
  },
  "1f4bd": {
    name: "computer_disk",
    code: "1f4bd",
    keywords: ["computer", "disk", "minidisk", "optical"]
  },
  "1f4be": {
    name: "floppy_disk",
    code: "1f4be",
    keywords: ["computer", "disk", "floppy"]
  },
  "1f4bf": {
    name: "optical_disk",
    code: "1f4bf",
    keywords: ["cd", "computer", "disk", "optical"]
  },
  "1f4c0": {
    name: "dvd",
    code: "1f4c0",
    keywords: ["blu-ray", "computer", "disk", "dvd", "optical"]
  },
  "1f9ee": {
    name: "abacus",
    code: "1f9ee",
    keywords: ["abacus", "calculation"]
  },
  "1f3a5": {
    name: "movie_camera",
    code: "1f3a5",
    keywords: ["camera", "cinema", "movie"]
  },
  "1f39e": {
    name: "film_frames",
    code: "1f39e",
    keywords: ["cinema", "film", "frames", "movie"]
  },
  "1f4fd": {
    name: "film_projector",
    code: "1f4fd",
    keywords: ["cinema", "film", "movie", "projector", "video"]
  },
  "1f3ac": {
    name: "clapper_board",
    code: "1f3ac",
    keywords: ["clapper", "clapper board", "movie"]
  },
  "1f4fa": {
    name: "television",
    code: "1f4fa",
    keywords: ["television", "tv", "video"]
  },
  "1f4f7": { name: "camera", code: "1f4f7", keywords: ["camera", "video"] },
  "1f4f8": {
    name: "camera_with_flash",
    code: "1f4f8",
    keywords: ["camera", "camera with flash", "flash", "video"]
  },
  "1f4f9": {
    name: "video_camera",
    code: "1f4f9",
    keywords: ["camera", "video"]
  },
  "1f4fc": {
    name: "videocassette",
    code: "1f4fc",
    keywords: ["tape", "vhs", "video", "videocassette"]
  },
  "1f50d": {
    name: "magnifying_glass_tilted_left",
    code: "1f50d",
    keywords: [
      "glass",
      "magnifying",
      "magnifying glass tilted left",
      "search",
      "tool"
    ]
  },
  "1f50e": {
    name: "magnifying_glass_tilted_right",
    code: "1f50e",
    keywords: [
      "glass",
      "magnifying",
      "magnifying glass tilted right",
      "search",
      "tool"
    ]
  },
  "1f56f": { name: "candle", code: "1f56f", keywords: ["candle", "light"] },
  "1f4a1": {
    name: "light_bulb",
    code: "1f4a1",
    keywords: ["bulb", "comic", "electric", "idea", "light"]
  },
  "1f526": {
    name: "flashlight",
    code: "1f526",
    keywords: ["electric", "flashlight", "light", "tool", "torch"]
  },
  "1f3ee": {
    name: "red_paper_lantern",
    code: "1f3ee",
    keywords: ["bar", "lantern", "light", "red", "red paper lantern"]
  },
  "1fa94": {
    name: "diya_lamp",
    code: "1fa94",
    keywords: ["diya", "lamp", "oil"]
  },
  "1f4d4": {
    name: "notebook_with_decorative_cover",
    code: "1f4d4",
    keywords: [
      "book",
      "cover",
      "decorated",
      "notebook",
      "notebook with decorative cover"
    ]
  },
  "1f4d5": { name: "closed_book", code: "1f4d5", keywords: ["book", "closed"] },
  "1f4d6": { name: "open_book", code: "1f4d6", keywords: ["book", "open"] },
  "1f4d7": { name: "green_book", code: "1f4d7", keywords: ["book", "green"] },
  "1f4d8": { name: "blue_book", code: "1f4d8", keywords: ["blue", "book"] },
  "1f4d9": { name: "orange_book", code: "1f4d9", keywords: ["book", "orange"] },
  "1f4da": { name: "books", code: "1f4da", keywords: ["book", "books"] },
  "1f4d3": { name: "notebook", code: "1f4d3", keywords: ["notebook"] },
  "1f4d2": { name: "ledger", code: "1f4d2", keywords: ["ledger", "notebook"] },
  "1f4c3": {
    name: "page_with_curl",
    code: "1f4c3",
    keywords: ["curl", "document", "page", "page with curl"]
  },
  "1f4dc": { name: "scroll", code: "1f4dc", keywords: ["paper", "scroll"] },
  "1f4c4": {
    name: "page_facing_up",
    code: "1f4c4",
    keywords: ["document", "page", "page facing up"]
  },
  "1f4f0": {
    name: "newspaper",
    code: "1f4f0",
    keywords: ["news", "newspaper", "paper"]
  },
  "1f5de": {
    name: "rolled-up_newspaper",
    code: "1f5de",
    keywords: ["news", "newspaper", "paper", "rolled", "rolled-up newspaper"]
  },
  "1f4d1": {
    name: "bookmark_tabs",
    code: "1f4d1",
    keywords: ["bookmark", "mark", "marker", "tabs"]
  },
  "1f516": { name: "bookmark", code: "1f516", keywords: ["bookmark", "mark"] },
  "1f3f7": { name: "label", code: "1f3f7", keywords: ["label"] },
  "1f4b0": {
    name: "money_bag",
    code: "1f4b0",
    keywords: ["bag", "dollar", "money", "moneybag"]
  },
  "1fa99": {
    name: "\u229b_coin",
    code: "1fa99",
    keywords: ["coin", "gold", "metal", "money", "silver", "treasure"]
  },
  "1f4b4": {
    name: "yen_banknote",
    code: "1f4b4",
    keywords: ["banknote", "bill", "currency", "money", "note", "yen"]
  },
  "1f4b5": {
    name: "dollar_banknote",
    code: "1f4b5",
    keywords: ["banknote", "bill", "currency", "dollar", "money", "note"]
  },
  "1f4b6": {
    name: "euro_banknote",
    code: "1f4b6",
    keywords: ["banknote", "bill", "currency", "euro", "money", "note"]
  },
  "1f4b7": {
    name: "pound_banknote",
    code: "1f4b7",
    keywords: ["banknote", "bill", "currency", "money", "note", "pound"]
  },
  "1f4b8": {
    name: "money_with_wings",
    code: "1f4b8",
    keywords: ["banknote", "bill", "fly", "money", "money with wings", "wings"]
  },
  "1f4b3": {
    name: "credit_card",
    code: "1f4b3",
    keywords: ["card", "credit", "money"]
  },
  "1f9fe": {
    name: "receipt",
    code: "1f9fe",
    keywords: ["accounting", "bookkeeping", "evidence", "proof", "receipt"]
  },
  "1f4b9": {
    name: "chart_increasing_with_yen",
    code: "1f4b9",
    keywords: [
      "chart",
      "chart increasing with yen",
      "graph",
      "growth",
      "money",
      "yen"
    ]
  },
  "2709": {
    name: "envelope",
    code: "2709",
    keywords: ["email", "envelope", "letter"]
  },
  "1f4e7": {
    name: "e-mail",
    code: "1f4e7",
    keywords: ["e-mail", "email", "letter", "mail"]
  },
  "1f4e8": {
    name: "incoming_envelope",
    code: "1f4e8",
    keywords: ["e-mail", "email", "envelope", "incoming", "letter", "receive"]
  },
  "1f4e9": {
    name: "envelope_with_arrow",
    code: "1f4e9",
    keywords: [
      "arrow",
      "e-mail",
      "email",
      "envelope",
      "envelope with arrow",
      "outgoing"
    ]
  },
  "1f4e4": {
    name: "outbox_tray",
    code: "1f4e4",
    keywords: ["box", "letter", "mail", "outbox", "sent", "tray"]
  },
  "1f4e5": {
    name: "inbox_tray",
    code: "1f4e5",
    keywords: ["box", "inbox", "letter", "mail", "receive", "tray"]
  },
  "1f4e6": {
    name: "package",
    code: "1f4e6",
    keywords: ["box", "package", "parcel"]
  },
  "1f4eb": {
    name: "closed_mailbox_with_raised_flag",
    code: "1f4eb",
    keywords: [
      "closed",
      "closed mailbox with raised flag",
      "mail",
      "mailbox",
      "postbox"
    ]
  },
  "1f4ea": {
    name: "closed_mailbox_with_lowered_flag",
    code: "1f4ea",
    keywords: [
      "closed",
      "closed mailbox with lowered flag",
      "lowered",
      "mail",
      "mailbox",
      "postbox"
    ]
  },
  "1f4ec": {
    name: "open_mailbox_with_raised_flag",
    code: "1f4ec",
    keywords: [
      "mail",
      "mailbox",
      "open",
      "open mailbox with raised flag",
      "postbox"
    ]
  },
  "1f4ed": {
    name: "open_mailbox_with_lowered_flag",
    code: "1f4ed",
    keywords: [
      "lowered",
      "mail",
      "mailbox",
      "open",
      "open mailbox with lowered flag",
      "postbox"
    ]
  },
  "1f4ee": {
    name: "postbox",
    code: "1f4ee",
    keywords: ["mail", "mailbox", "postbox"]
  },
  "1f5f3": {
    name: "ballot_box_with_ballot",
    code: "1f5f3",
    keywords: ["ballot", "ballot box with ballot", "box"]
  },
  "270f": { name: "pencil", code: "270f", keywords: ["pencil"] },
  "2712": {
    name: "black_nib",
    code: "2712",
    keywords: ["black nib", "nib", "pen"]
  },
  "1f58b": {
    name: "fountain_pen",
    code: "1f58b",
    keywords: ["fountain", "pen"]
  },
  "1f58a": { name: "pen", code: "1f58a", keywords: ["ballpoint", "pen"] },
  "1f58c": {
    name: "paintbrush",
    code: "1f58c",
    keywords: ["paintbrush", "painting"]
  },
  "1f58d": { name: "crayon", code: "1f58d", keywords: ["crayon"] },
  "1f4dd": { name: "memo", code: "1f4dd", keywords: ["memo", "pencil"] },
  "1f4bc": { name: "briefcase", code: "1f4bc", keywords: ["briefcase"] },
  "1f4c1": { name: "file_folder", code: "1f4c1", keywords: ["file", "folder"] },
  "1f4c2": {
    name: "open_file_folder",
    code: "1f4c2",
    keywords: ["file", "folder", "open"]
  },
  "1f5c2": {
    name: "card_index_dividers",
    code: "1f5c2",
    keywords: ["card", "dividers", "index"]
  },
  "1f4c5": { name: "calendar", code: "1f4c5", keywords: ["calendar", "date"] },
  "1f4c6": {
    name: "tear-off_calendar",
    code: "1f4c6",
    keywords: ["calendar", "tear-off calendar"]
  },
  "1f5d2": {
    name: "spiral_notepad",
    code: "1f5d2",
    keywords: ["note", "pad", "spiral", "spiral notepad"]
  },
  "1f5d3": {
    name: "spiral_calendar",
    code: "1f5d3",
    keywords: ["calendar", "pad", "spiral"]
  },
  "1f4c7": {
    name: "card_index",
    code: "1f4c7",
    keywords: ["card", "index", "rolodex"]
  },
  "1f4c8": {
    name: "chart_increasing",
    code: "1f4c8",
    keywords: [
      "chart",
      "chart increasing",
      "graph",
      "growth",
      "trend",
      "upward"
    ]
  },
  "1f4c9": {
    name: "chart_decreasing",
    code: "1f4c9",
    keywords: ["chart", "chart decreasing", "down", "graph", "trend"]
  },
  "1f4ca": {
    name: "bar_chart",
    code: "1f4ca",
    keywords: ["bar", "chart", "graph"]
  },
  "1f4cb": { name: "clipboard", code: "1f4cb", keywords: ["clipboard"] },
  "1f4cc": { name: "pushpin", code: "1f4cc", keywords: ["pin", "pushpin"] },
  "1f4cd": {
    name: "round_pushpin",
    code: "1f4cd",
    keywords: ["pin", "pushpin", "round pushpin"]
  },
  "1f4ce": { name: "paperclip", code: "1f4ce", keywords: ["paperclip"] },
  "1f587": {
    name: "linked_paperclips",
    code: "1f587",
    keywords: ["link", "linked paperclips", "paperclip"]
  },
  "1f4cf": {
    name: "straight_ruler",
    code: "1f4cf",
    keywords: ["ruler", "straight edge", "straight ruler"]
  },
  "1f4d0": {
    name: "triangular_ruler",
    code: "1f4d0",
    keywords: ["ruler", "set", "triangle", "triangular ruler"]
  },
  "2702": {
    name: "scissors",
    code: "2702",
    keywords: ["cutting", "scissors", "tool"]
  },
  "1f5c3": {
    name: "card_file_box",
    code: "1f5c3",
    keywords: ["box", "card", "file"]
  },
  "1f5c4": {
    name: "file_cabinet",
    code: "1f5c4",
    keywords: ["cabinet", "file", "filing"]
  },
  "1f5d1": { name: "wastebasket", code: "1f5d1", keywords: ["wastebasket"] },
  "1f512": { name: "locked", code: "1f512", keywords: ["closed", "locked"] },
  "1f513": {
    name: "unlocked",
    code: "1f513",
    keywords: ["lock", "open", "unlock", "unlocked"]
  },
  "1f50f": {
    name: "locked_with_pen",
    code: "1f50f",
    keywords: ["ink", "lock", "locked with pen", "nib", "pen", "privacy"]
  },
  "1f510": {
    name: "locked_with_key",
    code: "1f510",
    keywords: ["closed", "key", "lock", "locked with key", "secure"]
  },
  "1f511": {
    name: "key",
    code: "1f511",
    keywords: ["key", "lock", "password"]
  },
  "1f5dd": {
    name: "old_key",
    code: "1f5dd",
    keywords: ["clue", "key", "lock", "old"]
  },
  "1f528": { name: "hammer", code: "1f528", keywords: ["hammer", "tool"] },
  "1fa93": {
    name: "axe",
    code: "1fa93",
    keywords: ["axe", "chop", "hatchet", "split", "wood"]
  },
  "26cf": { name: "pick", code: "26cf", keywords: ["mining", "pick", "tool"] },
  "2692": {
    name: "hammer_and_pick",
    code: "2692",
    keywords: ["hammer", "hammer and pick", "pick", "tool"]
  },
  "1f6e0": {
    name: "hammer_and_wrench",
    code: "1f6e0",
    keywords: ["hammer", "hammer and wrench", "spanner", "tool", "wrench"]
  },
  "1f5e1": {
    name: "dagger",
    code: "1f5e1",
    keywords: ["dagger", "knife", "weapon"]
  },
  "2694": {
    name: "crossed_swords",
    code: "2694",
    keywords: ["crossed", "swords", "weapon"]
  },
  "1f52b": {
    name: "pistol",
    code: "1f52b",
    keywords: ["gun", "handgun", "pistol", "revolver", "tool", "weapon"]
  },
  "1fa83": {
    name: "\u229b_boomerang",
    code: "1fa83",
    keywords: ["australia", "boomerang", "rebound", "repercussion"]
  },
  "1f3f9": {
    name: "bow_and_arrow",
    code: "1f3f9",
    keywords: [
      "archer",
      "arrow",
      "bow",
      "bow and arrow",
      "Sagittarius",
      "zodiac"
    ]
  },
  "1f6e1": { name: "shield", code: "1f6e1", keywords: ["shield", "weapon"] },
  "1fa9a": {
    name: "\u229b_carpentry_saw",
    code: "1fa9a",
    keywords: ["carpenter", "carpentry saw", "lumber", "saw", "tool"]
  },
  "1f527": {
    name: "wrench",
    code: "1f527",
    keywords: ["spanner", "tool", "wrench"]
  },
  "1fa9b": {
    name: "\u229b_screwdriver",
    code: "1fa9b",
    keywords: ["screw", "screwdriver", "tool"]
  },
  "1f529": {
    name: "nut_and_bolt",
    code: "1f529",
    keywords: ["bolt", "nut", "nut and bolt", "tool"]
  },
  "2699": {
    name: "gear",
    code: "2699",
    keywords: ["cog", "cogwheel", "gear", "tool"]
  },
  "1f5dc": {
    name: "clamp",
    code: "1f5dc",
    keywords: ["clamp", "compress", "tool", "vice"]
  },
  "2696": {
    name: "balance_scale",
    code: "2696",
    keywords: ["balance", "justice", "Libra", "scale", "zodiac"]
  },
  "1f9af": {
    name: "white_cane",
    code: "1f9af",
    keywords: ["accessibility", "blind", "white cane"]
  },
  "1f517": { name: "link", code: "1f517", keywords: ["link"] },
  "26d3": { name: "chains", code: "26d3", keywords: ["chain", "chains"] },
  "1fa9d": {
    name: "\u229b_hook",
    code: "1fa9d",
    keywords: ["catch", "crook", "curve", "ensnare", "hook", "selling point"]
  },
  "1f9f0": {
    name: "toolbox",
    code: "1f9f0",
    keywords: ["chest", "mechanic", "tool", "toolbox"]
  },
  "1f9f2": {
    name: "magnet",
    code: "1f9f2",
    keywords: ["attraction", "horseshoe", "magnet", "magnetic"]
  },
  "1fa9c": {
    name: "\u229b_ladder",
    code: "1fa9c",
    keywords: ["climb", "ladder", "rung", "step"]
  },
  "2697": {
    name: "alembic",
    code: "2697",
    keywords: ["alembic", "chemistry", "tool"]
  },
  "1f9ea": {
    name: "test_tube",
    code: "1f9ea",
    keywords: [
      "chemist",
      "chemistry",
      "experiment",
      "lab",
      "science",
      "test tube"
    ]
  },
  "1f9eb": {
    name: "petri_dish",
    code: "1f9eb",
    keywords: [
      "bacteria",
      "biologist",
      "biology",
      "culture",
      "lab",
      "petri dish"
    ]
  },
  "1f9ec": {
    name: "dna",
    code: "1f9ec",
    keywords: ["biologist", "dna", "evolution", "gene", "genetics", "life"]
  },
  "1f52c": {
    name: "microscope",
    code: "1f52c",
    keywords: ["microscope", "science", "tool"]
  },
  "1f52d": {
    name: "telescope",
    code: "1f52d",
    keywords: ["science", "telescope", "tool"]
  },
  "1f4e1": {
    name: "satellite_antenna",
    code: "1f4e1",
    keywords: ["antenna", "dish", "satellite"]
  },
  "1f489": {
    name: "syringe",
    code: "1f489",
    keywords: ["medicine", "needle", "shot", "sick", "syringe"]
  },
  "1fa78": {
    name: "drop_of_blood",
    code: "1fa78",
    keywords: [
      "bleed",
      "blood donation",
      "drop of blood",
      "injury",
      "medicine",
      "menstruation"
    ]
  },
  "1f48a": {
    name: "pill",
    code: "1f48a",
    keywords: ["doctor", "medicine", "pill", "sick"]
  },
  "1fa79": {
    name: "adhesive_bandage",
    code: "1fa79",
    keywords: ["adhesive bandage", "bandage"]
  },
  "1fa7a": {
    name: "stethoscope",
    code: "1fa7a",
    keywords: ["doctor", "heart", "medicine", "stethoscope"]
  },
  "1f6aa": { name: "door", code: "1f6aa", keywords: ["door"] },
  "1f6d7": {
    name: "\u229b_elevator",
    code: "1f6d7",
    keywords: ["accessibility", "elevator", "hoist", "lift"]
  },
  "1fa9e": {
    name: "\u229b_mirror",
    code: "1fa9e",
    keywords: ["mirror", "reflection", "reflector", "speculum"]
  },
  "1fa9f": {
    name: "\u229b_window",
    code: "1fa9f",
    keywords: ["frame", "fresh air", "opening", "transparent", "view", "window"]
  },
  "1f6cf": { name: "bed", code: "1f6cf", keywords: ["bed", "hotel", "sleep"] },
  "1f6cb": {
    name: "couch_and_lamp",
    code: "1f6cb",
    keywords: ["couch", "couch and lamp", "hotel", "lamp"]
  },
  "1fa91": { name: "chair", code: "1fa91", keywords: ["chair", "seat", "sit"] },
  "1f6bd": { name: "toilet", code: "1f6bd", keywords: ["toilet"] },
  "1faa0": {
    name: "\u229b_plunger",
    code: "1faa0",
    keywords: ["force cup", "plumber", "plunger", "suction", "toilet"]
  },
  "1f6bf": { name: "shower", code: "1f6bf", keywords: ["shower", "water"] },
  "1f6c1": { name: "bathtub", code: "1f6c1", keywords: ["bath", "bathtub"] },
  "1faa4": {
    name: "\u229b_mouse_trap",
    code: "1faa4",
    keywords: ["bait", "mouse trap", "mousetrap", "snare", "trap"]
  },
  "1fa92": {
    name: "razor",
    code: "1fa92",
    keywords: ["razor", "sharp", "shave"]
  },
  "1f9f4": {
    name: "lotion_bottle",
    code: "1f9f4",
    keywords: ["lotion", "lotion bottle", "moisturizer", "shampoo", "sunscreen"]
  },
  "1f9f7": {
    name: "safety_pin",
    code: "1f9f7",
    keywords: ["diaper", "punk rock", "safety pin"]
  },
  "1f9f9": {
    name: "broom",
    code: "1f9f9",
    keywords: ["broom", "cleaning", "sweeping", "witch"]
  },
  "1f9fa": {
    name: "basket",
    code: "1f9fa",
    keywords: ["basket", "farming", "laundry", "picnic"]
  },
  "1f9fb": {
    name: "roll_of_paper",
    code: "1f9fb",
    keywords: ["paper towels", "roll of paper", "toilet paper"]
  },
  "1faa3": {
    name: "\u229b_bucket",
    code: "1faa3",
    keywords: ["bucket", "cask", "pail", "vat"]
  },
  "1f9fc": {
    name: "soap",
    code: "1f9fc",
    keywords: ["bar", "bathing", "cleaning", "lather", "soap", "soapdish"]
  },
  "1faa5": {
    name: "\u229b_toothbrush",
    code: "1faa5",
    keywords: [
      "bathroom",
      "brush",
      "clean",
      "dental",
      "hygiene",
      "teeth",
      "toothbrush"
    ]
  },
  "1f9fd": {
    name: "sponge",
    code: "1f9fd",
    keywords: ["absorbing", "cleaning", "porous", "sponge"]
  },
  "1f9ef": {
    name: "fire_extinguisher",
    code: "1f9ef",
    keywords: ["extinguish", "fire", "fire extinguisher", "quench"]
  },
  "1f6d2": {
    name: "shopping_cart",
    code: "1f6d2",
    keywords: ["cart", "shopping", "trolley"]
  },
  "1f6ac": {
    name: "cigarette",
    code: "1f6ac",
    keywords: ["cigarette", "smoking"]
  },
  "26b0": { name: "coffin", code: "26b0", keywords: ["coffin", "death"] },
  "1faa6": {
    name: "\u229b_headstone",
    code: "1faa6",
    keywords: ["cemetery", "grave", "graveyard", "headstone", "tombstone"]
  },
  "26b1": {
    name: "funeral_urn",
    code: "26b1",
    keywords: ["ashes", "death", "funeral", "urn"]
  },
  "1f5ff": {
    name: "moai",
    code: "1f5ff",
    keywords: ["face", "moai", "moyai", "statue"]
  },
  "1faa7": {
    name: "\u229b_placard",
    code: "1faa7",
    keywords: ["demonstration", "picket", "placard", "protest", "sign"]
  },
  "1f3e7": {
    name: "ATM_sign",
    code: "1f3e7",
    keywords: ["atm", "ATM sign", "automated", "bank", "teller"]
  },
  "1f6ae": {
    name: "litter_in_bin_sign",
    code: "1f6ae",
    keywords: ["litter", "litter bin", "litter in bin sign"]
  },
  "1f6b0": {
    name: "potable_water",
    code: "1f6b0",
    keywords: ["drinking", "potable", "water"]
  },
  "267f": {
    name: "wheelchair_symbol",
    code: "267f",
    keywords: ["access", "wheelchair symbol"]
  },
  "1f6b9": {
    name: "men\u2019s_room",
    code: "1f6b9",
    keywords: ["lavatory", "man", "men\u2019s room", "restroom", "wc"]
  },
  "1f6ba": {
    name: "women\u2019s_room",
    code: "1f6ba",
    keywords: ["lavatory", "restroom", "wc", "woman", "women\u2019s room"]
  },
  "1f6bb": {
    name: "restroom",
    code: "1f6bb",
    keywords: ["lavatory", "restroom", "WC"]
  },
  "1f6bc": {
    name: "baby_symbol",
    code: "1f6bc",
    keywords: ["baby", "baby symbol", "changing"]
  },
  "1f6be": {
    name: "water_closet",
    code: "1f6be",
    keywords: ["closet", "lavatory", "restroom", "water", "wc"]
  },
  "1f6c2": {
    name: "passport_control",
    code: "1f6c2",
    keywords: ["control", "passport"]
  },
  "1f6c3": { name: "customs", code: "1f6c3", keywords: ["customs"] },
  "1f6c4": {
    name: "baggage_claim",
    code: "1f6c4",
    keywords: ["baggage", "claim"]
  },
  "1f6c5": {
    name: "left_luggage",
    code: "1f6c5",
    keywords: ["baggage", "left luggage", "locker", "luggage"]
  },
  "26a0": { name: "warning", code: "26a0", keywords: ["warning"] },
  "1f6b8": {
    name: "children_crossing",
    code: "1f6b8",
    keywords: [
      "child",
      "children crossing",
      "crossing",
      "pedestrian",
      "traffic"
    ]
  },
  "26d4": {
    name: "no_entry",
    code: "26d4",
    keywords: ["entry", "forbidden", "no", "not", "prohibited", "traffic"]
  },
  "1f6ab": {
    name: "prohibited",
    code: "1f6ab",
    keywords: ["entry", "forbidden", "no", "not", "prohibited"]
  },
  "1f6b3": {
    name: "no_bicycles",
    code: "1f6b3",
    keywords: [
      "bicycle",
      "bike",
      "forbidden",
      "no",
      "no bicycles",
      "prohibited"
    ]
  },
  "1f6ad": {
    name: "no_smoking",
    code: "1f6ad",
    keywords: ["forbidden", "no", "not", "prohibited", "smoking"]
  },
  "1f6af": {
    name: "no_littering",
    code: "1f6af",
    keywords: ["forbidden", "litter", "no", "no littering", "not", "prohibited"]
  },
  "1f6b1": {
    name: "non-potable_water",
    code: "1f6b1",
    keywords: ["non-drinking", "non-potable", "water"]
  },
  "1f6b7": {
    name: "no_pedestrians",
    code: "1f6b7",
    keywords: [
      "forbidden",
      "no",
      "no pedestrians",
      "not",
      "pedestrian",
      "prohibited"
    ]
  },
  "1f4f5": {
    name: "no_mobile_phones",
    code: "1f4f5",
    keywords: ["cell", "forbidden", "mobile", "no", "no mobile phones", "phone"]
  },
  "1f51e": {
    name: "no_one_under_eighteen",
    code: "1f51e",
    keywords: [
      "18",
      "age restriction",
      "eighteen",
      "no one under eighteen",
      "prohibited",
      "underage"
    ]
  },
  "2622": {
    name: "radioactive",
    code: "2622",
    keywords: ["radioactive", "sign"]
  },
  "2623": { name: "biohazard", code: "2623", keywords: ["biohazard", "sign"] },
  "2b06": {
    name: "up_arrow",
    code: "2b06",
    keywords: ["arrow", "cardinal", "direction", "north", "up arrow"]
  },
  "2197": {
    name: "up-right_arrow",
    code: "2197",
    keywords: [
      "arrow",
      "direction",
      "intercardinal",
      "northeast",
      "up-right arrow"
    ]
  },
  "27a1": {
    name: "right_arrow",
    code: "27a1",
    keywords: ["arrow", "cardinal", "direction", "east", "right arrow"]
  },
  "2198": {
    name: "down-right_arrow",
    code: "2198",
    keywords: [
      "arrow",
      "direction",
      "down-right arrow",
      "intercardinal",
      "southeast"
    ]
  },
  "2b07": {
    name: "down_arrow",
    code: "2b07",
    keywords: ["arrow", "cardinal", "direction", "down", "south"]
  },
  "2199": {
    name: "down-left_arrow",
    code: "2199",
    keywords: [
      "arrow",
      "direction",
      "down-left arrow",
      "intercardinal",
      "southwest"
    ]
  },
  "2b05": {
    name: "left_arrow",
    code: "2b05",
    keywords: ["arrow", "cardinal", "direction", "left arrow", "west"]
  },
  "2196": {
    name: "up-left_arrow",
    code: "2196",
    keywords: [
      "arrow",
      "direction",
      "intercardinal",
      "northwest",
      "up-left arrow"
    ]
  },
  "2195": {
    name: "up-down_arrow",
    code: "2195",
    keywords: ["arrow", "up-down arrow"]
  },
  "2194": {
    name: "left-right_arrow",
    code: "2194",
    keywords: ["arrow", "left-right arrow"]
  },
  "21a9": {
    name: "right_arrow_curving_left",
    code: "21a9",
    keywords: ["arrow", "right arrow curving left"]
  },
  "21aa": {
    name: "left_arrow_curving_right",
    code: "21aa",
    keywords: ["arrow", "left arrow curving right"]
  },
  "2934": {
    name: "right_arrow_curving_up",
    code: "2934",
    keywords: ["arrow", "right arrow curving up"]
  },
  "2935": {
    name: "right_arrow_curving_down",
    code: "2935",
    keywords: ["arrow", "down", "right arrow curving down"]
  },
  "1f503": {
    name: "clockwise_vertical_arrows",
    code: "1f503",
    keywords: ["arrow", "clockwise", "clockwise vertical arrows", "reload"]
  },
  "1f504": {
    name: "counterclockwise_arrows_button",
    code: "1f504",
    keywords: [
      "anticlockwise",
      "arrow",
      "counterclockwise",
      "counterclockwise arrows button",
      "withershins"
    ]
  },
  "1f519": {
    name: "BACK_arrow",
    code: "1f519",
    keywords: ["arrow", "back", "BACK arrow"]
  },
  "1f51a": {
    name: "END_arrow",
    code: "1f51a",
    keywords: ["arrow", "end", "END arrow"]
  },
  "1f51b": {
    name: "ON!_arrow",
    code: "1f51b",
    keywords: ["arrow", "mark", "on", "ON! arrow"]
  },
  "1f51c": {
    name: "SOON_arrow",
    code: "1f51c",
    keywords: ["arrow", "soon", "SOON arrow"]
  },
  "1f51d": {
    name: "TOP_arrow",
    code: "1f51d",
    keywords: ["arrow", "top", "TOP arrow", "up"]
  },
  "1f6d0": {
    name: "place_of_worship",
    code: "1f6d0",
    keywords: ["place of worship", "religion", "worship"]
  },
  "269b": {
    name: "atom_symbol",
    code: "269b",
    keywords: ["atheist", "atom", "atom symbol"]
  },
  "1f549": { name: "om", code: "1f549", keywords: ["Hindu", "om", "religion"] },
  "2721": {
    name: "star_of_David",
    code: "2721",
    keywords: ["David", "Jew", "Jewish", "religion", "star", "star of David"]
  },
  "2638": {
    name: "wheel_of_dharma",
    code: "2638",
    keywords: ["Buddhist", "dharma", "religion", "wheel", "wheel of dharma"]
  },
  "262f": {
    name: "yin_yang",
    code: "262f",
    keywords: ["religion", "tao", "taoist", "yang", "yin"]
  },
  "271d": {
    name: "latin_cross",
    code: "271d",
    keywords: ["Christian", "cross", "latin cross", "religion"]
  },
  "2626": {
    name: "orthodox_cross",
    code: "2626",
    keywords: ["Christian", "cross", "orthodox cross", "religion"]
  },
  "262a": {
    name: "star_and_crescent",
    code: "262a",
    keywords: ["islam", "Muslim", "religion", "star and crescent"]
  },
  "262e": {
    name: "peace_symbol",
    code: "262e",
    keywords: ["peace", "peace symbol"]
  },
  "1f54e": {
    name: "menorah",
    code: "1f54e",
    keywords: ["candelabrum", "candlestick", "menorah", "religion"]
  },
  "1f52f": {
    name: "dotted_six-pointed_star",
    code: "1f52f",
    keywords: ["dotted six-pointed star", "fortune", "star"]
  },
  "2648": { name: "Aries", code: "2648", keywords: ["Aries", "ram", "zodiac"] },
  "2649": {
    name: "Taurus",
    code: "2649",
    keywords: ["bull", "ox", "Taurus", "zodiac"]
  },
  "264a": {
    name: "Gemini",
    code: "264a",
    keywords: ["Gemini", "twins", "zodiac"]
  },
  "264b": {
    name: "Cancer",
    code: "264b",
    keywords: ["Cancer", "crab", "zodiac"]
  },
  "264c": { name: "Leo", code: "264c", keywords: ["Leo", "lion", "zodiac"] },
  "264d": { name: "Virgo", code: "264d", keywords: ["Virgo", "zodiac"] },
  "264e": {
    name: "Libra",
    code: "264e",
    keywords: ["balance", "justice", "Libra", "scales", "zodiac"]
  },
  "264f": {
    name: "Scorpio",
    code: "264f",
    keywords: ["Scorpio", "scorpion", "scorpius", "zodiac"]
  },
  "2650": {
    name: "Sagittarius",
    code: "2650",
    keywords: ["archer", "Sagittarius", "zodiac"]
  },
  "2651": {
    name: "Capricorn",
    code: "2651",
    keywords: ["Capricorn", "goat", "zodiac"]
  },
  "2652": {
    name: "Aquarius",
    code: "2652",
    keywords: ["Aquarius", "bearer", "water", "zodiac"]
  },
  "2653": {
    name: "Pisces",
    code: "2653",
    keywords: ["fish", "Pisces", "zodiac"]
  },
  "26ce": {
    name: "Ophiuchus",
    code: "26ce",
    keywords: ["bearer", "Ophiuchus", "serpent", "snake", "zodiac"]
  },
  "1f500": {
    name: "shuffle_tracks_button",
    code: "1f500",
    keywords: ["arrow", "crossed", "shuffle tracks button"]
  },
  "1f501": {
    name: "repeat_button",
    code: "1f501",
    keywords: ["arrow", "clockwise", "repeat", "repeat button"]
  },
  "1f502": {
    name: "repeat_single_button",
    code: "1f502",
    keywords: ["arrow", "clockwise", "once", "repeat single button"]
  },
  "25b6": {
    name: "play_button",
    code: "25b6",
    keywords: ["arrow", "play", "play button", "right", "triangle"]
  },
  "23e9": {
    name: "fast-forward_button",
    code: "23e9",
    keywords: ["arrow", "double", "fast", "fast-forward button", "forward"]
  },
  "23ed": {
    name: "next_track_button",
    code: "23ed",
    keywords: [
      "arrow",
      "next scene",
      "next track",
      "next track button",
      "triangle"
    ]
  },
  "23ef": {
    name: "play_or_pause_button",
    code: "23ef",
    keywords: [
      "arrow",
      "pause",
      "play",
      "play or pause button",
      "right",
      "triangle"
    ]
  },
  "25c0": {
    name: "reverse_button",
    code: "25c0",
    keywords: ["arrow", "left", "reverse", "reverse button", "triangle"]
  },
  "23ea": {
    name: "fast_reverse_button",
    code: "23ea",
    keywords: ["arrow", "double", "fast reverse button", "rewind"]
  },
  "23ee": {
    name: "last_track_button",
    code: "23ee",
    keywords: [
      "arrow",
      "last track button",
      "previous scene",
      "previous track",
      "triangle"
    ]
  },
  "1f53c": {
    name: "upwards_button",
    code: "1f53c",
    keywords: ["arrow", "button", "red", "upwards button"]
  },
  "23eb": {
    name: "fast_up_button",
    code: "23eb",
    keywords: ["arrow", "double", "fast up button"]
  },
  "1f53d": {
    name: "downwards_button",
    code: "1f53d",
    keywords: ["arrow", "button", "down", "downwards button", "red"]
  },
  "23ec": {
    name: "fast_down_button",
    code: "23ec",
    keywords: ["arrow", "double", "down", "fast down button"]
  },
  "23f8": {
    name: "pause_button",
    code: "23f8",
    keywords: ["bar", "double", "pause", "pause button", "vertical"]
  },
  "23f9": {
    name: "stop_button",
    code: "23f9",
    keywords: ["square", "stop", "stop button"]
  },
  "23fa": {
    name: "record_button",
    code: "23fa",
    keywords: ["circle", "record", "record button"]
  },
  "23cf": {
    name: "eject_button",
    code: "23cf",
    keywords: ["eject", "eject button"]
  },
  "1f3a6": {
    name: "cinema",
    code: "1f3a6",
    keywords: ["camera", "cinema", "film", "movie"]
  },
  "1f505": {
    name: "dim_button",
    code: "1f505",
    keywords: ["brightness", "dim", "dim button", "low"]
  },
  "1f506": {
    name: "bright_button",
    code: "1f506",
    keywords: ["bright", "bright button", "brightness"]
  },
  "1f4f6": {
    name: "antenna_bars",
    code: "1f4f6",
    keywords: ["antenna", "antenna bars", "bar", "cell", "mobile", "phone"]
  },
  "1f4f3": {
    name: "vibration_mode",
    code: "1f4f3",
    keywords: ["cell", "mobile", "mode", "phone", "telephone", "vibration"]
  },
  "1f4f4": {
    name: "mobile_phone_off",
    code: "1f4f4",
    keywords: ["cell", "mobile", "off", "phone", "telephone"]
  },
  "2640": {
    name: "female_sign",
    code: "2640",
    keywords: ["female sign", "woman"]
  },
  "2642": { name: "male_sign", code: "2642", keywords: ["male sign", "man"] },
  "26a7": {
    name: "\u229b_transgender_symbol",
    code: "26a7",
    keywords: ["transgender", "transgender symbol"]
  },
  "2716": {
    name: "multiply",
    code: "2716",
    keywords: ["\u00d7", "cancel", "multiplication", "multiply", "sign", "x"]
  },
  "2795": {
    name: "plus",
    code: "2795",
    keywords: ["+", "math", "plus", "sign"]
  },
  "2796": {
    name: "minus",
    code: "2796",
    keywords: ["-", "\u2212", "math", "minus", "sign"]
  },
  "2797": {
    name: "divide",
    code: "2797",
    keywords: ["\u00f7", "division", "math", "sign"]
  },
  "267e": {
    name: "infinity",
    code: "267e",
    keywords: ["forever", "infinity", "unbounded", "universal"]
  },
  "203c": {
    name: "double_exclamation_mark",
    code: "203c",
    keywords: [
      "!",
      "!!",
      "bangbang",
      "double exclamation mark",
      "exclamation",
      "mark"
    ]
  },
  "2049": {
    name: "exclamation_question_mark",
    code: "2049",
    keywords: [
      "!",
      "!?",
      "?",
      "exclamation",
      "interrobang",
      "mark",
      "punctuation",
      "question"
    ]
  },
  "2753": {
    name: "question_mark",
    code: "2753",
    keywords: ["?", "mark", "punctuation", "question"]
  },
  "2754": {
    name: "white_question_mark",
    code: "2754",
    keywords: [
      "?",
      "mark",
      "outlined",
      "punctuation",
      "question",
      "white question mark"
    ]
  },
  "2755": {
    name: "white_exclamation_mark",
    code: "2755",
    keywords: [
      "!",
      "exclamation",
      "mark",
      "outlined",
      "punctuation",
      "white exclamation mark"
    ]
  },
  "2757": {
    name: "exclamation_mark",
    code: "2757",
    keywords: ["!", "exclamation", "mark", "punctuation"]
  },
  "3030": {
    name: "wavy_dash",
    code: "3030",
    keywords: ["dash", "punctuation", "wavy"]
  },
  "1f4b1": {
    name: "currency_exchange",
    code: "1f4b1",
    keywords: ["bank", "currency", "exchange", "money"]
  },
  "1f4b2": {
    name: "heavy_dollar_sign",
    code: "1f4b2",
    keywords: ["currency", "dollar", "heavy dollar sign", "money"]
  },
  "2695": {
    name: "medical_symbol",
    code: "2695",
    keywords: ["aesculapius", "medical symbol", "medicine", "staff"]
  },
  "267b": {
    name: "recycling_symbol",
    code: "267b",
    keywords: ["recycle", "recycling symbol"]
  },
  "269c": { name: "fleur-de-lis", code: "269c", keywords: ["fleur-de-lis"] },
  "1f531": {
    name: "trident_emblem",
    code: "1f531",
    keywords: ["anchor", "emblem", "ship", "tool", "trident"]
  },
  "1f4db": { name: "name_badge", code: "1f4db", keywords: ["badge", "name"] },
  "1f530": {
    name: "Japanese_symbol_for_beginner",
    code: "1f530",
    keywords: [
      "beginner",
      "chevron",
      "Japanese",
      "Japanese symbol for beginner",
      "leaf"
    ]
  },
  "2b55": {
    name: "hollow_red_circle",
    code: "2b55",
    keywords: ["circle", "hollow red circle", "large", "o", "red"]
  },
  "2705": {
    name: "check_mark_button",
    code: "2705",
    keywords: ["\u2713", "button", "check", "mark"]
  },
  "2611": {
    name: "check_box_with_check",
    code: "2611",
    keywords: ["\u2713", "box", "check", "check box with check"]
  },
  "2714": {
    name: "check_mark",
    code: "2714",
    keywords: ["\u2713", "check", "mark"]
  },
  "274c": {
    name: "cross_mark",
    code: "274c",
    keywords: [
      "\u00d7",
      "cancel",
      "cross",
      "mark",
      "multiplication",
      "multiply",
      "x"
    ]
  },
  "274e": {
    name: "cross_mark_button",
    code: "274e",
    keywords: ["\u00d7", "cross mark button", "mark", "square", "x"]
  },
  "27b0": {
    name: "curly_loop",
    code: "27b0",
    keywords: ["curl", "curly loop", "loop"]
  },
  "27bf": {
    name: "double_curly_loop",
    code: "27bf",
    keywords: ["curl", "double", "double curly loop", "loop"]
  },
  "303d": {
    name: "part_alternation_mark",
    code: "303d",
    keywords: ["mark", "part", "part alternation mark"]
  },
  "2733": {
    name: "eight-spoked_asterisk",
    code: "2733",
    keywords: ["*", "asterisk", "eight-spoked asterisk"]
  },
  "2734": {
    name: "eight-pointed_star",
    code: "2734",
    keywords: ["*", "eight-pointed star", "star"]
  },
  "2747": { name: "sparkle", code: "2747", keywords: ["*", "sparkle"] },
  "00a9": { name: "copyright", code: "00a9", keywords: ["c", "copyright"] },
  "00ae": { name: "registered", code: "00ae", keywords: ["r", "registered"] },
  "2122": {
    name: "trade_mark",
    code: "2122",
    keywords: ["mark", "tm", "trade mark", "trademark"]
  },
  "0023_fe0f_20e3": {
    name: "keycap:_#",
    code: "0023_fe0f_20e3",
    keywords: ["keycap"]
  },
  "002a_fe0f_20e3": {
    name: "keycap:_*",
    code: "002a_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0030_fe0f_20e3": {
    name: "keycap:_0",
    code: "0030_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0031_fe0f_20e3": {
    name: "keycap:_1",
    code: "0031_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0032_fe0f_20e3": {
    name: "keycap:_2",
    code: "0032_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0033_fe0f_20e3": {
    name: "keycap:_3",
    code: "0033_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0034_fe0f_20e3": {
    name: "keycap:_4",
    code: "0034_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0035_fe0f_20e3": {
    name: "keycap:_5",
    code: "0035_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0036_fe0f_20e3": {
    name: "keycap:_6",
    code: "0036_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0037_fe0f_20e3": {
    name: "keycap:_7",
    code: "0037_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0038_fe0f_20e3": {
    name: "keycap:_8",
    code: "0038_fe0f_20e3",
    keywords: ["keycap"]
  },
  "0039_fe0f_20e3": {
    name: "keycap:_9",
    code: "0039_fe0f_20e3",
    keywords: ["keycap"]
  },
  "1f51f": { name: "keycap:_10", code: "1f51f", keywords: ["keycap"] },
  "1f520": {
    name: "input_latin_uppercase",
    code: "1f520",
    keywords: ["ABCD", "input", "latin", "letters", "uppercase"]
  },
  "1f521": {
    name: "input_latin_lowercase",
    code: "1f521",
    keywords: ["abcd", "input", "latin", "letters", "lowercase"]
  },
  "1f522": {
    name: "input_numbers",
    code: "1f522",
    keywords: ["1234", "input", "numbers"]
  },
  "1f523": {
    name: "input_symbols",
    code: "1f523",
    keywords: ["\u3012\u266a&%", "input", "input symbols"]
  },
  "1f524": {
    name: "input_latin_letters",
    code: "1f524",
    keywords: ["abc", "alphabet", "input", "latin", "letters"]
  },
  "1f170": {
    name: "A_button_(blood_type)",
    code: "1f170",
    keywords: ["a", "A button (blood type)", "blood type"]
  },
  "1f18e": {
    name: "AB_button_(blood_type)",
    code: "1f18e",
    keywords: ["ab", "AB button (blood type)", "blood type"]
  },
  "1f171": {
    name: "B_button_(blood_type)",
    code: "1f171",
    keywords: ["b", "B button (blood type)", "blood type"]
  },
  "1f191": { name: "CL_button", code: "1f191", keywords: ["cl", "CL button"] },
  "1f192": {
    name: "COOL_button",
    code: "1f192",
    keywords: ["cool", "COOL button"]
  },
  "1f193": {
    name: "FREE_button",
    code: "1f193",
    keywords: ["free", "FREE button"]
  },
  "2139": { name: "information", code: "2139", keywords: ["i", "information"] },
  "1f194": {
    name: "ID_button",
    code: "1f194",
    keywords: ["id", "ID button", "identity"]
  },
  "24c2": {
    name: "circled_M",
    code: "24c2",
    keywords: ["circle", "circled M", "m"]
  },
  "1f195": {
    name: "NEW_button",
    code: "1f195",
    keywords: ["new", "NEW button"]
  },
  "1f196": { name: "NG_button", code: "1f196", keywords: ["ng", "NG button"] },
  "1f17e": {
    name: "O_button_(blood_type)",
    code: "1f17e",
    keywords: ["blood type", "o", "O button (blood type)"]
  },
  "1f197": { name: "OK_button", code: "1f197", keywords: ["OK", "OK button"] },
  "1f17f": {
    name: "P_button",
    code: "1f17f",
    keywords: ["P button", "parking"]
  },
  "1f198": {
    name: "SOS_button",
    code: "1f198",
    keywords: ["help", "sos", "SOS button"]
  },
  "1f199": {
    name: "UP!_button",
    code: "1f199",
    keywords: ["mark", "up", "UP! button"]
  },
  "1f19a": {
    name: "VS_button",
    code: "1f19a",
    keywords: ["versus", "vs", "VS button"]
  },
  "1f201": {
    name: "Japanese_\u201chere\u201d_button",
    code: "1f201",
    keywords: [
      "\u201chere\u201d",
      "Japanese",
      "Japanese \u201chere\u201d button",
      "katakana",
      "\u30b3\u30b3"
    ]
  },
  "1f202": {
    name: "Japanese_\u201cservice_charge\u201d_button",
    code: "1f202",
    keywords: [
      "\u201cservice charge\u201d",
      "Japanese",
      "Japanese \u201cservice charge\u201d button",
      "katakana",
      "\u30b5"
    ]
  },
  "1f237": {
    name: "Japanese_\u201cmonthly_amount\u201d_button",
    code: "1f237",
    keywords: [
      "\u201cmonthly amount\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cmonthly amount\u201d button",
      "\u6708"
    ]
  },
  "1f236": {
    name: "Japanese_\u201cnot_free_of_charge\u201d_button",
    code: "1f236",
    keywords: [
      "\u201cnot free of charge\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cnot free of charge\u201d button",
      "\u6709"
    ]
  },
  "1f22f": {
    name: "Japanese_\u201creserved\u201d_button",
    code: "1f22f",
    keywords: [
      "\u201creserved\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201creserved\u201d button",
      "\u6307"
    ]
  },
  "1f250": {
    name: "Japanese_\u201cbargain\u201d_button",
    code: "1f250",
    keywords: [
      "\u201cbargain\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cbargain\u201d button",
      "\u5f97"
    ]
  },
  "1f239": {
    name: "Japanese_\u201cdiscount\u201d_button",
    code: "1f239",
    keywords: [
      "\u201cdiscount\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cdiscount\u201d button",
      "\u5272"
    ]
  },
  "1f21a": {
    name: "Japanese_\u201cfree_of_charge\u201d_button",
    code: "1f21a",
    keywords: [
      "\u201cfree of charge\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cfree of charge\u201d button",
      "\u7121"
    ]
  },
  "1f232": {
    name: "Japanese_\u201cprohibited\u201d_button",
    code: "1f232",
    keywords: [
      "\u201cprohibited\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cprohibited\u201d button",
      "\u7981"
    ]
  },
  "1f251": {
    name: "Japanese_\u201cacceptable\u201d_button",
    code: "1f251",
    keywords: [
      "\u201cacceptable\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cacceptable\u201d button",
      "\u53ef"
    ]
  },
  "1f238": {
    name: "Japanese_\u201capplication\u201d_button",
    code: "1f238",
    keywords: [
      "\u201capplication\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201capplication\u201d button",
      "\u7533"
    ]
  },
  "1f234": {
    name: "Japanese_\u201cpassing_grade\u201d_button",
    code: "1f234",
    keywords: [
      "\u201cpassing grade\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cpassing grade\u201d button",
      "\u5408"
    ]
  },
  "1f233": {
    name: "Japanese_\u201cvacancy\u201d_button",
    code: "1f233",
    keywords: [
      "\u201cvacancy\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cvacancy\u201d button",
      "\u7a7a"
    ]
  },
  "3297": {
    name: "Japanese_\u201ccongratulations\u201d_button",
    code: "3297",
    keywords: [
      "\u201ccongratulations\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201ccongratulations\u201d button",
      "\u795d"
    ]
  },
  "3299": {
    name: "Japanese_\u201csecret\u201d_button",
    code: "3299",
    keywords: [
      "\u201csecret\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201csecret\u201d button",
      "\u79d8"
    ]
  },
  "1f23a": {
    name: "Japanese_\u201copen_for_business\u201d_button",
    code: "1f23a",
    keywords: [
      "\u201copen for business\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201copen for business\u201d button",
      "\u55b6"
    ]
  },
  "1f235": {
    name: "Japanese_\u201cno_vacancy\u201d_button",
    code: "1f235",
    keywords: [
      "\u201cno vacancy\u201d",
      "ideograph",
      "Japanese",
      "Japanese \u201cno vacancy\u201d button",
      "\u6e80"
    ]
  },
  "1f534": {
    name: "red_circle",
    code: "1f534",
    keywords: ["circle", "geometric", "red"]
  },
  "1f7e0": {
    name: "orange_circle",
    code: "1f7e0",
    keywords: ["circle", "orange"]
  },
  "1f7e1": {
    name: "yellow_circle",
    code: "1f7e1",
    keywords: ["circle", "yellow"]
  },
  "1f7e2": {
    name: "green_circle",
    code: "1f7e2",
    keywords: ["circle", "green"]
  },
  "1f535": {
    name: "blue_circle",
    code: "1f535",
    keywords: ["blue", "circle", "geometric"]
  },
  "1f7e3": {
    name: "purple_circle",
    code: "1f7e3",
    keywords: ["circle", "purple"]
  },
  "1f7e4": {
    name: "brown_circle",
    code: "1f7e4",
    keywords: ["brown", "circle"]
  },
  "26ab": {
    name: "black_circle",
    code: "26ab",
    keywords: ["black circle", "circle", "geometric"]
  },
  "26aa": {
    name: "white_circle",
    code: "26aa",
    keywords: ["circle", "geometric", "white circle"]
  },
  "1f7e5": { name: "red_square", code: "1f7e5", keywords: ["red", "square"] },
  "1f7e7": {
    name: "orange_square",
    code: "1f7e7",
    keywords: ["orange", "square"]
  },
  "1f7e8": {
    name: "yellow_square",
    code: "1f7e8",
    keywords: ["square", "yellow"]
  },
  "1f7e9": {
    name: "green_square",
    code: "1f7e9",
    keywords: ["green", "square"]
  },
  "1f7e6": { name: "blue_square", code: "1f7e6", keywords: ["blue", "square"] },
  "1f7ea": {
    name: "purple_square",
    code: "1f7ea",
    keywords: ["purple", "square"]
  },
  "1f7eb": {
    name: "brown_square",
    code: "1f7eb",
    keywords: ["brown", "square"]
  },
  "2b1b": {
    name: "black_large_square",
    code: "2b1b",
    keywords: ["black large square", "geometric", "square"]
  },
  "2b1c": {
    name: "white_large_square",
    code: "2b1c",
    keywords: ["geometric", "square", "white large square"]
  },
  "25fc": {
    name: "black_medium_square",
    code: "25fc",
    keywords: ["black medium square", "geometric", "square"]
  },
  "25fb": {
    name: "white_medium_square",
    code: "25fb",
    keywords: ["geometric", "square", "white medium square"]
  },
  "25fe": {
    name: "black_medium-small_square",
    code: "25fe",
    keywords: ["black medium-small square", "geometric", "square"]
  },
  "25fd": {
    name: "white_medium-small_square",
    code: "25fd",
    keywords: ["geometric", "square", "white medium-small square"]
  },
  "25aa": {
    name: "black_small_square",
    code: "25aa",
    keywords: ["black small square", "geometric", "square"]
  },
  "25ab": {
    name: "white_small_square",
    code: "25ab",
    keywords: ["geometric", "square", "white small square"]
  },
  "1f536": {
    name: "large_orange_diamond",
    code: "1f536",
    keywords: ["diamond", "geometric", "large orange diamond", "orange"]
  },
  "1f537": {
    name: "large_blue_diamond",
    code: "1f537",
    keywords: ["blue", "diamond", "geometric", "large blue diamond"]
  },
  "1f538": {
    name: "small_orange_diamond",
    code: "1f538",
    keywords: ["diamond", "geometric", "orange", "small orange diamond"]
  },
  "1f539": {
    name: "small_blue_diamond",
    code: "1f539",
    keywords: ["blue", "diamond", "geometric", "small blue diamond"]
  },
  "1f53a": {
    name: "red_triangle_pointed_up",
    code: "1f53a",
    keywords: ["geometric", "red", "red triangle pointed up"]
  },
  "1f53b": {
    name: "red_triangle_pointed_down",
    code: "1f53b",
    keywords: ["down", "geometric", "red", "red triangle pointed down"]
  },
  "1f4a0": {
    name: "diamond_with_a_dot",
    code: "1f4a0",
    keywords: ["comic", "diamond", "diamond with a dot", "geometric", "inside"]
  },
  "1f518": {
    name: "radio_button",
    code: "1f518",
    keywords: ["button", "geometric", "radio"]
  },
  "1f533": {
    name: "white_square_button",
    code: "1f533",
    keywords: [
      "button",
      "geometric",
      "outlined",
      "square",
      "white square button"
    ]
  },
  "1f532": {
    name: "black_square_button",
    code: "1f532",
    keywords: ["black square button", "button", "geometric", "square"]
  },
  "1f3c1": {
    name: "chequered_flag",
    code: "1f3c1",
    keywords: ["checkered", "chequered", "chequered flag", "racing"]
  },
  "1f6a9": {
    name: "triangular_flag",
    code: "1f6a9",
    keywords: ["post", "triangular flag"]
  },
  "1f38c": {
    name: "crossed_flags",
    code: "1f38c",
    keywords: ["celebration", "cross", "crossed", "crossed flags", "Japanese"]
  },
  "1f3f4": {
    name: "black_flag",
    code: "1f3f4",
    keywords: ["black flag", "waving"]
  },
  "1f3f3": {
    name: "white_flag",
    code: "1f3f3",
    keywords: ["waving", "white flag"]
  },
  "1f3f3_fe0f_200d_1f308": {
    name: "rainbow_flag",
    code: "1f3f3_fe0f_200d_1f308",
    keywords: ["pride", "rainbow", "rainbow flag"]
  },
  "1f3f3_fe0f_200d_26a7_fe0f": {
    name: "\u229b_transgender_flag",
    code: "1f3f3_fe0f_200d_26a7_fe0f",
    keywords: ["flag", "light blue", "pink", "transgender", "white"]
  },
  "1f3f4_200d_2620_fe0f": {
    name: "pirate_flag",
    code: "1f3f4_200d_2620_fe0f",
    keywords: ["Jolly Roger", "pirate", "pirate flag", "plunder", "treasure"]
  },
  "1f1e6_1f1e8": {
    name: "flag:_Ascension_Island",
    code: "1f1e6_1f1e8",
    keywords: ["flag"]
  },
  "1f1e6_1f1e9": {
    name: "flag:_Andorra",
    code: "1f1e6_1f1e9",
    keywords: ["flag"]
  },
  "1f1e6_1f1ea": {
    name: "flag:_United_Arab_Emirates",
    code: "1f1e6_1f1ea",
    keywords: ["flag"]
  },
  "1f1e6_1f1eb": {
    name: "flag:_Afghanistan",
    code: "1f1e6_1f1eb",
    keywords: ["flag"]
  },
  "1f1e6_1f1ec": {
    name: "flag:_Antigua_&_Barbuda",
    code: "1f1e6_1f1ec",
    keywords: ["flag"]
  },
  "1f1e6_1f1ee": {
    name: "flag:_Anguilla",
    code: "1f1e6_1f1ee",
    keywords: ["flag"]
  },
  "1f1e6_1f1f1": {
    name: "flag:_Albania",
    code: "1f1e6_1f1f1",
    keywords: ["flag"]
  },
  "1f1e6_1f1f2": {
    name: "flag:_Armenia",
    code: "1f1e6_1f1f2",
    keywords: ["flag"]
  },
  "1f1e6_1f1f4": {
    name: "flag:_Angola",
    code: "1f1e6_1f1f4",
    keywords: ["flag"]
  },
  "1f1e6_1f1f6": {
    name: "flag:_Antarctica",
    code: "1f1e6_1f1f6",
    keywords: ["flag"]
  },
  "1f1e6_1f1f7": {
    name: "flag:_Argentina",
    code: "1f1e6_1f1f7",
    keywords: ["flag"]
  },
  "1f1e6_1f1f8": {
    name: "flag:_American_Samoa",
    code: "1f1e6_1f1f8",
    keywords: ["flag"]
  },
  "1f1e6_1f1f9": {
    name: "flag:_Austria",
    code: "1f1e6_1f1f9",
    keywords: ["flag"]
  },
  "1f1e6_1f1fa": {
    name: "flag:_Australia",
    code: "1f1e6_1f1fa",
    keywords: ["flag"]
  },
  "1f1e6_1f1fc": {
    name: "flag:_Aruba",
    code: "1f1e6_1f1fc",
    keywords: ["flag"]
  },
  "1f1e6_1f1fd": {
    name: "flag:_\u00c5land_Islands",
    code: "1f1e6_1f1fd",
    keywords: ["flag"]
  },
  "1f1e6_1f1ff": {
    name: "flag:_Azerbaijan",
    code: "1f1e6_1f1ff",
    keywords: ["flag"]
  },
  "1f1e7_1f1e6": {
    name: "flag:_Bosnia_&_Herzegovina",
    code: "1f1e7_1f1e6",
    keywords: ["flag"]
  },
  "1f1e7_1f1e7": {
    name: "flag:_Barbados",
    code: "1f1e7_1f1e7",
    keywords: ["flag"]
  },
  "1f1e7_1f1e9": {
    name: "flag:_Bangladesh",
    code: "1f1e7_1f1e9",
    keywords: ["flag"]
  },
  "1f1e7_1f1ea": {
    name: "flag:_Belgium",
    code: "1f1e7_1f1ea",
    keywords: ["flag"]
  },
  "1f1e7_1f1eb": {
    name: "flag:_Burkina_Faso",
    code: "1f1e7_1f1eb",
    keywords: ["flag"]
  },
  "1f1e7_1f1ec": {
    name: "flag:_Bulgaria",
    code: "1f1e7_1f1ec",
    keywords: ["flag"]
  },
  "1f1e7_1f1ed": {
    name: "flag:_Bahrain",
    code: "1f1e7_1f1ed",
    keywords: ["flag"]
  },
  "1f1e7_1f1ee": {
    name: "flag:_Burundi",
    code: "1f1e7_1f1ee",
    keywords: ["flag"]
  },
  "1f1e7_1f1ef": {
    name: "flag:_Benin",
    code: "1f1e7_1f1ef",
    keywords: ["flag"]
  },
  "1f1e7_1f1f1": {
    name: "flag:_St._Barth\u00e9lemy",
    code: "1f1e7_1f1f1",
    keywords: ["flag"]
  },
  "1f1e7_1f1f2": {
    name: "flag:_Bermuda",
    code: "1f1e7_1f1f2",
    keywords: ["flag"]
  },
  "1f1e7_1f1f3": {
    name: "flag:_Brunei",
    code: "1f1e7_1f1f3",
    keywords: ["flag"]
  },
  "1f1e7_1f1f4": {
    name: "flag:_Bolivia",
    code: "1f1e7_1f1f4",
    keywords: ["flag"]
  },
  "1f1e7_1f1f6": {
    name: "flag:_Caribbean_Netherlands",
    code: "1f1e7_1f1f6",
    keywords: ["flag"]
  },
  "1f1e7_1f1f7": {
    name: "flag:_Brazil",
    code: "1f1e7_1f1f7",
    keywords: ["flag"]
  },
  "1f1e7_1f1f8": {
    name: "flag:_Bahamas",
    code: "1f1e7_1f1f8",
    keywords: ["flag"]
  },
  "1f1e7_1f1f9": {
    name: "flag:_Bhutan",
    code: "1f1e7_1f1f9",
    keywords: ["flag"]
  },
  "1f1e7_1f1fb": {
    name: "flag:_Bouvet_Island",
    code: "1f1e7_1f1fb",
    keywords: ["flag"]
  },
  "1f1e7_1f1fc": {
    name: "flag:_Botswana",
    code: "1f1e7_1f1fc",
    keywords: ["flag"]
  },
  "1f1e7_1f1fe": {
    name: "flag:_Belarus",
    code: "1f1e7_1f1fe",
    keywords: ["flag"]
  },
  "1f1e7_1f1ff": {
    name: "flag:_Belize",
    code: "1f1e7_1f1ff",
    keywords: ["flag"]
  },
  "1f1e8_1f1e6": {
    name: "flag:_Canada",
    code: "1f1e8_1f1e6",
    keywords: ["flag"]
  },
  "1f1e8_1f1e8": {
    name: "flag:_Cocos_(Keeling)_Islands",
    code: "1f1e8_1f1e8",
    keywords: ["flag"]
  },
  "1f1e8_1f1e9": {
    name: "flag:_Congo_-_Kinshasa",
    code: "1f1e8_1f1e9",
    keywords: ["flag"]
  },
  "1f1e8_1f1eb": {
    name: "flag:_Central_African_Republic",
    code: "1f1e8_1f1eb",
    keywords: ["flag"]
  },
  "1f1e8_1f1ec": {
    name: "flag:_Congo_-_Brazzaville",
    code: "1f1e8_1f1ec",
    keywords: ["flag"]
  },
  "1f1e8_1f1ed": {
    name: "flag:_Switzerland",
    code: "1f1e8_1f1ed",
    keywords: ["flag"]
  },
  "1f1e8_1f1ee": {
    name: "flag:_C\u00f4te_d\u2019Ivoire",
    code: "1f1e8_1f1ee",
    keywords: ["flag"]
  },
  "1f1e8_1f1f0": {
    name: "flag:_Cook_Islands",
    code: "1f1e8_1f1f0",
    keywords: ["flag"]
  },
  "1f1e8_1f1f1": {
    name: "flag:_Chile",
    code: "1f1e8_1f1f1",
    keywords: ["flag"]
  },
  "1f1e8_1f1f2": {
    name: "flag:_Cameroon",
    code: "1f1e8_1f1f2",
    keywords: ["flag"]
  },
  "1f1e8_1f1f3": {
    name: "flag:_China",
    code: "1f1e8_1f1f3",
    keywords: ["flag"]
  },
  "1f1e8_1f1f4": {
    name: "flag:_Colombia",
    code: "1f1e8_1f1f4",
    keywords: ["flag"]
  },
  "1f1e8_1f1f5": {
    name: "flag:_Clipperton_Island",
    code: "1f1e8_1f1f5",
    keywords: ["flag"]
  },
  "1f1e8_1f1f7": {
    name: "flag:_Costa_Rica",
    code: "1f1e8_1f1f7",
    keywords: ["flag"]
  },
  "1f1e8_1f1fa": {
    name: "flag:_Cuba",
    code: "1f1e8_1f1fa",
    keywords: ["flag"]
  },
  "1f1e8_1f1fb": {
    name: "flag:_Cape_Verde",
    code: "1f1e8_1f1fb",
    keywords: ["flag"]
  },
  "1f1e8_1f1fc": {
    name: "flag:_Cura\u00e7ao",
    code: "1f1e8_1f1fc",
    keywords: ["flag"]
  },
  "1f1e8_1f1fd": {
    name: "flag:_Christmas_Island",
    code: "1f1e8_1f1fd",
    keywords: ["flag"]
  },
  "1f1e8_1f1fe": {
    name: "flag:_Cyprus",
    code: "1f1e8_1f1fe",
    keywords: ["flag"]
  },
  "1f1e8_1f1ff": {
    name: "flag:_Czechia",
    code: "1f1e8_1f1ff",
    keywords: ["flag"]
  },
  "1f1e9_1f1ea": {
    name: "flag:_Germany",
    code: "1f1e9_1f1ea",
    keywords: ["flag"]
  },
  "1f1e9_1f1ec": {
    name: "flag:_Diego_Garcia",
    code: "1f1e9_1f1ec",
    keywords: ["flag"]
  },
  "1f1e9_1f1ef": {
    name: "flag:_Djibouti",
    code: "1f1e9_1f1ef",
    keywords: ["flag"]
  },
  "1f1e9_1f1f0": {
    name: "flag:_Denmark",
    code: "1f1e9_1f1f0",
    keywords: ["flag"]
  },
  "1f1e9_1f1f2": {
    name: "flag:_Dominica",
    code: "1f1e9_1f1f2",
    keywords: ["flag"]
  },
  "1f1e9_1f1f4": {
    name: "flag:_Dominican_Republic",
    code: "1f1e9_1f1f4",
    keywords: ["flag"]
  },
  "1f1e9_1f1ff": {
    name: "flag:_Algeria",
    code: "1f1e9_1f1ff",
    keywords: ["flag"]
  },
  "1f1ea_1f1e6": {
    name: "flag:_Ceuta_&_Melilla",
    code: "1f1ea_1f1e6",
    keywords: ["flag"]
  },
  "1f1ea_1f1e8": {
    name: "flag:_Ecuador",
    code: "1f1ea_1f1e8",
    keywords: ["flag"]
  },
  "1f1ea_1f1ea": {
    name: "flag:_Estonia",
    code: "1f1ea_1f1ea",
    keywords: ["flag"]
  },
  "1f1ea_1f1ec": {
    name: "flag:_Egypt",
    code: "1f1ea_1f1ec",
    keywords: ["flag"]
  },
  "1f1ea_1f1ed": {
    name: "flag:_Western_Sahara",
    code: "1f1ea_1f1ed",
    keywords: ["flag"]
  },
  "1f1ea_1f1f7": {
    name: "flag:_Eritrea",
    code: "1f1ea_1f1f7",
    keywords: ["flag"]
  },
  "1f1ea_1f1f8": {
    name: "flag:_Spain",
    code: "1f1ea_1f1f8",
    keywords: ["flag"]
  },
  "1f1ea_1f1f9": {
    name: "flag:_Ethiopia",
    code: "1f1ea_1f1f9",
    keywords: ["flag"]
  },
  "1f1ea_1f1fa": {
    name: "flag:_European_Union",
    code: "1f1ea_1f1fa",
    keywords: ["flag"]
  },
  "1f1eb_1f1ee": {
    name: "flag:_Finland",
    code: "1f1eb_1f1ee",
    keywords: ["flag"]
  },
  "1f1eb_1f1ef": {
    name: "flag:_Fiji",
    code: "1f1eb_1f1ef",
    keywords: ["flag"]
  },
  "1f1eb_1f1f0": {
    name: "flag:_Falkland_Islands",
    code: "1f1eb_1f1f0",
    keywords: ["flag"]
  },
  "1f1eb_1f1f2": {
    name: "flag:_Micronesia",
    code: "1f1eb_1f1f2",
    keywords: ["flag"]
  },
  "1f1eb_1f1f4": {
    name: "flag:_Faroe_Islands",
    code: "1f1eb_1f1f4",
    keywords: ["flag"]
  },
  "1f1eb_1f1f7": {
    name: "flag:_France",
    code: "1f1eb_1f1f7",
    keywords: ["flag"]
  },
  "1f1ec_1f1e6": {
    name: "flag:_Gabon",
    code: "1f1ec_1f1e6",
    keywords: ["flag"]
  },
  "1f1ec_1f1e7": {
    name: "flag:_United_Kingdom",
    code: "1f1ec_1f1e7",
    keywords: ["flag"]
  },
  "1f1ec_1f1e9": {
    name: "flag:_Grenada",
    code: "1f1ec_1f1e9",
    keywords: ["flag"]
  },
  "1f1ec_1f1ea": {
    name: "flag:_Georgia",
    code: "1f1ec_1f1ea",
    keywords: ["flag"]
  },
  "1f1ec_1f1eb": {
    name: "flag:_French_Guiana",
    code: "1f1ec_1f1eb",
    keywords: ["flag"]
  },
  "1f1ec_1f1ec": {
    name: "flag:_Guernsey",
    code: "1f1ec_1f1ec",
    keywords: ["flag"]
  },
  "1f1ec_1f1ed": {
    name: "flag:_Ghana",
    code: "1f1ec_1f1ed",
    keywords: ["flag"]
  },
  "1f1ec_1f1ee": {
    name: "flag:_Gibraltar",
    code: "1f1ec_1f1ee",
    keywords: ["flag"]
  },
  "1f1ec_1f1f1": {
    name: "flag:_Greenland",
    code: "1f1ec_1f1f1",
    keywords: ["flag"]
  },
  "1f1ec_1f1f2": {
    name: "flag:_Gambia",
    code: "1f1ec_1f1f2",
    keywords: ["flag"]
  },
  "1f1ec_1f1f3": {
    name: "flag:_Guinea",
    code: "1f1ec_1f1f3",
    keywords: ["flag"]
  },
  "1f1ec_1f1f5": {
    name: "flag:_Guadeloupe",
    code: "1f1ec_1f1f5",
    keywords: ["flag"]
  },
  "1f1ec_1f1f6": {
    name: "flag:_Equatorial_Guinea",
    code: "1f1ec_1f1f6",
    keywords: ["flag"]
  },
  "1f1ec_1f1f7": {
    name: "flag:_Greece",
    code: "1f1ec_1f1f7",
    keywords: ["flag"]
  },
  "1f1ec_1f1f8": {
    name: "flag:_South_Georgia_&_South_Sandwich_Islands",
    code: "1f1ec_1f1f8",
    keywords: ["flag"]
  },
  "1f1ec_1f1f9": {
    name: "flag:_Guatemala",
    code: "1f1ec_1f1f9",
    keywords: ["flag"]
  },
  "1f1ec_1f1fa": {
    name: "flag:_Guam",
    code: "1f1ec_1f1fa",
    keywords: ["flag"]
  },
  "1f1ec_1f1fc": {
    name: "flag:_Guinea-Bissau",
    code: "1f1ec_1f1fc",
    keywords: ["flag"]
  },
  "1f1ec_1f1fe": {
    name: "flag:_Guyana",
    code: "1f1ec_1f1fe",
    keywords: ["flag"]
  },
  "1f1ed_1f1f0": {
    name: "flag:_Hong_Kong_SAR_China",
    code: "1f1ed_1f1f0",
    keywords: ["flag"]
  },
  "1f1ed_1f1f2": {
    name: "flag:_Heard_&_McDonald_Islands",
    code: "1f1ed_1f1f2",
    keywords: ["flag"]
  },
  "1f1ed_1f1f3": {
    name: "flag:_Honduras",
    code: "1f1ed_1f1f3",
    keywords: ["flag"]
  },
  "1f1ed_1f1f7": {
    name: "flag:_Croatia",
    code: "1f1ed_1f1f7",
    keywords: ["flag"]
  },
  "1f1ed_1f1f9": {
    name: "flag:_Haiti",
    code: "1f1ed_1f1f9",
    keywords: ["flag"]
  },
  "1f1ed_1f1fa": {
    name: "flag:_Hungary",
    code: "1f1ed_1f1fa",
    keywords: ["flag"]
  },
  "1f1ee_1f1e8": {
    name: "flag:_Canary_Islands",
    code: "1f1ee_1f1e8",
    keywords: ["flag"]
  },
  "1f1ee_1f1e9": {
    name: "flag:_Indonesia",
    code: "1f1ee_1f1e9",
    keywords: ["flag"]
  },
  "1f1ee_1f1ea": {
    name: "flag:_Ireland",
    code: "1f1ee_1f1ea",
    keywords: ["flag"]
  },
  "1f1ee_1f1f1": {
    name: "flag:_Israel",
    code: "1f1ee_1f1f1",
    keywords: ["flag"]
  },
  "1f1ee_1f1f2": {
    name: "flag:_Isle_of_Man",
    code: "1f1ee_1f1f2",
    keywords: ["flag"]
  },
  "1f1ee_1f1f3": {
    name: "flag:_India",
    code: "1f1ee_1f1f3",
    keywords: ["flag"]
  },
  "1f1ee_1f1f4": {
    name: "flag:_British_Indian_Ocean_Territory",
    code: "1f1ee_1f1f4",
    keywords: ["flag"]
  },
  "1f1ee_1f1f6": {
    name: "flag:_Iraq",
    code: "1f1ee_1f1f6",
    keywords: ["flag"]
  },
  "1f1ee_1f1f7": {
    name: "flag:_Iran",
    code: "1f1ee_1f1f7",
    keywords: ["flag"]
  },
  "1f1ee_1f1f8": {
    name: "flag:_Iceland",
    code: "1f1ee_1f1f8",
    keywords: ["flag"]
  },
  "1f1ee_1f1f9": {
    name: "flag:_Italy",
    code: "1f1ee_1f1f9",
    keywords: ["flag"]
  },
  "1f1ef_1f1ea": {
    name: "flag:_Jersey",
    code: "1f1ef_1f1ea",
    keywords: ["flag"]
  },
  "1f1ef_1f1f2": {
    name: "flag:_Jamaica",
    code: "1f1ef_1f1f2",
    keywords: ["flag"]
  },
  "1f1ef_1f1f4": {
    name: "flag:_Jordan",
    code: "1f1ef_1f1f4",
    keywords: ["flag"]
  },
  "1f1ef_1f1f5": {
    name: "flag:_Japan",
    code: "1f1ef_1f1f5",
    keywords: ["flag"]
  },
  "1f1f0_1f1ea": {
    name: "flag:_Kenya",
    code: "1f1f0_1f1ea",
    keywords: ["flag"]
  },
  "1f1f0_1f1ec": {
    name: "flag:_Kyrgyzstan",
    code: "1f1f0_1f1ec",
    keywords: ["flag"]
  },
  "1f1f0_1f1ed": {
    name: "flag:_Cambodia",
    code: "1f1f0_1f1ed",
    keywords: ["flag"]
  },
  "1f1f0_1f1ee": {
    name: "flag:_Kiribati",
    code: "1f1f0_1f1ee",
    keywords: ["flag"]
  },
  "1f1f0_1f1f2": {
    name: "flag:_Comoros",
    code: "1f1f0_1f1f2",
    keywords: ["flag"]
  },
  "1f1f0_1f1f3": {
    name: "flag:_St._Kitts_&_Nevis",
    code: "1f1f0_1f1f3",
    keywords: ["flag"]
  },
  "1f1f0_1f1f5": {
    name: "flag:_North_Korea",
    code: "1f1f0_1f1f5",
    keywords: ["flag"]
  },
  "1f1f0_1f1f7": {
    name: "flag:_South_Korea",
    code: "1f1f0_1f1f7",
    keywords: ["flag"]
  },
  "1f1f0_1f1fc": {
    name: "flag:_Kuwait",
    code: "1f1f0_1f1fc",
    keywords: ["flag"]
  },
  "1f1f0_1f1fe": {
    name: "flag:_Cayman_Islands",
    code: "1f1f0_1f1fe",
    keywords: ["flag"]
  },
  "1f1f0_1f1ff": {
    name: "flag:_Kazakhstan",
    code: "1f1f0_1f1ff",
    keywords: ["flag"]
  },
  "1f1f1_1f1e6": {
    name: "flag:_Laos",
    code: "1f1f1_1f1e6",
    keywords: ["flag"]
  },
  "1f1f1_1f1e7": {
    name: "flag:_Lebanon",
    code: "1f1f1_1f1e7",
    keywords: ["flag"]
  },
  "1f1f1_1f1e8": {
    name: "flag:_St._Lucia",
    code: "1f1f1_1f1e8",
    keywords: ["flag"]
  },
  "1f1f1_1f1ee": {
    name: "flag:_Liechtenstein",
    code: "1f1f1_1f1ee",
    keywords: ["flag"]
  },
  "1f1f1_1f1f0": {
    name: "flag:_Sri_Lanka",
    code: "1f1f1_1f1f0",
    keywords: ["flag"]
  },
  "1f1f1_1f1f7": {
    name: "flag:_Liberia",
    code: "1f1f1_1f1f7",
    keywords: ["flag"]
  },
  "1f1f1_1f1f8": {
    name: "flag:_Lesotho",
    code: "1f1f1_1f1f8",
    keywords: ["flag"]
  },
  "1f1f1_1f1f9": {
    name: "flag:_Lithuania",
    code: "1f1f1_1f1f9",
    keywords: ["flag"]
  },
  "1f1f1_1f1fa": {
    name: "flag:_Luxembourg",
    code: "1f1f1_1f1fa",
    keywords: ["flag"]
  },
  "1f1f1_1f1fb": {
    name: "flag:_Latvia",
    code: "1f1f1_1f1fb",
    keywords: ["flag"]
  },
  "1f1f1_1f1fe": {
    name: "flag:_Libya",
    code: "1f1f1_1f1fe",
    keywords: ["flag"]
  },
  "1f1f2_1f1e6": {
    name: "flag:_Morocco",
    code: "1f1f2_1f1e6",
    keywords: ["flag"]
  },
  "1f1f2_1f1e8": {
    name: "flag:_Monaco",
    code: "1f1f2_1f1e8",
    keywords: ["flag"]
  },
  "1f1f2_1f1e9": {
    name: "flag:_Moldova",
    code: "1f1f2_1f1e9",
    keywords: ["flag"]
  },
  "1f1f2_1f1ea": {
    name: "flag:_Montenegro",
    code: "1f1f2_1f1ea",
    keywords: ["flag"]
  },
  "1f1f2_1f1eb": {
    name: "flag:_St._Martin",
    code: "1f1f2_1f1eb",
    keywords: ["flag"]
  },
  "1f1f2_1f1ec": {
    name: "flag:_Madagascar",
    code: "1f1f2_1f1ec",
    keywords: ["flag"]
  },
  "1f1f2_1f1ed": {
    name: "flag:_Marshall_Islands",
    code: "1f1f2_1f1ed",
    keywords: ["flag"]
  },
  "1f1f2_1f1f0": {
    name: "flag:_North_Macedonia",
    code: "1f1f2_1f1f0",
    keywords: ["flag"]
  },
  "1f1f2_1f1f1": {
    name: "flag:_Mali",
    code: "1f1f2_1f1f1",
    keywords: ["flag"]
  },
  "1f1f2_1f1f2": {
    name: "flag:_Myanmar_(Burma)",
    code: "1f1f2_1f1f2",
    keywords: ["flag"]
  },
  "1f1f2_1f1f3": {
    name: "flag:_Mongolia",
    code: "1f1f2_1f1f3",
    keywords: ["flag"]
  },
  "1f1f2_1f1f4": {
    name: "flag:_Macao_SAR_China",
    code: "1f1f2_1f1f4",
    keywords: ["flag"]
  },
  "1f1f2_1f1f5": {
    name: "flag:_Northern_Mariana_Islands",
    code: "1f1f2_1f1f5",
    keywords: ["flag"]
  },
  "1f1f2_1f1f6": {
    name: "flag:_Martinique",
    code: "1f1f2_1f1f6",
    keywords: ["flag"]
  },
  "1f1f2_1f1f7": {
    name: "flag:_Mauritania",
    code: "1f1f2_1f1f7",
    keywords: ["flag"]
  },
  "1f1f2_1f1f8": {
    name: "flag:_Montserrat",
    code: "1f1f2_1f1f8",
    keywords: ["flag"]
  },
  "1f1f2_1f1f9": {
    name: "flag:_Malta",
    code: "1f1f2_1f1f9",
    keywords: ["flag"]
  },
  "1f1f2_1f1fa": {
    name: "flag:_Mauritius",
    code: "1f1f2_1f1fa",
    keywords: ["flag"]
  },
  "1f1f2_1f1fb": {
    name: "flag:_Maldives",
    code: "1f1f2_1f1fb",
    keywords: ["flag"]
  },
  "1f1f2_1f1fc": {
    name: "flag:_Malawi",
    code: "1f1f2_1f1fc",
    keywords: ["flag"]
  },
  "1f1f2_1f1fd": {
    name: "flag:_Mexico",
    code: "1f1f2_1f1fd",
    keywords: ["flag"]
  },
  "1f1f2_1f1fe": {
    name: "flag:_Malaysia",
    code: "1f1f2_1f1fe",
    keywords: ["flag"]
  },
  "1f1f2_1f1ff": {
    name: "flag:_Mozambique",
    code: "1f1f2_1f1ff",
    keywords: ["flag"]
  },
  "1f1f3_1f1e6": {
    name: "flag:_Namibia",
    code: "1f1f3_1f1e6",
    keywords: ["flag"]
  },
  "1f1f3_1f1e8": {
    name: "flag:_New_Caledonia",
    code: "1f1f3_1f1e8",
    keywords: ["flag"]
  },
  "1f1f3_1f1ea": {
    name: "flag:_Niger",
    code: "1f1f3_1f1ea",
    keywords: ["flag"]
  },
  "1f1f3_1f1eb": {
    name: "flag:_Norfolk_Island",
    code: "1f1f3_1f1eb",
    keywords: ["flag"]
  },
  "1f1f3_1f1ec": {
    name: "flag:_Nigeria",
    code: "1f1f3_1f1ec",
    keywords: ["flag"]
  },
  "1f1f3_1f1ee": {
    name: "flag:_Nicaragua",
    code: "1f1f3_1f1ee",
    keywords: ["flag"]
  },
  "1f1f3_1f1f1": {
    name: "flag:_Netherlands",
    code: "1f1f3_1f1f1",
    keywords: ["flag"]
  },
  "1f1f3_1f1f4": {
    name: "flag:_Norway",
    code: "1f1f3_1f1f4",
    keywords: ["flag"]
  },
  "1f1f3_1f1f5": {
    name: "flag:_Nepal",
    code: "1f1f3_1f1f5",
    keywords: ["flag"]
  },
  "1f1f3_1f1f7": {
    name: "flag:_Nauru",
    code: "1f1f3_1f1f7",
    keywords: ["flag"]
  },
  "1f1f3_1f1fa": {
    name: "flag:_Niue",
    code: "1f1f3_1f1fa",
    keywords: ["flag"]
  },
  "1f1f3_1f1ff": {
    name: "flag:_New_Zealand",
    code: "1f1f3_1f1ff",
    keywords: ["flag"]
  },
  "1f1f4_1f1f2": {
    name: "flag:_Oman",
    code: "1f1f4_1f1f2",
    keywords: ["flag"]
  },
  "1f1f5_1f1e6": {
    name: "flag:_Panama",
    code: "1f1f5_1f1e6",
    keywords: ["flag"]
  },
  "1f1f5_1f1ea": {
    name: "flag:_Peru",
    code: "1f1f5_1f1ea",
    keywords: ["flag"]
  },
  "1f1f5_1f1eb": {
    name: "flag:_French_Polynesia",
    code: "1f1f5_1f1eb",
    keywords: ["flag"]
  },
  "1f1f5_1f1ec": {
    name: "flag:_Papua_New_Guinea",
    code: "1f1f5_1f1ec",
    keywords: ["flag"]
  },
  "1f1f5_1f1ed": {
    name: "flag:_Philippines",
    code: "1f1f5_1f1ed",
    keywords: ["flag"]
  },
  "1f1f5_1f1f0": {
    name: "flag:_Pakistan",
    code: "1f1f5_1f1f0",
    keywords: ["flag"]
  },
  "1f1f5_1f1f1": {
    name: "flag:_Poland",
    code: "1f1f5_1f1f1",
    keywords: ["flag"]
  },
  "1f1f5_1f1f2": {
    name: "flag:_St._Pierre_&_Miquelon",
    code: "1f1f5_1f1f2",
    keywords: ["flag"]
  },
  "1f1f5_1f1f3": {
    name: "flag:_Pitcairn_Islands",
    code: "1f1f5_1f1f3",
    keywords: ["flag"]
  },
  "1f1f5_1f1f7": {
    name: "flag:_Puerto_Rico",
    code: "1f1f5_1f1f7",
    keywords: ["flag"]
  },
  "1f1f5_1f1f8": {
    name: "flag:_Palestinian_Territories",
    code: "1f1f5_1f1f8",
    keywords: ["flag"]
  },
  "1f1f5_1f1f9": {
    name: "flag:_Portugal",
    code: "1f1f5_1f1f9",
    keywords: ["flag"]
  },
  "1f1f5_1f1fc": {
    name: "flag:_Palau",
    code: "1f1f5_1f1fc",
    keywords: ["flag"]
  },
  "1f1f5_1f1fe": {
    name: "flag:_Paraguay",
    code: "1f1f5_1f1fe",
    keywords: ["flag"]
  },
  "1f1f6_1f1e6": {
    name: "flag:_Qatar",
    code: "1f1f6_1f1e6",
    keywords: ["flag"]
  },
  "1f1f7_1f1ea": {
    name: "flag:_R\u00e9union",
    code: "1f1f7_1f1ea",
    keywords: ["flag"]
  },
  "1f1f7_1f1f4": {
    name: "flag:_Romania",
    code: "1f1f7_1f1f4",
    keywords: ["flag"]
  },
  "1f1f7_1f1f8": {
    name: "flag:_Serbia",
    code: "1f1f7_1f1f8",
    keywords: ["flag"]
  },
  "1f1f7_1f1fa": {
    name: "flag:_Russia",
    code: "1f1f7_1f1fa",
    keywords: ["flag"]
  },
  "1f1f7_1f1fc": {
    name: "flag:_Rwanda",
    code: "1f1f7_1f1fc",
    keywords: ["flag"]
  },
  "1f1f8_1f1e6": {
    name: "flag:_Saudi_Arabia",
    code: "1f1f8_1f1e6",
    keywords: ["flag"]
  },
  "1f1f8_1f1e7": {
    name: "flag:_Solomon_Islands",
    code: "1f1f8_1f1e7",
    keywords: ["flag"]
  },
  "1f1f8_1f1e8": {
    name: "flag:_Seychelles",
    code: "1f1f8_1f1e8",
    keywords: ["flag"]
  },
  "1f1f8_1f1e9": {
    name: "flag:_Sudan",
    code: "1f1f8_1f1e9",
    keywords: ["flag"]
  },
  "1f1f8_1f1ea": {
    name: "flag:_Sweden",
    code: "1f1f8_1f1ea",
    keywords: ["flag"]
  },
  "1f1f8_1f1ec": {
    name: "flag:_Singapore",
    code: "1f1f8_1f1ec",
    keywords: ["flag"]
  },
  "1f1f8_1f1ed": {
    name: "flag:_St._Helena",
    code: "1f1f8_1f1ed",
    keywords: ["flag"]
  },
  "1f1f8_1f1ee": {
    name: "flag:_Slovenia",
    code: "1f1f8_1f1ee",
    keywords: ["flag"]
  },
  "1f1f8_1f1ef": {
    name: "flag:_Svalbard_&_Jan_Mayen",
    code: "1f1f8_1f1ef",
    keywords: ["flag"]
  },
  "1f1f8_1f1f0": {
    name: "flag:_Slovakia",
    code: "1f1f8_1f1f0",
    keywords: ["flag"]
  },
  "1f1f8_1f1f1": {
    name: "flag:_Sierra_Leone",
    code: "1f1f8_1f1f1",
    keywords: ["flag"]
  },
  "1f1f8_1f1f2": {
    name: "flag:_San_Marino",
    code: "1f1f8_1f1f2",
    keywords: ["flag"]
  },
  "1f1f8_1f1f3": {
    name: "flag:_Senegal",
    code: "1f1f8_1f1f3",
    keywords: ["flag"]
  },
  "1f1f8_1f1f4": {
    name: "flag:_Somalia",
    code: "1f1f8_1f1f4",
    keywords: ["flag"]
  },
  "1f1f8_1f1f7": {
    name: "flag:_Suriname",
    code: "1f1f8_1f1f7",
    keywords: ["flag"]
  },
  "1f1f8_1f1f8": {
    name: "flag:_South_Sudan",
    code: "1f1f8_1f1f8",
    keywords: ["flag"]
  },
  "1f1f8_1f1f9": {
    name: "flag:_S\u00e3o_Tom\u00e9_&_Pr\u00edncipe",
    code: "1f1f8_1f1f9",
    keywords: ["flag"]
  },
  "1f1f8_1f1fb": {
    name: "flag:_El_Salvador",
    code: "1f1f8_1f1fb",
    keywords: ["flag"]
  },
  "1f1f8_1f1fd": {
    name: "flag:_Sint_Maarten",
    code: "1f1f8_1f1fd",
    keywords: ["flag"]
  },
  "1f1f8_1f1fe": {
    name: "flag:_Syria",
    code: "1f1f8_1f1fe",
    keywords: ["flag"]
  },
  "1f1f8_1f1ff": {
    name: "flag:_Eswatini",
    code: "1f1f8_1f1ff",
    keywords: ["flag"]
  },
  "1f1f9_1f1e6": {
    name: "flag:_Tristan_da_Cunha",
    code: "1f1f9_1f1e6",
    keywords: ["flag"]
  },
  "1f1f9_1f1e8": {
    name: "flag:_Turks_&_Caicos_Islands",
    code: "1f1f9_1f1e8",
    keywords: ["flag"]
  },
  "1f1f9_1f1e9": {
    name: "flag:_Chad",
    code: "1f1f9_1f1e9",
    keywords: ["flag"]
  },
  "1f1f9_1f1eb": {
    name: "flag:_French_Southern_Territories",
    code: "1f1f9_1f1eb",
    keywords: ["flag"]
  },
  "1f1f9_1f1ec": {
    name: "flag:_Togo",
    code: "1f1f9_1f1ec",
    keywords: ["flag"]
  },
  "1f1f9_1f1ed": {
    name: "flag:_Thailand",
    code: "1f1f9_1f1ed",
    keywords: ["flag"]
  },
  "1f1f9_1f1ef": {
    name: "flag:_Tajikistan",
    code: "1f1f9_1f1ef",
    keywords: ["flag"]
  },
  "1f1f9_1f1f0": {
    name: "flag:_Tokelau",
    code: "1f1f9_1f1f0",
    keywords: ["flag"]
  },
  "1f1f9_1f1f1": {
    name: "flag:_Timor-Leste",
    code: "1f1f9_1f1f1",
    keywords: ["flag"]
  },
  "1f1f9_1f1f2": {
    name: "flag:_Turkmenistan",
    code: "1f1f9_1f1f2",
    keywords: ["flag"]
  },
  "1f1f9_1f1f3": {
    name: "flag:_Tunisia",
    code: "1f1f9_1f1f3",
    keywords: ["flag"]
  },
  "1f1f9_1f1f4": {
    name: "flag:_Tonga",
    code: "1f1f9_1f1f4",
    keywords: ["flag"]
  },
  "1f1f9_1f1f7": {
    name: "flag:_Turkey",
    code: "1f1f9_1f1f7",
    keywords: ["flag"]
  },
  "1f1f9_1f1f9": {
    name: "flag:_Trinidad_&_Tobago",
    code: "1f1f9_1f1f9",
    keywords: ["flag"]
  },
  "1f1f9_1f1fb": {
    name: "flag:_Tuvalu",
    code: "1f1f9_1f1fb",
    keywords: ["flag"]
  },
  "1f1f9_1f1fc": {
    name: "flag:_Taiwan",
    code: "1f1f9_1f1fc",
    keywords: ["flag"]
  },
  "1f1f9_1f1ff": {
    name: "flag:_Tanzania",
    code: "1f1f9_1f1ff",
    keywords: ["flag"]
  },
  "1f1fa_1f1e6": {
    name: "flag:_Ukraine",
    code: "1f1fa_1f1e6",
    keywords: ["flag"]
  },
  "1f1fa_1f1ec": {
    name: "flag:_Uganda",
    code: "1f1fa_1f1ec",
    keywords: ["flag"]
  },
  "1f1fa_1f1f2": {
    name: "flag:_U.S._Outlying_Islands",
    code: "1f1fa_1f1f2",
    keywords: ["flag"]
  },
  "1f1fa_1f1f3": {
    name: "flag:_United_Nations",
    code: "1f1fa_1f1f3",
    keywords: ["flag"]
  },
  "1f1fa_1f1f8": {
    name: "flag:_United_States",
    code: "1f1fa_1f1f8",
    keywords: ["flag"]
  },
  "1f1fa_1f1fe": {
    name: "flag:_Uruguay",
    code: "1f1fa_1f1fe",
    keywords: ["flag"]
  },
  "1f1fa_1f1ff": {
    name: "flag:_Uzbekistan",
    code: "1f1fa_1f1ff",
    keywords: ["flag"]
  },
  "1f1fb_1f1e6": {
    name: "flag:_Vatican_City",
    code: "1f1fb_1f1e6",
    keywords: ["flag"]
  },
  "1f1fb_1f1e8": {
    name: "flag:_St._Vincent_&_Grenadines",
    code: "1f1fb_1f1e8",
    keywords: ["flag"]
  },
  "1f1fb_1f1ea": {
    name: "flag:_Venezuela",
    code: "1f1fb_1f1ea",
    keywords: ["flag"]
  },
  "1f1fb_1f1ec": {
    name: "flag:_British_Virgin_Islands",
    code: "1f1fb_1f1ec",
    keywords: ["flag"]
  },
  "1f1fb_1f1ee": {
    name: "flag:_U.S._Virgin_Islands",
    code: "1f1fb_1f1ee",
    keywords: ["flag"]
  },
  "1f1fb_1f1f3": {
    name: "flag:_Vietnam",
    code: "1f1fb_1f1f3",
    keywords: ["flag"]
  },
  "1f1fb_1f1fa": {
    name: "flag:_Vanuatu",
    code: "1f1fb_1f1fa",
    keywords: ["flag"]
  },
  "1f1fc_1f1eb": {
    name: "flag:_Wallis_&_Futuna",
    code: "1f1fc_1f1eb",
    keywords: ["flag"]
  },
  "1f1fc_1f1f8": {
    name: "flag:_Samoa",
    code: "1f1fc_1f1f8",
    keywords: ["flag"]
  },
  "1f1fd_1f1f0": {
    name: "flag:_Kosovo",
    code: "1f1fd_1f1f0",
    keywords: ["flag"]
  },
  "1f1fe_1f1ea": {
    name: "flag:_Yemen",
    code: "1f1fe_1f1ea",
    keywords: ["flag"]
  },
  "1f1fe_1f1f9": {
    name: "flag:_Mayotte",
    code: "1f1fe_1f1f9",
    keywords: ["flag"]
  },
  "1f1ff_1f1e6": {
    name: "flag:_South_Africa",
    code: "1f1ff_1f1e6",
    keywords: ["flag"]
  },
  "1f1ff_1f1f2": {
    name: "flag:_Zambia",
    code: "1f1ff_1f1f2",
    keywords: ["flag"]
  },
  "1f1ff_1f1fc": {
    name: "flag:_Zimbabwe",
    code: "1f1ff_1f1fc",
    keywords: ["flag"]
  },
  "1f3f4_e0067_e0062_e0065_e006e_e0067_e007f": {
    name: "flag:_England",
    code: "1f3f4_e0067_e0062_e0065_e006e_e0067_e007f",
    keywords: ["flag"]
  },
  "1f3f4_e0067_e0062_e0073_e0063_e0074_e007f": {
    name: "flag:_Scotland",
    code: "1f3f4_e0067_e0062_e0073_e0063_e0074_e007f",
    keywords: ["flag"]
  },
  "1f3f4_e0067_e0062_e0077_e006c_e0073_e007f": {
    name: "flag:_Wales",
    code: "1f3f4_e0067_e0062_e0077_e006c_e0073_e007f",
    keywords: ["flag"]
  }
};

export const EMOJIS_KEYS = Object.keys(EMOJIS);
export const EMOJIS_VALUES = Object.values(EMOJIS);
