import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Flex,
  formatDate,
  getShadow,
  Icon,
  IconAction,
  Image,
  orderObjects,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, API_ROOT } from "config";
import { Conversations } from "modules/Conversations";
import { DEFAULT_MARGIN, DEFAULT_SIDEMENU_WIDTH } from "constant";
import { HISTORY_TYPES } from "constant";
// import { Invites } from "./Invites";
import { MainWindow, SideMenu } from "components";
import { Task } from "modules";
import { Topic } from "modules/Topics";

const getModule = (update, dispatch) => {
  if (!update) return null;

  if (update.topic_id) return Topic;

  if (update.task_id) return Task;

  if (update.type_id === 21) {
    dispatch(
      ACTIONS.conversations.startConversation({
        acc: update.additional_info.uuid
      })
    );
    return Conversations;
  }
};

const Content = ({ content, update }) => {
  return (
    <Text
      fontSize="0.75rem"
      mt="0.25rem"
      style={{
        wordWrap: "break-word"
      }}
      fontWeight="semibold"
      color="t2"
    >
      {content && content.substring(0, 140)}
    </Text>
  );
};

const Reaction = ({ content }) => {
  return (
    <Flex
      mx="auto"
      my="1rem"
      bg="l0"
      borderRadius="50%"
      p="1rem"
      width="fit-content"
      {...getShadow("s5")}
    >
      <Image
        width="2rem"
        minWidth="2rem"
        height="2rem"
        minHeight="2rem"
        src={`https://static.elefante.com/emojis/${content}.png`}
      />
    </Flex>
  );
};

const MentionUpdate = ({ update }) => {
  const dispatch = useDispatch();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );
  const selectedUpdate = useSelector(
    (state) => state["updates"].selectedUpdate
  );

  useEffect(() => {
    if (
      selectedUpdate &&
      selectedUpdate.additional_info.uuid &&
      selectedUpdate.additional_info.uuid !== activeConversation
    ) {
      dispatch(
        ACTIONS.conversations.startConversation({
          acc: selectedUpdate.additional_info.uuid
        })
      );
    }
  }, [activeConversation, dispatch, selectedUpdate]);

  return null;
};

const Update = ({
  children,
  index,
  update,
  removeBottomSpace,
  collapse,
  acknowledgeTask
}) => {
  const { t } = useTranslation();

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const selectedUpdate = useSelector(
    (state) => state["updates"].selectedUpdate
  );
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  let RenderUpdate;

  // if (update.type_id === 21) {
  //   RenderUpdate = MentionUpdate;
  // } else RenderUpdate = TaskUpdate;

  let selected = update && selectedUpdate && update.id === selectedUpdate.id;

  // let color = "l0";
  // if (update.type_id === 2) color = "lightOrange";
  // if ([2, 17].includes(update.type_id)) color = "lightOrange";
  // if ([14].includes(update.type_id)) color = "lightRed";
  // if ([5].includes(update.type_id)) color = "lightGreen";

  // const nonDismissible = [24].includes(update.type_id);

  return (
    <Flex style={{ cursor: "pointer" }} mb="1rem" width={1}>
      <Flex flexDirection="column" width={1}>
        <Flex justifyContent="space-between" alignItems="center" width={1}>
          <Text fontSize="0.875rem" color="t1" fontWeight="semibold">
            {t(HISTORY_TYPES[update.type_id])}
          </Text>

          <IconAction
            onClick={(e) => acknowledgeTask(e, update)}
            icon="Ok"
            stroke="green"
            style={{ strokeWidth: "2px" }}
          />
        </Flex>

        <RenderUpdate key={update.id} update={update} collapse={collapse} />

        <Flex alignItems="center" mt="0.5rem">
          <Avatar
            size="medium"
            src={users[update.user_id].avatar}
            online={onlineUsersIds.includes(update.user_id)}
            mr="0.25rem"
            showOnline={true}
          />
          <Text
            color={selected ? "t2" : "t4"}
            fontWeight="semibold"
            fontSize="0.75rem"
          >
            {users[update.user_id].name}
            {", "}
            {formatDate(
              update.creation_date,
              user.locale,
              user.timezone
            ).distance()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Render = ({ update }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedUpdate = useSelector(
    (state) => state["updates"].selectedUpdate
  );
  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [open, setOpen] = useState();

  const acknowledgeUpdate = (e, update) => {
    e.stopPropagation();
    let nextActions = [ACTIONS.updates.acknowledgeHistory(update.id)];

    // let index = orderedUpates.findIndex(
    //   (orderedUpate) => update.id === orderedUpate.id
    // );

    // let count = orderedUpates.length;
    // if (count === 1) {
    //   // nextActions.push(ACTIONS.updates.selectUpdate(null));
    // } else if (index + 1 === count) {
    //   // nextActions.push(ACTIONS.updates.selectUpdate(orderedUpates[index - 1]));
    // } else {
    //   // nextActions.push(ACTIONS.updates.selectUpdate(orderedUpates[index + 1]));
    // }

    dispatch(
      ACTIONS.fetch.patch(
        "updates",
        `${API_ROOT}/task/acknowledge`,
        nextActions,
        { ids: [update.id] }
      )
    );
  };

  const selected = update && selectedUpdate && update.id === selectedUpdate.id;

  let content = update.subject ? update.subject : null;
  let View = Content;
  if (update.additional_info.content) content = update.additional_info.content;
  if (update.additional_info.reaction_code) {
    View = Reaction;
    content = update.additional_info.reaction_code;
  }

  let moduleId;
  let objId;

  if (update.type_id === 21) {
    moduleId = "conversations";
    objId = update.additional_info.uuid;
  } else if (update.task_id) {
    moduleId = "tasks";
    objId = update.task_id;
  } else if (update.topic_id) {
    moduleId = "topic";
    objId = update.topic_id;
  }

  let count = (update.updates && update.updates.length) || 0;

  return (
    <Flex
      mb="0.5rem"
      borderRadius="0.5rem"
      bg={selected ? "userLight" : "l0"}
      style={{ cursor: "pointer" }}
      pl="0.75rem"
      pr="0.25rem"
      py="0.5rem"
      flexDirection="column"
      justifyContent="space-between"
      boxShadow="0px 0px 8px 0px hsla(240, 0%, 80%)"
      onClick={() => {
        dispatch(
          ACTIONS.state.setAttribute("updates", "selectedUpdate", update)
        );
        moduleId &&
          dispatch(
            ACTIONS.state.changeState(moduleId, (state) => {
              state.loadingObjId = objId;
              return state;
            })
          );
      }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight="bold" color="t1">
          {t(HISTORY_TYPES[update.type_id])}
        </Text>

        {false && count > 0 && (
          <Text
            ml="auto"
            bg="userLight"
            borderRadius="4rem"
            fontSize="0.6875rem"
            fontWeight="semibold"
            height="fit-content"
            px="0.5rem"
            py="0.25rem"
          >
            {count}
          </Text>
        )}
        <IconAction
          onClick={(e) => acknowledgeUpdate(e, update)}
          icon="Ok"
          stroke="green"
          style={{ strokeWidth: "2px" }}
        />
      </Flex>
      <View content={content} update={update} />

      <Flex alignItems="center" mt="0.5rem">
        <Avatar
          size="medium"
          src={users[update.user_id].avatar}
          online={onlineUsersIds.includes(update.user_id)}
          mr="0.25rem"
          showOnline={true}
        />
        <Text
          color={selected ? "t2" : "t4"}
          fontWeight="semibold"
          fontSize="0.75rem"
        >
          {users[update.user_id].name}
          {", "}
          {formatDate(update.date, user.locale, user.timezone).distance()}
        </Text>

        {count > 0 && (
          <>
            {open ? (
              <Icon.ChevronDown
                ml="auto"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              />
            ) : (
              <Icon.ChevronRight
                ml="auto"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(!open);
                }}
              />
            )}
          </>
        )}
      </Flex>

      {update.updates &&
        open &&
        update.updates.map((update, index) => (
          <Flex
            key={update.id}
            flexDirection="column"
            mt={index ? "0.5rem" : "1rem"}
          >
            <Flex>
              <Text
                fontWeight="semibold"
                color="t2"
                fontSize="0.875rem"
                mt="0.25rem"
              >
                {t(HISTORY_TYPES[update.type_id])}
              </Text>
              <IconAction
                onClick={(e) => acknowledgeUpdate(e, update)}
                icon="Ok"
                stroke="green"
                ml="auto"
                style={{ strokeWidth: "2px" }}
              />
            </Flex>
            <Flex alignItems="center">
              <Avatar
                size="medium"
                src={users[update.user_id].avatar}
                online={onlineUsersIds.includes(update.user_id)}
                mr="0.25rem"
                showOnline={true}
              />
              <Text color="t4" fontWeight="semibold" fontSize="0.75rem">
                {users[update.user_id].name}
                {", "}
                {formatDate(
                  update.creation_date,
                  user.locale,
                  user.timezone
                ).distance()}
              </Text>
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};

export const Updates = ({ spaceId, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updates = useSelector((state) => state["updates"].updates);
  const selectedUpdate = useSelector(
    (state) => state["updates"].selectedUpdate
  );

  useEffect(() => {
    document.title = t("Updates");
    return () => (document.title = "Elefante");
  }, [t]);

  useEffect(() => {
    if (updates && !selectedUpdate) {
      const values = Object.values(updates);
      const len = values.length;

      if (!values || len === 0) return;

      const selected = values[len - 1];

      dispatch(
        ACTIONS.state.setAttribute("updates", "selectedUpdate", selected)
      );

      let moduleId;
      let objId;

      if (selected.task_id) {
        moduleId = "tasks";
        objId = selected.task_id;
      } else if (selected.topic_id) {
        moduleId = "topic";
        objId = selected.topic_id;
      }

      dispatch(
        ACTIONS.state.changeState(moduleId, (state) => {
          state.loadingObjId = objId;
          return state;
        })
      );
    }
  }, [dispatch, selectedUpdate, updates]);

  useEffect(() => {
    return () => {
      dispatch(
        ACTIONS.state.changeState("tasks", (state) => {
          state.loadingObjId = null;
          state.obj = null;
          return state;
        })
      );
      // dispatch(
      //   ACTIONS.state.changeState("topic", (state) => {
      //     state.loadingObjId = null;
      //     state.obj = null;
      //     return state;
      //   })
      // );
      dispatch(
        ACTIONS.state.changeState("updates", (state) => {
          state.selectedUpdate = null;
          return state;
        })
      );
    };
  }, [dispatch]);

  let orderedUpates = Object.values(updates);

  let groupedUpdates = {};

  const acknowledgeAllTasks = () => {
    let updatesIds = Object.values(updates).map((update) => update.id);
    dispatch(ACTIONS.updates.acknowledgeHistory(updatesIds));
    // dispatch(
    //   ACcTIONS.fetch.patch(
    //     "updates",
    //     `${API_ROOT}/task/acknowledge/`,
    //     [ACTIONS.updates.acknowledgeHistory],
    //     {
    //       ids: updatesIds
    //     }
    //   )
    // );
  };

  Object.values(updates).forEach((update) => {
    let key = "";
    let type;

    if (update.topic_id) {
      type = "TOPIC";
      key = `TOPIC.${update.topic_id}`;
    } else if (update.task_id) {
      type = "TASK";
      key = `TASK.${update.task_id}`;
    } else if (update.type_id === 21) {
      type = "CONVERSATION";
      key = `CONVERSATION.${update.id}`;
    }

    if (!groupedUpdates[key])
      if (type === "CONVERSATION")
        groupedUpdates[key] = {
          date: update.creation_date,
          ...update
        };
      else if (type === "TASK") {
        groupedUpdates[key] = {
          // taskId: update.task_id,
          updates: [],
          date: update.creation_date,
          ...update
        };
      } else if (type === "TOPIC") {
        groupedUpdates[key] = {
          // topicId: update.topic_id,
          updates: [],
          date: update.creation_date,
          ...update
        };
      } else {
        groupedUpdates[key].id = update.id;
      }

    if (["TOPIC", "TASK"].includes(type)) {
      if (update.creation_date > groupedUpdates[key].date)
        groupedUpdates[key].date = update.creation_date;
      groupedUpdates[key].updates.unshift(update);
    }
  });

  groupedUpdates = orderObjects(groupedUpdates, "date", "desc");

  const refreshUpdates = () => {
    dispatch(
      ACTIONS.fetch.get(
        "updates",
        `${API_ENDPOINT}/history/`,
        ACTIONS.updates.loadUpdates
      )
    );
  };

  if (orderedUpates.length === 0)
    return (
      <Flex
        width={1}
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Image width="16rem" src="images/work/ok.svg" />
        <Text fontSize="2rem" mt="1rem" fontWeight="semibold">
          {t("Nice job! You are up to date!")}
        </Text>
      </Flex>
    );

  if (!updates) return null;

  const Module = selectedUpdate ? getModule(selectedUpdate, dispatch) : null;

  return (
    <>
      <SideMenu
        width={DEFAULT_SIDEMENU_WIDTH}
        minWidth={DEFAULT_SIDEMENU_WIDTH}
      >
        <Flex alignItems="center" p={DEFAULT_MARGIN}>
          <Text textStyle="title">{t("Updates")}</Text>

          {false && (
            <IconAction
              ml="auto"
              onClick={refreshUpdates}
              size="1.125rem"
              icon="Refresh"
              stroke="green"
              style={{ strokeWidth: "2px" }}
            />
          )}

          <Text
            ml="auto"
            bg="userLight"
            borderRadius="4rem"
            fontSize="0.6875rem"
            fontWeight="semibold"
            height="fit-content"
            px="0.5rem"
            py="0.25rem"
          >
            {groupedUpdates.length}
          </Text>
        </Flex>

        <Flex flexDirection="column" overflow="auto" px={DEFAULT_MARGIN}>
          {groupedUpdates.map((update, index) => (
            <Render key={update.id} update={update} />
          ))}
        </Flex>
      </SideMenu>

      <MainWindow px="2rem">
        {selectedUpdate && <Module update={selectedUpdate} />}
      </MainWindow>
    </>
  );
};
