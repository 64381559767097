import { useEffect, useState } from "react";

export const SHORTCUTS = { disabled: false };

export const Hotkeys = () => {
  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);

  let key;
  let result;

  const downHandler = e => {
    if (SHORTCUTS.disabled) return;

    if (e.repeat) return;

    key = e.key.toLowerCase();

    if (e.ctrlKey && e.key !== "Control") key = "ctrl" + key;
    if (
      SHORTCUTS.hasOwnProperty(key) &&
      typeof SHORTCUTS[key].callBack === "function"
    ) {
      if (SHORTCUTS[key].preventDefault) e.preventDefault();
      result = SHORTCUTS[key].callBack(SHORTCUTS[key].value);
      SHORTCUTS[key].setSavedValue(result);
    }
  };
};

export const enableShortcuts = () => {
  SHORTCUTS.disabled = false;
};

export const disableShortcuts = () => {
  SHORTCUTS.disabled = true;
};

export const registerShortcut = (
  callBack,
  key,
  setSavedValue,
  value,
  preventDefault
) => {
  if (!value) delete SHORTCUTS[key];
  SHORTCUTS[key] = { value, setSavedValue, callBack, preventDefault };
};

export const unregisterShortcut = key => {
  delete SHORTCUTS[key];
};

export const useShortcut = (key, value, callBack, preventDefault) => {
  const [savedValue, setSavedValue] = useState(value);

  useEffect(() => {
    registerShortcut(callBack, key, setSavedValue, value, preventDefault);
    return () => registerShortcut(key);
  }, []);

  useEffect(() => {
    SHORTCUTS[key].value = savedValue;
  }, [savedValue]);

  return [savedValue, setSavedValue];
};

export default useShortcut;
