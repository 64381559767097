import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react/headless";

import { Flex, Icon, orderObjectsList, Text } from "tomato";

import { t } from "i18n";

const Option = ({ instance, option }) => {
  const action = (action) => {
    action();
    instance.unmount();
  };

  let Component;

  if (option.component) Component = option.component;

  return (
    <Flex
      borderRadius="0.375rem"
      hoverColor="lightBlue"
      px="0.5rem"
      py="0.5rem"
      my="0.125rem"
      onClick={() => action(option.action)}
      style={{ cursor: "pointer", whiteSpace: "nowrap" }}
    >
      {option.component && <Component />}
      <Text ml={option.component ? "0.5rem" : ""} fontSize="0.9375rem">
        {option.name}
      </Text>
    </Flex>
  );
};

const Menu = ({ instance, options }) => {
  return (
    <Flex
      bg="l0"
      maxHeight="15rem"
      overflow="auto"
      maxWidth="18rem"
      borderRadius="0.375rem"
      border="1px solid lightGrey"
      flexDirection="column"
      boxShadow="2px 3px 8px -2px rgba(0,0,0,0.40)"
      zIndex="1000"
      width="fit-content"
      p="0.5rem"
    >
      {options.map((option, index) => (
        <Option key={index} option={option} instance={instance} />
      ))}
    </Flex>
  );
};

export const FloatingMenu = ({
  placement = "bottom-start",
  options = [],
  children,
  ...props
}) => {
  const [instance, setInstance] = useState(null);

  return (
    <Tippy
      placement={placement}
      trigger="click"
      interactive={true}
      offset={[0, 4]}
      appendTo="parent"
      render={(attrs) => (
        <>{instance ? <Menu options={options} instance={instance} /> : ""}</>
      )}
      onCreate={setInstance}
    >
      <Flex style={{ cursor: "ponter" }}>{children}</Flex>
    </Tippy>
  );
};
