import styled from "styled-components";
import { buttonStyle } from "styled-system";

import { Box } from "../Flex";
import { UI } from "../../constants";

const StyledButton = styled('Flex')(buttonStyle);

export const Button = styled(StyledButton)`
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  min-width: 5rem;
  font-size: 0.9375rem;
  height: fit-content;
`;

Button.defaultProps = {
  as: "input",
  label: "Button",
  type: 'button',
  variant: "primary" 
};
