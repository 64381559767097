import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Flex, Image, Overlay, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

// import { registerSW } from "modules/serviceWorker";

/*
Public Key:
BLzkY-W7QnYME4awFBN0Ua_aj9ibmO-WaR5p0u-3I80zqpJK98SKLjdcHyImyTg2cDApAb2hbF7435vFcE1P1lM

Private Key:
v1aNlbhcR1h1Buy_Md54h0DMAye7sL0uEtukrS8Yf7I

*/

// Gerar keys com node_modules/web-push/src/cli.js generate-vapid-keys

const pushServerPublicKey =
  "BLzkY-W7QnYME4awFBN0Ua_aj9ibmO-WaR5p0u-3I80zqpJK98SKLjdcHyImyTg2cDApAb2hbF7435vFcE1P1lM";

async function createNotificationSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready.then(function (serviceWorker) {
    // subscribe and return the subscription
    return serviceWorker.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushServerPublicKey
      })
      .then(function (subscription) {
        return subscription;
      });
  });
}

const AskPermission = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state["account"].user);

  const saveNotificationSettings = (permission, subscription) => {
    let notifications = {};

    if (subscription && permission) {
      notifications.optin = true;
      notifications.subscriptions = [subscription];
      notifications.endpoints = [subscription.endpoint];
    } else {
      notifications.optin = false;
      notifications.subscriptions = [];
      notifications.endpoints = [];
    }

    dispatch(ACTIONS.account.setPreference("notifications", notifications));
    dispatch(
      ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
        preferences: { ...user.preferences, notifications }
      })
    );
  };

  Notification.requestPermission().then(function (permission) {
    if (permission !== "granted") {
      saveNotificationSettings(false);
      return null;
    }

    new Notification("Elefante says hi!");

    createNotificationSubscription().then((subscription) =>
      saveNotificationSettings(true, subscription)
    );
  });
  return (
    <>
      <Text textStyle="subtitle">
        Quer receber avisos de tarefas e conversas?
      </Text>

      <Image
        top="0"
        left="0"
        ml="1rem"
        mt="0.5em"
        mb="1.5em"
        src="/images/login/open.svg"
      />

      <Text textStyle="title">
        Clique em permitir para receber notificações!
      </Text>
    </>
  );
};

export const PushNotification = () => {
  const preferences = useSelector((state) => state["account"].preferences);

  const [askPermission, setAskPermission] = useState(false);

  useEffect(() => {}, []);

  const notificationCapable =
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  if (!notificationCapable) return null;

  const configured = ["denied", "granted"].includes(Notification.permission);

  return null;
  // if (configured && preferences.notifications.subscriptions.length !== 0)
  if (configured) return null;

  return (
    <Overlay open={true}>
      <Flex
        bg="l0"
        borderRadius="1rem"
        flexDirection="column"
        boxShadow="2px 3px 8px -2px rgba(0,0,0,0.40)"
        zIndex="1000"
        p="1rem"
        width="40rem"
        height="30rem"
        justifyContent="space-between"
        alignItems="center"
      >
        {!askPermission ? (
          <>
            <Text textStyle="subtitle">
              Quer receber avisos de tarefas e conversas?
            </Text>

            <Image
              top="0"
              left="0"
              ml="1rem"
              mt="0.5em"
              mb="1.5em"
              src="/images/login/open.svg"
            />

            <Flex justifyContent="center">
              <Button
                ml="0.5rem"
                variant="primary"
                onClick={() => setAskPermission(true)}
                value="Configurar notificações"
              />
            </Flex>
          </>
        ) : (
          <AskPermission />
        )}
      </Flex>
    </Overlay>
  );
};

export { createNotificationSubscription };
