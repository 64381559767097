import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Action,
  Avatar,
  Column,
  Flex,
  Editor,
  formatDate,
  Icon,
  Overlay,
  Reactions,
  Spinner,
  Text,
  orderObjects
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { API_ROOT, ROOT_URL } from "config";
import { DEFAULT_MARGIN } from "constant";
import { Header } from "components";

import NewTopic from "./NewTopic";

const Reply = ({ index, reply }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const saveReactions = (reactions) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topicreply/${reply.id}`,
        ACTIONS.state.loadObject,
        {
          reactions
        },
        (state, response) => {
          state.obj.replies[response.id] = response;
          return state;
        }
      )
    );
  };

  return (
    <>
      <Flex
        alignItems="flex-start"
        mt={index ? "1rem" : "2rem"}
        width={1}
        px="1rem"
      >
        <Avatar size="large" src={users[reply.created_by_id].avatar} />

        <Column ml="0.125rem" bg="l2" borderRadius="0.5rem" p="1rem" width={1}>
          <Text fontSize="0.875rem" fontWeight="semibold" color="t1">
            {reply.created_by_id === user.id
              ? t("You")
              : users[reply.created_by_id].name}{" "}
          </Text>

          <Editor
            editorStyle={{
              style: {
                padding: "rem",
                lineHeight: 1.5
              }
            }}
            enabled={false}
            value={reply.content}
            fileRootUrl={`${ROOT_URL}/shared/`}
            uploadEndpoint={`${API_ROOT}/file/upload?space_id=${reply.space_id}&topic_id=${reply.id}`}
          />

          <Flex
            mt="0.125rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontSize="0.75rem" color="t3">
              {formatDate(
                reply.creation_date,
                user.locale,
                user.timezone
              ).distance()}
            </Text>
            {false && <Action fontSize="0.75rem">Curtir</Action>}
            {false && <Action fontSize="0.75rem">Comentar</Action>}
          </Flex>
        </Column>
      </Flex>
    </>
  );
};

const Update = ({ lastRead, topic }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const replies = useSelector((state) => state["topic"].replies[topic.id]);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const history = useHistory();

  const [open, setOpen] = useState(true);

  const saveReactions = (reactions) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topic/${topic.id}`,
        ACTIONS.state.loadObject,
        {
          reactions
        },
        (state, response) => {
          let index = state.feed.findIndex((obj) => obj.id === response.id);
          state.feed[index] = response;

          return state;
        }
      )
    );
  };

  const loadReplies = () => {
    dispatch(
      ACTIONS.fetch.get(
        "topic",
        `${API_ENDPOINT}/topicreply?topic_id=${topic.id}&limit=0`,
        ACTIONS.state.loadObject,
        (state, response) => {
          state.replies[topic.id] = response.objects;
          return state;
        }
      )
    );
  };

  return (
    <Flex
      borderRadius="0.5rem"
      px="0.75rem"
      py="0.75rem"
      mb="1rem"
      bg="l0"
      flexDirection="column"
      boxShadow="0px 0px 4px 0px rgba(0,0,0,0.18)"
    >
      <Flex alignItems="center">
        <Avatar size="x-large" src={users[topic.created_by_id].avatar} />

        <Column ml="0.375rem">
          <Text fontSize="0.875rem" fontWeight="semibold" color="t1">
            {users[topic.created_by_id].name}
          </Text>

          <Flex alignItems="center" mt="0.125rem">
            <Text fontSize="0.75rem" color="t3" fontWeight="semibold">
              {topic.category__name}
            </Text>
            <Text ml="0.25rem" fontSize="0.675rem" color="t3">
              ({topic.space__name}){", "}
              {formatDate(
                topic.creation_date,
                user.locale,
                user.timezone
              ).distance()}
            </Text>

            {topic.last_reply_from_id && (
              <Flex ml="1rem" alignItems="center">
                <Icon.Reply size="14px" />
                <Text ml="0.25rem" fontSize="0.75rem" color="t3">
                  {users[topic.last_reply_from_id].nickname} {t("commented")}{" "}
                  {formatDate(
                    topic.update_date,
                    user.locale,
                    user.timezone
                  ).distance()}
                </Text>
              </Flex>
            )}
          </Flex>
        </Column>
      </Flex>

      <Column px="1.5rem">
        <Text mt="1.5rem" mb="1rem" textStyle="subtitle">
          {topic.title}
        </Text>

        <Editor
          enabled={false}
          value={topic.content}
          fileRootUrl={`${ROOT_URL}/shared/`}
          uploadEndpoint={`${API_ROOT}/file/upload?space_id=${topic.space_id}&topic_id=${topic.id}`}
          editorStyle={{
            style: {
              margin: "0rem 0rem",
              padding: "0",
              lineHeight: 1.5
            }
          }}
        />
      </Column>

      <Flex
        mt="1.5rem"
        alignItems="center"
        justifyContent="space-between"
        width={1}
        pb={open && replies && replies.length ? "0.5rem" : ""}
        borderBottom={
          open && replies && replies.length ? "1px solid lightGrey" : ""
        }
      >
        <Reactions
          user={user}
          users={users}
          reactions={topic.reactions}
          onSelect={saveReactions}
          size={14}
          singleReactions={false}
        />

        <Flex>
          <Action
            onClick={() => {
              history.push(
                `/topic/${topic.space_id}/${topic.category_id}/${topic.id}`
              );
            }}
          >
            {t("Comment")}
          </Action>
          <Action onClick={() => (replies ? setOpen(!open) : loadReplies())}>
            {topic.replies_count} {t("comments")}
          </Action>
        </Flex>
      </Flex>

      {open &&
        replies &&
        replies.map((reply, index) => <Reply reply={reply} index={index} />)}
    </Flex>
  );
};

export const Timeline = ({ categoryId, spaceId, lastRead, setLastRead }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const topics = useSelector((state) => state["topic"].feed);

  const [refresh, setRefresh] = useState(true);
  const [newTopic, setNewTopic] = useState(false);

  let orderedTopics = topics
    ? orderObjects(topics, "update_date", "desc")
    : null;

  useEffect(() => {
    if (!refresh) return;
    dispatch(
      ACTIONS.fetch.get(
        "topic",
        `${API_ENDPOINT}/feed`,
        ACTIONS.state.loadObject,
        (state, response) => {
          state.feed = response.objects;
          return state;
        }
      )
    );

    setRefresh(false);
  }, [dispatch, refresh]);

  if (!topics) return <Spinner />;

  let pinned = orderedTopics.filter((topic) => topic.pinned);
  let notPinned = orderedTopics.filter((topic) => !topic.pinned);

  orderedTopics = [...pinned, ...notPinned];

  return (
    <>
      <Overlay
        open={newTopic}
        setOpen={setNewTopic}
        maxWidth={800}
        borderRadius="0.5rem"
        bg="l0"
        p={DEFAULT_MARGIN}
        flexDirection="column"
      >
        <NewTopic
          open={newTopic}
          setOpen={setNewTopic}
          spaceId={spaceId}
          categoryId={categoryId}
        />
      </Overlay>

      <Header>
        <Text textStyle="subtitle">{t("Feed")}</Text>
      </Header>

      <Flex flexDirection="column" pb="5rem">
        {orderedTopics.map((topic, index) => (
          <Update topic={topic} key={topic.id} />
        ))}
      </Flex>
    </>
  );
};
