import React from "react";
import styled, { css, keyframes } from "styled-components";

import { Flex } from "tomato";

const pulse = keyframes`
{
    0% {transform: scale(0.1, 0.1); opacity: 0.2;}
	50% {opacity: 1.0;}
	100% {transform: scale(1.0, 1.0); opacity: 0.2;}     
}
`;

const pulsate = (props) =>
  css`
    ${pulse} 1s ease-out infinite;
  `;

const Animate = styled(Flex)`
  animation: ${pulsate};
`;

export const PulsingDot = ({ ...props }) => {
  return (
    <Animate {...props}>
      <Flex
        borderRadius="100%"
        minHeight="0.75rem"
        minWidth="0.75rem"
        bg="red"
      />
    </Animate>
  );
};
