import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const ChartLine = ({ data, legend = false, tooltip = true, height = 200 }) => {
  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart data={data.data}>
        <CartesianGrid />
        <XAxis dataKey={data.key} />
        <YAxis />
        {tooltip && <Tooltip />}
        {legend && <Legend />}

        {data.categories.map((category, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={category}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export { ChartLine as LineChart };
