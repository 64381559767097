import React from "react";
import { Transforms } from "slate";

import { EmojiPicker } from "../../EmojiPicker";

export const Emoji = ({ editor }) => {
  const insertEmoji = (emojiId) => {
    const text = { text: "" };

    const emoji = {
      type: "emoji",
      id: emojiId,
      children: [text]
    };

    Transforms.insertNodes(editor, emoji);
  };

  return <EmojiPicker onSelect={insertEmoji} />;
};

export const withEmoji = (editor) => {
  const { isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "emoji" ? true : isInline(element);
  };

  return editor;
};
