import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

import { LineChart, PieChart } from "components";
import { SmallWidget, LargeWidget } from "./Widgets";

export const Accounts = () => {
  const { t } = useTranslation();

  const dailyActiveAccount = useSelector(
    (state) => state["charts"]["dailyActiveAccount"]
  );
  const monthlyActiveAccount = useSelector(
    (state) => state["charts"]["monthlyActiveAccount"]
  );
  const total = useSelector((state) => state["charts"]["total"]);

  const [daa, setDaa] = useState();
  const [maa, setMaa] = useState();
  const [daaMaa, setDaaMaa] = useState();
  const [daaMaaData, setDaaMaaData] = useState();

  useEffect(() => {
    if (!(dailyActiveAccount && monthlyActiveAccount)) return;
    let dailyCount = dailyActiveAccount.last.count;
    let dailyMedium = dailyActiveAccount.medium;
    let monthlyCount = monthlyActiveAccount.last.count;
    let monthlyMedium = monthlyActiveAccount.medium;

    setDaa({ count: dailyCount, variation: dailyCount / dailyMedium });
    setMaa({ count: monthlyCount, variation: monthlyCount / monthlyMedium });
    setDaaMaa({
      count: dailyCount / monthlyCount,
      variation: dailyCount / monthlyMedium
    });

    let tmp = JSON.parse(JSON.stringify(dailyActiveAccount));
    tmp.data.forEach((day) => {
      day.count = day.count / monthlyCount;
    });
    setDaaMaaData(tmp);
  }, [dailyActiveAccount, monthlyActiveAccount]);

  return (
    <>
      <Text fontSize="1rem" fontWeight="semibold" mt="2rem" mb="1rem">
        {t("Accounts")}
      </Text>

      <Flex width={1} mr="1rem" justifyContent="space-between">
        <SmallWidget
          title={t("Accounts")}
          value={total && { count: total.accounts }}
        />

        <SmallWidget
          mx="2rem"
          title="DAA"
          value={daa}
          newValue={15}
          oldValue={10}
        >
          <PieChart
            data={
              daa && total && total.accounts
                ? [
                    { label: "active", value: daa.count, key: "lablel" },
                    {
                      label: "accounts",
                      value: total.accounts - daa.count,
                      key: "lablel"
                    }
                  ]
                : []
            }
          />
        </SmallWidget>

        <SmallWidget
          mr="2rem"
          title={t("MAA")}
          value={maa}
          newValue={3}
          oldValue={4}
        >
          <PieChart
            data={
              daa && total && total.accounts
                ? [
                    { label: "active", value: maa.count, key: "lablel" },
                    {
                      label: "maa",
                      value: total.accounts - maa.count,
                      key: "label"
                    }
                  ]
                : []
            }
          />
        </SmallWidget>

        <SmallWidget
          title={t("DAA/MAA")}
          value={daaMaa}
          newValue={18}
          oldValue={12}
        >
          <PieChart
            data={
              daa && total && total.accounts
                ? [
                    { label: "daa", value: daa.count, key: "lablel" },
                    {
                      label: "maa",
                      value: maa.count - daa.count,
                      key: "lablel"
                    }
                  ]
                : []
            }
          />
        </SmallWidget>
      </Flex>

      <Flex mt="2rem">
        <LargeWidget width={1 / 3} title={t("Daily active accounts")}>
          <LineChart data={dailyActiveAccount} />
        </LargeWidget>

        <LargeWidget
          mx="2rem"
          width={1 / 3}
          title={t("Monthly active accounts")}
        >
          <LineChart data={monthlyActiveAccount} />
        </LargeWidget>

        <LargeWidget width={1 / 3} title={t("Daily/Monthly active accounts")}>
          <LineChart data={daaMaaData} />
        </LargeWidget>
      </Flex>
    </>
  );
};
