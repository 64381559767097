import React from "react";

import Icon from "../Icon";

const RenderIcon = props => {
  return (
    <Icon {...props}>
    <path d="M22.4,6.8c0,2.6-2.1,4.6-4.6,4.6s-4.6-2.1-4.6-4.6c0-0.8,0.2-1.5,0.5-2.2c0-0.1,0.1-0.2,0.1-0.2c0.8-1.3,2.3-2.2,4-2.2C20.3,2.2,22.4,4.3,22.4,6.8z"/>
	<path d="M12.8,19.8v-2.6H1.6v2.6c0,1.1,0.9,2,2,2h11.2C13.7,21.8,12.8,20.9,12.8,19.8z"/>
	<polyline points="13.8,4.2 4.3,4.2 4.3,17.2 	"/>
	<path d="M14.2,21.8h1.7c1.1,0,2-0.9,2-2v-8.3"/>
    <line x1="17.5" y1="4.4" x2="17.5" y2="9.6"/>
	<path d="M18.5,5.3H17c-0.4,0-0.7,0.4-0.7,0.8C16.4,6.6,16.7,7,17,7h1c0.4,0,0.7,0.4,0.7,0.8c0,0.5-0.3,0.8-0.7,0.8h-1.7"/>
    </Icon>
  );
};

export default RenderIcon;
