import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Emoji, Flex, formatDate, Text } from "tomato";

import { ACTIONS, STATE } from "store";
import { API_ENDPOINT } from "config";
import { EMPTY_CONTENT } from "constant";
import { DEFAULT_MARGIN } from "constant";
import { Files } from "./Files";
import { Header, MainWindow, SideMenu } from "components";
import { Page } from "./Page";
import { Related } from "./Related";
import { SpacesMenu } from "modules";

const Card = ({ page }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const spaceId = useSelector((state) => state["pages"].spaceId);
  const menu = useSelector((state) => state["pages"].menu);
  const item = useSelector((state) => state["pages"].menu[spaceId]);

  const pages = useSelector((state) => state["pages"].pages);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  return (
    <>
      <Header>
        <Text textStyle="subtitle">{t("Pages")}</Text>
      </Header>

      <Flex mb="2rem" flexWrap="wrap">
        {item &&
          item.items &&
          item.items.map((uuid) => {
            const page = pages[menu[uuid].id];

            return (
              <Flex
                mb="2rem"
                mx="1rem"
                key={page.id}
                bg="l2"
                borderRadius="0.5rem"
                width="16rem"
                height="8rem"
                flexDirection="column"
                p="1rem"
                justifyContent="space-between"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  menu[uuid].onOpen && menu[uuid].onOpen(menu[uuid]);
                  dispatch(
                    ACTIONS.state.changeState("pages", (state) => {
                      state.item = menu[uuid];
                      state.spaceId = null;
                      return state;
                    })
                  );
                }}
              >
                <Flex alignItems="center">
                  {page.icon.type && (
                    <Emoji mr="0.5rem" code={page.icon.code} size="1.5rem" />
                  )}
                  <Text textStyle="subtitle">
                    {page.title || t("Untitled")}
                  </Text>
                </Flex>
                <Flex>
                  <Avatar src={users[page.created_by_id].avatar} />
                  <Text ml="0.25rem" color="t3" fontSize="0.875rem">
                    {users[page.created_by_id].name},{" "}
                    {formatDate(
                      page.update_date || page.creation_date,
                      user.locale,
                      user.timezone
                    ).distance()}
                  </Text>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
    </>
  );
};

export const Pages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const item = useSelector((state) => state["pages"].item);
  const spaceId = useSelector((state) => state["pages"].spaceId);
  const spaces = useSelector((state) => state["spaces"].spaces);

  useEffect(() => {
    document.title = "Wiki";
    return () => {
      document.title = "Elefante";
    };
  }, []);

  const spacesActions = {};
  const spaceActions = [
    {
      label: t("New page"),
      icon: "Plus",
      action: (item) => {
        newPage(item);
      }
    }
  ];

  const newPage = (item) => {
    const spaceId = item.spaceId;

    const data = { space_id: spaceId, content: EMPTY_CONTENT };
    if (item.parentId) data.parent_id = item.id;

    dispatch(
      ACTIONS.fetch.post(
        "pages",
        `${API_ENDPOINT}/page`,
        ACTIONS.state.loadObjects,
        data,
        (state, response) => {
          let page = { ...response };

          state.pages[response.id] = page;
          state.editingPage = true;

          let uuid = `${item.uuid}.${page.id}`;

          if (!state.menu[item.uuid].items) state.menu[item.uuid].items = [];

          state.menu[item.uuid].items.push(uuid);

          let newItem = {
            id: page.id,
            items: null,
            name: page.title || t("Untitled"),
            parentUuid: item.uuid,
            uuid,
            spaceId: item.spaceId,
            actions: spaceActions,
            onSelect: (item) => {
              loadPages(item);
            },
            onOpen: (item) => loadPages(item)
          };

          state.spaceId = null;
          state.menu[uuid] = newItem;
          state.item = newItem;

          return state;
        }
      )
    );
  };

  const loadPages = (item) => {
    const menu = STATE().pages.menu;

    dispatch(
      ACTIONS.state.changeState("pages", (state) => {
        state.item = item;
        state.spaceId = null;
        state.editingPage = false;
        return state;
      })
    );

    if (menu[item.uuid].items) return;

    const uuid = item.uuid;

    console.log("1", uuid);
    const url = `${API_ENDPOINT}/page?parent_id=${item.id}&limit=0`;

    dispatch(
      ACTIONS.fetch.get(
        "pages",
        url,
        ACTIONS.state.loadObjects,
        (state, response) => {
          const pages = response.objects;
          pages.forEach((page) => {
            state.pages[parseInt(page.id)] = {
              ...page,
              uuid: `${uuid}.${page.id}`
            };

            if (!state.menu[uuid]) {
              state.menu[uuid] = { items: [] };
            } else {
              if (!state.menu[uuid].items) state.menu[uuid].items = [];
            }

            state.menu[uuid].items.push(`${uuid}.${page.id}`);

            state.menu[`${uuid}.${page.id}`] = {
              id: page.id,
              items: null,
              spaceId: item.spaceId,
              parentId: item.id,
              name: page.title || t("Untitled"),
              parentUuid: uuid,
              uuid: `${uuid}.${page.id}`,
              actions: spaceActions,
              onSelect: (item) => {
                loadPages(item);
              },
              onOpen: (item) => loadPages(item)
            };
          });

          if (pages.length)
            state.menu[uuid].items = [...new Set(state.menu[uuid].items)];

          return state;
        }
      )
    );
  };

  const loadSpacePages = (spaceId) => {
    const menu = STATE().pages.menu;
    if (menu[spaceId].items) return;

    const url = `${API_ENDPOINT}/page?space_id=${spaceId}&parent_id__isnull= True&limit=0`;

    console.log("HERE");
    dispatch(
      ACTIONS.fetch.get(
        "pages",
        url,
        ACTIONS.state.loadObjects,
        (state, response) => {
          response.objects.forEach((page) => {
            state.pages[parseInt(page.id)] = {
              ...page,
              uuid: `${spaceId}.${page.id}`
            };

            if (!state.menu[spaceId].items) state.menu[spaceId].items = [];

            state.menu[spaceId].items.push(`${spaceId}.${page.id}`);
            state.spaceId = spaceId;

            state.menu[`${spaceId}.${page.id}`] = {
              id: page.id,
              items: null,
              spaceId: spaceId,
              parentId: spaceId,
              name: page.title || t("Untitled"),
              parentUuid: spaceId,
              uuid: `${spaceId}.${page.id}`,
              actions: spaceActions,
              onSelect: (item) => {
                loadPages(item);
              },
              onOpen: (item) => loadPages(item)
            };
          });

          // state.menu[spaceId].items = [...new Set(state.menu[spaceId].items)];

          return state;
        }
      )
    );
  };

  Object.values(spaces).forEach((space) => {
    spacesActions[space.id] = {
      ...space,
      parentId: null,
      spaceId: space.id,
      actions: spaceActions,
      onSelect: (item) => {
        dispatch(
          ACTIONS.state.changeState("pages", (state) => {
            state.item = null;
            state.spaceId = space.id;
            return state;
          })
        );

        loadSpacePages(space.id);
      },
      onOpen: (item) => loadSpacePages(space.id),
      items: null
    };
  });

  return (
    <Flex width={1} height="100%" label="Pages">
      <SideMenu p={DEFAULT_MARGIN}>
        <Text textStyle="title">{t("Wiki")}</Text>

        <SpacesMenu
          mt={DEFAULT_MARGIN}
          module="pages"
          showPublic={false}
          selectFirst={true}
          levels={100}
          spacesActions={spacesActions}
          placement="right"
        />
      </SideMenu>

      <MainWindow>
        {item && <Page key={item.uuid} />}
        {spaceId && <Card />}
      </MainWindow>

      {false && (
        <SideMenu>
          {item && <Files />}
          {item && <Related uuid={item.uuid} />}
        </SideMenu>
      )}
    </Flex>
  );
};
