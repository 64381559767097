import React from "react";

import Icon from "../Icon";

const ChevronDown = props => {
  return (
    <Icon {...props}>
      <polyline points="6 9 12 15 18 9" />
    </Icon>
  );
};

export default ChevronDown;
