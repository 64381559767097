import { ACTIONS } from "tomato";

import { actions as app } from "modules/App/reducers";
import { eventActions as events } from "./reducers/event";
import { actions as files } from "modules/Files/reducers";
import { actions as documents } from "modules/Documents/reducers";
import { actions as forms } from "modules/Forms/reducers";
import { actions as reminders } from "modules/Reminders/reducers";
import { actions as spaces } from "modules/Spaces/reducers";
import { taskActions as tasks } from "../modules/Tasks/reducers";
import { actions as topics } from "modules/Topics/reducers";
import { conversationsActions as conversations } from "../modules/Conversations/reducers";
import { actions as updates } from "modules/Updates/reducers";

const SYSTEM_ACTIONS = {
  ...ACTIONS,
  app,
  conversations,
  documents,
  events,
  files,
  forms,
  reminders,
  spaces,
  tasks,
  topics,
  updates
};

export { SYSTEM_ACTIONS as ACTIONS };
