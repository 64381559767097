import { createActions } from "redux-actions";

const options = { prefix: "fetch", namespace: "::" };

export const fetchActions = createActions(
  {
    GET: (module, url, nextAction, custom, withCredentials = true) => ({
      module,
      url,
      nextAction,
      custom,
      method: "GET",
      withCredentials
    }),

    DELETE: (module, url, nextAction, custom, withCredentials = true) => ({
      module,
      url,
      nextAction,
      custom,
      method: "DELETE",
      withCredentials
    }),

    PATCH: (module, url, nextAction, body, custom, withCredentials = true) => ({
      module,
      url,
      nextAction,
      body,
      custom,
      method: "PATCH",
      withCredentials
    }),

    PUT: (module, url, nextAction, body, custom, withCredentials = true) => ({
      module,
      url,
      body,
      nextAction,
      custom,
      method: "PUT",
      withCredentials
    }),

    POST: (module, url, nextAction, body, custom, withCredentials = true) => ({
      module,
      url,
      nextAction,
      body,
      custom,
      method: "POST",
      withCredentials
    })
  },
  options
);
