import React, { useEffect, useRef, useState } from "react";
import { Node } from "slate";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, SimpleEditor, strip, Text, uuidv4 } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, ROOT_URL } from "config";

const dot = keyframes` 
{
 15% {
   transform: translateY(-35%);
   opacity: 0.5;
 }
 30% {
   transform: translateY(0%);
   opacity: 1;
 }
`;

export const Dots = styled(Flex)`
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-right: 3px;
  display: inline-block;
  background-color: #5c5e69;
  animation: 1.2s ${dot} ease-in-out infinite;
  &:nth-of-type(2) {
    animation-delay: 0.15s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.25s;
  }
`;

const Typing = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );
  const conversation = useSelector(
    (state) => state["conversations"].conversations[activeConversation]
  );
  const typing = useSelector((state) => state["conversations"].typing);
  const users = useSelector((state) => state["spaces"].users);
  const user = useSelector((state) => state["account"].user);

  const [isTyping, setIsTyping] = useState(false);

  const timer = useRef();
  const userRef = useRef();

  useEffect(() => {
    setIsTyping(false);
    window.clearTimeout(timer.current);
  }, [activeConversation, conversation]);

  useEffect(() => {
    if (!typing || !activeConversation) return;

    let [type, other] = typing.acc.split(".");
    let fromId = typing.fromId;

    if (fromId === user.id) return;
    if (type !== activeConversation.split(".")[0]) return;
    if (type === "S" && activeConversation.split(".")[1] !== other) return;

    if (type.startsWith("D")) {
      userRef.current = users[fromId].nickname;
    } else {
      if (parseInt(fromId) === parseInt(user.id)) return;
    }

    userRef.current = users[fromId].nickname;

    dispatch(ACTIONS.conversations.clearTyping());

    setIsTyping(true);

    window.clearTimeout(timer.current);

    timer.current = window.setTimeout(() => {
      window.clearTimeout(timer);
      setIsTyping(false);
      userRef.current = "";
    }, 6000);
  }, [activeConversation, dispatch, typing, user.id, users]);

  return (
    <Flex ml="3rem" alignItems="center" minHeight="1.5rem">
      {isTyping && (
        <>
          <Dots />
          <Dots />
          <Dots />
          <Text
            ml="0.5rem"
            fontSize="0.675rem"
            fontWeight="semibold"
            color="t4"
          >
            {userRef.current} {t("is typing")}
          </Text>
        </>
      )}
    </Flex>
  );
};

export const Input = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );

  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const ws = useSelector((state) => state["ws"].ws);

  const [keyRef, setKeyRef] = useState(uuidv4());

  const count = useRef(0);
  const isTyping = useRef(false);
  const value = useRef(null);
  const timer = useRef();

  let members = [];
  let uuid = activeConversation.split(".");
  let [type, toId] = uuid;
  if (type === "D") {
    members = uuid.slice(1).filter((id) => parseInt(id) !== user.id);
    toId = members[0];
  } else if (type === "S") {
    members = spaces[toId].members
      .map((member) => member.id)
      .filter(
        (id) => id !== user.id && !users[id].is_deleted && users[id].is_active
      );
  }

  members = members.map((id) => {
    return { id, value: users[id].nickname };
  });

  let emptyContent = [
    {
      type: "paragraph",
      children: [{ text: "" }]
    }
  ];

  const typing = (content) => {
    if (!value.current) {
      value.current = JSON.stringify(emptyContent);
    }
    if (JSON.stringify(content) === value.current) return;

    if (count.current < 3 && !isTyping.current) {
      count.current += 1;
      return;
    }

    if (!isTyping.current) {
      ws.send(
        JSON.stringify({
          type: "conversations",
          action: "typing",
          data: {
            acc: activeConversation,
            toId
          }
        })
      );

      isTyping.current = true;

      timer.current = window.setTimeout(() => {
        isTyping.current = false;
        count.current = 0;
      }, 2000);
    }
  };

  const saveMessage = (message, mentions) => {
    const msgUuid = uuidv4();
    setKeyRef(msgUuid);

    const payload = {
      type: "conversations",
      action: "message",
      data: {
        acc: activeConversation,
        userId: user.id,
        message: JSON.stringify(message),
        fromName: user.name,
        mentions,
        summary: strip(message.map((n) => Node.string(n)).join(" ")).slice(
          0,
          50
        ),
        msgUuid
      }
    };

    dispatch(
      ACTIONS.conversations.insertMessage({
        ...payload.data,
        currentUserId: user.id,
        fromId: user.id,
        timestap: Date.now(),
        creationDate: new Date()
      })
    );
    ws.send(JSON.stringify(payload));
  };

  let messageTo = type === "D" ? users[toId].name : spaces[toId].name;

  return (
    <>
      <Flex
        flexDirection="column"
        mb="0.5rem"
        // position="absolute"

        bottom={0}
        width={1}
        bg="white"
        key={keyRef + uuid}
        px="1rem"
      >
        <SimpleEditor
          autoFocus={true}
          flexGrow="1"
          placeholder={`${t("Message")} ${t("to")} ${messageTo}`}
          maxHeight="50rem"
          enabled={true}
          enableToolbar={true}
          onEnter={saveMessage}
          toolbarPosition="BOTTOM"
          fileRootUrl={`${ROOT_URL}/shared/`}
          uploadEndpoint={`${API_ENDPOINT}/upload`}
          uploadId={{ module: "CONVERSATION", uuid: activeConversation }}
          onChange={typing}
          clearOnEnter={true}
          value={emptyContent}
          mentionOptions={members}
        />
      </Flex>

      {!ws && (
        <Flex
          position="absolute"
          bottom={0}
          zIndex={99}
          alignItems="center"
          minHeight="8rem"
          width={1}
          // bg="lightBlue"
          justifyContent="center"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <Dots />
          <Dots />
          <Dots />
          <Text ml="0.5rem">{t("Connecting")}</Text>
        </Flex>
      )}
    </>
  );
};
