export const mentionsExtract = (nodes) => {
  let mentionsIds = [];
  nodes.map((element) => {
    element.children.map((content) => {
      if (content.type === "mention" && content.id)
        mentionsIds.push(content.id);
    });
  });

  return mentionsIds;
};
