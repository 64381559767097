import { mergeMap, withLatestFrom } from "rxjs/operators";

import { ACTIONS } from "../reducers/actions";

export const networkEpic = (action$, state$, { dispatch }) => {
  return action$.ofType("network::RUN_PENDING_ACTIONS").pipe(
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      let runAction;
      let pendingActions = [...state["network"].pendingActions];

      while (pendingActions.length !== 0) {
        runAction = pendingActions.shift();
        dispatch(ACTIONS.network.removePendingAction());
        if (typeof runAction !== "undefined") dispatch(runAction);
      }
      return [];
    })
  );
};
