import { createActions, handleActions } from "redux-actions";

const modelId = "app";

const options = { prefix: "app", namespace: "::" };

export const actions = createActions(
  {
    CLEAN: (resource) => ({
      module: modelId
    }),

    LOADED_RESOURCE: (resource) => ({
      module: modelId,
      resource
    }),

    PONG: ({ ts }) => ({
      module: modelId,
      ts
    })
  },
  options
);

const reducer = handleActions(
  {
    CLEAN: (state, { payload: { resource } }) => {
      state.loadedResources = [];
      return state;
    },

    LOADED_RESOURCE: (state, { payload: { resource } }) => {
      state.loadedResources.push(resource);
      return state;
    },

    PONG: (state, { payload: { ts } }) => {
      // Se o último contato foi há mais de 30s, atualizo os dados
      if (!state.shouldRefresh && state.ts && ts - state.ts > 30000) {
        state.shouldRefresh = true;
        state.loadedResources = [];
      }
      state.ts = ts;

      return state;
    }
  },
  {},
  options
);

export default reducer;
