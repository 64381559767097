import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react/headless";

import {
  Avatar,
  EmojiPicker,
  Flex,
  Image,
  Text,
  Tippy as Tooltip
} from "tomato";

const Single = ({ placement, reactions, user, users, updateReaction }) => {
  return (
    <Flex alignItems="center" height="2rem">
      {reactions &&
        reactions.length > 0 &&
        reactions.map((reaction, index) => {
          return (
            <Fragment key={index}>
              {reaction.users.map((userId, index) => (
                <Tooltip
                  key={index}
                  content={users[userId].nickname}
                  placement="top"
                  theme="dark"
                >
                  <Flex
                    bg="l2"
                    borderRadius="2rem"
                    mr="0.5rem"
                    alignItems="center"
                    pr="0.5rem"
                    style={user.id === userId ? { cursor: "pointer" } : {}}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      user.id === userId &&
                        updateReaction(reactions, reaction.code);
                    }}
                  >
                    <Avatar
                      key={userId}
                      width="1.5rem"
                      height="1.5rem"
                      src={users[userId].avatar}
                    />
                    <Image
                      ml="0.375rem"
                      width="0.875rem"
                      minWidth="0.875rem"
                      height="0.875rem"
                      minHeight="0.875rem"
                      src={`https://static.elefante.com/emojis/${reaction.code}.png`}
                    />
                  </Flex>
                </Tooltip>
              ))}
            </Fragment>
          );
        })}
    </Flex>
  );
};

const Consolidated = ({ reactions, placement='right', size, updateReaction, user , users}) => {
  const { t } = useTranslation();

  const showUsers = (reaction) => {
    let count = reaction.users.length;
    let content = "";

    if (reaction.users.includes(user.id)) {
      content = t("You");
      count -= 1;
    }

    reaction.users.map((userId, index) => {
      if (userId === user.id) return null;

      if (index < count) {
        content += ", ";
      } else {
        content += ` ${t("and")} `;
      }
      content += users[userId].nickname;
      return <Text key={index}>{content}</Text>;
    });

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        py="0.5rem"
        px="1rem"
        bg="black"
        borderRadius="0.5rem"
      >
        <Image
          width="1.5rem"
          height="1.5rem"
          src={`https://static.elefante.com/emojis/${reaction.code}.png`}
        />
        <Text fontSize="0.75rem" mt="0.25rem" fontWeight="bold" color="white">
          {content}
        </Text>
      </Flex>
    );
  };
  return (
    <Flex>
      {reactions &&
        reactions.length > 0 &&
        reactions.map((reaction, index) => {
          return (
            <Tippy
              key={index}
              placement={placement}
              interactive={true}
              render={() => showUsers(reaction)}
            >
              <Flex
                alignItems="center"
                key={reaction.code}
                bg={reaction.users.includes(user.id) ? "lightBlue" : "l2"}
                border={
                  reaction.users.includes(user.id) ? "1px solid blue" : ""
                }
                borderRadius="1rem"
                px="0.375rem"
                py="0.125rem"
                mr={2}
                my={1}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  updateReaction(reactions, reaction.code);
                }}
              >
                <Image
                  mr="0.25rem"
                  width={size}
                  minWidth={size}
                  height={size}
                  minHeight={size}
                  src={`https://static.elefante.com/emojis/${reaction.code}.png`}
                />

                <Text
                  fontWeight={
                    reaction.users.includes(user.id) ? "semibold" : "regular"
                  }
                  color={reaction.users.includes(user.id) ? "blue" : "t3"}
                  fontSize="0.75rem"
                >
                  {reaction.users.length}
                </Text>
              </Flex>
            </Tippy>
          );
        })}
    </Flex>
  );
};

export const Reactions = ({
  reactions = [],
  singleReactions = true,
  onSelect,
  user,
  users,
  size = 16,
  placement='top',
  ...props
}) => {
  const updateReaction = (reactions, code) => {
    let added = true;
    let newReactions = [...reactions];

    let reactionIndex = newReactions.findIndex((emoji) => emoji.code === code);

    if (reactionIndex === -1) {
      newReactions = [...newReactions, { code, users: [user.id] }];
    } else {
      let existingReaction = { ...newReactions[reactionIndex] };
      existingReaction.users = [...existingReaction.users];

      if (existingReaction.users.includes(user.id)) {
        added = false;
        let userIndex = existingReaction.users.findIndex(
          (userId) => userId === user.id
        );
        existingReaction.users.splice(userIndex, 1);

        if (existingReaction.users.length === 0) {
          newReactions.splice(reactionIndex, 1);
        } else newReactions[reactionIndex] = existingReaction;
      } else {
        existingReaction.users.push(user.id);
        newReactions[reactionIndex] = existingReaction;
      }
    }

    onSelect && onSelect({ reactions: newReactions, added, code });
  };

  return (
    <Flex alignItems="center" height="2rem" {...props}>
      {singleReactions ? (
        <Single
          reactions={reactions}
          users={users}
          updateReaction={updateReaction}
          user={user}
          size={size}
          placement={placement}
        />
      ) : (
        <Consolidated
          reactions={reactions}
          updateReaction={updateReaction}
          user={user}
          users={users}
          size={size}
          placement={placement}
        />
      )}
      <EmojiPicker onSelect={(code) => updateReaction(reactions, code)} />
    </Flex>
  );
};
