import React from "react";

import { RoundedAction } from "../Flex";
import { Icon } from "../Icon";

export const IconAction = ({ icon, size = 1, ...props }) => {
  const RenderIcon = icon ? Icon[icon] : Icon.Plus;
  const stroke = props.stroke ?  {stroke: props.stroke} : {}
    

  return (
    <RoundedAction {...props}>
      <RenderIcon m={`${0.2 * size}rem`} size={`${size}rem`} {...stroke}/>
    </RoundedAction>
  );
};
