import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Dropdown, Flex, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

const Where = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const task = useSelector((state) => state["tasks"].obj);

  const [selectedProjectId, setSelectedProjectId] = useState(
    lists[task.list_id].project_id
  );
  const [selectedSpaceId, setSelectedSpaceId] = useState(
    projects[lists[task.list_id].project_id].space_id
  );
  const [selectedListId, setSelectedListId] = useState(task.list_id);

  const [listsOptions, setListsOptions] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);

  const [state, setState] = useState({ enabled: true, error: "" });

  useEffect(() => {
    setSelectedListId(task.list_id);
    setSelectedProjectId(lists[task.list_id].project_id);
    setSelectedSpaceId(projects[lists[task.list_id].project_id].space_id);
  }, [lists, projects, task]);

  useEffect(() => {
    if (!selectedProjectId) return;
    setListsOptions(
      Object.values(lists)
        .filter((list) => list.project_id === selectedProjectId)
        .map((list) => {
          return { id: list.id, value: list.name };
        })
    );
  }, [lists, selectedProjectId]);

  useEffect(() => {
    setProjectsOptions(
      Object.values(projects)
        .filter(
          (project) =>
            project.space_id === selectedSpaceId &&
            project.lists_ids.length !== 0
        )
        .map((project) => {
          return { id: project.id, value: project.name };
        })
    );
  }, [projects, selectedSpaceId]);

  useEffect(() => {
    setState({
      enabled: selectedListId ? true : false
    });
  }, [selectedListId]);

  useEffect(() => {
    if (task.list_id === selectedListId || !selectedListId) return;

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { group_id: selectedListId }
      )
    );
  }, [dispatch, selectedListId, task]);

  useEffect(() => {
    let members = spaces[selectedSpaceId].members.map((member) => member.id);

    if (
      task.created_by_id &&
      !projects[selectedProjectId].public &&
      !members.includes(task.created_by_id)
    ) {
      setState({
        enabled: false,
        error: t(
          "Owner does not belong to this space. Please choose another space."
        )
      });
      return;
    }

    if (task.owner_id && !members.includes(task.owner_id)) {
      setState({
        enabled: false,
        error: t(
          "Assignee dos not belong to this space. Please choose another space or remove assignee"
        )
      });
      return;
    }

    if (!selectedListId) {
      setState({
        enabled: false,
        error: t("Missing list")
      });
      return;
    }

    setState({ enabled: true });
  }, [
    projects,
    selectedListId,
    selectedProjectId,
    selectedSpaceId,
    spaces,
    t,
    task
  ]);

  let spacesOptions = Object.keys(spaces).map((spaceId) => {
    return { id: spaceId, value: spaces[spaceId].name };
  });

  return (
    <Flex flexDirection="column">
      <Flex mx="auto" width={1}>
        <Dropdown
          width={1}
          minWidth="8rem"
          label={t("Space")}
          key={selectedSpaceId}
          disabled={true}
          options={spacesOptions}
          onSelect={(option) => {
            setSelectedSpaceId(parseInt(option.id));
            setSelectedProjectId();
            setSelectedListId();
          }}
          placeholder={t("Choose space")}
          selected={
            selectedSpaceId
              ? {
                  id: selectedSpaceId,
                  value: spaces[selectedSpaceId].name
                }
              : null
          }
        />

        {selectedSpaceId && (
          <Dropdown
            width={1}
            minWidth="8rem"
            label={t("Project")}
            key={`S.${selectedSpaceId}`}
            ml="1rem"
            options={projectsOptions}
            onSelect={(option) => {
              setSelectedProjectId(parseInt(option.id));
              setSelectedListId();
            }}
            placeholder={t("Choose project")}
            disabled={projects[selectedProjectId].public}
            selected={
              selectedProjectId
                ? {
                    id: selectedProjectId,
                    value: projects[selectedProjectId].name
                  }
                : null
            }
          />
        )}

        {selectedProjectId && (
          <Dropdown
            width={1}
            minWidth="8rem"
            label={t("List")}
            key={`P.${selectedProjectId}`}
            ml="1rem"
            options={listsOptions}
            onSelect={(option) => {
              setState({ enabled: true });
              setSelectedListId(parseInt(option.id));
            }}
            placeholder={t("Choose list")}
            selected={
              selectedListId
                ? { id: selectedListId, value: lists[selectedListId].name }
                : null
            }
          />
        )}
      </Flex>

      {!state.enabled && (
        <Flex
          bg="lightRed"
          borderRadius="0.375rem"
          mt="0.5rem"
          py="0.5rem"
          justifyContent="center"
        >
          <Icon.Warning />
          <Text ml="0.5rem" fontWeight="semibold">
            {state.error}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Where;
