export const emptyContent = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

export const getInitialValue = (value) => {
  if (
    !value ||
    typeof value === "undefined" ||
    (Array.isArray(value) && value.length === 0)
  )
    return [...emptyContent];
  return value;
};
