import { createActions, handleActions } from "redux-actions";

const options = { prefix: "crud", namespace: "::" };

export const actions = createActions(
  {
    SET: (response, module, action) => ({
      module,
      response,
      action,
    }),
  },
  options
);

export const reducer = handleActions(
  {
    SET: (
      state,
      { payload: { action, response } }
    ) => {
      action(
        state,
        response
      );
      return state;
    }
  },
  {},
  options
);
