export * from "./colors";
export * from "./date";
export * from "./emojis";
export * from "./grid";
export * from "./mimeTypes";
export * from "./size";
export * from "./ui";

export const ROOT_URL = process.env.REACT_APP_URL;
export const API_ROOT = ROOT_URL + process.env.REACT_APP_API_URL;

export const API_LIMIT = 25;
