import React from "react";

import Icon from "../Icon";

const Checkmark = props => {
  return (
    <Icon {...props}>
      <polyline points="20 6 9 17 4 12" />
    </Icon>
  );
};

export default Checkmark;
