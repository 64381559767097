import { animated } from "react-spring";
import styled from "styled-components";
import {
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  textStyle,
  typography
} from "styled-system";

import { SIZES } from "../../constants";

const Icon = styled("svg")`
 
  width: 18px; min-width: 18px; height: 18px; min-height: 18px;
      
  ${(props) => props.stroke && `stroke: ${props.theme.colors[props.stroke]};`}

  ${(props) =>
    props.height && `height: ${props.height}; min-height: ${props.height};`}
                                                         
  ${(props) =>
    props.width && `width: ${props.width}; min-width: ${props.width};`} 

  ${(props) => {
    if (!props.size || typeof props.size === "undefined") return "";

    if (Object.keys(SIZES).includes(props.size))
      return `width: ${SIZES[props.size].size}; min-width: ${
        SIZES[props.size].size
      }; height: ${SIZES[props.size].size}; min-height: ${
        SIZES[props.size].size
      };`;

    return `width: ${props.size}; min-width: ${props.size}; height: ${props.size}; min-height: ${props.size};`;
  }}

  boxSizing: border-box;
  strokeWidth: 2px;
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
  ${textStyle}
`;

Icon.defaultProps = {
  fill: "none",
  stroke: "black",
  viewBox: "0 0 23 23"
};

let AnimatedIcon = animated(Icon);

export default AnimatedIcon;
