import React, { useEffect } from "react";
import { Button as ButtonWrapper } from "./Button";

export const Button = ({ children, onEsc, onEnter, onConfirm, ...props }) => {
  useEffect(() => {
    if (!onEnter || props.disabled) return;

    window.addEventListener("keydown", handleEnter);
    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [props.disabled]);

  useEffect(() => {
    if (!onEsc) return;
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const handleEsc = (e) => {
    e.stopPropagation();

    let keyCode = e.keyCode;

    if (keyCode === 27) {
      if (onEsc) onEsc();
    }
  };

  const handleEnter = (e) => {
    e.stopPropagation();

    let keyCode = e.keyCode;

    if (keyCode === 13) {
      onEnter();
    }
  };

  return (
    <ButtonWrapper onClick={onConfirm} {...props}>
      {children}
    </ButtonWrapper>
  );
};
