import { createActions, handleActions } from "redux-actions";

const modelId = "spaces";

const options = { prefix: "spaces", namespace: "::" };

export const actions = createActions(
  {
    SET_STATUS: ({ userId, status }) => ({
      module: modelId,
      userId,
      status
    }),

    SET_SPACE_ID: (spaceId) => ({
      module: modelId,
      spaceId
    }),

    UPDATE_STATUS: ({ status }) => ({
      module: modelId,
      status
    })
  },
  options
);

export const reducer = handleActions(
  {
    SET_STATUS: (state, { payload: { userId, status } }) => {
      state.users[userId].status = status;
      return state;
    },

    SET_SPACE_ID: (state, { payload: { spaceId } }) => {
      state.spaceId = spaceId;
      localStorage.setItem("spaceId", spaceId);
      return state;
    },

    UPDATE_STATUS: (state, { payload: { status } }) => {
      if (!status) return state;

      Object.keys(status).forEach(
        (userId) => (state.users[userId].status = status[userId])
      );
      return state;
    }
  },
  {},
  options
);
