import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Admin = ({ id }) => {
  const dispatch = useDispatch();

  let list = useSelector(state => state["task"].groups[id]);

  return `Admin ${list.name}`;
};

export default Admin;
