import React, { useState } from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Action,
  Avatar,
  Button,
  Column,
  Flex,
  FlexWrapper,
  FlexHidden,
  formatDate,
  Icon,
  orderObjects,
  orderObjectsList,
  Reactions,
  SimpleEditor,
  strip,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { AddTask } from "modules";
import { API_ENDPOINT, API_ROOT, ROOT_URL } from "config";

const Poll = ({ message }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);

  const saveAnswer = (id) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topicreply/${message.id}/answer`,
        ACTIONS.state.loadObject,
        { answer_id: id },
        (state, response) => {
          state.obj.replies[response.id] = response;
          return state;
        }
      )
    );
  };

  const totalQuestion = Object.keys(message.poll).length;
  let totalAnswers = 0;

  Object.values(message.poll).forEach((question) => {
    totalAnswers += Object.keys(question.answers).length;
  });

  return (
    <>
      <Flex mt="1rem">
        {Object.keys(message.poll).map((id, index) => {
          return (
            <Column
              onClick={() => saveAnswer(id)}
              borderTopLeftRadius={!index ? "0.5rem" : ""}
              borderBottomLeftRadius={!index ? "0.5rem" : ""}
              borderTopRightRadius={index + 1 === totalQuestion ? "0.5rem" : ""}
              borderBottomRightRadius={
                index + 1 === totalQuestion ? "0.5rem" : ""
              }
              borderLeft={!index ? "1px solid lightGrey" : ""}
              borderRight="1px solid lightGrey"
              borderTop="1px solid lightGrey"
              borderBottom="1px solid lightGrey"
              alignItems="center"
              px="1rem"
              bg={message.poll[id]["answers"][user.id] ? "lightRed" : "l0"}
              style={{ cursor: "pointer" }}
              hoverColor="lightBlue"
            >
              <Text pt="0.5rem" fontSize="1.25rem" fontWeight="semibold">
                {totalAnswers > 0
                  ? (
                      (Object.values(message.poll[id].answers).length * 100) /
                      totalAnswers
                    ).toFixed(1)
                  : 0}
                %
              </Text>
              <Text py="0.5rem">{message.poll[id].label}</Text>
            </Column>
          );
        })}
      </Flex>
      <Text mt="0.5rem">
        {t("Total answers")}: {totalAnswers}
      </Text>
    </>
  );
};
export const Message = ({
  lastRead,
  reply,
  isReply = true,
  spaceId,
  first,
  last
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const topic = useSelector((state) => state["topic"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [content, setContent] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableReply, setEnableReply] = useState(false);
  const [newTask, setNewTask] = useState();

  const saveReactions = (reactions) => {
    let url = isReply
      ? `${API_ENDPOINT}/topicreply/${reply.id}`
      : `${API_ENDPOINT}/topic/${reply.id}`;

    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        url,
        ACTIONS.state.loadObject,
        {
          reactions
        },
        (state, response) => {
          if (isReply) {
            state.obj.replies[response.id] = response;
          } else {
            state.obj = response;
          }
          return state;
        }
      )
    );
  };

  const saveContent = () => {
    const action = enableEdit ? "editing" : "replying";
    setEnableEdit(false);
    setEnableReply(false);
    setContent();

    const summary = strip(content.map((n) => Node.string(n)).join(" "));

    if (action === "editing") {
      if (isReply) {
        dispatch(
          ACTIONS.fetch.patch(
            "topic",
            `${API_ROOT}/topic/${reply.id}/update_reply`,
            [],
            {
              content,
              summary
            }
          )
        );
      } else {
        dispatch(
          ACTIONS.fetch.patch("topic", `${API_ROOT}/topic/${reply.id}`, [], {
            content
          })
        );
      }
    } else {
      let data = { content, summary };
      // if (isReply) {
      data.reply_to_id = reply.id;
      data.topic_id = reply.topic_id;
      // } else {
      //   data.topic_id = reply.id;
      // }

      dispatch(
        ACTIONS.fetch.post(
          "topic",
          `${API_ENDPOINT}/topicreply`,
          ACTIONS.state.loadObject,
          data,
          (state, response) => {
            state.obj.replies[response.id] = response;
            return state;
          }
        )
      );
      if (!topic.followers.includes(user.id))
        dispatch(
          ACTIONS.state.changeState("topic", (state) => {
            state.obj.followers.push(user.id);
            return state;
          })
        );
    }
  };

  const updateReply = (state, response) => {
    state.obj.replies[reply.id].task_id = response.id;
    return state;
  };

  const markAsRead = (messageId) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topicreply/${messageId}/read`,
        ACTIONS.state.loadObject,
        {},
        (state, response) => {
          state.obj.replies[response.id] = response;
          return state;
        }
      )
    );
  };

  const deleteReply = (id) => {
    dispatch(
      ACTIONS.fetch.delete(
        "topic",
        `${API_ENDPOINT}/topicreply/${id}`,
        ACTIONS.state.loadObject,
        (state, response) => {
          console.log(response);
          if (response.success) delete state.obj.replies[response.id];
          return state;
        }
      )
    );
  };

  const onSave = ACTIONS.state.changeStateFromResponse("topic", updateReply);

  const lastUpdate = new Date(reply.update_date).getTime();
  const now = new Date().getTime();
  const lastReadTime = lastRead[topic.id] ? parseInt(lastRead[topic.id]) : 0;

  const notRead =
    reply.created_by_id !== user.id &&
    lastReadTime < lastUpdate + 15 * 60 * 1000 &&
    lastUpdate > now - 86400000;

  return (
    <FlexWrapper
      borderRadius="0.25rem"
      flexDirection="column"
      borderLeft={first ? "" : "2px dotted lightGrey"}
      py="0.5rem"
      ml={first ? "0" : "1.5rem"}
      pl={first ? "0" : "1rem"}
    >
      {newTask && (
        <AddTask
          replyId={reply.id}
          full={true}
          typeId={1}
          description={reply.content}
          ownerId={newTask.ownerId}
          setOpen={setNewTask}
          allowMultiples={false}
          onSave={onSave}
        />
      )}
      <Flex
        alignItems="flex-start"
        width={1}
        bg={notRead ? "lightRed" : ""}
        position="relative"
        borderRadius="0.5rem"
        borderTopLeftRadius="1.5rem"
      >
        {reply.confirm_read && !reply.read_by[user.id] && (
          <Column
            width={1}
            height="100%"
            position="absolute"
            zIndex="99999"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              style={{ backdropFilter: "blur(6px)" }}
              width={1}
              height="100%"
            >
              <Text
                onClick={() => markAsRead(reply.id)}
                fontWeight="semibold"
                style={{ cursor: "pointer" }}
                bg="lightRed"
                p="1rem"
                borderRadius="0.5rem"
              >
                {t(
                  "You should read this message carefully! If you agree click here!"
                )}
              </Text>
            </Flex>
          </Column>
        )}

        <Flex alignItems="center" mt="0.125rem">
          <Avatar
            size="x-large"
            src={users[reply.created_by_id].avatar}
            online={onlineUsersIds.includes(reply.created_by_id)}
            showOnline={true}
          />
        </Flex>
        <Flex ml="1.25rem" mt="0.25rem" flexDirection="column" width={1}>
          <Flex alignItems="center" mb="0.75rem">
            <Text color="t1" fontWeight="bold" fontSize="0.875rem">
              {reply.created_by_id === user.id
                ? t("You")
                : users[reply.created_by_id].name}
            </Text>
            <Text
              color="t4"
              ml="0.5rem"
              fontWeight="semiibold"
              fontSize="0.75rem"
            >
              {formatDate(
                reply.creation_date,
                user.locale,
                user.timezone
              ).distance()}
            </Text>

            {new Date(reply.update_date) - new Date(reply.creation_date) >
              100 && (
              <Text
                ml="0.5rem"
                color="t2"
                fontWeight="semibold"
                fontSize="0.75rem"
                // bg="l2"
                borderRadius="0.25rem"
                px="0.5rem"
                py="0.25rem"
              >
                {t("edited")}
              </Text>
            )}
          </Flex>

          <SimpleEditor
            key={reply.id}
            placeholder={t("Write a reply...")}
            value={reply.content}
            lineHeight={1.5}
            width={1}
            enabled={enableEdit}
            autofocus={true}
            enableToolbar={true}
            fileRootUrl={`${ROOT_URL}/shared/`}
            uploadEndpoint={`${API_ROOT}/file/upload?space_id=${spaceId}&topic_id=${reply.id}`}
            toolbarPosition="TOP"
            renderMode={!enableEdit}
            onChange={setContent}
            onEnter={saveContent}
          />
          {enableReply && (
            <Flex mt="1rem">
              <SimpleEditor
                key={`R.${reply.id}`}
                autoFocus={true}
                placeholder={`${t("reply")} ${t("to")} ${
                  users[reply.created_by_id].name
                }`}
                enabled={true}
                enableToolbar={true}
                toolbarPosition="TOP"
                fileRootUrl={`${ROOT_URL}/shared/`}
                uploadEndpoint={`${API_ROOT}/file/upload?space_id=${spaceId}&topic_id=${reply.id}`}
                onChange={setContent}
                clearOnEnter={true}
                onEnter={saveContent}
              />
            </Flex>
          )}

          {enableReply || enableEdit ? (
            <>
              {false && (
                <Flex mt="0.5rem" justifyContent="flex-end" mb="0.5rem">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setContent();
                      setEnableReply(false);
                      setEnableEdit(false);
                    }}
                    value={t("Cancel")}
                  />

                  <Button
                    variant="primary"
                    onClick={saveContent}
                    value={enableReply ? t("Reply") : t("Save")}
                  />
                </Flex>
              )}
            </>
          ) : (
            <>
              {reply.type_id === 2 && <Poll message={reply} />}

              <Flex alignItems="center" mt="0.5rem">
                <Reactions
                  user={user}
                  users={users}
                  reactions={reply.reactions}
                  onSelect={saveReactions}
                  size={14}
                  showReactions={false}
                />

                {false && (
                  <>
                    <Avatar
                      ml="0.25rem"
                      width="1.25rem"
                      height="1.25rem"
                      // size="large"
                      src={users[reply.created_by_id].avatar}
                      online={onlineUsersIds.includes(reply.created_by_id)}
                      showOnline={true}
                    />
                    <Text
                      ml="0.5rem"
                      color="t4"
                      fontWeight="bold"
                      fontSize="0.75rem"
                    >
                      {reply.created_by_id === user.id
                        ? t("You")
                        : users[reply.created_by_id].name}
                      &nbsp;
                      {formatDate(
                        reply.creation_date,
                        user.locale,
                        user.timezone
                      ).distance()}
                    </Text>
                    {new Date(reply.update_date) -
                      new Date(reply.creation_date) >
                      100 && (
                      <Text
                        ml="0.5rem"
                        color="t2"
                        fontWeight="semibold"
                        fontSize="0.75rem"
                        // bg="l2"
                        borderRadius="0.25rem"
                        px="0.5rem"
                        py="0.25rem"
                      >
                        {t("edited")}
                      </Text>
                    )}
                  </>
                )}

                <FlexHidden>
                  {!reply.disable_reply && (
                    <Action
                      ml="0.5rem"
                      fontSize="0.75rem"
                      onClick={() => setEnableReply(!enableReply)}
                    >
                      {t("Reply")}
                    </Action>
                  )}

                  {user.id === reply.created_by_id && (
                    <Action
                      ml="0.5rem"
                      fontSize="0.75rem"
                      onClick={() => setEnableEdit(!enableEdit)}
                    >
                      {t("Edit")}
                    </Action>
                  )}

                  {!reply.task_id ? (
                    <Action
                      ml="0.5rem"
                      fontSize="0.75rem"
                      onClick={() =>
                        setNewTask({ description: "message", ownerId: user.id })
                      }
                    >
                      {t("Add task")}
                    </Action>
                  ) : (
                    <Flex
                      borderRadius="0.25rem"
                      fontSize="0.75rem"
                      color="white"
                      px="0.5rem"
                      py="0.25rem"
                      bg="green"
                      onClick={() => {}}
                      alignItems="center"
                      ml="0.5rem"
                    >
                      <Icon.Task stroke="white" size="14px" mr="0.125rem" />
                      {reply.task_id}
                    </Flex>
                  )}

                  {reply.replies &&
                    reply.replies.length === 0 &&
                    reply.created_by_id === user.id && (
                      <Action
                        ml="0.5rem"
                        fontSize="0.75rem"
                        onClick={() => deleteReply(reply.id)}
                      >
                        {t("Delete")}
                      </Action>
                    )}
                </FlexHidden>
              </Flex>

              {reply.confirm_read && (
                <Flex mt="0.5rem" alignItems="center">
                  <Text fontSize="0.75rem">{t("Read by")}:</Text>
                  {Object.keys(reply.read_by).map((userId) => (
                    <Avatar
                      size="medium"
                      ml="0.5rem"
                      src={users[userId].avatar}
                    />
                  ))}
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Flex>

      {reply.replies && reply.replies.length > 0 && (
        <Flex minWidth="30rem" flexDirection="column">
          {reply.replies.map((msg, index) => (
            <Message
              key={msg.id}
              reply={msg}
              last={reply.replies.length === index + 1}
              lastRead={lastRead}
              isReply={true}
              spaceId={spaceId}
            />
          ))}
        </Flex>
      )}
    </FlexWrapper>
  );
};

export const Replies = ({ lastRead, spaceId }) => {
  const replies = useSelector((state) => state["topic"].obj.replies);

  let root = [];
  let orderedReplies = {};

  if (typeof replies === "undefined") return null;

  const orderedNestedReplies = orderObjects(replies, "creation_date", "asc");

  root = orderedNestedReplies
    .filter((reply) => !reply.reply_to_id)
    .map((reply) => {
      return { ...reply };
    });

  const makeTree = (nodes, reply, first) => {
    return nodes
      .filter((node) => node.reply_to_id === reply.id)
      .reduce((tree, node) => {
        return [
          ...tree,
          {
            ...node,
            replies: makeTree(nodes, node, first)
          }
        ];
      }, []);
  };

  root.forEach((reply) => {
    reply.last_reply_date = reply.creation_date;
    reply.replies = makeTree(orderedNestedReplies, reply, reply);
  });

  orderedReplies = orderObjectsList(root, "last_reply_date", "desc");

  return (
    <Flex flexDirection="column" mt="0.5rem">
      {Object.values(orderedReplies).map((reply, index) => (
        <Message
          lastRead={lastRead}
          key={reply.id}
          reply={reply}
          spaceId={spaceId}
          first={true}
        />
      ))}
    </Flex>
  );
};

// return (
//                      <Flex
//                        alignItems="center"
//                        key={reaction.code}
//                        bg={
//                          reaction.users.includes(user.id) ? "lightBlue" : "l2"
//                        }
//                        border={
//                          reaction.users.includes(user.id)
//                            ? "1px solid blue"
//                            : ""
//                        }
//                        borderRadius="1rem"
//                        px="0.375rem"
//                        py="0.125rem"
//                        mr={2}
//                        my={1}
//                        style={{ cursor: "pointer" }}
//                        onClick={(e) => {
//                          e.stopPropagation();
//                          // updateReaction(reactions, reaction.code);
//                        }}
//                      >
//                        <Image
//                          mr="0.25rem"
//                          widt="1rem"
//                          minWidth="1rem"
//                          height="1rem"
//                          minHeight="1rem"
//                          src={`https://static.elefante.com/emojis/${reaction.code}.png`}
//                        />

//                        <Text
//                          fontWeight={
//                            reaction.users.includes(user.id)
//                              ? "semibold"
//                              : "regular"
//                          }
//                          color={
//                            reaction.users.includes(user.id) ? "blue" : "t3"
//                          }
//                          fontSize="0.75rem"
//                        >
//                          {reaction.users.length}
//                        </Text>
//                      </Flex>
//                    );
