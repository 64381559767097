import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Calendar } from "modules";
import { Filters } from "../Filters/Filters";
import { filter } from "../filter";
import { Widget } from "components";

const _Calendar = ({ useFilters = false, ...props }) => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state["tasks"].filters);
  const lists = useSelector((state) => state["tasks"].lists);
  const projectId = useSelector((state) => state["tasks"].filters.projectId);
  const spaceId = useSelector((state) => state["tasks"].filters.spaceId);
  const tasks = useSelector((state) => state["tasks"].tasks);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const filtered = useMemo(() => {
    return filter(
      filters,
      Object.values(tasks).map((task) => task),
      user
    );
  }, [filters, tasks, user]);

  const [events, setEvents] = useState({});

  useEffect(() => {
    if (!filtered) return;
    let newEvents = {};
    let key;

    Object.values(useFilters ? filtered.filtered : filtered.all).forEach(
      (task) => {
        if (filters.status === "CLOSED") key = task.doneKey;
        if (filters.status === "OPEN") key = task.deadlineKey;
        if (filters.status === "OPEN_AND_CLOSED")
          key = task.deadlineKey || task.doneKey;

        if (!newEvents[key]) newEvents[key] = [];

        let list = lists[task.list_id];

        newEvents[key].push({
          ...task,
          color: `hsl(${list.color.h}, ${list.color.s}%, 94%)`,
          url: `/space/${task.space_id}/tasks/${task.id}`,
          done: task.doneKey
        });
      }
    );
    setEvents(newEvents);
  }, [
    filters,
    filtered,
    lists,
    projectId,
    spaceId,
    tasks,
    user.id,
    useFilters
  ]);

  const ShowFilters = (
    <Filters selectedFilters={["Stats", "Due", "Priority", "Assignment"]} />
  );

  return (
    <Widget
      titleLeft={t("Calendar")}
      height="100%"
      width={1}
      titleRight={ShowFilters}
    >
      <Calendar events={events} users={users} />
    </Widget>
  );
};

export { _Calendar as Calendar };
