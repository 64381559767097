import React, { createRef, useEffect, useRef } from "react";
import iro from "@jaames/iro";

import { Flex } from "../Flex";

export const ColorPicker = ({ pickerSize, color, onColorChange, ...props }) => {
  let colorPicker = useRef(null);
  let el = createRef();

  useEffect(() => {
    if (!el.current) {
      return;
    }

    if (!colorPicker.current) {
      colorPicker.current = new iro.ColorPicker(el.current, {
        width: pickerSize,
        layout: [
          {
            component: iro.ui.Wheel
          }
        ]
      });

      if (!colorPicker.current) {
        return;
      }

      colorPicker.current.on("color:change", (color: { hexString: string }) => {
        onColorChange && onColorChange(color.hsl);
        colorPicker.current.color.set(colorPicker.current.color.hexString);
      });
    } else if (color !== colorPicker.current.color.hexString) {
      colorPicker.current.color.set(color);
    }
  }, []);

  return (
    <Flex justifyContent="center" mx="auto" {...props}>
      <div ref={el} />
    </Flex>
  );
};
