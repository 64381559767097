import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Flex, formatDate, Icon, Text } from "tomato";

const Info = () => {
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  return (
    <>
      <Flex alignItems="center" mt="2rem">
        <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
          {t("Creation")}
        </Text>

        {!task.created_by_id ? (
          <>
            <Icon.Automation size="large" />

            <Text ml="0.5rem" color="t2" fontSize="0.9375rem">
              {t("Automation")},&nbsp;
              {formatDate(task.creation_date).datetime()}
            </Text>
          </>
        ) : (
          <>
            <Avatar
              size="large"
              src={
                !users[task.created_by_id].is_deleted &&
                users[task.created_by_id].avatar
              }
            />

            <Text ml="0.5rem" color="t2" fontSize="0.9375rem">
              {!users[task.created_by_id].is_deleted &&
                users[task.created_by_id].name + ",&nbsp; "}

              {formatDate(
                task.creation_date,
                user.locale,
                user.timezone
              ).datetime()}
            </Text>
          </>
        )}
      </Flex>

      {task.done_by_id && (
        <Flex mt="2rem" alignItems="center">
          <Text
            color="t2"
            fontWeight="semibold"
            fontSize="0.9375rem"
            width={140}
          >
            {t("Done by")}
          </Text>

          <Avatar size="large" src={users[task.done_by_id].avatar} />

          <Text ml="0.5rem" color="t2" fontSize="0.9375rem">
            {users[task.done_by_id].name},&nbsp;
            {formatDate(task.done_date, user.locale, user.timezone).datetime()}
          </Text>
        </Flex>
      )}

      {task.deleted_by_id && (
        <Flex mt="2rem" alignItems="center">
          <Text
            color="t2"
            fontWeight="semibold"
            fontSize="0.9375rem"
            width={140}
          >
            {t("Deleted by")}
          </Text>

          <Avatar size="large" src={users[task.deleted_by_id].avatar} />

          <Text ml="0.5rem" color="t2" fontSize="0.9375rem">
            {users[task.deleted_by_id].name},&nbsp;
            {formatDate(
              task.deletion_date,
              user.locale,
              user.timezone
            ).datetime()}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default Info;
