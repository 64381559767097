import React, { useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Dropdown,
  Flex,
  Icon,
  Switch,
  Text,
  Tippy as ToolTip
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

const Followers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [instance, setInstance] = useState(null);

  const addFollower = (member) => {
    instance.hide();
    dispatch(ACTIONS.events.send("Follower added", task));
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/follow`,
        ACTIONS.tasks.followTask,
        { follower_id: member.id }
      )
    );
  };

  const followTask = () => {
    dispatch(
      ACTIONS.events.send(following ? "Follow task" : "Unfollow task", task)
    );
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/follow`,
        ACTIONS.tasks.followTask,
        { follower_id: user.id }
      )
    );
  };

  let followers = [];
  if (task.created_by_id) followers = [task.created_by_id];
  if (task.owner_id) followers.push(task.owner_id);
  followers = [...new Set([...followers, ...task.followers])];

  const following = followers.includes(user.id);

  let members =
    spaces[projects[lists[task.list_id].project_id].space_id].members;

  let membersOptions = members
    .filter(
      (member) =>
        users[member.id].is_active &&
        !users[member.id].is_deleted &&
        !followers.includes(member.id)
    )
    .map((member) => {
      return {
        id: member.id,
        value: users[member.id].name,
        avatar: users[member.id].avatar
      };
    });

  return (
    <Flex mt="2rem" alignItems="center">
      <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
        {t("Followers")}
      </Text>

      <Flex flexWrap="wrap" alignItems="center">
        {followers
          .filter(
            (followerId) => users[followerId] && !users[followerId].is_deleted
          )
          .map((followerId, index) => (
            <ToolTip key={index} content={users[followerId].name}>
              <Flex key={index}>
                <Avatar
                  mr="0.5rem"
                  size="large"
                  src={users[followerId].avatar}
                />
              </Flex>
            </ToolTip>
          ))}

        {(task.created_by_id === user.id || task.owner_id === user.id) && (
          <Tippy
            trigger="click"
            placement="right"
            interactive={true}
            offset={[0, 4]}
            render={(attrs) => (
              <>
                {instance ? (
                  <Dropdown
                    minWidth="6rem"
                    options={membersOptions}
                    onSelect={addFollower}
                    placeholder={t("Add follower")}
                  />
                ) : (
                  ""
                )}
              </>
            )}
            onCreate={setInstance}
          >
            <Flex
              // bg="l2"
              hoverColor="lightBlue"
              borderRadius="50%"
              p="0.25rem"
              style={{ cursor: "pointer" }}
            >
              <Icon.Plus />
            </Flex>
          </Tippy>
        )}

        {task.created_by_id !== user.id && task.owner_id !== user.id && (
          <Switch
            ml="0.5rem"
            checked={following}
            onCheck={followTask}
            label={t("Follow")}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Followers;
