import React from "react";
import styled from "styled-components";

import { Flex } from "tomato";

export const StyledText = styled(Flex)`
  cursor: pointer;
  font-size: 0.75rem;
  &:hover {
    background-color: ${(props) =>
      props.day.selected ? props.theme.colors.blue : props.theme.colors.l3};
  }

  ${(props) => {
    let day = props.day;

    let style = {};
    style.color = props.theme.colors.t1;

    if (day.isPast) {
      // style.cursor = "not-allowed";
    }

    if (!day.isThisMonth) {
      // style.visibility = "hidden";
    }

    if (day.isWeekend) {
      if (day.isPast) {
        style.color = props.theme.colors.t4;
      } else {
        style.color = props.theme.colors.t4;
      }
    }

    if (day.isToday && !(day.isStartDate || day.isEndDate)) {
      style.color = "red";
      style.fontWeight = "bold";
    }

    if (day.isInBetween && !day.isToday) {
    }

    if (day.isStartDate) {
      style.backgroundColor = props.theme.colors.blue;
      style.color = "white";
      if (props.range) {
      } else {
        style.width = "14px";
        style.height = "14px";
      }
    }

    if (day.isEndDate) {
      style.backgroundColor = props.theme.colors.blue;
      style.color = "white";
      style.borderTopRightRadius = "8px";
      style.borderBottomRightRadius = "8px";
    }

    return { ...style };
  }};
`;
