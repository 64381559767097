export const BG = "hsla(214, 15%, 100%)";

const FONT_FAMILY =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;";

const FONT_SIZE = "16px";

const FONT_WEIGHT = "400";

const RADIUS = "1rem";

const PANELS_BORDER_RADIUS = {
  borderTopLeftRadius: "1em",
  borderBottomLeftRadius: "1em"
};

const BORDER = "1px solid hsl(214, 10%, 90%)";

const POSITION = {
  TOP: "top",
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  BOTTOM: "bottom",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right",
  LEFT: "left",
  LEFT_TOP: "left-top",
  LEFT_BOTTOM: "left-bottom",
  RIGHT: "right",
  RIGHT_BOTTOM: "right-bottom",
  RIGHT_TOP: "right-top"
};

const LOGO = "/img/logo.svg";

const INPUT_HEIGHT = "40px";

const HOVERING_BOX_SHADOW = "4px 8px 16px -8px rgba(0,0,0,0.8)";

export const UI = {
  BG,
  BORDER,
  HOVERING_BOX_SHADOW,
  INPUT_HEIGHT,
  LOGO,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  PANELS_BORDER_RADIUS,
  POSITION,
  RADIUS
};

// import { enUS, ptBR } from "date-fns/locale";
// export const DATE_LOCALES = { "en-US": enUS, "pt-BR": ptBR };

// export let TRANSLATIONS_DIR = process.env.REACT_APP_TRANSLATIONS_DIR;

// export const VERSION = "0.1";
// export const PIPZ_APP_ID = 12;

// export let ROOT_URL = process.env.REACT_APP_URL;
// export let API_ROOT = process.env.REACT_APP_API_URL;

// export const ENABLE_WS = false;
// export let WS_URL = process.env.REACT_APP_WS_URL;

// API_ROOT = ROOT_URL + API_ROOT;

// export const API_LIMIT = 25;

// // export { POSITION } from "./position";

// export const LOGO = "/images/logo.gif";

// export let PROD = false;
// if (process.env.REACT_APP_ENV === "prod") {
//   ROOT_URL = "";
//   PROD = true;
//   WS_URL = "wss://tasks.spaces.pipz.io/ws";
// }

// // Tempo de cache em milisegundos (minutos * segundos * ms)
// export const TTL = 5 * 60 * 1000;

// export const BOX_SHADOW = "0px 0px 12px 0px rgba(0,0,0,0.1)";
// export const HOVERING_BOX_SHADOW = "0px 0px 16px 0px rgba(0,0,0,0.4)";

// export const OPEN_SIDEMENU_PADDING_LEFT = "0px";
// export const OPEN_SIDEMENU_WIDTH = 200;
// export const CLOSED_SIDEMENU_PADDING_LEFT = "20px";
// export const CLOSED_SIDEMENU_WIDTH = 56;
// export const MAX_CONTENT_WIDHT = 1366;
// export const TOPMENU_HEIGHT = 56;

// // export const DEFAULT_SHADOW = "0px 0px 10px 0px rgba(0,0,0,0.2)";
// export const DEFAULT_SHADOW = "";
// export const BG_COLOR_PRIMARY_MENU = "l1";
// export const BG_COLOR_SECONDARY_MENU = "l1";
// export const BG_COLOR_TERTIARY_MENU = "l2";
// export const BG_COLOR_MAIN = "l0";
// export const DEFAULT_BG = BG_COLOR_MAIN;
// export const DEFAULT_BORDER = "1px solid #e2e2e2";
// export const PANELS_BORDER_RADIUS = {
//   borderTopLeftRadius: "16px",
//   borderBottomLeftRadius: "16px"
// };

// const MAIN_PADDING = "32px";

// const SHADOW = "-5px 0px 10px 0px rgba(0,0,0,0.10)";

// export const UI = {
//   BG_COLOR_PRIMARY_MENU,
//   BG_COLOR_SECONDARY_MENU,
//   BG_COLOR_TERTIARY_MENU,
//   CLOSED_SIDEMENU_WIDTH,
//   DEFAULT_BG,
//   DEFAULT_BORDER,
//   DEFAULT_FONT_SIZE,
//   DEFAULT_FONT_FAMILY,
//   DEFAULT_INPUT_HEIGHT,
//   DEFAULT_SHADOW,
//   MAIN_PADDING,
//   MAX_CONTENT_WIDHT,
//   OPEN_SIDEMENU_WIDTH,
//   PANELS_BORDER_RADIUS,
//   SHADOW,
//   BOX_SHADOW,
//   HOVERING_BOX_SHADOW
// };

// export const SPACES_UI = {
//   BG: "l1",
//   BG: "l0",
//   BORDER: "1px solid #e2e2e2",
//   BORDER: "1px solid #f2f2f2",
//   BORDER: "none",
//   BOX_SHADOW: "0px 2px 4px 0px rgba(0,0,0,0.15)",
//   BOX_SHADOW: "0px 8px 16px 0px rgba(0,0,0,0.2)",
//   BOX_SHADOW: "0px 2px 4px 0px rgba(0,0,0,0.15)",
//   BOX_SHADOW: "0px 2px 4px 0px hsl(214, 10%, 85%)",
//   BOX_SHADOW: "",
//   BOX_SHADOW: "0px 0px 12px 6px hsl(214, 10%, 90%)"
// };
