import React, { useState } from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Flex,
  formatDate,
  Icon,
  orderObjects,
  Reactions,
  SimpleEditor,
  strip,
  Text,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, ROOT_URL } from "config";

const RenderNote = ({ content, task }) => {
  let data;
  try {
    if (!content) {
      data = [
        {
          type: "paragraph",
          children: [{ text: "" }]
        }
      ];
    } else {
      data = content;

      if (!Array.isArray(data))
        data = [
          {
            type: "paragraph",
            children: [{ text: content }]
          }
        ];
    }
  } catch (err) {
    data = [
      {
        type: "paragraph",
        children: [{ text: content }]
      }
    ];
  }
  return (
    <SimpleEditor
      borderRadius={0}
      style={{ workBreak: "break-all" }}
      flexGrow="1"
      lineHeight={1.25}
      value={data}
      fileRootUrl={`${ROOT_URL}/shared/`}
      uploadEndpoint={`${API_ENDPOINT}/upload`}
      uploadId={{ module: "TASK", task_id: task.id }}
      renderMode={true}
      enabled={false}
      removePadding={true}
      autofocus={true}
    />
  );
};

const Notes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const notes = useSelector((state) => state["tasks"].obj.notes);
  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [key, setKey] = useState(uuidv4);

  const saveReactions = (note, reactions) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ENDPOINT}/tasknote/${note.id}`,
        ACTIONS.state.loadObject,
        {
          reactions
        },
        (state, response) => {
          state.obj.notes[response.id] = response;
          return state;
        }
      )
    );
  };

  const addNote = (value) => {
    setKey(uuidv4());
    if (!value) return;

    let note = {
      task_id: task.id,
      content: value,
      summary: strip(value.map((n) => Node.string(n)).join(" ")).slice(0, 50)
    };

    dispatch(ACTIONS.events.send("Note added", note));
    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ENDPOINT}/task/${task.id}/add_note`,
        ACTIONS.tasks.addNote,
        note
      )
    );
  };

  const deleteNote = (noteId) => {
    let note = {
      note_id: noteId
    };

    dispatch(ACTIONS.events.send("Note deleted", note));
    dispatch(
      ACTIONS.fetch.delete(
        "task",
        `${API_ENDPOINT}/task/${task.id}/delete_note/${noteId}`,
        ACTIONS.tasks.deleteNote
      )
    );
  };

  const count = Object.keys(notes);

  //   const members = spaces[toId].members
  //     .map((member) => member.id)
  //     .filter(
  //       (id) => id !== user.id && !users[id].is_deleted && users[id].is_active
  //     )
  //     .map.map((id) => {
  //       return { id, value: users[id].nickname };
  //     });

  //   console.log(members);

  return (
    <>
      <Flex>
        <Avatar
          size="large"
          src={users[user.id].avatar}
          mr="0.5rem"
          mt="0.25rem"
        />
        <SimpleEditor
          key={key}
          placeholder={t("Write a new note...")}
          lineHeight={1.5}
          width={1}
          autofocus={true}
          enableToolbar={true}
          onEnter={addNote}
          fileRootUrl={`${ROOT_URL}/shared/`}
          uploadEndpoint={`${API_ENDPOINT}/upload`}
          uploadId={{ module: "TASK", task_id: task.id }}
          toolbarPosition="TOP"
          enabled={true}
        />
      </Flex>

      {count === 0 && (
        <Text
          mx="auto"
          color="t3"
          fontSize="0.9375rem"
          mt="0.5rem"
          fontWeight="semibold"
        >
          {t("no notes found")}
        </Text>
      )}

      {count !== 0 && (
        <Flex width={1} flexDirection="column" mt="1rem">
          {orderObjects(notes, "creation_date", "desc").map((note, index) => (
            <Flex key={note.id} mb={6}>
              <Avatar size="large" src={users[note.created_by_id].avatar} />

              <Flex flexDirection="column" mx="0.5rem" width={1}>
                <Flex
                  borderRadius="0.5rem"
                  p="0.5rem"
                  bg="l2"
                  flexDirection="column"
                  mt={-1}
                  lineHeight="1.75"
                  minHeight="2.5rem"
                >
                  <RenderNote task={task} content={note.content} />

                  {false &&
                    note.content &&
                    note.content.split("\n").map((content, index) =>
                      content ? (
                        <Text fontSize="1rem" color="t1" key={index}>
                          {content}
                        </Text>
                      ) : (
                        <p key={index} />
                      )
                    )}
                </Flex>
                <Text color="t3" mt={1}>
                  {users[note.created_by_id].name},&nbsp;
                  {formatDate(
                    note.creation_date,
                    user.locale,
                    user.timezone
                  ).distance()}
                </Text>
                <Reactions
                  user={user}
                  users={users}
                  reactions={note.reactions}
                  onSelect={(reactions) => saveReactions(note, reactions)}
                />
              </Flex>

              <Icon.Trash
                mt="0.5rem"
                style={{ cursor: "pointer" }}
                onClick={() => deleteNote(note.id)}
              />
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
};

export default Notes;
