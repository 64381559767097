import React, { useState } from "react";

import { Flex, Icon, Text } from "../";

export const Tag = ({ children, onDelete, onSelect, selected, ...props }) => {
  let enableDelete = typeof onDelete === "function";
  let enableSelect = typeof onSelect === "function";

  const [view, setView] = useState(false);

  const deleteTag = (e) => {
    e.stopPropagation();
    if (enableDelete) onDelete();
  };
  const selectTag = () => {
    if (enableSelect) onSelect();
  };

  return (
    <Flex
      justifyContent="center"
      bg={selected ? "blue" : "l3"}
      borderRadius="0.25rem"
      px="0.25rem"
      onClick={selectTag}
      style={enableSelect ? { cursor: "pointer" } : {}}
      {...props}
      alignItems="center"
      label="Tag"
      width="fit-content"
      position="relative"
      onMouseEnter={() => setView(true)}
      onMouseLeave={() => setView(false)}
    >
      <Text
        color={selected ? "white" : "t1"}
        fontSize="0.75rem"
        py="0.25rem"
        px="0.75rem"
        // pr={enableDelete ? 0 : 2}
        width="fit-content"
      >
        {children}
      </Text>

      {enableDelete && view && (
        <Icon.Close
          right="0.125rem"
          position="absolute"
          stroke="blue"
            style={{ cursor: "pointer", strokeWidth:'2px' }}
          size="14px"
          onClick={(e) => deleteTag(e)}
        />
      )}
    </Flex>
  );
};
