import PropTypes from "prop-types";
import styled from "styled-components";

import { Box } from "../Box";
import { UI } from "../../constants";
// import Box from "../Box";
// import UI from "../../constants/ui";

const POSITION = UI.POSITION;

export const ArrowBox = styled(Box)`
  ${(props) => {
    let bgColor = props.bg || props.background || props.theme.colors["tooltip"];
    let color = props.color || props.theme.colors["black"];
    let borderColor =
      props.theme.colors[bgColor] || props.theme.colors["tooltip"];

    let before = `
      color: ${color}; 
      position: relative; 
      background: ${bgColor}; 
      border-radius: 4px; 
      padding: 8px 16px;
      box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.40);`;

    let arrow;

    if (!props.location || props.location === POSITION.LEFT)
      arrow = `right: 100%; top: 50%; border-right-color: ${borderColor}; margin-top: -5px`;

    if (!props.location || props.location === POSITION.LEFT_TOP)
      arrow = `right: 100%; top: 15px; border-right-color: ${borderColor}; margin-top: -5px`;

    if (props.location === POSITION.LEFT_BOTTOM)
      arrow = `right: 100%; bottom: 15px; border-right-color: ${borderColor}; margin-top: -5px;`;

    if (props.location === POSITION.RIGHT)
      arrow = `left: 100%; top: 50%; border-left-color: ${borderColor}; margin-top: -5px;`;

    if (props.location === POSITION.RIGHT_TOP)
      arrow = `left: 100%; top: 15px; border-left-color: ${borderColor}; margin-top: -5px;`;

    if (props.location === POSITION.RIGHT_BOTTOM)
      arrow = `left: 100%; bottom: 15px; border-left-color: ${borderColor}; margin-top: -5px;`;

    if (props.location === POSITION.TOP)
      arrow = `left: 50%; bottom: 100%; border-bottom-color: ${borderColor}; margin-left: -5px`;

    if (props.location === POSITION.TOP_LEFT)
      arrow = `left: 15px; bottom: 100%; border-bottom-color: ${borderColor}; margin-left: -5px`;

    if (props.location === POSITION.TOP_RIGHT)
      arrow = `right: 15px; bottom: 100%; border-bottom-color: ${borderColor}; margin-left: -5px`;

    if (props.location === POSITION.BOTTOM)
      arrow = `left: 50%; top: 100%; border-top-color: ${borderColor}; margin-left: -5px;`;

    if (props.location === POSITION.BOTTOM_LEFT)
      arrow = `left: 15px; top: 100%; border-top-color: ${borderColor}; margin-left: -5px;`;

    if (props.location === POSITION.BOTTOM_RIGHT)
      arrow = `right: 15px; top: 100%; border-top-color: ${borderColor}; margin-left: -5px;`;

    let after = `
      &:after {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        border-width: 5px;
        ${arrow}
      }`;

    return before + after;
  }}
`;

ArrowBox.propTypes = {
  /**
   * Posicao da ArrowBox na ToolTip
   */
  location: PropTypes.oneOf(Object.keys(POSITION).map((key) => POSITION[key]))
    .isRequired,
  /**
   * Cor da tooltip
   */
  bg: PropTypes.string,
  /**
   * Cor do text da tooltip
   */
  color: PropTypes.string
};
