import React, { useState } from "react";

import { Flex,  } from "tomato";
import { useLocalStorage } from "tomato";

export const Draggable = ({ id, startX = 0.1, startY = 0.9, children }) => {
  let width = window.innerWidth;
  let height = window.innerHeight;

  const [position, setPosition] = useLocalStorage(id, {
    x: startX,
    y: startY
  });

  const getPosition = () => {
    return {
      x: window.innerWidth * position.x,
      y: window.innerHeight * position.y
    };
  };

  const [state, setState] = useState(getPosition());

  const getPan = (e) => {
    if (e.type.includes("drag")) {
      return { x: e.clientX, y: e.clientY };
    }

    const touch = e.targetTouches[0];
    return { x: touch.clientX, y: touch.clientY };
  };

  const getDragImage = () => {
    let img = new Image();
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
    return img;
  };

  const onPanStart = (e) => {
    if (e.type === "dragstart") {
      e.dataTransfer.setDragImage(getDragImage(), 0, 0);
    }
    setState({ ...state, dragging: true });
  };

  const onPan = (e) => {
    if (e.clientX <= 0 || e.clientY <= 0) return false;
    setState({ ...state, ...getPan(e) });
  };

  const onPanEnd = () => {
    setState({ ...state});

    setPosition({ x: x / width, y: y / height });
  };

  const { x, y } = state;

  return (
    <Flex
      position="fixed"
      top={0}
      left={0}
      draggable="true"
      style={{
        display: "inline-block",
        cursor: "move",
        WebkitTransform: `translate3d(${x - 32}px, ${y - 32}px, 0)`,
        transform: `translate3d(${x - 32}px, ${y - 32}px, 0)`
      }}
      onTouchStart={onPanStart}
      onDragStart={onPanStart}
      onDrag={onPan}
      onTouchMove={onPan}
      onTouchEnd={onPanEnd}
      onDragEnd={onPanEnd}
    >
      {children}
    </Flex>
  );
};
