import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ACTIONS } from "store";
import { NewProject } from "../New";
import { SpacesMenu } from "modules";

export const Spaces = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orderedLists = useSelector((state) => state["tasks"].orderedLists);
  const orderedProjects = useSelector(
    (state) => state["tasks"].orderedProjects
  );
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [newProject, setNewProject] = useState(false);

  const spacesActions = {};

  const spaceActions = [
    {
      label: t("New project"),
      icon: "Plus",
      action: (item) => {
        setNewProject({
          space: item
        });
      }
    }
  ];

  Object.values(spaces).forEach((space) => {
    spacesActions[space.id] = {
      ...space,
      onSelect: (space) =>
        dispatch(ACTIONS.tasks.setFilter("spaceId", space.id)),
      actions: spaceActions,
      items: []
    };
  });

  const tree = {};

  orderedProjects.forEach((project) => {
    let item = {
      id: project.id,
      uuid: `1.${project.id}`,
      parentUuid: project.space_id,
      name: project.name,
      onSelect: (item) =>
        dispatch(ACTIONS.tasks.setFilter("projectId", item.id)),
      items: []
    };

    spacesActions[item.parentUuid].items.push(item.uuid);

    tree[`1.${project.id}`] = item;
  });

  orderedLists.forEach((list) => {
    let item = {
      id: list.id,
      items: [],
      name: list.name,
      onSelect: (item) => dispatch(ACTIONS.tasks.setFilter("listId", item.id)),
      parentUuid: `1.${list.project_id}`,
      uuid: `2.${list.id}`
    };

    if (!tree[item.parentUuid]) {
      tree[item.parentUuid] = { items: [item.uuid] };
    } else {
      tree[item.parentUuid].items.push(item.uuid);
    }

    if (tree[`2.${list.id}`]) {
      tree[`2.${list.id}`] = { ...tree[`2.${list.id}`], ...item };
    } else {
      tree[`2.${list.id}`] = item;
    }
  });

  return (
    <>
      <SpacesMenu
        levels={2}
        showAll={true}
        selectFirst={true}
        menu={tree}
        spacesActions={spacesActions}
        placement="right"
        module="tasks"
      />

      {newProject && (
        <NewProject
          open={newProject}
          setOpen={() => setNewProject(false)}
          space={newProject.space}
        />
      )}
    </>
  );
};
