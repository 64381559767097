import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ActionsMenu, Column, Flex, FlexWrapper, Icon, Text } from "tomato";

import { ACTIONS } from "store";

const Item = ({
  level = 0,
  levels,
  module,
  placement,
  selected,
  onOpen,
  selector,
  spaceId,
  uuid
}) => {
  const dispatch = useDispatch();

  const activeItem = useSelector((state) => state["spaces"].activeItem);
  const item = useSelector((state) => state[module].menu[uuid]);

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const active = activeItem && activeItem.includes(selector);

  const disableOpen = levels === false || (levels !== false && level > levels);

  return (
    <>
      <FlexWrapper
        alignItems="center"
        borderRadius="0.25rem"
        px="0.5rem"
        py="0.5rem"
        width={1}
        hoverColor="l3"
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(ACTIONS.spaces.setSpaceId(spaceId));
          item.onSelect && item.onSelect(item);
          dispatch(
            ACTIONS.state.setAttribute("spaces", "activeSpaceId", spaceId)
          );
          dispatch(
            ACTIONS.state.setAttribute("spaces", "activeItem", selector)
          );
          item.linkTo && history.push(item.linkTo);
        }}
        mt="0.125rem"
      >
        <Flex
          onClick={(e) => {
            e.stopPropagation();
            if (disableOpen) return;
            !open && item.onOpen && item.onOpen(item);
            setOpen(!open);
          }}
          p="0.25rem"
        >
          <Icon.Triangle
            style={{
              transform: open ? "rotate(180deg)" : "rotate(90deg)",
              cursor: disableOpen ? "default" : "pointer"
            }}
            fill={active ? "grey" : "#c4c4c4"}
            strokeWidth="0px"
            size="8px"
          />
        </Flex>

        <Text
          mr="0.5rem"
          color={active ? "t1" : "t4"}
          fontWeight="semibold"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            cursor: "pointer"
          }}
        >
          {item.name}
        </Text>

        {item.actions && (
          <ActionsMenu placement={placement} item={item} ml="auto" mr={0} />
        )}
      </FlexWrapper>

      {open && item.items && item.items.length > 0 && (
        <Column pl={`${level * 0.5}rem`} width={1}>
          {item.items.map((uuid) => (
            <Item
              key={uuid}
              uuid={uuid}
              level={level + 1}
              levels={levels}
              module={module}
              selected={selected}
              selector={`${selector}L${level}.${uuid}.`}
              spaceId={spaceId}
            />
          ))}
        </Column>
      )}
    </>
  );
};

const Space = ({
  level = 1,
  levels,
  placement,
  selected,
  selectFirst,
  selector,
  module,
  uuid
}) => {
  const dispatch = useDispatch();

  const activeItem = useSelector((state) => state["spaces"].activeItem);
  const space = useSelector((state) => state[module].menu[uuid]);
  const items = useSelector((state) => state[module].menu[uuid].items);
  // console.log(useSelector((state) => state[module].menu[uuid]));

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const active = activeItem && activeItem.includes(selector);

  const disableOpen = levels === false || (levels !== false && level > levels);
  return (
    <>
      <FlexWrapper
        alignItems="center"
        borderRadius="0.25rem"
        px="0.5rem"
        py="0.5rem"
        width={1}
        hoverColor="l3"
        bg={active ? "l3" : ""}
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(ACTIONS.spaces.setSpaceId(space.id));
          space.onSelect && space.onSelect(space);
          dispatch(
            ACTIONS.state.setAttribute("spaces", "activeItem", selector)
          );
          dispatch(
            ACTIONS.state.setAttribute("spaces", "activeSpaceId", space.id)
          );
          space.linkTo && history.push(space.linkTo);
        }}
        mt="0.25rem"
      >
        <Flex
          p="0.25rem"
          onClick={(e) => {
            e.stopPropagation();
            if (disableOpen) return;
            !open && space.onOpen && space.onOpen(space);
            setOpen(!open);
          }}
        >
          <Icon.Triangle
            style={{
              transform: open ? "rotate(180deg)" : "rotate(90deg)",
              cursor: disableOpen ? "default" : "pointer"
            }}
            fill={active ? "grey" : "#c4c4c4"}
            strokeWidth="0px"
            size="8px"
          />
        </Flex>

        <Text
          ml={levels ? "" : "0.25rem"}
          mr="1rem"
          color={active ? "t1" : "t4"}
          fontWeight="semibold"
          fontSize="1rem"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            cursor: "pointer"
          }}
        >
          {space.name}
        </Text>

        {space.actions && (
          <ActionsMenu placement={placement} item={space} mr={0} ml="auto" />
        )}
      </FlexWrapper>

      {open && items && items.length > 0 && (
        <Column pl={`${level * 0.5}rem`} width={1}>
          {items.map((uuid) => (
            <Item
              uuid={uuid}
              key={uuid}
              level={level + 1}
              levels={levels}
              module={module}
              selected={selected}
              placement={placement}
              selector={`${selector}L${level}.${uuid}.`}
              spaceId={space.id}
            />
          ))}
        </Column>
      )}
    </>
  );
};

export const SpacesMenu = ({
  menu,
  levels = false,
  selected,
  selectFirst = true,
  showPublic = true,
  showAll = false,
  clearSelected = false,
  spacesActions = {},
  placement,
  module,
  ...props
}) => {
  const dispatch = useDispatch();

  const spaceId = useSelector((state) => state["spaces"].spaceId);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const orderedSpaces = useSelector((state) => state["spaces"].orderedSpaces);
  const user = useSelector((state) => state["account"].user);
  const loadedMenu = useSelector((state) => state[module].menu);

  const activeItem = useSelector((state) => state["spaces"].activeItem);
  const activeSpaceId = useSelector((state) => state["spaces"].activeSpaceId);

  const firstLoad = useRef();

  const filteredSpaces = useMemo(() => {
    const spaces = orderedSpaces
      .filter((space) => (showPublic || showAll ? true : !space.public))
      .filter(
        (space) =>
          showAll ||
          space.members.some((member) => parseInt(member.id) === user.id)
      );
    return spaces;
  }, [orderedSpaces, showPublic, showAll, user.id]);

  useEffect(() => {
    if (!spacesActions || !Object.keys(spacesActions).length) return;

    let newMenu = loadedMenu ? { ...loadedMenu } : {};

    filteredSpaces.forEach((space) => {
      if (!newMenu[space.id]) {
        newMenu[space.id] = { ...space, ...spacesActions[space.id] };
      }
    });

    dispatch(ACTIONS.state.setAttribute(module, "menu", newMenu));
  }, [dispatch, filteredSpaces, module, spaces, spacesActions]);

  useEffect(() => {
    if (menu) {
      dispatch(
        ACTIONS.state.setAttribute(module, "menu", {
          ...spacesActions,
          ...menu
        })
      );
    } else {
      let newMenu = {};
      filteredSpaces.forEach((space) => {
        if (!newMenu[space.id]) {
          newMenu[space.id] = { ...space, ...spacesActions[space.id] };
        }
      });
      dispatch(ACTIONS.state.setAttribute(module, "menu", newMenu));
    }
  }, [dispatch, menu, module]);

  useEffect(() => {
    if (!selectFirst || (selectFirst && firstLoad.current)) return;

    firstLoad.current = true;
    const id = filteredSpaces[0].id;
    const actions = spacesActions[id];
    actions.onOpen && actions.onOpen();
  }, [filteredSpaces, spacesActions, selectFirst]);

  useEffect(() => {
    if (
      (spaceId && !activeItem) ||
      !filteredSpaces.find((space) => space.id === activeSpaceId)
    ) {
      let space =
        filteredSpaces.find((space) => space.id === spaceId) ||
        filteredSpaces[0];

      dispatch(ACTIONS.spaces.setSpaceId(space.id));

      dispatch(
        ACTIONS.state.setAttribute("spaces", "activeItem", `L0.${space.id}.`)
      );
      dispatch(ACTIONS.state.setAttribute("spaces", "activeSpaceId", space.id));
    }
  }, [activeItem, activeSpaceId, dispatch, filteredSpaces, spaceId, spaces]);

  useEffect(() => {
    if (clearSelected) {
      dispatch(ACTIONS.state.setAttribute("spaces", "activeItem", null));
    }
  }, [clearSelected, dispatch]);

  if (!spaces) return null;

  return (
    <Flex alignItems="center" flexDirection="column" width={1} {...props}>
      {loadedMenu &&
        filteredSpaces.map((space, index) => (
          <Space
            uuid={space.id}
            key={space.id}
            selector={`L0.${space.id}.`}
            index={index}
            levels={levels}
            module={module}
            placement={placement}
          />
        ))}
    </Flex>
  );
};
