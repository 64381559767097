import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Flex, Icon, Input, Overlay, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";

export const NewFolder = ({ open, setOpen, space }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filters = useSelector((state) => state["files"].filters);

  const [name, setName] = useState();

  useEffect(() => {
    setName();
  }, []);

  const addFolder = () => {
    dispatch(
      ACTIONS.fetch.post(
        "files",
        `${API_ENDPOINT}/file/add_folder`,
        ACTIONS.state.loadObjects,
        { name, space_id: filters.spaceId, folder_id: filters.folderId },
        (state, response) => {
          state.files[response.id] = response;
          state.folders[filters.folderId || filters.spaceId][response.id] =
            response;

          return state;
        }
      )
    );
    setName("");
    setOpen(false);
  };

  if (!space) return null;

  return (
    <Overlay open={open} setOpen={setOpen}>
      <Flex
        m="auto"
        p="1rem"
        borderRadius="0.5rem"
        width="25rem"
        minWidth="20rem"
        minHeight="13rem"
        flexDirection="column"
        justifyContent="space-between"
        bg="l0"
        // boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
        overflow="visible"
      >
        <Text textStyle="subtitle">
          {t("Create folder on space") + " " + space.name}
        </Text>

        <Input
          label={t("Folder name")}
          maxWidth={600}
          placeholder={t("Folder name") + "..."}
          onChange={setName}
          focus={true}
        />

        <Flex mr={0} ml="auto">
          <Button
            variant="secondary"
            px="1rem"
            onClick={() => setOpen(false)}
            value={t("Cancel")}
          />

          <Button
            variant="primary"
            px="1rem"
            onClick={addFolder}
            value={t("Create folder")}
          />
        </Flex>
      </Flex>
    </Overlay>
  );
};

const Folder = ({ folder, spaceId, level = 0 }) => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state["files"].filters);
  const loadedFolders = useSelector(
    (state) => state["files"].folders[folder.id]
  );

  const [open, setOpen] = useState(false);

  let ml = 1 + level * 1.25;

  return (
    <Flex flexDirection="column">
      <Flex
        style={{ cursor: "pointer" }}
        borderRadius="0.25rem"
        ml={`${ml}rem`}
        my="0.125rem"
        py="0.25rem"
        px="0.5rem"
        width={1}
        hoverColor="l2"
        alignItems="center"
        bg={filters.folderId === folder.id ? "lightBlue" : ""}
        onClick={(e) => {
          dispatch(ACTIONS.files.setFilter("folderId", folder.id));
        }}
      >
        <Icon.Triangle
          size="8px"
          fill="grey"
          style={{
            transform: open ? "rotate(180deg)" : "rotate(90deg)"
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!loadedFolders)
              dispatch(ACTIONS.files.setFilter("folderId", folder.id));
            // setOpenFolder(!openFolder);

            setOpen(!open);
          }}
        />

        <Icon.Folder
          ml="0.375rem"
          style={{
            strokeWidth: "0px"
          }}
          fill={filters.folderId === folder.id ? "grey" : "grey"}
        />

        <Text
          ml="0.375rem"
          fontSize="0.875rem"
          color={filters.folderId === folder.id ? "blue" : "t2"}
          fontWeight={filters.folderId === folder.id ? "bold" : "semibold"}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            cursor: "pointer"
          }}
        >
          {folder.name}
        </Text>
      </Flex>

      {open &&
        loadedFolders &&
        Object.values(loadedFolders).map((folder) => (
          <Folder
            key={folder.id}
            folder={folder}
            spaceId={spaceId}
            level={level + 1}
          />
        ))}
    </Flex>
  );
};

export const Folders = ({ spaceId }) => {
  const files = useSelector((state) => state["files"].folders[spaceId]);
  const folders = useSelector((state) => state["files"].folders[spaceId]);

  if (!folders) return null;

  const loadedFolders = Object.values(files).filter(
    (file) => file.content_type === "folder"
  );

  return (
    <>
      {loadedFolders.map((folder) => (
        <Folder key={folder.id} folder={folder} spaceId={spaceId} />
      ))}
    </>
  );
};
