import accountReducer from "./account";
import comboboxReducer from "./combobox";
import { reducer as crud } from "./crud";
import { reducer as graphql } from "./graphql";
import listReducer from "./list";
import modalReducer from "./modal";
import networkReducer from "./network";
import stateReducer from "./state";
import toastReducer from "./toast";
import wsReducer from "./ws";

const allReducers = {
  accountReducer,
  comboboxReducer,
  crud, 
  graphql,
  listReducer,
  modalReducer,
  networkReducer,
  toastReducer,
  stateReducer,
  wsReducer
};

export { allReducers as REDUCERS };
