import { ACTIONS, STORE } from "store";

export class Crud {
  constructor(module, name, endpoint, loader) {
    this.module = module;
    this.name = name;
    this.endpoint = endpoint;
    this.loader = loader;
  }

  create(state, response, name) {
    state[name + "s"][response.id] = response;
    state[name + "sIds"].push(response.id);
    return state;
  }

  createOne(variables) {
    STORE.dispatch(
      ACTIONS.fetch.post(
        this.module,
        this.endpoint,
        ACTIONS.crud.set,
        variables,
        (state, response) => this.create(state, response, this.name)
      )
    );
  }

  deleteObject(state, response, name) {
    if (state[name + "s"][response.id]) {
      delete state[name + "s"][response.id];
    }

    state[name + "sIds"] = state[name + "sIds"].filter(
      (objId) => parseInt(objId) !== parseInt(response.id)
    );

    if (state[name]) state[name] = null;

    return state;
  }

  deleteOne(id) {
    STORE.dispatch(
      ACTIONS.fetch.delete(
        this.module,
        this.endpoint + id,
        ACTIONS.crud.set,
        (state, response) => this.deleteObject(state, response, this.name)
      )
    );
  }

  setObject(state, response, name, type) {
    state[name] = response[type];
    return state;
  }

  getOne(id) {
    STORE.dispatch(
      ACTIONS.fetch.get(
        this.module,
        this.endpoint + id,
        [ACTIONS.crud.set],
        this.loader.edit
          ? this.loader.edit
          : (state, response) => this.setObject(state, response, this.name)
      )
    );
  }

  setObjects(state, response, name) {
    state[name + "s"] = {};
    response.forEach((obj) => (state[name + "s"][obj.id] = obj));
    state[name + "sIds"] = Object.values(response).map((result) => result.id);
    return state;
  }

  getAll() {
    STORE.dispatch(
      ACTIONS.fetch.get(
        this.module,
        this.endpoint,
        [ACTIONS.crud.set],
        (state, response) => this.setObjects(state, response, this.name)
      )
    );
  }

  update(state, response, name) {
    if (state[name + "s"][response.id])
      state[name + "s"][response.id] = response;
    if (state[name]) state[name] = response;
    return state;
  }

  updateOne(id, variables) {
    STORE.dispatch(
      ACTIONS.fetch.patch(
        this.module,
        this.endpoint + id,
        ACTIONS.crud.set,
        variables,
        (state, response) => this.update(state, response, this.name)
      )
    );
  }
}
