import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { DEFAULT_MARGIN, DEFAULT_SIDEMENU_WIDTH } from "constant";
import { Header, MainWindow, SideMenu } from "components";
import { Spaces } from "./Spaces";
import { FilesList } from "./List";

export const Files = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filters = useSelector((state) => state["files"].filters);
  const folders = useSelector((state) => state["files"].folders);
  const spaces = useSelector((state) => state["spaces"].spaces);

  useEffect(() => {
    document.title = t("Files");
    return () => (document.title = "Elefante");
  }, [t]);

  useEffect(() => {
    if (filters.spaceId || filters.folderId) return;
    if (spaces)
      dispatch(
        ACTIONS.files.setFilter("spaceId", parseInt(Object.keys(spaces)[0]))
      );
  }, [dispatch, filters, spaces]);

  useEffect(() => {
    if (!filters.spaceId && !filters.folderId) return;

    let id = filters.folderId || filters.spaceId;
    if (folders[id]) return;

    let url = `${API_ENDPOINT}/file?limit=0`;

    if (filters.folderId) {
      url = url + `&folder_id=${filters.folderId}`;
    } else {
      url = url + `&root_id=${filters.spaceId}`;
    }

    dispatch(
      ACTIONS.fetch.get(
        "files",
        url,
        [ACTIONS.state.loadObjects],
        (state, response) => {
          let files = {};

          response.objects.forEach((file) => {
            files[file.id] = file;
          });

          state.files = { ...state.files, ...files };
          state.folders[id] = files;

          return state;
        }
      )
    );
  }, [dispatch, filters]);

  return (
    <Flex width={1} height="100%" label="Files">
      <SideMenu>
        <Text textStyle="title" p={DEFAULT_MARGIN}>
          {t("Files")}
        </Text>

        <Spaces />
      </SideMenu>

      <MainWindow flexGrow={1}>
        <FilesList />
      </MainWindow>
    </Flex>
  );
};
