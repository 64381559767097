import React from "react";

import { Flex } from "../Flex";
import { Text } from "../Text";

export const Counter = ({ count, ...props }) => {
  return (
    <Flex
      borderRadius="2rem"
      width="fit-content"
      height="fit-content"
      bg={count ? "userBold" : "l1"}
      {...props}
    >
      <Text
        fontWeight="semibold"
        fontSize="0.6875rem"
        my="0.125rem"
        mx="0.375rem"
        textAlign="center"
        color={count ? "t1" : "t3"}
      >
        {count}
      </Text>
    </Flex>
  );
};
