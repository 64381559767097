import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { addMinutes, format } from "date-fns";

import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { DatePicker } from "../DatePicker";
import { Dropdown } from "../Dropdown";
import { Flex } from "../Flex";
import { Text } from "../Text";

const TIMES = [
  "12:00am",
  "12:15am",
  "12:30am",
  "12:45am",
  "1:00am",
  "1:15am",
  "1:30am",
  "1:45am",
  "2:00am",
  "2:15am",
  "2:30am",
  "2:45am",
  "3:00am",
  "3:15am",
  "3:30am",
  "3:45am",
  "4:00am",
  "4:15am",
  "4:30am",
  "4:45am",
  "5:00am",
  "5:15am",
  "5:30am",
  "5:45am",
  "6:00am",
  "6:15am",
  "6:30am",
  "6:45am",
  "7:00am",
  "7:15am",
  "7:30am",
  "7:45am",
  "8:00am",
  "8:15am",
  "8:30am",
  "8:45am",
  "9:00am",
  "9:15am",
  "9:30am",
  "9:45am",
  "10:00am",
  "10:15am",
  "10:30am",
  "10:45am",
  "11:00am",
  "11:15am",
  "11:30am",
  "11:45am",
  "12:00pm",
  "12:15pm",
  "12:30pm",
  "12:45pm",
  "1:00pm",
  "1:15pm",
  "1:30pm",
  "1:45pm",
  "2:00pm",
  "2:15pm",
  "2:30pm",
  "2:45pm",
  "3:00pm",
  "3:15pm",
  "3:30pm",
  "3:45pm",
  "4:00pm",
  "4:15pm",
  "4:30pm",
  "4:45pm",
  "5:00pm",
  "5:15pm",
  "5:30pm",
  "5:45pm",
  "6:00pm",
  "6:15pm",
  "6:30pm",
  "6:45pm",
  "7:00pm",
  "7:15pm",
  "7:30pm",
  "7:45pm",
  "8:00pm",
  "8:15pm",
  "8:30pm",
  "8:45pm",
  "9:00pm",
  "9:15pm",
  "9:30pm",
  "9:45pm",
  "10:00pm",
  "10:15pm",
  "10:30pm",
  "10:45pm",
  "11:00pm",
  "11:15pm",
  "11:30pm",
  "11:45pm"
];

const TIME_OPTIONS = TIMES.map((time, index) => {
  return { id: time, value: time, index: index };
});

export const Clock = () => {
  return (
    <Flex
      minWidth="100%"
      minHeight="100%"
      borderRadius="50%"
      border="8px solid black"
      boxShadow="
        inset 40px 40px 90px rgba(0,0,0,.2),
        inset 10px 10px 30px rgba(0,0,0,.4), 
        20px 20px 30px rgba(0,0,0,.2),
        40px 40px 60px rgba(0,0,0,.2);"
    >
      <Flex
        width="100%"
        height="100%"
        borderRadius="50%"
        border="8px solid white"
        position="relative"
      >
        <Flex
          position="absolute"
          width="8%"
          height="8%"
          top="46%"
          left="46%"
          borderRadius="50%"
          bg="black"
          zIndex="8"
        />
        <Flex
          position="absolute"
          width="6%"
          height="6%"
          top="47%"
          left="47%"
          borderRadius="50%"
          bg="black"
          zIndex="10"
          boxShadow="-2px -2px 7px rgba(255,255,255,.6)"
        />
        <Flex
          label="Hour"
          width="40%"
          height="4%"
          position="absolute"
          zIndex="2"
          top="48%"
          left="43%"
          bg="black"
          borderTopRightRadius="20%"
          borderBottomRightRadius="20%"
        />
        <Flex
          label="Minute"
          width="52%"
          height="2%"
          position="absolute"
          zIndex="3"
          top="49%"
          left="45%"
          bg="red"
          borderTopRightRadius="30%"
          borderBottomRightRadius="30%"
          style={{ transform: "rotate(-160deg)", transformOrigin: "10%" }}
          boxShadow="10px 10px 10px rgba(0,0,0,.4)"
        />

        {[0, 30, 60, 90, 120, 150].map((deg) => {
          let style = {
            transform: `rotate(${deg}deg)`,
            transformOrigin: "50%"
          };

          return (
            <Flex
              key={deg}
              label="Tick"
              width="92%"
              height="1%"
              position="absolute"
              zIndex="3"
              top="50%"
              left="4%"
              bg="transparent"
              borderLeft="15px solid black"
              borderRight="15px solid black"
              style={style}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export const TimePicker = ({
  allDay,
  endTime,
  range,
  startTime,
  setStartTime,
  setEndTime
}) => {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Text fontWeight="semibold" mb="0.25rem">
          Start
        </Text>

        <Dropdown
          disabled={allDay}
          options={TIME_OPTIONS}
          selected={{
            id: TIMES[startTime],
            value: TIMES[startTime],
            index: startTime
          }}
          onSelect={setStartTime}
        />
      </Flex>

      {range && (
        <Flex flexDirection="column" mt="1rem">
          <Text fontWeight="semibold" mb="0.25rem">
            End
          </Text>

          <Dropdown
            disabled={allDay}
            options={TIME_OPTIONS.filter((time) => time.index > startTime)
              .filter((time, index) => {
                return index < 12;
              })
              .map((time, index) => {
                return {
                  index: time.index,
                  id: time.id,
                  value: time.value + ` (${index * 15 + 15}min)`
                };
              })}
            selected={{
              id: TIMES[endTime],
              value: TIMES[endTime],
              index: endTime
            }}
            onSelect={setEndTime}
          />
        </Flex>
      )}
    </Flex>
  );
};

export const DateTimePicker = ({
  onCancel,
  onSelect,
  blockPast = false,
  range = false
}) => {
  const { t } = useTranslation();

  let now = new Date(Math.ceil(new Date().getTime() / 900000) * 900000);

  const getTimeIndex = (time) => {
    return TIMES.findIndex((option) => option === time);
  };

  const [allDay, setAllDay] = useState(false);
  const [startDate, setStartDate] = useState(now);
  const [startTime, setStartTime] = useState(
    getTimeIndex(
      `${format(now, "h")}:${format(now, "mm")}${format(now, "aaaa").replaceAll(
        ".",
        ""
      )}`
    )
  );
  const [endDate, setEndDate] = useState(addMinutes(now, 15));
  const [endTime, setEndTime] = useState(
    getTimeIndex(
      `${format(addMinutes(now, 15), "h")}:${format(
        addMinutes(now, 15),
        "mm"
      )}${format(addMinutes(now, 15), "aaaa").replaceAll(".", "")}`
    )
  );

  useEffect(() => {
    setEndDate(startDate);
  }, [startDate]);

  const changeStartTime = (time) => {
    setStartTime(time.index);
    if (time.index > endTime) setEndTime(time.index);
  };

  const changeEndTime = (time) => {
    setEndTime(time.index);
  };

  const getHourMinutes = (date, time) => {
    var newDate = new Date(date.getTime());

    let times = /(.*):(.*)(am|pm)/g.exec(time);
    let hour = parseInt(times[1]);
    let minutes = parseInt(times[2]);
    let shift = times[3];
    if (shift === "pm") hour += 12;

    newDate.setHours(hour, minutes, 0);
    return newDate;
  };

  const save = () => {
    onSelect &&
      onSelect(
        getHourMinutes(startDate, TIMES[startTime]),
        TIMES[startTime],
        getHourMinutes(endDate, TIMES[endTime]),
        TIMES[endTime]
      );
  };

  return (
    <Flex>
      <Flex width="15rem">
        <DatePicker
          blockPast={blockPast}
          startDate={startDate}
          onSelect={setStartDate}
          enableEmptyDate={false}
        />
      </Flex>

      {false && (
        <Flex minWidth={200} minHeight={200} maxWidth={200} maxHeight={200}>
          <Clock />
        </Flex>
      )}

      <Flex ml="2rem" flexDirection="column" justifyContent="space-between">
        <Flex flexDirection="column">
          <TimePicker
            allDay={allDay}
            startTime={startTime}
            setStartTime={changeStartTime}
            endTime={endTime}
            setEndTime={changeEndTime}
            range={range}
          />

          {false && (
            <Flex mt="1rem">
              <Checkbox checked={allDay} onSelect={setAllDay} />
              <Text ml="1rem">{t("All day")}</Text>
            </Flex>
          )}
        </Flex>

        <Flex>
          <Button
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();
              onCancel && onCancel();
            }}
            value="Cancel"
          />
          <Button
            ml="1rem"
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              save();
            }}
            value="Save"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
