import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  addMonths,
  addYears,
  format,
  isSameDay,
  parseISO,
  subMonths,
  subYears
} from "date-fns";
import { useSelector } from "react-redux";
import { zonedTimeToUtc } from "date-fns-tz";

import { Button } from "../Button";
import { Grid } from "../Grid";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { getCalendar } from "./util";

const StyledText = styled(Flex)`
  cursor: pointer;
  height: 24px;
  min-width: 28px;
  min-height: 28px;
  font-size: 0.875rem;
  &:hover {
    background-color: ${(props) =>
      props.day.selected ? props.theme.colors.blue : props.theme.colors.l3};
  }

  ${(props) => {
    let day = props.day;

    let style = {};
    style.color = props.theme.colors.t1;

    if (day.isPast) {
      // style.cursor = "not-allowed";
    }

    if (!day.isThisMonth) {
      // style.visibility = "hidden";
    }

    if (day.isWeekend) {
      if (day.isPast) {
        style.color = props.theme.colors.t5;
      } else {
        style.color = props.theme.colors.t5;
      }
    }

    if (day.isToday && !(day.isStartDate || day.isEndDate)) {
      style.borderRadius = "50%";
      style.width = "14px";
      style.height = "14px";
      style.margin = "auto";
      style.backgroundColor = props.theme.colors.red;
      style.color = "white";
    }

    if (day.isInBetween && !day.isToday) {
      style.backgroundColor = props.theme.colors.lightBlue;
      style.borderRadius = 0;
    }

    if (day.isStartDate) {
      style.backgroundColor = props.theme.colors.blue;
      style.color = "white";
      if (props.range) {
        style.borderTopLeftRadius = "8px";
        style.borderBottomLeftRadius = "8px";
      } else {
        style.borderRadius = "8px";
        style.width = "14px";
        style.height = "14px";
      }
    }

    if (day.isEndDate) {
      style.backgroundColor = props.theme.colors.blue;
      style.color = "white";
      style.borderTopRightRadius = "8px";
      style.borderBottomRightRadius = "8px";
    }

    return { ...style };
  }};
`;

export const Calendar = ({ date, setDay, startDate, range, endDate }) => {
  const { t } = useTranslation();

  let month = getCalendar(date, startDate, endDate);

  useEffect(() => {}, [startDate]);

  const weekdays = [
    t("Sun"),
    t("Mon"),
    t("Tue"),
    t("Wed"),
    t("Thu"),
    t("Fri"),
    t("Sat")
  ];

  const saveDay = (e, day) => {
    e.stopPropagation();
    setDay(day);
  };

  return (
    <Grid
      width={1}
      gridTemplateRows={`repeat(${month.numberOfWeeks + 1}, 1fr)`}
      gridTemplateColumns={`repeat(${7}, 1fr)`}
      gridColumnGap={0}
      gridRowGap={1}
    >
      {weekdays.map((day, index) => (
        <Text
          m="auto"
          color="t5"
          fontWeight="bold"
          fontSize="0.625rem"
          key={index}
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            verticalAlign: "middle"
          }}
        >
          {t(day)}
        </Text>
      ))}

      {month.dates.map((day, index) => (
        <StyledText
          mx="auto"
          width={1}
          key={index}
          onClick={(e) => saveDay(e, day)}
          day={day}
          range={range}
          justifyContent="center"
          alignItems="center"
        >
          {day.date.getDate()}
        </StyledText>
      ))}
    </Grid>
  );
};

const normalizeDate = (date) => {
  if (!date) return date;
  let zonedDate = date;
  if (typeof date === "string") {
    zonedDate = parseISO(date);
    zonedDate.setHours(0, 0, 0);
    // zonedStartDate = utcToZonedTime(zonedStartDate, timezone);
  }
  return zonedDate;
};

export const DatePicker = ({
  enableEmptyDate = true,
  enableConfirmation = false,
  onSelect,
  saveDate,
  startDate,
  endDate,
  range = false,
  ...props
}) => {
  const timezone = useSelector((state) => state["account"].user.timezone);

  const [selectedStartDate, setSelectedStartDate] = useState(
    normalizeDate(startDate)
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    normalizeDate(endDate)
  );

  const [date, setCurrentDate] = useState(
    normalizeDate(startDate) || new Date()
  );

  if (saveDate && !onSelect) onSelect = saveDate;

  useEffect(() => {
    if (
      JSON.stringify(selectedStartDate) ===
      JSON.stringify(normalizeDate(startDate))
    )
      return;

    setSelectedStartDate(normalizeDate(startDate));
  }, [startDate]);

  useEffect(() => {
    if (endDate) setSelectedEndDate(normalizeDate(endDate));
  }, [endDate]);

  // console.log(selectedStartDate);

  const setDay = (day) => {
    // console.log(day)
    let newDate = day.date;
    // setDate(date, day);
    newDate.setHours(0, 0, 0);

    if (range) {
      if (selectedStartDate && selectedEndDate) {
        if (isSameDay(newDate, selectedEndDate) && enableEmptyDate) {
          setSelectedStartDate(null);
        } else if (isSameDay(newDate, selectedStartDate) && enableEmptyDate) {
          setSelectedEndDate(null);
        } else {
          setSelectedStartDate(newDate);
          if (enableEmptyDate) setSelectedEndDate(null);
        }
      } else if (selectedStartDate) {
        if (isSameDay(newDate, selectedStartDate)) {
          setSelectedEndDate(newDate);
          if (enableEmptyDate) setSelectedStartDate(null);
        } else if (newDate > selectedStartDate) {
          setSelectedEndDate(newDate);
        } else {
          setSelectedEndDate(selectedStartDate);
          setSelectedStartDate(newDate);
        }
      } else if (selectedEndDate) {
        if (isSameDay(newDate, selectedEndDate) && enableEmptyDate) {
          setSelectedEndDate(null);
        } else if (newDate < selectedEndDate) {
          setSelectedStartDate(newDate);
        } else {
          setSelectedStartDate(selectedEndDate);
          setSelectedEndDate(newDate);
        }
      } else setSelectedStartDate(newDate);
    } else {
      if (isSameDay(selectedStartDate, newDate) && enableEmptyDate) {
        setSelectedStartDate(null);
      } else {
        setSelectedStartDate(newDate);
      }
    }
  };

  const addMonth = () => {
    setCurrentDate(addMonths(date, 1));
  };

  const subMonth = () => {
    setCurrentDate(subMonths(date, 1));
  };

  const addYear = () => {
    setCurrentDate(addYears(date, 1));
  };

  const subYear = () => {
    setCurrentDate(subYears(date, 1));
  };

  let month = format(date, "MMM");
  let year = format(date, "yyyy");

  const save = () => {
    let startDate = selectedStartDate
      ? zonedTimeToUtc(selectedStartDate, timezone)
      : null;

    if (range) {
      let endDate = selectedEndDate
        ? zonedTimeToUtc(selectedEndDate, timezone)
        : null;

      onSelect && onSelect(startDate, endDate);
    } else {
      onSelect && onSelect(startDate);
    }
  };

  useEffect(() => {
    if (
      JSON.stringify(selectedStartDate) ===
      JSON.stringify(normalizeDate(startDate))
    )
      return;
    save();
  }, [selectedStartDate]);

  return (
    <Flex
      flexDirection="column"
      style={{ "&:hover": { backgroundColor: "black" } }}
      width={1}
      // ref={ref}
      {...props}
    >
      <Flex width={1} mb={2} pb={2} borderBottom="1px solid lightGrey">
        <Flex width={0.5} px={2} alignItems="center">
          <Icon.ChevronLeft
            stroke="red"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              subMonth();
            }}
          />
          <Text
            fontSize="0.875rem"
            style={{ textTransform: "uppercase" }}
            mx="auto"
            fontWeight="bold"
          >
            {month}
          </Text>
          <Icon.ChevronRight
            stroke="red"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              addMonth();
            }}
          />
        </Flex>

        <Flex width={0.5} px={2} alignItems="center">
          <Icon.ChevronLeft
            stroke="red"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              subYear();
            }}
          />
          <Text fontSize={14} mx="auto" fontWeight="bold">
            {year}
          </Text>
          <Icon.ChevronRight
            stroke="red"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              addYear();
            }}
          />
        </Flex>
      </Flex>

      <Calendar
        date={date}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        setDay={setDay}
        range={range}
      />

      {enableConfirmation && (
        <Flex justifyContent="flex-end" mt={4}>
          <Button
            variant="secondary"
            mr={2}
            onClick={(e) => {
              e.stopPropagation();
              if (typeof setOpen === "function") setOpen(false);
            }}
            value="Cancel"
          />

          <Button
            variant="primary"
            disabled={
              (range &&
                zonedStartDate === selectedStartDate &&
                zonedEndDate &&
                zonedEndDate === selectedEndDate) ||
              (!range && zonedStartDate === selectedStartDate)
            }
            onClick={(e) => {
              e.stopPropagation();
              save();
            }}
            value="Save"
          />
        </Flex>
      )}
    </Flex>
  );
};
