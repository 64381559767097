import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text, useLocalStorage } from "tomato";

import { ACTIONS } from "store";
import Admin from "./Admin";
import { Dashboard } from "./Dashboard";
import { DEFAULT_MARGIN } from "constant";
import { Header, MainWindow, MembersList, SideMenu } from "components";
import onDragEnd from "./onDragEnd";
import { Search } from "./Search";
import Sidebar from "./Task";
import { Spaces } from "./Spaces";
import Tasks from "./Tasks";
import { TasksHeader } from "./Header";
import { Widgets } from "./Widgets";

const Main = () => {
  const dispatch = useDispatch();
  const [view, setView] = useLocalStorage("view", "TASKS");

  const [enableSidebar, setEnableSidebar] = useState(false);
  useEffect(() => setEnableSidebar(true), []);

  useEffect(() => {
    dispatch(ACTIONS.events.send("Tasks view", {}));
  }, [dispatch]);

  return (
    <>
      <MainWindow>
        <Header>
          <TasksHeader setView={setView} view={view} />
        </Header>

        {false && view === "CALENDAR" && <Widgets.Calendar useFilters={true} />}

        {false && view === "DASHBOARD" && <Dashboard />}

        {view === "TASKS" && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Tasks />
          </DragDropContext>
        )}
      </MainWindow>

      {enableSidebar && <Sidebar />}
    </>
  );
};

const _Tasks = () => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state["tasks"].filters);
  let spaceId = filters.spaceId;

  return (
    <Flex
      width={1}
      height="100%"
      label="Tasks"
      minWidth="0px" // evita task expandir parent
    >
      <Admin />

      <SideMenu>
        <Text textStyle="title" p={DEFAULT_MARGIN}>
          {t("Tasks")}
        </Text>

        <Flex flexDirection="column" overflow="auto" px={DEFAULT_MARGIN}>
          <Search />
          <Spaces />
        </Flex>
      </SideMenu>

      <Main />

      {false && (
        <SideMenu>
          <MembersList spaceId={spaceId} />
        </SideMenu>
      )}
    </Flex>
  );
};

export { _Tasks as Tasks };

// import Pomodoro from "components/Pomodoro";
// import ShortcutHelp from "./ShortCuts";
// <ShortcutHelp open={open} setOpen={setOpen} />
