import React, { useState } from "react";
import { RRule } from "rrule";

import { Dropdown, Flex } from "tomato";

import {
  daysOptions,
  monthOptions,
  weekDaysOptions,
  reversedWeekdayPosition,
  workingDaysOptions,
  weekdays,
  weekdayPosition
} from "./config";

const RenderMonthly = ({ rrule, updateRRule }) => {
  const getOption = (rrule) => {
    // by month day
    if (rrule.bymonthday) return monthOptions[0];

    // by working day
    if (rrule.bysetpos && Array.isArray(rrule.byweekday))
      return monthOptions[1];

    // by week day
    let n = reversedWeekdayPosition[rrule.bysetpos];
    return monthOptions[n - 1];
  };

  const [option, setOption] = useState(getOption(rrule));

  let newRRule = { ...rrule };

  const setMonthRule = (option) => {
    setOption(option);

    if (option.id === 1) {
      delete newRRule.byweekday;
      delete newRRule.bysetpos;
      newRRule.bymonthday = [new Date().getDate()];
    } else if (option.id === 2) {
      delete newRRule.byweekday;
      delete newRRule.bymonthday;
      newRRule.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
      newRRule.bysetpos = 1;
    } else {
      delete newRRule.bymonthday;
      newRRule.bysetpos = weekdayPosition[option.id];
      if (!newRRule.byweekday || Array.isArray(newRRule.byweekday))
        newRRule.byweekday = RRule.MO;
    }

    updateRRule(newRRule);
  };

  const setMonthDay = (option) => {
    newRRule.bymonthday = [option.id];
    delete newRRule.byweekday;
    delete newRRule.bysetpos;
    updateRRule(newRRule);
  };

  const setWorkingDay = (option) => {
    newRRule.bysetpos = option.id;
    newRRule.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
    delete newRRule.bymonthday;
    updateRRule(newRRule);
  };

  const setWeekDay = (option) => {
    newRRule.byweekday = weekdays[option.id];
    delete newRRule.bymonthday;
    updateRRule(newRRule);
  };

  if (!(rrule.bymonthday || rrule.bysetpos || rrule.byweekday)) return null;

  const getValue = () => {
    // day o the month
    if (option.id === 1)
      return daysOptions.find((option) => option.id === rrule.bymonthday[0]);

    // working day of the month
    if (option.id === 2) {
      return workingDaysOptions.find((option) => option.id === rrule.bysetpos);
    }

    // day of the week
    if (rrule.byweekday) {
      return weekDaysOptions.find(
        (option) => option.id === rrule.byweekday.weekday
      );
    } else {
      return RRule.MO;
    }
  };

  return (
    <Flex>
      <Dropdown
        width="10rem"
        options={monthOptions}
        selected={option}
        onSelect={setMonthRule}
        mr={2}
      />

      {option && option.id === 1 && (
        <Dropdown
          width="5rem"
          options={daysOptions}
          selected={getValue()}
          onSelect={setMonthDay}
        />
      )}

      {option && option.id === 2 && (
        <Dropdown
          width="5rem"
          options={workingDaysOptions}
          selected={getValue()}
          onSelect={setWorkingDay}
        />
      )}

      {option && [3, 4, 5, 6, 7].includes(option.id) && (
        <Dropdown
          width="5rem"
          options={weekDaysOptions}
          selected={getValue()}
          onSelect={setWeekDay}
        />
      )}
    </Flex>
  );
};

export default RenderMonthly;
