import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Draggable, Flex, FormatDate, Icon, Text, useInterval } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { pauseTimer } from "modules";

const RenderTimer = () => {
  const dispatch = useDispatch();

  const inProgress = useSelector((state) => state["tasks"].inProgress);
  const tasks = useSelector((state) => state["tasks"].objs);

  const history = useHistory();

  const task = inProgress && tasks[inProgress];

  const [time, setTime] = useState("0:00:00");

  const calcTimer = useCallback(() => {
    let totalSeconds = task.time_spent;

    if (task.timer_start) {
      totalSeconds = FormatDate.Difference(task.timer_start) + totalSeconds;
    }

    let hours = parseInt(totalSeconds / 3600);
    totalSeconds = totalSeconds % 3600;

    let minutes = parseInt(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;

    let seconds = parseInt(totalSeconds);

    hours = ("0" + hours).slice(-2);
    minutes = ("0" + minutes).slice(-2);
    seconds = ("0" + seconds).slice(-2);

    setTime(`${hours}:${minutes}:${seconds}`);
  }, [task]);

  useInterval(calcTimer, 1000);

  const editTask = () => {
    history.push(`/tasks#${task.id}`);
  };

  const pause = (e) => {
    e.stopPropagation();
    pauseTimer(task);
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/pause_timer`,
        ACTIONS.tasks.updateTimer
      )
    );
  };

  return (
    <Flex
      bg="green"
      borderRadius="0.5rem"
      boxShadow="0px 0px 20px 0px rgba(0,0,0,0.30)"
      alignItems="center"
      px="1rem"
      py="0.5rem"
      onClick={editTask}
      width="18rem"
      style={{ cursor: "pointer" }}
      height="4rem"
      maxHeight="4rem"
    >
      <Flex
        borderRadius="50%"
        onClick={(e) => pause(e)}
        bg="lightGreen"
        p="0.25rem"
      >
        <Icon.Pause
          stroke="green"
          size="medium"
          style={{ strokeWidth: "2px", cursor: "pointer" }}
        />
      </Flex>

      <Text
        color="t0"
        fontWeight="bold"
        mx="0.5rem"
        fontSize="0.875rem"
        maxHeight="2rem"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "no wrap",
          overflow: "hidden"
        }}
      >
        {task.subject}
      </Text>

      <Text
        mr={0}
        ml="auto"
        color="t0"
        width="5rem"
        textAlign="right"
        fontSize="0.875rem"
        fontWeight="semibold"
      >
        {time}
      </Text>
    </Flex>
  );
};

export const Timer = () => {
  const inProgress = useSelector((state) => state["tasks"].inProgress);

  return (
    <>
      {inProgress && (
        <Draggable id="timer">
          <RenderTimer />
        </Draggable>
      )}
    </>
  );
};
