import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Flex, formatDate, Text } from "tomato";

import { AddTask } from "modules";
import { ACTIONS } from "store";
import { Dots } from "./Input";
import { Message } from "./Message";
import { Poll } from "./Poll";
import { Profile } from "./Profile";
import { Thread } from "./Threads";

export const Messages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );

  const conversation = useSelector(
    (state) => state["conversations"].conversations[activeConversation]
  );
  const user = useSelector((state) => state["account"].user);
  const ws = useSelector((state) => state["ws"].ws);

  const [newTask, setNewTask] = useState();

  useEffect(() => {
    if (ws && activeConversation && !conversation) {
      ws.send(
        JSON.stringify({
          type: "conversations",
          action: "subscribeSpace",
          data: {
            acc: activeConversation
          }
        })
      );
    }
  }, [activeConversation, conversation, ws]);

  useEffect(() => {
    if (ws && conversation && !conversation.loaded)
      ws.send(
        JSON.stringify({
          type: "conversations",
          action: "getMessages",
          data: {
            acc: activeConversation
          }
        })
      );
  }, [activeConversation, conversation, ws]);

  useEffect(() => {
    if (!ws || !activeConversation || !conversation) return;

    if (
      conversation.last_message_timestamp >
      conversation.last_message_timestamp_read_by_you
    ) {
      const lastMessageReadId = Object.keys(conversation.messages).slice(-1)[0];

      if (lastMessageReadId) {
        dispatch(
          ACTIONS.conversations.read({
            acc: activeConversation
          })
        );

        const read = JSON.stringify({
          type: "conversations",
          action: "read",
          data: {
            id: conversation.id,
            acc: activeConversation,
            lastMessageReadId: Object.keys(conversation.messages).slice(-1)[0]
          }
        });
        ws.send(read);
      }
    }
  }, [activeConversation, conversation, dispatch, ws]);

  if (typeof conversation === "undefined") return null;

  let count = Object.keys(conversation.messages).length - 1;

  let newUser;
  let userChanged = false;
  let nextUser;

  let date = null;
  let dateChanged = false;
  let nextDate;

  let messages = Object.values(conversation.messages).reverse();

  let lastMessageReadId = conversation.last_message_read_id;

  let type = conversation.type;

  if (!conversation.loaded)
    return (
      <Flex mb="1rem" ml="2rem" alignItems="center">
        <Dots />
        <Dots />
        <Dots />
        <Text ml="0.5rem" fontSize="0.675rem" fontWeight="semibold" color="t4">
          {t("loading messages")}
        </Text>
      </Flex>
    );

  if (messages.length === 0 && conversation.loaded)
    return (
      <Flex alignItems="center" m="1rem" mt="auto">
        <Avatar
          size="large"
          src="/images/logo/face.jpg"
          style={{ cursor: "pointer" }}
        />
        <Text fontSize="1rem" ml="1rem">
          {t("Reminder. All messages posted here will disappear in 24h.")}
        </Text>
      </Flex>
    );

  const addTask = (description, ownerId) => {
    setNewTask({
      description,
      ownerId,
      typeId: 1
    });
  };

  return (
    <Flex width={1} height="100%">
      {newTask && (
        <AddTask
          full={true}
          description={newTask.description}
          ownerId={newTask.ownerId}
          setOpen={setNewTask}
        />
      )}

      <Flex
        flexDirection="column-reverse"
        width={1}
        overflow="auto"
        flexShrink="1"
      >
        {messages.map((msg, index) => {
          if (dateChanged) date = nextDate;

          if (userChanged) newUser = nextUser;

          if (index < count) {
            nextUser = messages[index + 1].user_id;

            nextDate = formatDate(
              messages[index + 1].creation_date,
              user.locale,
              user.timezone
            ).fullFriendlyDate();
          }

          if (!newUser) newUser = msg.user_id;

          if (!date) {
            date = formatDate(
              msg.creation_date,
              user.locale,
              user.timezone
            ).fullFriendlyDate();
          }

          dateChanged = index === count || nextDate !== date;
          userChanged = index === count || nextUser !== newUser;

          if (true || msg.type_id === 1)
            return (
              <Message
                key={index}
                index={index}
                msg={msg}
                type={type}
                read={lastMessageReadId && lastMessageReadId >= msg.id}
                newUser={dateChanged || userChanged}
                date={dateChanged && date}
                acc={activeConversation}
                addTask={addTask}
              />
            );

          if (msg.type_id === 2)
            return (
              <Poll
                key={index}
                index={index}
                msg={msg}
                type={type}
                read={lastMessageReadId && lastMessageReadId >= msg.id}
                newUser={dateChanged || userChanged}
                date={dateChanged && date}
                acc={activeConversation}
              />
            );

          return null;
        })}
      </Flex>

      <Thread />
    </Flex>
  );
};
