import { t } from "i18n";

export const OBJECT_TYPES = {
  1: "Task",
  2: "Topic",
  3: "File"
};

export const HISTORY_TYPES = {
  1: t("Task created"),
  2: t("Task assigned"),
  3: t("Task started"),
  4: t("Task paused"),
  5: t("Task done"),
  6: t("Task deleted"),
  7: t("File uploaded"),
  8: t("File deleted"),
  9: t("Note added"),
  10: t("Note deleted"),
  11: t("Deadline changed"),
  12: t("Start date changed"),
  13: t("Task accepted"),
  14: t("Task refused"),
  15: t("Note replied"),
  16: t("Reacted to a note"),
  17: t("Asked for an update"),
  18: t("Task undoned"),
  19: t("Started following"),
  20: t("Stopped following"),
  21: t("Mentioned you"),
  22: t("Added follower"),
  23: t("Follower removed"),
  24: t("Invite sent"),
  25: t("Invite confirmed"),
  26: t("Invited refused"),
  27: t("Invite undecided"),
  28: t("Invite cancelled"),
  29: t("Topic posted"),
  30: t("Topic replied"),
  31: t("Topic reaction")
};
