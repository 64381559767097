import React from "react";

import { useSelector } from "react-redux";

import { Overlay, Provider } from "tomato";

import { GlobalAlerts } from "components";
import { i18n } from "i18n";
import { MODE } from "config";
import { Login, ReAuth } from "views/Login";
import {
  PushNotification,
  WebSocket,
  Loading,
  RefreshData,
  Reminder
} from "modules";
import { Routes } from "routes";
import { Storage } from "storage";
import { Timer } from "modules/Tasks/Timer";

i18n.changeLanguage("pt-BR");

const Head = () => {
  const script = document.createElement("script");
  let key = MODE === "PROD" ? "AP-UEWHFMVAQITB-2" : "AP-UEWHFMVAQITB-2-4";

  document.head.appendChild(script);
  script.innerHTML = `
    <!-- Gainsight PX Tag-->
  (function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
      (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
    var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
    var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
  })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${key}");
<!-- Gainsight PX Tag-->      
    `;
  return null;
};

const Identify = () => {
  const user = useSelector((state) => state["account"].user);

  const script = document.createElement("script");
  document.body.appendChild(script);
  script.innerHTML = `
	aptrinsic("identify",
	  {
		"id": "${user.id}", // Required for logged in app users
		"email": "${user.email}",
		"firstName": "${user.name.split(" ")[0]}",
		"lastName": "${user.name.split(" ")[1]}",
		"signUpDate": 1522697426479, //unix time in ms
	  },
	  {
		"id":"1688", //Required
		"name":"Tasks",
	 });
    `;
  return null;
};

const PlaySound = () => {
  const zenMode = useSelector((state) => state["account"].zenMode);
  if (zenMode) return;

  let audio = new Audio("audio/im_recv.mp3");
  audio.load();

  if (navigator.serviceWorker) {
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === "PLAY_SOUND") {
        audio.play();
      }
    };
  }

  return null;
};

export const App = () => {
  const ready = useSelector((state) => state["app"].ready);
  const userTheme = useSelector((state) => state["account"].preferences.theme);
  const color = useSelector((state) => state["account"].preferences.color);
  const user = useSelector((state) => state["account"].user);

  if (!user || (user && !user.authenticated)) {
    return (
      <Provider theme={userTheme}>
        <Login />
      </Provider>
    );
  }

  if (user && user.needsAuth)
    return (
      <Provider theme={userTheme}>
        <Overlay blocking={true} open={user.needsAuth}>
          <ReAuth />
        </Overlay>
      </Provider>
    );

  return (
    <>
      <RefreshData />

      {!ready ? (
        <Loading />
      ) : (
        <>
          <Provider
            locale="pt-BR"
            tz="America/Sao_Paulo"
            theme={userTheme}
            color={color}
          >
            {false && <Head />}
            <GlobalAlerts />
            <Reminder />
            <PushNotification />
            <Storage />
            <Routes />
            <Timer />
          </Provider>
          <PlaySound />
          <WebSocket />
          {false && <Identify />}
        </>
      )}
    </>
  );
};
