import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Action,
  Avatar,
  ClickToEdit,
  Confirm,
  Editor,
  Emoji,
  EmojiPicker,
  Empty,
  Flex,
  formatDate,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, ROOT_URL } from "config";

import { Breadcrumb } from "./Breadcrumb";
import { Header } from "components";

export const Page = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const item = useSelector((state) => state["pages"].item);
  const spaceId = useSelector((state) => state["pages"].spaceId);
  const page = useSelector((state) => state["pages"].pages[item.id]);
  const menu = useSelector((state) => state["pages"].menu);
  const editingPage = useSelector((state) => state["pages"].editingPage);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [open, setOpen] = useState(false);

  const [content, setContent] = useState(page.content);
  const [icon, setIcon] = useState(page.icon);
  const [title, setTitle] = useState(page.title);

  useEffect(() => {
    if (!title) return;
    document.title = title;
    return () => (document.title = "Elefante");
  }, [title]);

  const save = (content, title, icon) => {
    if (
      JSON.stringify(page.content) === JSON.stringify(content) &&
      title === page.title &&
      icon === page.icon
    )
      return;

    dispatch(
      ACTIONS.fetch.patch(
        "pages",
        `${API_ENDPOINT}/page/${page.id}`,
        ACTIONS.state.loadObjects,
        { content, title, icon },
        (state, response) => {
          state.pages[page.id] = { ...state.pages[page.id], ...response };
          state.page = { ...page, ...response };
          state.menu[item.uuid].name = title;
          return state;
        }
      )
    );
  };

  const deletePage = () => {
    setOpen(false);
    dispatch(ACTIONS.state.setAttribute("pages", "item", null));

    dispatch(
      ACTIONS.fetch.delete(
        "pages",
        `${API_ENDPOINT}/page/${page.id}`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          let childrensUuid = menu[item.uuid].items || [];
          let parentPageUuid = menu[item.uuid].parentUuid || null;

          childrensUuid.forEach((uuid) => {
            state.menu[uuid].parentUuid = parentPageUuid;
            const newUuid = `${parentPageUuid}.${menu[uuid].id}`;
            state.menu[newUuid] = state.menu[uuid];
            state.menu[newUuid].uuid = newUuid;
            delete state.menu[uuid];

            if (parentPageUuid) state.menu[parentPageUuid].items.push(newUuid);
          });

          if (parentPageUuid) {
            state.menu[parentPageUuid].items = state.menu[
              parentPageUuid
            ].items.filter((uuid) => uuid !== item.uuid);
          }

          state.item = null;
          state.editingPage = false;
          delete state.pages[page.id];
          delete state.menu[item.uuid];

          return state;
        }
      )
    );
  };

  if (!page || !content) return <Empty />;

  const getTitle = () => {
    return title || t("Untitled");
  };
  return (
    <>
      <Confirm
        title={t("Delete") + " " + t("page")}
        content={
          t("Are you sure that you want to delete") + " " + getTitle() + "?"
        }
        open={open}
        setOpen={setOpen}
        onConfirm={deletePage}
        yesText={t("Yes, delete")}
        noText={t("No, take me back")}
      />

      <Header>
        <Flex
          width={1}
          alignItems="center"
          justifyContent="space-between"
          style={{ visibility: editingPage ? "hidden" : "visible" }}
        >
          <Breadcrumb />

          <Flex alignItems="center">
            <Action
              onClick={() => {
                setOpen(true);
              }}
            >
              {t("Delete")}
            </Action>

            <Action
              ml="0.5rem"
              onClick={() => {
                editingPage
                  ? save()
                  : dispatch(
                      ACTIONS.state.setAttribute("pages", "editingPage", true)
                    );
              }}
            >
              {editingPage ? t("Save") : t("Edit")}
            </Action>
          </Flex>
        </Flex>
      </Header>

      <Flex height="100%" overflow="auto" width={1}>
        <Flex
          mb="1rem"
          width={1}
          // width="80%"
          height="100%"
          minWidth="20rem"
          // maxWidth="60rem"
          mx="auto"
          flexDirection="column"
          // overflow="auto"
        >
          <Flex alignItems="center" mb="2rem">
            {icon.type && <Emoji code={icon.code} size="4rem" />}

            {editingPage && (
              <Flex ml={icon.type ? "1rem" : ""} style={{ cursor: "pointer" }}>
                <EmojiPicker
                  random={!icon.code}
                  onSelect={(code) => {
                    save(content, title, { type: "emoji", code });
                    setIcon({ type: "emoji", code });
                  }}
                />
              </Flex>
            )}
          </Flex>

          <ClickToEdit
            enabled={editingPage}
            fontSize="1.75rem"
            fontWeight="bold"
            value={page && page.title ? page.title : ""}
            placeholder={t("Untitled")}
            onChange={setTitle}
            onBlur={() => save(content, title, icon)}
            autoFocus={editingPage}
          />

          <Flex mt="0.5rem" mb="2rem">
            <Avatar
              src={users[page.updated_by_id || page.created_by_id].avatar}
            />
            <Text ml="0.25rem" color="t3" fontSize="0.875rem">
              {users[page.updated_by_id || page.created_by_id].name},{" "}
              {formatDate(
                page.update_date || page.creation_date,
                user.locale,
                user.timezone
              ).distance()}
            </Text>
          </Flex>

          <Editor
            editorStyle={{
              style: {
                // margin: "1rem 1rem 1rem 1rem",
                // position: "relative",
                padding: "1rem",
                lineHeight: 1.5
                // paddingTop: "3rem"
              }
            }}
            toolBarStyle={{
              position: "absolute",
              width: 1,
              padding: "0.75rem 1rem ",
              top: 0,
              left: 0,
              bg: "white"
            }}
            enableExit={true}
            debounceTime={2500}
            enableHint={true}
            enabled={editingPage}
            onSave={(content) => {
              setContent(content);
              dispatch(
                ACTIONS.state.setAttribute("pages", "editingPage", false)
              );
              save(content, title, icon);
            }}
            autoSave={(content) => {
              setContent(content);
              save(content, title, icon);
            }}
            value={content}
            fileRootUrl={`${ROOT_URL}/shared/`}
            uploadEndpoint={`${API_ENDPOINT}/upload`}
            uploadId={{ module: "PAGE", space_id: spaceId, page_id: page.id }}
          />

          <Flex height="3rem" />
        </Flex>
      </Flex>
    </>
  );
};
