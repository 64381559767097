import React, { Fragment } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Icon, Text, uuidv4 } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT, ROOT_URL } from "config";
import { ShortAnswer, LongAnswer } from "./Answer";
import { QUESTION_TYPE } from "constant";

const PAGE_ITEM_TYPE = {
  1: "Question",
  TITLE: 2,
  3: "Image",
  4: "Video"
};

const PageItemType = {
  1: "Question",
  2: "Title",
  3: "Image",
  4: "Video"
};

const QUESTION = 1;
const TITLE = 2;
const IMAGE = 3;
const VIDEO = 4;
const PAGE = 5;

const DEFAULT_ITEMS = {
  1: {
    id: uuidv4(),
    title: "O que vc acha?",
    description: "Description da questao",
    placeholder: "Question",
    typeId: 1,
    questionTypeId: QUESTION_TYPE.CHECKBOX,
    questions: [
      { id: 1, value: "Option 1" },
      { id: 2, value: "teste" }
    ],
    image: "",
    required: false
  },
  2: {
    id: uuidv4(),
    title: "Title",
    description: "Description",
    typeId: PAGE_ITEM_TYPE.TITLE,
    image: ""
  },
  3: {
    id: uuidv4(),
    title: "Title",
    description: "Description",
    typeId: PAGE_ITEM_TYPE.IMAGE,
    image: ""
  },
  4: {
    id: uuidv4(),
    title: "Title",
    description: "Description",
    typeId: PAGE_ITEM_TYPE.VIDEO,
    image: ""
  },
  5: {
    title: "Title",
    description: "Description da secao",
    color: "",
    backgroundColor: "",
    image: "",
    afterPage: "",
    items: []
  }
};

export const Menu = ({ pageIndex, itemIndex }) => {
  const dispatch = useDispatch();

  const form = useSelector((state) => state["forms"].form.content);

  const addPage = (pageIndex, itemIndex) => {
    dispatch(ACTIONS.forms.addPage(itemIndex, pageIndex));
  };

  const deleteItem = (pageIndex, itemIndex) => {
    dispatch(ACTIONS.forms.deleteItem(itemIndex, pageIndex));
  };

  const addItem = (typeId, pageIndex, itemIndex) => {
    dispatch(ACTIONS.forms.addItem(typeId, itemIndex, pageIndex));
  };

  const add = (typeId) => {
    if (typeId === PAGE) {
      addPage(pageIndex, itemIndex);
    } else {
      addItem(typeId, pageIndex, itemIndex);
    }
  };

  const options = [
    { icon: "Plus", text: "Add question", onAdd: QUESTION },
    { icon: "Plus", text: "Add title", onAdd: TITLE },
    { icon: "Image", text: "Add image", onAdd: IMAGE },
    { icon: "Video", text: "Add video", onAdd: VIDEO },
    { icon: "Plus", text: "Add page", onAdd: PAGE }
  ];

  const getOption = (option) => {
    let RenderIcon = Icon[option.icon];

    return (
      <Flex
        style={{ cursor: "pointer" }}
        onClick={() => {
          add(option.onAdd);
        }}
        hoverColor="lightBlue"
        py="0.5rem"
        px="1rem"
        borderRadius="0.5rem"
      >
        <RenderIcon style={{ strokeWidth: "2px" }} />
        <Text ml="0.5rem">{option.text}</Text>
      </Flex>
    );
  };

  return (
    <Flex
      bg="l0"
      p="0.5rem"
      borderRadius={8}
      flexDirection="column"
      boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
    >
      {options.map((option, index) => (
        <Fragment key={index}>{getOption(option)}</Fragment>
      ))}

      <Flex flexDirection="column" mt="1rem">
        <Flex
          style={{ cursor: "pointer" }}
          onClick={() => {}}
          hoverColor="lightBlue"
          py="0.5rem"
          px="1rem"
          borderRadius="0.5rem"
        >
          <Icon.Clone style={{ strokeWidth: "2px" }} />
          <Text ml="0.5rem">Clone</Text>
        </Flex>
        {(form.pages.length > 1 || itemIndex || itemIndex === 0) && (
          <Flex
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteItem(pageIndex, itemIndex);
            }}
            hoverColor="lightBlue"
            py="0.5rem"
            px="1rem"
            borderRadius="0.5rem"
          >
            <Icon.Trash style={{ strokeWidth: "2px" }} />
            <Text ml="0.5rem">
              Delete {!itemIndex && itemIndex !== 0 && "page"}{" "}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
