import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Dropdown, Flex, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

export const Priority = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);

  const setPriority = (priority) => {
    if (priority && priority.id === task.priority_id) return;

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { priority_id: priority ? priority.id : null }
      )
    );
  };

  const PRIORITIES = {
    1: "Low",
    2: "Medium",
    3: "High"
  };

  const PRIORITY_OPTIONS = [
    { id: 1, value: "Low" },
    { id: 2, value: "Medium" },
    { id: 3, value: "High" }
  ];

  return (
    <Flex alignItems="center" mt="2rem">
      <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
        {t("Priority")}
      </Text>

      <Dropdown
        width={1}
        key={task.priority_id}
        options={PRIORITY_OPTIONS}
        onSelect={setPriority}
        placeholder={t("Set priority")}
        enableClear={true}
        selected={
          task.priority_id
            ? {
                id: task.priority_id,
                value: PRIORITIES[task.priority_id]
              }
            : null
        }
      />
    </Flex>
  );
};
