import { t } from "i18n";

export const TYPE = {
  ALL: { id: "ALL", value: t("All types") },
  TASK: { id: "TASK", value: t("Tasks") },
  EVENT: { id: "EVENT", value: t("Events") }
};

export const TYPE_OPTIONS = Object.values(TYPE);

export const OWNER_OPTIONS = [
  { id: "OWNED_BY_YOU", value: t("Owned by you") },
  { id: "WITHOUT_OWNER", value: t("Without owner") },
  { id: "OWNED_BY_OTHERS", value: t("Owned by others") }
];

// ASSIGNED_FOR_YOU: {
//     id: "ASSIGNED_FOR_YOU",
//     value: t("Someone assigned to you")
// },

export const APPROVAL_STATUS = {
  1: { status: "Waiting", color: "yellow" },
  2: { status: "Approved", color: "green" },
  3: { status: "Refused", color: "red" },
  4: { status: "Approved with restrictions", color: "orange" }
};

const TASK = 1;
const EVENT = 2;
const APPROVAL = 3;
const PAYMENT = 4;

export const TASK_TYPE = {
  TASK,
  EVENT,
  APPROVAL,
  PAYMENT
};

export const ASSIGNMENT = {
  SPECIFIC_USER: { id: "SPECIFIC_USER", value: t("A specific user") },
  ASSIGNED_TO_ALL: { id: "ASSIGNED_TO_ALL", value: t("All users") },
  ASSIGNED_TO_YOU: { id: "ASSIGNED_TO_YOU", value: t("Your tasks") },
  ASSIGNED_FOR_YOU: { id: "ASSIGNED_FOR_YOU", value: t("Assigned to you") },
  ASSIGNED_BY_YOU: {
    id: "ASSIGNED_BY_YOU",
    value: t("You assigned to someone")
  },
  ASSIGNED_TO_OTHER: { id: "ASSIGNED_TO_OTHER", value: t("Not your tasks") },
  UNASSIGNED: { id: "UNASSIGNED", value: t("Unassigned") },
  PARTICIPANT: { id: "PARTICIPANT", value: t("Your are a participant") }
};

export const ASSIGNMENT_OPTIONS = Object.values(ASSIGNMENT);

export const DUE = {
  ANYTIME: { id: "ANYTIME", value: t("Anytime") },
  DUE: { id: "DUE", value: t("Due") },
  DUE_TODAY: { id: "DUE_TODAY", value: t("Due today") },
  DUE_TOMORROW: { id: "DUE_TOMORROW", value: t("Due tomorrow") },
  UPCOMING: { id: "UPCOMING", value: t("Upcoming") },
  UNDEFINED: { id: "UNDEFINED", value: t("Undefined") }
};

export const DUE_OPTIONS = Object.values(DUE);

export const STATUS = {
  OPEN: { id: "OPEN", value: t("Open") },
  CLOSED: { id: "CLOSED", value: t("Closed") },
  OPEN_AND_CLOSED: { id: "OPEN_AND_CLOSED", value: t("All status") }
};

export const STATUS_OPTIONS = Object.values(STATUS);

export const PERIOD = {
  NO_PERIOD: { id: "NO_PERIOD", value: t("In any date") },
  TODAY: { id: "TODAY", value: t("Today") },
  YESTERDAY: { id: "YESTERDAY", value: t("Yesterday") },
  THIS_WEEK: { id: "THIS_WEEK", value: t("This week") },
  LAST_WEEK: { id: "LAST_WEEK", value: t("Last week") },
  THIS_MONTH: { id: "THIS_MONTH", value: t("This month") },
  LAST_MONTH: { id: "LAST_MONTH", value: t("Last month") }
};

export const PERIOD_OPTIONS = Object.values(PERIOD);

export const PRIORITIES = {
  ALL_PRIORITIES: { id: "ALL_PRIORITIES", value: t("All priorities") },
  URGENT: { id: "URGENT", value: t("Urgent") },
  NOT_URGENT: { id: "NOT_URGENT", value: t("Not urgent") }
};

export const PRIORITIES_OPTIONS = Object.values(PRIORITIES);

export const MEMBER_TYPE = {
  USER: 1,
  ADMIN: 2,
  GUEST: 3
};

export const MEMBER_DESCRIPTION = {
  1: t("User"),
  2: t("Administrator"),
  3: t("Guest")
};

export const TIMEFRAME = { 1: t("Anytime"), 2: t("Someday") };
