import React, { Fragment } from "react";
import { useSlate } from "slate-react";
import { useEditor } from "slate-react";

import { Flex } from "../../Flex";
import { Icon } from "../../Icon";
import { Text } from "../../Text";
import { ToolTip } from "../../ToolTip";
import { UI } from "../../../constants";

import { toggleBlock, isBlockActive } from "./Block";
import { toggleMark, isMarkActive } from "./Mark";
import { insertLink } from "../Helpers/Link";
import { Audio } from "../Helpers/Audio";
import { Emoji } from "../Helpers/Emoji";
import { File } from "../Helpers/File";

const fileOptions = [
  // {
  //   icon: "Mic",
  //   name: "audio",
  //   format: "audio",
  //   type: "audio",
  //   render: Audio
  // },
  {
    icon: "Paperclip",
    name: "file",
    format: "file",
    type: "file",
    render: File
  },
  {
    icon: "Smile",
    name: "emoji",
    format: "emoji",
    type: "emoji",
    render: Emoji
  }
];

const typeOptions = [
  { icon: "Bold", name: "Bold", format: "bold", type: "mark" },
  { icon: "Italic", name: "Italic", format: "italic", type: "mark" },
  { icon: "Underline", name: "Underline", format: "underline", type: "mark" },
  { icon: "Strike", name: "Strike", format: "strike", type: "mark" },
  {
    icon: "NumberedList",
    name: "Numbered list",
    format: "numbered-list",
    type: "block",
    strokeWidth: "1px",
    fill: "#4c4c4c"
  },
  {
    icon: "Quote",
    name: "Quote",
    format: "block-quote",
    type: "block",
    fill: "#4c4c4c"
  },
  { icon: "Code", name: "Code", format: "code", type: "mark" },
  // { name: "h1", format: "Heading-one", type: "block" },
  // { name: "h2", format: "Heading-two", type: "block" },
  { icon: "List", name: "List", format: "bulleted-list", type: "block" },
  // {
  //   icon: "Config",
  //   name: "Numbered list",
  //   format: "numbered-list",
  //   type: "block"
  // },
  // { icon: "Config", name: "Quote", format: "block-quote", type: "block" },
  { icon: "Link", name: "Link", format: "link", type: "link" }
];

const Option = ({ option }) => {
  const editor = useSlate();

  const setOption = () => {
    if (option.type === "link") {
      let url = window.prompt("Enter the URL of the link:");
      if (!url) return;
      insertLink(editor, url);
    } else if (option.type === "mark") {
      toggleMark(editor, option.format);
    } else if (option.type === "block") toggleBlock(editor, option.format);
  };

  const isActive = () => {
    if (option.type === "mark") return isMarkActive(editor, option.format);
    return isBlockActive(editor, option.format);
  };

  let RenderIcon;
  if (option.icon) RenderIcon = Icon[option.icon];

  let style = { cursor: "pointer" };
  if (isActive(editor, option.format)) style = { bg: "l4" };

  return (
    <ToolTip content={option.name} location={UI.POSITION.TOP}>
      <Flex
        mr="0.5rem"
        onMouseDown={(e) => {
          e.preventDefault();
          setOption();
        }}
        borderRadius="0.25rem"
        p="0.5rem"
        {...style}
        style={{ cursor: "pointer" }}
      >
        {RenderIcon ? (
          <RenderIcon
            width="1rem"
            minWidth="1rem"
            stroke="black"
            style={{ strokeWidth: "2px" }}
          />
        ) : (
          <Text>{option.name}</Text>
        )}
      </Flex>
    </ToolTip>
  );
};

export const Toolbar = ({
  enableArrow,
  fileRootUrl,
  isEmpty,
  saveContent,
  uploadEndpoint,
  uploadId,
  ...props
}) => {
  const editor = useEditor();

  return (
    <Flex
      bg="l2"
      width={1}
      alignItems="center"
      minHeight="36px"
      height="36px"
      px={2}
      justifyContent="space-between"
      {...props}
    >
      <Flex alignItems="center">
        {typeOptions.map((option, index) => {
          let Render = Fragment;

          if (option.render) {
            Render = option.render;
          }

          return (
            <Render key={index}>
              <Option option={option} editor={editor} />
            </Render>
          );
        })}
      </Flex>

      <Flex alignItems="center" mx="0.5rem">
        {fileOptions.map((option, index) => {
          let Render = Fragment;

          if (option.render) {
            Render = option.render;
          }

          let style = {};
          if (!uploadEndpoint && ["file", "audio"].includes(option.type))
            return null;

          if (["file", "audio"].includes(option.type))
            style = { fileRootUrl, uploadEndpoint, uploadId };

          return (
            <Render key={index} {...style} isEmpty={isEmpty} editor={editor}>
              <Option option={option} />
            </Render>
          );
        })}

        {enableArrow && (
          <Flex
            width="fit-content"
            borderRadius="0.25rem"
            ml="1rem"
            bg={isEmpty ? "" : "blue"}
            style={{ cursor: isEmpty ? "" : "pointer" }}
            p="0.5rem"
            onClick={(e) => {
              e.stopPropagation();
              if (isEmpty) return;
              saveContent();
            }}
          >
            <Icon.Triangle
              width="1rem"
              minWidth="1rem"
              stroke="lightGrey"
              style={{
                strokeWidth: "0px",
                fill: isEmpty ? "lightGrey" : "white",
                transform: "rotate(90deg)"
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
