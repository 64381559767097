import React from "react";

import { Flex } from "../Flex";
import { Text } from "../Text";

export const Switch = ({
  checked,
  label,
  onCheck,
  reverseLabel = false,
  size,
  ...props
}) => {
  let small = size === "SMALL";
  let componentSize = size ? "0.625rem" : "1rem";

  return (
    <Flex alignItems="center" {...props}>
      {reverseLabel && (
        <Text
          mr={small ? "0.5rem" : "0.25rem"}
          fontSize={small ? "0.875rem" : "0.9375rem"}
          fontWeight="semibold"
        >
          {label}
        </Text>
      )}

      <Flex
        borderRadius="2rem"
        bg={checked ? "green" : "l5"}
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation();
          onCheck(!checked);
        }}
        style={{ cursor: "pointer" }}
      >
        <Flex
          m="0.125rem"
          mr={checked ? "" : componentSize}
          ml={checked ? componentSize : ""}
          bg="l0"
          height={componentSize}
          width={componentSize}
          borderRadius="1rem"
        />
      </Flex>

      {!reverseLabel && (
        <Text
          ml={small ? "0.25rem" : "0.5rem"}
          fontSize={small ? "0.875rem" : "0.9375rem"}
          fontWeight="semibold"
        >
          {label}
        </Text>
      )}
    </Flex>
  );
};
