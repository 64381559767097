import ReactDOM from "react-dom";

import { Editor } from "slate";

export * from "./Block";
export * from "./Mark";

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

export const isFormatActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n[format] === true,
    mode: "all"
  });
  return !!match;
};
