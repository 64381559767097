import styled from "styled-components";
import Tippy from "@tippyjs/react";

import {
  border,
  color,
  grid,
  layout,
  position,
  shadow,
  space
} from "styled-system";

import "tippy.js/dist/tippy.css";
import 'tippy.js/themes/light.css';

// border: 1px solid ${props.theme.colors[props.borderColor] || "blue"};

const Tippy_ = styled(Tippy)`
   ${border}
   ${color}
   ${grid}
   ${layout}
   ${position}
   ${shadow}
   ${space}
   ${(props) => {
     // return `       
     // color: ${props.theme.colors["white"]};


     // background: ${
     //   props.theme.colors[props.color] || props.theme.colors["black"]
     // };

     // &[data-placement^='top'] > .tippy-arrow::before {
     //    border-top-color: ${
     //      props.theme.colors[props.borderColor] ||
     //      props.theme.colors[props.color] ||
     //      props.theme.colors["black"]
     //    };
     // }  
     // &[data-placement^='bottom'] > .tippy-arrow::before {
     //    border-bottom-color: ${
     //      props.theme.colors[props.borderColor] ||
     //      props.theme.colors[props.color] ||
     //      props.theme.colors["black"]
     //    };
     // }   

     // &[data-placement^='left'] > .tippy-arrow::before {
     //    border-left-color: ${
     //      props.theme.colors[props.borderColor] ||
     //      props.theme.colors[props.color] ||
     //      props.theme.colors["black"]
     //    };
     // }   

     // &[data-placement^='right'] > .tippy-arrow::before {
     //    border-right-color: ${
     //      props.theme.colors[props.borderColor] ||
     //      props.theme.colors[props.color] ||
     //      props.theme.colors["black"]
     //    };
     // }            
    // `;
   }}

`;
 
Tippy_.defaultProps = {
  zIndex: 99999
};     

export { Tippy_ as Tippy };
