import React, { useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Card, ClickToEdit, Flex, SimpleEditor, Switch, Text } from "tomato";

import { ACTIONS } from "store";
import { Menu } from "./Menu";

export const Page = ({ pageIndex, page }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const editing = useSelector((state) => state["forms"].editing);
  const form = useSelector((state) => state["forms"].form.content);

  const [instance, setInstance] = useState(null);

  const updatePage = (key, value) => {
    dispatch(ACTIONS.forms.updatePage(pageIndex, key, value));
  };

  const isEditing =
    editing.pageIndex === pageIndex &&
    !editing.itemIndex &&
    editing.itemIndex !== 0;

  const setEditing = (editing) => {
    dispatch(ACTIONS.state.setAttribute("forms", "editing", editing));
  };

  let color = `hsl(${form.settings.color.h}, ${form.settings.color.s}%, 50%)`;

  return (
    <Tippy
      placement="right-start"
      // trigger="click"
      interactive={true}
      offset={[32, 8]}
      appendTo="parent"
      render={(attrs) => (
        <>{instance ? <Menu {...attrs} pageIndex={pageIndex} /> : ""}</>
      )}
      onCreate={setInstance}
    >
      <Flex
        flexDirection="column"
        onClick={() => setEditing({ pageIndex, itemIndex: null })}
      >
        <Flex
          bg={color}
          px="1rem"
          py="0.5rem"
          borderRadius="0.5rem"
          width="fit-content"
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
        >
          <Text fontWeight="semibold" color="t0" fontSize="0.875rem">
            Page {pageIndex + 1} of {form.pages.length}
          </Text>
        </Flex>

        <Card
          width={1}
          flexDirection="column"
          mb="1rem"
          p="1.5rem"
          pt="1.25rem"
          borderTopLeftRadius={0}
          borderLeft="6px solid"
          borderColor={isEditing ? color : "transparent"}
        >
          <ClickToEdit
            fontWeight="bold"
            color="t1"
            fontSize="1.5rem"
            enabled={true}
            value={page.title}
            placeholder={t("Enter title...")}
            onBlur={(name) => updatePage("title", name)}
          />

          <SimpleEditor
            enableToolbar={true}
            autoFocus={false}
            key={page.id}
            value={page.description}
            width={1}
            placeholder={t("Write a new note...")}
            plainText={false}
            // onEnter={changeD}
            enabled={true}
          />

          {isEditing && (
            <Flex
              alignItems="center"
              mt="1.5rem"
              pt="1rem"
              borderTop="1px solid lightGrey"
            >
              <Switch
                checked={page.showDescription}
                onCheck={(value) => updatePage("showDescription", value)}
                text={t("Show description")}
              />
            </Flex>
          )}
        </Card>
      </Flex>
    </Tippy>
  );
};
