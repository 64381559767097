import React from "react";

import { Flex } from "tomato";

export const SideMenu = ({ title, children, add, tooltip, ...props }) => {
  return (
    <Flex
      minWidth="20rem"
      width="20rem"
      maxWidth="20rem"
      overflow="auto"
      flexDirection="column"
      {...props}
    >
      {children}
    </Flex>
  );
};
