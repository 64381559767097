export const MODE = process.env.REACT_APP_MODE;
export const TRANSLATIONS_DIR = process.env.REACT_APP_TRANSLATIONS_DIR;

export const ENV = {};

ENV["DEV"] = {
  ROOT_URL: process.env.REACT_APP_DEV_URL,
  API_ROOT: process.env.REACT_APP_DEV_URL + process.env.REACT_APP_API_URL,
  API_ENDPOINT: process.env.REACT_APP_DEV_API_ENDPOINT,
  WS_URL: process.env.REACT_APP_DEV_WS_URL || "",
  ENABLE_WS: process.env.REACT_APP_DEV_ENABLE_WS === "true",
  SEND_EVENTS: process.env.REACT_APP_DEV_SEND_EVENTS === "true",
  AMPLITUDE_API_KEY: process.env.REACT_APP_DEV_AMPLITUDE_API_KEY
};

ENV["PROD"] = {
  ROOT_URL: process.env.REACT_APP_PROD_URL,
  API_ROOT: process.env.REACT_APP_PROD_URL + process.env.REACT_APP_API_URL,
  API_ENDPOINT: process.env.REACT_APP_PROD_API_ENDPOINT,
  WS_URL: process.env.REACT_APP_PROD_WS_URL || "",
  ENABLE_WS: process.env.REACT_APP_PROD_ENABLE_WS === "true",
  SEND_EVENTS: process.env.REACT_APP_PROD_SEND_EVENTS === "true",
  AMPLITUDE_API_KEY: process.env.REACT_APP_PROD_AMPLITUDE_API_KEY
};

const {
  ROOT_URL,
  API_ROOT,
  API_ENDPOINT,
  WS_URL,
  ENABLE_WS,
  AMPLITUDE_API_KEY,
  SEND_EVENTS
} = ENV[MODE];

export {
  ROOT_URL,
  API_ROOT,
  API_ENDPOINT,
  WS_URL,
  ENABLE_WS,
  AMPLITUDE_API_KEY,
  SEND_EVENTS
};
