import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

import { ACTIONS } from "store";
import { Filters } from "modules/Tasks/Filters/Filters";
import { filter } from "modules/Tasks/filter";
import { RenderCalendar } from "./RenderCalendar";
import { Header, Main, SideMenu } from "components";
import { DEFAULT_MARGIN, DEFAULT_SIDEMENU_WIDTH } from "constant";
import { Sidebar } from "modules/Tasks/Task/Sidebar";

export const Calendar = ({ useFilters = false, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filters = useSelector((state) => state["tasks"].calendarFilters);
  const lists = useSelector((state) => state["tasks"].lists);
  const projectId = useSelector((state) => state["tasks"].filters.projectId);
  const spaceId = useSelector((state) => state["tasks"].filters.spaceId);
  const tasks = useSelector((state) => state["tasks"].objs);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  // No react 17 o clean up das tasks é assíncrono, precisamos terminar ele executar para recarregar
  // o sidebar
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true), []);

  useEffect(() => {
    document.title = t("Calendar");
    return () => (document.title = "Elefante");
  }, [t]);

  // useEffect(() => {
  // dispatch(ACTIONS.events.send("Calendar view", {}));
  // }, [dispatch]);

  const filtered = useMemo(() => {
    return filter(
      filters,
      Object.values(tasks).map((task) => task),
      user
    );
  }, [filters, tasks, user]);

  const [events, setEvents] = useState({});

  useEffect(() => {
    if (!filtered) return;
    let newEvents = {};
    let key;

    // Object.values(useFilters ? filtered.filtered : filtered.all).forEach(
    Object.values(filtered.filtered).forEach((task) => {
      // if (filters.status === "CLOSED") key = task.doneKey;
      // if (filters.status === "OPEN") key = task.deadlineKey;
      // if (filters.status === "OPEN_AND_CLOSED")
      key = task.deadlineKey || task.doneKey;

      if (!newEvents[key]) newEvents[key] = [];

      let list = lists[task.list_id];

      newEvents[key].push({
        ...task,
        color: `hsl(${list.color.h}, ${list.color.s}%, 94%)`,
        url: `/space/${task.space_id}/tasks/${task.id}`,
        done: task.doneKey
      });
    });
    setEvents(newEvents);
  }, [
    filters,
    filtered,
    lists,
    projectId,
    spaceId,
    tasks,
    user.id,
    useFilters
  ]);

  return (
    <>
      <Flex alignItems="flex-start" height="100%" width={1} overflow="auto">
        <SideMenu>
          <Text textStyle="title" p={DEFAULT_MARGIN}>
            {t("Calendar")}
          </Text>

          <Filters view="calendar" />
        </SideMenu>

        <Main>
          <RenderCalendar events={events} users={users} />
        </Main>
      </Flex>

      {loaded && <Sidebar />}
    </>
  );
};
