import React, { Fragment } from "react";
import { matchPath } from "react-router";
import { useTranslation } from "react-i18next";

import { Flex, Icon, Link, Text } from "../";
import { getColor } from "../../themes/util";
// import ToolTip from "../ToolTip";
// import { POSITION } from "config/constants";

const RenderOption = ({ item, showTitle }) => {
  const { t } = useTranslation();
  const isMenuActive = (path) => {
    return matchPath(window.location.pathname, {
      path: path
    });
  };

  const OptionIcon = Icon[item.icon || "Settings"];

  // <OptionIcon
  //   {...iconStyle}
  //   height={20}
  //   width={20}
  //   minHeight={20}
  //   minWidth={20}
  // />

  let isActive = isMenuActive(item.path);

  let iconStyle = {};
  if (isActive) {
    iconStyle = { stroke: getColor("black") };
  }

  let titleStyle = {};
  if (!showTitle) {
    titleStyle = { style: { display: "none", visibility: "hidden" } };
  }
  // <ToolTip
  //  content={t(item.title)}
  //  location={POSITION.RIGHT}
  //  enabled={!showTitle}
  // >

  return (
    <Link to={item.path}>
      <Flex
        bg={isActive ? "l3" : "transparent"}
        borderRadius="0.25em"
        hoverColor="l3"
        alignItems="center"
        color="t2"
        fontSize="0.875rem"
        fontWeight={isActive ? "semibold" : "regular"}
        mb="0.25em"
        p="0.5em"
        style={{ cursor: "pointer" }}
      >
        <OptionIcon {...iconStyle} size="14px" />

        <Text ml="0.5em" {...titleStyle}>
          {t(item.title)}
        </Text>
      </Flex>
    </Link>
  );
};

// </ToolTip>
export const RenderMenu = ({ menu, showTitle = true }) => {
  const { t } = useTranslation();

  return (
    <Flex px={4} width={1} overflow="auto" height="100%" flexDirection="column">
      {menu.map((mainLevel, index) => (
        <Fragment key={index}>
          <Flex flexDirection="column" mt={index ? "2em" : "0"}>
            {showTitle && (
              <Text mb="0.5em" ml="0.5em" textStyle="subsection">
                {t(mainLevel.title)}
              </Text>
            )}
          </Flex>

          {mainLevel.items.map((item, index) => {
            return (
              <RenderOption key={index} item={item} showTitle={showTitle} />
            );
          })}
        </Fragment>
      ))}
    </Flex>
  );
};
