import React, { useEffect, useState } from "react";

import { Flex, formatDate, Icon, Text } from "tomato";

const Time = () => {
  const [time, setTime] = useState(new Date());

  let delay = 60000;

  useEffect(() => {
    const handler = setInterval(() => {
      setTime(new Date());
    }, delay);

    return () => {
      clearInterval(handler);
    };
  }, [delay]);

  return (
    <Flex alignItems="center">
      <Text textStyle="subtitle">{formatDate(new Date()).friendlyDate()}</Text>
      <Icon.Clock size="small" ml="0.5rem" mr="0.25rem" />
      <Text color="t4" fontWeight="bold">
        {formatDate(time).hour()}
      </Text>
    </Flex>
  );
};

export default Time;
