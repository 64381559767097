import { createActions, handleActions } from "redux-actions";

const modelId = "documents";

const options = { prefix: "documents", namespace: "::" };

const EMPTY_CONTENT = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

export const actions = createActions(
  {
    ADD_document: (response) => ({
      module: modelId,
      response
    }),

    UPDATE_CONTENT: (content) => ({
      module: modelId,
      content
    }),

    UPDATE_document: (document) => ({
      module: modelId,
      document
    })
  },
  options
);

export const reducer = handleActions(
  {
    ADD_document: (state, { payload: { response } }) => {
      state.document = response;
      return state;
    },

    UPDATE_CONTENT: (state, { payload: { content } }) => {
      state.document.content = content;
      return state;
    },

    UPDATE_document: (state, { payload: { document } }) => {
      state.document = document;
      return state;
    }
  },
  {},
  options
);
