import React, { useCallback, useEffect, useState } from "react";
import {
  addMonths,
  addYears,
  addWeeks,
  subWeeks,
  format,
  parseISO,
  subMonths,
  subYears
} from "date-fns";

import { Flex, Icon, Dropdown, Text } from "tomato";

import { Month } from "./Month";
import { Week } from "./Week";

function useClientRect() {
  const [rect, setRect] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}

export const RenderCalendar = ({
  events,
  startDate,
  endDate,
  range = false,
  users,
  locale,
  onClick,
  timzeone,
  ...props
}) => {
  const [view, setView] = useState("WEEK");

  let zonedStartDate = startDate;
  let zonedEndDate = endDate;

  if (typeof startDate === "string") {
    zonedStartDate = parseISO(startDate);
    zonedStartDate.setHours(0, 0, 0);
    // zonedStartDate = utcToZonedTime(zonedStartDate, timezone);
  }

  if (typeof endDate === "string") {
    zonedEndDate = parseISO(endDate);
    zonedEndDate.setHours(0, 0, 0);
    // zonedEndDate = utcToZonedTime(zonedEndDate, timezone);
  }

  useEffect(() => {}, [startDate, endDate]);

  const [date, setCurrentDate] = useState(zonedStartDate || new Date());

  const addMonth = () => {
    setCurrentDate(addMonths(date, 1));
  };

  const subMonth = () => {
    setCurrentDate(subMonths(date, 1));
  };

  const addWeek = () => {
    setCurrentDate(addWeeks(date, 1));
  };

  const subWeek = () => {
    setCurrentDate(subWeeks(date, 1));
  };

  const addYear = () => {
    setCurrentDate(addYears(date, 1));
  };

  const subYear = () => {
    setCurrentDate(subYears(date, 1));
  };

  let month = format(date, "MMMM");
  let year = format(date, "yyyy");

  const options = {
    MONTH: "Month",
    WEEK: "Week"
    // DAY: "Day"
  };

  const viewOptions = [
    {
      id: "MONTH",
      value: "Month"
    },
    { id: "WEEK", value: "Week" }
    // { id: "DAY", value: "Day" }
  ];

  const changeView = (option) => {
    setView(option.id);
  };

  const addEvent = (e, date) => {
    console.log(e, date);
    console.log(e.clientX, e.clientY);
  };

  return (
    <Flex
      flexDirection="column"
      style={{ "&:hover": { backgroundColor: "black" } }}
      width={1}
      overflow="auto"
      height="100%"
      {...props}
    >
      <Flex
        width={1}
        my={2}
        pb={2}
        justifyContent="space-between"
        borderBottom="1px solid lightGrey"
      >
        {view !== "YEAR" ? (
          <Flex px={2} alignItems="center">
            <Icon.ChevronLeft
              stroke="red"
              style={{ cursor: "pointer", strokeWidth: "2px" }}
              onClick={(e) => {
                e.stopPropagation();
                view === "MONTH" ? subMonth() : subWeek();
              }}
            />
            <Icon.ChevronRight
              stroke="red"
              style={{ cursor: "pointer", strokeWidth: "2px" }}
              onClick={(e) => {
                e.stopPropagation();
                view === "MONTH" ? addMonth() : addWeek();
              }}
            />
            <Text ml="0.5rem" fontSize="1.25rem" fontWeight="bold" color="t2">
              {month} {year}
            </Text>
          </Flex>
        ) : (
          <Flex width={0.5} px={2} alignItems="center">
            <Icon.ChevronLeft
              stroke="red"
              style={{ cursor: "pointer", strokeWidth: "2px" }}
              onClick={(e) => {
                e.stopPropagation();
                subYear();
              }}
            />
            <Icon.ChevronRight
              stroke="red"
              style={{ cursor: "pointer", strokeWidth: "2px" }}
              onClick={(e) => {
                e.stopPropagation();
                addYear();
              }}
            />
            <Text fontSize={14} mx="auto" fontWeight="bold">
              {year}
            </Text>
          </Flex>
        )}

        <Dropdown
          width="8rem"
          options={viewOptions}
          selected={{ id: view, value: options[view] }}
          onSelect={changeView}
        />
      </Flex>

      {view === "WEEK" && (
        <Week
          date={date}
          range={range}
          events={events}
          users={users}
          view={view}
          onClick={addEvent}
        />
      )}

      {view === "MONTH" && (
        <Month
          date={date}
          range={range}
          events={events}
          users={users}
          view={view}
          onClick={addEvent}
        />
      )}
    </Flex>
  );
};
