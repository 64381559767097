import React, { useEffect, useState } from "react";
import { RRule } from "rrule";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, formatDate, Input, Options, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import { calcRecur } from "../util";

import RenderDaily from "./recur/Daily";
import RenderMonthly from "./recur/Monthly";
import RenderWeekly from "./recur/Weekly";
import RenderYearly from "./recur/Yearly";

const rruleOptions = {};
rruleOptions["NEVER"] = "Never";
rruleOptions[RRule.DAILY] = "Daily";
rruleOptions[RRule.WEEKLY] = "Weekly";
rruleOptions[RRule.MONTHLY] = "Monthly";
rruleOptions[RRule.YEARLY] = "Yearly";

const DAILY = 3;
const WEEKLY = 2;
const MONTHLY = 1;
const YEARLY = 0;

const info = {
  3: { singular: "day", plural: "days" },
  2: { singular: "week", plural: "weeks" },
  1: { singular: "month", plural: "months" },
  0: { singular: "year", plural: "years" }
};

const RRULES = {
  DAILY: { id: DAILY, value: "Daily" },
  WEEKLY: { id: WEEKLY, value: "Weekly" },
  MONTHLY: { id: MONTHLY, value: "Monthly" },
  YEARLY: { id: YEARLY, value: "Yearly" }
};

const OPTIONS = {
  3: { id: DAILY, value: "Daily" },
  2: { id: WEEKLY, value: "Weekly" },
  1: { id: MONTHLY, value: "Monthly" },
  0: { id: YEARLY, value: "Yearly" }
};

const RenderInterval = ({ rrule, updateRRule }) => {
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);

  const [nextStartDate, setNextStartDate] = useState();

  const setInterval = (interval) => {
    if (!interval) return;
    updateRRule({ ...rrule, interval });
  };

  const interval =
    rrule.interval === 1 ? info[rrule.freq].singular : info[rrule.freq].plural;

  useEffect(() => {
    let fromDate = task.type_id === 2 ? task.start_date : task.deadline;
    setNextStartDate(calcRecur(rrule, fromDate));
  }, [rrule, task.type_id, task.start_date, task.deadline]);

  return (
    <>
      <Flex alignItems="center" my={3}>
        <Text mr="0.5rem">{t("Every")}</Text>
        <Input
          minWidth="2rem"
          maxWidth="3rem"
          height="2rem"
          onChange={setInterval}
          value={rrule.interval}
          type="Number"
        />
        <Text ml="0.5rem">{t(interval)}</Text>
      </Flex>

      {rrule.freq === DAILY && (
        <RenderDaily rrule={rrule} updateRRule={updateRRule} />
      )}

      {rrule.freq === WEEKLY && (
        <RenderWeekly rrule={rrule} updateRRule={updateRRule} />
      )}

      {rrule.freq === MONTHLY && (
        <RenderMonthly rrule={rrule} updateRRule={updateRRule} />
      )}

      {rrule.freq === YEARLY && (
        <RenderYearly rrule={rrule} updateRRule={updateRRule} />
      )}

      {nextStartDate && (
        <Text mt="0.5rem" fontSize="0.75rem" color="t3">
          {t("Next date")}: {formatDate(nextStartDate).fullFriendlyDate()}
        </Text>
      )}
    </>
  );
};

const Recur = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);

  const [rrule, setRRule] = useState(task.rrule);

  useEffect(() => {
    setRRule(task.rrule);
  }, [task]);

  const updateRRule = (rrule) => {
    setRRule(rrule);

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { rrule }
      )
    );
  };

  const setFreq = (option) => {
    let freq = option.id;
    let interval = freq || freq === 0 ? rrule.interval || 1 : 1;

    let rule = {};

    if (freq !== null) {
      rule = {
        freq,
        interval
      };
    }

    if (freq === 0) {
      // yearly  default
      rule.bymonth = [1];
      rule.bymonthday = [1];
    } else if (freq === 1) {
      // monthly default
      rule.bymonthday = [new Date().getDate()];
    }
    updateRRule(rule);
  };

  if (!task.deadline || Object.keys(task.rrule).length === 0) return null;

  return (
    <>
      <Flex mt="2rem">
        <Flex alignItems="baseline" width={140}>
          <Text
            color="t2"
            fontWeight="semibold"
            fontSize="0.9375rem"
            width={140}
          >
            {t("Repeat")}
          </Text>
        </Flex>

        <Flex flexDirection="column" width={1}>
          <Options
            selected={
              rrule.freq || rrule.freq === 0
                ? OPTIONS[rrule.freq]
                : RRULES.NEVER
            }
            options={Object.values(RRULES)}
            onSelect={setFreq}
            width={1}
          />

          {(rrule.freq || rrule.freq === 0) && (
            <RenderInterval rrule={rrule} updateRRule={updateRRule} />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Recur;
