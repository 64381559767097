import React from "react";

import Icon from "../Icon";

const ChevronRight = props => {
  return (
    <Icon {...props}>
      <polyline points="9 18 15 12 9 6" />
    </Icon>
  );
};

export default ChevronRight;
