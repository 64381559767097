import { insertFile } from "../Helpers/File";

export const withImages = (editor) => {
  const { insertData, isVoid, normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    // console.log(path);
    // if (
    //   Element.isElement(node) &&
    //   node.type === "image" &&
    //   node.url !== undefined
    // ) {
    //   const paragraph = {
    //     type: "paragraph",
    //     children: [{ text: "" }]
    //   };
    //   Transforms.insertNodes(editor, paragraph, { at: [path[0] + 1] });
    // }
    return normalizeNode([node, path]);
  };

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        // console.log(file);
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          const url = reader.result;
          insertFile(editor, url);
        });

        reader.readAsDataURL(file);
      }
    }
    // else if (false && isImageUrl(text)) {
    // insertImage(editor, text);
    // }
    else {
      insertData(data);
    }
  };

  return editor;
};
