import React, { useEffect } from "react";

export const Hotkeys = ({ disabled, keys, onKeysDown, onKeysUp }) => {
  let keyDownActive = typeof onKeysDown === "function" ? true : false;
  let keyUpActive = typeof onKeysUp === "function" ? true : false;

  useEffect(() => {
    if (keyDownActive) window.addEventListener("keydown", downHandler);
    if (keyUpActive) window.addEventListener("keyup", upHandler);

    return () => {
      if (keyDownActive) window.removeEventListener("keydown", downHandler);
      if (keyUpActive) window.removeEventListener("keyup", upHandler);
    };
  }, [keys, onKeysUp]);

  let key;
  let keyStrokes = "";
  let keyCount = 0;

  const downHandler = e => {
    if (e.repeat || disabled) return;

    key = e.key.toLowerCase();

    if (
      keys.length === 0 &&
      e.key !== "Escape" &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      onKeysDown(key);
      return;
    }

    if (e.ctrlKey && e.key !== "Control") key = "ctrl" + key;

    if (!keys.includes(key)) return;

    keyStrokes = keyStrokes + e.key;
    keyCount += 1;

    onKeysDown(key);
  };

  const upHandler = () => {
    if (disabled) return;

    keyCount -= 1;
    if (keyCount > 0) return;

    if (keys.includes(keyStrokes.toLowerCase()))
      onKeysUp(keyStrokes.toLowerCase());

    keyStrokes = "";
  };

  return null;
};

