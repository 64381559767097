import { useEffect, useRef } from "react";

export const useClickOutside = (callBack) => {
  const ref = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (typeof callBack === "function") callBack();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return ref;
};

export default useClickOutside;
