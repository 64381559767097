import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Flex, Input, Row, SimpleEditor, strip, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";

const New = ({ spaceId, open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const addCategory = () => {
    const message = {
      name: strip(title),
      description: strip(description),
      space_id: spaceId
    };

    dispatch(
      ACTIONS.fetch.post(
        "topic",
        `${API_ENDPOINT}/category/`,
        ACTIONS.state.loadObject,
        message,
        (state, response) => {
          state.categories.push(response);
          return state;
        }
      )
    );

    setOpen(false);
  };

  let style = { disabled: "disabled" };
  if (title && title.length > 0 && spaceId && description) style = {};

  return (
    <>
      <Text textStyle="subtitle" mb={4}>
        {t("New category")}
      </Text>

      <Row mt="2rem" label={t("Title")}>
        <Input
          width={1}
          focus={true}
          mb={2}
          placeholder={t("Category title...")}
          onChange={setTitle}
        />
      </Row>

      <Row mt="1rem" label={t("Descrição")}>
        <SimpleEditor
          plainText={true}
          minHeight={32}
          lineHeight={1.5}
          enabled={true}
          height="100%"
          onChange={setDescription}
        />
      </Row>

      <Flex mb={0} mt="2rem" alignItems="center" justifyContent="flex-end">
        <Button
          ml={2}
          variant="secondary"
          onClick={() => setOpen(false)}
          value={t("Cancel")}
        />
        <Button
          ml={2}
          {...style}
          variant="primary"
          onClick={addCategory}
          value={t("Add category")}
        />
      </Flex>
    </>
  );
};

export default New;
