import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { Flex, Text } from "tomato";

import { Widgets } from "modules/Tasks/Widgets";

import { UserActivities } from "./Activities";
import { Accounts } from "./Accounts";
import { Users } from "./Users";

const Userso = () => {
  return (
    <Flex flexDirection="column" mt="-0.5rem">
      <Flex mb="0.25rem">
        <Text fontSize="1rem" width={0.9}>
          Stamatios Stamou Jr from eCentry
        </Text>
        <Text ml="0.5rem" width={0.1}>
          120
        </Text>
      </Flex>

      <Flex mb="0.25rem">
        <Text fontSize="1rem" width={0.9}>
          Stamatios Stamou Jr from eCentry
        </Text>
        <Text ml="0.5rem" width={0.1}>
          120
        </Text>
      </Flex>

      <Flex mb="0.25rem">
        <Text fontSize="1rem" width={0.9}>
          Stamatios Stamou Jr from eCentry
        </Text>
        <Text ml="0.5rem" width={0.1}>
          120
        </Text>
      </Flex>

      <Flex mb="0.25rem">
        <Text fontSize="1rem" width={0.9}>
          Stamatios Stamou Jr from eCentry
        </Text>
        <Text ml="0.5rem" width={0.1}>
          120
        </Text>
      </Flex>

      <Flex mb="0.25rem">
        <Text fontSize="1rem" width={0.9}>
          Stamatios Stamou Jr from eCentry
        </Text>
        <Text ml="0.5rem" width={0.1}>
          120
        </Text>
      </Flex>
    </Flex>
  );
};

export const Saas = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/active/user/daily`,
        ACTIONS.state.loadObject,
        "dailyActive"
      )
    );

    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/active/user/monthly`,
        ACTIONS.state.loadObject,
        "monthlyActive"
      )
    );
    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/active/account/daily`,
        ACTIONS.state.loadObject,
        "dailyActiveAccount"
      )
    );

    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/active/account/monthly`,
        ACTIONS.state.loadObject,
        "monthlyActiveAccount"
      )
    );

    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/total/all`,
        ACTIONS.state.loadObject,
        "total"
      )
    );

    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/event/daily`,
        ACTIONS.state.loadObject,
        "dailyEvent"
      )
    );

    dispatch(
      ACTIONS.fetch.get(
        "charts",
        `${API_ROOT}/metrics/event/top`,
        ACTIONS.state.loadObject,
        "topEvents"
      )
    );

    return () => {
      dispatch(ACTIONS.state.setAttribute("charts", "dailyActive"));
      dispatch(ACTIONS.state.setAttribute("charts", "dailyActiveAccount"));
      dispatch(ACTIONS.state.setAttribute("charts", "monthlyActive"));
      dispatch(ACTIONS.state.setAttribute("charts", "monthlyActiveAccount"));
      dispatch(ACTIONS.state.setAttribute("charts", "dailyEvent"));
      dispatch(ACTIONS.state.setAttribute("charts", "topEvents"));
      dispatch(ACTIONS.state.setAttribute("charts", "totalAll"));
    };
  }, []);

  return (
    <Flex flexDirection="column" width={1} m="1rem" pr="1.75rem">
      <Flex justifyContent="space-between" alignItems="center">
        <Text textStyle="title">
          <Text textStyle="title">SaaS {t("dashboard")}</Text>
        </Text>
        <Widgets.Time />
      </Flex>

      <Flex
        width={1}
        mt="0.5rem"
        borderBottom="1px solid white"
        height="2px"
        minHeight="2px"
        mb="1rem"
        bg="l4"
        mr="1rem"
      />

      <Users />

      <UserActivities />

      <Accounts />

      <Flex minHeight="5rem" width={1}></Flex>
    </Flex>
  );
};
