import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Flex, Text } from "tomato";

const Header = ({ adding, title, addText, setAdding }) => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems="center"
      width={1}
      justifyContent="space-between"
      mb={4}
      pb={4}
      borderBottom="1px solid lightGrey"
    >
      <Text textStyle="subtitle">{title}</Text>

      <Button
        variant="primary"
        onClick={() => setAdding(!adding)}
        value={t(addText)}
      />
    </Flex>
  );
};

export default Header;
