export const IMAGE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/svg+xml"
];

export const FILE_TYPES = [
  "application/epub+zip",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "text/csv",
  "text/plain",
  "text/html",
  "application/xhtml+xml",
  "application/xml",
  "text/xml"
];

export const MIME_TYPES = IMAGE_TYPES.concat(FILE_TYPES);
