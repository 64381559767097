import { applyMiddleware, createStore } from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";

import { EPICS } from "./epics";
import { INITIAL_STATE } from "./state";
import { REDUCERS } from "./reducers";

const middlewares = [];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export const STORE = createStore(REDUCERS, INITIAL_STATE, composedEnhancers);

// react-observable rxjs middleware
const epicMiddleware = createEpicMiddleware({
  dependencies: { dispatch: STORE.dispatch }
});

// Esta criação é feita desta maneira para termos acesso ao dispatch dentro dos epics importados
STORE.dispatch = epicMiddleware(STORE)(STORE.dispatch);
epicMiddleware.run(combineEpics(...EPICS));

export const STATE = STORE.getState;
export const DISPATCH = STORE.dispatch;
