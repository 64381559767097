import styled from "styled-components";

import { Box } from "../Box";

export const Audio = styled(Box)`
  box-sizing: border-box;
  outline: none;
`;

Audio.defaultProps = {
  as: "audio"
};
