import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import {
  DateTimePicker,
  Dropdown,
  Editor,
  Emojis,
  EmojiPicker,
  EMOJIS_GROUPS,
  FilesPreview,
  Flex,
  Icon,
  Image,
  Image as Img,
  Reactions,
  SimpleEditor,
  Spinner,
  Text
} from "tomato";

// import { useFetch, useQuery } from "hooks";

// import { Forms, Reminders, VirtualRoom, Zen } from "modules";
// import { Upload } from "layouts/Default/Upload";
// import { ROOT_URL } from "config";
import { ACTIONS } from "store";
import { API_ENDPOINT, ROOT_URL } from "config";
import { SpacesMenu } from "modules";

// function ChatHead({ src, ...props }) {
//   return (
//     <Img
//       draggable="false"
//       src={src}
//       style={{ transition: "all 300ms ease-in-out" }}
//       borderRadius="100%"
//       p="0.25rem"
//       width="4rem"
//       borderColor="rgba(0,0,0,.1)"
//       borderStyle="solid"
//       borderWidth="1px"
//       height="4rem"
//       className=" ba b--black-10 "
//       alt="chat-head"
//       {...props}
//     />
//   );
// }

const saveMessage = (content, mentions) => {
  console.log(content, mentions);
};

const initialValue = [
  {
    type: "paragraph",
    children: [
      {
        text: "Lorem ipsum dolor sit amet, conse"
      }
    ]
  }
];

export const Dev = () => {
  const dispatch = useDispatch();

  const files = useSelector((state) => state["updates"].files);

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "updates",
        `${API_ENDPOINT}/file?content_type=image%2Fsvg%2Bxml&task_id=206&order_by=-id`,

        ACTIONS.state.loadObjects,
        (state, response) => {
          state.files = response.objects;
          return state;
        }
      )
    );
  }, []);

  // console.log("Files h", files);
  // if (!files || (files && files.length === 0)) return null;

  return (
    <Flex p="10rem">
      <SpacesMenu />
    </Flex>
  );

  return (
    <FilesPreview
      files={files}
      previewIndex={0}
      rootUrl={ROOT_URL + "/shared/"}
    />
  );
};

export const DevEditor = () => {
  const [value, setValue] = useState(initialValue);

  return (
    <Flex width={1} height="100%">
      <Flex
        width={640}
        mx="auto"
        px="2rem"
        py="4rem"
        bg="white"
        flexDirection="column"
      >
        <Editor value={value} onSave={(content) => console.log(content)} />
      </Flex>
      <Flex>Text</Flex>
    </Flex>
  );
};
export const Dev1 = () => {
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [reactions, setReactions] = useState([
    {
      code: "1f922",
      users: [6]
    }
  ]);

  return (
    <Flex width={1} height="100%" justifyContent="center" alignItems="center">
      <Reactions
        user={user}
        users={users}
        reactions={reactions}
        onSelect={setReactions}
      />
    </Flex>
  );
};

// export const Devold = () => {
//   const dispatch = useDispatch();

//   let query = `query getMessages {
//   messages {
//     id
//     content
//     creationDate
//     expireDate
//     space {
//       id
//       name
//       __typename
//     }
//     createdBy {
//       id
//       name
//       isActive
//       __typename
//     }
//       __typename
//   }
// }`;

//   query = `query Tasks {
//     tasks {
//       approvers
//       createdBy {
//       	id
//       }
//       deadline
//       doneBy {
//       	id
//       }
//       doneDate
//       followers
//       order
//       owner {
//         id
//         __typename
//       }
//       parent {
//         id
//       }
//       pinned
//       project {
//       	id
//         __typename
//       }
//       rrule
//       space {
//         id
//         __typename
//       }
//       startDate
//       timeSpent
//       timerStart
//       totalFiles
//       totalNotes
//       typeId
//       updateDate
//       value
//       id
//       subject
//       creationDate
//       group {
//         id
//         name
//         order
//         __typename
//       }
//        __typename
//     }
//   }`;

// const { loading, messages, tasks, users } = useQuery(query, {
//   TaskType: { module: "tasks", attribute: "tasks" }
// });

// console.log(loading, tasks);

//   const showResults = (state, response) => {
//     console.log(state, response);
//     state.messages = response.messages;
//     return state;
//   };

//   useEffect(() => {
//     const matrix = {
//       BillboardType: { module: "billboard", attribute: "messages" }
//     };
//     dispatch(
//       ACTIONS.fetch.graphql(query, "billboard", showResults, matrix, false)
//     );
//   }, []);

//   const loading = false;
//   return <>{loading ? <Spinner /> : <Text>response</Text>}</>;
// };

// export const DevAudio = () => {
//   const [record, setRecord] = useState(false);
//   const chunks = useRef([]);
//   const [blob, setBlob] = useState();
//   const recorder = useRef(null);
//   const started = useRef(false);
//   const stream = useRef(null);

//   const success = (stream_, record) => {
//     stream.current = stream_;
//     recorder.current = new MediaRecorder(stream.current);
//     recorder.current.ondataavailable = (e) => {
//       chunks.current.push(e.data);
//     };
//     recorder.current.start(100);
//   };

//   const error = () => console.log("Error in audio capture");

//   useEffect(() => {
//     if (record && !started.current) {
//       chunks.current = [];
//       started.current = true;
//     }

//     if (!record && started.current) {
//       started.current = false;
//       recorder.current.stop();
//       stream.current.getTracks().forEach((track) => {
//         track.stop();
//       });
//       console.log(chunks.current);
//       let blob_ = new Blob(chunks.current, { type: "audio/webm" });
//       blob_ = URL.createObjectURL(blob_);
//       setBlob(blob_);
//     }

//     if (!started.current) return;

//     navigator.getUserMedia({ audio: true, video: false }, success, error);
//   }, [record]);

//   return (
//     <Flex width={1} height="100%" justifyContent="center" alignItems="center">
//       <Flex
//         style={{ cursor: "pointer" }}
//         p="0.25rem"
//         borderRadius="50%"
//         bg="l2"
//         onMouseDown={() => setRecord(true)}
//         onMouseUp={() => setRecord(false)}
//       >
//         <Icon.Mic stroke={record ? "red" : "grey"} />
//       </Flex>

//       {blob && (
//         <audio controls src={blob}>
//           Erro no áudio
//         </audio>
//       )}
//     </Flex>
//   );
// };

// export const DevEmojis = () => {
//   return (
//     <Flex
//       width={1}
//       height="100%"
//       alignItems="center"
//       flexDirection="column"
//       overflow="auto"
//       pt="40rem"
//     >
//       <Emojis />
//       {false &&
//         Object.keys(EMOJIS_GROUPS).map((group) => (
//           <>
//             <Text my="2rem">{group}</Text>

//             {false && (
//               <Flex flexWrap="wrap">
//                 {EMOJIS_GROUPS[group].map((emojiCode) => (
//                   <Flex flexDirection="column" mr="1rem" mb="1rem">
//                     <Image
//                       width="2rem"
//                       src={`https://static.elefante.com/emojis/${emojiCode}.png`}
//                     />
//                     <Text>{emojiCode}</Text>
//                   </Flex>
//                 ))}
//               </Flex>
//             )}
//           </>
//         ))}
//     </Flex>
//   );
// };
// export const DevD = () => {
//   return (
//     <Flex width={1} height="100%" justifyContent="center" alignItems="center">
//       <Dropdown
//         enableClear={true}
//         options={[
//           { id: 1, value: "teste" },
//           { id: 2, value: "teste 2" }
//         ]}
//       />
//     </Flex>
//   );
// };
// export const DevDP = () => {
//   const onSelect = (startDate, endDate) => {
//     console.log(startDate, endDate);
//   };
//   return (
//     <Flex width={1} height="100%" justifyContent="center" alignItems="center">
//       <DateTimePicker onSelect={onSelect} range={true} />
//     </Flex>
//   );
// };

// export const DevRooms = () => {
//   return <VirtualRoom />;
// };

// export const DevZen = () => {
//   return <Zen />;
// };

// export const DevFiles = () => {
//   return <FilesPreview rootUrl={ROOT_URL + "/shared"} previewIndex={0} />;
// };

// export const DevEditor = () => {
//   return (
//     <Flex width={1} height="100%" justifyContent="center" alignItems="center">
//       <Editor width="50rem" mx="auto" />
//     </Flex>
//   );
// };
// export const DevForm = () => {
//   return (
//     <Flex width={1} height="100%" justifyContent="center" alignItems="center">
//       <Forms />
//     </Flex>
//   );
// };

// export const DevEmoji = () => {
//   return (
//     <Flex alignItems="center" justifyContent="center" m="auto">
//       <EmojiPicker />
//     </Flex>
//   );
// };

// export const DevA = () => {
//   const CHARACTERS = [
//     { id: 1, value: "Aayla Secura" },
//     { id: 2, value: "Adi Gallia" },
//     { id: 3, value: "Admiral Dodd Rancit" },
//     { id: 4, value: "Admiral Firmus Piett" },
//     { id: 5, value: "Admiral Gial Ackbar" },
//     { id: 6, value: "Admiral Ozzel" }
//   ];

//   return (
//     <Flex width={1} height="100%" justifyContent="center" alignItems="center">
//       <SimpleEditor
//         maxWidth={600}
//         autoFocus={true}
//         width={1}
//         maxHeight="25rem"
//         enabled={true}
//         enableToolbar={true}
//         onEnter={saveMessage}
//         toolbarPosition="BOTTOM"
//         mentionOptions={CHARACTERS}
//         // onChange={typing}
//         value={[
//           {
//             children: [
//               {
//                 text: "This example shows how you might implement a simple @-mentions feature that lets users autocomplete mentioning ant."
//               }
//             ]
//           },
//           {
//             children: [
//               { text: "Try mentioning characters, like " },
//               {
//                 type: "mention",
//                 id: "1",
//                 children: [{ text: "R2-D2" }]
//               },
//               { text: " or " },
//               {
//                 type: "mention",
//                 id: "2",
//                 children: [{ text: "Mace Windu" }]
//               },
//               { text: "!" }
//             ]
//           }
//         ]}
//         clearOnEnter={true}
//       />
//     </Flex>
//   );
// };
