import React from "react";
import { Flex } from "tomato";

export const Clean = ({ View, ...props }) => {
  return (
    <Flex width={1} height="100%" overflow="auto">
      <View />
    </Flex>
  );
};
