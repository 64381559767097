import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

// import SideMenu from "components/SideMenu";
const SideMenu = () => {
  return null;
};

const Admin = ({ admin }) => {
  const { t } = useTranslation();
  const [activeOption, setActiveOption] = useState(0);

  const options = admin.options;
  let View = options[activeOption].view;
  let props = options[activeOption].props;

  return (
    <>
      <Text mb={4} textStyle="subtitle">
        {t(admin.title)}
      </Text>

      <Flex height="100%">
        <SideMenu
          options={options}
          optionKey="title"
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />

        <Flex flexGrow="1" height="100%" overflow="auto">
          <View {...props} />
        </Flex>
      </Flex>
    </>
  );
};

export default Admin;
