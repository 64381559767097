import React, { useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Dropdown, Flex, FlexAction, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { t } from "i18n";

const INVITE_STATUS = {
  1: t("Waiting"),
  2: t("Confirmed"),
  3: t("Refused"),
  4: t("Undecided"),
  5: t("Invite cancelled by organizer")
};

const Guests = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const tasks = useSelector((state) => state["tasks"].objs);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  let internalGuests =
    (task.guests.internal && { ...task.guests.internal }) || {};

  const [instance, setInstance] = useState(null);

  const removeGuest = (guestId, location) => {
    dispatch(ACTIONS.events.send("Guest removed", task));

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/guest`,
        [ACTIONS.tasks.updateGuests],
        {
          users_ids: [guestId]
        }
      )
    );
  };

  const addGuest = (member) => {
    console.log(member);
    // instance.hide();

    dispatch(ACTIONS.events.send("Guest added", task));

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/guest`,
        [ACTIONS.tasks.updateGuests],
        {
          users_ids: [member.id]
        }
      )
    );
  };

  let membersOptions = Object.values(users)
    .filter(
      (member) =>
        member.id !== user.id &&
        users[member.id].is_active &&
        !users[member.id].is_deleted &&
        !internalGuests[member.id]
    )
    .map((member) => {
      return {
        id: member.id,
        value: users[member.id].name,
        avatar: users[member.id].avatar
      };
    });

  if (false && task.parent_id)
    return (
      <Flex mt="2rem">
        <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
          {t("Organizer")}
        </Text>

        <Text>{users[tasks[task.parent_id].created_by_id].name}</Text>
      </Flex>
    );

  return (
    <Flex mt="2rem">
      <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
        {t("Guests")}
      </Text>

      <Flex flexDirection="column">
        {Object.keys(internalGuests).map((guestId, index) => (
          <Flex key={index} alignItems="center" mb="0.25rem">
            <Avatar size="large" src={users[guestId].avatar} />
            <Text ml="0.5rem">{users[guestId].name}</Text>
            <Text ml="0.5rem">
              ({t(INVITE_STATUS[internalGuests[guestId].statusId])})
            </Text>

            {internalGuests[guestId].statusId !== 5 && (
              <Icon.Trash
                ml="1rem"
                style={{ cursor: "pointer" }}
                onClick={() => removeGuest(guestId)}
              />
            )}
          </Flex>
        ))}

        {task.created_by_id === user.id && (
          <Tippy
            trigger="click"
            placement="right"
            interactive={true}
            offset={[0, 4]}
            render={(attrs) => (
              <>
                {instance ? (
                  <Dropdown
                    minWidth="6rem"
                    options={membersOptions}
                    onSelect={addGuest}
                    placeholder={t("Add guest")}
                  />
                ) : (
                  ""
                )}
              </>
            )}
            onCreate={setInstance}
          >
            <FlexAction>{t("Add guests")}</FlexAction>
          </Tippy>
        )}
      </Flex>
    </Flex>
  );
};

export default Guests;
