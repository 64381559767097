import React from "react";
import { subtractMinutes } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  Flex,
  FlexAction,
  Icon,
  Input,
  Text,
  toTimestamp,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { t } from "i18n";

const CHANNEL = {
  ALL: t("Notification + email"),
  EMAIL: t("email"),
  NOTIFICATION: t("Notification")
};

const TYPE = {
  MINUTES: t("minutes"),
  HOURS: t("hours"),
  DAYS: t("days"),
  WEEKS: "weeks"
};

const channelOptions = [
  {
    id: "ALL",
    value: t("Notification + email")
  },
  {
    id: "EMAIL",
    value: t("email")
  },
  {
    id: "NOTIFICATION",
    value: t("Notification")
  }
];

const timeOptions = [
  {
    id: "MINUTES",
    value: t("minutes")
  },
  {
    id: "HOURS",
    value: t("hours")
  },
  {
    id: "DAYS",
    value: t("days")
  },
  {
    id: "WEEKS",
    value: "weeks"
  }
];

const Notification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const ws = useSelector((state) => state["ws"].ws);

  const save = (reminders) => {
    dispatch(ACTIONS.tasks.updateAttribute(task.id, "reminders", reminders));

    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { reminders }
      )
    );
  };

  const deleteReminder = (uuid) => {
    ws.send(
      JSON.stringify({
        type: "reminders",
        action: "delete",
        data: {
          uuid,
          title: task.subject,
          userId: task.owner_id,
          taskId: task.id
        }
      })
    );

    let newReminders = {
      ...task.reminders
    };

    delete newReminders[uuid];
    save(newReminders);
  };

  const addReminder = () => {
    let expireDate = toTimestamp(task.start_date);
    expireDate -= 10 * 60 * 1000;

    let uuid = uuidv4();

    let reminder = {};

    reminder[uuid] = {
      uuid,
      channel: "ALL",
      value: 10,
      type: "MINUTES"
    };

    let newReminders = {
      ...task.reminders,
      ...reminder
    };

    ws.send(
      JSON.stringify({
        type: "reminders",
        action: "add",
        data: {
          uuid,
          title: task.subject,
          expireDate,
          userId: task.owner_id,
          taskId: task.id
        }
      })
    );

    save(newReminders);
  };

  const change = () => {};

  return (
    <>
      <Flex mt="2rem">
        <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
          {t("Reminders")}
        </Text>

        <Flex flexDirection="column">
          {Object.values(task.reminders).map((reminder) => (
            <Flex mb="0.5rem" alignItems="center">
              <Dropdown
                onSelect={change}
                options={channelOptions}
                selected={{
                  id: reminder.channel,
                  value: CHANNEL[reminder.channel]
                }}
              />

              <Input
                ml="1rem"
                type="float"
                width="fit-content"
                value={reminder.value}
              />

              <Dropdown
                ml="1rem"
                onSelect={change}
                options={timeOptions}
                selected={{ id: reminder.type, value: TYPE[reminder.type] }}
              />

              <Icon.Trash
                ml="1rem"
                style={{ cursor: "pointer" }}
                onClick={() => deleteReminder(reminder.uuid)}
              />
            </Flex>
          ))}

          <FlexAction onClick={addReminder}>{t("Add reminder")}</FlexAction>
        </Flex>
      </Flex>
    </>
  );
};

export default Notification;
