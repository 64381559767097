import styled from "styled-components";

import {
  border,
  color,
  flexbox,
  layout,
  space,
  position,
  typography,
  textStyle
} from "styled-system";

import { getColor } from "../../themes/util";

export const Table = styled("table")(
  border,
  color,
  flexbox,
  layout,
  space,
  typography,
  textStyle,
  {
    width: "100%",
    margin: "0",
    padding: "0",
    borderSpacing: "0",
    borderCollapse: "collapse"
  }
);

export const TableRow = styled("tr")`
    ${space}
    ${color}
    ${layout}
    ${typography}
    ${textStyle}
    ${border}
    ${flexbox}        

    ${(props) =>
      props.hoverColor &&
      `
          &:hover {
            background-color: ${props.theme.colors[props.hoverColor]};
          }
        `}   
`;

export const TableHead = styled("th")(
  space,
  color,
  layout,
  typography,
  textStyle,
  border,
  position,
  flexbox
);

TableHead.defaultProps = {
  height: "2rem",
  position: "sticky",
  top: 0,
  backgroundColor: "l0",
  zIndex: 10
};

export const TableData = styled("td")(
  space,
  color,
  layout,
  typography,
  textStyle,
  border,
  position,
  flexbox,
  {
    whiteSpace: "nowrap"
  }
);
