import React, { useEffect, useRef, useState } from "react";
import Transition from "react-transition-group/Transition";

import { Box, Portal } from "../";
import { UI } from "../../constants";

const POSITION = UI.POSITION;

export const Positioner = ({
  target,
  children,
  location,
  isShown = true,
  animationDuration = 200,
  deltaX = 0,
  deltaY = 0,
  zIndex = 9999
}) => {
  const childrenRef = useRef();
  const targetRef = useRef();

  const viewportHeight = document.body.clientHeight;
  const viewportWidth = document.body.clientWidth;

  const [childrenPosition, setChildrenPosition] = useState({});
  const [targetPosition, setTargetPosition] = useState({});

  let height;
  let width;
  let x;
  let y;

  useEffect(() => {
    const childrenRect = childrenRef.current.getBoundingClientRect();
    setChildrenPosition(childrenRect);
  }, []);

  const handleEnter = () => {
    const childrenRect = childrenRef.current.getBoundingClientRect();
    const targetRect = targetRef.current.getBoundingClientRect();
    setChildrenPosition(childrenRect);
    setTargetPosition(targetRect);
  };

  const handleEntered = () => {};
  const handleExited = () => {};

  const minimumXDistance = 4;
  const minimumYDistance = 4;

  let maxWidth;
  let maxHeight;
  let top;
  let left;

  const positions = new Map([
    [
      POSITION.BOTTOM,
      () => {
        if (
          targetPosition.height >
          viewportHeight - childrenPosition.y + childrenPosition.height
        ) {
          top = childrenPosition.y + childrenPosition.height + minimumYDistance;
          maxHeight =
            childrenPosition.y + childrenPosition.height - 2 * minimumYDistance;
        } else {
          top = childrenPosition.y + childrenPosition.height + minimumYDistance;
          maxHeight = targetPosition.height;
        }
        left =
          childrenPosition.x -
          targetPosition.width / 2 +
          childrenPosition.width / 2;
      }
    ],
    [
      POSITION.BOTTOM_LEFT,
      () => {
        if (
          targetPosition.height >
          viewportHeight - childrenPosition.y + childrenPosition.height
        ) {
          top = childrenPosition.y + childrenPosition.height + minimumYDistance;
          maxHeight =
            childrenPosition.y + childrenPosition.height - 2 * minimumYDistance;
        } else {
          top = childrenPosition.y + childrenPosition.height + minimumYDistance;
          maxHeight = targetPosition.height;
        }
        left = childrenPosition.x;
      }
    ],
    [
      POSITION.BOTTOM_RIGHT,
      () => {
        if (
          targetPosition.height >
          viewportHeight - childrenPosition.y + childrenPosition.height
        ) {
          top = childrenPosition.y + childrenPosition.height + minimumYDistance;
          maxHeight =
            childrenPosition.y + childrenPosition.height - 2 * minimumYDistance;
        } else {
          top = childrenPosition.y + childrenPosition.height + minimumYDistance;
          maxHeight = targetPosition.height;
        }

        const childrenFinalX = childrenPosition.x + childrenPosition.width;

        left = childrenFinalX - targetPosition.width;
      }
    ],
    [
      POSITION.TOP,
      () => {
        if (targetPosition.height > childrenPosition.y) {
          top = minimumYDistance;
          maxHeight = childrenPosition.y - 2 * minimumYDistance;
        } else {
          top = childrenPosition.y - targetPosition.height - minimumYDistance;
          maxHeight = targetPosition.height;
        }
        left =
          childrenPosition.x -
          targetPosition.width / 2 +
          childrenPosition.width / 2;
      }
    ],
    [
      POSITION.TOP_LEFT,
      () => {
        if (targetPosition.height > childrenPosition.y) {
          top = minimumYDistance;
          maxHeight = childrenPosition.y - 2 * minimumYDistance;
        } else {
          top = childrenPosition.y - targetPosition.height - minimumYDistance;
          maxHeight = targetPosition.height;
        }
        left = childrenPosition.x;
      }
    ],
    [
      POSITION.TOP_RIGHT,
      () => {
        if (targetPosition.height > childrenPosition.y) {
          top = minimumYDistance;
          maxHeight = childrenPosition.y - 2 * minimumYDistance;
        } else {
          top = childrenPosition.y - targetPosition.height - minimumYDistance;
          maxHeight = targetPosition.height;
        }
        const childrenFinalX = childrenPosition.x + childrenPosition.width;
        left = childrenFinalX - targetPosition.width;
      }
    ],
    [
      POSITION.LEFT,
      () => {
        if (childrenPosition.height > targetPosition.height) {
          top =
            childrenPosition.y +
            (childrenPosition.height - targetPosition.height) / 2;
        } else {
          top =
            childrenPosition.y -
            childrenPosition.height +
            targetPosition.height / 2;
        }
        left = childrenPosition.x - targetPosition.width - minimumXDistance;
      }
    ],
    [
      POSITION.LEFT_TOP,
      () => {
        if (targetPosition.top > minimumYDistance) {
          top = minimumYDistance;
        }
        top = childrenPosition.top;
        left = childrenPosition.x - targetPosition.width - minimumXDistance;
      }
    ],
    [
      POSITION.LEFT_BOTTOM,
      () => {
        if (targetPosition.top > minimumYDistance) {
          top = minimumYDistance;
        }
        top =
          childrenPosition.top +
          (childrenPosition.height - targetPosition.height);

        left = childrenPosition.x - targetPosition.width - minimumXDistance;
      }
    ],
    [
      POSITION.RIGHT,
      () => {
        if (childrenPosition.height > targetPosition.height) {
          top =
            childrenPosition.y +
            (childrenPosition.height - targetPosition.height) / 2;
        } else {
          top =
            childrenPosition.y -
            childrenPosition.height +
            targetPosition.height / 2;
        }
        left = childrenPosition.x + childrenPosition.width + minimumXDistance;
      }
    ],
    [
      POSITION.RIGHT_TOP,
      () => {
        if (targetPosition.top > minimumYDistance) {
          top = minimumYDistance;
        }
        top = childrenPosition.top;

        left = childrenPosition.x + childrenPosition.width + minimumXDistance;
      }
    ],
    [
      POSITION.RIGHT_BOTTOM,
      () => {
        if (targetPosition.top > minimumYDistance) {
          top = minimumYDistance;
        }
        top =
          childrenPosition.top +
          (childrenPosition.height - targetPosition.height);

        left = childrenPosition.x + childrenPosition.width + minimumXDistance;
      }
    ]
  ]);

  const style = (location) => {
    positions.get(location)();
    return {
      maxHeight: `${maxHeight}px`,
      top: `${top + deltaY}px`,
      left: `${left + deltaX}px`
    };
  };

  return (
    <>
      <Box ref={childrenRef}>{children}</Box>

      {isShown && (
        <Transition
          appear
          in={isShown}
          timeout={animationDuration}
          onEnter={handleEnter}
          onEntered={handleEntered}
          onExited={handleExited}
          unmountOnExit
        >
          {(state) => (
            <Portal>
              <Box position="absolute" {...style(location)} zIndex={zIndex}
              width='fit-content'
              height='fit-content' 
              >
                <div ref={targetRef}>{target}</div>
              </Box>
            </Portal>
          )}
        </Transition>
      )}
    </>
  );
};
