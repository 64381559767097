import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  ClickToEdit,
  Flex,
  FlexAction,
  formatDate,
  Icon,
  Input,
  orderObjects,
  orderObjectsList,
  SimpleEditor,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { Sidebar } from "modules/Tasks/Task/Sidebar";
import { MainWindow, SideMenu } from "components";
import {
  doneTask,
  startTimer,
  loadTask,
  pauseTimer,
  undoneTask
} from "modules";

export const ZenOld = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tasks = useSelector((state) => state["tasks"].objs);
  const user = useSelector((state) => state["account"].user);

  const match = useRouteMatch("/zen/:listId");
  let listId;
  if (match) {
    listId = match.params.listId;
  }

  useEffect(() => {
    dispatch(ACTIONS.state.setAttribute("account", "zenMode", true));
    return dispatch(ACTIONS.state.setAttribute("account", "zenMode", false));
  }, [dispatch]);

  let done = [];
  let next = [];

  Object.values(tasks)
    .filter((task) => {
      return task.owner_id && task.owner_id === user.id;
    })
    .filter((task) => {
      return listId ? parseInt(task.list_id) === parseInt(listId) : true;
    })
    .forEach((task) => {
      if (task.done_date) {
        done.push(task);
      } else {
        next.push(task);
      }
    });

  done = orderObjectsList(done, "order", "desc").slice(0, 10);
  next = orderObjectsList(next, "order", "asc").slice(0, 10);

  let nextTask = next[0];
  next = next.slice(1, 10);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width={1}
      justifyContent="space-between"
      height="100%"
    >
      <FlexAction
        position="fixed"
        top="1rem"
        left="1rem"
        bg="white"
        onClick={() => window.history.back()}
      >
        <Icon.Left style={{ strokeWidth: "2px" }} />
      </FlexAction>

      <Flex
        position="fixed"
        top="1rem"
        right="1rem"
        bg="white"
        onClick={() => window.history.back()}
      >
        <Icon.BellOff style={{ strokeWidth: "2px" }} />
      </Flex>

      <Flex
        flexGrow="1"
        width={1}
        flexDirection="column-reverse"
        justifyContent="flex-start"
        alignItems="center"
        color="t5"
      >
        {done.length === 0 ? (
          <Text>{t("Nothing done")}</Text>
        ) : (
          <>
            {done.map((task) => (
              <Flex key={task.id} my="0.5rem" alignItems="center">
                <Text style={{ textDecoration: "line-through" }}>
                  {task.subject}
                </Text>
                <Icon.Undo
                  style={{ cursor: "pointer" }}
                  ml="0.25rem"
                  size="small"
                  onClick={() => undoneTask(task)}
                />
              </Flex>
            ))}
          </>
        )}
      </Flex>

      {nextTask && (
        <Flex
          height="5rem"
          alignItems="center"
          justifyContent="center"
          width={1}
        >
          <Icon.Ok
            style={{ cursor: "pointer", strokeWidth: "2px" }}
            bg={nextTask.done_date ? "green" : "white"}
            borderRadius="25%"
            border={nextTask.done_date ? "none" : "1px solid gray"}
            size="small"
            mr="0.25rem"
            stroke="white"
            onClick={() => {
              doneTask(nextTask);
            }}
          />

          <FlexAction
            ml="1rem"
            fontWeight="semibold"
            fontSize="1.75rem"
            onClick={() => loadTask(nextTask.id)}
          >
            {nextTask.subject}
          </FlexAction>

          {nextTask.timer_start ? (
            <FlexAction
              bg="white"
              ml="0.5rem"
              onClick={() => pauseTimer(nextTask)}
            >
              <Icon.Pause size="medium" />
            </FlexAction>
          ) : (
            <FlexAction
              bg="white"
              ml="0.5rem"
              onClick={() => startTimer(nextTask)}
            >
              <Icon.Play size="medium" />
            </FlexAction>
          )}
        </Flex>
      )}

      <Flex
        flexGrow="1"
        width={1}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        color="t5"
      >
        {next.length === 0 ? (
          <Text>{t("Nothing to do in the future")}></Text>
        ) : (
          <>
            {next.map((task) => (
              <Text key={task.id} my="0.5rem">
                {task.subject}
              </Text>
            ))}
          </>
        )}
      </Flex>

      <Sidebar />
    </Flex>
  );
};

export const Zen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const inProgress = useSelector((state) => state["tasks"].inProgress);
  const completed = useSelector((state) => state["tasks"].zen);
  const pending = useSelector((state) => state["tasks"].pending);
  const user = useSelector((state) => state["account"].user);
  const task = useSelector((state) => state["tasks"].obj);

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "tasks",
        `${API_ENDPOINT}/task?done_by_id=${user.id}&order_by=-done_date&limit=10`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          state.zen = response.objects;
          return state;
        }
      )
    );
    dispatch(
      ACTIONS.fetch.get(
        "tasks",
        `${API_ENDPOINT}/task?owner_id=${user.id}&time_spent__gt=0&timer_start__isnull=False&limit=10`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          state.pending = response.objects;
          return state;
        }
      )
    );
  }, [dispatch, user]);

  useEffect(() => {
    if (!inProgress) return;
    dispatch(
      ACTIONS.fetch.get(
        "tasks",
        `${API_ENDPOINT}/task/${inProgress}`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          console.log(response);
          state.obj = response;
          return state;
        }
      )
    );
  }, [dispatch, user, inProgress]);

  const saveDescription = (description) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ENDPOINT}/task/${inProgress}`,
        ACTIONS.state.loadObjects,
        { description },
        (state, response) => {
          console.log(response);
          state.obj = response;
          return state;
        }
      )
    );
  };
  const updateSubject = (subject) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ENDPOINT}/task/${inProgress}`,
        ACTIONS.state.loadObjects,
        { subject },
        (state, response) => {
          console.log(response);
          state.obj = response;
          return state;
        }
      )
    );
  };

  return (
    <Flex width={1} p="1.5rem">
      <Flex
        width={1 / 4}
        mx="auto"
        bg="white"
        height="100%"
        p="1rem"
        flexDirection="column"
      >
        <Text textStyle="subtitle">Tarefas pendentes</Text>

        <Flex overflow="auto" flexDirection="column">
          {pending &&
            pending.map((task) => (
              <Flex
                flexDirection="column"
                p="1rem"
                mt="1rem"
                bg="l2"
                borderRadius="0.25rem"
              >
                <Text fontSize="0.9375rem" fontWeight="semibold">
                  {task.subject}
                </Text>
                <Text fontSize="0.75rem" mt="0.25rem">
                  {task.time_spent}
                </Text>
              </Flex>
            ))}
        </Flex>
      </Flex>

      <Flex
        width={2 / 4}
        mx="1rem"
        bg="white"
        height="100%"
        p="1rem"
        // borderLeft="1px solid lightGrey"
        // borderRight="1px solid lightGrey"
        flexDirection="column"
      >
        {!task && (
          <>
            <Text textStyle="subtitle">Working</Text>
            <Input
              // key={"i" + uuid}
              placeholder={t("What are you doing now?")}
              // rightIcon="ReturnLeft"
              // onEnter={addTask}
              // onEsc={() => setShowAddTask(false)}
              // onChange={changeSubject}
              focus={true}
              // onBlur={onBlur}
              width={1}
              showMaxLength={true}
              clearOnEnter={true}
            />
          </>
        )}

        {task && (
          <Flex overflow="auto" flexDirection="column">
            <ClickToEdit
              fontSize="1.25rem"
              fontWeight="bold"
              color="t1"
              maxLength={80}
              placeholder={t("Enter task subject...")}
              enabled={true}
              value={task.subject}
              onBlur={updateSubject}
              mb="1.5rem"
            />
            <SimpleEditor
              mt="1.5rem"
              key={task.id}
              autoFocus={true}
              placeholder={t("Write description")}
              value={task.description}
              minHeight={100}
              enableToolbar={true}
              lineHeight={1.5}
              onSave={saveDescription}
              onBlur={saveDescription}
              enabled={true}
            />
          </Flex>
        )}
      </Flex>

      <Flex
        width={1 / 4}
        mx="auto"
        bg="white"
        height="100%"
        p="1rem"
        flexDirection="column"
      >
        <Text textStyle="subtitle">Tarefas finalizadas</Text>

        <Flex overflow="auto" flexDirection="column">
          {completed &&
            completed.map((task) => (
              <Flex
                flexDirection="column"
                p="1rem"
                mt="1rem"
                bg="l2"
                borderRadius="0.25rem"
              >
                <Text fontSize="0.9375rem" fontWeight="semibold">
                  {task.subject}
                </Text>
                <Text fontSize="0.75rem" mt="0.25rem">
                  {formatDate(
                    task.done_date,
                    user.locale,
                    user.timezone
                  ).distance()}
                </Text>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
