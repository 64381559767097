import React from "react";

import Icon from "../Icon";

const Square = props => {
  return (
    <Icon {...props}>
      <rect x="3" y="3" width="18" height="18" />
    </Icon>
  );
};

export default Square;
