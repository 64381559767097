import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Text } from "../Text";
import { Overlay } from "../Overlay";

export const Confirm = ({
  blocked,
  title,
  content,
  children,
  onConfirm,
  onEnter,
  onEsc,
  open,
  setOpen,
  yesText,
  noText,
  ...props
}) => {
  const { t } = useTranslation();

  const handleAction = () => {
    onConfirm ? onConfirm() : setOpen(false);
  };

  return (
    <Overlay open={open} setOpen={setOpen} blocked={blocked}>
      <Flex
        py="1.5rem"
        px="1.5rem"
        borderRadius="0.5rem"
        // minWidth="40rem"
        // minHeight="20rem"
        flexDirection="column"
        justifyContent="space-between"
        bg="l0"
        boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
        overflow="auto"
        {...props}
      >
        <Text textStyle="subtitle">{title}</Text>

        <Box mt="2rem" mb="2rem" justifyContent="center" alignItems="center">
          {content}

          {children}
        </Box>

        <Flex justifyContent="flex-end">
          <Button
            variant="secondary"
            onClick={() => setOpen(false)}
            mr="1rem"
            value={t(noText ? noText : "No, take me back")}
          />

          <Button
            variant="primary"
            onClick={handleAction}
            value={t(yesText ? yesText : "Yes, delete")}
          />
        </Flex>
      </Flex>
    </Overlay>
  );
};
