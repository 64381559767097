import React, { useRef, useState } from "react";
import styled from "styled-components";

import { Box, Flex, Text } from "../../components";

const Icon = styled.svg`
  position: absolute;
  fill: none;
  top: -8px;
  left: -2px;
  stroke: green;
  stroke-width: 4px;
  width: 24px;
  height: 24px;
  visibility: ${(props) => (props.checked ? "visible" : "hidden")};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled(Box)`
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  transition: all 150ms;
  border: 1px solid grey;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #96d5ff;
  }
`;

export const Checkbox = ({
  checked,
  onChange,
  disabled = false,
  label,
  ...props
}) => {
  const [active, setActive] = useState(checked);
  const ref = useRef();

  return (
    <Flex
      alignItems="center"
      {...props}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) return;
        setActive(!active);
        ref.current.checked = !active;
        onChange && onChange(!active);
      }}
    >
      <label>
        <HiddenCheckbox
          ref={ref}
          checked={active}
          onChange={() => {
            if (disabled) return;
            setActive(!active);
            ref.current.checked = !active;
            onChange && onChange(!active);
          }}
        />
        <StyledCheckbox checked={active}  
            style={{ cursor: "pointer" }}>
          <Icon checked={active} viewBox="0 0 24 24">
            <polyline points="4 12 9 17 20 6" />
          </Icon>
        </StyledCheckbox>
      </label>

      {label && <Text ml="0.5rem">{label}</Text>}
    </Flex>
  );
};
