import React from "react";
import { RRule } from "rrule";
import { useTranslation } from "react-i18next";

import { Flex } from "tomato";

const RenderWeekly = ({ rrule, updateRRule }) => {
  const { t } = useTranslation();

  const checkWeekday = (weekdayRule) => {
    if (typeof rrule.byweekday === "undefined") return false;

    let index = rrule.byweekday.findIndex(
      (rule) => rule.weekday === weekdayRule.weekday
    );
    if (index < 0) return false;

    return true;
  };

  const setWeekday = (weekdayRule) => {
    let newRRule = {
      freq: rrule.freq,
      byweekday: rrule.byweekday ? [...rrule.byweekday] : [],
      interval: rrule.interval
    };

    let index = newRRule.byweekday.findIndex(
      (rule) => rule.weekday === weekdayRule.weekday
    );

    if (index < 0) {
      newRRule.byweekday.push({ ...weekdayRule });
    } else newRRule.byweekday.splice(index, 1);

    updateRRule(newRRule);
  };

  const OPTIONS = [
    { id: RRule.SU, value: t("Sun") },
    { id: RRule.MO, value: t("Mon") },
    { id: RRule.TU, value: t("Tue") },
    { id: RRule.WE, value: t("Wed") },
    { id: RRule.TH, value: t("Thu") },
    { id: RRule.FR, value: t("Fri") },
    { id: RRule.SA, value: t("Sat") }
  ];

  return (
    <Flex
      border="1px solid lightGrey"
      borderRadius="2rem"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        borderTopLeftRadius="2rem"
        borderBottomLeftRadius="2rem"
        px="0.5rem"
        py="0.375rem"
        width={1 / 7}
        justifyContent="center"
        bg={checkWeekday(RRule.SU) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.SU)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Sun")}
      </Flex>

      <Flex
        px="0.5rem"
        py="0.375rem"
        width={1 / 7}
        borderLeft="1px solid lightGrey"
        justifyContent="center"
        bg={checkWeekday(RRule.MO) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.MO)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Mon")}
      </Flex>

      <Flex
        px="0.5rem"
        py="0.375rem"
        width={1 / 7}
        borderLeft="1px solid lightGrey"
        justifyContent="center"
        bg={checkWeekday(RRule.TU) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.TU)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Tue")}
      </Flex>

      <Flex
        px="0.5rem"
        py="0.375rem"
        width={1 / 7}
        borderLeft="1px solid lightGrey"
        justifyContent="center"
        bg={checkWeekday(RRule.WE) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.WE)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Wed")}
      </Flex>

      <Flex
        px="0.5rem"
        py="0.375rem"
        width={1 / 7}
        borderLeft="1px solid lightGrey"
        justifyContent="center"
        bg={checkWeekday(RRule.TH) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.TH)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Thu")}
      </Flex>

      <Flex
        px="0.5rem"
        py="0.375rem"
        width={1 / 7}
        borderLeft="1px solid lightGrey"
        justifyContent="center"
        bg={checkWeekday(RRule.FR) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.FR)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Fri")}
      </Flex>

      <Flex
        px="0.5rem"
        py="0.375rem"
        borderTopRightRadius="2rem"
        borderBottomRightRadius="2rem"
        width={1 / 7}
        borderLeft="1px solid lightGrey"
        justifyContent="center"
        bg={checkWeekday(RRule.SA) ? "lightBlue" : ""}
        onClick={() => setWeekday(RRule.SA)}
        fontSize="0.675rem"
        style={{ textTransform: "uppercase", cursor: "pointer" }}
      >
        {t("Sat")}
      </Flex>
    </Flex>
  );
};

export default RenderWeekly;
