import styled from "styled-components";
import { height, borderRadius } from "styled-system";

import { Box } from "../Box";

export const Image = styled(Box)`
  ${(props) => props.width && `min-width: ${props.width};`}
  ${(props) => props.height && `min-height: ${props.height};`}
`;

Image.propTypes = {
  ...height.propTypes,
  ...borderRadius.propTypes
};

Image.defaultProps = {
  as: "img",
  maxWidth: "100%"
};
