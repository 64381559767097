import React from "react";
import styled from "styled-components";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { getColor } from "../../themes/util";

const StyledFlex = styled(Flex)`
  border-radius: 4px;
  transition: all 150ms ease-in-out;
  &:hover {
    ${(props) => {
      if (!props.selected) return `background-color: ${props.theme.colors.l2}`;
    }}
  }
`;

export const Chip = ({
  children,
  color,
  icon,
  onSelect,
  selected,
  size = "medium",
  enableDelete,
  onDelete,
  ...props
}) => {
  let RenderIcon;
  if (icon) RenderIcon = Icon[icon];

  const style = {
    small: { fontSize: "12px" },
    medium: { fontSize: "14px" },
    large: { fontSize: "16px", height: "24px", fontWeight: "bold" }
  };

  const flexStyle = {
    small: { px: 2, py: "2px" },
    medium: { px: 2, py: "2px" },
    large: { px: 2, py: 1 }
  };

  const iStyle = {
    small: {
      minHeight: "12px",
      minWidth: "12px",
      width: "12px",
      height: "12px"
    },
    medium: {
      minHeight: "14px",
      minWidth: "14px",
      width: "14px",
      height: "14px"
    },
    large: {
      minHeight: "16px",
      minWidth: "16px",
      width: "16px",
      height: "16px"
    }
  };

  let textStyle = { ...style[size] };
  textStyle.color = "t3";
  if (selected) textStyle.color = "white";

  let iconStyle = { ...iStyle[size] };
  if (selected) iconStyle.stroke = "white";

  let colorStyle = { bg: "l0" };

  if (onSelect) {
    colorStyle.style = { cursor: "pointer" };
  }
  if (onDelete) {
    iconStyle.style = { cursor: "pointer" };
  }

  if (color && selected) colorStyle.bg = color;

  if (selected) colorStyle.bg = "blue";
  if (color && selected) colorStyle.bg = color;

  return (
    <StyledFlex
      alignItems="center"
      selected={selected}
      border={
        selected ? "1px solid transparent" : "1px solid " + getColor("t5")
      }
      onClick={onSelect && onSelect()}
      {...flexStyle[size]}
      {...colorStyle}
      {...props}
      flexDirection="column"
      justifyContent="center"
    >
      <Flex alignItems="center">
        {icon && <RenderIcon mr={1} {...iconStyle} />}

        <Text {...textStyle}>{children}</Text>

        {enableDelete && (
          <>
            <Icon.Close
              ml={1}
              {...iconStyle}
              onClick={(e) => onDelete && onDelete()}
            />
          </>
        )}
      </Flex>
    </StyledFlex>
  );
};

// import React from "react";

// import { Chip } from "./Chip";
// import Flex from "components/Flex";
// import Icon from "components/Icon";

// export const ChipWrapper = ({ children, ...props }) => {
//   return (
//     <Chip {...props}>
//       <Flex alignItems="center" px={2} justifyContent="space-between">
//         {children}
//         <Icon.Close
//           width="14px"
//           ml={1}
//           onClick={props.handleAction}
//           style={{
//             cursor: "pointer",
//             display:
//               props.enableAction || typeof props.enableAction === "undefined"
//                 ? "block"
//                 : "none"
//           }}
//         />
//       </Flex>
//     </Chip>
//   );
// };
