import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import Tippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";

import { Action, Column, Flex, Icon, Input, Tag, Text } from "../";

const searchOptions = {
  includeScore: false,
  shouldSort: true,
  threshold: 0.15,
  location: 0,
  distance: 1000,
  ignoreLocation: true,
  maxPatternLength: 100,
  minMatchCharLength: 1
};

const Dropdown = ({ attrs, addTag, instance, filteredTags }) => {
  return (
    <Column
      {...attrs}
      bg="l0"
      width="fit-content"
      maxHeight="15rem"
      maxWidth="18rem"
      borderRadius="0.25rem"
      border="1px solid lightGrey"
      boxShadow="2px 3px 8px -2px rgba(0,0,0,0.40)"
      zIndex="1000"
      overflow="auto"
    >
      {filteredTags.map((tag) => (
        <Text
          key={tag}
          hoverColor="lightBlue"
          fontSize="0.9375rem"
          px="0.75rem"
          py="0.5rem"
          // my="0.125rem"
          onClick={(e) => {
            e.stopPropagation();
            addTag(tag);
            instance.hide();
          }}
          style={{ cursor: "pointer", whiteSpace: "nowrap" }}
        >
          {tag}
        </Text>
      ))}
    </Column>
  );
};

export const Tags = ({ tags, options = [], limit = 5, onChange, ...props }) => {
  const { t } = useTranslation();

  const [filteredTags, setFilteredTags] = useState([]);
  const [instance, setInstance] = useState(null);
  const [tag, setTag] = useState("");
  const [tags_, setTags] = useState(tags || []);
  const [showMore, setShowMore] = useState(false);

  const fuse = new Fuse(options || [], searchOptions);

  useEffect(() => {
    if (tags === tags_) return;
    setTags(tags);
  }, [tags]);

  useEffect(() => {
    if (!instance) return;

    if (filteredTags.length === 0) {
      instance.hide();
    } else {
      instance.show();
    }
  }, [filteredTags]);

  const addTag = (tag) => {
    setFilteredTags([]);

    if (!tags_.includes(tag)) {
      const newTags = [...tags_, tag.replace(/^\s+|\s+$/g, "")];
      setTags(newTags);
      if (onChange) onChange(newTags);
    }

    setTag("");
  };

  const deleteTag = (tag) => {
    let newTags = tags_.filter((item) => item !== tag);
    setTags(newTags);
    if (onChange) onChange(newTags);
  };

  const removeLast = () => {
    let newTags = [...tags_];
    newTags.pop();
    setTags(newTags);
    if (onChange) onChange(newTags);
  };

  const renderTag = (tag) => {
    return (
      <Tag
        key={tag}
        label="Tag"
        mr="0.25rem"
        mb="0.25rem"
        height="fit-content"
        onDelete={() => deleteTag(tag)}
      >
        {tag}
      </Tag>
    );
  };

  let keyCode;
  const handleKeyPress = (e) => {
    e.stopPropagation();

    keyCode = e.keyCode || e.charCode;

    if (keyCode === 8 && !tag) {
      removeLast();
    } else if (keyCode === 27) {
      setTag("");
      setFilteredTags([]);
      instance.hide();
    } else if (keyCode === 13) {
      if (tag) addTag(tag);
    }
  };

  let remain = tags.length > limit ? tags.length - limit : 0;

  return (
    <Flex label="Tags" minHeight="2rem" alignItems="flex-start" {...props}>
      {tags.length === 0 && <Icon.Tag mt="0.3rem" size="16px" />}

      <Flex flexWrap="wrap" ml="0.25rem">
        {tags_.filter((tag, index) => index < limit || showMore).map(renderTag)}
        {remain > 0 && (
          <Action
            paddingTop="0.25rem"
            paddingBottom="0.25rem"
            fontSize="0.75rem"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "-" + t("show less") : `+${remain}`}
          </Action>
        )}

        <Tippy
          placement="bottom-start"
          trigger="manual"
          interactive={true}
          offset={[0, -4]}
          render={(attrs) => (
            <Dropdown
              attrs={attrs}
              addTag={addTag}
              filteredTags={filteredTags}
              instance={instance}
            />
          )}
          onCreate={setInstance}
        >
          <Flex>
            <Input
              autoExpand={true}
              color="t3"
              clearOnEnter={true}
              placeholder={t("tag...")}
              onChange={(value) => {
                setFilteredTags(
                  fuse
                    .search(value)
                    .filter((tag) => !tags.includes(tag.item))
                    .map((result) => result.item)
                );
                setTag(value);
              }}
              onKeyDown={handleKeyPress}
              variant="tags"
            />
          </Flex>
        </Tippy>
      </Flex>
    </Flex>
  );
};
