import { mergeMap, withLatestFrom } from "rxjs/operators";

import { ACTIONS, STORE } from "store";
import { HISTORY_TYPES } from "constant";
import { t } from "i18n";

export const updates = (action$, state$, { dispatch }) => {
  return action$.ofType("updates::UPDATE_UPDATES").pipe(
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const users = STORE.getState()["spaces"]["users"];
      dispatch(
        ACTIONS.toast.addToast({
          message: `${t(HISTORY_TYPES[action.payload.message.type_id])}, ${
            users[action.payload.message.user_id].nickname
          }`,
          type: "success",
          autoClose: 5000
        })
      );
      return [];
    })
  );
};
