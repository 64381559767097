import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  ActionsMenu,
  Button,
  Column,
  Empty,
  Flex,
  Link,
  Overlay,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { DEFAULT_MARGIN } from "constant";
import { Header } from "components";

import NewCategory from "./NewCategory";

export const Categories = ({ spaceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const categories = useSelector((state) => state["topic"].categories);
  const categories = useSelector(
    (state) => state["topic"].spaceCategories[spaceId]
  );
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [newCategory, setNewCategory] = useState(false);

  useEffect(() => {
    // if (categories) return;
    dispatch(
      ACTIONS.fetch.get(
        "topic",
        `${API_ENDPOINT}/category?space_id=${spaceId}&limit=0`,
        ACTIONS.state.loadObject,
        (state, response) => {
          state.categories = response.objects;
          state.spaceCategories[spaceId] = response.objects;
          return state;
        }
      )
    );
  }, [dispatch, spaceId]);

  const actions = {
    actions: [
      {
        label: t("New category"),
        icon: "Plus",
        action: () => {
          setNewCategory({
            spaceId: spaceId
          });
        }
      }
    ]
  };

  if (!categories) return null;

  return (
    <>
      {newCategory && (
        <Overlay
          open={newCategory}
          setOpen={setNewCategory}
          maxWidth={800}
          width="90%"
          maxHeight="90%"
          borderRadius="0.5rem"
          bg="l0"
          p={DEFAULT_MARGIN}
          flexDirection="column"
        >
          <NewCategory
            open={newCategory}
            setOpen={setNewCategory}
            spaceId={spaceId}
          />
        </Overlay>
      )}

      <Column overflow="auto">
        <Header>
          <Flex justifyContent="space-between" alignItems="center" width={1}>
            <Text textStyle="subtitle">
              {spaceId ? spaces[spaceId].name : "Categories"}
            </Text>

            {false && spaceId && <ActionsMenu item={actions} hidden={false} />}

            {spaceId && (
              <Button
                style={{ marginLeft: "1rem" }}
                variant="primary"
                value={t("New category")}
                onClick={() => setNewCategory(true)}
              />
            )}
          </Flex>
        </Header>

        <Column overflow="auto">
          {categories.length ? (
            <>
              {categories
                .filter((category) =>
                  spaceId ? category.space_id === parseInt(spaceId) : true
                )
                .map((category, index) => (
                  <Link
                    key={index}
                    to={`/topic/${category.space_id}/${category.id}`}
                  >
                    <Flex
                      mt={index ? "1rem" : DEFAULT_MARGIN}
                      bg="l2"
                      borderRadius="0.5rem"
                      p="1rem"
                      flexDirection="column"
                      style={{ cursor: "pointer" }}
                    >
                      <Text textStyle="subtitle">{category.name}</Text>
                      <Text mt="0.5rem">{category.description}</Text>
                    </Flex>
                  </Link>
                ))}
            </>
          ) : (
            <Empty />
          )}
        </Column>
      </Column>
    </>
  );
};
