import { createActions, handleActions } from "redux-actions";

const modelId = "files";

const options = { prefix: "files", namespace: "::" };

export const actions = createActions(
  {
    ADD_FOLDER: (response) => ({
      module: modelId,
      response
    }),

    ADD_FILES: (response) => ({
      module: modelId,
      response
    }),

    DELETE_FILE: (response) => ({
      module: modelId,
      response
    }),

    DELETE_FOLDER: (response) => ({
      module: modelId,
      response
    }),

    RENAME_FOLDER: (response) => ({
      module: modelId,
      response
    }),

    SET_FILTER: (key, value) => ({
      module: modelId,
      key,
      value
    })
  },
  options
);

export const reducer = handleActions(
  {
    ADD_FOLDER: (state, { payload: { response } }) => {
      state.files[response.id] = response;
      state.folders[response.id] = response;
      return state;
    },

    ADD_FILES: (state, { payload: { response } }) => {
      state.files[response.id] = response;
      state.folders[response.folder_id || response.root_id][response.id] =
        response;
      return state;
    },

    DELETE_FILE: (state, { payload: { response } }) => {
      if (response.success) {
        delete state.files[response.id];
        delete state.folders[response.id];
      }

      return state;
    },

    DELETE_FOLDER: (state, { payload: { response } }) => {
      return state;
    },

    RENAME_FOLDER: (state, { payload: { response } }) => {
      return state;
    },

    SET_FILTER: (state, { payload: { key, value } }) => {
      state.filters[key] = value;
      return state;
    }
  },
  {},
  options
);
