import React from "react";

import { Column } from "tomato";

// <Column maxWidth="60rem" overflow="auto">

export const MainWindow = ({ children, ...props }) => {
  return (
    <Column
      height="100%"
      width={1}
      minWidth="0px"
      position="relative"
      maxWidth="60rem"
      overflow="auto"
      bg="l0"
      px="3.5rem"
      // borderLeft="1px solid lightGrey"
      // boxShadow="0px 0px 8px 0px hsla(240, 0%, 80%)"
      boxShadow="0px 0px 1px 0px hsla(240, 0%, 50%)"
      {...props}
    >
      {children}
    </Column>
  );
};

export { MainWindow as Main };
