import React from "react";
import { useSelector } from "react-redux";

import { Text } from "tomato";

import { Space } from "./Space";

export const Spaces = ({ activeSpaces }) => {
  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);

  const spacesRemaining = Object.keys(spaces).filter(
    (spaceId) =>
      !activeSpaces.includes(spaceId) &&
      spaces[spaceId].members.some((member) => parseInt(member.id) === user.id)
  );

  return (
    <>
      <Text
        mb="0.5rem"
        fontSize="0.625rem"
        fontWeight="bold"
        style={{ textTransform: "uppercase" }}
      >
        Channels
      </Text>

      {activeSpaces.map((spaceId, index) => (
        <Space key={index} space={spaces[spaceId]} />
      ))}

      {spacesRemaining.map((spaceId, index) => (
        <Space key={index} space={spaces[spaceId]} />
      ))}
    </>
  );
};
