import React from "react";

import { Text } from "../Text";

export const Pill = ({ count, bg = "red", color = "white", fontSize, ...props }) => {
  return (
    <Text
      borderRadius="0.5rem"
      px="0.375rem"
      py="0.0625rem"
      fontSize={fontSize || "0.625rem"}
      {...props}
      fontWeight="bold"
      bg={bg}
      color={color}
    >
      {count}
    </Text>
  );
};
