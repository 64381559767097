import { createActions, handleActions } from "redux-actions";

import { DEFAULT_ITEMS, ITEM_TYPE } from "constant";

const modelId = "forms";

const options = { prefix: "forms", namespace: "::" };

export const actions = createActions(
  {
    ADD_FORM: (response) => ({
      module: modelId,
      response
    }),

    ADD_ITEM: (typeId, itemIndex, pageIndex) => ({
      module: modelId,
      typeId,
      itemIndex,
      pageIndex
    }),

    ADD_PAGE: (itemIndex, pageIndex) => ({
      module: modelId,
      itemIndex,
      pageIndex
    }),

    DELETE_ITEM: (itemIndex, pageIndex) => ({
      module: modelId,
      itemIndex,
      pageIndex
    }),

    UPDATE_ITEM: (itemIndex, pageIndex, key, value) => ({
      module: modelId,
      itemIndex,
      pageIndex,
      key,
      value
    }),

    UPDATE_PAGE: (pageIndex, key, value) => ({
      module: modelId,
      pageIndex,
      key,
      value
    }),

    UPDATE_SETTINGS: (key, value) => ({
      module: modelId,
      key,
      value
    }),

    UPDATE_CONTENT: (content) => ({
      module: modelId,
      content
    }),

    UPDATE_FORM: (form) => ({
      module: modelId,
      form
    })
  },
  options
);

export const reducer = handleActions(
  {
    ADD_FORM: (state, { payload: { response } }) => {
      state.form = response;
      return state;
    },

    ADD_PAGE: (state, { payload: { itemIndex, pageIndex } }) => {
      let page = DEFAULT_ITEMS[ITEM_TYPE.PAGE];
      state.form.content.pages.splice(pageIndex + 1, 0, page);
      state.editing = { pageIndex: pageIndex + 1, itemIndex: null };
      return state;
    },

    ADD_ITEM: (state, { payload: { typeId, itemIndex, pageIndex } }) => {
      let items = state.form.content.pages[pageIndex].items;

      let item = DEFAULT_ITEMS[typeId];

      if (!itemIndex) {
        items.unshift(item);
      } else {
        items.splice(itemIndex + 1, 0, item);
      }

      if (!itemIndex) {
        itemIndex = 0;
      } else itemIndex += 1;
      state.editing = { pageIndex, itemIndex };
      return state;
    },

    DELETE_ITEM: (state, { payload: { itemIndex, pageIndex } }) => {
      if (!itemIndex && itemIndex !== 0) {
        state.form.content.pages.splice(pageIndex, 1);
      } else {
        let page = state.form.content.pages[pageIndex];
        let items = page.items;
        items.splice(itemIndex, 1);
      }
      return state;
    },

    UPDATE_CONTENT: (state, { payload: { content } }) => {
      state.form.content = content;
      return state;
    },

    UPDATE_ITEM: (state, { payload: { itemIndex, pageIndex, key, value } }) => {
      let item = state.form.content.pages[pageIndex].items[itemIndex];
      item[key] = value;
      return state;
    },

    UPDATE_PAGE: (state, { payload: { pageIndex, key, value } }) => {
      let page = state.form.content.pages[pageIndex];
      page[key] = value;
      return state;
    },

    UPDATE_SETTINGS: (state, { payload: { key, value } }) => {
      state.form.content.settings[key] = value;
      return state;
    },

    UPDATE_FORM: (state, { payload: { form } }) => {
      state.form = form;
      return state;
    }
  },
  {},
  options
);
