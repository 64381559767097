import React from "react";

import { Flex } from "../Flex";
import { getShadow } from "../../themes/util";
import { Text } from "../Text";
import { useTranslation } from "react-i18next";

export const Options = ({
  label,
  onSelect,
  options,
  selected,
  size = "MEDIUM",
  uppercase = false,
  ...props
}) => {
  const { t } = useTranslation();

  const shadow = (showShadow) => {
    if (showShadow) return { ...getShadow("s0") };
  };

  if (!options) return null;

  const SIZES = {
    MEDIUM: {
      fontSize: uppercase ? "0.675rem" : "0.75rem",
      minWidth: "4rem",
      px: "0.75rem",
      py: "0.25rem"
    },
    LARGE: {
      fontSize: uppercase ? "0.75rem" : "0.875rem",
      minWidth: "5rem",
      px: "1rem",
      py: "0.5rem"
    }
  };

  return (
    <Flex flexDirection="column" {...props}>
      {label && (
        <Text fontWeight="semibold" color="t2" ml="0.5rem" mb="0.5rem">
          {t(label)}
        </Text>
      )}

      <Flex
        borderRadius="0.375rem"
        bg="l3"
        justifyContent="space-between"
        width={props.width || "fit-content"}
      >
        {options.map((option, index) => (
          <Flex
            key={index}
            width={1 / options.length}
            m="0.125rem"
            py="0.5rem"
            borderRadius="0.375rem"
            textAlign="center"
            bg={selected && selected.id === option.id ? "l0" : ""}
            style={{
              textTransform: uppercase ? "uppercase" : "",
              cursor: "pointer"
            }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => onSelect && onSelect(option)}
       //     {...shadow(selected && selected.id === option.id)}
            fontWeight="semibold"
            color={selected && selected.id === option.id ? "t1" : "t3"}
            justifyContent="center"
            alignItems="center"
            {...SIZES[size.toUpperCase()]}
          >
            <Text
              ml="0.5rem"
              mr={option.count && option.count !== 0 ? "0.25rem" : "0.5rem"}
              style={{
                whiteSpace: "nowrap",
                textTransform: uppercase ? "uppercase" : "",
                cursor: "pointer"
              }}
              width="fit-content"
              fontWeight="semibold"
              color={selected && selected.id === option.id ? "t1" : "t3"}
            >
              {t(option.value)}
            </Text>

            {option.count !== 0 && option.count && (
              <Flex
                borderRadius="0.375rem"
                width="fit-content"
                bg={selected && selected.id === option.id ? "lightBlue" : "l1"}
              >
                <Text
                  fontWeight="semibold"
                  fontSize="0.6875rem"
                  my="0.125rem"
                  mx="0.25rem"
                  textAlign="center"
                  color={selected && selected.id === option.id ? "t1" : "t3"}
                >
                  {option.count}
                </Text>
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
