import React from "react";

import Icon from "../Icon";

const Circle = props => {
  return (
    <Icon {...props}>
      <circle cx="12" cy="12" r="10" />
    </Icon>
  );
};

export default Circle;
