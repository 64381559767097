import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dropzone } from "../Dropzone";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Image } from "../Image";
import { IMAGE_TYPES } from "../../constants/mimeTypes";
import { Spinner } from "../Spinner";
import { Text } from "../Text";

const RenderUploadStatus = ({ index, status }) => {
  return (
    <Flex ml={2} width="1.5rem">
      {status[index] === 100 ? (
        <Icon.Ok minWidth={24} minHeight={24} />
      ) : (
        <Spinner width={24} height={24} />
      )}
    </Flex>
  );
};

const RenderPreview = ({ removeFile, index, file, sendingStatus, status }) => {
  let type = file.type.split("/")[1];

  return (
    <Flex maxHeight={60} minHeight={60} alignItems="center">
      <Flex width={60} ml={2} mr={2}>
        {IMAGE_TYPES.includes(file.type) ? (
          <Image
            borderRadius={4}
            width={1}
            maxHeight={40}
            src={URL.createObjectURL(file)}
          />
        ) : (
          <Flex
            bg="white"
            width={1}
            height={40}
            borderRadius={4}
            border="1px solid lightGrey"
          >
            <Text
              m="auto"
              fontSize="12px"
              fontWeight="bold"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                verticalAlign: "middle"
              }}
            >
              {type}
            </Text>
          </Flex>
        )}
      </Flex>

      <Text fontSize="0.75rem" ml={0} mr="auto">
        {file.name}
      </Text>

      {sendingStatus === "CONFIRMING_SELECT" && (
        <Icon.Trash
          style={{ cursor: "pointer" }}
          ml={2}
          minHeight="1.5rem"
          minWidth="1.5rem"
          onClick={() => removeFile(index)}
        />
      )}

      {["SENDING", "SENT"].includes(sendingStatus) && (
        <RenderUploadStatus index={index} status={status} />
      )}
    </Flex>
  );
};

export const Upload = ({
  dropzone = true,
  endpoint,
  endAction,
  onAdd,
  maxFiles,
  maxFileSize,
  id,
  ...props
}) => {
  const { t } = useTranslation();

  const SENDING_STATUS = {
    SELECT: t("Select files"),
    CONFIRMING_SELECT: t("Confirming selected files"),
    SENDING: t("Sending files"),
    SENT: t("Files sent")
  };

  const [files, setFilesState] = useState([]);
  // const [enabledDragDrop, setEnabledDragDrop] = useState(true);
  const [status, setStatusState] = useState({});
  const [sendingStatus, setSendingStatus] = useState("SELECT");

  const inputRef = useRef();

  useEffect(() => {
    if (files.length === 0) {
      setSendingStatus("SELECT");
    } else {
      onAdd && onAdd(files);
      sendFiles();
    }
  }, [files]);

  const removeFile = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const setFiles = (files) => {
    setFilesState([...files]);
    for (var x = 0; x < files.length; x++) {
      status[x] = 0;
      setStatusState({ ...status });
    }
  };

  const postFile = (file, index) => {
    const payload = new FormData();
    const xhr = new XMLHttpRequest();

    payload.append("file", file);
    payload.append("id", JSON.stringify(id));

    xhr.withCredentials = true;

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        endAction && endAction(xhr.response);
        status[index] = 100;
        setStatusState({ ...status });
      }
    };

    xhr.upload.onprogress = (e) => {
      const done = e.position || e.loaded;
      const total = e.totalSize || e.total;
      const perc = Math.floor((done / total) * 100);

      if (perc >= 100) {
        setTimeout(() => {
          // setEnabledDragDrop(true);
          setSendingStatus("SENT");
        }, 750);
      } else {
        status[index] = perc;
        setStatusState({ ...status });
      }
      status[index] = perc;
      setStatusState({ ...status });
    };

    xhr.open("POST", endpoint);
    xhr.responseType = "json";
    xhr.send(payload);
  };

  const sendFiles = () => {
    setSendingStatus("SENDING");

    for (var x = 0; x < files.length; x++) {
      postFile(files[x], x);
    }
  };

  const hasFiles = files.length !== 0;
  if (!dropzone)
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        onClick={() => inputRef.current.click()}
      >
        <input
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
          multiple={false}
          onChange={(e) => {
            setFiles(e.target.files);
          }}
        />
        {props.children}
      </Flex>
    );

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      width={1}
      borderTopLeftRadius={8}
      borderBottomLeftRadius={8}
    >
      {sendingStatus === "SELECT" && (
        <Dropzone
          files={files}
          setFiles={setFiles}
          maxFiles={maxFiles}
          maxFileSize={maxFileSize}
        />
      )}

      {hasFiles && (
        <Flex
          alignItems="center"
          flexDirection="column"
          overflow="auto"
          borderTopRightRadius={8}
          borderBottomRightRadius={8}
        >
          {files.map((file, index) => (
            <RenderPreview
              key={index}
              index={index}
              removeFile={removeFile}
              sendingStatus={sendingStatus}
              file={file}
              status={status}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
