import { ignoreElements, tap } from "rxjs/operators";

export const redirect = action$ => {
  return action$.ofType("REDIRECT").pipe(
    tap(action => {
      action.payload.route.history.push(action.payload.url);
    }),
    ignoreElements()
  );
};

export const redirectAfterCreation = action$ => {
  return action$.ofType("REDIRECT_AFTER_CREATION").pipe(
    tap(action => {
      action.payload.history.push(
        `${action.payload.url}/${action.payload.response.id}`
      );
    }),
    ignoreElements()
  );
};
