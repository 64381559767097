import React, { useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Card,
  Flex,
  FlexAction,
  Input,
  Options,
  SimpleEditor,
  Spinner,
  Text
} from "tomato";

const CUSTOM_FIELDS = {
  1: "Text", // TEXT
  2: "Number", // NUMBER
  3: "DateTime", // DATETIME
  4: "Radio", // RADIO
  5: "Checkbox", // CHECKBOX
  6: "Dropdown", // DROPDOWN
  7: "TextArea", // TEXTAREA
  10: "Float" // VALUE
};

const CFRadio = (cf) => {
  const [value, setValue] = useState(cf.properties.value);

  return (
    <Flex alignItems="center">
      <Text minWidth="10rem">{cf.properties.label}</Text>
      <Input value={value} onChange={setValue} />
    </Flex>
  );
};

const CFCheckbox = (cf) => {
  const [value, setValue] = useState(cf.properties.value);

  return (
    <Flex alignItems="center">
      <Text minWidth="10rem">{cf.properties.label}</Text>
      <Input value={value} onChange={setValue} />
    </Flex>
  );
};

const CFText = (cf) => {
  const [value, setValue] = useState(cf.properties.value);

  return (
    <Flex alignItems="center">
      <Text minWidth="10rem">{cf.properties.label}</Text>
      <Input value={value} onChange={setValue} />
    </Flex>
  );
};

const CFTextArea = (cf) => {
  const [value, setValue] = useState(cf.properties.value);

  return (
    <Flex alignItems="flex-start">
      <Text minWidth="10rem">{cf.properties.label}</Text>
      <SimpleEditor value={value} onChange={setValue} />
    </Flex>
  );
};

const CFDate = (cf) => {
  const [value, setValue] = useState(cf.properties.value);

  return (
    <Flex alignItems="flex-start">
      <Text minWidth="10rem">{cf.properties.label}</Text>
      <SimpleEditor value={value} onChange={setValue} />
    </Flex>
  );
};

const CFDateTime = (cf) => {
  const [value, setValue] = useState(cf.properties.value);

  return (
    <Flex alignItems="flex-start">
      <Text minWidth="10rem">{cf.properties.label}</Text>
      <SimpleEditor value={value} onChange={setValue} />
    </Flex>
  );
};

const CF = {
  1: CFText,
  2: CFTextArea,
  3: CFRadio,
  4: CFCheckbox,
  5: CFDate,
  6: CFDateTime
};

export const Custom = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);

  const [instance, setInstance] = useState(null);

  const custom = [
    { type: 1, properties: { label: "enderço", value: "teste" } },
    { type: 2, properties: { label: "comentários", value: "aqui mesmo" } },
    {
      type: 3,
      properties: {
        label: "Radio",
        values: [
          [
            { id: 1, value: "green" },
            { id: 2, value: "red" }
          ]
        ],
        selected: [1]
      }
    },
    {
      type: 4,
      properties: {
        label: "Checkbox",
        values: [
          [
            { id: 1, value: "green" },
            { id: 2, value: "red" }
          ]
        ],
        selected: [1, 2]
      }
    },
    { type: 5, properties: { label: "Date", value: "teste" } },
    { type: 6, properties: { label: "Datetime", value: "teste" } }
  ];

  return (
    <Flex flexDirection="column">
      <Tippy
        placement="bottom-end"
        trigger="click"
        interactive={true}
        offset={[0, 4]}
        render={(attrs) => (
          <>
            {instance ? (
              <Flex
                {...attrs}
                bg="l0"
                p={4}
                borderRadius={8}
                boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
              >
                <Flex>Text</Flex>
              </Flex>
            ) : (
              ""
            )}
          </>
        )}
        onCreate={setInstance}
      >
        <FlexAction mb="1rem" size="small" mr={0} ml="auto">
          {t("add custom field")}
        </FlexAction>
      </Tippy>

      {custom.map((field) => {
        return (
          <Flex maxWidth="30rem" mb="1rem">
            {CF[field.type](field)}
          </Flex>
        );
      })}
    </Flex>
  );
};
