import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import Tippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";

import { Flex, Icon, Input, Tag, Text } from "tomato";

export const Row = ({ children, label, ...props }) => {
  return (
    <Flex {...props}>
      {label && (
        <Text mt="0.5rem" fontSize="0.875rem" fontWeight="semibold" width="8rem">
          {label}
        </Text>
      )}
      {children && <Flex width={1}>{children}</Flex>}
    </Flex>
  );
};
