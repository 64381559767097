import React from "react";

import Icon from "../Icon";

export const Ok = props => {
  return (
    <Icon {...props}>
      <polyline points="20 6 9 17 4 10" />
    </Icon>
  );
};

export default Ok;
