import React from "react";
import Tippy from "@tippyjs/react/headless";

import { Flex, FlexHidden, Icon, Text } from "tomato";

export const ActionsMenu = ({ item, hidden = true, placement, ...props }) => {
  const Hidden = hidden ? FlexHidden : Flex;
  const position = placement ? { placement } : {};

  return (
    <>
      {item.actions && (
        <Tippy
          {...position}
          offset={[0, 4]}
          interactive={true}
          render={() => (
            <Flex
              bg="l0"
              zIndex="50"
              borderRadius='0.25rem'
              border="1px solid lightGrey"
              flexDirection="column"
              boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
            >
              {item.actions.map((action, index) => {
                const RenderIcon = action.icon ? Icon[action.icon] : null;
                return (
                  <Flex
                    key={index}
                    alignItems="center"
                    hoverColor="userLight"
                    py="0.5rem"
                    px="1rem"
                    pl="0.5rem"
                    onClick={(e) => {
                      e.stopPropagation();
                      action.action && action.action(item);
                    }}
                  >
                    {RenderIcon && <RenderIcon mr="0.25rem" />}
                    <Text style={{ cursor: "pointer" }} fontSize="0.875rem">
                      {action.label}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          )}
        >
          <Hidden {...props} style={{ cursor: "pointer" }}>
            <Icon.MoreVertical />
          </Hidden>
        </Tippy>
      )}
    </>
  );
};
