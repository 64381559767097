import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Button,
  Flex,
  formatDate,
  Icon,
  Overlay,
  Spinner,
  Switch,
  Text,
  orderObjects
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { Breadcrumb } from "./Breadcrumb";
import { DEFAULT_MARGIN } from "constant";
import { Header } from "components";
import { Members } from "components";

import NewTopic from "./NewTopic";

const Update = ({ lastRead, topic }) => {
  const { t } = useTranslation();

  const categories = useSelector((state) => state["topic"].categories);
  const loadedTopic = useSelector((state) => state["topic"].obj);
  const topics = useSelector((state) => state["topic"].objs);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const spaces = useSelector((state) => state["spaces"].spaces);

  const history = useHistory();

  let orderedTopics = topics
    ? orderObjects(topics, "update_date", "desc")
    : null;

  if (!topics) return <Spinner />;

  // if (orderedTopics) {
  //   orderedTopics = orderedTopics.filter((topic) =>
  //     filter.category_id
  //       ? topic.category_id === filter.category_id
  //       : filter.space_id
  //       ? filter.space_id === topic.space_id
  //       : false
  //   );
  // }

  let pinned = orderedTopics.filter((topic) => topic.pinned);
  let notPinned = orderedTopics.filter((topic) => !topic.pinned);
  orderedTopics = [...pinned, ...notPinned];

  const getCategory = (topic) =>
    categories.find((category) => category.id === topic.category_id);

  const category = getCategory(topic);
  const lastUpdate = new Date(topic.update_date).getTime();
  const now = new Date().getTime();
  const lastReadTime = lastRead[topic.id] ? parseInt(lastRead[topic.id]) : 0;
  const notRead =
    lastReadTime < lastUpdate + 15 * 60 * 1000 && lastUpdate > now - 86400000;

  return (
    <Flex
      style={{ cursor: "pointer" }}
      onClick={() => {
        history.push(`/topic/${category.space_id}/${category.id}/${topic.id}`);
      }}
      borderRadius="0.5rem"
      bg={loadedTopic && topic.id === loadedTopic.id ? "lightBlue" : "l2"}
      hoverColor="userHover"
      px="0.75rem"
      py="0.5rem"
      mb="1rem"
    >
      <Avatar
        size="x-large"
        src={users[topic.last_reply_from_id || topic.created_by_id].avatar}
      />
      <Flex ml="0.5rem" flexDirection="column" width={1}>
        <Flex alignItems="center" justifyContent="space-between" width={1}>
          <Flex alignItems="center">
            {notRead && (
              <Text
                fontSize="0.5rem"
                mt="0.25rem"
                mr="0.25rem"
                fontWeight="bold"
                bg="red"
                color="white"
                px="0.25rem"
                py="0.125rem"
                borderRadius="0.125rem"
              >
                {t("NEW")}
              </Text>
            )}

            {topic.pinned && (
              <Icon.Pin
                mr="0.5rem"
                stroke="blue"
                style={{ stroke: "#ff0000", fill: "#ff0000" }}
              />
            )}
            <Text fontWeight="bold">{topic.title}</Text>
          </Flex>
          <Text
            mr={0}
            ml="auto"
            borderRadius="4rem"
            bg="userBold"
            px="0.5rem"
            fontSize="0.6875rem"
            py="0.25rem"
            fontWeight="semibold"
            height="fit-content"
          >
            {topic.replies_count}
          </Text>
        </Flex>

        {topic.summary && (
          <Flex
            px="0.5rem"
            mt="0.5rem"
            mb="0.25rem"
            fontSize="0.75rem"
            color="t2"
            borderRadius="0.25rem"
            bg="userVeryLight"
            lineHeight="1rem"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
            display="-webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;"
          >
            {topic.summary}
          </Flex>
        )}

        <Flex mt="0.25rem" alignItems="center">
          {topic.last_reply_from_id && <Icon.Reply mr="0.25rem" />}
          <Text fontSize="0.75rem" fontWeight="semibold" color="t3">
            {users[topic.last_reply_from_id || topic.created_by_id].name} in{" "}
            {spaces[topic.space_id].name}, {"  "}
            {`${getCategory(topic).name}`}
            {", "}
            {formatDate(
              topic.update_date,
              user.locale,
              user.timezone
            ).distance()}
          </Text>
        </Flex>

        {false && (
          <Flex alignItems="center" mt="0.5rem">
            {topic.last_reply_from_id && (
              <>
                <Icon.Reply />
                <Avatar
                  ml="0.25rem"
                  size="medium"
                  src={users[topic.last_reply_from_id].avatar}
                />
                <Text
                  ml="0.25rem"
                  color="t4"
                  fontWeight="semibold"
                  fontSize="0.75rem"
                >
                  {users[topic.last_reply_from_id].nickname}
                  {", "}
                  {formatDate(
                    topic.update_date,
                    user.locale,
                    user.timezone
                  ).distance()}
                </Text>
              </>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const Updates = ({ categoryId, spaceId, lastRead, setLastRead }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const categories = useSelector((state) => state["topic"].categories);
  const topics = useSelector((state) => state["topic"].objs);
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [newTopic, setNewTopic] = useState(false);

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "topic",
        `${API_ENDPOINT}/topic?category_id=${categoryId}&limit=0`,
        ACTIONS.state.loadObject,
        (state, response) => {
          let topics = {};
          response.objects.forEach((topic) => (topics[topic.id] = topic));
          state.objs = response.objects;
          return state;
        }
      )
    );
  }, [categoryId, dispatch, spaceId]);

  let orderedTopics = topics
    ? orderObjects(topics, "update_date", "desc")
    : null;

  if (!topics || !categories) return <Spinner />;

  if (orderedTopics && (categoryId || spaceId)) {
    orderedTopics = orderedTopics.filter((topic) =>
      categoryId
        ? topic.category_id === categoryId
        : spaceId
        ? spaceId === topic.space_id
        : false
    );
  }

  let pinned = orderedTopics.filter((topic) => topic.pinned);
  let notPinned = orderedTopics.filter((topic) => !topic.pinned);

  orderedTopics = [...pinned, ...notPinned];

  const category = categoryId
    ? categories.find((category) => category.id === categoryId)
    : null;

  //   const spaceId =
  //     (filter && filter.space_id) || (category && category.space_id) || 1;

  let breadcrumb = [];
  // {
  //   title: t("All"),
  //   dispatch: () =>
  //     dispatch(
  //       ACTIONS.state.changeState("topic", (state) => {
  //         state.loadingObjId = null;
  //         state.obj = null;
  //         state.filter = null;
  //         return state;
  //       })
  //     )
  // }
  // ];

  if (spaceId) {
    breadcrumb.push({
      title: spaces[spaceId].name,
      route: `/topic/${spaceId}`
    });
  }

  if (categoryId) {
    breadcrumb.push({
      title: category.name
    });
  }

  return (
    <>
      <Overlay
        open={newTopic}
        setOpen={setNewTopic}
        maxWidth={800}
        width="90%"
        maxHeight="90%"
        borderRadius="0.5rem"
        bg="l0"
        p={DEFAULT_MARGIN}
        flexDirection="column"
      >
        <NewTopic
          open={newTopic}
          setOpen={setNewTopic}
          spaceId={spaceId}
          categoryId={categoryId}
        />
      </Overlay>

      <Header flexDirection="column" width={1} alignItems="flex-start">
        <Flex justifyContent="space-between" minHeight="2rem" width={1}>
          {breadcrumb.length ? (
            <Breadcrumb breadcrumb={breadcrumb} />
          ) : (
            <Text textStyle="subtitle">{t("Updates")}</Text>
          )}

          {categoryId && (
            <Button
              style={{ marginLeft: "1rem" }}
              variant="primary"
              value={t("New post")}
              onClick={() => setNewTopic(true)}
            />
          )}
          {false && spaceId && <Members spaceId={spaceId} />}
        </Flex>

        <Switch
          mt="0.5rem"
          checked={lastRead["showRead"]}
          onCheck={(value) => {
            setLastRead({ ...lastRead, showRead: value });
          }}
          size="small"
          label="Exibir apenas atualizadas"
        />
      </Header>

      <Flex flexDirection="column" pb="5rem">
        {category && category.description && (
          <Flex
            bg="lightGreen"
            p="1rem"
            borderRadius="0.5rem"
            mb={DEFAULT_MARGIN}
          >
            {category.description}
          </Flex>
        )}

        {orderedTopics
          .filter((topic) => {
            return !(
              lastRead["showRead"] &&
              lastRead[topic.id] &&
              parseInt(lastRead[topic.id]) >
                new Date(topic.update_date).getTime()
            );
          })
          .map((topic, index) => (
            <Update lastRead={lastRead} topic={topic} key={topic.id} />
          ))}
      </Flex>
    </>
  );
};
