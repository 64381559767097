import React from "react";

import { Flex } from "tomato";

import { getMonth } from "../util";
import { RenderWeek, RenderWeekDays } from "../Week";

export const Month = ({ events, date, startDate, range, endDate, users }) => {
  let month = getMonth(date, startDate, endDate);

  let weeks = [
    month.dates.slice(0, 7),
    month.dates.slice(7, 14),
    month.dates.slice(14, 21),
    month.dates.slice(21, 28),
    month.dates.slice(28, 35)
  ];

  return (
    <Flex
      width={1}
      flexDirection="column"
      height="100%"
      flexShrink="0"
      flexBasis="0%"
      flexGrow="1"
      overflow="hidden"
      position="relative"
      label="CalendarWrapper"
    >
      <RenderWeekDays />

      {weeks.map((week, index) => (
        <RenderWeek
          key={index}
          index={index}
          week={week}
          range={range}
          events={events}
          users={users}
        />
      ))}
    </Flex>
  );
};
