import React, { useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Card,
  ClickToEdit,
  Flex,
  Input,
  SimpleEditor,
  Switch,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { Menu } from "./Menu";
import { Question } from "./Question";
import { ShortAnswer, LongAnswer } from "./Answer";

const Title = ({ item, isEditing, updateItem }) => {
  const { t } = useTranslation();

  return (
    <>
      <ClickToEdit
        fontWeight="bold"
        color="t1"
        fontSize="1rem"
        enabled={true}
        value={item.title}
        placeholder={t("Enter title...")}
        // onBlur={updateGroupName}
      />
      <Input focus={true} value="Title" />
      <SimpleEditor placeholder="Description" />

      {isEditing && (
        <Flex alignItems="center" mt="0.5rem">
          <Switch
            checked={item.showDescription}
            onCheck={(value) => updateItem("showDescription", value)}
            text={t("Show description")}
          />
        </Flex>
      )}
    </>
  );
};

export const Item = ({ pageIndex, itemIndex, item }) => {
  const dispatch = useDispatch();

  const editing = useSelector((state) => state["forms"].editing);
  const form = useSelector((state) => state["forms"].form.content);

  const [instance, setInstance] = useState(null);

  const updateItem = (key, value) => {
    dispatch(ACTIONS.forms.updateItem(itemIndex, pageIndex, key, value));
  };

  const setEditing = (editing) => {
    dispatch(ACTIONS.state.setAttribute("forms", "editing", editing));
  };

  const isEditing =
    editing.pageIndex === pageIndex && editing.itemIndex === itemIndex;

  let color = `hsl(${form.settings.color.h}, ${form.settings.color.s}%, 50%)`;

  return (
    <Tippy
      key={itemIndex}
      placement="right-start"
      // trigger="click"
      interactive={true}
      offset={[0, 8]}
      appendTo="parent"
      render={(attrs) => (
        <>
          {instance ? (
            <Menu {...attrs} pageIndex={pageIndex} itemIndex={itemIndex} />
          ) : (
            ""
          )}
        </>
      )}
      onCreate={setInstance}
    >
      <Card
        width={1}
        flexDirection="column"
        mb="1rem"
        p="1.5rem"
        onClick={() => setEditing({ pageIndex, itemIndex })}
        borderLeft="6px solid"
        borderColor={isEditing ? color : "transparent"}
      >
        {item.typeId === 1 && (
          <Question
            item={item}
            isEditing={isEditing}
            pageIndex={pageIndex}
            itemIndex={itemIndex}
            updateItem={updateItem}
          />
        )}
        {item.typeId === 2 && (
          <Title
            item={item}
            isEditing={isEditing}
            pageIndex={pageIndex}
            itemIndex={itemIndex}
            updateItem={updateItem}
          />
        )}
      </Card>
    </Tippy>
  );
};
