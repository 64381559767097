import {
  startOfToday,
  endOfToday,
  isAfter,
  isBefore,
  isToday,
  isTomorrow,
  isThisWeek,
  isThisMonth,
  isYesterday,
  parseISO,
  subMonths,
  startOfMonth,
  endOfMonth,
  isWithinInterval,
  subWeeks,
  startOfWeek,
  endOfWeek
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const zonedDate = (date, timezone) => {
  let newDate;
  if (typeof date === "string") {
    newDate = parseISO(date);
    newDate = utcToZonedTime(newDate, timezone);
  } else {
    newDate = date;
  }
  return newDate;
};

const isLastMonth = (date, timezone) => {
  if (!date) return false;

  let today = Date.now();
  let lastMonthDate = subMonths(today, 1);

  let firstDayOfLastMonth = startOfMonth(lastMonthDate);
  let lastDayOfLastMonth = endOfMonth(lastMonthDate);

  let newDate = zonedDate(date, timezone);
  return isWithinInterval(newDate, {
    start: firstDayOfLastMonth,
    end: lastDayOfLastMonth
  });
};

const isLastWeek = (date, timezone) => {
  if (!date) return false;

  let today = Date.now();
  let lastWeekDate = subWeeks(today, 1);

  let firstDayOfLastWeek = startOfWeek(lastWeekDate);
  let lastDayOfLastWeek = endOfWeek(lastWeekDate);

  let newDate = zonedDate(date, timezone);
  return isWithinInterval(newDate, {
    start: firstDayOfLastWeek,
    end: lastDayOfLastWeek
  });
};

export const isDueYesterday = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isYesterday(newDate);
};

export const isDueThisWeek = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isThisWeek(newDate);
};

export const isDueThisMonth = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isThisMonth(newDate);
};

export const isDueUntilToday = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isBefore(newDate, startOfToday());
};

export const isDueToday = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isToday(newDate, endOfToday());
};

export const isDueTomorrow = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isTomorrow(newDate, endOfToday());
};

export const isDueFromTomorrow = (date, timezone) => {
  if (!date) return false;
  let newDate = zonedDate(date, timezone);
  return isAfter(newDate, endOfToday());
};

export const filter = (filters, tasks, user, fuse) => {
  let filtered = {
    count: 0,
    pinned: [],
    grouped: {},
    ungrouped: [],
    all: [],
    filtered: [],
    tags: [],
    owners_ids: [],
    creators_ids: []
  };
  let listId;
  let projectId;
  let spaceId;

  if (tasks.length === 0) return filtered;

  let searchResult = [];

  if (filters.search && !fuse) return;

  if (filters.search) {
    searchResult = fuse
      .search(filters.search)
      .map((result) => parseInt(result.item.id));
  }

  if (filters.period) {
  }

  tasks.forEach((task) => {
    listId = task.list_id;
    projectId = task.project_id;
    spaceId = task.space_id;

    if (filters.search && !searchResult.includes(parseInt(task.id)))
      return null;

    // Filtro por tipo
    if (filters.type) {
      if (filters.type === "TASK") {
        if (task.type_id !== 1) return null;
      } else if (filters.type === "EVENT") {
        if (task.type_id !== 2) return null;
      }
    }

    // Filtro por prioridade
    switch (filters.priority) {
      case "URGENT":
        if (task.priority_id !== 3) return null;
        break;

      case "NOT_URGENT":
        if (task.priority_id === 3) return null;
        break;

      default:
        break;
    }

    // Filtro por status
    switch (filters.status) {
      case "OPEN":
        if (task.done_date) return null;
        break;

      case "CLOSED":
        if (!task.done_date) return null;
        break;

      default:
        break;
    }

    if (filters.status === "CLOSED") {
      switch (filters.period) {
        case "TODAY":
          if (!isDueToday(task.done_date, user.timezone)) return;
          break;

        case "YESTERDAY":
          if (!isDueYesterday(task.done_date, user.timezone)) return;
          break;

        case "THIS_WEEK":
          if (!isDueThisWeek(task.done_date, user.timezone)) return;
          break;

        case "LAST_WEEK":
          if (!isLastWeek(task.done_date, user.timezone)) return;
          break;

        case "THIS_MONTH":
          if (!isDueThisMonth(task.done_date, user.timezone)) return;
          break;

        case "LAST_MONTH":
          if (!isLastMonth(task.done_date, user.timezone)) return;
          break;

        default:
          break;
      }
    }

    // Filtro por assignment
    if (filters.assignment) {
      if (filters.assignment === "SPECIFIC_USER") {
        if (task.owner_id !== filters.assigneeId) return null;
      } else if (filters.assignment === "UNASSIGNED") {
        if (task.owner_id) return null;
      } else if (filters.assignment === "PARTICIPANT") {
        if (task.owner_id !== user.id && task.created_by_id !== user.id)
          return null;
      } else if (filters.assignment === "ASSIGNED_FOR_YOU") {
        if (!(task.owner_id === user.id && task.created_by_id !== user.id))
          return null;
      } else if (filters.assignment === "ASSIGNED_TO_YOU") {
        if (!(task.owner_id === user.id)) return null;
      } else if (filters.assignment === "ASSIGNED_BY_YOU") {
        if (
          !(
            task.owner_id !== user.id &&
            task.created_by_id === user.id &&
            task.owner_id
          )
        )
          return null;
      } else if (filters.assignment === "ASSIGNED_TO_OTHER") {
        if (!(task.owner_id && task.owner_id !== user.id)) return null;
      }
    }

    if (filters.due) {
      if (
        filters.due === "DUE_TODAY" &&
        !isDueToday(task.deadline, user.timezone)
      ) {
        return;
      } else if (
        filters.due === "DUE_TOMORROW" &&
        !isDueTomorrow(task.deadline, user.timezone)
      ) {
        return;
      } else if (
        filters.due === "DUE" &&
        !isDueUntilToday(task.deadline, user.timezone)
      ) {
        return;
      } else if (
        filters.due === "UPCOMING" &&
        !isDueFromTomorrow(task.deadline, user.timezone)
      ) {
        return;
      } else if (filters.due === "UNDEFINED" && task.deadline) {
        return;
      }
    }

    filtered["all"].push(task);

    if (filters.listId && parseInt(filters.listId) !== listId) return;
    if (filters.projectId && parseInt(filters.projectId) !== projectId) return;
    if (filters.spaceId && parseInt(filters.spaceId) !== spaceId) return;

    filtered["filtered"].push(task);

    if (task.pinned) {
      filtered["pinned"].push(task.id);
    } else {
      if (!(task.list_id in filtered["grouped"]))
        filtered["grouped"][task.list_id] = [];
      filtered["grouped"][task.list_id].push(task.id);
    }
  });

  return filtered;
};
