import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { Flex, Text } from "tomato";

export const Breadcrumb = ({ breadcrumb, textStyle = "subtitle" }) => {
  const history = useHistory();

  return (
    <Flex alignItems="center" minHeight="2.5rem">
      {breadcrumb.map((route, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && (
              <Text color="t3" mx="0.5rem">
                {">"}
              </Text>
            )}
            <Text
              style={{ cursor: route.route ? "pointer" : "" }}
              textStyle={textStyle}
              onClick={() => {
                route.route && history.push(route.route);
              }}
            >
              {route.title}
            </Text>
          </Fragment>
        );
      })}
    </Flex>
  );
};
