import React, { useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Flex, Icon, Input, orderObjects, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import Admin from "./Admin";

const Reveal = () => {
  return null;
};

const Spaces = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let spaces = useSelector((state) => state["space"].spaces);

  const [addingNewSpace, setAddingNewSpace] = useState(false);

  const addSpace = (name) => {
    setAddingNewSpace(false);
    dispatch(
      ACTIONS.fetch.post("task", `${API_ROOT}/space`, ACTIONS.space.addSpace, {
        name
      })
    );
  };

  const deleteSpace = (spaceId) => {
    dispatch(
      ACTIONS.fetch.delete(
        "task",
        `${API_ROOT}/space/${spaceId}/delete`,
        ACTIONS.space.deleteSpace
      )
    );
  };

  let orderedSpaces = orderObjects(spaces, "order", "desc");

  return (
    <Flex width={1} height="100%" flexDirection="column">
      <Flex
        alignItems="center"
        width={1}
        justifyContent="space-between"
        mb={4}
        pb={4}
        borderBottom="1px solid lightGrey"
      >
        <Text textStyle="subtitle" color="t1" fontWeight="bold">
          {t("Spaces")}
        </Text>

        <Button
          variant="primary"
          onClick={() => setAddingNewSpace(!addingNewSpace)}
          value={t("New space")}
        />
      </Flex>

      <Reveal height={48} width="100%" opened={addingNewSpace}>
        <Input
          width={1}
          placeholder={t("Add new space")}
          rightIcon="ReturnLeft"
          onEnter={addSpace}
          focus={addingNewSpace}
          setFocus={setAddingNewSpace}
        />
      </Reveal>

      <Droppable droppableId="spaces" type="SPACE">
        {(provided, snapshot) => (
          <Flex
            ref={provided.innerRef}
            width={1}
            flexDirection="column"
            minHeight={20}
            borderRadius={4}
            bg={snapshot.isDraggingOver ? "#ddf5ff" : "l2"}
            p={4}
            overflow="auto"
          >
            {orderedSpaces.map((space, index) => {
              return (
                <Draggable
                  draggableId={`space.${index}`}
                  index={index}
                  key={index}
                >
                  {(provided) => (
                    <Flex
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      flexDirection="column"
                      borderRadius={4}
                      width={1}
                      mb={orderedSpaces.length - 1 === index ? 0 : 2}
                      bg="white"
                      {...provided.dragHandleProps}
                    >
                      <Flex
                        alignItems="center"
                        px={2}
                        py={1}
                        justifyContent="space-between"
                        width={1}
                      >
                        <Text mr={1}>{space.name}</Text>
                        <Icon.Trash
                          style={{ cursor: "pointer" }}
                          width={14}
                          height={24}
                          minWidth={14}
                          minHeight={24}
                          onClick={() => deleteSpace(space.id)}
                        />
                      </Flex>
                    </Flex>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
    </Flex>
  );
};

const SpacesAdmin = () => {
  const { t } = useTranslation();
  const ADMIN = {
    icon: Icon.Spaces,
    title: t("All spaces admin"),
    options: [{ icon: "User", title: t("Spaces"), view: Spaces }]
  };
  return <Admin admin={ADMIN} />;
};

export default SpacesAdmin;
