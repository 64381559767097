import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Box, Flex, Icon, Image, Input, Overlay, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import { MEMBER_DESCRIPTION, MEMBER_TYPE } from "../constants";
import { t } from "i18n";

const STEPS = {
  START: 1,
  LISTS: 2,
  REVIEW: 3
};

// MEMBERS: 1,
// CONFIG: 2,
// POWERUPS: 5,
//  COSTS: 6,

export const NewProject = ({ open, setOpen, space }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let spaceId = space.id;
  const tasksUi = useSelector((state) => state["tasks"].ui);
  const user = useSelector((state) => state["account"].user);

  let members = {};
  members[user.id] = MEMBER_TYPE.ADMIN;

  const [activeStepId, setActiveStepId] = useState(STEPS.START);
  const [name, setName] = useState();
  const [lists, setLists] = useState([]);
  const [listName, setListName] = useState();

  const addProject = () => {
    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ROOT}/project`,
        ACTIONS.tasks.addProject,
        { name, members, lists, space_id: spaceId }
      )
    );
    setOpen(false);

    localStorage.setItem(
      "tasksUi",
      JSON.stringify({ ...tasksUi, hideEmptyLists: false })
    );

    dispatch(ACTIONS.tasks.updateUi("hideEmptyLists", false));
  };

  let style = { disabled: "disabled" };
  if (activeStepId === STEPS.START && name && name.length > 2) style = {};
  if (activeStepId === STEPS.LISTS && lists.length > 0) style = {};

  let listsStyle = { disabled: "disabled" };
  if (activeStepId === STEPS.LISTS && listName && listName.length > 2)
    listsStyle = {};

  const addList = (name) => {
    if (!name) return;
    setLists([...lists, name]);
    setListName("");
  };

  const removeFromList = (index) => {
    lists.splice(index, 1);
    setLists([...lists]);
  };

  return (
    <Overlay open={open} setOpen={setOpen} spaceId={spaceId}>
      <Flex
        m="auto"
        p="1.5rem"
        borderRadius="0.5rem"
        width="40rem"
        minWidth="20rem"
        minHeight="20rem"
        flexDirection="column"
        justifyContent="space-between"
        bg="l0"
        boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
        overflow="visible"
      >
        <Text textStyle="subtitle">
          {t("Add new project to space") + " " + space.name}
        </Text>

        <Box my="3rem" justifyContent="center" alignItems="center">
          {activeStepId === STEPS.START && (
            <Flex flexDirection="column">
              <Input
                label={t("Project name")}
                minLength={3}
                minWidth={400}
                maxWidth={600}
                placeholder={t("Project name") + "..."}
                onChange={setName}
                focus={true}
                clearOnEnter={false}
              />
            </Flex>
          )}

          {activeStepId === STEPS.LISTS && (
            <Flex flexDirection="column" minHeight="4rem">
              <Text>{t("Tasks list name")}</Text>
              <Flex alignItems="center" mt="1rem">
                <Input
                  minLength={3}
                  width={1}
                  placeholder={t("List name") + "..."}
                  onChange={setListName}
                  focus={true}
                />

                <Flex>
                  <Button
                    style={{ marginLeft: "0.5rem" }}
                    height="fit-content"
                    variant="primary"
                    onClick={() => addList(listName)}
                    {...listsStyle}
                    value={t("Add tasks list")}
                  />
                </Flex>
              </Flex>

              <Flex
                mt="2rem"
                alignItems="flex-start"
                flexWrap="wrap"
                overflow="auto"
                width={500}
                maxHeight={100}
              >
                {lists.map((name, index) => (
                  <Flex
                    key={index}
                    alignItems="center"
                    py={1}
                    bg="l2"
                    borderRadius={4}
                    mb={1}
                    mr={2}
                  >
                    <Text fontSize={14} mx={2}>
                      {name}
                    </Text>

                    <Icon.Close
                      width={12}
                      height={12}
                      style={{ cursor: "pointer" }}
                      mr={1}
                      onClick={() => removeFromList(index)}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}

          {activeStepId === STEPS.REVIEW && (
            <Flex flexDirection="column">
              <Flex mb="1rem">
                <Text fontSize="1rem" minWidth="10rem">
                  {t("Project name")}:
                </Text>

                <Text py="0.25rem" px="0.5rem" bg="l2" borderRadius="0.25rem">
                  {name}
                </Text>
              </Flex>

              <Flex mb="1rem">
                <Text fontSize="1rem" minWidth="10rem">
                  {t("Lists")}:
                </Text>

                <Flex
                  alignItems="flex-start"
                  flexWrap="wrap"
                  overflow="auto"
                  width={1}
                  maxHeight={100}
                >
                  {lists.map((name, index) => (
                    <Text
                      key={index}
                      py="0.25rem"
                      px="0.5rem"
                      bg="l2"
                      borderRadius="0.25rem"
                      mb="0.25rem"
                      mr="0.5rem"
                    >
                      {name}
                    </Text>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Box>

        <Flex justifyContent="flex-end">
          {activeStepId > 1 && (
            <Button
              variant="secondary"
              onClick={() => {
                setActiveStepId(activeStepId - 1);
              }}
              mr="1rem"
              value={t("Previous")}
            />
          )}

          {activeStepId < STEPS.REVIEW ? (
            <Button
              variant="primary"
              px="1rem"
              onClick={() => {
                setActiveStepId(activeStepId + 1);
              }}
              {...style}
              value={t("Next")}
            />
          ) : (
            <Button
              variant="primary"
              px="1rem"
              onClick={addProject}
              value={t("Create new project")}
            />
          )}
        </Flex>
      </Flex>
    </Overlay>
  );
};

// export const NewProjectOld = ({ open, setOpen, spaceId }) => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const user = useSelector((state) => state["account"].user);

//   let members = {};
//   members[user.id] = MEMBER_TYPE.ADMIN;

//   let initialState = {
//     name: "",
//     members: members,
//     lists: [],
//     cost_type_id: null,
//     cost_value: null,
//     power_ups: []
//   };

//   let inititalStepsState = {
//     1: false,
//     2: false,
//     3: false,
//     4: false,
//     5: false,
//     6: false,
//     7: false
//   };

//   const [activeStepId, setActiveStepId] = useState(STEPS.START);
//   const [disabled, setDisabled] = useState({ disabled: "disabled" });
//   const [project, setProject] = useState({ ...initialState });
//   const [validStepsIds, setValidStepsIds] = useState({ ...inititalStepsState });

//   const restart = () => {
//     setActiveStepId(STEPS.START);
//     setValidStepsIds({ ...inititalStepsState });
//     setProject({ ...initialState });
//   };

//   const closeProject = () => {
//     restart();
//     setOpen(false);
//   };

//   useEffect(() => {
//     if (project.name.length > 2) {
//       validStepsIds[STEPS.START] = true;
//       // validStepsIds[STEPS.CONFIG] = true;
//       validStepsIds[STEPS.MEMBERS] = true;
//     } else {
//       validStepsIds[STEPS.START] = false;
//       // validStepsIds[STEPS.CONFIG] = false;
//       validStepsIds[STEPS.MEMBERS] = false;
//       validStepsIds[STEPS.LISTS] = false;
//     }

//     if (Object.keys(project.lists).length > 0) {
//       validStepsIds[STEPS.LISTS] = true;
//       // validStepsIds[STEPS.POWERUPS] = true;
//       validStepsIds[STEPS.REVIEW] = true;
//     } else {
//       validStepsIds[STEPS.LISTS] = false;
//       // validStepsIds[STEPS.POWERUPS] = false;
//       validStepsIds[STEPS.REVIEW] = false;
//     }

//     // if (
//     //   (validStepsIds[STEPS.POWERUPS] && !project.power_ups.includes(4)) ||
//     //   (validStepsIds[STEPS.POWERUPS] &&
//     //     project.power_ups.includes(4) &&
//     //     validStepsIds[STEPS.COSTS])
//     // ) {
//     //   validStepsIds[STEPS.REVIEW] = true;
//     // }
//     // else {
//     //   if (project.cost_value) {
//     //     validStepsIds[STEPS.COSTS] = true;
//     //     validStepsIds[STEPS.REVIEW] = true;
//     //   } else {
//     //     validStepsIds[STEPS.COSTS] = false;
//     //     validStepsIds[STEPS.REVIEW] = false;
//     //   }
//     // }

//     setValidStepsIds(validStepsIds);

//     if (!validStepsIds[activeStepId]) {
//       setDisabled({ disabled: "disabled" });
//     } else {
//       setDisabled({});
//     }
//   }, [activeStepId, project]);
//   // const steps = [];

//   // 2: {
//   //   id: STEPS.CONFIG,
//   //   title: "Config",
//   //   description: "",
//   //   view: Config,
//   //   image: "/images/logo/elefante.svg",
//   //   enabled: false
//   // }, ,
//   // 5: {
//   //   id: STEPS.POWERUPS,
//   //   title: "Powerups",
//   //   description: "",
//   //   view: Powerups,
//   //   image: "/images/logo/elefante.svg",
//   //   enabled: false
//   // },
//   // 6: {
//   //   id: STEPS.COSTS,
//   //   title: "Costs",
//   //   description: "",
//   //   view: Costs,
//   //   image: "/images/logo/elefante.svg",
//   //   enabled: false
//   // }

//   const steps = {
//     0: {
//       id: STEPS.START,
//       title: t("Start"),
//       description: t("{{userFirstName}}, give a name to your project", {
//         userFirstName: user.firstName
//       }),
//       view: Start,
//       image: "/images/new/project/start.svg"
//     },
//     1: {
//       id: STEPS.MEMBERS,
//       title: t("Members"),
//       description: t("add great members..."),
//       view: Members,
//       image: "/images/new/project/members.svg"
//     },
//     2: {
//       id: STEPS.LISTS,
//       title: t("Lists"),
//       description: t("create some tasks lists..."),
//       view: Lists,
//       image: "/images/new/project/lists.svg"
//     },
//     3: {
//       id: STEPS.REVIEW,
//       title: t("Review"),
//       description: t("review your master creation!"),
//       view: Review,
//       image: "/images/new/project/review.svg"
//     }
//   };

//   let View = steps[activeStepId].view;

//   const navigateToStep = (stepId) => {
//     if (!validStepsIds[stepId]) return;
//     setActiveStepId(stepId);
//   };

//   const addProject = () => {
//     dispatch(
//       ACTIONS.fetch.post(
//         "tasks",
//         `${API_ROOT}/project`,
//         ACTIONS.tasks.addProject,
//         { ...project, space_id: spaceId }
//       )
//     );
//     setOpen(false);
//     restart();
//   };

//   const nextStep = () => {
//     if (activeStepId === STEPS.POWERUPS && !project.power_ups.includes(4)) {
//       setActiveStepId(STEPS.REVIEW);
//     } else if (activeStepId === STEPS.REVIEW) {
//       addProject();
//     } else setActiveStepId(activeStepId + 1);
//   };

//   const previousStep = () => {
//     if (activeStepId === STEPS.START) return;
//     setActiveStepId(activeStepId - 1);
//     // if (activeStepId === STEPS.REVIEW && !project.power_ups.includes(4)) {
//     //   setActiveStepId(STEPS.POWERUPS);
//     // } else {
//     //   setActiveStepId(activeStepId - 1);
//     // }
//   };

//   const getStyle = (stepId) => {
//     let style = {
//       opacity: validStepsIds[stepId] || stepId === activeStepId ? 1 : 0.3,
//       border:
//         activeStepId === stepId ? "1px solid blue" : "1px solid transparent"
//     };

//     if (validStepsIds[stepId]) style.cursor = "pointer";

//     return style;
//   };

//   let style;

//   const [name, setName] = useState();
//   return (
//     <Overlay open={open} setOpen={closeProject} spaceId={spaceId}>
//       <Flex
//         m="auto"
//         p="1rem"
//         borderRadius="0.5rem"
//         width="40rem"
//         minWidth="20rem"
//         minHeight="20rem"
//         flexDirection="column"
//         justifyContent="space-between"
//         bg="l0"
//         boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
//         overflow="visible"
//       >
//         <Text textStyle="subtitle">{t("Add new project")}</Text>

//         <Box my="3rem" justifyContent="center" alignItems="center">
//           <Input
//             minWidth={400}
//             maxWidth={600}
//             placeholder={t("Project name")}
//             value={project.name || ""}
//             onChange={setName}
//             onEnter={nextStep}
//             focus={true}
//             clearValueAfterEnter={false}
//           />
//         </Box>

//         <Flex justifyContent="flex-end">
//           <Button variant="secondary" onClick={() => {}} mr="1rem">
//             {t("No, take me back")}
//           </Button>

//           <Button variant="primary" px="1rem" onClick={() => {}} {...style}>
//             {t("Yes, delete")}
//           </Button>
//         </Flex>
//       </Flex>
//     </Overlay>
//   );

//   return (
//     <Overlay open={open} setOpen={closeProject} spaceId={spaceId}>
//       <Flex
//         minWidth={800}
//         maxWidth={800}
//         minHeight={450}
//         maxHeight={650}
//         borderRadius={4}
//         bg="l0"
//         p={4}
//         flexDirection="column"
//       >
//         <Flex justifyContent="space-around" mt={4}>
//           {Object.keys(steps).map((stepId, index) => {
//             if (
//               parseInt(stepId) === STEPS.COSTS &&
//               !project.power_ups.includes(4)
//             )
//               return null;

//             return (
//               <Flex
//                 key={index}
//                 flexDirection="column"
//                 alignItems="center"
//                 mb={4}
//                 mx={4}
//                 onClick={() => navigateToStep(parseInt(stepId))}
//                 bg="l1"
//                 style={getStyle(parseInt(stepId))}
//                 borderRadius={8}
//                 width={70}
//                 height={70}
//               >
//                 <Image
//                   width={40}
//                   maxHeight={30}
//                   my="auto"
//                   src={steps[stepId].image}
//                 />
//                 <Text
//                   fontSize={12}
//                   mb={2}
//                   mt="auto"
//                   color="t4"
//                   fontWeight="bold"
//                 >
//                   {t(steps[stepId].title)}
//                 </Text>
//               </Flex>
//             );
//           })}
//         </Flex>

//         <Flex flexGrow="1" width={1}>
//           <Flex
//             flexGrow="1"
//             alignItems="center"
//             justifyContent="center"
//             width={1}
//             flexDirection="column"
//           >
//             <View
//               step={steps[activeStepId]}
//               activeStep={activeStepId}
//               setActiveStepId={setActiveStepId}
//               project={project}
//               setProject={setProject}
//               validStepsIds={validStepsIds}
//             />
//           </Flex>
//         </Flex>

//         <Flex mb={0} mt={4} width={1}>
//           <Button variant="secondary" onClick={closeProject} ml={0} mr="auto">
//             {t("Exit")}
//           </Button>

//           {activeStepId !== STEPS.START && (
//             <Button variant="secondary" onClick={previousStep} mr={2}>
//               {t("Previous")}
//             </Button>
//           )}

//           <Button variant="primary" {...disabled} onClick={nextStep}>
//             {activeStepId === STEPS.REVIEW ? t("Create project") : t("Next")}
//           </Button>
//         </Flex>
//       </Flex>
//     </Overlay>
//   );
// };

// const Members = ({ project, setProject, step }) => {
//   const { t } = useTranslation();
//   const adminId = useSelector((state) => state["account"].user.id);
//   const filters = useSelector((state) => state["tasks"].filters);
//   const spaces = useSelector((state) => state["spaces"].spaces);
//   const users = useSelector((state) => state["spaces"].users);

//   let members = {};
//   let membersList = {};
//   let user;

//   if (filters.spaceId) {
//     let spaceMembers = spaces[filters.spaceId].members;
//     spaceMembers.forEach((index) => {
//       user = users[index.id];
//       if (!user.is_deleted && index.id !== adminId) {
//         members[index.id] = user.name;
//         membersList[index.id] = user;
//       }
//     });
//   } else {
//     for (let [id, user] of Object.entries(users)) {
//       if (!user.is_deleted && id !== adminId) members[id] = user.name;
//     }
//     membersList = users;
//   }

//   const addUser = (userId) => {
//     if (project.members[userId]) {
//       delete project.members[userId];
//     } else {
//       project.members[userId] = MEMBER_TYPE.USER;
//     }
//     setProject({ ...project });
//   };

//   const changeRole = (userId, roleId) => {
//     project.members[userId] = roleId;
//     setProject({ ...project });
//   };
//   // console.log(membersList);
//   // console.log(project);
//   // console.log(project.members);
//   // if (project.members[userId]) return null;
//   return (
//     <>
//       <Text color="t2" textStyle="subtitle" mx={40}>
//         {t(step.description)}
//       </Text>

//       <Flex width={1} height="100%" justifyContent="space-between" mt={4}>
//         <Flex ml={4} flexDirection="column" width={0.45}>
//           <Text fontSize={16} fontWeight="bold" color="t3" mb={2}>
//             {t("Users")}
//           </Text>
//           <Text fontSize={12} color="t2" mb={6}>
//             {t("Click to add user to your new project")}
//           </Text>

//           <Flex flexWrap="wrap" overflow="auto">
//             {Object.keys(membersList).map((userId) => {
//               console.log(userId);
//               return (
//                 <Flex
//                   key={userId}
//                   alignItems="center"
//                   py={1}
//                   bg="l2"
//                   borderRadius={4}
//                   mr={2}
//                   mb={2}
//                   onClick={() => addUser(userId)}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <Image
//                     ml={2}
//                     mr={1}
//                     minWidth={12}
//                     maxWidth={12}
//                     minHeight={12}
//                     maxHeight={12}
//                     src={users[userId].avatar}
//                   />
//                   <Text fontSize={12} mr={2}>
//                     {users[userId].name}
//                   </Text>
//                 </Flex>
//               );
//             })}
//           </Flex>
//         </Flex>

//         <Flex mr={4} flexDirection="column" width={0.5}>
//           <Text fontSize={16} fontWeight="bold" color="t3" mb={2}>
//             {t("Members")}
//           </Text>

//           <Text fontSize={12} color="t2" mb={6}>
//             {t("Choose the role of the members of the project")}
//           </Text>

//           {Object.keys(project.members).map((userId) => {
//             return (
//               <Flex
//                 key={userId}
//                 alignItems="center"
//                 mb={2}
//                 justifyContent="space-between"
//                 overflow="auto"
//               >
//                 <Flex
//                   alignItems="center"
//                   py={1}
//                   bg="l2"
//                   borderRadius={4}
//                   mr={2}
//                 >
//                   <Image
//                     ml={2}
//                     mr={1}
//                     minWidth={12}
//                     maxWidth={12}
//                     minHeight={12}
//                     maxHeight={12}
//                     src={users[userId].avatar}
//                   />
//                   <Text fontSize={12} mr={2}>
//                     {users[userId].name}
//                   </Text>

//                   {adminId !== parseInt(userId) && (
//                     <Icon.Close
//                       width={12}
//                       height={12}
//                       style={{ cursor: "pointer" }}
//                       mr={1}
//                       onClick={() => addUser(userId)}
//                     />
//                   )}
//                 </Flex>

//                 <Flex alignItems="center">
//                   <Text
//                     fontSize={12}
//                     py={1}
//                     px={2}
//                     borderRadius={4}
//                     mr={2}
//                     style={{ cursor: "pointer" }}
//                     bg={
//                       project.members[userId] === MEMBER_TYPE.ADMIN
//                         ? "blue"
//                         : "l2"
//                     }
//                     color={
//                       project.members[userId] === MEMBER_TYPE.ADMIN
//                         ? "white"
//                         : "t2"
//                     }
//                     onClick={() => changeRole(userId, MEMBER_TYPE.ADMIN)}
//                   >
//                     {MEMBER_DESCRIPTION[MEMBER_TYPE.ADMIN]}
//                   </Text>

//                   <Text
//                     fontSize={12}
//                     py={1}
//                     px={2}
//                     borderRadius={4}
//                     mr={2}
//                     style={{ cursor: "pointer" }}
//                     bg={
//                       project.members[userId] === MEMBER_TYPE.USER
//                         ? "blue"
//                         : "l2"
//                     }
//                     color={
//                       project.members[userId] === MEMBER_TYPE.USER
//                         ? "white"
//                         : "t2"
//                     }
//                     onClick={() => changeRole(userId, MEMBER_TYPE.USER)}
//                   >
//                     {MEMBER_DESCRIPTION[MEMBER_TYPE.USER]}
//                   </Text>

//                   <Text
//                     fontSize={12}
//                     py={1}
//                     px={2}
//                     borderRadius={4}
//                     mr={2}
//                     style={{ cursor: "pointer" }}
//                     bg={
//                       project.members[userId] === MEMBER_TYPE.GUEST
//                         ? "blue"
//                         : "l2"
//                     }
//                     color={
//                       project.members[userId] === MEMBER_TYPE.GUEST
//                         ? "white"
//                         : "t2"
//                     }
//                     onClick={() => changeRole(userId, MEMBER_TYPE.GUEST)}
//                   >
//                     {MEMBER_DESCRIPTION[MEMBER_TYPE.GUEST]}
//                   </Text>
//                 </Flex>
//               </Flex>
//             );
//           })}
//         </Flex>
//       </Flex>
//     </>
//   );
// };

// const powerUps = {
//   1: {
//     title: t("Time tracking"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   2: {
//     title: t("Automations"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   3: {
//     title: t("Presence detection"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   4: {
//     title: t("Cost tracking"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   5: {
//     title: t("Files upload"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   6: {
//     title: t("Comments"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   7: {
//     title: t("Priorities"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   8: {
//     title: t("Task status"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   },
//   9: {
//     title: t("Boards"),
//     description: "",
//     image: "/images/login/reauth.svg"
//   }
// };

// const SelectBox = ({ option, active, onSelect }) => {
//   const { t } = useTranslation();
//   return (
//     <Flex
//       flexDirection="column"
//       width={180}
//       borderRadius={8}
//       style={{ cursor: "pointer" }}
//       hoverColor="l1"
//       onClick={onSelect}
//       border={active ? "1px solid blue" : "1px solid lightGrey"}
//     >
//       <Flex m={4} mt="auto" flexDirection="column">
//         <Image
//           maxWidth={60}
//           maxHeight={60}
//           height={60}
//           my={4}
//           src={option.image}
//         />

//         <Flex mb={0} mt="auto" flexDirection="column">
//           <Text fontSize={16} fontWeight="bold" color="t3" mb={2}>
//             {t(option.title)}
//           </Text>
//           <Text fontSize={12} color="t2">
//             {t(option.description)}
//           </Text>
//         </Flex>
//       </Flex>
//     </Flex>
//   );
// };

// const Start = ({
//   step,
//   project,
//   setActiveStepId,
//   setProject,
//   validStepsIds
// }) => {
//   const { t } = useTranslation();
//   const setName = (name) => {
//     setProject({ ...project, name });
//   };

//   const nextStep = () => {
//     if (validStepsIds[STEPS.START]) setActiveStepId(STEPS.MEMBERS);
//   };

//   return (
//     <Flex flexDirection="column" width={1} alignItems="center">
//       <Text mb={24} color="t2" textStyle="subtitle">
//         {t(step.description)}
//       </Text>

//       <Flex alignItems="flex-end">
//         <Input
//           minWidth={400}
//           maxWidth={400}
//           placeholder={t("Project name...")}
//           value={project.name || ""}
//           onChange={setName}
//           onEnter={nextStep}
//           focus={true}
//           clearValueAfterEnter={false}
//         />
//         <Text mb={4} mt={1} color="t4" fontSize="10px">
//           <b>{t("Return")}</b> {t("to add")}
//         </Text>
//       </Flex>
//     </Flex>
//   );
// };

// const Config = ({ setActiveStepId }) => {
//   const { t } = useTranslation();
//   let options = [
//     {
//       title: t("From scratch"),
//       description: t(
//         "Choose this option if you want to configure everything from scratch"
//       ),
//       image: "/images/logo/elefante.svg"
//     },
//     {
//       title: t("From template"),
//       description: t(
//         "Choose this option if you want to use a pre-defined template"
//       ),
//       image: "/images/logo/elefante.svg"
//     }
//   ];

//   const setTemplate = (typeId) => {
//     if (typeId === 0) setActiveStepId(STEPS.MEMBERS);
//   };

//   return (
//     <Flex width={0.7} height="100%" alignItems="center">
//       <Flex width={1} justifyContent="space-between">
//         {options.map((option, index) => (
//           <SelectBox
//             key={index}
//             option={option}
//             onSelect={() => setTemplate(index)}
//           />
//         ))}
//       </Flex>
//     </Flex>
//   );
// };

// const Lists = ({ step, project, setProject }) => {
//   const { t } = useTranslation();
//   const addList = (name) => {
//     if (!name) return;
//     let capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
//     if (project.lists.includes(capitalizedName)) return;
//     project.lists.unshift(capitalizedName);
//     setProject({ ...project });
//   };

//   const removeFromList = (index) => {
//     project.lists.splice(index, 1);
//     setProject({ ...project });
//   };

//   return (
//     <>
//       <Text mb={24} color="t2" textStyle="subtitle">
//         {t(step.description)}
//       </Text>

//       <Flex flexDirection="column" alignItems="flex-end">
//         <Input
//           maxWidth={400}
//           width={400}
//           placeholder={t("List name...")}
//           rightIcon="ReturnLeft"
//           value={""}
//           onEnter={addList}
//           focus={true}
//           clearValueAfterEnter={true}
//         />
//         <Text mb={4} mt={1} color="t4" fontSize="10px">
//           <b>{t("Return")}</b> {t("to add")}
//         </Text>
//       </Flex>

//       <Flex
//         alignItems="flex-start"
//         flexWrap="wrap"
//         overflow="auto"
//         width={500}
//         maxHeight={100}
//       >
//         {project.lists.map((name, index) => (
//           <Flex
//             key={index}
//             alignItems="center"
//             py={1}
//             bg="l2"
//             borderRadius={4}
//             mb={1}
//             mr={2}
//           >
//             <Text fontSize={14} mx={2}>
//               {name}
//             </Text>

//             <Icon.Close
//               width={12}
//               height={12}
//               style={{ cursor: "pointer" }}
//               mr={1}
//               onClick={() => removeFromList(index)}
//             />
//           </Flex>
//         ))}
//       </Flex>
//     </>
//   );
// };

// const Powerups = ({ project, setProject, step }) => {
//   const { t } = useTranslation();
//   const addPowerUp = (powerUpId) => {
//     if (project.power_ups.includes(powerUpId)) {
//       let index = project.power_ups.indexOf(powerUpId);
//       project.power_ups.splice(index, 1);

//       if (powerUpId === 1) {
//         index = project.power_ups.indexOf(4);
//         project.power_ups.splice(index, 1);
//       }

//       if (powerUpId === 4) {
//         project.cost_value = 0;
//         project.cost_type_id = 0;
//       }

//       if (powerUpId === 8) {
//         index = project.power_ups.indexOf(9);
//         project.power_ups.splice(index, 1);
//       }

//       setProject({ ...project });
//     } else {
//       project.power_ups.push(powerUpId);

//       if (powerUpId === 4 && !project.power_ups.includes(1))
//         project.power_ups.push(1);

//       if (powerUpId === 4) {
//         project.cost_type_id = 1;
//         project.cost_value = null;
//       }

//       if (powerUpId === 9 && !project.power_ups.includes(8))
//         project.power_ups.push(8);

//       setProject({ ...project });
//     }
//   };

//   return (
//     <Flex width={1} flexDirection="column" alignItems="center">
//       <Image maxWidth={60} maxHeight={60} height={60} mb={4} src={step.image} />

//       <Flex
//         flexWrap="wrap"
//         overflow="auto"
//         justifyContent="space-between"
//         maxHeight={200}
//         alignItems="center"
//         mx={8}
//       >
//         {Object.keys(powerUps).map((powerUpId) => {
//           let id = parseInt(powerUpId);
//           return (
//             <Flex
//               key={powerUpId}
//               mx={1}
//               width={180}
//               height={40}
//               borderRadius={4}
//               onClick={() => addPowerUp(id)}
//               style={{ cursor: "pointer" }}
//               border={
//                 project.power_ups.includes(id)
//                   ? "1px solid blue"
//                   : "1px solid lightGrey"
//               }
//               mb={2}
//             >
//               <Flex mx={1} alignItems="center">
//                 <Image
//                   maxWidth={30}
//                   maxHeight={30}
//                   height={30}
//                   src={powerUps[id].image}
//                   mr={2}
//                 />
//                 <Text color="t2">{t(powerUps[id].title)}</Text>
//               </Flex>
//             </Flex>
//           );
//         })}
//       </Flex>
//     </Flex>
//   );
// };

// const Costs = ({ setActiveStepId, project, setProject }) => {
//   const { t } = useTranslation();
//   const setCostTypeId = (costTypeId) => {
//     // let cost = { type_id: costTypeId, value: project.cost.value };
//     setProject({ ...project, cost_type_id: costTypeId });
//   };

//   const setCost = (value) => {
//     // let cost = { type_id: project.cost_type_id, value };
//     setProject({ ...project, cost_value: value });
//   };

//   const nextStep = () => {
//     setActiveStepId(STEPS.REVIEW);
//   };

//   let costOptions = [
//     {
//       id: 1,
//       title: t("Total per project"),
//       description: t("The project have a fixed cost for completing it"),
//       image: "/images/logo/elefante.svg"
//     },
//     {
//       id: 2,
//       title: t("Per working hour"),
//       description: t("The cost will be calculated based on worked hours"),
//       image: "/images/logo/elefante.svg"
//     }
//   ];

//   return (
//     <Flex width={0.7} height="100%" flexDirection="column">
//       <Flex
//         width={1}
//         justifyContent="space-between"
//         alignItems="center"
//         height="100%"
//       >
//         {costOptions.map((option, index) => (
//           <SelectBox
//             key={index}
//             option={option}
//             active={project.cost_type_id === option.id}
//             onSelect={() => setCostTypeId(option.id)}
//           />
//         ))}
//       </Flex>

//       <Flex mt={1} alignItems="center">
//         <Text mr={4}>
//           {project.cost_type_id === 1
//             ? t("Project cost:")
//             : t("Cost per hour:")}
//         </Text>

//         <Input
//           minWidth={200}
//           maxWidth={200}
//           placeholder={t("Value...")}
//           rightIcon="ReturnLeft"
//           value={project.cost_value}
//           onChange={setCost}
//           onEnter={nextStep}
//           focus={true}
//           clearValueAfterEnter={false}
//           inputType="float"
//         />
//       </Flex>
//     </Flex>
//   );
// };

// const Review = ({ project, step }) => {
//   const { t } = useTranslation();
//   const users = useSelector((state) => state["spaces"].users);

//   return (
//     <>
//       <Text mb={24} color="t2" textStyle="subtitle">
//         {t(step.description)}
//       </Text>

//       <Flex
//         flexDirection="column"
//         width={0.8}
//         alignItems="flex-start"
//         justifyContent="center"
//       >
//         <Text mb={1}>{t("Project name")}:</Text>
//         <Box alignItems="center" py={1} px={2} bg="l2" borderRadius={4}>
//           <Text fontSize={12}>{project.name}</Text>
//         </Box>
//         <Text mt={4} mb={1}>
//           {t("Members")}:
//         </Text>
//         <Flex
//           alignItems="flex-start"
//           flexWrap="wrap"
//           overflow="auto"
//           width={1}
//           maxHeight={100}
//         >
//           {Object.keys(project.members).map((userId) => {
//             return (
//               <Flex
//                 key={userId}
//                 alignItems="center"
//                 mb={2}
//                 justifyContent="space-between"
//               >
//                 <Flex
//                   alignItems="center"
//                   py={1}
//                   bg="l2"
//                   borderRadius={4}
//                   mr={2}
//                 >
//                   <Image
//                     ml={2}
//                     mr={1}
//                     minWidth={12}
//                     maxWidth={12}
//                     minHeight={12}
//                     maxHeight={12}
//                     src={users[userId].avatar}
//                   />
//                   <Text fontSize={12} mr={2}>
//                     {users[userId].name}
//                   </Text>
//                 </Flex>
//               </Flex>
//             );
//           })}
//         </Flex>
//         <Text mt={2} mb={1}>
//           {t("Lists")}:
//         </Text>
//         <Flex
//           alignItems="flex-start"
//           flexWrap="wrap"
//           overflow="auto"
//           width={1}
//           maxHeight={100}
//         >
//           {project.lists.map((name, index) => (
//             <Flex
//               key={index}
//               alignItems="center"
//               py={1}
//               bg="l2"
//               borderRadius={4}
//               mb={1}
//               mr={2}
//             >
//               <Text fontSize={12} mx={2}>
//                 {name}
//               </Text>
//             </Flex>
//           ))}
//         </Flex>
//         {project.power_ups.length !== 0 && (
//           <>
//             <Text mt={2} mb={1}>
//               {t("Powerups")}:
//             </Text>
//             <Flex
//               alignItems="flex-start"
//               flexWrap="wrap"
//               overflow="auto"
//               width={1}
//               maxHeight={100}
//             >
//               {project.power_ups.map((powerUpId) => {
//                 return (
//                   <Flex
//                     key={powerUpId}
//                     alignItems="center"
//                     py={1}
//                     bg="l2"
//                     borderRadius={4}
//                     mr={2}
//                     mb={1}
//                   >
//                     <Flex mx={1} alignItems="center">
//                       <Image
//                         ml={2}
//                         mr={1}
//                         minWidth={12}
//                         maxWidth={12}
//                         minHeight={12}
//                         maxHeight={12}
//                         src={powerUps[powerUpId].image}
//                       />
//                       <Text fontSize={12} mr={2}>
//                         {t(powerUps[powerUpId].title)}
//                       </Text>
//                     </Flex>
//                   </Flex>
//                 );
//               })}
//             </Flex>
//           </>
//         )}
//         {project.power_ups.includes(4) && (
//           <>
//             <Text mt={2} mb={1}>
//               {t("Cost")}{" "}
//               {project.cost_type_id === 1 ? t("per project") : t("per hour")}
//             </Text>
//             <Box alignItems="center" py={1} px={2} bg="l2" borderRadius={4}>
//               <Text fontSize={12}>{project.cost_value}</Text>
//             </Box>
//           </>
//         )}
//       </Flex>
//     </>
//   );
// };
