import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Icon, RotatingBox, Text, Overlay } from "tomato";

import { ACTIONS } from "store";
import { RESOURCES } from "constant";

const TopAlerts = () => {
  const { t } = useTranslation();

  let content;
  const needsReload = useSelector((state) => state["app"].needsReload);

  const reload = () => window.location.reload(true);

  if (needsReload)
    content = (
      <Flex
        flexDirection="column"
        bg="#E7E5FF"
        width={1}
        alignItems="center"
        justifyContent="center"
        onClick={reload}
        borderBottom="1px solid lightGrey"
      >
        <Flex mt="0.5rem" color="#4500CC">
          <Icon.Info />
          <Text ml="0.5rem" style={{ fontSize: "1rem", cursor: "pointer" }}>
            {t("There is a new version of Elefante. ")}
          </Text>
          <Text
            style={{
              marginLeft: "0.5rem",
              fontWeight: "semibold",
              cursor: "pointer",
              fontSize: "1rem",
              textDecoration: "underline"
            }}
          >
            {t("Click here to reload")}
          </Text>{" "}
        </Flex>

        {needsReload.message && (
          <Text
            style={{
              margin: "0.5rem 0",
              fontSize: "1rem",
              fontWeight: "semibold"
            }}
          >
            {needsReload.message}
          </Text>
        )}
      </Flex>
    );

  return (
    <>
      {content && (
        <Flex width={1} alignItems="center" justifyContent="center">
          {content}
        </Flex>
      )}
    </>
  );
};

const ModalAlert = () => {
  const { t } = useTranslation();

  const newAlert = useSelector((state) => state["account"].newAlert);

  return (
    <Overlay open={true} height="95%" mr="1rem" ml="auto">
      <Flex
        borderRadius="1rem"
        flexDirection="column"
        flexGrow="1"
        bg="l0"
        width="25rem"
        p="1rem"
      >
        <Text fontSize="1rem" fontWeight="semibold">
          {t("Last updates")}
        </Text>
      </Flex>
    </Overlay>
  );
};

const RefreshAlert = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const shouldRefresh = useSelector((state) => state["app"].shouldRefresh);
  const loadedResources = useSelector((state) => state["app"].loadedResources);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!shouldRefresh) return;
    let refreshed = RESOURCES.every((resource) =>
      loadedResources.includes(resource)
    );

    setRefreshing(!refreshed);

    if (refreshed)
      dispatch(ACTIONS.state.setAttribute("app", "shouldRefresh", false));
  }, [dispatch, shouldRefresh, loadedResources]);

  let completed = loadedResources.length / RESOURCES.length;

  return (
    <Overlay open={refreshing} height="100%" mt="2rem">
      <Flex
        flexDirection="column"
        bg="lightGreen"
        alignItems="center"
        justifyContent="center"
        height="fit-content"
        py="1rem"
        px="1.5rem"
        borderRadius="0.5rem"
      >
        <Flex>
          <RotatingBox>
            <Icon.Refresh />
          </RotatingBox>
          <Text fontSize="1rem" ml="0.5rem" style={{ cursor: "pointer" }}>
            {t("Updating all data.")}
          </Text>
        </Flex>

        <Flex bg="l3" mt="1rem" width={1} height="0.5rem" borderRadius="2rem">
          <Flex
            width={completed}
            height="0.5rem"
            bg="green"
            borderRadius="2rem"
          />
        </Flex>
      </Flex>
    </Overlay>
  );
};

export const GlobalAlerts = () => {
  return (
    <>
      <TopAlerts />
      {false && <ModalAlert />}
      <RefreshAlert />
    </>
  );
};
