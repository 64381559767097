import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Confirm,
  Dropdown,
  Emojis,
  Flex,
  Icon,
  Image,
  Input,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { t } from "i18n";

const clearValues = {
  1: t("Don't clear"),
  2: "30 " + t("minutes"),
  3: "1 " + t("hour"),
  4: "4 " + t("hours"),
  5: t("Today"),
  6: t("This week")
};

const clearOptions = [
  {
    id: 1,
    value: t("Don't clear")
  },
  {
    id: 2,
    value: "30 " + t("minutes")
  },
  {
    id: 3,
    value: "1 " + t("hour")
  },
  {
    id: 4,
    value: "4 " + t("hours")
  },
  {
    id: 5,
    value: t("Today")
  },
  {
    id: 6,
    value: t("This week")
  }
];

const statusOptions = [
  { emojiCode: "2600", value: t("In a call"), clearId: 1 },
  { emojiCode: "2603", value: t("In a meeting"), clearId: 1 },
  { emojiCode: "1f37d", value: t("Lunch"), clearId: 1 }
];

export const Status = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);
  const preferences = useSelector((state) => state["account"].preferences);
  const ws = useSelector((state) => state["ws"].ws);

  const [instance, setInstance] = useState(null);
  const [emojiCode, setEmoji] = useState(preferences.status.emojiCode);
  const [message, setMessage] = useState(preferences.status.message);
  const [clearId, setClear] = useState(preferences.status.clearId);

  useEffect(() => {
    // dispatch(ACTIONS.events.send("Status view", {}));
  }, [dispatch]);

  const selectClear = (option) => {
    setMessage(option.value);
    setClear(option.clearId);
    setEmoji(option.emojiCode);
  };

  const save = () => {
    let status = {
      message,
      clearId,
      emojiCode,
      expireDate: new Date()
    };
    ws.send(
      JSON.stringify({
        type: "spaces",
        action: "setStatus",
        data: {
          status
        }
      })
    );

    dispatch(ACTIONS.account.setPreference("status", status));
    ACTIONS.events.send("Changed status", {
      status,
      userId: user.id
    });

    dispatch(
      ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
        preferences: { ...user.preferences, status }
      })
    );
    setOpen(false);
  };

  return (
    <Confirm
      open={open}
      setOpen={setOpen}
      width="35rem"
      height="fit-content"
      maxHeight="40rem"
      title={t("Set a status")}
      yesText={t("Save status")}
      noText={t("Cancel")}
      onConfirm={save}
      onEnter={() => {
        message && clearId && save();
        setOpen(false);
      }}
      onEsc={() => {
        setOpen(false);
      }}
    >
      <Flex alignItems="center" mb="1rem">
        <Tippy
          trigger="click"
          placement="left"
          interactive={true}
          offset={[0, 4]}
          render={(attrs) => (
            <>
              {instance ? (
                <Emojis
                  attrs={attrs}
                  onSelect={(emojiCode) => {
                    console.log(emojiCode);
                    setEmoji(emojiCode);
                    instance.hide();
                  }}
                />
              ) : (
                ""
              )}
            </>
          )}
          onCreate={setInstance}
        >
          <Flex style={{ cursor: "pointer" }}>
            {emojiCode ? (
              <Image
                width="2rem"
                src={`https://static.elefante.com/emojis/${emojiCode}.png`}
              />
            ) : (
              <Icon.Smile />
            )}
          </Flex>
        </Tippy>

        <Input
          ml="1rem"
          placeholder={t("What's your status")}
          onChange={setMessage}
          value={message}
          focus={true}
          enableClear={message}
          onClear={() => setMessage("")}
          onEsc={() => {
            setOpen(false);
          }}
        />
      </Flex>

      {(emojiCode || message) && (
        <Flex
          mb="0.25rem"
          px="1rem"
          py="0.5rem"
          bg="l2"
          borderRadius="0.375rem"
          hoverColor="lightBlue"
          // width="fit-content"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setMessage();
            setEmoji();
          }}
        >
          {t("Remove status")}
        </Flex>
      )}

      {statusOptions.map((option, index) => (
        <Flex
          key={index}
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => selectClear(option)}
          hoverColor="lightBlue"
          px="1rem"
          py="0.5rem"
          borderRadius="0.375rem"
        >
          <Image
            width="2rem"
            src={`https://static.elefante.com/emojis/${option.emojiCode}.png`}
          />
          <Text ml="0.5rem" fontSize="1rem" fontWeight="semibold">
            {option.value}
          </Text>
          {false && (
            <Text ml="0.5rem" fontSize="1rem" color="t3">
              — {clearValues[option.clearId]}
            </Text>
          )}
        </Flex>
      ))}

      {false && (
        <Flex mt="2rem" alignItems="center">
          <Text mr="0.5rem">{t("Clear after")}:</Text>
          <Dropdown
            options={clearOptions}
            selected={{ id: clearId, value: clearValues[clearId] }}
            onSelect={(option) => setClear(option.id)}
          />
        </Flex>
      )}
    </Confirm>
  );
};
