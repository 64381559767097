import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ACTIONS } from "../../store/reducers/actions";
import { Icon } from "../Icon";
import { getColor } from 'tomato'

export const ChangeTheme = ({ onChange,  ...props }) => {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state["account"].preferences.theme);

  const color = getColor("white");

  const getTheme = (theme) => {
    if (theme === "dark") {
      return "light";
    } else {
      return "dark";
    }
  };

  const changeTheme = () => {
    let newTheme = getTheme(theme);
    dispatch(ACTIONS.account.setPreference("theme", newTheme));
    onChange && onChange(newTheme)
  };

  let RenderIcon = theme === "light" ? Icon.Moon : Icon.Sun;

  return (
    <RenderIcon
      style={{ cursor: "pointer", stroke: color }}
      onClick={changeTheme}
      {...props}
    />
  );
};
