import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createEditor, Node } from "slate";
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useFocused
} from "slate-react";
import { withHistory } from "slate-history";

import useDebounce from "../../hooks/useDebounce";
import { UI } from "../../constants";
import { Flex, Text } from "../";

const emptyState = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

export const plainTextSerializer = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export const plainTextDeserialize = (content) => {
  let children = content.split("\n").map((line) => {
    return { type: "paragraph", children: [{ text: line }] };
  });

  return children;
};

const Editor = ({
  disabled,
  handleKeyPress,
  label,
  placeholder,
  required,
  ...props
}) => {
  const { t } = useTranslation();
  const focused = useFocused();

  let style = {
    lineHeight: UI.INPUT_HEIGHT,
    border: focused ? "1px solid blue" : "1px solid lightGrey",
    bg: "l0",
    borderRadius: "0.25rem",
    padding: "0.125rem 0.5rem"
  };

  return (
    <Flex flexDirection="column" flexGrow="1" width={props.width}>
      {label && (
        <Flex
          mb="0.25rem"
          ml="0.25rem"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text textStyle="label">{t(label)}</Text>

          {!disabled && required && (
            <Text textStyle="label" ml="0.125rem">
              {t("(required)")}
            </Text>
          )}
        </Flex>
      )}

      <Flex
        height="fit-content"
        width={1}
        style={style}
        minHeight={props.minHeight || UI.INPUT_HEIGHT}
        {...props}
      >
        <Editable
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          style={{ width: "100%", height: "100%" }}
        />
      </Flex>
    </Flex>
  );
};

export const TextEditor = ({
  clearOnSave,
  debounceTime,
  disabled,
  label,
  onSave,
  placeholder,
  required,
  saveOnEnter,
  value = "",
  ...props
}) => {
  const initialValue = (value) => {
    if (value) return plainTextDeserialize(value);
    return emptyState;
  };

  const [content, setContent] = useState(initialValue(value));
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const debouncedValue = useDebounce(content, debounceTime || 2500);

  const saveData = (content) => {
    const text = plainTextSerializer(content);
    if (text === value) return;
    onSave && onSave(text);
    if (clearOnSave) {
      ReactEditor.blur(editor);
      setContent(emptyState);
    }
  };

  useEffect(() => {
    !saveOnEnter && saveData(debouncedValue);
  }, [debouncedValue]);

  const handleKeyPress = (e) => {
    if (saveOnEnter && e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      saveData(content);
    }
  };

  return (
    <Slate
      editor={editor}
      value={content}
      onChange={(content) => setContent(content)}
    >
      <Editor
        disabled={disabled}
        handleKeyPress={handleKeyPress}
        label={label}
        placeholder={placeholder}
        required={required}
        onBlur={() => !saveOnEnter && saveData(content)}
        {...props}
      />
    </Slate>
  );
};

// import { Input } from "../Input/Input";

// const textInput = variant({ key: "textInput" });

// const TextInput = styled(Input)(textInput);

// TextInput.defaultProps = {
//   as: "textarea",
//   lineHeight: 1.25,
//   paddingTop: "0.5rem",
//   paddingBottom: "0.5rem"
//   // fontWeight: "regular",
//   // lineHeight: "24px"
//   // fontSize: UI.DEFAULT_FONT_SIZE,
//   // fontFamily: UI.DEFAULT_FONT_FAMILY,
//   // boxSizing: "border-box",
//   // resize: "none",
//   // outline: 0
// };

// const TextInputWrapper = ({
//   debounceTime,
//   disabled = false,
//   maxLength = false,
//   maxHeight = false,
//   minHeight = 72,
//   height,
//   value,
//   placeholder,
//   onEscape,
//   onChange,
//   variant,
//   props
// }) => {
//   const { t } = useTranslation();
//   const inputRef = useRef(null);

//   const [inputValue, setInputValue] = useState(value || "");
//   const [maxStyle, setMaxStyle] = useState({});
//   const [descriptionHeight, setDescriptionHeight] = useState(
//     height || minHeight
//   );

//   useEffect(() => {
//     // calcDescriptionHeight();
//   }, [inputValue]);

//   useEffect(() => {
//     if (maxLength && inputValue.length / maxLength >= 1) {
//       setMaxStyle({ color: "red" });
//     } else if (maxLength && inputValue.length / maxLength >= 0.8) {
//       setMaxStyle({ color: "orange" });
//     }
//   }, [maxLength, inputValue]);

//   // O debounce tem que ser listado após o objeto de referência
//   const debouncedValue = useDebounce(inputValue, debounceTime || 0);

//   useEffect(() => {
//     if (typeof onChange === "function" && debouncedValue !== value)
//       onChange(inputValue);
//   }, [debouncedValue]);

//   let keyCode;

//   const changeValue = (e) => {
//     if (disabled) return;

//     if (maxLength && e.target.value.length > maxLength) {
//       return;
//     }

//     setInputValue(e.target.value);
//   };

//   // const calcDescriptionHeight = () => {
//   //   if (height) return;
//   //   let limitHeight = inputRef.current.offsetHeight;
//   //   let scrollHeight = inputRef.current.scrollHeight;
//   //   let newHeight = descriptionHeight;
//   //   if (limitHeight < scrollHeight) newHeight = scrollHeight;
//   //   if (inputValue.length === 0) newHeight = 36;
//   //   if (maxHeight && newHeight > maxHeight) newHeight = maxHeight;
//   //   setDescriptionHeight(newHeight);
//   // };

//   const handleKeyPress = (e) => {
//     e.stopPropagation();
//     keyCode = e.keyCode || e.charCode;
//     if (keyCode === 27) {
//       if (typeof onEscape === "function") onEscape();
//     }
//   };

//   return (
//     <TextInput
//       {...props}
//       ref={inputRef}
//       variant={variant || "default"}
//       value={inputValue}
//       placeholder={t(placeholder) || ""}
//       onChange={(e) => changeValue(e)}
//       onKeyDown={handleKeyPress}
//       height={descriptionHeight}
//       style={maxStyle}
//       // style={{ fontFamily: true ? "OpenSans-Regular" : "OpenSans-Italic" }}
//     />
//   );
// };

// export { TextInputWrapper as TextInput };
