import { Calendar } from "./Calendar";
// import { DueToday } from "./DueToday";
// import Evolution from "./Evolution";
// import InProgress from "./InProgress";
import Time from "./Time";
// import { Overdue } from "./Overdue";
// import { Upcoming } from "./Upcoming";
// import { Workload } from "./Workload";

export const Widgets = {
  Calendar,
  // DueToday,
  // Evolution,
  // InProgress,
  // Overdue,
  Time
  // Upcoming,
  // Workload
};
