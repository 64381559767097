import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Options, Spinner } from "tomato";

import { API_ENDPOINT } from "config";
import { ACTIONS } from "store";
import { Approval } from "./Approval";
import { Custom } from "./Custom";
import Description from "./Description";
import Files from "./Files";
import Followers from "./Followers";
import Guests from "./Guests";
import Header from "./Header";
import Info from "./Info";
import Notes from "./Notes";
import History from "./History";
import { ParentTask } from "./ParentTask";
import { Priority } from "./Priority";
import Recur from "./Recur";
import Time from "./Time";
import Notification from "./Notification";
import { Subtasks } from "./Subtasks";
import { Undelete } from "./Undelete";
import When from "./When";
import Where from "./Where";
import Who from "./Who";

const ScrollingArea = () => {
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const tasks = useSelector((state) => state["tasks"].objs);

  const OPTION = [
    {
      id: "INFO",
      value: t("Info")
    },
    { id: "NOTES", value: t("Notes") },
    { id: "FILES", value: t("Files") },
    { id: "SUBTASKS", value: t("Subtasks") },
    { id: "HISTORY", value: t("History") }
    // { id: "CUSTOM", value: t("Custom") }
  ];

  const [selectedOption, setSelectedOption] = useState({
    id: "INFO",
    value: t("Info")
  });

  const getCount = (option) => {
    let count = 0;

    switch (option.id) {
      case "FILES":
        count = task.files.length;
        break;

      case "NOTES":
        count = Object.keys(task.notes).length;
        break;

      case "HISTORY":
        count = task.history.length;
        break;

      case "SUBTASKS":
        if (tasks[task.id]) count = tasks[task.id].subtasksIds.length;
        break;

      default:
        break;
    }

    return count;
  };

  let options = OPTION.filter((option) => {
    return task.type_id === 1
      ? true
      : ["INFO", "NOTES", "FILES"].includes(option.id);
  }).map((option) => {
    return { ...option, count: getCount(option) };
  });

  return (
    <Flex width={1} height="100%" overflow="auto">
      <Flex
        flexDirection="column"
        flexGrow="1"
        height="100%"
        overflow="auto"
        px="2rem"
      >
        <Options
          width={1}
          mt="1rem"
          mb="2rem"
          selected={selectedOption}
          options={options}
          onSelect={setSelectedOption}
          size="large"
        />

        <Flex flexDirection="column" flexGrow="1" height="100%" overflow="auto">
          {selectedOption.id === "INFO" && (
            <>
              {task.type_id === 1 && (
                <>
                  <Where />
                  <ParentTask />
                  <Priority />
                  <Followers />
                </>
              )}

              <Info />

              {task.type_id === 1 && (
                <>
                  <Who />
                </>
              )}

              <When />
              <Recur />
              <Time />

              {task.type_id === 2 && (
                <>
                  <Guests />
                  {false && <Notification />}
                </>
              )}
              <Description />
            </>
          )}

          {selectedOption.id === "NOTES" && <Notes />}
          {selectedOption.id === "FILES" && <Files />}
          {selectedOption.id === "HISTORY" && <History />}
          {selectedOption.id === "SUBTASKS" && <Subtasks />}
          {selectedOption.id === "CUSTOM" && <Custom />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Task = ({ update }) => {
  const dispatch = useDispatch();

  const loadingObjId = useSelector((state) => state["tasks"].loadingObjId);
  const task = useSelector((state) => state["tasks"].obj);

  const taskIdFromHash = window.location.hash.split("#")[1];

  useEffect(() => {
    return () => {
      dispatch(
        ACTIONS.state.changeState("tasks", (state) => {
          state.obj = null;
          state.loadingObjId = null;
          return state;
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (loadingObjId)
      dispatch(
        ACTIONS.fetch.get(
          "tasks",
          `${API_ENDPOINT}/task/${loadingObjId}`,
          ACTIONS.state.loadObject,
          (state, response) => {
            state.obj = response;
            state.loadingObjId = null;
            return state;
          }
        )
      );
  }, [dispatch, loadingObjId]);

  // let erro = null;

  if (!task) return null;

  // try {
  //   let members =
  //     spaces[projects[lists[task.list_id].project_id].space_id].members;
  // } catch (err) {
  //   erro = err;
  // }

  if (loadingObjId && !task) return <Spinner />;

  // if (spaces && erro) {
  //   return (
  //     <Card
  //       width={1}
  //       height="100%"
  //       p="4rem"
  //       borderBottomLeftRadius={0}
  //       borderBottomRightRadius={0}
  //       flexDirection="column"
  //       justifyContent="center"
  //       alignItems="center"
  //     >
  //       <Text>
  //         Encontrei um erro aqui e não foi possível carregar esta página. Isso
  //         acontece pq provavelmente a tarefa abaixo foi movida de lugar.
  //       </Text>
  //       <Text fontWeight="bold" mt="2rem" fontSize="1.25rem">
  //         [{task.id}] {task.subject}
  //       </Text>

  //       <Flex>
  //         {task.owner_id && (
  //           <Text mr="1rem">Responsável: {users[task.owner_id].name}</Text>
  //         )}
  //         {task.created_by_id && (
  //           <Text>Criada por: {users[task.created_by_id].name}</Text>
  //         )}
  //       </Flex>

  //       <Text fontWeight="bold" mt="2rem">
  //         Por favor peça para a pessoa que moveu retornar a mesma ao local de
  //         origem.
  //       </Text>
  //     </Card>
  //   );
  // }

  return (
    <>
      {task && task.id ? (
        <Flex
          height="100%"
          key={task.id}
          flexDirection="column"
          width={1}
          overflow="auto"
        >
          <Approval update={update} />

          {task.deleted_by_id && <Undelete task={task} />}

          <Header />

          <ScrollingArea />
        </Flex>
      ) : (
        <Spinner size="large" />
      )}
    </>
  );
};
