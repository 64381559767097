import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Box,
  ColorPicker,
  Flex,
  FlexAction,
  Icon,
  Overlay,
  Text
} from "tomato";

const Card = ({
  title,
  content,
  width = "20rem",
  height = "20rem",
  children,
  ...props
}) => {
  return (
    <Flex
      m="auto"
      p="1rem"
      borderRadius="0.5rem"
      width={width}
      minWidth={width}
      minHeight={height}
      overflow="auto"
      flexDirection="column"
      justifyContent="space-between"
      {...props}
      bg="l0"
      boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
    >
      {title && <Text textStyle="subtitle">{title}</Text>}

      <Box my="2rem" width={1}>
        {children}
      </Box>
    </Flex>
  );
};

const colors = [
  { h: 0, s: 0, l: 100 },
  { h: 20, s: 50, l: 50 },
  { h: 40, s: 50, l: 50 },
  { h: 60, s: 50, l: 50 },
  { h: 80, s: 50, l: 50 },
  { h: 80, s: 50, l: 50 },
  { h: 100, s: 50, l: 50 },
  { h: 120, s: 50, l: 50 },
  { h: 140, s: 50, l: 50 },
  { h: 160, s: 50, l: 50 },
  { h: 180, s: 50, l: 50 },
  { h: 200, s: 50, l: 50 },
  { h: 220, s: 50, l: 50 },
  { h: 240, s: 50, l: 50 },
  { h: 260, s: 50, l: 50 },
  { h: 280, s: 50, l: 50 },
  { h: 300, s: 50, l: 50 },
  { h: 320, s: 50, l: 50 },
  { h: 340, s: 50, l: 50 },
  { h: 360, s: 50, l: 50 }
];

const getColor = (color) => {
  if (!color.h) return null;
  return `hsl(${color.h}, ${color.s}%, ${color.l}%)`;
};

const RenderPicker = ({ open, setOpen, color, setColor }) => {
  const selectColor = (option) => {
    setColor(option);
  };

  return (
    <Overlay key="colorpicker" open={open} setOpen={setOpen} opacity="0%">
      <Card width="27rem" height="10rem" title="Set Color">
        <ColorPicker onColorChange={setColor} color={color} pickerSize={160} />

        <Flex flexWrap="wrap" mt="2rem">
          {colors.map((option, index) => {
            let stroke = "transparent";
            if (Object.keys(color).length !== 0) {
              if (
                option.h === color.h &&
                option.l === color.l &&
                option.s === color.s
              ) {
                stroke = "black";
              } else if (option.h === 0 && option.l === 100 && option.s === 0) {
                stroke = "lightGrey";
              }
            }

            return (
              <Icon.Circle
                key={index}
                style={{
                  fill: getColor(option),
                  stroke,
                  strokeWidth: "1px",
                  cursor: "pointer"
                }}
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  selectColor(option);
                }}
                mx={1}
                mb={2}
              />
            );
          })}
        </Flex>
      </Card>
    </Overlay>
  );
};

const _ColorPicker = ({ color, setColor }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  if (!color) color = { h: 240, s: 10, l: 40 };

  let colorOption = `hsl(${color.h}, ${color.s}%, ${color.l}%)`;

  return (
    <Flex position="relative" flexDirection="column">
      <FlexAction
        bg={colorOption}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <Text>{t("Change color")}</Text>
      </FlexAction>

      <RenderPicker
        setOpen={setOpen}
        open={open}
        setColor={setColor}
        color={color}
      />
    </Flex>
  );
};

export { _ColorPicker as ColorPicker };
