import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Button,
  FilesPreview,
  Flex,
  FloatingMenu,
  formatDate,
  Icon,
  Image,
  Input,
  Link,
  Overlay,
  Spinner,
  Text,
  Tippy,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, API_ROOT, ROOT_URL } from "config";
import { DEFAULT_FORM } from "constant";
import { Header, MainWindow } from "components";
import { MODE } from "config";
import { NewFolder } from "./Folders";
import { Upload } from "./Upload";

const getSize = (size) => {
  let finalSize = Math.round(size / 1024);
  if (finalSize < 1024) return `${finalSize}K`;

  finalSize = Math.round(finalSize / 1024);
  if (finalSize < 1024) return `${finalSize}M`;

  finalSize = Math.round(finalSize / 1024);
  return `${finalSize}G`;
};

const ContentType = ({ file, index, setPreviewIndex }) => {
  const dispatch = useDispatch();

  let contentType = file.content_type.split("/");
  let fileUrl = `${ROOT_URL}/shared/${file.filename}`;
  let color = "blue";

  if (contentType[0] === "FORM")
    return (
      <Flex width="1.5rem" mr="0.5rem">
        <Link to={`/files/forms/${file.id}`}>
          <Flex
            style={{ cursor: "pointer" }}
            mr="1rem"
            borderRadius="0.25rem"
            width="1.625rem"
            height="1.625rem"
            bg="green"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="l0" fontSize="0.625rem" fontWeight="bold">
              FOR
            </Text>
          </Flex>
        </Link>
      </Flex>
    );

  if (contentType[0] === "DOCUMENT")
    return (
      <Flex width="1.5rem" mr="0.5rem">
        <Link to={`/files/documents/${file.id}`}>
          <Flex
            style={{ cursor: "pointer" }}
            mr="1rem"
            borderRadius="0.25rem"
            width="1.625rem"
            height="1.625rem"
            bg="green"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="l0" fontSize="0.625rem" fontWeight="bold">
              DOC
            </Text>
          </Flex>
        </Link>
      </Flex>
    );

  if (contentType[0] === "folder")
    return (
      <Flex width="1.5rem" mr="0.5rem">
        <Flex
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(ACTIONS.files.setFilter("folderId", file.id));
          }}
        >
          <Icon.Folder
            width="1.625rem"
            height="1.625rem"
            fill="grey"
            stroke="grey"
            style={{ strokeWidth: "0px" }}
          />
        </Flex>
      </Flex>
    );

  if (contentType[0] === "image") {
    contentType = "image";
  } else if (["pdf", "csv"].includes(contentType[1])) {
    color = "#ff0000";
    contentType = contentType[1];
  } else if (contentType[0] === "text") {
    contentType = "txt";
  } else contentType = file.content_type;

  return (
    <Flex width="1.5rem" mr="0.5rem">
      <Flex style={{ cursor: "pointer" }}>
        {contentType === "image" ? (
          <Image
            onClick={() => setPreviewIndex(index)}
            mr="1rem"
            maxWidth="1.625rem"
            maxHeight="1.625rem"
            src={fileUrl}
          />
        ) : (
          <Flex
            mr="1rem"
            width="1.625rem"
            height="1.625rem"
            borderRadius="0.25rem"
            bg={color}
            alignItems="center"
            justifyContent="center"
          >
            <Text
              color="l0"
              fontSize="0.625rem"
              fontWeight="bold"
              onClick={() => setPreviewIndex(index)}
            >
              {contentType.toUpperCase()}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const NewDocument = ({ open, setOpen, space, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filters = useSelector((state) => state["files"].filters);

  const [name, setName] = useState();

  useEffect(() => {
    setName();
  }, []);

  if (!space) return null;

  let string;
  let content;
  let empty;

  if (type === "FORM") {
    string = t("Form");
    empty = t("Untitled form");
  } else if (type === "DOCUMENT") {
    string = t("Document");
    empty = t("Untitled document");
  } else return;

  const addDocument = () => {
    let uuid = uuidv4();
    let action;

    if (type === "FORM") {
      content = {
        ...DEFAULT_FORM,
        uuid
      };
      uuid = content.uuid;
      action = ACTIONS.forms.addForm;
    } else if (type === "DOCUMENT") {
      content = [
        {
          type: "paragraph",
          children: [{ text: "" }]
        }
      ];
      action = ACTIONS.documents.addDocument;
    } else return;

    dispatch(
      ACTIONS.fetch.post("files", `${API_ROOT}/file/add_document`, action, {
        name: name || empty,
        content: JSON.stringify(content),
        uuid,
        type,
        space_id: filters.spaceId,
        root_id: filters.spaceId,
        folder_id: filters.folderId
      })
    );
    setName("");
    setOpen(false);
  };

  return (
    <Overlay open={open} setOpen={setOpen}>
      <Flex
        m="auto"
        p="1rem"
        borderRadius="0.5rem"
        width="25rem"
        minWidth="20rem"
        minHeight="13rem"
        flexDirection="column"
        justifyContent="space-between"
        bg="l0"
        // boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
        overflow="visible"
      >
        <Text textStyle="subtitle">
          {t("Create") + " " + string + " " + t("on space") + " " + space.name}
        </Text>

        <Input
          label={string + " " + t("name")}
          maxWidth={600}
          placeholder={string + " " + t("name") + "..."}
          onChange={setName}
          focus={true}
        />

        <Flex mr={0} ml="auto">
          <Button
            variant="secondary"
            px="1rem"
            onClick={() => setOpen(false)}
            value={t("Cancel")}
          />

          <Button
            variant="primary"
            px="1rem"
            onClick={addDocument}
            value={t("Create") + " " + string}
          />
        </Flex>
      </Flex>
    </Overlay>
  );
};

const Breadcrumb = () => {
  const dispatch = useDispatch();

  const files = useSelector((state) => state["files"].files);
  const filters = useSelector((state) => state["files"].filters);
  const spaces = useSelector((state) => state["spaces"].spaces);

  let path = [];

  const getPath = (path, folderId) => {
    path.unshift(folderId);
    if (files[folderId].folder_id) {
      return getPath(path, files[folderId].folder_id);
    } else {
      return path;
    }
  };

  if (filters.folderId) path = getPath([], filters.folderId);

  return (
    <Flex alignItems="center">
      <Text
        textStyle="subtitle"
        fontSize="1.125rem"
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(ACTIONS.files.setFilter("folderId", null));
        }}
      >
        {spaces[filters.spaceId].name}
      </Text>

      {path.map((folderId) => (
        <Fragment key={folderId}>
          <Text mx="0.25rem" fontSize="0.875rem">
            {">"}
          </Text>
          <Text
            style={{ cursor: "pointer" }}
            // fontWeight="semibold"
            fontSize="0.875rem"
            onClick={() => {
              dispatch(ACTIONS.files.setFilter("folderId", folderId));
            }}
          >
            {files[folderId].name}
          </Text>
        </Fragment>
      ))}
    </Flex>
  );
};

const ListHeader = ({ open, setOpen, openUpload, setOpenUpload, path }) => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state["files"].filters);

  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);

  // const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);

  return (
    <Header>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width={1}
        minHeight="2rem"
      >
        <Breadcrumb path={path} />

        <Flex>
          {(MODE === "DEV" || user.id === 2) && (
            <>
              <Tippy content={t("Create document")}>
                <Flex
                  ml="auto"
                  mr="1rem"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenDocument(!openDocument)}
                >
                  <Icon.FileText strokeWidth="2px" stroke="black" />
                </Flex>
              </Tippy>

              <Tippy content={t("Add new form")}>
                <Flex
                  mr="1rem"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenForm(!openForm)}
                >
                  <Icon.FileText strokeWidth="2px" stroke="black" />
                </Flex>
              </Tippy>
            </>
          )}

          <Tippy content={t("Create folder")}>
            <Flex
              ml="auto"
              mr="1rem"
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(!open)}
            >
              <Icon.FolderPlus strokeWidth="2px" stroke="black" />
            </Flex>
          </Tippy>

          <Tippy content={t("Add new file")}>
            <Flex
              mr="1rem"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenUpload(!openUpload)}
            >
              <Icon.Upload strokeWidth="2px" stroke="black" />
            </Flex>
          </Tippy>

          <NewDocument
            space={spaces[filters.spaceId]}
            open={openForm}
            setOpen={setOpenForm}
            type="FORM"
          />

          <NewDocument
            space={spaces[filters.spaceId]}
            open={openDocument}
            setOpen={setOpenDocument}
            type="DOCUMENT"
          />

          <NewFolder
            space={spaces[filters.spaceId]}
            open={open}
            setOpen={setOpen}
          />
        </Flex>

        <Upload open={openUpload} setOpen={setOpenUpload} />
      </Flex>
    </Header>
  );
};

export const FilesList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const filters = useSelector((state) => state["files"].filters);
  const id = filters.folderId || filters.spaceId;

  const files = useSelector((state) => state["files"].folders[id]);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [previewIndex, setPreviewIndex] = useState();
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const deleteFile = (fileId) => {
    dispatch(
      ACTIONS.fetch.delete(
        "files",
        `${API_ENDPOINT}/file/${fileId}`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          delete state.files[fileId];
          delete state.folders[filters.folderId || filters.spaceId][fileId];

          return state;
        }
      )
    );
  };

  const onDragEnd = () => {};

  if (!files || !filters.spaceId) return <Spinner />;

  let folders = Object.values(files).filter((file) => {
    if (file.content_type !== "folder") return false;

    if (filters.folderId) {
      return file.folder_id === filters.folderId;
    } else if (filters.spaceId) return file.root_id === filters.spaceId;

    return true;
  });

  let filesList = Object.values(files).filter((file) => {
    if (file.content_type === "folder") return false;

    if (filters.folderId) {
      return file.folder_id === filters.folderId;
    } else if (filters.spaceId) return file.root_id === filters.spaceId;

    return false;
  });

  return (
    <>
      <FilesPreview
        previewIndex={previewIndex}
        files={filesList}
        setPreviewIndex={setPreviewIndex}
        rootUrl={ROOT_URL + "/shared/"}
      />

      <ListHeader
        open={open}
        setOpen={setOpen}
        openUpload={openUpload}
        setOpenUpload={setOpenUpload}
      />

      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Flex
            flexDirection="column"
            p="1.5rem"
            pt="0rem"
            flexGrow="1"
            overflow="auto"
          >
            {folders.length === 0 && filesList.length === 0 && (
              <Flex
                height="100%"
                width={1}
                alignItems="center"
                flexDirection="column"
                pt="5rem"
              >
                <Flex
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(!open)}
                  alignItems="center"
                  hoverColor="lightBlue"
                  p="1rem"
                  borderRadius="0.5rem"
                >
                  <Icon.FolderPlus
                    width="1.5rem"
                    height="1.5rem"
                    strokeWidth="2px"
                    stroke="black"
                  />
                  <Text fontSize="1.25rem" ml="1rem">
                    {t("Create folder")}
                  </Text>
                </Flex>

                <Text my="1rem" fontSize="1rem">
                  {t("or")}
                </Text>

                <Flex
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenUpload(!openUpload)}
                  alignItems="center"
                  hoverColor="lightBlue"
                  p="1rem"
                  borderRadius="0.5rem"
                >
                  <Icon.Upload
                    width="1.5rem"
                    height="1.5rem"
                    strokeWidth="2px"
                    stroke="black"
                  />
                  <Text fontSize="1.25rem" ml="1rem">
                    {t("Add new file")}
                  </Text>
                </Flex>
              </Flex>
            )}

            <Flex flexDirection="column" width={1}>
              {folders.map((file) => (
                <Flex
                  key={file.id}
                  borderBottom="1px solid lightGrey"
                  py="0.75rem"
                  alignItems="center"
                  hoverColor="lightBlue"
                >
                  <ContentType file={file} />

                  <Flex width={1 / 3} pr="1rem">
                    <Tippy content={file.name}>
                      <Text
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                      >
                        {file.name}
                      </Text>
                    </Tippy>
                  </Flex>

                  <Flex width={1 / 4}>
                    <Avatar
                      mr="0.25rem"
                      src={users[file.created_by_id].avatar}
                    />

                    <Text color="t3">{users[file.created_by_id].nickname}</Text>
                  </Flex>

                  <Text width={1 / 4} color="t3">
                    {formatDate(
                      file.creation_date,
                      user.locale,
                      user.timezone
                    ).distance()}
                  </Text>
                  <Text width={1 / 8} color="t3">
                    -
                  </Text>

                  <Flex width="1rem" color="t3" style={{ cursor: "pointer" }}>
                    <FloatingMenu
                      placement="bottom-end"
                      options={[
                        {
                          name: "Delete",
                          component: Icon.Trash,
                          action: () => deleteFile(file.id)
                        }
                      ]}
                    >
                      <Icon.MoreVertical />
                    </FloatingMenu>
                  </Flex>
                </Flex>
              ))}
            </Flex>

            <Flex flexDirection="column" width={1}>
              {filesList.map((file, index) => (
                <Flex
                  key={file.id}
                  borderBottom="1px solid lightGrey"
                  py="0.75rem"
                  alignItems="center"
                >
                  <ContentType
                    file={file}
                    index={index}
                    setPreviewIndex={setPreviewIndex}
                  />

                  <Flex width={1 / 3} pr="1rem">
                    <Tippy content={file.name}>
                      <Text
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                        fontSize="0.875rem"
                      >
                        {file.name}
                      </Text>
                    </Tippy>
                  </Flex>

                  <Flex width={1 / 4}>
                    <Avatar
                      mr="0.5rem"
                      src={users[file.created_by_id].avatar}
                    />

                    <Text fontSize="0.875rem" color="t3">
                      {users[file.created_by_id].nickname}
                    </Text>
                  </Flex>

                  <Text width={1 / 4} color="t3" fontSize="0.875rem">
                    {formatDate(
                      file.creation_date,
                      user.locale,
                      user.timezone
                    ).distance()}
                  </Text>

                  <Text width={1 / 8} color="t3" fontSize="0.875rem">
                    {getSize(file.size)}
                  </Text>

                  <Flex width="1rem" color="t3" style={{ cursor: "pointer" }}>
                    <FloatingMenu
                      placement="bottom-end"
                      options={[
                        {
                          name: "Delete",
                          component: Icon.Trash,
                          action: () => deleteFile(file.id)
                        }
                      ]}
                    >
                      <Icon.MoreVertical />
                    </FloatingMenu>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </DragDropContext>
      </>
    </>
  );
};
