import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import { App } from "App";
import { STORE } from "store";
import { MODE } from "config";

import { register } from "workers";

let enableSW = false;

if (MODE === "PROD")
  Sentry.init({
    dsn: "https://dbba93b45f3d4f1e9495b5216020a948@sentry.ecentry.com/8"
  });

ReactDOM.render(
  <Provider store={STORE}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("app")
);

if (enableSW) register("/ef-sw.js");
