import { createActions, handleActions } from "redux-actions";
import { format } from "date-fns";

import { orderObjects } from "tomato";

import { defaultFilters } from "store";

const modelId = "tasks";

const options = { prefix: "tasks", namespace: "::" };

export const taskActions = createActions(
  {
    ADD_NOTE: (response) => ({
      module: modelId,
      response
    }),

    ADD_LIST: (response) => ({
      module: modelId,
      response
    }),

    ADD_PROJECT: (response) => ({
      module: modelId,
      response
    }),

    ADD_TASK: (response) => ({
      module: modelId,
      response
    }),

    ADD_FILES: (response) => ({
      module: modelId,
      response
    }),

    // ADD_PROJECT_USER: response => ({
    //   module: modelId,
    //   response
    // }),

    // ADD_SPACE_USER: response => ({
    //   module: modelId,
    //   response
    // }),

    DELETE_LIST: (response) => ({
      module: modelId,
      response
    }),

    DELETE_FILE: (response) => ({
      module: modelId,
      response
    }),

    DELETE_NOTE: (response) => ({
      module: modelId,
      response
    }),

    DELETE_PROJECT: (response) => ({
      module: modelId,
      response
    }),

    DELETE_TASK: (response) => ({
      module: modelId,
      response
    }),

    // DELETE_FROM_TRASH: response => ({
    //   module: modelId,
    //   response
    // }),

    DONE: (response) => ({
      module: modelId,
      response
    }),

    // FILTER_TASKS: (user) => ({
    //   module: modelId,
    //   user
    // }),

    // EMPTY_TRASH: response => ({
    //   module: modelId,
    //   response
    // }),

    FOLLOW_TASK: (response) => ({
      module: modelId,
      response
    }),

    LOAD_TASK: (response) => ({
      module: modelId,
      response
    }),

    LOAD_PROJECTS: (response) => ({
      module: modelId,
      response
    }),

    LOAD_TASKS: (response) => ({
      module: modelId,
      response
    }),

    // NEW_PROJECT: response => ({
    //   module: modelId,
    //   response
    // }),

    // REORDER_LIST: (listId, order) => ({
    //   module: modelId,
    //   listId,
    //   order
    // }),

    // REORDER_PROJECT: (projectId, order) => ({
    //   module: modelId,
    //   projectId,
    //   order
    // }),

    // REORDER_TASK: response => ({
    //   module: modelId,
    //   response
    // }),

    RESET_FILTERS: (view) => ({
      module: modelId,
      view
    }),

    SET_ADMIN_VIEW: (view) => ({
      module: modelId,
      view
    }),

    SET_FILTER: (key, value, view) => ({
      module: modelId,
      key,
      value,
      view
    }),

    SET_FILTERED_TASKS_IDS: (filteredTasksIds) => ({
      module: modelId,
      filteredTasksIds
    }),

    // SET_ORDERED_TASKS: tasks => ({
    //   module: modelId,
    //   tasks
    // }),

    // UPDATE_LOCAL_TASK: (key, value) => ({
    //   module: modelId,
    //   key,
    //   value
    // }),

    // SET_MODE: mode => ({
    //   module: modelId,
    //   mode
    // }),

    // SET_PRIORITY: response => ({
    //   module: modelId,
    //   response
    // }),

    // SET_STATUS: status => ({
    //   module: modelId,
    //   status
    // }),

    // SET_TAGS: tags => ({
    //   module: modelId,
    //   tags
    // }),

    // SET_TTL: key => ({
    //   module: modelId,
    //   key
    // }),

    UNDELETE_TASK: (response) => ({
      module: modelId,
      response
    }),

    UNDONE_TASK: (response) => ({
      module: modelId,
      response
    }),

    // UNLOAD_TASK: () => ({
    //   modelId
    // }),

    UPDATE_ATTRIBUTE: (taskId, key, value) => ({
      module: modelId,
      taskId,
      key,
      value
    }),

    UPDATE_UI: (key, value) => ({
      module: modelId,
      key,
      value
    }),

    UPDATE_GUESTS: (response) => ({
      module: modelId,
      response
    }),

    UPDATE_LIST: (response) => ({
      module: modelId,
      response
    }),

    // UPDATE_LIST_NAME: response => ({
    //   module: modelId,
    //   response
    // }),

    UPDATE_TASK: (response) => ({
      module: modelId,
      response
    }),

    UPDATE_TASKS: (taskId, task) => ({
      module: modelId,
      taskId,
      task
    }),

    UPDATE_TASK_ORDER: (taskId, listId, order) => ({
      module: modelId,
      taskId,
      listId,
      order
    }),

    UPDATE_PROJECT: (response) => ({
      module: modelId,
      response
    }),

    UPDATE_REACTIONS: (id, reactions) => ({
      module: modelId,
      id,
      reactions
    }),

    UPDATE_TIMER: (response) => ({
      module: modelId,
      response
    })
  },
  options
);

export const reducer = handleActions(
  {
    ADD_NOTE: (state, { payload: { response } }) => {
      if (
        (state.obj && typeof state.obj.notes === "undefined") ||
        typeof state.objs[response.task_id] === "undefined"
      )
        return state;

      if (state.obj) {
        state.obj.notes[response.id] = response;
        state.obj.total_notes = response.total_notes;
      }
      state.objs[response.task_id].total_notes = response.total_notes;
      return state;
    },

    ADD_FILES: (state, { payload: { response } }) => {
      console.log(response);
      state.obj.files.unshift(response);
      let task_id = response.task_id;
      state.objs[task_id].total_files = state.obj.files.length;
      return state;
    },

    // ADD_PROJECT_USER: (state, { payload: { response } }) => {
    //   if (response.success)
    //     state.projects[response.project_id].members.push({
    //       id: response.user_id,
    //       type_id: response.type_id
    //     });
    //   return state;
    // },
    // ADD_SPACE_USER: (state, { payload: { response } }) => {
    //   if (response.success)
    //     state.spaces[response.space_id].members.push({
    //       id: response.user_id,
    //       type_id: response.type_id
    //     });
    //   return state;
    // },

    DELETE_LIST: (state, { payload: { response } }) => {
      let listIndex;
      let projectId = response.project_id;
      delete state.lists[response.id];

      state.orderedLists = orderObjects(state.lists, "order", "asc");

      if (state.filters.listId === response.id) {
        state.filters.listId = null;
        localStorage.setItem("filters", JSON.stringify(state.filters));
      }

      Object.values(state.objs).forEach((task) => {
        if (task.list_id === response.id) delete state.objs[task.id];
      });

      listIndex = state.projects[projectId].lists_ids.indexOf(response.id);
      state.projects[projectId].lists_ids.splice(listIndex, 1);
      return state;
    },

    DELETE_FILE: (state, { payload: { response } }) => {
      state.objs[response.task_id].total_files -= response.total_deleted;
      // Iterando reversamente
      for (var i = state.obj.files.length - 1; i >= 0; --i) {
        if (response.ids.includes(state.obj.files[i].id)) {
          state.obj.files.splice(i, 1);
        }
      }
      return state;
    },

    DELETE_NOTE: (state, { payload: { response } }) => {
      state.obj.total_notes -= 1;
      state.objs[response.task_id].total_notes -= 1;
      delete state.obj.notes[response.note_id];
      return state;
    },

    ADD_LIST: (state, { payload: { response } }) => {
      state.lists[response.id] = response;
      state.projects[response.project_id].lists_ids.push(response.id);
      state.orderedLists = orderObjects(state.lists, "order", "asc");
      return state;
    },

    ADD_PROJECT: (state, { payload: { response } }) => {
      let project = response.project;

      state.projects[project.id] = project;
      state.orderedProjects = orderObjects(state.projects, "order", "asc");

      let lists = response.lists;
      state.lists = Object.assign({}, state.lists, lists);

      state.orderedLists = orderObjects(state.lists, "order", "asc");

      state.filters.listId = null;
      state.filters.projectId = response.id;
      localStorage.setItem("filters", JSON.stringify(state.filters));

      return state;
    },

    ADD_TASK: (state, { payload: { response } }) => {
      state.objs[response.id] = response;
      state.objs[response.id].subtasksIds = [];

      if (response.parent_id)
        state.objs[response.parent_id].subtasksIds.push(response.id);
      state.objs[response.parent_id] = { ...state.objs[response.parent_id] };
      if (
        !["ASSIGNED_TO_YOU", "ASSIGNED_TO_ALL"].includes(
          state.filters.assignment
        )
      )
        state.filters.assignment = "ASSIGNED_TO_YOU";

      state.filters.status = "OPEN";
      state.filters.priority = "ALL_PRIORITIES";
      state.filters.due = "ANYTIME";
      localStorage.setItem("filters", JSON.stringify(state.filters));

      // state.orderedTasks = orderObjects(response, "order", "asc");

      return state;
    },

    DELETE_PROJECT: (state, { payload: { response } }) => {
      if (state.filters.projectId === response.id) {
        state.filters.projectId = null;
        localStorage.setItem("filters", JSON.stringify(state.filters));
      }
      response.tasks_ids.forEach((taskId) => {
        delete state.objs[taskId];
      });

      response.lists_ids.forEach((listId) => {
        delete state.lists[listId];
      });
      state.orderedLists = orderObjects(state.lists, "order", "asc");

      delete state.projects[response.id];
      state.orderedProjects = orderObjects(state.projects, "order", "asc");
      return state;
    },

    DELETE_TASK: (state, { payload: { response } }) => {
      if (state.obj) {
        state.obj.deletion_date = response.deletion_date;
        state.obj.deleted_by_id = response.deleted_by_id;
        state.obj.timer_start = response.timer_start;
        state.obj.time_spent = response.time_spent;
      }
      if (!state.objs[response.id]) return state;
      delete state.objs[response.id];
      return state;
    },
    // DELETE_FROM_TRASH: (state, { payload: { response } }) => {
    //   let index = state.filtered["TRASH"].all.indexOf(response.id);
    //   state.filtered["TRASH"].all.splice(index, 1);
    //   delete state.objs[response.id];
    //   return state;
    // },

    DONE: (state, { payload: { response } }) => {
      if (state.obj && state.obj.id === response.id) {
        state.obj.done_date = response.done_date;
        state.obj.timer_start = null;
        state.obj.time_spent = response.time_spent;
        state.obj.done_by_id = response.done_by_id;
      }
      state.objs[response.id].done_date = response.done_date;
      state.objs[response.id].timer_start = null;
      state.objs[response.id].time_spent = response.time_spent;
      state.objs[response.id].done_by_id = response.done_by_id;
      state.inProgress = null;

      if (response.new_task) {
        let new_task = response.new_task;
        state.objs[new_task.id] = new_task;

        // new_task.deadlineKey =
        //   (new_task.deadline &&
        //     format(new Date(new_task.deadline), "yyyyMMdd")) ||
        //   null;
      }
      // task.space_id = projects[task.project_id].space_id;
      // new_task.project_id = lists[task.list_id].project_id;

      return state;
    },

    FOLLOW_TASK: (state, { payload: { response } }) => {
      if (state.obj) {
        state.obj.followers = response.followers;
      }
      state.objs[response.id].followers = response.followers;

      return state;
    },

    // EMPTY_TRASH: (state, { payload: { response } }) => {
    //   state.filtered["TRASH"].all = [];
    //   let id;
    //   for (id = 0; id < response.deleted_tasks_ids.length; id++) {
    //     delete state.objs[response.deleted_tasks_ids[id]];
    //   }
    //   return state;
    // },

    LOAD_TASK: (state, { payload: { response } }) => {
      response.deadlineKey =
        (response.deadline &&
          format(new Date(response.deadline), "yyyyMMdd")) ||
        null;

      response.doneKey =
        (response.done_date &&
          format(new Date(response.done_date), "yyyyMMdd")) ||
        null;

      state.obj = response;

      let subtasksIds = [];
      state.obj.subtasksIds = subtasksIds;

      if (response.parent_id && state.objs[response.parent_id]) {
        state.objs[response.parent_id].subtasksIds.push(response.id);
      }

      if (state.objs[response.id]) {
        subtasksIds = state.objs[response.id].subtasksIds;
      } else {
        // state.objs[response.id] = state.obj;
      }

      return state;
    },

    LOAD_PROJECTS: (state, { payload: { response } }) => {
      const lists = response.objects.lists;
      state.lists = lists;
      state.orderedLists = orderObjects(lists, "order", "asc");

      const projects = response.objects.projects;
      state.projects = projects;

      state.orderedLists.forEach((list) =>
        state.projects[list.project_id].lists_ids.push(list.id)
      );
      state.orderedProjects = orderObjects(projects, "order", "asc");
      return state;
    },

    LOAD_TASKS: (state, { payload: { response } }) => {
      state.objs = response.objects;
      return state;
    },

    // NEW_PROJECT: (state, { payload: { response } }) => {
    //   state.objs.projects[response.id] = {
    //     id: response.id,
    //     name: response.name,
    //     admin: response.admin
    //   };
    //   if (response.space.id)
    //     state.objs.spaces[response.space.id].projects.push(response.id);
    //   state.objs.lists_order = [];
    //   state.filters.listId = null;
    //   state.filters.projectId = response.id;
    //   return state;
    // },
    // REORDER_LIST: (state, { payload: { listId, order } }) => {
    //   state.lists[listId].order = order;
    //   return state;
    // },
    // REORDER_PROJECT: (state, { payload: { projectId, order } }) => {
    //   state.projects[projectId].order = order;
    //   return state;
    // },
    // REORDER_TASK: (state, { payload: { response } }) => {
    //   state.objs.lists[response.from_list_id].tasks.splice(
    //     response.from_index,
    //     1
    //   );
    //   state.objs.lists[response.to_list_id].tasks.splice(
    //     response.to_index,
    //     0,
    //     response.id
    //   );
    //   return state;
    // },

    RESET_FILTERS: (state, { payload: { view } }) => {
      if (view === "calendar") {
        state.calendarFilters = defaultFilters;
        localStorage.setItem("calendarFilters", JSON.stringify(defaultFilters));
      } else {
        state.filters = defaultFilters;
        localStorage.setItem("filters", JSON.stringify(defaultFilters));
      }
      return state;
    },

    SET_ADMIN_VIEW: (state, { payload: { view } }) => {
      state.adminView = view;
      return state;
    },

    SET_FILTER: (state, { payload: { view, key, value } }) => {
      let filter = view === "calendar" ? state.calendarFilters : state.filters;

      if (key === "spaceId") {
        filter.projectId = null;
        filter.listId = null;
        filter.spaceId = value;
      } else if (key === "projectId" && state.objs) {
        filter.projectId = value;
        filter.listId = null;
        filter.spaceId = null;
      } else if (key === "listId" && state.objs) {
        filter.spaceId = null;
        filter.projectId = null;
        filter.listId = filter.listId === value ? null : value;
      } else if (key === "mainFilter") {
        filter.tags.tags = [];
        filter[key] = value;
      } else if (key === "assignment") {
        // if (value === state.filters.assignment) {
        //   state.filters.assignment = null;
        // } else {
        filter.assignment = value;
        // }
        filter.owner = {};
      } else if (key === "owner") {
        state.filters.owner = value;
        // state.filters.assignment = null;
      } else if (key === "tags") {
        if (!value) {
          filter.tags.tags = [];
        }
        if (value.condition) filter.tags.condition = value.condition;
        if (filter.tags.tags.includes(value.tag)) {
          for (var i = filter.tags.tags.length - 1; i >= 0; --i) {
            if (filter.tags.tags[i] === value.tag) {
              filter.tags.tags.splice(i, 1);
            }
          }
        } else if (value.tag) {
          filter.tags.tags.push(value.tag);
        }
      } else {
        filter[key] = value;
      }
      // if (state.filters[key] === value) {
      //   state.filters[key] = null;
      // } else {
      //   state.filters[key] = value;
      // }
      localStorage.setItem(
        view === "calendar" ? "calendarFilters" : "filters",
        JSON.stringify(filter)
      );
      filter = { ...filter };
      return state;
    },

    // SET_ORDERED_TASKS: (state, { payload: { tasks } }) => {
    //   state.orderedTasks = tasks;
    //   return state;
    // },

    SET_FILTERED_TASKS_IDS: (state, { payload: { filteredTasksIds } }) => {
      state.filteredTasksIds = filteredTasksIds;
      return state;
    },

    // UPDATE_LOCAL_TASK: (state, { payload: { key, value } }) => {
    //   state.local[key] = value;
    //   return state;
    // },
    // SET_MODE: (state, { payload: { mode } }) => {
    //   state.mode = mode;
    //   state.filters.projectId = null;
    //   state.filters.listId = null;
    //   return state;
    // },
    // SET_PRIORITY: (state, { payload: { response } }) => {
    //   state.objs[response.id].priority = {
    //     id: response.priority_id,
    //     color: response.priority.color,
    //     name: response.priority.name
    //   };
    //   // state.local.task.priority = {
    //   //   id: response.priority_id,
    //   //   color: response.priority.color,
    //   //   name: response.priority.name
    //   // };
    //   // state.local.task.priority_id = response.priority_id;
    //   return state;
    // },
    // SET_STATUS: (state, { payload: { status } }) => {
    //   state.status = status;
    //   return state;
    // },
    // SET_TAGS: (state, { payload: { tags } }) => {
    //   state.tags = tags;
    //   return state;
    // },
    // SET_TTL: (state, { payload: { key } }) => {
    //   state.ttl[key] = Date.now() + TTL;
    //   return state;
    // },

    UNDELETE_TASK: (state, { payload: { response } }) => {
      if (state.objs[response.id]) {
        state.objs[response.id].deletion_date = null;
        state.objs[response.id].deleted_by_id = null;
      }
      if (state.obj && state.obj.id === response.id) {
        state.obj.deletion_date = null;
        state.obj.deleted_by_id = null;
      }
      return state;
    },

    UNDONE_TASK: (state, { payload: { response } }) => {
      if (state.objs[response.id]) {
        state.objs[response.id].done_date = null;
        state.objs[response.id].done_by_id = null;
      }
      if (state.obj && state.obj.id === response.id) {
        state.obj.done_date = null;
        state.obj.done_by_id = null;
      }
      return state;
    },
    // UPDATE_LIST_NAME: (state, { payload: { response } }) => {
    //   console.log(response);
    //   state.lists[response.id].name = response.name;
    //   return state;
    // },

    UPDATE_PROJECT: (state, { payload: { response } }) => {
      state.projects[response.id] = response;
      let index = state.orderedProjects.findIndex(
        (project) => project.id === response.id
      );
      state.orderedProjects[index] = response;
      return state;
    },

    // UNLOAD_TASK: (state) => {
    //   state.obj = {};
    //   return state;
    // },

    UPDATE_ATTRIBUTE: (state, { payload: { taskId, key, value } }) => {
      if (state.obj) state.obj[key] = value;
      if (state.objs[taskId]) state.objs[taskId][key] = value;
      return state;
    },

    UPDATE_UI: (state, { payload: { key, value } }) => {
      state.ui[key] = value;
      return state;
    },

    UPDATE_LIST: (state, { payload: { response } }) => {
      state.lists[response.id] = response;
      // state.orderedLists = orderObjects(state.lists, "order", "asc");
      return state;
    },

    UPDATE_GUESTS: (state, { payload: { response } }) => {
      state.objs[response.id].guests = response.guests;
      if (state.obj) state.obj.guests = response.guests;
      return state;
    },

    UPDATE_REACTIONS: (state, { payload: { id, reactions } }) => {
      state.obj.notes[id].reactions = reactions;
      return state;
    },

    UPDATE_TASK_ORDER: (state, { payload: { taskId, listId, order } }) => {
      state.objs[taskId].order = order;
      state.objs[taskId].list_id = listId;
      return state;
    },

    UPDATE_TASK: (state, { payload: { response } }) => {
      if (state.obj && state.obj.id === response.id) {
        state.obj = response;
      }

      state.objs[response.id] = {
        ...response,
        subtasksIds: state.objs[response.id].subtasksIds
      };
      return state;
    },

    UPDATE_TASKS: (state, { payload: { taskId, task } }) => {
      let taskState = state.objs[taskId];
      state.objs[taskId] = { ...taskState, ...task };
      return state;
    },

    UPDATE_TIMER: (state, { payload: { response } }) => {
      if (state.obj) {
        state.obj.timer_start = response.timer_start;
        state.obj.time_spent = response.time_spent;
      }
      state.objs[response.id].timer_start = response.timer_start;
      state.objs[response.id].time_spent = response.time_spent;

      if (response.timer_start) {
        state.inProgress = response.id;
      } else state.inProgress = null;

      if (response.owner_id) {
        state.objs[response.id].owner_id = response.owner_id;
        if (state.obj) state.obj.owner_id = response.owner_id;
      }

      if (response.paused_tasks) {
        response.paused_tasks.map((task) => {
          state.objs[task.id].time_spent = task.time_spent;
          state.objs[task.id].timer_start = null;
          return null;
        });
      }
      return state;
    }
  },
  {},
  options
);
