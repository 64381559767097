import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Flex, Overlay, Text, useLocalStorage } from "tomato";

import { Categories } from "./Categories";
import { DEFAULT_MARGIN } from "constant";
import { MainWindow, MembersList, SideMenu } from "components";
import NewCategory from "./NewCategory";
import NewTopic from "./NewTopic";
import { SpacesMenu } from "modules";
import { Timeline } from "./Timeline";
import { Updates } from "./Updates";

import { Topic } from "./Topic";

export const Topics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeSpace = useSelector((state) => state["spaces"].activeSpace);
  const categories = useSelector((state) => state["topic"].categories);
  const loadingObjId = useSelector((state) => state["topic"].loadingObjId);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const selectedSpaceId = useSelector((state) => state["spaces"].spaceId);

  const [lastRead, setLastRead] = useLocalStorage("topicLastRead", {});

  const spaceMatch = useRouteMatch("/topic/:spaceId");
  const categoryMatch = useRouteMatch("/topic/:spaceId/:categoryId");
  const topicMatch = useRouteMatch("/topic/:spaceId/:categoryId/:topicId");

  const [newCategory, setNewCategory] = useState(false);
  const [newTopic, setNewTopic] = useState(false);

  const history = useHistory();

  let View = Timeline;
  let spaceId;
  let categoryId;
  let topicId;

  if (topicMatch) {
    View = Topic;
    spaceId = parseInt(topicMatch.params.spaceId);
    categoryId = parseInt(topicMatch.params.categoryId);
    topicId = parseInt(topicMatch.params.topicId);
  } else if (categoryMatch) {
    spaceId = parseInt(categoryMatch.params.spaceId);
    categoryId = parseInt(categoryMatch.params.categoryId);
    View = Updates;
  } else if (spaceMatch) {
    spaceId = parseInt(spaceMatch.params.spaceId);
    View = Categories;
  } else if (selectedSpaceId) {
    history.push(`/topic/${selectedSpaceId}`);
    spaceId = selectedSpaceId;
    View = Categories;
  }

  useEffect(() => {
    document.title = t("Message board");
    return () => (document.title = "Elefante");
  }, [t]);

  useEffect(() => {
    if (!loadingObjId) return;
    let newDate = {};
    newDate[loadingObjId] = new Date().getTime();
    setLastRead({ ...lastRead, ...newDate });
  }, [loadingObjId, setLastRead]);

  // useEffect(() => {
  // if (!refresh) return;
  // dispatch(
  //   ACTIONS.fetch.get(
  //     "topic",
  //     `${API_ENDPOINT}/category?limit=0`,
  //     ACTIONS.state.loadObject,
  //     (state, response) => {
  //       state.categories = response.objects;
  //       return state;
  //     }
  //   )
  // );
  // dispatch(
  //   ACTIONS.fetch.get(
  //     "topic",
  //     `${API_ENDPOINT}/topic`,
  //     ACTIONS.state.loadObject,
  //     (state, response) => {
  //       let topics = {};
  //       response.objects.forEach((topic) => (topics[topic.id] = topic));
  //       state.objs = response.objects;
  //       return state;
  //     }
  //   )
  // );
  // setRefresh(false);
  // }, [dispatch, refresh]);

  // if (!spaces || !categories) return null;

  const spacesActions = {};

  const spaceActions = [
    {
      label: t("New category"),
      icon: "Plus",
      action: (item) => {
        setNewCategory({
          spaceId: item.id
        });
      }
    }
  ];

  Object.values(spaces).forEach((space) => {
    spacesActions[space.id] = {
      ...space,
      parentId: null,
      spaceId: space.id,
      view: Categories,
      actions: spaceActions,
      linkTo: `/topic/${space.id}`,
      items: []
    };
  });

  const categoryActions = [
    {
      label: t("New post"),
      icon: "Plus",
      action: (item) => {
        console.log(item);
        setNewTopic({
          spaceId: item.spaceId,
          categoryId: item.categoryId
        });
      }
    }
  ];

  const tree = {};
  categories &&
    categories.forEach((category) => {
      let item = {
        id: category.id,
        uuid: `1.${category.id}`,
        parentUuid: category.space_id,
        name: category.name,
        actions: categoryActions,
        spaceId: category.space_id,
        linkTo: `/topic/${category.space_id}/${category.id}`
      };
      // spacesActions[item.parentUuid].items.push(item.uuid);
      tree[`1.${category.id}`] = item;
    });

  return (
    <Flex width={1} height="100%" label="Pages">
      {newCategory && (
        <Overlay
          open={newCategory}
          setOpen={setNewCategory}
          maxWidth={800}
          width="90%"
          maxHeight="90%"
          borderRadius="0.5rem"
          bg="l0"
          p={DEFAULT_MARGIN}
          flexDirection="column"
        >
          <NewCategory
            open={newCategory}
            setOpen={setNewCategory}
            spaceId={newCategory.spaceId}
          />
        </Overlay>
      )}

      {newTopic && (
        <Overlay
          open={newTopic}
          setOpen={setNewTopic}
          maxWidth={800}
          width="90%"
          maxHeight="90%"
          borderRadius="0.5rem"
          bg="l0"
          p={DEFAULT_MARGIN}
          flexDirection="column"
        >
          <NewTopic
            open={newTopic}
            setOpen={setNewTopic}
            spaceId={newTopic.spaceId}
            categoryId={newTopic.categoryId}
          />
        </Overlay>
      )}

      <SideMenu>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p={DEFAULT_MARGIN}
        >
          <Text textStyle="title">{t("Forum")}</Text>
        </Flex>

        <Flex flexDirection="column" overflow="auto" px={DEFAULT_MARGIN}>
          {false && (
            <Text
              borderRadius="0.25rem"
              bg={spaceId ? "l2" : "l3"}
              fontSize="1rem"
              color={spaceId ? "t4" : "t1"}
              fontWeight="semibold"
              mb="1rem"
              px="1rem"
              py="0.5rem"
              onClick={() => {
                history.push("/topic");
              }}
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "pointer"
              }}
            >
              {t("Feed")}
            </Text>
          )}

          <SpacesMenu
            menu={tree}
            module="topic"
            showPublic={false}
            selectFirst={true}
            levels={0}
            spacesActions={spacesActions}
            placement="right"
          />
        </Flex>
      </SideMenu>

      <MainWindow bg={spaceId ? "l0" : "l1"}>
        <View
          lastRead={lastRead}
          setLastRead={setLastRead}
          spaceId={spaceId}
          categoryId={categoryId}
          topicId={topicId}
        />
      </MainWindow>

      {false && (
        <SideMenu>
          <MembersList spaceId={activeSpace && activeSpace.id} />
        </SideMenu>
      )}
    </Flex>
  );
};
