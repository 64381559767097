import React, { useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import { Grid } from "react-virtualized";
import Tippy from "@tippyjs/react/headless";
import { useTranslation } from "react-i18next";

import { EMOJIS, EMOJIS_VALUES, EMOJIS_GROUPS } from "../../constants";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Image } from "../Image";
import { Input } from "../Input";
import { Text } from "../Text";
import { orderObjects, useLocalStorage } from "tomato";

let searchOptions = {
  shouldSort: false,
  threshold: 0,
  ignoreLocation: true,
  maxPatternLength: 100,
  minMatchCharLength: 2,
  keys: ["name", "keywords"]
};

export const Emojis = ({
  attrs,
  instance,
  onSelect,
  setOpen,
  mostUsed,
  setMostUsed
}) => {
  const { t } = useTranslation();

  const [preview, setPreview] = useState();
  const [search, setSearch] = useState("");

  const fuse = useMemo(() => {
    return new Fuse(EMOJIS_VALUES, searchOptions);
  }, []);

  const selectEmoji = (emoji) => {
    let code = emoji.code;
    if (!(code in mostUsed)) mostUsed[code] = { id: code, c: 0 };
    mostUsed[code].c += 1;
    setMostUsed({ ...mostUsed });
    setOpen && setOpen(false);
    if (typeof onSelect !== "function") return;
    onSelect(emoji.code);
  };

  let count = 0;
  let total = 0;
  let objs;
  let render = [];

  if (Object.keys(mostUsed).length !== 0) {
    const orderedMostUsed = orderObjects(mostUsed, "c", "desc");

    render = [{ type: "text", text: t("Most used") }];
    total = orderedMostUsed.length;

    orderedMostUsed.forEach((emoji) => {
      if (count === 0) objs = { type: "emoji", list: [] };
      objs.list.push({ code: emoji.id });
      count += 1;
      total -= 1;
      if (count === 9 || total === 0) {
        count = 0;
        render.push(objs);
      }
    });
  }

  if (!search) {
    Object.keys(EMOJIS_GROUPS).forEach((group) => {
      count = 0;

      render.push({ type: "text", text: group });

      EMOJIS_GROUPS[group].forEach((emojiCode) => {
        if (count === 0) objs = { type: "emoji", list: [] };

        objs.list.push({ code: emojiCode });

        count += 1;
        total -= 1;
        if (count === 9 || total === 0) {
          count = 0;
          render.push(objs);
        }
      });
    });
  } else {
    let results = fuse.search(search);
    count = 0;

    results.forEach((emoji) => {
      console.log(emoji);
      if (count === 0) objs = { type: "emoji", list: [] };

      objs.list.push({ code: emoji.item.code });

      count += 1;
      total -= 1;
      if (count === 9 || total === 0) {
        count = 0;
        render.push(objs);
      }
    });
  }

  const renderer = ({ render, setPreview, key, rowIndex, style }) => {
    let row = render[rowIndex];

    if (row.type === "text")
      return (
        <Flex
          key={key}
          justifyContent="space-between"
          style={style}
          alignItems="center"
        >
          <Text fontWeight="bold">{row.text}</Text>
        </Flex>
      );

    return (
      <Flex key={key} style={style} justifyContent="space-between">
        {row.list.map((emoji) => (
          <Flex
            style={{ cursor: "pointer" }}
            hoverColor="l4"
            borderRadius="0.5rem"
            p="0.5rem"
            height="fit-content"
            key={emoji.code}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectEmoji(emoji);
            }}
            onMouseEnter={() => setPreview(emoji)}
            onMouseLeave={() => setPreview()}
          >
            <Image
              width={26}
              minWidth={26}
              height={26}
              minHeight={26}
              src={`https://static.elefante.com/emojis/${emoji.code}.png`}
            />
          </Flex>
        ))}
      </Flex>
    );
  };

  if (instance && !instance.state.isVisible) return null;

  return (
    <Flex
      {...attrs}
      bg="l0"
      maxHeight="30rem"
      overflow="auto"
      borderRadius="0.375rem"
      border="1px solid lightGrey"
      flexDirection="column"
      boxShadow="2px 3px 8px -2px rgba(0,0,0,0.40)"
      zIndex="100000"
      width="fit-content"
      p="0.5rem"
    >
      {false && (
        <Flex>
          {Object.keys(EMOJIS_GROUPS).map((group) => (
            <Text key={group}>{group}</Text>
          ))}
        </Flex>
      )}

      <Input
        my="0.5rem"
        onChange={setSearch}
        leftIcon="Search"
        placeholder="Search"
        enableClear={true}
        onClear={() => setSearch()}
        // focus={true}
      />

      <Flex px="0.5rem">
        <Grid
          style={{ outline: "none" }}
          cellRenderer={(props) => renderer({ render, setPreview, ...props })}
          columnCount={1}
          height={300}
          rowCount={render.length}
          columnWidth={440}
          rowHeight={40}
          width={440}
        />
      </Flex>

      <Flex
        height={60}
        mb={0}
        mt={0}
        p={4}
        alignItems="center"
        borderTop="1px solid #e2e2e2"
      >
        {preview ? (
          <>
            <Image
              width={36}
              height={36}
              src={`https://static.elefante.com/emojis/${preview.code}.png`}
            />
            <Flex flexDirection="column" ml="1rem">
              <Flex alignItems="center">
                <Text fontWeight="semibold" color="t2">
                  {EMOJIS[preview.code].name}
                </Text>
                <Text ml="0.5rem" fontSize="0.625rem" color="t3">
                  ({preview.code})
                </Text>
              </Flex>
              <Text color="t3">{`:${EMOJIS[preview.code].name}:`}</Text>
            </Flex>
          </>
        ) : (
          <Flex justifyContent="space-between" alignItems="center" width={1}>
            <Text fontWeight="semibold" color="t2">
              {t("Quick reactions")}
            </Text>

            <Flex>
              <Flex
                p="0.5rem"
                borderRadius="0.375rem"
                hoverColor="lightBlue"
                width="fit-content"
                style={{ cursor: "pointer" }}
                onClick={() => selectEmoji({ code: "1f603" })}
              >
                <Image
                  width={30}
                  height={30}
                  src={`https://static.elefante.com/emojis/1f603.png`}
                />
              </Flex>
              <Flex
                p="0.5rem"
                borderRadius="0.375rem"
                hoverColor="lightBlue"
                width="fit-content"
                style={{ cursor: "pointer" }}
                onClick={() => selectEmoji({ code: "1f44d" })}
              >
                <Image
                  width={30}
                  height={30}
                  src={`https://static.elefante.com/emojis/1f44d.png`}
                />
              </Flex>
              <Flex
                p="0.5rem"
                borderRadius="0.375rem"
                hoverColor="lightBlue"
                width="fit-content"
                style={{ cursor: "pointer" }}
                onClick={() => selectEmoji({ code: "2764" })}
              >
                <Image
                  width={30}
                  height={30}
                  src={`https://static.elefante.com/emojis/2764.png`}
                />
              </Flex>
              <Flex
                p="0.5rem"
                borderRadius="0.375rem"
                hoverColor="lightBlue"
                width="fit-content"
                style={{ cursor: "pointer" }}
                onClick={() => selectEmoji({ code: "1f440" })}
              >
                <Image
                  width={30}
                  height={30}
                  src={`https://static.elefante.com/emojis/1f440.png`}
                />
              </Flex>
              <Flex
                style={{ cursor: "pointer" }}
                onClick={() => selectEmoji({ code: "1f44a" })}
                p="0.5rem"
                borderRadius="0.375rem"
                hoverColor="lightBlue"
                width="fit-content"
              >
                <Image
                  width={30}
                  height={30}
                  src={`https://static.elefante.com/emojis/1f44a.png`}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const EmojiPicker = ({ random = false, onSelect, ...props }) => {
  const [mostUsed, setMostUsed] = useLocalStorage("MUE", {});
  const [instance, setInstance] = useState(null);
  const [open, setOpen] = useState(false);

  return (
    <Tippy
      trigger="manual"
      placement='right'
      interactive={true}
      offset={[0, 4]}
      appendTo={() => document.body}
      onCreate={setInstance}
      onClickOutside={() => {
        if (open) setOpen(false);
      }}
      render={(attrs) => (
        <>
          {open ? (
            <Emojis
              attrs={attrs}
              instance={instance}
              onSelect={onSelect}
              setOpen={setOpen}
              mostUsed={mostUsed}
              setMostUsed={setMostUsed}
            />
          ) : (
            ""
          )}
        </>
      )}
      zIndex="100000"
    >
      <Flex
        onClick={(e) => {
          e.preventDefault();

          if (random) {
            const index = parseInt(Math.random() * 1809);
            const emoji = Object.values(EMOJIS)[index];
            onSelect(emoji.code);
          } else {
            open ? instance.hide() : instance.show();
            instance && setOpen(!open);
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onMouseUp={(e) => {
          e.preventDefault();
        }}
        style={{ cursor: "pointer" }}
        {...props}
      >
        <Icon.Smile />
      </Flex>
    </Tippy>
  );
};
