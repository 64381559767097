import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, Flex, Icon, Input, Text } from "tomato";

export const Admin = () => {
  const ws = useSelector((state) => state["ws"].ws);

  const [message, setMessage] = useState("");

  const forceReload = () => {
    ws.send(JSON.stringify({ action: "ping" }));
    ws.send(
      JSON.stringify({
        type: "pubsub",
        action: "publish",
        data: {
          channel: "GLOBAL",
          message: { type: "ws", action: "forceReload", data: { message } }
        }
      })
    );
  };

  return (
    <Flex p={8} flexDirection="column">
      <Flex alignItems="center" mb={8}>
        <Text textStyle="title">Administration</Text>
      </Flex>

      <Input
        width="20rem"
        type="text"
        placeholder="Enter to save and reload"
        clearOnEnter={true}
        onEnter={forceReload}
        onChange={setMessage}
      />
    </Flex>
  );
};
