import { mergeMap, withLatestFrom } from "rxjs/operators";

import { ACTIONS, STORE } from "store";

// Este epic existe para poder acrescentar o user que está recebendo a mensage, pois, no pubsub não
// é possível saber quem está recebendo
export const updateMessage = (action$, state$, { dispatch }) => {
  return action$.ofType("conversations::MESSAGE").pipe(
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const user = STORE.getState()["account"]["user"];
      action.payload.currentUserId = user.id;
      dispatch(ACTIONS.conversations.insertMessage(action.payload));

      return [];
    })
  );
};
