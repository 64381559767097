import React from "react";
import { useSelector } from "react-redux";

import { Flex, GlobalStyle, Image } from "tomato";

import { RESOURCES } from "constant";

export const Loading = () => {
  const loadedResources = useSelector((state) => state["app"].loadedResources);

  let completed = loadedResources.length / RESOURCES.length;

  return (
    <>
      <GlobalStyle />
      <Flex
        width={1}
        pt="3rem"
        alignItems="center"
        justifyContent="center"
        heigth="100%"
        flexDirection="column"
      >
        <Image
          minHeigth="5rem"
          maxHeigth="5rem"
          maxWidth="15rem"
          src="/images/login/loading.svg"
        />

        <Flex
          bg="lightGrey"
          mt="1rem"
          width="15rem"
          maxWidth="15rem"
          height="1rem"
          borderRadius="2rem"
          ml="-2rem"
        >
          <Flex
            width={completed}
            height="1rem"
            bg="green"
            borderRadius="2rem"
          />
        </Flex>
      </Flex>
    </>
  );
};
