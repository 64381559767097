import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const COLORS = ["#0088FE", "#e5f3ff", "#FFBB28", "#FF8042"];
const ChartBar = ({ data, legend = false, tooltip = true, height = 200 }) => {
  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        {tooltip && <Tooltip />}
        {legend && <Legend />}
        <Bar dataKey={data.key} fill="#82ca9d">
          {data.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export { ChartBar as BarChart };
