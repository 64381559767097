import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ACTIONS } from "store";

import { ENABLE_WS } from "config";
import { WS_URL } from "config";

const Subscribe = () => {
  const user = useSelector((state) => state["account"].user);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const ws = useSelector((state) => state["ws"].ws);

  useEffect(() => {
    if (!ws) return;
    if (!spaces && !user) return;

    let channels = Object.values(spaces)
      .filter((space) => {
        let isMember = false;
        space.members.forEach((member) => {
          if (member.id === user.id) isMember = true;
        });
        return isMember;
      })
      .map((space) => `space:${space.id}`);

    ws.send(
      JSON.stringify({
        type: "pubsub",
        action: "subscribe",
        data: {
          channels
        }
      })
    );
  }, [ws, user, spaces]);

  return null;
};

const WebSocket_ = () => {
  const dispatch = useDispatch();

  const uuid = useSelector((state) => state["account"].uuid);

  const uuidRef = useRef(uuid);

  const connect = useCallback(() => {
    let ws = new WebSocket(`${WS_URL}/ws?uuid=${uuidRef.current}`);
    ws.onopen = () => {
      console.log("Real time socket is connected.");
      dispatch(ACTIONS.ws.setWs(ws));
    };

    ws.onclose = (e) => {
      dispatch(ACTIONS.ws.setWs(null));

      console.log(
        "Socket is closed. Reconnect will be attempted in 3 seconds.",
        e.reason
      );

      setTimeout(function () {
        connect();
      }, 5000);
    };

    ws.onerror = (e) => {
      console.error("Socket error: ", e.message, "Closing socket");
      ws.close();
    };

    ws.onmessage = (message) => {
      let data = JSON.parse(message.data);
      let action = data.action;
      let reducer = data.type;
      let payload = { ...data.data };

      if (action === "forceReload") {
        dispatch(ACTIONS.state.setAttribute("app", "needsReload", payload));
        return;
      }

      if (!reducer) {
        console.log("WS missing reducer");
        return;
      }

      let dispatcher = ACTIONS[reducer][action];

      if (typeof dispatcher === "undefined") {
        console.log("Ws dispatcher not a function");
        return;
      }

      dispatch(dispatcher(payload));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!ENABLE_WS) return;
    console.log("Connecting websocket");
    connect();
  }, [connect]);

  return <Subscribe />;
};

export { WebSocket_ as WebSocket };
