import styled from "styled-components";
import {
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  color,
  layout,
  position,
  shadow,
  space,
  typography,
  variant
} from "styled-system";

import { Text } from "../Text";
import { UI } from "../../constants";

const inputs = variant({ key: "inputs" });

export const StyledInput = styled(Text)(
  {
    height: UI.INPUT_HEIGHT,
    minHeight: UI.INPUT_HEIGHT,

    wordWrap: "break-word",
    whiteSpace: "normal",
    display: "inline-block",
    boxShadow: "none",

    fontFamily: UI.FONT_FAMILY,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "0.25rem",
    border: "1px solid red",
    outline: "0px",
    background: "transparent"
  },
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  color,
  inputs,
  layout,
  position,
  shadow,
  space,
  typography
);

StyledInput.propTypes = {
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...boxShadow.propTypes,
  ...inputs.propTypes
};

StyledInput.defaultProps = {
  as: "input"
};

export { StyledInput as Input };
