import { RRule } from "rrule";
import { parseISO } from "date-fns";

import { FormatDate } from "tomato";

import { STORE } from "store";

const calcRRule = (rrule, fromDate) => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let UTCDate = new Date(Date.UTC(year, month, day, hours, minutes));

  if (typeof fromDate !== "undefined") {
    if (fromDate > UTCDate) UTCDate = fromDate;
  }

  let rule = new RRule({
    ...rrule,
    wkst: RRule.SU,
    dtstart: UTCDate,
    count: 2
  });

  return rule.all()[1];
};

export const calcRecur = (rrule, date) => {
  let fromDate;
  // if (date) fromDate = addDays(parseISO(date), 1);
  if (date) fromDate = parseISO(date);
  let nextDate;
  nextDate = calcRRule(rrule, fromDate);
  return nextDate;
};

export const DueColor = (date) => {
  let days = FormatDate.DaysDifference(date);

  if (days < 0) {
    if (days > -6) {
      return "orange";
    }

    return "green";
  }

  if (days >= 0) {
    return "red";
  }
};

export const formatTimeSpent = (totalSeconds) => {
  if (!totalSeconds || typeof totalSeconds === "undefined")
    return "00h 00m 00s";
  let hours = parseInt(totalSeconds / 3600);
  totalSeconds = totalSeconds % 3600;

  let minutes = parseInt(totalSeconds / 60);
  totalSeconds = totalSeconds % 60;

  let seconds = parseInt(totalSeconds);

  hours = ("0" + hours).slice(-2);
  minutes = ("0" + minutes).slice(-2);
  seconds = ("0" + seconds).slice(-2);

  return `${hours}h ${minutes}m ${seconds}s`;
};

const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const orderTasks = (tasks, tasksIds, order) => {
  let orderedTasks = [];
  let sortedArray = [...tasksIds];

  if (tasksIds.length === 0) return [];

  if (!order) order = { field: "order", direction: "desc" };

  let x, y;

  sortedArray.sort((a, b) => {
    [x, y] = [tasks[a][order.field], tasks[b][order.field]];
    if (order.direction === "asc") {
      return compare(x, y);
    } else {
      return compare(y, x);
    }
  });

  sortedArray.forEach((taskId) => orderedTasks.push(taskId));

  return orderedTasks;
};

export const getTags = (filteredTasks) => {
  const tasks = STORE.getState()["tasks"]["tasks"];
  // console.log(filteredTasks.tags);
  let tags = [];
  if (!tasks) return tags;
  // console.log(filteredTasks);

  //   filteredTasks.all.map(taskId => {
  //     tags = tags.concat(tasks[taskId].tags);
  //   });

  return Array.from(new Set(filteredTasks.tags));
};

export const calcTime = (totalSeconds) => {
  if (!totalSeconds) return "0h:00m";

  let days = parseInt(totalSeconds / 86400);
  let totalHours = totalSeconds % 86400;

  let hours = parseInt(totalHours / 3600);
  totalSeconds = totalSeconds % 3600;

  let minutes = parseInt(totalSeconds / 60);

  hours = ("0" + hours).slice(-2);
  minutes = ("0" + minutes).slice(-2);

  if (days) return `${days}d ${hours}h:${minutes}m`;
  if (hours) return `${hours}h:${minutes}m`;
  if (minutes) return `${minutes}m`;
};

// export const filterTasks = (filters, tasks, order) => {
//   let groups = STORE.getState()["tasks"]["lists"];
//   let projects = STORE.getState()["tasks"]["projects"];

//   let active;
//   let filteredTasks = {
//     pinned: [],
//     grouped: {},
//     ungrouped: [],
//     milestones: {},
//     all: []
//   };

//   let groupId;
//   let projectId;
//   let spaceId;
//   // let milestoneId;
//   let task;

//   // let selectedGroup;
//   // let selectedProject;
//   // let selectedSpaceId;
//   // let selectedOwnerId = filters.ownerId;
//   // let selectedAssignment = filters.assignment;

//   let filteredIds = [];
//   let filteredIdsbeforeTags = [];
//   let tagFilter;
//   let tags = [];

//   // A busca ignora o main filter (a ser definido)
//   let useMainFilters = true;
//   // typeof filters.search === "undefined" ||
//   // filters.search.results.length === 0;

//   if (!tasks || tasks.length === 0) return filteredTasks;

//   // if (filters.tags.condition === "or") {
//   //   tagFilter = anyListItemInAnotherList;
//   // } else {
//   //   tagFilter = allListItemInAnotherList;
//   // }

//   Object.keys(tasks).forEach((taskId) => {
//     taskId = parseInt(taskId);
//     task = tasks[taskId];
//     groupId = task.group_id;
//     if (groupId) projectId = groups[groupId].project_id;
//     if (projectId) spaceId = projects[projectId].space_id;

//     // projectId = milestoneId = task.milestone_id;

//     active = !(task.done_date || task.deletion_date);

//     if (
//       typeof filters.search !== "undefined" &&
//       filters.search.term &&
//       filters.search.results.length !== 0
//     ) {
//       if (!filters.search.results.includes(taskId)) return;
//     }

//     if (filters.groupId && parseInt(filters.groupId) !== groupId) return;

//     if (filters.projectId && parseInt(filters.projectId) !== projectId) return;

//     if (filters.spaceId && parseInt(filters.spaceId) !== spaceId) return;

//     // if (filters.milestoneId && parseInt(filters.milestoneId) !== milestoneId)
//     //   return;

//     // if (filters.spaceId) {
//     //   selectedGroup = groups[groupId];
//     //   if (typeof selectedGroup !== "undefined") {
//     //     if (selectedGroup.project_id) {
//     //       selectedProject = projects[selectedGroup.project_id];
//     //       if (typeof selectedProject !== "undefined")
//     //         selectedSpaceId = projects[selectedGroup.project_id].space_id;
//     //     }
//     //   }
//     // if (parseInt(filters.spaceId) !== selectedSpaceId) return;
//     // }

//     if (groupId && !(groupId in filteredTasks["grouped"]))
//       filteredTasks["grouped"][groupId] = [];

//     // Filtro por owner
//     // if (selectedOwnerId) {
//     //   if (!(active && task.owner_id === parseInt(selectedOwnerId))) return;
//     // }

//     // Filtro por assignment
//     // if (selectedAssignment) {
//     //   if (selectedAssignment === "UNASSIGNED") {
//     //     if (!(active && !task.owner_id)) return;
//     //   } else if (selectedAssignment === "ASSIGNED_TO_YOU") {
//     //     if (
//     //       !(
//     //         active &&
//     //         task.owner_id === user.id &&
//     //         task.created_by_id !== user.id
//     //       )
//     //     )
//     //       return;
//     //   } else if (selectedAssignment === "ASSIGNED_BY_YOU") {
//     //     if (
//     //       !(
//     //         active &&
//     //         task.owner_id !== user.id &&
//     //         task.created_by_id === user.id &&
//     //         task.owner_id
//     //       )
//     //     )
//     //       return;
//     //   } else if (selectedAssignment === "OWNED_BY_YOU") {
//     //     if (!(active && task.owner_id === user.id)) return;
//     //   } else if (selectedAssignment === "OWNED_BY_OTHERS") {
//     //     if (!(active && task.owner_id && task.owner_id !== user.id)) return;
//     //   }
//     // }

//     // Main filters
//     if (useMainFilters) {
//       if (filters.mainFilter === "ALL") {
//         if (!active) return;
//       }

//       // if (filters.mainFilter === "ANYTIME") {
//       //   if (!(active && task.timeframe_id === 1)) return;
//       // }

//       // Tarefas filtradas por Someday só aparecem no filtro Someday, não aparecem nos outros filters
//       // O Someday é tipo Backlog, não deve poluir o dia a dia
//       // if (filters.mainFilter === "SOMEDAY") {
//       //   if (!(active && task.timeframe_id === 2 && !task.start_date)) return;
//       // } else {
//       //   if (task.timeframe_id === 2 && !task.start_date) return;
//       // }

//       if (filters.mainFilter === "INBOX") {
//         if (
//           !(
//             active &&
//             task.timeframe_id === 0 &&
//             !task.start_date &&
//             !task.group_id &&
//             !task.owner_id
//           )
//         )
//           return;
//       }

//       if (filters.mainFilter === "DONE") {
//         if (!task.done_date || task.deletion_date) return;
//       }

//       if (filters.mainFilter === "IN_PROGRESS") {
//         if (!task.timer_start) return;
//       }

//       if (filters.mainFilter === "UPCOMING") {
//         if (
//           !active ||
//           !task.start_date ||
//           !FormatDate.isDueFromTomorrow(task.start_date)
//         )
//           return;
//       }

//       if (filters.mainFilter === "TODAY") {
//         if (!active || !FormatDate.isDueUntilToday(task.start_date)) return;
//       }

//       if (filters.mainFilter === "TRASH") {
//         if (!task.deletion_date) return;
//       }
//     }

//     tags = tags.concat(task.tags);

//     // Permitir filtros por tags após a seleção do Main Filter
//     filteredIdsbeforeTags.push(taskId);

//     // if (filters.tags.tags.length !== 0) {
//     //   if (!tagFilter(task.tags, filters.tags.tags)) return;
//     // }

//     filteredIds.push(taskId);

//     return null;
//   });

//   // Ordeno o retorno
//   let orderedTasks = orderTasks(tasks, filteredIds, order);

//   orderedTasks.forEach((taskId) => {
//     filteredTasks["all"].push(taskId);

//     task = tasks[taskId];
//     if (task.pinned) {
//       filteredTasks["pinned"].push(taskId);
//     } else {
//       if (task.group_id) {
//         filteredTasks["grouped"][task.group_id].push(taskId);
//       } else {
//         filteredTasks["ungrouped"].push(taskId);
//       }
//     }
//   });

//   STORE.dispatch(
//     ACTIONS.tasks.setFilteredTasksIds({
//       beforeTags: filteredIdsbeforeTags,
//       afterTags: filteredTasks["all"]
//     })
//   );

//   // Removo grupos vazios
//   // Object.keys(filteredTasks["grouped"]).map(groupId => {
//   //   if (filteredTasks["grouped"][groupId].length === 0) {
//   //     delete filteredTasks["grouped"][groupId];
//   //   }
//   // });

//   return filteredTasks;
// };
