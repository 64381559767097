import React, { useEffect, useRef } from "react";

import { Editor, Range } from "slate";
import { ReactEditor, useSlate } from "slate-react";

import { Flex, FlexTransitionOpacity, Icon } from "tomato";

import { Emoji } from "../Helpers/Emoji";
import { isFormatActive, Portal, toggleBlock, toggleMark } from "../util";

const HoverElement = ({ format, icon }) => {
  const editor = useSlate();

  const RenderIcon = Icon[icon];

  return (
    <Flex
      mx="0.25rem"
      style={{ cursor: "pointer" }}
      borderRadius="0.375rem"
      p="0.125rem"
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <RenderIcon stroke="white" style={{ strokeWidth: "2px" }} width="1rem" />
    </Flex>
  );
};
const HoverLeaf = ({ format, icon }) => {
  const editor = useSlate();

  const RenderIcon = Icon[icon];

  let active = isFormatActive(editor, format);

  return (
    <Flex
      mx="0.25rem"
      style={{ cursor: "pointer" }}
      borderRadius="0.375rem"
      p="0.125rem"
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <RenderIcon
        stroke={active ? "white" : "#707070"}
        style={{ strokeWidth: "2px" }}
        width="1rem"
      />
    </Flex>
  );
};

export const HoveringToolbar = () => {
  const ref = useRef();
  const editor = useSlate();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);

    const rect = domRange.getBoundingClientRect();

    el.style.opacity = "1";
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  return (
    <Portal>
      <FlexTransitionOpacity
        position="absolute"
        ref={ref}
        bg="#222"
        zIndex="1"
        borderRadius="0.375rem"
        top="-10000px"
        left="-10000px"
        marginTop="-0.5rem"
        opacity="0"
        style={{
          transition: "opacity 0.75s"
        }}
        px="0.5rem"
        py="0.375rem"
      >
        <HoverLeaf icon="Bold" format="bold" />
        <HoverLeaf icon="Italic" format="italic" />
        <HoverLeaf icon="Underline" format="underline" />
        <HoverLeaf icon="Strike" format="strike" />
        <HoverLeaf icon="Code" format="code" />

        {false && (
          <>
            <Flex width="1rem" />
            <Emoji editor={editor} />
            <HoverElement icon="Smile" format="emoji" />
          </>
        )}
      </FlexTransitionOpacity>
    </Portal>
  );
};

// <HoverItem icon="List" format="list" />
