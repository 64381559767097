import React from "react";

import { Flex } from "tomato";

export const Clear = () => {
  const clear = () => {
    localStorage.clear();
    window.location = "/";
  };

  const load = () => {
    window.location = "/";
  };

  return (
    <Flex
      width={1}
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        style={{ cursor: "pointer" }}
        onClick={clear}
        px="1rem"
        py="0.5rem"
        bg="lightRed"
        borderRadius="0.25rem"
      >
        Clique aqui para limpar seu local storage
      </Flex>

      <Flex
        mt="2rem"
        style={{ cursor: "pointer" }}
        onClick={load}
        px="1rem"
        py="0.5rem"
        bg="lightGreen"
        borderRadius="0.25rem"
      >
        Clique aqui para voltar ao elefante sem fazer nada
      </Flex>
    </Flex>
  );
};
