import { createActions, handleActions } from "redux-actions";

// import { orderObjects } from "util/order";

const modelId = "topics";

const options = { prefix: "topics", namespace: "::" };

export const actions = createActions(
  {
    ADD_REPLY: (response) => ({
      module: modelId,
      response
    }),

    ADD_TOPIC: (response) => ({
      module: modelId,
      response
    }),

    CLEAN_TOPIC: () => ({
      module: modelId
    }),

    DELETE_TOPIC: (response) => ({
      module: modelId,
      response
    }),

    LOAD_TOPIC: (response) => ({
      module: modelId,
      response
    }),

    LOAD_TOPICS: (response) => ({
      module: modelId,
      response
    }),

    UPDATE_REACTIONS: (replyId, reactions) => ({
      module: modelId,
      replyId,
      reactions
    }),

    UPDATE_TOPIC: (response) => ({
      module: modelId,
      response
    })
  },
  options
);

export const reducer = handleActions(
  {
    ADD_REPLY: (state, { payload: { response } }) => {
      state.topic.replies[response.id] = response;
      state.topics[state.topic.id].update_date = response.creation_date;
      return state;
    },

    ADD_TOPIC: (state, { payload: { response } }) => {
      state.topics[response.id] = response;
      return state;
    },

    CLEAN_TOPIC: (state) => {
      state.topic = null;
      return state;
    },

    DELETE_TOPIC: (state, { payload: { response } }) => {
      delete state.topics[response.id];
      // state.orderedtopics = orderObjects(state.topics, "update_date", "desc");
      return state;
    },

    LOAD_TOPIC: (state, { payload: { response } }) => {
      state.topic = response;
      state.topics[response.id] = response;
      return state;
    },

    LOAD_TOPICS: (state, { payload: { response } }) => {
      state.topics = response;
      // state.orderedtopics = orderObjects(response, "update_date", "desc");
      return state;
    },

    UPDATE_REACTIONS: (state, { payload: { replyId, reactions } }) => {
      if (replyId) {
        state.topic.replies[replyId].reactions = reactions;
      } else state.topic.reactions = reactions;
      return state;
    },

    UPDATE_TOPIC: (state, { payload: { response } }) => {
      state.topic = response;
      state.topics[response.id] = response;
      return state;
    }
  },
  {},
  options
);
