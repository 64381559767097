import React, { useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Action,
  Button,
  Column,
  EmojiPicker,
  Flex,
  Image,
  Input,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { t } from "i18n";

const QUICK_STATUS = [
  {
    away: true,
    do_not_disturb: false,
    message: t("Lunch"),
    label: t("Lunch"),
    emojiCode: "1f37d"
  },
  {
    away: false,
    do_not_disturb: false,
    message: t("Call"),
    label: t("Call"),
    emojiCode: "260e"
  }
];

export const Presence = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);
  const preferences = useSelector((state) => state["account"].preferences);
  const ws = useSelector((state) => state["ws"].ws);

  const [showPicker, setShowPicker] = useState(true);

  const save = (message) => {
    const status = {
      ...user.preferences.status,
      ...message,
      expireDate: new Date()
    };

    ws &&
      ws.send(
        JSON.stringify({
          type: "spaces",
          action: "setStatus",
          data: { status }
        })
      );

    dispatch(ACTIONS.account.setPreference("status", status));

    dispatch(
      ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
        preferences: { ...preferences, status }
      })
    );
  };

  const status = user.preferences.status;

  let color = status.away
    ? "lightOrange"
    : status.do_not_disturb
    ? "red"
    : status.message
    ? "lightRed"
    : "lightGreen";

  let message = status.message
    ? status.message
    : status.away
    ? t("Away")
    : t("Free");

  return (
    <Tippy
      interactive={true}
      offset={[0, 4]}
      render={(attrs) => (
        <Column
          bg="l0"
          borderRadius="0.5rem"
          p="1.5rem"
          boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
        >
          <Text textStyle="subtitle">Status</Text>
          <Flex alignItems="flex-start" mt="1rem">
            <Text textStyle="subsubtitle" mt="0.5rem" width="5rem">
              {t("Doing")}
            </Text>

            <Column ml="1rem">
              <Flex alignItems="center">
                {showPicker || !preferences.status.emojiCode ? (
                  <EmojiPicker
                    ml="0.5rem"
                    opened={true}
                    onSelect={(emojiCode) => {
                      save({ emojiCode });
                      setShowPicker(false);
                    }}
                  />
                ) : (
                  <Image
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPicker(true)}
                    width="2rem"
                    src={`https://static.elefante.com/emojis/${preferences.status.emojiCode}.png`}
                  />
                )}

                <Input
                  ml="0.5rem"
                  minWidth="18rem"
                  maxWidth="20rem"
                  placeholder={"conta para gente aqui..."}
                  focus={true}
                  onEnter={(message) => save({ message })}
                  clearOnEnter={true}
                />
              </Flex>
              <Flex mt="0.25rem">
                {QUICK_STATUS.map((status, index) => (
                  <Action key={index} onClick={() => save(status)}>
                    <Image
                      width="1rem"
                      mr="0.25rem"
                      src={`https://static.elefante.com/emojis/${status.emojiCode}.png`}
                    />
                    {status.label}
                  </Action>
                ))}
              </Flex>
            </Column>
          </Flex>
          <Flex mt="2rem" alignItems="center">
            <Text textStyle="subsubtitle" width="5rem">
              {t("Status")}
            </Text>
            <Button
              style={{ marginLeft: "1rem" }}
              variant={
                !preferences.status.message &&
                !preferences.status.away &&
                !preferences.status.do_not_disturb
                  ? "actionEnabled"
                  : "actionDisabled"
              }
              value={t("Free")}
              onClick={() =>
                save({
                  away: false,
                  do_not_disturb: false,
                  message: "",
                  emojiCode: ""
                })
              }
            />
            <Button
              style={{ marginLeft: "0.5rem" }}
              variant={
                preferences.status.away ? "actionEnabled" : "actionDisabled"
              }
              value={t("Away")}
              onClick={() =>
                save({ away: !preferences.status.away, do_not_disturb: false })
              }
            />

            {preferences.status.message && (
              <Button
                style={{ marginLeft: "0.5rem" }}
                variant={
                  preferences.status.do_not_disturb
                    ? "actionEnabled"
                    : "actionDisabled"
                }
                value={t("Do not disturb")}
                onClick={() => {
                  save({
                    away: false,
                    do_not_disturb: !preferences.status.do_not_disturb
                  });
                }}
              />
            )}
          </Flex>
        </Column>
      )}
    >
      <Flex
        alignItems="center"
        height="2rem"
        px="0.5rem"
        py="0.25rem"
        bg={color}
        width="9rem"
        justifyContent="center"
        borderRadius="0.25rem"
      >
        {status && status.emojiCode && (
          <Image
            width="1.25rem"
            mr="0.5rem"
            src={`https://static.elefante.com/emojis/${status.emojiCode}.png`}
          />
        )}
        <Text
          fontSize="0.875rem"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            cursor: "pointer"
          }}
        >
          {message}
        </Text>
      </Flex>
    </Tippy>
  );
};
