import styled from "styled-components";
import { flexbox, variant } from "styled-system";

import { Box } from "../Box";

let variants = variant({
  variants: {
    header: {
      width: "100%",
      minHeight: "4em",
      pt: "2em",
      pb: "2em"
    }
  }
});

export const Flex = styled(Box)`
  box-sizing: border-box;
  ${flexbox}
  ${variants}
`;

Flex.defaultProps = {
  display: "flex"
};

export const Column = styled(Flex)`
    flexDirection: column;
`
Column.defaultProps = {
  flexDirection: "column"
};
 

export const Action = styled(Flex)`
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  width: fit-content;
  height: fit-content;
  align-items: center;
  // background: #9666ff;
  ${(props) =>
    `
	  color: ${props.theme.colors["user"]};
      // background: ${props.theme.colors["userActive"]};
      &:hover {
        background-color: ${props.theme.colors["userBold"]};
      }
    `}
`;
Action.defaultProps = {
  fontSize: "0.875rem",
  paddingTop: "0.25rem",
  paddingBottom: "0.25rem",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem"
};

export const FlexAction = styled(Flex)`
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  hovercolor: userLight;
  transition: all 150ms ease-in-out;
  width: fit-content;
  font-size: 0.875rem;
  ${(props) =>
    `
      background-color: ${
        props.bg ? props.theme.colors[props.bg] : props.theme.colors["l2"]
      }};
      &:hover {
        background-color: ${props.theme.colors["userLight"]};
      }
    `}
`;

export const RoundedAction = styled(Flex)`
  border-radius: 100rem;
  cursor: pointer;
  hovercolor: userLight;
  transition: all 150ms ease-in-out;
  font-size: 0.875rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  ${(props) =>
    `
      background-color: ${
        props.bg ? props.theme.colors[props.bg] : "transparent"
      }};
      &:hover {
        background-color: ${props.theme.colors["userLight"]};
      }
    `}
`;

export const FlexTransitionOpacity = styled(Flex)`
  transition: opacity 750ms;
`;

export const FlexHover = styled(Flex)`
  // transition: all 350ms ease-in-out;
`;

export const FlexWrapper = styled(Flex)`
  // transition: all 350ms ease-in-out;
`;

export const FlexHidden = styled(Flex)`
  visibility: hidden;
  opacity: 0;
  // transition: all 350ms ease-in-out;
  ${FlexWrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;
