import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dropdown,
  Flex,
  Input,
  Overlay,
  Row,
  SimpleEditor,
  Switch,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";

const Where = ({ selectedSpaceId, setSelectedSpaceId }) => {
  const { t } = useTranslation();

  let spaces = useSelector((state) => state["spaces"].spaces);

  let spacesOptions = Object.keys(spaces).map((spaceId) => {
    return { id: spaceId, value: spaces[spaceId].name };
  });

  return (
    <Row label={t("Space")} mt="1rem">
      <Flex flexDirection="column" width={1}>
        <Dropdown
          key={selectedSpaceId}
          options={spacesOptions}
          onSelect={(option) => {
            setSelectedSpaceId(parseInt(option.id));
          }}
          placeholder={t("Choose space")}
          selected={
            selectedSpaceId
              ? {
                  id: selectedSpaceId,
                  value: spaces[selectedSpaceId].name
                }
              : null
          }
        />
      </Flex>
    </Row>
  );
};

export const RenderAddTask = ({ list, tasksIds, onBlur, setShowAddTask }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [publicTemplate, setPublicTemplate] = useState(false);
  const [selectedSpaceId, setSelectedSpaceId] = useState();
  const [name, setName] = useState(list.name);
  const [description, setDescription] = useState([]);
  console.log(tasksIds);
  useEffect(() => {
    if (list.id) {
      setSelectedSpaceId(projects[lists[list.id].project_id].space_id);
    }
  }, [list, lists, projects, spaces]);

  const addTemplate = () => {
    const template = {
      name,
      description,
      public: publicTemplate,
      space_id: selectedSpaceId,
      tasks_ids: tasksIds
    };

    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ENDPOINT}/template`,
        [
          ACTIONS.state.loadObjects,
          ACTIONS.toast.addToast({
            message: t("Created!"),
            type: "success"
          })
        ],
        template
      )
    );
    setShowAddTask(false);
  };

  return (
    <Flex
      mx="0.25rem"
      flexDirection="column"
      width={1}
      bg="l0"
      p="1.5rem"
      borderRadius="0.5rem"
      boxShadow="2px 3px 6px -2px rgba(0,0,0,0.80)"
    >
      <Text textStyle="subtitle" mb="1.5rem">
        {t("New template") + " " + t("from") + " " + list.name}
      </Text>

      <Row label={t("Name")}>
        <Input
          placeholder={t("Add name")}
          onChange={setName}
          focus={true}
          maxLength={80}
          width={1}
          value={list.name}
          showMaxLength={true}
          clearOnEnter={true}
        />
      </Row>

      <Row mt="2rem" label={t("Visibility")}>
        <Switch
          label={t("Public")}
          checked={publicTemplate}
          onCheck={setPublicTemplate}
        />
      </Row>

      <Flex flexDirection="column" overflow="auto" mt="1rem">
        {!publicTemplate && (
          <Where
            selectedSpaceId={selectedSpaceId}
            setSelectedSpaceId={setSelectedSpaceId}
          />
        )}

        <Row mt="2rem" label={t("Description")}>
          <SimpleEditor
            autoFocus={false}
            placeholder={t("Write description")}
            height={100}
            enableToolbar={true}
            lineHeight={1.5}
            onSave={setDescription}
            onBlur={setDescription}
            enabled={true}
            plainText={true}
          />
        </Row>

        <Flex mt="2rem" alignItems="center" justifyContent="flex-end">
          <Button
            variant="secondary"
            style={{ marginRight: "0.5rem" }}
            onClick={() => setShowAddTask(false)}
            value={t("Cancel")}
          />

          <Button
            variant="primary"
            style={{ marginLeft: "0.5rem" }}
            disabled={!name}
            onClick={addTemplate}
            value={t("Save and exit")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const AddTemplate = ({ tasksIds, list, open, setOpen, onBlur }) => {
  return (
    <Overlay open={open} setOpen={setOpen}>
      <Flex width="50rem">
        <RenderAddTask
          list={list}
          showAddTask={open}
          setShowAddTask={setOpen}
          onBlur={onBlur}
          tasksIds={tasksIds}
        />
      </Flex>
    </Overlay>
  );
};

export default AddTemplate;
