import { ajax } from "rxjs/ajax";
import {
  catchError,
  map,
  mergeMap,
  pluck,
  tap,
  withLatestFrom
} from "rxjs/operators";

import { ACTIONS } from "../reducers/actions";

let defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

export let fetchEpic = (action$, state$, { dispatch }) => {
  return action$
    .ofType(
      "fetch::GET",
      "fetch::POST",
      "fetch::PUT",
      "fetch::PATCH",
      "fetch::DELETE",
      "graphql::MUTATION",
      "graphql::QUERY"
    )
    .pipe(
      tap(() => {
        dispatch(ACTIONS.network.start());
      }),

      withLatestFrom(state$),

      mergeMap(([action]) =>
        ajax({
          url: action.payload.url,
          crossDomain: true,
          withCredentials: action.payload.withCredentials,
          method: action.payload.method,
          headers: defaultHeaders,
          body: action.payload.body
        }).pipe(
          pluck("response"),

          map((response) => {
            if ("success" in response && "message" in response) {
              dispatch(
                ACTIONS.toast.addToast({
                  message: response.message,
                  type: response.success ? "success" : "error"
                })
              );
            } else if (action.type === "graphql::QUERY") {
              dispatch(
                ACTIONS.graphql.set(
                  response,
                  action.payload.module,
                  action.payload.action,
                  action.payload.operation,
                  action.payload.operationType
                )
              );
            }

            let nextAction = [];

            if (!Array.isArray(action.payload.nextAction)) {
              nextAction.push(action.payload.nextAction);
            } else {
              nextAction = action.payload.nextAction;
            }

            nextAction.forEach((next) => {
              if (typeof next === "function") {
                dispatch(
                  next(response, action.payload.module, action.payload.custom)
                );
              } else {
                next.payload.response = response;
                dispatch(next);
              }
            });

            return ACTIONS.network.end();
          }),

          catchError((error) => {
            console.log(error);
            let message = `${error.status} url: ${error.request}`;

            const response = error.response;
            if (
              error.responseType === "json" &&
              response &&
              "success" in response
            )
              message = response.message;

            if (error.status === 401) {
              dispatch(ACTIONS.network.addPendingAction(action));
              dispatch(ACTIONS.account.reAuth());
              return [];
            }

            if (error.status === 403) {
              message = "You don't have authorization to access this resource";
            } else if (error.status === 0) {
              dispatch(ACTIONS.modal.open("reConnect"));
              dispatch(ACTIONS.network.addPendingAction({ action: action }));
              return [];
            }

            dispatch(
              ACTIONS.toast.addToast({
                message,
                type: "error",
                autoClose: 2000
              })
            );

            dispatch(ACTIONS.network.end());
            return [];
          })
        )
      )
    );
};
