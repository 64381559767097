import { createGlobalStyle } from "styled-components";

import { UI } from "../../constants";

export const GlobalStyle = createGlobalStyle`

    @media screen and (max-width: 350px) {
      .three-col .column {
        max-width: 100% !important;
      }
    }
    @media screen and (min-width: 351px) and (max-width: 460px) {
      .three-col .column {
        max-width: 50% !important;
      }
    }
    @media screen and (max-width: 460px) {
      .two-col .column,
      .two-col img {
        max-width: 100% !important;
      }
    }
    @media screen and (min-width: 461px) {
      .three-col .column {
        max-width: 33.3% !important;
      }
      .two-col .column {
        max-width: 50% !important;
      }
      .sidebar .small {
        max-width: 16% !important;
      }
      .sidebar .large {
        max-width: 84% !important;
      }
    }

  body { 

    font-family: ${UI.FONT_FAMILY};
    font-weight: ${UI.FONT_WEIGHT};
    font-size: ${UI.FONT_SIZE};
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;       

    height: 100vh;
    margin: 0;

  }

  p { margin: 0px 0px }

  body > #app  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }
`;

export default GlobalStyle;
