import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, FlexAction, Spinner, Text } from "tomato";

import { ACTIONS } from "store";
import { TASKS } from "crud";

export const ParentTask = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const tasks = useSelector((state) => state["tasks"].objs);

  useEffect(() => {
    // if (task.parent_id && !tasks[task.parent_id]) {
    //   dispatch(
    //     ACTIONS.state.setAttribute("tasks", "loadingObjId", task.parent_id)
    //   );
    //   TASKS.getOne(task.parent_id);
    // }
  }, [dispatch, task, tasks]);

  const openTask = () => {
    // const to = `/space/${
    //   projects[lists[tasks[task.parent_id].list_id].project_id].space_id
    // }/tasks/${task.parent_id}`;
    // history.push(to);
    // dispatch(ACTIONS.state.setAttribute("tasks", "task", null));
    dispatch(
      ACTIONS.state.setAttribute("tasks", "loadingObjId", task.parent_id)
    );

    TASKS.getOne(task.parent_id);
  };

  let parentTask = tasks[task.parent_id];

  return null;
  if (!task.parent_id) return null;
  if (!parentTask) return <Spinner />;

  return (
    <Flex alignItems="center" mt="2rem">
      <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
        {t("Subtask of")}
      </Text>

      <FlexAction onClick={openTask}>{parentTask.subject}</FlexAction>
    </Flex>
  );
};
