const DEFAULT_GRID_HEIGHT = 32;
const DEFAULT_COLUMN_SIZE = 100;

const SIZE = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "large"
};

const MULTIPLIER = {
  Small: 1,
  Medium: 1.25,
  Large: 1.5
};

const DEFAULT_GRID_SIZE = SIZE.MEDIUM;

const PAGINATION_TYPE = {
  INFINITE: "Infinite scroll",
  PAGINATION: "Pagination"
};
const DEFAULT_PAGINATION_TYPE = PAGINATION_TYPE.PAGINATION;
const DEFAULT_LINES_PER_PAGE = 25;

export const GRID = {
  DEFAULT_GRID_HEIGHT,
  DEFAULT_COLUMN_SIZE,
  DEFAULT_GRID_SIZE,
  SIZE,
  MULTIPLIER,
  PAGINATION_TYPE,
  DEFAULT_PAGINATION_TYPE,
  DEFAULT_LINES_PER_PAGE
};
