import React from "react";
import {
  AreaChart,
  Area,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const COLORS = [
  "#0088FE",
  "#e5f3ff",
  "#FFBB28",
  "#FF8042",
  "#1f77b4", // muted blue
  "#ff7f0e", // safety orange
  "#2ca02c", // cooked asparagus green
  "#d62728", // brick red
  "#9467bd", // muted purple
  "#8c564b", // chestnut brown
  "#e377c2", // raspberry yogurt pink
  "#7f7f7f", // middle gray
  "#bcbd22", // curry yellow-green
  "#17becf"
];

const ChartArea = ({ data, legend = false, tooltip = true, height = 200 }) => {
  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={data.data}>
        {tooltip && <Tooltip />}
        {legend && <Legend />}

        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.0} />
          </linearGradient>
        </defs>
        <YAxis type="number" />
        <XAxis dataKey={data.key} type="category" />

        {data.categories.map((category, index) => {
          return (
            <Area
              key={index}
              type="monotone"
              dataKey={category}
              stroke="#8884d8"
              fillOpacity={1}
              fill={COLORS[index % COLORS.length]}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export { ChartArea as AreaChart };
