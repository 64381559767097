import { t } from "i18n";

export const ROUTES = {
  page: {
    moduleId: "pages",
    path: "/wiki",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Pages",
    adminOnly: false
  },
  zen: {
    moduleId: "zen",
    path: "/zen",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Zen",
    adminOnly: false
  },
  zenList: {
    moduleId: "zen",
    path: "/zen/:listId",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Zen",
    adminOnly: false
  },
  calendar: {
    moduleId: "calendar",
    path: "/calendar",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Calendar",
    adminOnly: false
  },
  document: {
    moduleId: "documents",
    path: "/files/documents/:formUUID",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Documents",
    adminOnly: false
  },
  renderForm: {
    moduleId: "forms",
    path: "/files/forms/:formUUID",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Forms",
    adminOnly: false
  },
  form: {
    moduleId: "forms",
    path: "/files/forms",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Forms",
    adminOnly: false
  },
  dev: {
    moduleId: "dev",
    path: "/dev",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Dev",
    adminOnly: false
  },
  reset: {
    moduleId: "clear",
    path: "/clear",
    exact: true,
    layout: "Clean",
    level: 0,
    view: "Clear",
    adminOnly: false
  },
  admin: {
    moduleId: "dashboard",
    path: "/saas",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Dashboard",
    adminOnly: true
  },
  files: {
    moduleId: "files",
    path: "/files",
    exact: true,
    label: t("files"),
    layout: "Default",
    level: 0,
    view: "Files",
    adminOnly: false
  },
  updates: {
    moduleId: "updates",
    path: "/updates",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Updates",
    adminOnly: false
  },
  conversations: {
    moduleId: "conversations",
    path: "/conversations",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Conversations",
    adminOnly: true
  },
  dashboard: {
    moduleId: "admin",
    path: "/admin",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Admin",
    adminOnly: true
  },
  profile: {
    moduleId: "profile",
    path: "/profile",
    exact: true,
    layout: "Default",
    level: 0,
    view: "Profile",
    adminOnly: false
  },
  home: {
    moduleId: "root",
    path: "/",
    exact: true,
    label: t("spaces"),
    layout: "Default",
    level: 0,
    view: "Welcome",
    adminOnly: false
  },
  spaces: {
    moduleId: "spaces",
    path: "/space",
    exact: true,
    label: t(""),
    layout: "Default",
    level: 1,
    view: "Spaces",
    adminOnly: false
  },
  space: {
    moduleId: "space",
    path: "/space/:spaceId",
    exact: true,
    label: t("spaces"),
    layout: "Default",
    level: 1,
    view: "Spaces",
    adminOnly: false
  },
  reports: {
    moduleId: "reports",
    path: "/reports",
    exact: false,
    label: t("Reports"),
    layout: "Clean",
    level: 2,
    view: "Reports",
    adminOnly: false
  },
  tasks: {
    moduleId: "tasks",
    path: "/tasks",
    exact: false,
    label: t("tasks"),
    layout: "Default",
    level: 2,
    view: "Tasks",
    adminOnly: false
  },
  topic: {
    moduleId: "topic",
    path: "/topic*",
    exact: true,
    label: t("messages"),
    layout: "Default",
    level: 3,
    view: "Topics",
    adminOnly: false
  },
  topics: {
    moduleId: "topics",
    path: "/topici",
    exact: true,
    label: t("messages"),
    layout: "Default",
    level: 2,
    view: "Topics",
    adminOnly: false
  },
  virtualRoom: {
    moduleId: "virtualroom",
    path: "/people",
    exact: true,
    layout: "Default",
    level: 0,
    view: "VirtualRoom",
    adminOnly: true
  }
};

// let SYSTEM_ROUTES = [home, space, spaces, task, tasks, topic, topics];
