import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, FormatDate, Icon, Text, useInterval } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

const Time = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let task = useSelector((state) => state["tasks"].obj);
  let user = useSelector((state) => state["account"].user);
  const ws = useSelector((state) => state["ws"].ws);

  const [time, setTime] = useState("0:00:00");

  const calcTimer = () => {
    if (!task) return;

    let totalSeconds = task.time_spent || 0;

    if (task.timer_start) {
      totalSeconds = FormatDate.Difference(task.timer_start) + totalSeconds;
    }

    let hours = parseInt(totalSeconds / 3600);
    totalSeconds = totalSeconds % 3600;

    let minutes = parseInt(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;

    let seconds = parseInt(totalSeconds);

    hours = ("0" + hours).slice(-2);
    minutes = ("0" + minutes).slice(-2);
    seconds = ("0" + seconds).slice(-2);

    setTime(`${hours}:${minutes}:${seconds}`);
  };

  useEffect(() => {
    calcTimer();
  });

  useInterval(calcTimer);

  const startTimer = () => {
    let status = {
      emojiCode: "1f680",
      message: task.subject,
      clearId: 1
    };
    ws.send(
      JSON.stringify({
        type: "spaces",
        action: "setStatus",
        data: {
          status
        }
      })
    );
    dispatch(ACTIONS.account.setPreference("status", status));

    dispatch(ACTIONS.events.send("Task started", task));
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/start_timer`,
        ACTIONS.tasks.updateTimer
      )
    );
  };

  const pauseTimer = () => {
    let status = {
      emojiCode: null,
      message: null,
      clearId: null
    };
    ws.send(
      JSON.stringify({
        type: "spaces",
        action: "setStatus",
        data: {
          status
        }
      })
    );
    dispatch(ACTIONS.account.setPreference("status", status));

    dispatch(ACTIONS.events.send("Task paused", task));
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/pause_timer`,
        ACTIONS.tasks.updateTimer
      )
    );
  };

  return (
    <Flex alignItems="center" mt="2rem" minHeight={30}>
      <Text color="t2" fontWeight="semibold" fontSize="0.9375rem" width={140}>
        {t("Time spent")}
      </Text>

      {task.done_date && (
        <Text fontSize="18px" fontWeight="bold" color="t1">
          {time}
        </Text>
      )}

      {!task.done_date && (
        <>
          <Flex alignItems="center">
            {(task.owner_id === user.id || !task.owner_id) && (
              <>
                {!task.timer_start && (
                  <Flex
                    height={30}
                    width={30}
                    borderRadius="50%"
                    bg="green"
                    alignItems="center"
                    justifyContent="center"
                    pl="1px"
                    style={{ cursor: "pointer" }}
                    onClick={startTimer}
                    mr={2}
                  >
                    <Icon.Play width={16} height={16} m="auto" stroke="white" />
                  </Flex>
                )}

                {task.timer_start && (
                  <Flex
                    width={30}
                    height={30}
                    borderRadius="50%"
                    bg="orange"
                    alignItems="center"
                    justifyContent="center"
                    style={{ cursor: "pointer" }}
                    onClick={pauseTimer}
                    mr={2}
                  >
                    <Icon.Pause
                      width={16}
                      height={16}
                      m="auto"
                      stroke="white"
                    />
                  </Flex>
                )}
              </>
            )}

            <Text fontSize="18px" fontWeight="bold" color="t1">
              {time}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default Time;
