import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, SimpleEditor, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, API_ROOT, ROOT_URL } from "config";

const Description = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);

  const [enabled, setEnabled] = useState(false);

  const saveDescription = (description) => {
    setEnabled(false);
    if (description === task.description) return;
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { description }
      )
    );
  };

  let style = { style: {} };
  if (!enabled) style.style.cursor = "pointer";

  return (
    <Flex
      my="2rem"
      onClick={() => !enabled && setEnabled(!enabled)}
      {...style}
      height="100%"
    >
      <Text
        color="t2"
        fontWeight="semibold"
        fontSize="0.9375rem"
        width={140}
        minWidth={140}
      >
        {t("Description")}
      </Text>

      <SimpleEditor
        key={task.id}
        autoFocus={false}
        placeholder={t("Write description")}
        value={task.description}
        minHeight={100}
        enableToolbar={true}
        lineHeight={1.5}
        onSave={saveDescription}
        onBlur={saveDescription}
        enabled={enabled}
        fileRootUrl={`${ROOT_URL}/shared/`}
        uploadEndpoint={`${API_ENDPOINT}/upload`}
        uploadId={{ module: "TASK", task_id: task.id }}
      />
    </Flex>
  );
};

export default Description;
