import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Image, Text } from "tomato";

import { ACTIONS } from "store";
import { Header } from "./Header";
import { Input } from "./Input";
import { Messages } from "./Messages";

export const Conversations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );
  const activeThread = useSelector(
    (state) => state["conversations"].activeThread
  );

  // useEffect(() => {
  //   dispatch(ACTIONS.events.send("Conversations view", {}));
  // }, [dispatch]);

  if (!activeConversation)
    return (
      <Flex
        width={1}
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Image width="16rem" src="images/work/looking.svg" />
        <Text fontSize="2rem" mt="1rem" fontWeight="semibold">
          {t("Choose someone to talk first!")}
        </Text>
      </Flex>
    );

  let prStyle = {};
  // if (activeThread) prStyle = { pr: "0px" };

  return (
    <>
      <Header />

      <Flex
        flexGrow="1"
        flexShrink="1"
        overflow="auto"
        p="0px"
        alignItems="flex-end"
        {...prStyle}
      >
        <Messages />
      </Flex>

      <Input />
    </>
  );
};
