import React, { useState } from "react";
import { useSelected, useFocused } from "slate-react";

import { Flex, FlexHidden, FlexWrapper, Icon } from "tomato";
import { FilesPreview } from "../../FilesPreview";
import { Image as Img } from "../../Image";
import { Spinner } from "../../Spinner";
import { Text } from "../../Text";

export const File = ({ attributes, children, element, fileRootUrl }) => {
  const selected = useSelected();
  const focused = useFocused();

  const [previewIndex, setPreviewIndex] = useState();

  let files = [
    {
      mime_type: element.mimeType,
      filename: element.url,
      name: element.name,
      size: element.size
    }
  ];

  let type = "file";
  if (element.mimeType.split("/")[0] === "image") type = "image";

  const extension = () => {
    if (!element) return "";
    let txt = element.name.split(".");
    return txt[txt.length - 1].slice(0, 4);
  };

  return (
    <Flex
      {...attributes}
      flexDirection="column"
      justifyContent="flex-start"
      position="relative"
      // width="fit-content"
    >
      <FilesPreview
        rootUrl={fileRootUrl}
        files={files}
        setPreviewIndex={setPreviewIndex}
        previewIndex={previewIndex}
      />

      <div contentEditable={false}>
        {false && (
          <Text color="t3" fontSize="0.825rem" mb="0.5rem">
            {element.name}
          </Text>
        )}

        <FlexWrapper position="relative">
          {!element.loading && (
            <>
              <FlexHidden
                position="absolute"
                top="-1.5rem"
                right="-2rem"
                border="1px solid lightGrey"
                bg="l0"
                borderRadius="0.25rem"
                p="0.5rem"
                px="1rem"
                alignItems="center"
              >
                <Flex
                  style={{ cursor: "pointer" }}
                  mr="1rem"
                  onClick={() => setPreviewIndex(0)}
                >
                  <Icon.ZoomIn style={{ strokeWidth: "2px" }} />
                </Flex>

                <a
                  download={element.name}
                  href={fileRootUrl + element.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Flex style={{ cursor: "pointer" }}>
                    <Icon.Download style={{ strokeWidth: "2px" }} />
                  </Flex>
                </a>
              </FlexHidden>{" "}
            </>
          )}

          {type === "image" ? (
            <Img
              borderRadius="0.25rem"
              mx='auto'
              minHeight="2rem"
              minWidth="2rem"
              maxHeight="30rem"
              maxWidth="40rem"
              onClick={() => {
                if (element.loading) return;
                setPreviewIndex(0);
              }}
              src={element.loading ? element.url : fileRootUrl + element.url}
              style={{
                cursor: "zoom-in",
                boxShadow: `${
                  selected && focused ? "0 0 0 3px #B4D5FF" : "none"
                }`
              }}
            />
          ) : (
            <Flex
              borderRadius="0.25rem"
              border="1px solid lightGrey"
              attributes={attributes}
              element={element}
              style={{ cursor: "zoom-in" }}
              // width="fit-content"
              px="0.5rem"
              alignItems="center"
              my="1rem"
            >
              <Text
                borderRight="1px solid lightGrey"
                mr="0.5rem"
                px="0.5rem"
                fontWeight="semibold"
                color="t2"
              >
                {extension()}
              </Text>

              <Text color="t2" fontSize="0.875rem" fontWeight="semibold">
                {element.name}
              </Text>

              {children}
            </Flex>
          )}
        </FlexWrapper>

        {element.loading && (
          <Spinner position="absolute" bottom="50%" right="50%" />
        )}
      </div>
      {children}
    </Flex>
  );
};
