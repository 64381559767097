import React from "react";

import Icon from "../Icon";

const RenderIcon = props => {
  return (
    <Icon {...props}>
      <path class="st0" d="M16,4h2c1.105,0,2,0.895,2,2v14c0,1.105-0.895,2-2,2H6c-1.105,0-2-0.895-2-2V6c0-1.105,0.895-2,2-2h2"/>
      <path class="st0" d="M9,2h6c0.552,0,1,0.448,1,1v2c0,0.552-0.448,1-1,1H9C8.448,6,8,5.552,8,5V3C8,2.448,8.448,2,9,2z"/>
      <line class="st1" x1="11.875" y1="8.125" x2="11.875" y2="19.583"/>
      <path class="st1" d="M14.479,10.208h-3.906c-1.007,0-1.823,0.816-1.823,1.823s0.816,1.823,1.823,1.823h2.604   c1.007,0,1.823,0.816,1.823,1.823S14.184,17.5,13.177,17.5H8.75"/>
    </Icon>
  );
};

export default RenderIcon;
