import React from "react";
import styled from "styled-components";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Text } from "../Text";

const StyledFlex = styled(Flex)`
  cursor: pointer;
  border-radius: 3px;
  transition: all 150ms ease-in-out;
  &:hover {
    ${props => {
      if (props.enableHover && !props.selected) return `background-color: ${props.theme.colors.blue}`;
    }}
  }
`;

export const Badge = ({ enableHover=false, children, color = "l5", icon, size = "small", ...props }) => {
  let RenderIcon;
  if (icon) RenderIcon = Icon[icon];

  const style = {
    small: { fontSize: "0.5625rem", fontWeight: "bold" },
    medium: { fontSize: "0.875rem" },
    large: { fontSize: "1rem", height: "1.5rem", fontWeight: "bold" }
  };

  const iStyle = {
    small: {
      minHeight: '0.75rem',
      minWidth: '0.75rem',
      width: '0.75rem',
      height: '0.75rem'
    },
    medium: {
      minHeight: "0.875rem",
      minWidth: "0.875rem",
      width: "0.875rem",
      height: "0.875rem"
    },
    large: {
      minHeight: "1rem",
      minWidth: "1rem",
      width: "1rem",
      height: "1rem"
    }
  };

  let textStyle = { ...style[size] };
  textStyle.color = "t1";
  textStyle.style = { textTransform: "uppercase", whiteSpace: "nowrap" };

  let iconStyle = { ...iStyle[size] };

  return (
    <StyledFlex
      alignItems="center"
      px='0.5rem'
      py='0.125rem'
      flexDirection="column"
      justifyContent="center"               
      height='fit-content'
      width='fit-content'
      bg={color}
      enableHover={enableHover}
      {...props}
    >
      <Flex alignItems="center">
        {icon && <RenderIcon mr={1} {...iconStyle} />}
        <Text {...textStyle} color="white">
          {children}
        </Text>
      </Flex>
    </StyledFlex>
  );
};

