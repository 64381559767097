import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { Folders } from "./Folders";
import { NewFolder } from "./Folders";

const Space = ({ space, index }) => {
  const dispatch = useDispatch();

  const folders = useSelector((state) => state["files"].folders[space.id]);
  const filters = useSelector((state) => state["files"].filters);

  const [addFolder, setAddFolder] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);

  return (
    <>
      <Flex
        enableHover={true}
        alignItems="center"
        style={{ cursor: "pointer" }}
        mt={index ? "0.25rem" : ""}
        px="0.5rem"
        py="0.5rem"
        borderRadius="0.25rem"
        bg={filters.spaceId === space.id ? "l3" : ""}
        onClick={(e) => {
          dispatch(ACTIONS.files.setFilter("spaceId", space.id));
          dispatch(ACTIONS.files.setFilter("folderId", null));
        }}
      >
        <Icon.Triangle
          onClick={(e) => {
            e.stopPropagation();
            if (!folders) {
              dispatch(ACTIONS.files.setFilter("spaceId", space.id));
              dispatch(ACTIONS.files.setFilter("folderId", null));
            }
            setOpenFolder(!openFolder);
          }}
          size="8px"
          fill="grey"
          style={{
            transform: openFolder ? "rotate(180deg)" : "rotate(90deg)"
          }}
        />
        {false && (
          <Icon.Folder
            ml="0.25rem"
            style={{
              cursor: "pointer",
              strokeWidth: "0px"
            }}
            fill={filters.spaceId === space.id ? "grey" : "grey"}
          />
        )}

        <Text
          ml="0.5rem"
          fontSize="1rem"
          color={filters.spaceId === space.id ? "t1" : "t4"}
          fontWeight="semibold"
        >
          {space.name}
        </Text>
      </Flex>

      {openFolder && <Folders spaceId={space.id} level={0} />}

      <NewFolder space={space} open={addFolder} setOpen={setAddFolder} />
    </>
  );
};

export const Spaces = () => {
  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);

  return (
    <Flex mx="1.5rem" flexDirection="column" overflow="auto">
      {Object.values(spaces)
        .filter((space) =>
          space.members.some((member) => parseInt(member.id) === user.id)
        )
        .map((space, index) => {
          return <Space key={space.id} space={space} index={index} />;
        })}
    </Flex>
  );
};
