import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

import { LineChart, PieChart } from "components";
import { SmallWidget, LargeWidget } from "./Widgets";

export const Users = () => {
  const { t } = useTranslation();

  const dailyActive = useSelector((state) => state["charts"]["dailyActive"]);
  const monthlyActive = useSelector(
    (state) => state["charts"]["monthlyActive"]
  );
  const total = useSelector((state) => state["charts"]["total"]);

  const [dau, setDau] = useState();
  const [mau, setMau] = useState();
  const [dauMau, setDauMau] = useState();
  const [dauMauData, setDauMauData] = useState();

  useEffect(() => {
    if (!(dailyActive && monthlyActive)) return;
    let dailyCount = dailyActive.last.count;
    let dailyMedium = dailyActive.medium;
    let monthlyCount = monthlyActive.last.count;
    let monthlyMedium = monthlyActive.medium;

    setDau({ count: dailyCount, variation: dailyCount / dailyMedium });
    setMau({ count: monthlyCount, variation: monthlyCount / monthlyMedium });
    setDauMau({
      count: dailyCount / monthlyCount,
      variation: dailyCount / monthlyMedium
    });

    let tmp = JSON.parse(JSON.stringify(dailyActive));
    tmp.data.forEach((day) => {
      day.count = day.count / monthlyCount;
    });
    setDauMauData(tmp);
  }, [dailyActive, monthlyActive]);

  return (
    <>
      <Text fontSize="1rem" fontWeight="semibold" mt="1rem" mb="1rem">
        {t("Users")}
      </Text>

      <Flex width={1} mr="1rem" justifyContent="space-between">
        <SmallWidget
          title={t("Users")}
          value={total && { count: total.users }}
        />

        <SmallWidget mx="2rem" title="DAU" value={dau} position="relative">
          <Flex position="absolute" mt="-1rem" height="8rem" width="6rem">
            <PieChart
              data={
                dau && total && total.users
                  ? [
                      { label: "active", value: dau.count, key: "lablel" },
                      {
                        label: "users",
                        value: total.users - dau.count,
                        key: "lablel"
                      }
                    ]
                  : []
              }
            />
          </Flex>
        </SmallWidget>

        <SmallWidget mr="2rem" title={t("MAU")} value={mau} position="relative">
          <Flex position="absolute" mt="-1rem" height="8rem" width="6rem">
            <PieChart
              data={
                dau && total && total.users
                  ? [
                      { label: "active", value: mau.count, key: "lablel" },
                      {
                        label: "users",
                        value: total.users - mau.count,
                        key: "lablel"
                      }
                    ]
                  : []
              }
            />
          </Flex>
        </SmallWidget>

        <SmallWidget title={t("DAU/MAU")} value={dauMau} position="relative">
          <Flex position="absolute" mt="-1rem" height="8rem" width="6rem">
            <PieChart
              data={
                dau && total && total.users
                  ? [
                      { label: "dau", value: dau.count, key: "lablel" },
                      {
                        label: "mau",
                        value: mau.count - dau.count,
                        key: "lablel"
                      }
                    ]
                  : []
              }
            />
          </Flex>
        </SmallWidget>
      </Flex>

      <Flex mt="2rem">
        <LargeWidget width={1 / 3} title={t("Daily active users")}>
          <LineChart data={dailyActive} />
        </LargeWidget>

        <LargeWidget mx="2rem" width={1 / 3} title={t("Monthly active users")}>
          <LineChart data={monthlyActive} />
        </LargeWidget>

        <LargeWidget width={1 / 3} title={t("Daily/Monthly active users")}>
          <LineChart data={dauMauData} />
        </LargeWidget>
      </Flex>
    </>
  );
};
