import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex, Overlay, Upload } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";

const UploadFiles = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state["files"].filters);

  const endAction = (response) => {
    dispatch(ACTIONS.files.addFiles(response));
    setOpen(false);
  };

  let id = {
    module: "FILE",
    space_id: filters.spaceId,
    root_id: filters.spaceId
  };
  if (filters.folderId) id["folder_id"] = filters.folderId;

  return (
    <Overlay open={open} setOpen={setOpen} bg="l0" borderRadius="0.5rem">
      <Flex width="30rem" height="20rem" p="4rem" alignItems="center">
        <Upload
          maxFiles={5}
          maxFileSize={10}
          endpoint={`${API_ENDPOINT}/upload`}
          id={id}
          setOpen={() => setOpen(!open)}
          endAction={endAction}
        />
      </Flex>
    </Overlay>
  );
};

export { UploadFiles as Upload };
