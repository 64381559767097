import React from "react";

import { Flex, Icon, Text, getShadow } from "tomato";

const Performance = ({ variation }) => {
  let color = "blue";

  if (variation > 1) {
    color = "green";
  } else if (variation > 0) color = "red";

  return (
    <Flex>
      {variation !== 0 && (
        <Icon.Arrow style={{ strokeWidth: "2px" }} stroke={color} />
      )}
      <Text color={color} fontWeight="semibold">
        {variation ? variation.toFixed(1) * 100 + " %" : "-"}
      </Text>
    </Flex>
  );
};

const Value = ({ value }) => {
  return (
    <Text mt="0.25rem" fontSize="2rem" color="blue">
      {value}
    </Text>
  );
};

export const SmallWidget = ({ title, children, value, ...props }) => {
  return (
    <Flex
      bg="l0"
      borderRadius="0.5rem"
      p="1rem"
      flexDirection="column"
      {...getShadow("s0")}
      width={1}
      {...props}
      justifyContent="center"
    >
      <Flex justifyContent="space-between" mb="0.5rem">
        <Text fontWeight="semibold" fontSize="1rem" color="t2">
          {title}
        </Text>

        {value && value.variation && (
          <Performance variation={value.variation} />
        )}
      </Flex>

      <Flex flexGrow="1">
        {value && <Value value={value.count} />}

        <Flex height="3rem" mr="0px" ml="auto" width="6rem" maxWidth="6rem">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const LargeWidget = ({ title, children, graph, ...props }) => {
  return (
    <Flex
      bg="l0"
      borderRadius="0.5rem"
      p="1rem"
      flexDirection="column"
      width={1}
      {...getShadow("s1")}
      {...props}
      justifyContent="space-between"
    >
      <Text fontWeight="semibold" fontSize="1rem" color="t2">
        {title}
      </Text>

      <Flex
        mt="1rem"
        flexGrow="1"
        height="12rem"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Flex>
    </Flex>
  );
};
