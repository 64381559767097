import React, { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Icon, Input, orderObjects, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import Admin from "./Admin";
import Header from "./Header";
import Members from "./Members";

const Reveal = () => {
  return null;
};

const RenderProjects = ({ spaceId }) => {
  const dispatch = useDispatch();

  let projects = useSelector((state) => state["task"].projects);
  let space = useSelector((state) => state["space"].spaces[spaceId]);

  const deleteProject = (projectId) => {
    dispatch(
      ACTIONS.fetch.delete(
        "tasks",
        `${API_ROOT}/project/${projectId}/delete`,
        ACTIONS.tasks.deleteProject
      )
    );
  };

  let orderedProjects = orderObjects(projects, "order", "desc");

  return (
    <Droppable droppableId={`space.${space.id}`} type="PROJECT">
      {(provided, snapshot) => (
        <Flex
          ref={provided.innerRef}
          flexDirection="column"
          minHeight={20}
          borderRadius={4}
          bg={snapshot.isDraggingOver ? "#ddf5ff" : "l2"}
          p={4}
          overflow="auto"
        >
          {orderedProjects.map((project, index) => {
            return (
              <Draggable
                draggableId={`project.${index}`}
                index={index}
                key={index}
              >
                {(provided) => (
                  <Flex
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    borderRadius={4}
                    width={1}
                    mb={2}
                    py={1}
                    bg="white"
                    alignItems="center"
                    justifyContent="space-between"
                    height={18}
                    {...provided.dragHandleProps}
                  >
                    <Text ml={2}>{project.name}</Text>
                    <Icon.Trash
                      style={{ cursor: "pointer" }}
                      mr={2}
                      width={14}
                      height={14}
                      minWidth={14}
                      minHeight={14}
                      onClick={() => deleteProject(project.id)}
                    />
                  </Flex>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </Flex>
      )}
    </Droppable>
  );
};

const Projects = ({ space }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [adding, setAdding] = useState(false);

  const addProject = (name) => {
    setAdding(false);
    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ROOT}/project`,
        ACTIONS.tasks.addProject,
        { name, space_id: space.id }
      )
    );
    dispatch(ACTIONS.tasks.setAdminView({}));
  };

  return (
    <Flex flexDirection="column" width={1}>
      <Flex flexDirection="column">
        <Header
          adding={adding}
          setAdding={setAdding}
          title={t("Projects")}
          addText={t("New project")}
        />

        <Reveal height={44} width="100%" opened={adding}>
          <Input
            name="New project"
            value=""
            placeholder={t("Add new project")}
            onEnter={addProject}
            opened={adding}
            changeOpened={setAdding}
            mb={4}
            rightIcon="ReturnLeft"
          />
        </Reveal>
      </Flex>

      <RenderProjects spaceId={space.id} />
    </Flex>
  );
};

const SpaceAdmin = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let space = useSelector((state) => state["spaces"].spaces[id]);
  let members = useSelector((state) => state["spaces"].spaces[id].members);
  let users = useSelector((state) => state["spaces"].users);

  useEffect(() => {
    // load.page("space", `${API_ROOT}/space/users`, "users");
  }, []);

  const addMember = (newUser, newUserRole) => {
    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ROOT}/space/add_user`,
        ACTIONS.tasks.addSpaceUser,
        { user_id: newUser, type_id: newUserRole, space_id: space.id }
      )
    );
  };

  let spaceUsers = {};
  for (let [id, user] of Object.entries(users)) {
    if (!user.is_deleted) spaceUsers[id] = user.name;
  }

  const ADMIN = {
    icon: Icon.Spaces,
    title: space.name,
    options: [
      {
        icon: "User",
        title: t("Members"),
        view: Members,
        props: { addMember, members, users: spaceUsers }
      },
      {
        icon: "Project",
        title: t("Projects"),
        view: Projects,
        props: { space }
      }
    ]
  };

  return <Admin admin={ADMIN} />;
};

export default SpaceAdmin;
