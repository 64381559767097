import React from "react";
import { RRule } from "rrule";
import { useTranslation } from "react-i18next";

import { Checkbox, Flex, Text } from "tomato";

const RenderDaily = ({ rrule, updateRRule }) => {
  const { t } = useTranslation();

  const setWorkingDays = () => {
    let newRRule = { ...rrule };
    if (typeof rrule.byweekday === "undefined") {
      newRRule.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
    } else {
      delete newRRule.byweekday;
    }
    updateRRule({ ...newRRule });
  };

  return (
    <Flex alignItems="baseline">
      <Checkbox
        checked={typeof rrule.byweekday !== "undefined"}
        onChange={setWorkingDays}
      />
      <Text ml={2}>{t("Working days only")}</Text>
    </Flex>
  );
};

export default RenderDaily;
