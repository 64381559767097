import React from "react";

import Icon from "../Icon";

const RenderIcon = props => {
  return (
    <Icon {...props}>
    <path d="M16.8,6.5h4.3c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2h-18c-1.1,0-2-0.9-2-2v-12c0-1.1,0.9-2,2-2h5.5"/>
	<line x1="15.8" y1="11.5" x2="23.2" y2="11.5"/>
	<line x1="1.2" y1="11.5" x2="6.2" y2="11.5"/>
	<path d="M15.6,15.6l1.1-0.9l0,2.3c-0.7,1.6-2.3,2.7-4.1,2.7c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5V6"/>
	<circle cx="12.7" cy="3.6" r="2"/>
	<line x1="4" y1="9" x2="4.5" y2="9"/>
	<line x1="5.8" y1="9" x2="6.3" y2="9"/>
	<line x1="7.7" y1="9" x2="8.2" y2="9"/>
    </Icon>
  );
};

export default RenderIcon;
