import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex } from "tomato";

import { ACTIONS } from "store";
import AddTask from "../AddTask";
import { TASKS } from "crud";
import { TaskWrapper, TypeTask } from "../TaskList";

export const Subtasks = () => {
  const dispatch = useDispatch();

  const task = useSelector((state) => state["tasks"].obj);
  const tasks = useSelector((state) => state["tasks"].objs);

  const openTask = (taskId) => {
    dispatch(ACTIONS.state.setAttribute("tasks", "loadingObjId", taskId));
    TASKS.getOne(taskId);
  };

  const subtasksIds = tasks[task.id].subtasksIds;

  return (
    <Flex flexDirection="column">
      <AddTask
        open={true}
        listId={task.list_id}
        parentId={task.id}
        order={task.order}
        focus={true}
      />

      <Flex flexDirection="column" mt="0.5rem">
        {subtasksIds.map((subtaskId) => (
          <TaskWrapper
            key={subtaskId}
            width={1}
            alignItems="center"
            pl={2}
            py={1}
            height={34}
            minWidth="0px"
            bg="l2"
            mb="0.5rem"
            style={{ cursor: "pointer" }}
            onClick={() => openTask(subtaskId)}
          >
            <TypeTask
              mainList={false}
              task={tasks[subtaskId]}
              index={subtaskId}
            />
          </TaskWrapper>
        ))}
      </Flex>
    </Flex>
  );
};
