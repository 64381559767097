import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ClickToEdit, Editor, Flex, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

const Type = ({ type }) => {
  let color = "blue";
  return (
    <Flex
      mr="1rem"
      borderRadius="0.25rem"
      width="2rem"
      height="2.75rem"
      bg={color}
      alignItems="center"
      justifyContent="center"
    >
      <Text color="white" fontSize="1rem" fontWeight="bold">
        {type.toUpperCase()}
      </Text>
    </Flex>
  );
};

export const Documents = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const content = useRef();
  const doc = useSelector((state) => state["documents"].document);
  const network = useSelector((state) => state["network"].activity);

  const [saving, setSaving] = useState(false);

  const match = useRouteMatch(`/files/documents/:uuid`);

  const uuid = match.params.uuid;
  // const url = `${API_ROOT}${module.endpoint}/${match.params.id}`;

  // const [editing, setEditing] = useState({ pageIndex: null, itemIndex: null });

  useEffect(() => {
    dispatch(
      ACTIONS.fetch.get(
        "documents",
        `${API_ROOT}/file/${uuid}`,
        ACTIONS.state.loadObject,
        "document"
      )
    );
  }, []);

  useEffect(() => {
    if (!saving) return;
    if (!network) setSaving(false);
  }, [network, saving]);

  // const save = (content, title, icon) => {
  // if (
  //   JSON.stringify(page.content) === JSON.stringify(content) &&
  //   title === page.title &&
  //   icon === page.icon
  // )
  //   return;

  // dispatch(
  //   ACTIONS.fetch.patch(
  //     "pages",
  //     `${API_ENDPOINT}/page/${page.id}`,
  //     ACTIONS.state.loadObjects,
  //     { content, title, icon },
  //     (state, response) => {
  //       state.pages[page.id] = { ...state.pages[page.id], ...response };
  //       state.page = { ...page, ...response };
  //       return state;
  //     }
  //   )
  // );
  // };

  const saveDocument = () => {
    setSaving(true);
    dispatch(
      ACTIONS.fetch.patch("documents", `${API_ROOT}/file/${uuid}`, [], {
        name: doc.content.name,
        content: JSON.stringify(content.current)
      })
    );
  };

  const updateName = (name) => {
    setSaving(true);
    dispatch(
      ACTIONS.fetch.patch("documents", `${API_ROOT}/file/${uuid}`, [], {
        name
      })
    );
  };

  const onChange = (value) => {
    content.current = value;
  };

  if (!doc) return null;

  return (
    <Flex
      height="100%"
      justifyContent="center"
      // overflow="auto"
      // flexDirection="column"
      width={1}
      bg="l1"
    >
      {false && (
        <Flex
          bg="l0"
          height="4rem"
          minHeight="4rem"
          width={1}
          borderBottom="1px solid lightGrey"
        >
          <Flex alignItems="center" width={1} p="1.5rem">
            <Type type="D" />

            <Flex flexDirection="column">
              <Flex>
                <ClickToEdit
                  fontWeight="bold"
                  color="t1"
                  fontSize="1rem"
                  enabled={true}
                  value={doc.name}
                  placeholder={t("Enter document name...")}
                  onBlur={updateName}
                />

                {saving && <Text ml="3rem">saving...</Text>}
              </Flex>
              <Flex mt="0.375rem">
                <Text mr="1rem" fontSize="0.875rem">
                  File
                </Text>
                <Text mr="1rem" fontSize="0.875rem">
                  Edit
                </Text>
                <Text mr="1rem" fontSize="0.875rem">
                  View
                </Text>
                <Text mr="1rem" fontSize="0.875rem">
                  Insert
                </Text>
                <Text mr="1rem" fontSize="0.875rem">
                  Format
                </Text>
              </Flex>
            </Flex>

            <Flex width="fit-content" ml="auto" mr={0}>
              <Icon.Save
                mr="1rem"
                style={{ cursor: "pointer" }}
                onClick={saveDocument}
              />
            </Flex>
          </Flex>
        </Flex>
      )}

      <Flex
        // overflow="auto"
        width="80%"
        height="fit-content"
        // height="100%"
        flexDirection="column"
        bg="l0"
        mt="4.5rem"
      >
        <Editor
          editorStyle={{
            style: {
              // marginTop: "1.5rem",
              // margin: "1rem 1rem 1rem 1rem",
              // position: "relative",
              padding: "3rem 5rem",
              lineHeight: 1.5
              // paddingTop: "3rem"
            }
          }}
          toolBarStyle={{
            position: "absolute",
            width: 1,
            padding: "0.75rem 1rem ",
            top: 0,
            left: 0,
            bg: "l0"
          }}
          enableExit={true}
          debounceTime={2500}
          enableHint={true}
          enabled={true}
          onChange={onChange}
          onSave={(content) => {
            onChange(content);
            // dispatch(ACTIONS.state.setAttribute("pages", "editingPage", false));
            // save(content, title, icon);
          }}
          autoSave={(content) => {
            onChange(content);
            // save(content, title, icon);
          }}
          value={doc.content}
          // fileRootUrl={`${ROOT_URL}/shared/`}
          // uploadEndpoint={`${API_ENDPOINT}/upload`}
          // uploadId={{ module: "PAGE", space_id: spaceId, page_id: page.id }}
        />
      </Flex>
    </Flex>
  );
};
