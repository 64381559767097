import React from "react";
import styled from "styled-components";

import { Flex, FlexWrapper } from "../Flex";
import { Text } from "../Text";
import { getShadow } from "../../themes/util";
import { UI } from "../../constants";

export const Card_ = styled(Flex)`
  transition: all 150ms ease-in-out;
  &:hover {
    ${(props) =>
      props.enableHover
        ? `box-shadow: ${UI.HOVERING_BOX_SHADOW}; transform: scale(1.03); cursor: pointer; z-index: 100;`
        : ""};
  }
`;

export const CardWrapper_ = styled(FlexWrapper)`
  transition: all 250ms ease-in-out;
  &:hover {
    ${(props) =>
      props.enableHover
        ? `box-shadow: ${UI.HOVERING_BOX_SHADOW}; cursor: pointer; z-index: 100;`
        : ""};
  }
`;

export const Card = React.forwardRef((props, ref) => {
  let renderProps = { ...props };
  delete renderProps.children;

  return (
    <Card_
      label="Card"
      position="relative"
      className="Card"
      bg="l0"
      p="1rem"
      borderRadius="0.5rem"
      {...getShadow("s0")}
      {...renderProps}
      ref={ref}
    >
      {(props.title || props.titleRight) && (
        <>
          <Flex
            borderLeft="8px solid blue"
            height="1.5rem"
            position="absolute"
            left={0}
          />
          <Flex width={1} height="fit-content" alignItems="center"
                mb="1.5rem"
              justifyContent='space-between'
          >
            {props.title && (
              <Text
                fontWeight="bold"
                color="t1"
                fontSize="1.25rem"
              >
                {props.title}
              </Text>
            )}
            {props.titleRight && <>{props.titleRight}</>}
          </Flex>
        </>
      )}
      {props.children}
    </Card_>
  );
});

export const CardWrapper = React.forwardRef((props, ref) => {
  let renderProps = { ...props };
  delete renderProps.children;

  return (
    <CardWrapper_
      position="relative"
      className="CardWrapper_"
      bg="l0"
      p="1rem"
      borderRadius="0.75em"
      {...getShadow("s0")}
      {...renderProps}
      ref={ref}
    >
      {props.children}
    </CardWrapper_>
  );
});
