import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#0088FE", "#e5f3ff", "#FFBB28", "#FF8042"];

const ChartPie = ({ data, legend = false, tooltip = true, ...props }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          innerRadius="80%"
          outerRadius="100%"
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export { ChartPie as PieChart };
