import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex, Drawer, Spinner } from "tomato";

import { ACTIONS } from "store";
import { Task } from "modules";
import { TASKS } from "crud";

export const Sidebar = () => {
  const dispatch = useDispatch();

  const loadingObjId = useSelector((state) => state["tasks"].loadingObjId);
  const task = useSelector((state) => state["tasks"].obj);

  useEffect(() => {
    if (!task) return;
    document.title = task.subject;
    return () => (document.title = "Elefante");
  }, [task]);

  const taskIdFromHash = window.location.hash.split("#")[1];

  useEffect(() => {
    if (loadingObjId) {
      if (
        loadingObjId !== taskIdFromHash &&
        window.location.pathname === "/tasks"
      ) {
        let url =
          window.location.origin +
          window.location.pathname +
          "#" +
          loadingObjId;
        window.history.pushState("", "", url);
      }
    }
  }, [loadingObjId, taskIdFromHash]);

  const onExit = () => {
    let url = window.location.origin + window.location.pathname;
    window.history.pushState("", "", url);
  };

  if (taskIdFromHash && !loadingObjId && !task) {
    dispatch(
      ACTIONS.state.setAttribute("tasks", "loadingObjId", taskIdFromHash)
    );
    TASKS.getOne(taskIdFromHash);
  }

  useEffect(() => {
    return () =>
      dispatch(
        ACTIONS.state.changeState("tasks", (state) => {
          state.obj = null;
          state.loadingObjId = null;
          return state;
        })
      );
  }, [dispatch]);

  return (
    <Drawer width="50rem" open={task ? true : false} onExit={onExit}>
      {task && task.id ? (
        <Task />
      ) : (
        <Flex
          height="100%"
          alignItems="center"
          justifyContent="center"
          width={1}
          label="Sidebar"
        >
          <Spinner size="medium" />
        </Flex>
      )}
    </Drawer>
  );
};

export default Sidebar;
