import { createActions, handleActions } from "redux-actions";

const modelId = "conversations";

const options = { prefix: "conversations", namespace: "::" };

export const conversationsActions = createActions(
  {
    DELETE_MESSAGE: ({ acc, id }) => ({
      module: modelId,
      acc,
      id
    }),

    MESSAGE: (props) => ({
      module: modelId,
      ...props
    }),

    INSERT_MESSAGE: ({
      acc,
      creationDate,
      currentUserId,
      fromId,
      message,
      messageId,
      msgUuid,
      replyToId,
      timestamp,
      summary
    }) => ({
      module: modelId,
      acc,
      creationDate,
      currentUserId,
      fromId,
      message,
      messageId,
      msgUuid,
      replyToId,
      timestamp,
      summary
    }),

    LOAD_CONVERSATIONS: (response) => ({
      module: modelId,
      response
    }),

    NEW_CONVERSATION: (conversation) => ({
      module: modelId,
      conversation
    }),

    LOAD_MESSAGES: ({ acc, messages }) => ({
      module: modelId,
      acc,
      messages
    }),

    LOAD_THREAD: ({ parent, messages }) => ({
      module: modelId,
      messages,
      parent
    }),

    START_CONVERSATION: ({ acc, toId, type }) => ({
      module: modelId,
      acc,
      toId,
      type
    }),

    CLEAR_TYPING: () => ({
      module: modelId
    }),

    TYPING: ({ acc, fromId, toId }) => ({
      module: modelId,
      acc,
      fromId,
      toId
    }),

    READ: ({ acc }) => ({
      module: modelId,
      acc
    }),

    READ_RECEIPT: ({ acc, lastMessageReadId }) => ({
      module: modelId,
      acc,
      lastMessageReadId
    }),

    REACTION: ({ acc, id, reactions }) => ({
      module: modelId,
      acc,
      id,
      reactions
    }),

    UPDATE_MESSAGE: ({ acc, id, message }) => ({
      module: modelId,
      acc,
      id,
      message
    })
  },
  options
);

const reducer = handleActions(
  {
    DELETE_MESSAGE: (state, { payload: { acc, id } }) => {
      if (state.conversations[acc] && state.conversations[acc].messages[id])
        state.conversations[acc].messages[id].message = null;

      if (
        state.conversations[acc] &&
        state.conversations[acc].last_message_id === id
      )
        state.conversations[acc].last_message_summary = null;

      if (state.activeThread.messages && state.activeThread.messages[id])
        state.activeThread.messages[id].message = null;

      return state;
    },

    MESSAGE: (
      state,
      { payload: { acc, creationDate, fromId, message, messageId, timestamp } }
    ) => {
      return state;
    },

    INSERT_MESSAGE: (
      state,
      {
        payload: {
          acc,
          creationDate,
          currentUserId,
          fromId,
          message,
          messageId,
          msgUuid,
          replyToId,
          timestamp,
          summary
        }
      }
    ) => {
      // console.log(
      //   "insert message",
      //   acc,
      //   creationDate,
      //   currentUserId,
      //   fromId,
      //   message,
      //   messageId,
      //   msgUuid,
      //   replyToId,
      //   timestamp,
      //   summary
      // );
      if (!(acc in state.conversations)) {
        state.conversations[acc] = {
          messages: {},
          uuid: acc,
          loaded: true,
          member: fromId,
          update_date_timestamp: timestamp - 1
        };
      } else {
        if (Object.keys(state.conversations[acc].messages).length === 0)
          state.conversations[acc].loaded = false;
      }

      state.conversations[acc].last_message_date = creationDate;
      state.conversations[acc].last_message_timestamp = timestamp;

      message = JSON.parse(message);

      state.conversations[acc].last_message_summary = summary;
      state.conversations[acc].last_message_from_id = fromId;
      state.conversations[acc].last_message_id = messageId;

      let newMessage = {
        user_id: fromId,
        message: message,
        id: messageId,
        creation_date: creationDate,
        reply_to_id: replyToId,
        reactions: [],
        replies: {}
      };

      let replies;

      if (replyToId) {
        replies = state.conversations[acc].messages[replyToId].replies;
        replies.last_reply_date = new Date();
        if (!replies.users_ids) {
          replies.count = 1;
          replies.users_ids = [fromId];
        } else {
          replies.count += 1;
          if (!replies.users_ids.includes(fromId))
            replies.users_ids.push(fromId);
        }
      }

      if (
        state.activeThread.parent &&
        state.activeThread.parent.id === replyToId
      ) {
        state.activeThread.messages[messageId] = newMessage;
        state.activeThread.parent.replies = replies;
      }

      const messages = state.conversations[acc].messages;
      if (!messageId) {
        newMessage.saving = true;
        messages[msgUuid] = newMessage;
      } else {
        delete Object.assign(messages, { [messageId]: newMessage })[msgUuid];
      }

      return state;
    },

    LOAD_THREAD: (state, { payload: { messages, parent } }) => {
      parent.message = JSON.parse(parent.message);
      parent.reactions = JSON.parse(parent.reactions);
      parent.replies = JSON.parse(parent.replies);
      state.activeThread.parent = parent;

      Object.values(messages).forEach((message) => {
        message.reactions = JSON.parse(message.reactions);
        message.replies = JSON.parse(message.replies);

        if (message.message)
          try {
            message.message = JSON.parse(message.message);
          } catch (err) {
            message.message = [
              {
                type: "paragraph",
                children: [{ text: message.message }]
              }
            ];
          }
      });
      state.activeThread.messages = messages;

      state.activeThread.loading = false;
      return state;
    },

    LOAD_MESSAGES: (state, { payload: { acc, messages } }) => {
      if (!(acc in state.conversations)) {
        state.conversations[acc] = {};
      }

      Object.values(messages).forEach((message) => {
        message.reactions = JSON.parse(message.reactions);
        message.replies = JSON.parse(message.replies);
        if (message.message)
          try {
            message.message = JSON.parse(message.message);
          } catch (err) {
            message.message = [
              {
                type: "paragraph",
                children: [{ text: message.message }]
              }
            ];
          }
      });

      state.conversations[acc].messages = messages;
      state.conversations[acc].loaded = true;

      return state;
    },

    START_CONVERSATION: (state, { payload: { acc, toId, type } }) => {
      if (!(acc in state.conversations))
        state.conversations[acc] = {
          last_message: null,
          last_message_from_id: null,
          last_message_read_id: null,
          last_message_timestamp: null,
          messages: {},
          type,
          update_date_timestamp: null,
          uuid: acc,
          loaded: false,
          member: toId
        };

      state.activeConversation = acc;
      localStorage.setItem("acc", JSON.stringify(acc));

      return state;
    },

    LOAD_CONVERSATIONS: (state, { payload: { response } }) => {
      state.conversations = response.objects;
      return state;
    },

    NEW_CONVERSATION: (state, { payload: { conversation } }) => {
      state.conversations[conversation.uuid] = conversation;
      return state;
    },

    CLEAR_TYPING: (state) => {
      state.typing = null;
      return state;
    },

    TYPING: (state, { payload: { acc, fromId, toId } }) => {
      state.typing = { acc, fromId: parseInt(fromId), toId: parseInt(toId) };
      return state;
    },

    READ: (state, { payload: { acc } }) => {
      if (state.conversations[acc])
        state.conversations[acc].last_message_timestamp_read_by_you =
          Date.now();
      return state;
    },

    READ_RECEIPT: (state, { payload: { acc, lastMessageReadId } }) => {
      if (state.conversations[acc]) {
        state.conversations[acc].last_message_read_id = lastMessageReadId;
        state.conversations[acc].last_message_timestamp_read_by_other =
          Date.now();
      }
      return state;
    },

    REACTION: (state, { payload: { acc, id, reactions } }) => {
      if (state.conversations[acc] && state.conversations[acc].messages[id])
        state.conversations[acc].messages[id].reactions = JSON.parse(reactions);
      return state;
    },

    UPDATE_MESSAGE: (state, { payload: { acc, id, message } }) => {
      if (state.conversations[acc] && state.conversations[acc].messages[id])
        state.conversations[acc].messages[id].message = JSON.parse(message);
      return state;
    }
  },
  {},
  options
);

export default reducer;
