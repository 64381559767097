// const primaryColor = "240";
// const defaultSaturation = "10%";

export const COLORS = (variant, primaryColor, defaultSaturation) => {
  const userColor = primaryColor === 240 ? 260 : primaryColor;
  const userSaturation =
    primaryColor === 240 && defaultSaturation === "10%"
      ? "80%"
      : defaultSaturation;

  const colors = {
    dark: {
      blue: `hsla(240, 100%, 70%)`,
      lightBlue: `hsla(200, 100%, 50%)`,
      brown: "rgb(181, 148, 105)",
      gray: "rgb(152, 152, 157)",
      green: "hsla(120, 50%, 50%)",
      lightGreen: "hsla(120, 50%, 90%)",
      orange: "hsla(30, 100%, 50%)",
      lightOrange: "hsla(30, 100%, 85%)",
      pink: "rgb(255, 100, 130)",
      purple: "rgb(218, 143, 255)",
      red: "hsl(0, 100%, 70%)",
      lightRed: "hsl(0, 100%, 95%)",
      yellow: "hsl(35, 100%, 80%)",
      appBackground: "#333944",
      regular: "#FFFFFF",
      link: "#b2b2b2",
      hover: "#F3F6FC",
      labels: "#626475",
      borders: "#CACDD6",
      card: "#FFFFFF",
      buttonNormal: "#4C9EFF",
      buttonHover: "#1480D6",
      buttonDisabled: "#4C9EFF 30%",
      interactive: "#295ED9",
      selected: "#EAEFFA",
      appHeader: "#1F2555",
      primary: "#333333",
      secondary: "#333333 75%",
      disabled: "#333333 30%",
      light: "#b2b2b2",
      background: "#282C34",
      pipz: "#1480D6",
      mainNavBackground: "#E0E0E0",
      black: `hsl(${primaryColor}, ${defaultSaturation}%, 20%)`,
      white: `hsl(${primaryColor}, ${defaultSaturation}%, 95%)`,
      grey: "#afafaf",
      lightGrey: "#7f8a93",
      l0: `hsla(${primaryColor}, ${defaultSaturation}, 18%)`,
      l1: `hsla(${primaryColor}, ${defaultSaturation}, 12%)`,
      l2: `hsla(${primaryColor}, ${defaultSaturation}, 10%)`,
      l3: `hsla(${primaryColor}, ${defaultSaturation}, 7%)`,
      l4: `hsla(${primaryColor}, ${defaultSaturation}, 4%)`,
      l5: `hsla(${primaryColor}, ${defaultSaturation}, 100%)`,
      l6: `hsla(${primaryColor}, ${defaultSaturation}, 1005)`,
      s0: `hsla(${primaryColor}, ${defaultSaturation}%, 9%)`,
      s1: `hsla(${primaryColor}, ${defaultSaturation}%, 7%)`,
      s2: `hsla(${primaryColor}, ${defaultSaturation}%, 5%)`,
      s3: `hsla(${primaryColor}, ${defaultSaturation}%, 3%)`,
      s4: `hsla(${primaryColor}, ${defaultSaturation}%, 25%)`,
      s5: `hsla(${primaryColor}, ${defaultSaturation}%, 30%)`,
      s6: `hsla(${primaryColor}, ${defaultSaturation}%, 35%)`,
      t0: "hsla(0, 0%, 65% )",
      t1: "hsla(0, 0%, 90% )",
      t2: "hsla(0, 0%, 80% )",
      t3: "hsla(0, 0%, 70% )",
      t4: "hsla(0, 0%, 60% )",
      t5: "hsla(0, 0%, 40% )",
      t6: "hsla(0, 0%, 35% )",
      b1: "hsla(0, 0%, 40% )",
      tooltip: "#0d064a",
      focus: "hsl(214, 100%, 65%)",
      veryLight: `hsla(${primaryColor}, ${defaultSaturation}, 2%)`,
      user: `hsla(${primaryColor}, ${defaultSaturation}%, 35%)`,
      userLight: `hsla(${primaryColor}, ${defaultSaturation}%, 35%)`,
      userVeryLight: `hsla(${primaryColor}, ${defaultSaturation}%, 15%)`
    },
    light: {
      // blue: `hsla(230, 100%, 47%)`,
      blue: `hsla(254, 100%, 65%)`,
      // blue: `hsla(262, 100%, 62%)`,
      // lightBlue: `hsla(217, 100%, 94%)`,
      lightBlue: `hsla(254, 100%, 94%)`,
      // lightBlue: `hsla(262, 100%, 94%)`,
      brown: "rgb(127, 101, 69)",
      gray: "rgb(105, 105, 110)",
      green: "hsla(120, 50%, 50%)",
      lightGreen: "hsla(120, 50%, 95%)",
      orange: "hsla(30, 100%, 50%)",
      lightOrange: "hsla(30, 100%, 95%)",
      pink: "rgb(211, 68, 171)",
      purple: "rgb(137, 68, 171)",
      red: "hsl(0, 100%, 70%)",
      lightRed: "hsl(0, 100%, 95%)",
      yellow: "hsl(35, 100%, 90%)",
      appBackground: "white",
      regular: "black",
      link: "#1480d6",
      hover: "#7f7f7f",
      labels: "#626475",
      borders: "#CACDD6",
      card: "#FFFFFF",
      buttonNormal: "#0566FF",
      buttonHover: "#1480D6",
      buttonDisabled: "#4C9EFF 30%",
      interactive: "#295ED9",
      selected: "#EAEFFA",
      appHeader: "#1F2555",
      primary: "#333333",
      secondary: "#333333 75%",
      disabled: "#333333 30%",
      light: "#b2b2b2",
      pipz: "#1480D6",
      mainNavBackground: "#E0E0E0",
      black: `hsl(${primaryColor}, ${defaultSaturation}%, 100%)`,
      white: `hsl(${primaryColor}, ${defaultSaturation}%, 20%)`,
      grey: "#4c4c4c",
      lightGrey: "#FAFAFA",
      l0: `hsla(${primaryColor}, ${defaultSaturation}, 100%)`,
      l1: `hsla(${primaryColor}, ${defaultSaturation}, 97%)`,
      l2: `hsla(${primaryColor}, ${defaultSaturation}, 94%)`,
      l3: `hsla(${primaryColor}, ${defaultSaturation}, 86%)`,
      l4: `hsla(${primaryColor}, ${defaultSaturation}, 78%)`,
      l5: `hsla(${primaryColor}, ${defaultSaturation}, 75%)`,
      l6: `hsla(${primaryColor}, ${defaultSaturation}, 70%)`,
      s0: `hsla(${primaryColor}, ${defaultSaturation}%, 85%)`,
      s1: `hsla(${primaryColor}, ${defaultSaturation}%, 85%)`,
      s2: `hsla(${primaryColor}, ${defaultSaturation}%, 80%)`,
      s3: `hsla(${primaryColor}, ${defaultSaturation}%, 75%)`,
      s4: `hsla(${primaryColor}, ${defaultSaturation}%, 70%)`,
      s5: `hsla(${primaryColor}, ${defaultSaturation}%, 65%)`,
      s6: `hsla(${primaryColor}, ${defaultSaturation}%, 20%)`,
      t0: "hsla(0, 100%, 100% )",
      t1: "hsla(0, 0%, 10% )",
      t2: "hsla(0, 0%, 30% )",
      t3: "hsla(0, 0%, 40% )",
      t4: "hsla(0, 0%, 60% )",
      t5: "hsla(0, 0%, 80% )",
      t6: "hsla(0, 0%, 90% )",
      b1: "hsla(0, 0%, 80% )",
      tooltip: "white",
      focus: "hsl(214, 100%, 65%)",
      veryLight: `hsla(${primaryColor}, ${defaultSaturation}, 98%)`,
      user: `hsla(${userColor}, ${userSaturation}, 60%)`,
      userHover: `hsla(${userColor}, ${userSaturation}, 85%)`,
      userActive: `hsla(${userColor}, ${userSaturation}, 70%)`,
      userInactive: `hsla(${userColor}, ${userSaturation}, 90%)`,
      userBold: `hsla(${userColor}, ${userSaturation}, 90%)`,
      userLight: `hsla(${userColor}, ${userSaturation}, 95%)`,
      userVeryLight: `hsla(${userColor}, ${userSaturation}, 97%)`
    }
  };
  return colors[variant];
};

export const COLOR_LIST = {
  1: "#07c",
  2: "#374047",
  3: "#f8f9f9",
  4: "#ebedee",
  5: "#dee1e3",
  6: "#cfd3d6",
  7: "#bec4c8",
  8: "#acb4b9",
  9: "#97a1a7",
  10: "#7f8a93",
  11: "#5f6e78",
  12: "#374047",
  13: "#e4f0f9",
  14: "#c6e1f3",
  15: "#a5cfed",
  16: "#7db9e5",
  17: "#4a9eda",
  18: "#0077cc",
  19: "#006bb7",
  20: "#005da0",
  21: "#004d84",
  22: "#00365d",
  23: "#eaebfa",
  24: "#d2d5f6",
  25: "#b7bbf0",
  26: "#959ce9",
  27: "#6872e0",
  28: "#0011cc",
  29: "#000fb7",
  30: "#000da0",
  31: "#000a83",
  32: "#00075c",
  33: "#f0e9fa",
  34: "#e1d2f6",
  35: "#ceb6f0",
  36: "#b894e9",
  37: "#9966e0",
  38: "#5500cc",
  39: "#4c00b8",
  40: "#4300a1",
  41: "#370084",
  42: "#27005e",
  43: "#f9e9fa",
  44: "#f2d1f5",
  45: "#ebb5f0",
  46: "#e293e9",
  47: "#d665e0",
  48: "#bb00cc",
  49: "#a900b8",
  50: "#9400a2",
  51: "#7b0086",
  52: "#580061",
  53: "#fae9f3",
  54: "#f5d1e6",
  55: "#f0b6d8",
  56: "#e994c6",
  57: "#e066ad",
  58: "#cc0077",
  59: "#b8006b",
  60: "#a2005e",
  61: "#86004e",
  62: "#610038",
  63: "#faeaeb",
  64: "#f6d2d5",
  65: "#f0b7bc",
  66: "#ea969d",
  67: "#e16973",
  68: "#cc0011",
  69: "#b8000f",
  70: "#a2000d",
  71: "#86000b",
  72: "#610008",
  73: "#f9ede4",
  74: "#f3d9c6",
  75: "#ecc2a4",
  76: "#e4a87c",
  77: "#da864a",
  78: "#cc5500",
  79: "#b84c00",
  80: "#a04300",
  81: "#843700",
  82: "#5e2700",
  83: "#f8f6de",
  84: "#f1ecba",
  85: "#e9e293",
  86: "#e0d668",
  87: "#d7c938",
  88: "#ccbb00",
  89: "#b8a900",
  90: "#a29400",
  91: "#867b00",
  92: "#615800",
  93: "#eef8df",
  94: "#dcf1bd",
  95: "#c7ea97",
  96: "#b1e16c",
  97: "#96d73b",
  98: "#77cc00",
  99: "#6bb800",
  100: "#5ea200",
  101: "#4e8600",
  102: "#386100",
  103: "#e5f9e4",
  104: "#c9f3c6",
  105: "#a9eca3",
  106: "#84e47b",
  107: "#54da48",
  108: "#11cc00",
  109: "#0fb800",
  110: "#0da200",
  111: "#0b8600",
  112: "#086100",
  113: "#e3f9ec",
  114: "#c5f3d8",
  115: "#a2ecc1",
  116: "#79e4a6",
  117: "#46da84",
  118: "#00cc55",
  119: "#00b84c",
  120: "#00a243",
  121: "#008638",
  122: "#006128",
  123: "#e3f9f7",
  124: "#c4f3ef",
  125: "#a0ece5",
  126: "#77e3da",
  127: "#44d9cd",
  128: "#00ccbb",
  129: "#00b8a9",
  130: "#00a294",
  131: "#00867b",
  132: "#00615"
};

export default COLORS;
