const xxsmall = "0.25rem";
const xsmall = "0.5rem";
const small = "0.75rem";
const medium = "1.25rem";
const large = "2rem";
const xlarge = "2.5rem";
const xxlarge = "3rem";

export const SIZES = {
  "xx-small": {
    width: xxsmall,
    height: xxsmall,
    minWidth: xxsmall,
    maxHeight: xxsmall,
    size: xxsmall
  },
  "x-small": {
    width: xsmall,
    height: xsmall,
    minWidth: xsmall,
    maxHeight: xsmall,
    size: xsmall
  },
  small: {
    width: small,
    height: small,
    minWidth: small,
    maxHeight: small,
    size: small
  },
  medium: {
    width: medium,
    height: medium,
    minWidth: medium,
    maxHeight: medium,
    size: medium
  },
  large: {
    width: large,
    height: large,
    minWidth: large,
    maxHeight: large,
    size: large
  },
  "x-large": {
    width: xlarge,
    height: xlarge,
    minWidth: xlarge,
    maxHeight: xlarge,
    size: xlarge
  } ,
  "xx-large": {
    width: xxlarge,
    height: xxlarge,
    minWidth: xxlarge,
    maxHeight: xxlarge,
    size: xxlarge
  } 
};
