import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex } from "tomato";

import { AreaChart, BarChart } from "components";
import { LargeWidget } from "./Widgets";

export const UserActivities = () => {
  const { t } = useTranslation();

  const dailyEvent = useSelector((state) => state["charts"]["dailyEvent"]);
  const topEvents = useSelector((state) => state["charts"]["topEvents"]);

  return (
    <>
      <Flex mt="2rem">
        <LargeWidget mr="2rem" width={1 / 2} title={t("Top activities")}>
          <BarChart data={topEvents} legend={false} />
        </LargeWidget>

        <LargeWidget width={1 / 2} title={t("Activities by day")}>
          <AreaChart data={dailyEvent} legend={false} />
        </LargeWidget>
      </Flex>
    </>
  );
};
