import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Chip,
  DatePicker,
  DateTimePicker,
  Dropdown,
  Flex,
  FlexHidden,
  FlexWrapper,
  formatDate,
  FormatDate,
  Icon,
  Input,
  Overlay,
  Row,
  SimpleEditor,
  Text,
  Tags,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";
import { Transition } from "components/Transition";
import { t } from "i18n";

import { DueColor } from "modules/Tasks/util";

const ACTIVITIES_TYPES = {
  1: t("Inside work"),
  2: t("Call"),
  3: t("Email"),
  4: t("Meeting"),
  5: t("Lunch"),
  6: t("Document"),
  7: t("Proposal"),
  9: t("Presentation"),
  10: t("Code"),
  11: t("Travel"),
  12: t("Writing")
};

const What = ({
  tags,
  setTags,
  selectedActivityTypeId,
  setSelecteActivityTypeId
}) => {
  const { t } = useTranslation();

  const allTags = useSelector((state) => state["tasks"].tags);

  return (
    <Row mt="1rem" label={t("Tags")}>
      <Tags tags={tags} options={allTags} onChange={setTags} />
    </Row>
  );
};

const WhenEvent = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  pinned,
  setPinned,
  priorityId,
  setPriorityId,
  typeId
}) => {
  const { t } = useTranslation();
  let user = useSelector((state) => state["account"].user);

  const [instance, setInstance] = useState(null);

  const [startTime, setStarTime] = useState("");
  const [endTime, setEndTime] = useState("");

  let flagStyle = { stroke: "red" };
  if (priorityId) {
    flagStyle.fill = "red";
  }

  let pinStyle = { stroke: "orange" };
  if (pinned) {
    pinStyle.fill = "orange";
  }

  const onSelect = (startDate, startTime, endDate, endTime) => {
    setEndDate(endDate);
    setStartDate(startDate);
    setStarTime(startTime);
    setEndTime(endTime);
    instance.hide();
  };

  return (
    <Row mt="1rem" label={t("Date")}>
      <Tippy
        placement="bottom"
        trigger="click"
        interactive={true}
        render={(attrs) => (
          <>
            {instance ? (
              <Flex
                {...attrs}
                bg="l0"
                p="2rem"
                borderRadius="0.5rem"
                boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
              >
                <DateTimePicker
                  onSelect={onSelect}
                  onCancel={() => instance.hide()}
                  range={true}
                  disablePast={true}
                />
              </Flex>
            ) : (
              ""
            )}
          </>
        )}
        onCreate={setInstance}
      >
        <Flex position="relative">
          {!endDate && (
            <Flex alignItems="center" style={{ cursor: "pointer" }}>
              <Icon.Calendar size="medium" />
              <Flex
                ml="0.5rem"
                py="0.25rem"
                px="0.5rem"
                borderRadius="0.25rem"
                style={{ cursor: "pointer" }}
                alignItems="center"
                bg="l2"
              >
                <Text color="t3">{t("No date")}</Text>
              </Flex>
            </Flex>
          )}

          {endDate && (
            <FlexWrapper alignItems="center">
              {
                formatDate(startDate, user.language, user.timezone)
                  .fullFriendlyDate
              }
              , {startTime} - {endTime}
            </FlexWrapper>
          )}
        </Flex>
      </Tippy>
    </Row>
  );
};

const WhenTask = ({
  endDate,
  setEndDate,
  pinned,
  setPinned,
  priorityId,
  setPriorityId,
  typeId
}) => {
  const { t } = useTranslation();
  const [instance, setInstance] = useState(null);

  let flagStyle = { stroke: "red" };
  if (priorityId) {
    flagStyle.fill = "red";
  }

  let pinStyle = { stroke: "orange" };
  if (pinned) {
    pinStyle.fill = "orange";
  }

  return (
    <Row mt="1rem" label={t("Due date")}>
      <Tippy
        placement="bottom-start"
        trigger="click"
        interactive={true}
        offset={[0, 4]}
        appendTo="parent"
        render={(attrs) => (
          <>
            {instance ? (
              <Flex
                {...attrs}
                bg="l0"
                p={4}
                borderRadius={8}
                boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
              >
                <DatePicker
                  width={280}
                  startDate={endDate}
                  saveDate={(date) => {
                    setEndDate(date);
                    instance.hide();
                  }}
                />
              </Flex>
            ) : (
              ""
            )}
          </>
        )}
        onCreate={setInstance}
      >
        <Flex position="relative">
          {!endDate && (
            <Flex alignItems="center" style={{ cursor: "pointer" }}>
              <Icon.Calendar size="medium" />
              <Flex
                ml="0.5rem"
                p="0.25rem"
                borderRadius="0.25rem"
                style={{ cursor: "pointer" }}
                alignItems="center"
                bg="l2"
              >
                <Text color="t3">{t("No due date")}</Text>
              </Flex>
            </Flex>
          )}

          {endDate && (
            <FlexWrapper alignItems="center">
              <Chip
                color={endDate ? DueColor(endDate) : "green"}
                selected={true}
              >
                {endDate && FormatDate.dMMM(endDate)}
              </Chip>

              <FlexHidden
                ml="0.5rem"
                p="0.25rem"
                borderRadius="0.25rem"
                style={{ cursor: "pointer" }}
                alignItems="center"
                hoverColor="l2"
                onClick={() => setEndDate(null)}
              >
                <Icon.Close />
                <Text color="t3">{t("Remove date")}</Text>
              </FlexHidden>
            </FlexWrapper>
          )}
        </Flex>
      </Tippy>
    </Row>
  );
};

const Where = ({
  selectedListId,
  setSelectedListId,
  selectedSpaceId,
  setSelectedSpaceId,
  selectedProjectId,
  setSelectedProjectId
}) => {
  const { t } = useTranslation();

  let lists = useSelector((state) => state["tasks"].lists);
  let projects = useSelector((state) => state["tasks"].projects);
  let spaces = useSelector((state) => state["spaces"].spaces);

  const [listsOptions, setListsOptions] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);

  useEffect(() => {
    if (!selectedProjectId) return;
    setListsOptions(
      Object.values(lists)
        .filter((list) => list.project_id === selectedProjectId)
        .map((list) => {
          return { id: list.id, value: list.name };
        })
    );
  }, [lists, selectedProjectId]);

  useEffect(() => {
    setProjectsOptions(
      Object.values(projects)
        .filter((project) => project.space_id === selectedSpaceId)
        .map((project) => {
          return { id: project.id, value: project.name };
        })
    );
  }, [projects, selectedSpaceId]);

  let spacesOptions = Object.keys(spaces).map((spaceId) => {
    return { id: spaceId, value: spaces[spaceId].name };
  });

  return (
    <Row label={t("Where")} mt="0.25rem" alignItems="center">
      <Flex width={1}>
        <Dropdown
          label={t("Space")}
          width={1 / 3}
          key={`s${selectedSpaceId}`}
          options={spacesOptions}
          onSelect={(option) => {
            setSelectedSpaceId(parseInt(option.id));
            setSelectedProjectId();
            setSelectedListId();
          }}
          placeholder={t("Choose space")}
          selected={
            selectedSpaceId
              ? {
                  id: selectedSpaceId,
                  value: spaces[selectedSpaceId].name
                }
              : null
          }
        />

        {selectedSpaceId && (
          <Dropdown
            label={t("Project")}
            mx="1rem"
            width={1 / 3}
            key={`p${selectedProjectId}`}
            options={projectsOptions}
            onSelect={(option) => {
              setSelectedProjectId(parseInt(option.id));
              setSelectedListId();
            }}
            placeholder={t("Choose project")}
            selected={
              selectedProjectId
                ? {
                    id: selectedProjectId,
                    value: projects[selectedProjectId].name
                  }
                : null
            }
          />
        )}

        {selectedProjectId && (
          <Dropdown
            label={t("List")}
            width={1 / 3}
            key={`l${selectedListId}`}
            options={listsOptions}
            onSelect={(option) => setSelectedListId(parseInt(option.id))}
            placeholder={t("Choose list")}
            selected={
              selectedListId
                ? { id: selectedListId, value: lists[selectedListId].name }
                : null
            }
          />
        )}
      </Flex>
    </Row>
  );
};

const Who = ({
  selectedUserId,
  setSelectedUserId,
  selectedSpaceId,
  selectedProjectId
}) => {
  const { t } = useTranslation();
  let spaces = useSelector((state) => state["spaces"].spaces);
  let users = useSelector((state) => state["spaces"].users);
  let user = useSelector((state) => state["account"].user);

  let members = selectedSpaceId ? spaces[selectedSpaceId].members : [];

  let membersOptions = members
    .filter(
      (member) => users[member.id].is_active && !users[member.id].is_deleted
    )
    .map((member) => {
      return {
        id: member.id,
        value: users[member.id].name,
        avatar: users[member.id].avatar
      };
    });

  return (
    <Row label={t("Assignee")} alignItems="center">
      <Dropdown
        width={1 / 3}
        options={membersOptions}
        onSelect={(member) => setSelectedUserId(member.id)}
        placeholder={t("Set assignee")}
        enableClear={true}
        selected={
          selectedUserId
            ? { id: selectedUserId, value: users[selectedUserId].name }
            : { id: user.id, value: user.name }
        }
      />
    </Row>
  );
};

export const RenderAddTask = ({
  allowMultiples,
  onSave,
  taskDescription,
  focus,
  full,
  listId,
  typeId,
  onBlur,
  order,
  ownerId,
  parentId,
  setShowAddTask,
  title,
  replyId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state["tasks"].filters);
  const projectId = useSelector((state) => state["tasks"].filters.projectId);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaceId = useSelector((state) => state["spaces"].selectedSpaceId);
  const lists = useSelector((state) => state["tasks"].lists);
  const user = useSelector((state) => state["account"].user);
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedSpaceId, setSelectedSpaceId] = useState(filters.spaceId);
  const [selectedListId, setSelectedListId] = useState();

  const [description, setDescription] = useState(
    taskDescription || [{ children: [{ text: "" }], type: "paragraph" }]
  );
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pinned, setPinned] = useState(false);
  const [tags, setTags] = useState([]);
  const [priorityId, setPriorityId] = useState();

  const [subject, setSubject] = useState(title);
  const [selectedUserId, setSelectedUserId] = useState(ownerId);
  const [selectedActivityTypeId, setSelecteActivityTypeId] = useState();

  const [uuid, setUuid] = useState(uuidv4());

  useEffect(() => {
    if (listId) {
      setSelectedListId(listId);
      setSelectedProjectId(lists[listId].project_id);
      setSelectedSpaceId(projects[lists[listId].project_id].space_id);
    }
  }, [lists, listId, projects, spaces]);

  const changeTaskDescription = (description) => {
    setDescription(description);
  };

  const changeSubject = (subject) => {
    setSubject(subject);
  };

  const addTask = ({ close = true }) => {
    if (!subject) return;
    if (!selectedListId) return;

    let task = {
      subject: subject,
      description: description,
      deadline: endDate,
      priority_id: priorityId,
      activity_id: selectedActivityTypeId,
      parent_id: parentId,
      type_id: typeId,
      owner_id: user.id,
      pinned: pinned,
      tags: tags
    };

    if (replyId) task.reply_id = replyId;
    if (startDate) task.start_date = startDate;
    if (order) task.order = order;

    task.group_id = selectedListId || filters.listId;

    let publicProject = projects[lists[task.group_id].project_id].public;

    if (!task.list_id) {
      task.project_id = projectId;
      task.space_id = spaceId;
    }

    setSubject();
    setDescription([{ children: [{ text: "" }], type: "paragraph" }]);
    setEndDate();
    setPriorityId();
    setPinned(false);

    if (selectedUserId && !publicProject) task.owner_id = selectedUserId;
    if (selectedProjectId) task.project_id = selectedProjectId;
    if (selectedListId) task.list_id = selectedListId;

    dispatch(ACTIONS.events.send("Task added", task));

    let actions = [
      ACTIONS.tasks.addTask,
      ACTIONS.toast.addToast({
        message: t("Created!"),
        type: "success"
      })
    ];

    if (onSave) actions.unshift(onSave);

    dispatch(ACTIONS.fetch.post("tasks", `${API_ROOT}/task`, actions, task));

    if (close && typeof setShowAddTask === "function") setShowAddTask(false);
    if (!close) setUuid(uuidv4());
  };

  let listName = "Inbox";

  if (listId) listName = lists[listId].name;
  if (listId === 0) listName = "ungrouped";
  if (filters.listId && filters.listId !== "0") {
    listName = lists[filters.listId].name;
  }

  let fullStyle = {};

  if (full)
    fullStyle = {
      bg: "l0",
      p: "1.5rem",
      borderRadius: "0.5rem",
      boxShadow: "2px 3px 6px -2px rgba(0,0,0,0.80)"
    };

  return (
    <Flex mx="0.25rem" flexDirection="column" width={1} {...fullStyle}>
      {full && (
        <Text textStyle="subtitle" mb="1.5rem">
          {typeId === 1 ? t("New task") : t("New event")}
        </Text>
      )}

      <Row label={t("Title")}>
        <Input
          key={"i" + uuid}
          placeholder={t("Add title")}
          onChange={changeSubject}
          focus={true}
          maxLength={80}
          width={1}
          showMaxLength={true}
          clearOnEnter={true}
          onEnter={addTask}
          // onEsc={() => setShowAddTask(false)}
          // onBlur={onBlur}
        />
      </Row>

      {full && (
        <Flex flexDirection="column" overflow="auto">
          {typeId === 1 && (
            <What
              tags={tags}
              setTags={setTags}
              selectedActivityTypeId={selectedActivityTypeId}
              setSelecteActivityTypeId={setSelecteActivityTypeId}
            />
          )}

          <Where
            selectedListId={selectedListId}
            setSelectedListId={setSelectedListId}
            selectedSpaceId={selectedSpaceId}
            setSelectedSpaceId={setSelectedSpaceId}
            selectedProjectId={selectedProjectId}
            setSelectedProjectId={setSelectedProjectId}
          />

          {typeId === 1 && (
            <Who
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId}
              selectedProjectId={selectedProjectId}
              selectedSpaceId={selectedSpaceId}
            />
          )}
          {typeId === 1 ? (
            <WhenTask
              endDate={endDate}
              setEndDate={setEndDate}
              pinned={pinned}
              setPinned={setPinned}
              priorityId={priorityId}
              setPriorityId={setPriorityId}
            />
          ) : (
            <WhenEvent
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}

          <Row mt="1rem" key={"e" + uuidv4} label={t("Description")}>
            <SimpleEditor
              autoFocus={false}
              placeholder={t("Write description")}
              enableToolbar={true}
              lineHeight={1.5}
              onSave={changeTaskDescription}
              onBlur={changeTaskDescription}
              enabled={true}
              value={description}
            />
          </Row>

          <Flex mt="1rem" alignItems="center" justifyContent="flex-end">
            <Button
              variant="secondary"
              style={{ marginRight: "0.5rem" }}
              onClick={() => setShowAddTask(false)}
              value={t("Cancel")}
            />

            {allowMultiples && (
              <Button
                variant="primary"
                disabled={
                  !subject || !selectedListId || (typeId === 2 && !startDate)
                }
                onClick={() => addTask({ close: false })}
                value={t("Save and create another")}
              />
            )}

            <Button
              variant="primary"
              style={{ marginLeft: "0.5rem" }}
              disabled={
                !subject || !selectedListId || (typeId === 2 && !startDate)
              }
              onClick={() => addTask({ close: true })}
              value={t("Save and exit")}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const AddTask = ({
  allowMultiples = true,
  onSave,
  description,
  full,
  typeId,
  listId,
  open = true,
  parentId,
  setOpen,
  title,
  focus,
  onBlur,
  order = null,
  ownerId,
  replyId
}) => {
  return (
    <>
      {full ? (
        <Overlay
          open={open}
          setOpen={setOpen}
          minHeight="625px"
          height="fit-content"
          maxHeight="85%"
          blocked={true}
        >
          <Flex width="60rem">
            <RenderAddTask
              allowMultiples={allowMultiples}
              onSave={onSave}
              full={true}
              listId={listId}
              typeId={typeId}
              showAddTask={open}
              setShowAddTask={setOpen}
              focus={focus}
              onBlur={onBlur}
              order={order}
              parentId={parentId}
              taskDescription={description}
              title={title}
              ownerId={ownerId}
              replyId={replyId}
            />
          </Flex>
        </Overlay>
      ) : (
        <Transition height="3rem" open={open} orientation="VERTICAL">
          <RenderAddTask
            allowMultiples={allowMultiples}
            onSave={onSave}
            full={false}
            listId={listId}
            typeId={typeId}
            showAddTask={open}
            setShowAddTask={setOpen}
            focus={focus}
            onBlur={onBlur}
            order={order}
            parentId={parentId}
            taskDescription={description}
            title={title}
            ownerId={ownerId}
            replyId={replyId}
          />
        </Transition>
      )}
    </>
  );
};

export default AddTask;
