import React from "react";

import { Flex } from "../Flex";
import { Text } from "../Text";
import { Overlay } from "../Overlay";

export const Dialog = ({
  title,
  children,
  open,
  setOpen,
  width = "50rem",
  height = "25rem",

  ...props
}) => {
  return (
    <Overlay open={open} setOpen={setOpen}>
      <Flex
        py="1.5rem"
        px="2rem"
        borderRadius="0.5rem"
        width={width}
        minWidth={width}
        minHeight={height}
        flexDirection="column"
        {...props}
        bg="l0"
        boxShadow="0px 0px 18px 0px rgba(0,0,0,0.5)"
        overflow="auto"
      >
        <Text textStyle="title">{title}</Text>

        <Flex mt="2rem" flexDirection="column" overflow="auto" height="100%">
          {children}
        </Flex>
      </Flex>
    </Overlay>
  );
};
