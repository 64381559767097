import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { orderObjects } from "tomato";

import { Spaces } from "./Spaces";
import { Users } from "./Users";

export const SideMenu = () => {
  const conversations = useSelector(
    (state) => state["conversations"].conversations
  );

  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  let activeSpaces = [];
  let activeUsers = [];

  useEffect(() => {}, [conversations]);

  const orderedConversations = orderObjects(
    conversations,
    "last_message_timestamp",
    "desc"
  );

  orderedConversations.forEach((conversation) => {
    let userId = parseInt(conversation.member);
    if (
      conversation.type === "D" &&
      users[userId] &&
      userId !== user.id &&
      !users[userId].is_deleted &&
      users[userId].is_active
    ) {
      activeUsers.push(userId);
    } else if (conversation.type === "S") {
      let spaceId = conversation.uuid.split(".")[1];
      let isMember =
        spaces[spaceId] &&
        spaces[spaceId].members.some(
          (member) => parseInt(member.id) === user.id
        );
      if (isMember) activeSpaces.push(spaceId);
    }
  });

  return (
    <>
      <Spaces activeSpaces={activeSpaces} />
      <Users activeUsers={activeUsers} />
    </>
  );
};
