import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Card,
  EmojiPicker,
  Flex,
  FlexHidden,
  FlexWrapper,
  formatDate,
  Icon,
  Input,
  SimpleEditor,
  Text,
  uuidv4
} from "tomato";

import { API_ROOT, ROOT_URL } from "config";

export const ShortAnswer = ({ item }) => {
  return <Input />;
};

export const LongAnswer = ({ item }) => {
  return <SimpleEditor />;
};
