import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { followCursor } from "tippy.js";
import Tippy from "@tippyjs/react/headless";

import { Events } from "../Events";
import { Flex, Text } from "tomato";
import { getWeek } from "../util";
import { StyledText } from "../StyledText";

const AddEvent = ({ children }) => {
  const [instance, setInstance] = useState(null);

  console.log(instance);
  return (
    <Tippy
      // placement="top"
      trigger="click"
      interactive={true}
      // offset={[0, 4]}
      // appendTo="parent"
      followCursor="initial"
      plugins={[followCursor]}
      render={(attrs) => (
        <>
          {instance ? (
            <Flex
              width="20rem"
              bg="red"
              {...attrs}
              // setSelectedOption={setSelectedOption}
              // selectedOption={selectedOption}
              // onSelect={changeOption}
              // options={options}
              // hide={instance.hide}
              // show={instance.show}
            >
              Teste
            </Flex>
          ) : (
            ""
          )}
        </>
      )}
      onCreate={setInstance}
    >
      {children}
    </Tippy>
  );
};
// <Text width={1} height="100%" onClick={(e) => onClick(e, day.date)}>
//  teste
// </Text>
// <AddEvent>
// </AddEvent>
const Day = ({ day, range, events, users, onClick }) => {
  return (
    <Flex
      flexDirection="column"
      flexGrow="1"
      flexShrink="0"
      flexBasis="0%"
      position="relative"
      overflowY="hidden"
      borderRight="1px solid lightGrey"
      onClick={(e) => onClick && onClick(e, day.date)}
      label="CalendarDay"
    >
      <Flex
        width={1}
        height="100%"
        position="absolute"
        flexDirection="column"
        p="0.5rem"
      >
        <StyledText mb="0.25rem" width={1} day={day} range={range}>
          {day.date.getDate()}
        </StyledText>

        <Events events={events[day.key]} users={users} />
      </Flex>
    </Flex>
  );
};

export const RenderWeekDays = () => {
  const { t } = useTranslation();

  const weekdays = [
    t("Sun"),
    t("Mon"),
    t("Tue"),
    t("Wed"),
    t("Thu"),
    t("Fri"),
    t("Sat")
  ];

  return (
    <Flex width={1} height="1.5rem" label="CalendarRow">
      {weekdays.map((day, index) => (
        <Text
          mx="auto"
          color="t4"
          fontWeight="bold"
          fontSize="0.625rem"
          minWidth="6rem"
          key={index}
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            verticalAlign: "middle"
          }}
        >
          {t(day)}
        </Text>
      ))}
    </Flex>
  );
};

export const RenderWeek = ({ events, range, week, index, users, onClick }) => {
  return (
    <Flex
      width={1}
      flexShrink="0"
      flexBasis="0%"
      flexGrow="1"
      label="CalendarRow"
      borderBottom="1px solid lightGrey"
      borderLeft="1px solid lightGrey"
      borderTop={!index ? "1px solid lightGrey" : ""}
    >
      {week.map((day, index) => (
        <Day
          key={index}
          day={day}
          range={range}
          events={events}
          users={users}
          onClick={onClick}
        />
      ))}
    </Flex>
  );
};

export const Week = ({
  events,
  date,
  startDate,
  range,
  endDate,
  users,
  onClick
}) => {
  let week = getWeek(date, startDate, endDate);

  return (
    <Flex
      width={1}
      flexDirection="column"
      height="100%"
      flexShrink="0"
      flexBasis="0%"
      flexGrow="1"
      overflow="hidden"
      position="relative"
      label="CalendarWrapper"
    >
      <RenderWeekDays />

      <RenderWeek
        week={week.dates}
        range={range}
        events={events}
        users={users}
        onClick={onClick}
      />
    </Flex>
  );
};
