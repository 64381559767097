import React from "react";

import Icon from "../Icon";

const RenderIcon = props => {
  return (
    <Icon {...props}>
      <path d="M21.25,20.25c0,1.105-0.895,2-2,2H4.75c-1.105,0-2-0.895-2-2V9.75c0-1.105,0.895-2,2-2   c0,0,16.375,5.875,16.5,6V20.25z" />
      <path d="M21.25,20.25c0,1.105-0.895,2-2,2H4.75c-1.105,0-2-0.895-2-2V9.75c0-1.105,0.895-2,2-2   c0,0,16.375,5.875,16.5,6V20.25z" />
      <path d="M6.687,19.125v-2" />
      <path d="M10.148,19.125v-2" />
      <path d="M13.609,19.125v-2" />
      <path d="M17.07,19.125v-2" />
      <path d="M11.125,10.125V3.054c0-0.73-0.574-1.304-1.304-1.304H8.547c-0.73,0-1.304,0.574-1.304,1.304V8.75" />
      <path d="M18.25,13.375V6.429c0-0.73-0.574-1.304-1.304-1.304h-1.274c-0.73,0-1.304,0.574-1.304,1.304v5.321" />

      <path d="M18.25,13.375V6.429c0-0.73-0.574-1.304-1.304-1.304h-1.274c-0.73,0-1.304,0.574-1.304,1.304v5.321" />
      <line x1="3.125" y1="14.125" x2="20.75" y2="14.125" />
    </Icon>
  );
};

export default RenderIcon;
