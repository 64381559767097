import React from "react";

import { Audio } from "tomato";
import { File } from "./File";
import { Flex, Image as Emoji, Text } from "../../";
import { Mention } from "./Mention";

export const Element = ({ attributes, children, element, fileRootUrl }) => {
  switch (element.type) {
    case "audio":
      return (
        <Audio
          controls
          controlsList="nodownload"
          src={`${fileRootUrl}/${element.url}`}
        >
          Erro no áudio
        </Audio>
      );

    case "block-quote":
      return (
        <Flex
          {...attributes}
          bg="lightBlue"
          borderRadius="0.25rem"
          px="1rem"
          py="0.5rem"
        >
          {children}
        </Flex>
      );

    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;

    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;

    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;

    case "emoji":
      return (
        <Emoji
          mb="-6px"
          mx="2px"
          width="22px"
          height="22px"
          src={"https://static.elefante.com/emojis/" + element.id + ".png"}
        />
      );

    case "image":
      return (
        <File
          attributes={attributes}
          element={element}
          fileRootUrl={fileRootUrl}
        >
          {children}
        </File>
      );

    case "file":
      return (
        <File
          attributes={attributes}
          element={element}
          fileRootUrl={fileRootUrl}
        >
          {children}
        </File>
      );

    case "link":
      return (
        <a
          {...attributes}
          style={{ cursor: "pointer" }}
          href={element.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );

    case "list-item":
      return <li {...attributes}>{children}</li>;

    case "mention":
      return (
        <Mention attributes={attributes} element={element}>
          {children}
        </Mention>
      );

    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;

    default:
      return (
        <p style={{ marginBottom: "1rem" }} {...attributes}>
          {children}
        </p>
      );
  }
};
