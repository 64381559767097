import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Confirm, Dropdown, Flex } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

const TransferTask = ({ open, setOpen, task }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const users = useSelector((state) => state["spaces"].users);
  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);

  const [selectedUser, setSelectedUser] = useState(
    task.owner_id
      ? {
          id: task.owner_id,
          value: users[task.owner_id].name,
          avatar: users[task.owner_id].avatar
        }
      : null
  );

  const transferTask = () => {
    dispatch(
      ACTIONS.tasks.updateAttribute(task.id, "owner_id", selectedUser.id)
    );
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { owner_id: selectedUser.id }
      )
    );
    setOpen(false);
  };

  const listGroup = lists[task.list_id];
  const project = projects[listGroup.project_id];
  const spaceId = project.space_id;
  const space = spaces[spaceId];

  let membersOptions = space
    ? space.members
        .filter(
          (member) =>
            users[member.id] &&
            users[member.id].is_active &&
            !users[member.id].is_deleted
        )
        .map((member) => {
          return {
            id: member.id,
            value: users[member.id].name,
            avatar: users[member.id].avatar
          };
        })
    : [];

  let enableConfirm = false;
  if (selectedUser && parseInt(selectedUser.id) !== task.owner_id) {
    enableConfirm = true;
  }

  if (task.done_date) return null;

  return (
    <Confirm
      open={open}
      setOpen={setOpen}
      title={t("Assign task")}
      yesText={t("Assign")}
      onConfirm={transferTask}
      enableConfirm={enableConfirm}
      onEnter={setSelectedUser}
    >
      <Flex width={1} justifyContent="center">
        <Dropdown
          minWidth="20rem"
          options={membersOptions}
          onSelect={setSelectedUser}
          placeholder={t("Choose owner")}
          selected={selectedUser}
        />
      </Flex>
    </Confirm>
  );
};

export default TransferTask;
