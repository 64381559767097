import { RRule } from "rrule";

import { t } from "i18n";

// Days
const last = [
  { id: -3, value: t("antepenultimate") },
  { id: -2, value: t("penultimate") },
  { id: -1, value: t("last") }
];

export const daysOptions = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 },
  { id: 6, value: 6 },
  { id: 7, value: 7 },
  { id: 8, value: 8 },
  { id: 9, value: 9 },
  { id: 10, value: 10 },
  { id: 11, value: 11 },
  { id: 12, value: 12 },
  { id: 13, value: 13 },
  { id: 14, value: 14 },
  { id: 15, value: 15 },
  { id: 16, value: 16 },
  { id: 17, value: 17 },
  { id: 18, value: 18 },
  { id: 19, value: 19 },
  { id: 20, value: 20 },
  { id: 21, value: 21 },
  { id: 22, value: 22 },
  { id: 23, value: 23 },
  { id: 24, value: 24 },
  { id: 25, value: 25 },
  { id: 26, value: 26 },
  { id: 27, value: 27 },
  { id: 28, value: 28 },
  { id: 29, value: 29 },
  { id: 30, value: 30 },
  { id: 31, value: 31 },
  ...last
];

export const workingDaysOptions = [
  { id: 1, value: t("1th") },
  { id: 2, value: t("2th") },
  { id: 3, value: t("3th") },
  { id: 4, value: t("4th") },
  { id: 5, value: t("5th") },
  { id: 6, value: t("6th") },
  { id: 7, value: t("7th") },
  { id: 8, value: t("8th") },
  { id: 9, value: t("9th") },
  { id: 10, value: t("10th") },
  { id: 11, value: t("11th") },
  { id: 12, value: t("12th") },
  { id: 13, value: t("13th") },
  { id: 14, value: t("14th") },
  { id: 15, value: t("15th") },
  { id: 16, value: t("16th") },
  { id: 17, value: t("17th") },
  { id: 18, value: t("18th") },
  { id: 19, value: t("19th") },
  { id: 20, value: t("20th") },
  { id: 21, value: t("21th") },
  { id: 22, value: t("22th") },
  { id: 23, value: t("23th") },
  ...last
];

export const monthOptions = [
  { id: 1, value: t("On day") },
  { id: 2, value: t("On working day") },
  { id: 3, value: t("On first") },
  { id: 4, value: t("On second") },
  { id: 5, value: t("On third") },
  { id: 6, value: t("On fourth") },
  { id: 7, value: t("On last") }
];

// Months

export const months = {
  1: t("Jan"),
  2: t("Feb"),
  3: t("Mar"),
  4: t("Apr"),
  5: t("May"),
  6: t("Jun"),
  7: t("Jul"),
  8: t("Aug"),
  9: t("Sep"),
  10: t("Oct"),
  11: t("Nov"),
  12: t("Dec")
};

export const monthsOptions = [
  { id: 1, value: t("Jan") },
  { id: 2, value: t("Feb") },
  { id: 3, value: t("Mar") },
  { id: 4, value: t("Apr") },
  { id: 5, value: t("May") },
  { id: 6, value: t("Jun") },
  { id: 7, value: t("Jul") },
  { id: 8, value: t("Aug") },
  { id: 9, value: t("Sep") },
  { id: 10, value: t("Oct") },
  { id: 11, value: t("Nov") },
  { id: 12, value: t("Dec") }
];

// Weekdays

export const weekdays = {
  0: RRule.MO,
  1: RRule.TU,
  2: RRule.WE,
  3: RRule.TH,
  4: RRule.FR,
  5: RRule.SA,
  6: RRule.SU
};

export const weekdayPosition = {
  3: 1,
  4: 2,
  5: 3,
  6: 4,
  7: -1
};

export const reversedWeekdayPosition = {
  1: 3,
  2: 4,
  3: 5,
  4: 6,
  "-1": 7
};
export const weekDaysOptions = [
  { id: 6, value: t("Sunday") },
  { id: 0, value: t("Monday") },
  { id: 1, value: t("Tuesday") },
  { id: 2, value: t("Wednesday") },
  { id: 3, value: t("Thursday") },
  { id: 4, value: t("Friday") },
  { id: 5, value: t("Saturday") }
];
