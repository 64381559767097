import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { animated, useTransition } from "react-spring";

import {
  Button,
  Flex,
  getShadow,
  Icon,
  Image,
  Input,
  Text,
  useLocalStorage
} from "tomato";
import { ACTIONS } from "store";

import { ROOT_URL } from "config";

export const Login = () => {
  const dispatch = useDispatch();

  const [doingLogin, setDoingLogin] = useState(false);
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState(false);
  const [login, setLogin] = useLocalStorage("login", { rememberLogin: false });
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // if (login.rememberLogin) setEmail(login.email);
  }, [login.email, login.rememberLogin]);

  useEffect(() => {
    validateEmail(email);
    // if (login.rememberLogin) setLogin({ ...login, email });
  }, [email, login, setLogin]);

  const validateEmail = (email) => {
    if (!email) {
      setEmailValid(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  };

  const validatePassword = (password) => {
    setPassword(password);
    if (password.length > 4) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const handleSubmit = () => {
    dispatch(
      ACTIONS.fetch.post(
        "account",
        `${ROOT_URL}/login/do_auth/`,
        ACTIONS.account.loginDone,
        { email, password }
      )
    );
  };

  const doLogin = () => {
    if (validateEmail && validatePassword) handleSubmit();
  };

  const handleRemember = () => {
    let shouldRemember = !login.rememberLogin;
    let rememberLogin = { rememberLogin: shouldRemember };
    if (shouldRemember && email) {
      rememberLogin.email = email;
    } else {
      delete rememberLogin.email;
    }
    setLogin({ ...rememberLogin });
  };

  let buttonText = doingLogin ? t("Working...") : t("Login");

  const [toggle, set] = useState(false);
  const transitions = useTransition(toggle, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const AnimatedImage = animated(Image);

  return (
    <Flex
      width={1}
      height="100%"
      justifyContent="center"
      alignItems="center"
      bg="l3"
    >
      <Image
        position="absolute"
        top="0"
        left="0"
        height="2rem"
        ml="1rem"
        mt="0.5em"
        mb="1.5em"
        src="/images/logo/logo.svg"
      />

      <Flex
        width={0.5}
        height="100%"
        alignItems="center"
        justifyContent="flex-end"
        pr="0.5rem"
      >
        {transitions.map(({ item, key, props }) => (
          <AnimatedImage
            key={key}
            style={props}
            height="18em"
            src={item ? "/images/login/closed.svg" : "/images/login/open.svg"}
            onClick={() => set(item ? false : true)}
          />
        ))}
      </Flex>

      <Flex width={0.5} height="100%" alignItems="center" pl="0.5rem">
        <Flex
          width="25em"
          flexDirection="column"
          borderRadius="1em"
          p="1.5em"
          bg="l0"
          {...getShadow("s0")}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Input
              label="Email"
              placeholder={t("Enter your email")}
              onChange={setEmail}
              flexGrow="1"
            />
            <Icon.Ok
              minWidth={18}
              minHeight={18}
              ml={2}
              stroke={emailValid ? "green" : "lightGrey"}
              style={{ strokeWidth: "4px" }}
            />
          </Flex>

          <Flex mt={2} alignItems="center" justifyContent="space-between">
            <Input
              label={t("Password")}
              type={showPassword ? "text" : "password"}
              placeholder={t("Enter your password")}
              onChange={validatePassword}
              rightIcon={showPassword ? "Eye" : "EyeOff"}
              rightIconClick={() => setShowPassword(!showPassword)}
              flexGrow="1"
              onEnter={doLogin}
              onFocus={() => set(true)}
              onBlur={() => set(false)}
            />
            <Icon.Ok
              minWidth={18}
              minHeight={18}
              ml={2}
              mb={-4}
              stroke={passwordValid ? "green" : "lightGrey"}
              style={{ strokeWidth: "4px" }}
            />
          </Flex>

          <Flex mt="1rem" justifyContent="flex-end">
            <Button
              variant={emailValid && passwordValid ? "primary" : "primary"}
              disabled={!(emailValid && passwordValid)}
              onClick={handleSubmit}
              onEnter={handleSubmit}
              value={buttonText}
            />
          </Flex>

          <Flex alignItems="center" mt={2}>
            <input
              type="checkbox"
              defaultChecked={login.email}
              onClick={handleRemember}
            />
            <Text color="t3" fontSize={14}>
              {t("Remember my login")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
