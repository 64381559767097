import { animated } from "react-spring";
import styled, { keyframes } from "styled-components";
import {
  border,
  color,
  grid,
  layout,
  position,
  shadow,
  space,
  typography
} from "styled-system";
 
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
 
export const Box = styled("div")`
   box-sizing: border-box;
   ${border}
   ${color}
   ${grid}
   ${layout}
   ${position}
   ${shadow}
   ${space}
   ${typography}      
   ${(props) =>
     props.hoverColor &&
     `
      &:hover {
        background-color: ${props.theme.colors[props.hoverColor]};
      }
    `} 
    ${(props) =>
     props.size &&
     `
        width: ${props.size};
        minWidth: ${props.size};
        height: ${props.size};     
        minHeight: ${props.size};     
      }
    `} 
 

`;
  
export const RotatingBox = styled(Box)`
  animation: ${rotate360} 0.5s linear infinite;
`;                

export const AnimatedBox =  animated(Box)
// ${(props) => {
//   if (!props.before) return;
//   console.log(props.before);
//   const before = props.before;

//   return `
//     &:before {
//     content: "🦄";
//     content: "${props.before.content}"
//     }
//   `;
// }}
// ${props =>
//  props.border &&
//  `
//     border: 1px solid ${props.theme.colors.t5};
// `}
// Box.propTypes = {
//   ...border.propTypes,
//   ...color.propTypes,
//   ...layout.propTypes,
//   ...position.propTypes,
//   ...space.propTypes,
//   ...typography.propTypes
// };
