import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Input } from "tomato";

import { ACTIONS } from "store";

export const Search = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const search = useSelector((state) => state["tasks"].filters.search);

  const doSearch = (term) => {
    dispatch(ACTIONS.tasks.setFilter("search", term ? term : ""));
  };

  const clearSearch = () => {
    dispatch(ACTIONS.tasks.setFilter("search", ""));
  };

  return (
    <Input
      mb="1rem"
      placeholder={t("Search")}
      leftIcon="Search"
      onClear={clearSearch}
      value={search}
      onEnter={doSearch}
      debounceTime={800}
      enableClear={true}
    />
  );
};

export default Search;
