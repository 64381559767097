import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex, Text } from "tomato";

import { ACTIONS } from "store";
import { Dots } from "./Input";
import { PulsingDot } from "components";

export const Space = ({ space }) => {
  const dispatch = useDispatch();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );

  let acc = `S.${space.id}`;
  const conversation = useSelector(
    (state) => state["conversations"].conversations[acc]
  );

  const typing = useSelector((state) => state["conversations"].typing);
  const user = useSelector((state) => state["account"].user);

  const [isTyping, setIsTyping] = useState(false);
  const timer = useRef();

  let active = activeConversation === acc;

  useEffect(() => {
    if (!typing || typing.acc[0] !== "S") return;
    if (typing.toId !== space.id || typing.fromId === user.id) return;

    setIsTyping(true);
    timer.current = window.setTimeout(() => {
      window.clearTimeout(timer);
      setIsTyping(false);
    }, 6000);
  }, [activeConversation, typing, space.id, user.id]);

  let unreadMessages =
    !active && conversation && Object.keys(conversation.messages).length
      ? conversation.last_message_timestamp >
        conversation.last_message_timestamp_read_by_you
      : false;

  const startConversation = () => {
    dispatch(
      ACTIONS.events.send("Start space conversation", {
        space: space.name,
        spaceId: space.id
      })
    );

    dispatch(
      ACTIONS.conversations.startConversation({
        acc,
        type: "S",
        toId: space.id
      })
    );
  };

  return (
    <Flex
      borderRadius={4}
      hoverColor="l3"
      bg={active ? "l3" : ""}
      style={{ cursor: "pointer" }}
      onClick={startConversation}
      alignItems="center"
      my="0.125rem"
      pl="0.5rem"
      pr="0.25rem"
      py="0.5rem"
      label="userConversation"
    >
      <Text
        mr="0.25rem"
        fontSize="0.875rem"
        color={active ? "t1" : "t4"}
        fontWeight={active ? "bold" : "semibold"}
      >
        #
      </Text>

      <Text
        // fontSize="0.875rem"
        // fontSize="1rem"
        color={active ? "t1" : "t4"}
        // fontWeight={active ? "bold" : "semibold"}
        fontWeight="semibold"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {space.name}
      </Text>

      {isTyping && (
        <Flex mr={0} ml="auto">
          <Dots />
          <Dots />
          <Dots />
        </Flex>
      )}

      {unreadMessages && <PulsingDot mr={0} ml="auto" />}
    </Flex>
  );
};
