import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Action, Avatar, Flex, formatDate, Icon, Image, Text } from "tomato";

import { Header } from "components";

const Space = () => {
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );
  const spaces = useSelector((state) => state["spaces"].spaces);
  const ws = useSelector((state) => state["ws"].ws);

  let [, spaceId] = activeConversation.split(".");

  const refreshMessages = () => {
    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "getMessages",
        data: {
          acc: activeConversation
        }
      })
    );
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Text mr="0.25rem" fontSize="1.25rem" fontWeight="bold" color="t1">
          #
        </Text>

        <Text my="auto" fontSize="1.25rem" fontWeight="bold" color="t1">
          {spaces[spaceId].name}
        </Text>

        {false && (
          <Action
            ml="1rem"
            alignItems="center"
            py="0.25rem"
            px="0.25rem"
            onClick={refreshMessages}
          >
            <Text
              ml="0.25rem"
              width="fit-content"
              fontSize="0.75rem"
              color="t3"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}
            >
              {t("Update messages")}
            </Text>
          </Action>
        )}
      </Flex>
    </Flex>
  );
};

const User = () => {
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );
  const conversation = useSelector(
    (state) => state["conversations"].conversations[activeConversation]
  );
  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const ws = useSelector((state) => state["ws"].ws);

  if (!conversation) return null;

  const userId = conversation.member;
  const online = onlineUsersIds.includes(userId);

  const refreshMessages = () => {
    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "getMessages",
        data: {
          acc: activeConversation
        }
      })
    );
  };

  let statusMessage;

  if (onlineUsersIds.includes(userId)) {
    statusMessage = t("Online");
  } else if (users[userId].last_interaction_date) {
    statusMessage = formatDate(
      users[userId].last_interaction_date,
      user.locale,
      user.timezone
    ).distance();
  } else {
    statusMessage = t("Never seen");
  }

  return (
    <Flex alignItems="center">
      <Flex>
        <Avatar
          size="large"
          src={users[userId].avatar}
          showOnline={true}
          online={online}
        />
        <Flex ml="0.5rem" flexDirection="column">
          <Text fontSize="1.25rem" fontWeight="bold" color="t1">
            {users[userId].name}
          </Text>
          <Text ml="0.25rem" color="t3" fontSize="0.75rem">
            {statusMessage}
          </Text>
        </Flex>
      </Flex>

      {online && users[userId].status && (
        <Flex alignItems="center" ml="1rem">
          {users[userId].status.emojiCode && (
            <Image
              width="1.5rem"
              src={`https://static.elefante.com/emojis/${users[userId].status.emojiCode}.png`}
            />
          )}
          {users[userId].status.message && (
            <Text ml="0.25rem" fontSize="1rem">
              {users[userId].status.message}
            </Text>
          )}
          {users[userId].status.do_not_disturb && (
            <Text
              ml="0.5rem"
              fontSize="0.75rem"
              bg="red"
              px="0.25rem"
              py="0.125rem"
              fontWeight="semibold"
              color="t0"
              borderRadius="0.25rem"
            >
              {t("Do not disturb")}
            </Text>
          )}
        </Flex>
      )}

      {false && (
        <Action ml="1rem" fontSize="0.75rem" onClick={refreshMessages}>
          {t("Update messages")}
        </Action>
      )}
    </Flex>
  );
};

const ConversationsHeader = () => {
  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );

  return (
    <Header>
      {activeConversation.startsWith("D") && <User />}
      {activeConversation.startsWith("S") && <Space />}
    </Header>
  );
};

export { ConversationsHeader as Header };
