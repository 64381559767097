import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

import { Conversations } from "./Conversations";
import { DEFAULT_MARGIN, DEFAULT_SIDEMENU_WIDTH } from "constant";
import { MainWindow, SideMenu } from "components";
import { MembersList } from "components";
import { Profile } from "./Profile";
import { SideMenu as Menu } from "./SideMenu";

export const Conversations_ = ({ update }) => {
  const { t } = useTranslation();

  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );
  let [, spaceId] = activeConversation.split(".");

  useEffect(() => {
    document.title = t("Conversations");
    return () => (document.title = "Elefante");
  }, [t]);

  if (update) return <Conversations />;

  return (
    <>
      <SideMenu
        width={DEFAULT_SIDEMENU_WIDTH}
        minWidth={DEFAULT_SIDEMENU_WIDTH}
      >
        <Text p={DEFAULT_MARGIN} textStyle="title">
          {t("Chat")}
        </Text>

        <Flex flexDirection="column" overflow="auto" px={DEFAULT_MARGIN}>
          <Menu />
        </Flex>
      </SideMenu>
      <MainWindow>
        <Conversations />
      </MainWindow>

      {false && (
        <SideMenu>
          {activeConversation && activeConversation.startsWith("S") && (
            <MembersList spaceId={spaceId} />
          )}
          {activeConversation && activeConversation.startsWith("D") && (
            <Profile />
          )}
        </SideMenu>
      )}
    </>
  );
};

export { Conversations_ as Conversations };
