import React, { useState } from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Action,
  Button,
  Column,
  Dropdown,
  isEmptyContent,
  Editor,
  Flex,
  Overlay,
  Row,
  strip,
  Text,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";

const Who = ({ userId, spaceId, setUserId, setSpaceId }) => {
  const { t } = useTranslation();

  let spaces = useSelector((state) => state["spaces"].spaces);
  let users = useSelector((state) => state["spaces"].users);
  let user = useSelector((state) => state["account"].user);

  let members = spaceId ? spaces[spaceId].members : [];

  let membersOptions = members
    .filter(
      (member) =>
        member.id !== user.id &&
        users[member.id].is_active &&
        !users[member.id].is_deleted
    )
    .map((member) => {
      return {
        id: member.id,
        value: users[member.id].name,
        avatar: users[member.id].avatar
      };
    });

  let spacesOptions = Object.keys(spaces).map((spaceId) => {
    return { id: spaceId, value: spaces[spaceId].name };
  });

  return (
    <Row label={t("Who")}>
      <Dropdown
        width={1 / 2}
        key={`s${spaceId}`}
        options={spacesOptions}
        onSelect={(option) => {
          setSpaceId(parseInt(option.id));
          setUserId();
        }}
        placeholder={t("Choose space")}
        selected={
          spaceId
            ? {
                id: spaceId,
                value: spaces[spaceId].name
              }
            : null
        }
      />

      {spaceId && (
        <Dropdown
          key={spaceId}
          ml="1rem"
          width={1 / 2}
          options={membersOptions}
          onSelect={(member) => setUserId(member.id)}
          placeholder={t("Who")}
          selected={userId ? { id: userId, value: users[userId].name } : null}
        />
      )}
    </Row>
  );
};

const RenderMessage = ({ draft, setOpen, setOpenChat }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);
  const ws = useSelector((state) => state["ws"].ws);

  const [userId, setUserId] = useState();
  const [spaceId, setSpaceId] = useState();

  const [message, setMessage] = useState(draft);

  let acc =
    user.id < userId ? `D.${user.id}.${userId}` : `D.${userId}.${user.id}`;

  const sendMessage = () => {
    const msgUuid = null;
    let mentions = null;

    const payload = {
      type: "conversations",
      action: "message",
      data: {
        acc,
        userId: user.id,
        message: JSON.stringify(message),
        fromName: user.name,
        mentions,
        summary: strip(message.map((n) => Node.string(n)).join(" ")).slice(
          0,
          50
        ),
        msgUuid
      }
    };

    dispatch(
      ACTIONS.conversations.insertMessage({
        ...payload.data,
        currentUserId: user.id,
        fromId: user.id,
        timestap: Date.now(),
        creationDate: new Date()
      })
    );

    setOpen(false);
    ws.send(JSON.stringify(payload));
    setOpenChat(true);
    dispatch(
      ACTIONS.events.send("Start dm conversation", {
        acc,
        fromId: user.id,
        toId: userId
      })
    );

    dispatch(
      ACTIONS.conversations.startConversation({
        acc,
        toId: user.id,
        type: "D"
      })
    );
    setOpenChat(true);
    dispatch(
      ACTIONS.events.send("Start dm conversation", {
        acc,
        fromId: user.id,
        toId: userId
      })
    );

    dispatch(
      ACTIONS.conversations.startConversation({
        acc,
        toId: userId,
        type: "D"
      })
    );
  };

  return (
    <Column
      width="50rem"
      bg="l0"
      p="1rem"
      borderRadius="0.5rem"
      overflow="auto"
    >
      <Flex flexDirection="column" width={1} overflow="auto">
        <Text textStyle="subtitle" mb="1.5rem">
          {t("Send message")}
        </Text>

        <Who
          userId={userId}
          setUserId={setUserId}
          spaceId={spaceId}
          setSpaceId={setSpaceId}
        />

        <Row
          mt="1rem"
          key={"e" + uuidv4}
          label={t("Description")}
          overflow="auto"
        >
          <Editor
            editorStyle={{
              style: {
                position: "relative",
                padding: "1rem",
                lineHeight: 1.5,
                paddingTop: "3rem"
              }
            }}
            borderRadius="0.5rem"
            border="1px solid lightGrey"
            mt="1rem"
            position="relative"
            placeholder={t("write your notes, toughts, anything here...")}
            value={message}
            enableExit={false}
            onChange={(content) => setMessage(content)}
          />
        </Row>
      </Flex>

      <Flex mt="1rem" alignItems="center" justifyContent="flex-end">
        <Button
          variant="secondary"
          style={{ marginRight: "0.5rem" }}
          onClick={() => setOpen(false)}
          value={t("Cancel")}
        />

        <Button
          variant="primary"
          style={{ marginLeft: "0.5rem" }}
          disabled={!userId || isEmptyContent(message)}
          onClick={() => sendMessage({ close: true })}
          value={t("Send")}
        />
      </Flex>
    </Column>
  );
};

export const SendMessage = ({ draft, setOpenChat }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Overlay
        open={open}
        setOpen={setOpen}
        height="fit-content"
        width="fit-content"
        maxHeight="85%"
        blocked={true}
      >
        <RenderMessage
          draft={draft}
          setOpen={setOpen}
          setOpenChat={setOpenChat}
        />
      </Overlay>

      <Action onClick={() => setOpen(true)}>{t("Send to someone")}</Action>
    </>
  );
};
