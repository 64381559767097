import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Action,
  Flex,
  FilesPreview,
  FormatDate,
  Icon,
  Image,
  Text,
  Upload,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, ROOT_URL } from "config";
import { Header } from "components";

const RenderFile = ({ index, file, page, setPreviewIndex, taskId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let fileUrl = `${ROOT_URL}/shared/${file.filename}`;
  let type = file.content_type.split("/");
  const users = useSelector((state) => state["spaces"].users);

  const downloadFile = () => {
    window.open(fileUrl, "_blank");
  };

  const deleteFile = () => {
    dispatch(
      ACTIONS.fetch.delete(
        "pages",
        `${API_ENDPOINT}/file/${file.id}`,
        ACTIONS.state.loadObjects,
        (state, response) => {
          console.log(response);
          const files = state.page.files.filter(
            (pageFile) => pageFile.id !== file.id
          );
          state.page.files = files;
          state.pages[page.id].files = files;

          return state;
        }
      )
    );
    dispatch(
      ACTIONS.toast.addToast({
        message: t("File deleted"),
        type: "success",
        autoClose: 1000
      })
    );
  };

  const extension = () => {
    if (!file) return "";
    let txt = file.name.split(".");
    return txt[txt.length - 1].slice(0, 4);
  };

  return (
    <>
      <Flex
        mb="0.5rem"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => setPreviewIndex(index)}
      >
        <Flex width={50} alignItems="center">
          {type[0] === "image" ? (
            <Image
              borderRadius="0.25rem"
              border="1px solid lightGrey"
              width={1}
              maxHeight={50}
              maxWidth={50}
              src={fileUrl}
              style={{
                cursor: "pointer"
              }}
            />
          ) : (
            <Flex
              bg="white"
              width={1}
              height={50}
              borderRadius={4}
              border="1px solid lightGrey"
            >
              <Text
                m="auto"
                fontSize="12px"
                fontWeight="bold"
                style={{
                  cursor: "pointer",
                  textTransform: "uppercase",
                  textAlign: "center",
                  verticalAlign: "middle"
                }}
              >
                {extension()}
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex flexDirection="column" ml="1rem">
          <Text
            width={1}
            fontSize="0.875rem"
            color="t1"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }}
          >
            {file.name}
          </Text>

          <Text fontSize="0.75rem" color="t2">
            {users[file.created_by_id].name}
            <br />
            {FormatDate.Distance(file.creation_date)}
          </Text>
        </Flex>

        <Flex alignItems="center" ml="auto">
          <Icon.Download
            onClick={downloadFile}
            width="16px"
            minWidth="16px"
            height="16px"
            minHeight="16px"
            style={{ cursor: "pointer" }}
          />
          <Icon.Trash
            onClick={deleteFile}
            width="16px"
            minWidth="16px"
            height="16px"
            minHeight="16px"
            style={{ cursor: "pointer" }}
            ml={2}
          />
        </Flex>
      </Flex>
    </>
  );
};

export const Files = () => {
  const dispatch = useDispatch();

  const item = useSelector((state) => state["pages"].item);
  const page = useSelector((state) => state["pages"].pages[item.id]);

  const [key, setKey] = useState(uuidv4());
  const [previewIndex, setPreviewIndex] = useState();
  const [open, setOpen] = useState(false);

  const endAction = (response) => {
    dispatch(
      ACTIONS.state.changeState("pages", (state) => {
        if (state.page.files) {
          state.page.files.unshift(response);
          state.pages[page.id].files.unshift(response);
        } else {
          state.page.files = [response];
          state.pages[page.id].files = [response];
        }
        return state;
      })
    );
    setOpen(false);
    setKey(uuidv4());
  };

  const files = page.files;

  useEffect(() => {
    if (page && !page.files)
      dispatch(
        ACTIONS.fetch.get(
          "pages",
          `${API_ENDPOINT}/file?page_id=${page.id}&limit=0`,
          ACTIONS.state.loadObjects,
          (state, response) => {
            // state.page.files = response.objects;
            state.pages[page.id].files = response.objects;
            return state;
          }
        )
      );
  }, [dispatch, page]);

  return (
    <Flex flexDirection="column" px="2rem">
      <Header>
        <Flex width={1} alignItems="center" justifyContent="space-between">
          <Text textStyle="subtitle">Arquivos</Text>
          <Action onClick={() => setOpen(!open)}>Novo</Action>
        </Flex>
      </Header>

      {open && (
        <Upload
          key={key}
          maxFiles={5}
          maxFileSize={10}
          endpoint={`${API_ENDPOINT}/upload`}
          endAction={endAction}
          id={{ module: "PAGE", page_id: page.id }}
        />
      )}

      <Flex width={1} mt="1rem" flexDirection="column">
        {files &&
          files.map((file, index) => (
            <RenderFile
              taskId={file.id}
              file={file}
              key={index}
              index={index}
              page={page}
              setPreviewIndex={setPreviewIndex}
            />
          ))}
      </Flex>

      {files && !files.length && (
        <Image mx="auto" width="8rem" src="/images/empty_files.svg" />
      )}

      <FilesPreview
        previewIndex={previewIndex}
        files={files}
        setPreviewIndex={setPreviewIndex}
        rootUrl={ROOT_URL + "/shared/"}
      />
    </Flex>
  );
};
