import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { createEditor, Editor, Range, Transforms } from "slate";
import { Editable, ReactEditor, withReact, Slate } from "slate-react";
import { withHistory } from "slate-history";

import { HoveringToolbar } from "../Editor/HoveringToolbar";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Text } from "../Text";
import { uuidv4 } from "../../util";
import { Element, Leaf } from "./Element";
import { plainTextSerializer } from "./Serializer";
import { Toolbar } from "./Toolbar";
import { withLinks } from "./Helpers/Link";
import { withImages } from "./Helpers/Image";
import { withEmoji } from "./Helpers/Emoji";
import { insertMention, MentionDropdown, withMention } from "./Helpers/Mention";
import { mentionsExtract } from "./Serializer/mentions";

const emptyContent = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

const mentionRe = /^@(\w+)$|^@$/;

const getInitialValue = (value, plainText) => {
  if (
    !value ||
    typeof value === "undefined" ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return [...emptyContent];
  } else if (plainText || (value && !Array.isArray(value))) {
    return [
      {
        type: "paragraph",
        children: [{ text: value }]
      }
    ];
  }

  return value;
};

export const SimpleEditor = ({
  autoFocus = false,
  enabled = false,
  enableToolbar = false,
  enableArrow = false,
  hoveringToolbar = false,
  label,
  fileRootUrl,
  onChange,
  onBlur,
  clearOnEnter,
  onEnter,
  onEsc,
  onSave,
  placeholder,
  plainText = false,
  required,
  toolbarPosition = "TOP",
  uploadEndpoint,
  uploadId,
  renderMode = false,
  setEmpty,
  value,
  mentionOptions,
  ...props
}) => {
  const { t } = useTranslation();

  const editorRef = useRef(uuidv4());
  const mentionRef = useRef();

  const [content, setContent] = useState(getInitialValue(value, plainText));
  const [isEmpty, setIsEmpty] = useState(true);

  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [insertingMention, setInsertMention] = useState(false);

  const options = mentionOptions
    ? mentionOptions
        .filter((c) => c.value.toLowerCase().startsWith(search.toLowerCase()))
        .slice(0, 10)
    : [];

  const editor = useMemo(() => {
    let editor = withEmoji(
      withImages(withLinks(withHistory(withReact(createEditor()))))
    );
    if (mentionOptions) editor = withMention(editor);
    return editor;
  }, []);

  useEffect(() => {
    if (JSON.stringify(content) === JSON.stringify(emptyContent)) {
      setIsEmpty(true);
      setEmpty && setEmpty(true);
    } else {
      setIsEmpty(false);
      setEmpty && setEmpty(false);
    }
  }, [content, setIsEmpty, value, emptyContent]);

  const renderElement = useCallback(
    (props) => <Element fileRootUrl={fileRootUrl} {...props} />,
    []
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const changeValue = (content) => {
    if (typeof onChange === "function") {
      if (plainText) {
        onChange(plainTextSerializer(content));
      } else {
        onChange(content);
      }
    }

    setContent(content);
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start, end] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: "word" });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(mentionRe);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        setInsertMention(true);
        return;
      }
    }

    setTarget(null);
  };

  useEffect(() => {
    if (renderMode || !mentionOptions) return;

    if (target && options.length > 0) {
      const el = mentionRef.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [options.length, editor, index, search, target]);

  const doOnBlur = () => {
    if (typeof onBlur === "function") {
      if (plainText) {
        let mentions = [];
        onBlur(plainTextSerializer(content, mentions));
      } else {
        onBlur(content);
      }
    }
  };

  const saveContent = () => {
    if (isEmpty) return;

    let mentions = [];
    if (mentionOptions) mentions = mentionsExtract(content);

    if (typeof onEnter === "function") {
      if (plainText) {
        onEnter(plainTextSerializer(content, mentions));
        if (clearOnEnter) {
          editorRef.current = uuidv4();
          // setContent(emptyContent);
        }
      } else {
        onEnter(content, mentions);
        if (clearOnEnter) {
          editorRef.current = uuidv4();
          // setContent(emptyContent);
        }
      }
    }

    if (typeof onSave === "function") {
      if (plainText) {
        onSave(plainTextSerializer(content, mentions));
      } else {
        onSave(content, mentions);
      }
    }
  };

  let editorBorder = {};

  if (toolbarPosition === "BOTTOM") {
    editorBorder.borderBottomLeftRadius = "0.5rem";
    editorBorder.borderBottomRightRadius = "0.5rem";
  }

  if (toolbarPosition === "TOP") {
    editorBorder.borderTopLeftRadius = "0.5rem";
    editorBorder.borderTopRightRadius = "0.5rem";
  }

  if (renderMode) {
    editorBorder.padding = "0px";
  }

  let allowEdit = enabled && !renderMode;

  let border = {};
  if (!renderMode)
    border = {
      border: allowEdit ? "1px solid lightgrey" : "1px solid lightGrey",
      bg: "l0",
      borderRadius: "0.375rem",
      lineHeight: "2"
    };

  const onKeyDown = (e) => {
    let prevIndex;
    let nextIndex;
    if (target && insertingMention) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          prevIndex = index >= options.length - 1 ? 0 : index + 1;
          setIndex(prevIndex);
          break;
        case "ArrowUp":
          e.preventDefault();
          nextIndex = index <= 0 ? options.length - 1 : index - 1;
          setIndex(nextIndex);
          break;
        case "Tab":
        case "Enter":
          e.preventDefault();
          Transforms.select(editor, target);
          insertMention(editor, options[index].value, options[index].id);
          setTarget(null);
          setInsertMention(false);
          break;
        case "Escape":
          e.preventDefault();
          setTarget(null);
          if (onEsc) onEsc();
          break;
      }
    } else {
      if (onEnter && e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        saveContent();
      }
      if (onEsc && e.keyCode === 27) {
        e.preventDefault();
        onEsc();
      }
    }
  };

  let style = {
    style: {
      padding: "0.25rem 1rem"
    }
  };

  if (renderMode) {
    style.style.padding = "0px";
  }

  if (props.height) {
    style.style.height = props.height;
    style.style.overflow = "auto";
  }

  if (props.maxHeight) {
    style.style.maxHeight = props.maxHeight;
    style.style.overflow = "auto";
  }

  if (props.minHeight) {
    style.style.minHeight = props.minHeight;
    style.style.overflow = "auto";
  }

  return (
    <Flex ref={editorRef} flexDirection="column" lineHeight="1.5" width={1}>
      {label && (
        <Flex ml="0.25rem" mb="0.125rem" alignItems="center">
          <Text textStyle="label">{t(label)}</Text>

          {!renderMode && required && (
            <Text textStyle="label" color="t4" ml={1}>
              {t("(required)")}
            </Text>
          )}
        </Flex>
      )}
      <Flex flexDirection={plainText ? "row" : "column"}>
        <Flex
          {...border}
          width={1}
          flexDirection={
            toolbarPosition === "TOP" ? "column" : "column-reverse"
          }
        >
          <Slate editor={editor} value={content} onChange={changeValue}>
            {allowEdit && enableToolbar && !plainText && (
              <Toolbar
                {...editorBorder}
                saveContent={saveContent}
                isEmpty={isEmpty}
                editor={editor}
                fileRootUrl={fileRootUrl}
                uploadEndpoint={uploadEndpoint}
                uploadId={uploadId}
                enableArrow={enableArrow}
              />
            )}

            <div display="flex" style={{ ...editorBorder }}>
              {hoveringToolbar && <HoveringToolbar />}
              <Editable
                height={80}
                scroll="auto"
                autoFocus={autoFocus}
                key={`${allowEdit}`}
                readOnly={allowEdit ? false : true}
                placeholder={placeholder || t("Enter some text…")}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={onKeyDown}
                onBlur={doOnBlur}
                {...style}
              />
            </div>
          </Slate>
        </Flex>

        {target && options.length > 0 && (
          <MentionDropdown ref={mentionRef} options={options} index={index} />
        )}

        {plainText && enableArrow && (
          <Flex
            mt="0.5rem"
            width="fit-content"
            height="fit-content"
            borderRadius="0.375rem"
            ml="0.25rem"
            bg={isEmpty ? "" : "blue"}
            style={{ cursor: isEmpty ? "" : "pointer" }}
            p="0.5rem"
            onClick={(e) => {
              e.stopPropagation();
              if (isEmpty) return;
              saveContent();
            }}
          >
            <Icon.Triangle
              width="1rem"
              minWidth="1rem"
              stroke="lightGrey"
              style={{
                strokeWidth: "0px",
                fill: isEmpty ? "lightGrey" : "white",
                transform: "rotate(90deg)"
              }}
            />
          </Flex>
        )}
      </Flex>

      {!renderMode && onEnter && (
        <Flex
          visibility={isEmpty ? "hidden" : "visible"}
          m="0.25rem"
          justifyContent="flex-start"
          style={{
            visibility: isEmpty ? "hidden" : "visible",
            transition: "all 350ms ease-in-out"
          }}
          transition="all 350ms ease-in-out"
        >
          <Text color="t3" fontSize="0.75rem">
            <b>{t("Enter")}</b> {t("to send")}
          </Text>
          <Text color="t3" ml="1rem" fontSize="0.75rem">
            <b>{t("Shift + Enter")}</b> {t("to add a new paragraph")}
          </Text>
          {onEsc && (
            <Text color="t3" ml="1rem" fontSize="0.75rem">
              <b>Esc</b> {t("to exit")}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
};
