import { ACTIONS, STORE } from "store";

export const detectSleepWorker = new Worker("/ef-tr.js");

detectSleepWorker.onmessage = function (ev) {
  if (ev && ev.data === "wakeup") {
    console.log("Wakeup");
    STORE.dispatch(ACTIONS.state.setAttribute("app", "loadedResources", []));
    STORE.dispatch(ACTIONS.state.setAttribute("app", "shouldRefresh", true));
  }
};
