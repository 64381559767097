// definição do estado inicial dos módulos sem models
let preferences = localStorage.getItem("preferences");

if (preferences) {
  preferences = JSON.parse(preferences);
} else {
  preferences = {
    sideMenuOpen: true,
    showFilters: true,
    theme: "light"
  };

  localStorage.setItem("preferences", JSON.stringify(preferences));
}

let user = JSON.parse(localStorage.getItem("user"));
let userState = { authenticated: false, needsAuth: true };

if (user) userState = { ...user, authenticated: true, needsAuth: false };

const INITIAL_STATE = {};

const getInitialState = () => {
  return { obj: null, objs: null, loadingObjId: null, menu: null };
};

INITIAL_STATE["account"] = {
  loginState: {
    doingLogin: false,
    error: null
  },
  permissions: {},
  plan: null,
  preferences,
  user: userState,
  zenMode: false
};

const STAGES = {
  0: "Waiting for login",
  1: "Logging in",
  2: "Starting app",
  3: "Recovering data from cache",
  4: "Loading data",
  5: "Updating data"
};

const AUTH_STATUS = {
  0: "Waiting for login",
  1: "Login error",
  2: "Authenticated",
  3: "Needs reauth"
};

const NETWORK_STATUS = {
  0: "Not connected",
  1: "Connected"
};

INITIAL_STATE["app"] = {
  authStatus: AUTH_STATUS[2],
  networkStatus: NETWORK_STATUS[0],
  status: "ready",
  stage: STAGES[0],
  ready: false,
  needsReload: false,
  shouldRefresh: false,
  loadedResources: [],
  version: null,
  ts: null
};

INITIAL_STATE["billboard"] = { ...getInitialState(), messages: null };

INITIAL_STATE["charts"] = {};

INITIAL_STATE["combobox"] = {};

INITIAL_STATE["modal"] = {};

let spaceId = localStorage.getItem("spaceId");

if (spaceId) spaceId = JSON.parse(spaceId);

INITIAL_STATE["spaces"] = {
  spaces: {},
  users: {},
  spaceId: spaceId
};

const initialFilters = JSON.parse(localStorage.getItem("filters"));
if (initialFilters && !initialFilters.type) initialFilters.type = "ALL";

const initialCalendarFilters = JSON.parse(
  localStorage.getItem("calendarFilters")
);

if (initialCalendarFilters && !initialCalendarFilters.type)
  initialCalendarFilters.type = "ALL";

const defaultFilters = {
  assignment: "PARTICIPANT",
  type: "ALL",
  status: "OPEN",
  due: "ANYTIME",
  priority: "ALL_PRIORITIES",
  period: "THIS_WEEK",
  listId: null,
  projectId: null,
  spaceId: null,
  tags: { condition: "or", tags: [] },
  search: "",
  assigneeId: null
};

let activeConversation;
try {
  activeConversation = JSON.parse(localStorage.getItem("acc"));
} catch {
  activeConversation = "";
}

if (!activeConversation) activeConversation = "";

INITIAL_STATE["events"] = {};

INITIAL_STATE["pages"] = {
  spaceId: null,
  page: null,
  pages: {},
  files: {},
  editingPage: false,
  loadedSpaces: []
};

INITIAL_STATE["updates"] = {
  updates: null,
  selectedUpdate: null,
  editingPage: false
};

INITIAL_STATE["conversations"] = {
  activeConversation,
  activeProfile: null,
  activeThread: {
    parent: null,
    messages: null,
    loadingThread: false
  },
  conversations: null,
  typing: null
};

let tasksUi = JSON.parse(localStorage.getItem("tasksUi"));
if (!tasksUi)
  tasksUi = { allGroupsOpen: true, groupClosed: {}, hideEmptyLists: true };

INITIAL_STATE["files"] = {
  files: {},
  folders: {},
  count: { spaces: 0, folders: 0 },
  filters: { spaceId: null, folderId: null }
};

INITIAL_STATE["reports"] = {
  tasks: null
};

INITIAL_STATE["documents"] = {
  document: null
};

INITIAL_STATE["forms"] = {
  editing: { pageIndex: null, itemIndex: null },
  form: null
};

let notify = JSON.parse(localStorage.getItem("reminders")) || [];

INITIAL_STATE["reminders"] = {
  reminders: [],
  notify
};

INITIAL_STATE["tasks"] = {
  adminView: {},
  filters: initialFilters || defaultFilters,
  calendarFilters: initialCalendarFilters || defaultFilters,
  // tasks: null,
  // task: null,
  projects: null,
  orderedProjects: null,
  lists: null,
  orderedLists: null,
  filtered: null,
  owner: { type: null },
  ownerId: null,
  tags: [],
  tasksCount: {},
  ui: tasksUi,
  inProgress: null,
  updates: []
};

INITIAL_STATE["people"] = {};

INITIAL_STATE["topic"] = {
  ...getInitialState(),
  replies: {},
  spaceCategories: {}
};

INITIAL_STATE["network"] = {
  activity: false,
  pendingActions: []
};

INITIAL_STATE["toast"] = [];

INITIAL_STATE["ws"] = {
  onlineUsersIds: [],
  ws: null
};

const allModules = Object.keys(INITIAL_STATE).map((slice) => slice);

export {
  defaultFilters,
  INITIAL_STATE,
  allModules as ALL_MODULES,
  getInitialState
};
