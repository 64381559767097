import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, formatDate, Text } from "tomato";

import { HISTORY_TYPES } from "constant";

const History = () => {
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  let assignedTypeId = 2;

  return (
    <Flex justifyContent="center">
      <Flex flexDirection="column">
        {task.history.map((history, index) => (
          <Flex key={index} alignItems="flex-start" width={1}>
            <Flex height="100%" flexDirection="column" alignItems="center">
              <Flex
                borderRight={index !== 0 ? "1px solid lightGrey" : ""}
                width="2px"
                height="4px"
              />
              <Flex
                borderRadius="50%"
                border="2px solid lightGrey"
                width="12px"
                height="12px"
                bg={
                  index === 0 || index === task.history.length - 1 ? "l5" : "l0"
                }
              />
              <Flex
                borderRight={
                  index === task.history.length - 1 ? "" : "1px solid lightGrey"
                }
                width="2px"
                height="48px"
              />
            </Flex>

            <Flex ml={2} flexDirection="column">
              <Text color="t1" fontSize="0.9375rem" fontWeight="semibold">
                {t(HISTORY_TYPES[history.type_id])}
                {history.assigned_to_user_id &&
                  history.type_id === assignedTypeId && (
                    <>
                      {" "}
                      {t("to")} {users[history.assigned_to_user_id].name}
                    </>
                  )}

                {history.additional_info.start_date && (
                  <>
                    {" "}
                    {t("to")}{" "}
                    {formatDate(
                      history.additional_info.start_date,
                      user.locale,
                      user.timezone
                    ).date()}
                  </>
                )}
              </Text>

              {false && history.additional_info.content && (
                <Text color="t2" fontSize="0.9375rem">
                  {history.additional_info.content}
                </Text>
              )}

              <Text fontSize={14} color="t2" mt={1}>
                {users[history.user_id].name},&nbsp;
                {formatDate(
                  history.creation_date,
                  user.locale,
                  user.timezone
                ).distance()}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default History;
