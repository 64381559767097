import React, { useEffect, useRef, useState } from "react";

import { Flex, Icon, Image, Overlay, Spinner, Text } from "tomato";

var pdfjs = require("pdfjs-dist");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js`;

async function getPage(pdf, scale, pageNumber) {
  const page = await pdf.getPage(pageNumber);

  const viewport = page.getViewport({ scale });

  const canvas = document.getElementById(`${scale}${pageNumber}`);
  const canvasContext = canvas.getContext("2d");

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  await page.render({
    canvasContext,
    viewport
  }).promise;

  return pageNumber;
}

async function getFirstPage(pdf) {
  const page = await pdf.getPage(1);
  return page;
}

async function getDocument(url) {
  const pdf = await pdfjs.getDocument(url).promise.then((pdf) => {
    return pdf;
  });

  const page = await getFirstPage(pdf).then((page) => {
    return page;
  });

  return [pdf, page];
}

const RenderPage = ({ viewport, scale, renderedPage, index }) => {
  return (
    <Flex
      key={index}
      border="1px solid lightGrey"
      borderRadius="0.375rem"
      p="0.25rem"
      mb="1rem"
      bg="l0"
      width={viewport.width * scale + 16}
      height={viewport.height * scale + 16}
      style={{
        visibility: index + 1 <= renderedPage ? "visible" : "hidden"
      }}
    >
      <canvas id={`${scale}${index + 1}`} />
    </Flex>
  );
};

const RenderPDF = ({ url }) => {
  const [numPages, setNumPages] = useState();
  const [pdf, setPdf] = useState();
  const [renderedPage, setRenderedPage] = useState();
  const [scale, setScale] = useState(1);
  const [visibleRef, setVisibleRef] = useState(false);
  const [viewport, setViewport] = useState({ width: 1, height: 64 });

  const refs = useRef({});
  const scrollRef = useRef();

  useEffect(() => {
    getDocument(url).then(([pdf, page]) => {
      setPdf(pdf);
      setNumPages(pdf.numPages);
      const viewport = page.getViewport({ scale });

      setViewport(viewport);
    });
  }, [scale, url]);

  // const callback = (entries) => {
  //   entries.forEach(
  //     (entry) => (refs.current[entry.target.id].ratio = entry.intersectionRatio)
  //   );

  //   const visible = Object.values(refs.current).reduce(
  //     (acc, value) => (value.ratio > acc.ratio ? value : acc),
  //     visibleRef
  //   );

  //   if (visible.ratio > visibleRef.ratio) {
  //     setVisibleRef({ ...visible });
  //   }
  // };

  // const observer = new IntersectionObserver(callback, {
  //   root: scrollRef.current,
  //   threshold: new Array(numPages).fill(0).map((v, i) => i * 0.01)
  // });

  // const observer = useMemo(() => {
  //   console.log(numPages);
  //   if (!numPages) return null;
  //   return new IntersectionObserver(callback, {
  //     root: scrollRef.current,
  //     threshold: new Array(numPages).fill(0).map((v, i) => i * 0.01)
  //   });
  // }, [callback, numPages]);

  useEffect(() => {
    if (!numPages) return;
    Array(numPages)
      .fill()
      .map(
        (_, index) =>
          (refs.current[index] = {
            id: index,
            ref: React.createRef(),
            ratio: 0
          })
      );
    setVisibleRef(refs.current[0]);
  }, [numPages]);

  // useEffect(() => {
  //   if (renderedPage !== numPages) return;
  //   Object.values(refs.current).forEach((value) => {
  //     observer.observe(value.current);
  //   });
  // }, [observer, renderedPage, numPages]);

  // Page {visibleRef ? visibleRef.id : 1} / {numPages}

  useEffect(() => {
    if (!numPages) return;
    let pageRendered = getPage(pdf, scale, 1);
    pageRendered.then((pageNumber) => setRenderedPage(pageNumber));
  }, [pdf, numPages, scale]);

  useEffect(() => {
    if (renderedPage === numPages || !renderedPage) return;
    let pageRendered = getPage(pdf, scale, renderedPage + 1);
    pageRendered.then((pageNumber) => setRenderedPage(pageNumber));
  }, [pdf, scale, numPages, renderedPage]);

  if (!numPages) return <Spinner />;

  return (
    <Flex
      height="100%"
      flexDirection="column"
      width={1}
      alignItems="center"
      pt="1rem"
      bg="transparent"
      overflow="auto"
    >
      <Flex
        bg="grey"
        zIndex="10"
        position="fixed"
        bottom="1rem"
        left="50%"
        style={{ transform: "translate(-50%)" }}
        width="15rem"
        height="2.5rem"
        alignItems="center"
        px="1.5rem"
        justifyContent="space-around"
        borderRadius="0.375rem"
      >
        {renderedPage === numPages ? (
          <>
            <Text fontSize="0.875rem" color="white">
              {numPages} pages
            </Text>
            <Flex alignItems="center" position="relative">
              <Text
                fontSize="2rem"
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (renderedPage === numPages) setScale(scale * 0.8);
                }}
              >
                -
              </Text>
              <Icon.ZoomIn
                mx="1rem"
                stroke="white"
                style={{ strokeWidth: "2px" }}
                onClick={() => {
                  if (renderedPage === numPages) setScale(1);
                }}
              />
              <Text
                fontSize="1.25rem"
                color="white"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (renderedPage === numPages) setScale(scale / 0.8);
                }}
              >
                +
              </Text>
            </Flex>
          </>
        ) : (
          <Spinner />
        )}
      </Flex>

      <Flex
        height="100%"
        flexDirection="column"
        overflow="auto"
        mt="1rem"
        ref={scrollRef}
      >
        {numPages &&
          Array(numPages)
            .fill()
            .map((_, index) => (
              <Flex ref={refs.current[index]} id={index} key={index}>
                <RenderPage
                  viewport={viewport}
                  index={index}
                  scale={scale}
                  renderedPage={renderedPage}
                />
              </Flex>
            ))}
      </Flex>
    </Flex>
  );
};

const TopBar = ({ files, previewIndex, rootUrl, setOpen }) => {
  let file = files[previewIndex];
  let fileUrl = `${rootUrl}${file.filename}`;

  const downloadFile = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Flex
      width={1}
      minHeight="4rem"
      alignItems="center"
      justifyContent="space-between"
      bg="l0"
      px="1rem"
    >
      <Text color="t1" ml="0.5rem" fontWeight="semibold" fontSize="1.25rem">
        {file.name}
      </Text>

      <Flex ml="auto" style={{ cursor: "pointer" }} onClick={downloadFile}>
        <Icon.Download
          size={24}
          style={{ strokeWidth: "2px" }}
          stroke="black"
        />
      </Flex>

      <Flex style={{ cursor: "pointer" }} onClick={() => setOpen()} mx="2rem">
        <Icon.Close size={28} stroke="black" style={{ strokeWidth: "2px" }} />
      </Flex>
    </Flex>
  );
};

const Preview = ({ files, previewIndex, rootUrl, setOpen }) => {
  let file = files[previewIndex];
  let fileType = file.mime_type
    ? file.mime_type.split("/")
    : file.content_type.split("/");
  let fileUrl = `${rootUrl}${file.filename}`;

  const downloadFile = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Flex
      alignItems="center"
      height="100%"
      overflow="auto"
      onClick={() => {
        setOpen();
      }}
    >
      <Flex height="100%" justifyContent="center" flexGrow="1" overflow="auto">
        {fileType[1] === "pdf" && <RenderPDF url={fileUrl} />}

        {fileType[0] === "image" && (
          <Image
            my="auto"
            width={0.8}
            borderRadius="0.5rem"
            src={fileUrl}
            style={{
              cursor: "pointer"
            }}
            onClick={(e) => {
              e.stopPropagation();
              downloadFile();
            }}
          />
        )}

        {fileType[0] !== "image" && fileType[1] !== "pdf" && (
          <Text
            bg="l0"
            minWidth={60}
            width={0.5}
            py={24}
            borderRadius={4}
            fontSize="36px"
            fontWeight="bold"
            style={{
              cursor: "pointer",
              textTransform: "uppercase",
              textAlign: "center",
              verticalAlign: "middle"
            }}
          >
            {fileType[1]}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const Thumbnail = ({ file, index, selected, setIndex, rootUrl }) => {
  let fileType = file.mime_type
    ? file.mime_type.split("/")
    : file.content_type.split("/");
  let fileUrl = `${rootUrl}${file.filename}`;

  if (!fileType[1]) return null;

  return (
    <Flex
      borderRadius="0.25rem"
      border={selected ? "1px solid black" : "1px solid transparent"}
      mx="0.25rem"
      key={file.id}
      height="6rem"
      width="6rem"
      minWidth="6rem"
      minHeight="6rem"
      my="1rem"
      alignItems="center"
      justifyContent="center"
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        e.preventDefault();
        setIndex(index);
      }}
    >
      {fileType[0] === "image" && (
        <Image width={0.8} borderRadius="0.5rem" src={fileUrl} />
      )}

      {fileType[0] !== "image" && (
        <Text
          fontSize="0.75rem"
          fontWeight="semibold"
          style={{
            textTransform: "uppercase",
            textAlign: "center",
            verticalAlign: "middle"
          }}
        >
          {fileType[1] || file.name}
        </Text>
      )}
    </Flex>
  );
};

export const FilesPreview = ({
  files,
  previewIndex,
  setPreviewIndex,
  rootUrl
}) => {
  const [open, setOpen] = useState();
  const [index, setIndex] = useState(previewIndex);

  useEffect(() => {
    if (previewIndex || previewIndex === 0) {
      setIndex(previewIndex);
      setOpen(true);
    }
  }, [previewIndex]);

  if (!files || (files && files.length === 0)) return null;
  let countFiles = files.length;

  return (
    <Overlay
      open={open}
      setOpen={setOpen}
      onExit={() => {
        setPreviewIndex && setPreviewIndex();
      }}
      width={1}
      height="100%"
      fill="white"
      opacity="93%"
    >
      <Flex
        flexDirection="column"
        width={1}
        height="100%"
        justifyContent="space-between"
      >
        <TopBar
          files={files}
          previewIndex={index}
          rootUrl={rootUrl}
          setOpen={setOpen}
        />

        <Flex width={1} justifyContent="space-between" height="100%">
          <Flex
            width="6rem"
            height="100%"
            onClick={(e) => {
              e.preventDefault()
              if (index > 0) setIndex(index - 1);
            }}
            style={{ cursor: index > 0 ? "pointer" : "default" }}
            alignItems="center"
            justifyContent="center"
          >
            <Flex bg="l6" borderRadius="50%" m="1rem" p="0.5rem">
              <Icon.ChevronLeft
                minWidth="2rem"
                minHeight="2rem"
                stroke="white"
                style={{ strokeWidth: "3px" }}
              />
            </Flex>
          </Flex>

          <Flex maxWidth="50rem">
            <Preview
              files={files}
              setOpen={setOpen}
              previewIndex={index}
              rootUrl={rootUrl}
            />
          </Flex>

          <Flex
            width="6rem"
            height="100%"
            onClick={(e) => {
              e.preventDefault()
              if (index + 1 < countFiles) setIndex(index + 1);
            }}
            style={{
              cursor: index + 1 < countFiles ? "pointer" : "default"
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Flex bg="l6" borderRadius="50%" m="1rem" p="0.5rem">
              <Icon.ChevronRight
                minWidth="2rem"
                minHeight="2rem"
                stroke="white"
                style={{ strokeWidth: "3px" }}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          borderTop="1px solid lightGrey"
          mt="auto"
          mb={0}
          overflow="auto"
        >
          {files.map((file, filesIndex) => (
            <Thumbnail
              key={filesIndex}
              file={file}
              index={filesIndex}
              selected={filesIndex === index}
              setIndex={setIndex}
              rootUrl={rootUrl}
            />
          ))}
        </Flex>
      </Flex>
    </Overlay>
  );
};

export default FilesPreview;
