import React from "react";

import { Flex } from "tomato";

import { DEFAULT_MARGIN } from "constant";

export const Header = ({ children, ...props }) => {
  return (
    <Flex
      width={1}
      minWidth="0px"
      height="fit-content"
      alignItems="center"
      my={DEFAULT_MARGIN}
      {...props}
    >
      {children}
    </Flex>
  );
};
