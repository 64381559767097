import React, { useState } from "react";
import { parseISO } from "date-fns";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Chip,
  DatePicker,
  DateTimePicker,
  Flex,
  FlexHidden,
  FlexWrapper,
  formatDate,
  Icon,
  isToday,
  Switch,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import { DueColor } from "../util";
import { TIMEFRAME } from "../constants";

const When = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);

  const [instance, setInstance] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const setDate = (date) => {
    setShowCalendar(false);

    if (typeof date === "undefined") return;

    let newDate = {
      start_date: date
    };

    if (task.deadline) {
      let zonedEndDate = parseISO(task.deadline);
      zonedEndDate.setHours(0, 0, 0);
      if (date > zonedEndDate) {
        newDate.start_date = task.deadline;
        newDate.deadline = date;
        dispatch(
          ACTIONS.tasks.updateAttribute(task.id, "deadline", newDate.deadline)
        );
      }
    }

    // dispatch(ACTIONS.task.updateAttribute("timeframe_id", 0));

    dispatch(
      ACTIONS.tasks.updateAttribute(task.id, "start_date", newDate.start_date)
    );

    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { ...newDate }
      )
    );
  };

  const setDeadline = (date) => {
    instance.unmount();

    if (typeof date === "undefined") return;

    let newDate = {
      deadline: date
    };

    if (task.start_date) {
      let zonedStartDate = parseISO(task.start_date);
      zonedStartDate.setHours(0, 0, 0);
      if (date < zonedStartDate) {
        newDate.deadline = task.start_date;
        newDate.start_date = date;
        dispatch(
          ACTIONS.tasks.updateAttribute(
            task.id,
            "start_date",
            newDate.start_date
          )
        );
      }
    }

    dispatch(
      ACTIONS.tasks.updateAttribute(task.id, "deadline", newDate.deadline)
    );

    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { ...newDate }
      )
    );
  };

  const onSelect = (startDate, startTime, endDate, endTime) => {
    instance.unmount();

    let saveDate = {};
    saveDate.start_date = startDate;
    saveDate.deadline = endDate;

    dispatch(ACTIONS.tasks.updateTasks(task.id, saveDate));

    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { ...saveDate }
      )
    );
  };

  const changeRepeat = (repeat) => {
    let rrule = {};
    if (repeat) rrule = { freq: 3, interval: 1 };

    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { rrule }
      )
    );
  };

  return (
    <>
      {false && (
        <Flex alignItems="center" mt="2rem" minHeight={32}>
          <Text color="t2" width={140}>
            {t("Start date")}
          </Text>

          <Flex position="relative">
            {!task.start_date && (
              <Icon.Calendar
                size="medium"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowCalendar(!showCalendar);
                }}
              />
            )}

            {task.start_date && (
              <FlexWrapper alignItems="center">
                <Chip
                  color={task.start_date ? DueColor(task.start_date) : "green"}
                  onClick={() => {
                    setShowCalendar(!showCalendar);
                  }}
                  selected={true}
                >
                  {task.start_date &&
                    formatDate(
                      task.deadline,
                      user.locale,
                      user.timezone
                    ).date()}
                  {false && task.timeframe_id !== 0 && (
                    <>{TIMEFRAME[task.timeframe_id]}</>
                  )}
                  {task.start_date}
                </Chip>
                <FlexHidden style={{ cursor: "pointer" }}>
                  <Icon.Close onClick={() => setDate(null)} />
                </FlexHidden>
              </FlexWrapper>
            )}

            {showCalendar && (
              <Flex
                position="absolute"
                bg="l0"
                p={4}
                top={28}
                borderRadius={8}
                zIndex={100}
                boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
              >
                <DatePicker
                  width={280}
                  startDate={task.start_date}
                  saveDate={setDate}
                  setOpen={setShowCalendar}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      )}

      {task.type_id === 2 && (
        <Flex alignItems="center" minHeight={32} mt="2rem">
          <Text
            color="t2"
            fontWeight="semibold"
            fontSize="0.9375rem"
            width={140}
          >
            {t("Date")}
          </Text>

          <FlexWrapper alignItems="center">
            <Tippy
              placement="bottom-start"
              trigger="click"
              interactive={true}
              offset={[0, 4]}
              render={(attrs) => (
                <>
                  {instance ? (
                    <Flex
                      {...attrs}
                      bg="l0"
                      p={4}
                      borderRadius={8}
                      boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
                    >
                      <DateTimePicker
                        startDate={task.start_date}
                        endDate={task.deadline}
                        onSelect={onSelect}
                        onCancel={() => instance.hide()}
                        range={true}
                      />
                    </Flex>
                  ) : (
                    ""
                  )}
                </>
              )}
              onCreate={setInstance}
            >
              <Flex position="relative">
                {task.start_date && (
                  <FlexWrapper alignItems="center">
                    <Chip
                      color={
                        task.start_date ? DueColor(task.start_date) : "green"
                      }
                      selected={true}
                    >
                      {isToday(task.start_date)
                        ? formatDate(
                            task.start_date,
                            user.locale,
                            user.timezone
                          ).hour()
                        : formatDate(
                            task.start_date,
                            user.locale,
                            user.timezone
                          ).dateHour()}
                    </Chip>
                  </FlexWrapper>
                )}
              </Flex>
            </Tippy>

            <Switch
              ml="1rem"
              checked={task.rrule.freq || task.rrule.freq === 0}
              label={t("Repeat")}
              onCheck={changeRepeat}
            />
          </FlexWrapper>
        </Flex>
      )}

      {task.type_id === 1 && (
        <Flex alignItems="center" minHeight={32} mt="2rem">
          <Text
            color="t2"
            fontWeight="semibold"
            fontSize="0.9375rem"
            width={140}
          >
            {t("Due date")}
          </Text>

          <FlexWrapper alignItems="center">
            <Tippy
              placement="bottom-start"
              trigger="click"
              interactive={true}
              offset={[0, 4]}
              appendTo="parent"
              render={(attrs) => (
                <>
                  {instance ? (
                    <Flex
                      {...attrs}
                      bg="l0"
                      p={4}
                      borderRadius={8}
                      boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
                    >
                      <DatePicker
                        width={280}
                        startDate={task.deadline}
                        saveDate={setDeadline}
                      />
                    </Flex>
                  ) : (
                    ""
                  )}
                </>
              )}
              onCreate={setInstance}
            >
              <Flex position="relative" style={{ cursor: "pointer" }}>
                {!task.deadline && (
                  <Flex alignItems="center">
                    <Icon.Calendar size="medium" />
                    <Flex
                      ml="0.5rem"
                      p="0.25rem"
                      borderRadius="0.25rem"
                      alignItems="center"
                      bg="l2"
                    >
                      <Text color="t3">{t("No due date")}</Text>
                    </Flex>
                  </Flex>
                )}

                {task.deadline && (
                  <FlexWrapper alignItems="center">
                    <Chip
                      color={task.deadline ? DueColor(task.deadline) : "green"}
                      selected={true}
                    >
                      {task.deadline &&
                        formatDate(
                          task.deadline,
                          user.locale,
                          user.timezone
                        ).date()}
                    </Chip>
                  </FlexWrapper>
                )}
              </Flex>
            </Tippy>

            {task.deadline && (
              <>
                <Switch
                  ml="1rem"
                  checked={task.rrule.freq || task.rrule.freq === 0}
                  label={t("Repeat")}
                  onCheck={changeRepeat}
                />

                <FlexHidden
                  ml="0.5rem"
                  p="0.25rem"
                  borderRadius="0.25rem"
                  style={{ cursor: "pointer" }}
                  alignItems="center"
                  hoverColor="l2"
                  onClick={() => setDeadline(null)}
                >
                  <Icon.Close />
                  <Text color="t3">{t("Remove date")}</Text>
                </FlexHidden>
              </>
            )}
          </FlexWrapper>
        </Flex>
      )}
    </>
  );
};

export default When;
