import {
  addDays,
  format,
  getDay,
  getDaysInMonth,
  isSameDay,
  isSameMonth,
  isSunday,
  isWeekend,
  startOfMonth,
  startOfWeek,
  subDays
} from "date-fns";

export const getMonth = (date, selectedDate, endDate) => {
  let now = new Date();
  let yesterday = subDays(now, 1);

  let daysOfMonth = getDaysInMonth(date);
  let firstDateOfMonth = startOfMonth(date);
  let firstWeekday = getDay(firstDateOfMonth);
  let weeksOfMonth = Math.ceil(daysOfMonth / 7);

  let month = [];
  let fDay = subDays(firstDateOfMonth, firstWeekday);

  let n;
  for (n = 0; n < weeksOfMonth * 7; n++) {
    let calendarDate = addDays(fDay, n);

    month.push({
      date: calendarDate,
      key: format(new Date(calendarDate), "yyyyMMdd"),
      day: calendarDate.getDate(),
      isSunday: isSunday(calendarDate),
      isToday: isSameDay(calendarDate, now),
      isThisMonth: isSameMonth(calendarDate, date),
      isStartDate: isSameDay(calendarDate, selectedDate),
      isEndDate: isSameDay(calendarDate, endDate),
      isInBetween:
        selectedDate && calendarDate > selectedDate && calendarDate < endDate,
      isPast: calendarDate <= yesterday,
      isWeekend: isWeekend(calendarDate),
      haveEndDate: endDate
    });
  }

  return { numberOfWeeks: weeksOfMonth, dates: month };
};

export const getWeek = (date, selectedDate, endDate) => {
  let now = new Date();
  let yesterday = subDays(now, 1);

  let daysOfMonth = getDaysInMonth(date);
  let firstDateOfMonth = startOfMonth(date);
  let weeksOfMonth = Math.ceil(daysOfMonth / 7);

  let week = [];
  let fDay = startOfWeek(date);

  let n;
  for (n = 0; n < 7; n++) {
    let calendarDate = addDays(fDay, n);

    week.push({
      date: calendarDate,
      key: format(new Date(calendarDate), "yyyyMMdd"),
      day: calendarDate.getDate(),
      isSunday: isSunday(calendarDate),
      isToday: isSameDay(calendarDate, now),
      isThisMonth: isSameMonth(calendarDate, date),
      isStartDate: isSameDay(calendarDate, selectedDate),
      isEndDate: isSameDay(calendarDate, endDate),
      isInBetween:
        selectedDate && calendarDate > selectedDate && calendarDate < endDate,
      isPast: calendarDate <= yesterday,
      isWeekend: isWeekend(calendarDate),
      haveEndDate: endDate
    });
  }

  return { numberOfWeeks: weeksOfMonth, dates: week };
};
