import React from "react";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "tomato";

export const Label = ({ disabled, label, required, ...props }) => {
  const { t } = useTranslation();

  return (
    <Flex
      mb="0.125rem"
      px="0.25rem"
      alignItems="center"
      minHeight="1.125rem"
      {...props}
    >
      <Text textStyle="label">{t(label)}</Text>

      {!disabled && required && (
        <Text textStyle="label" ml="0.125rem">
          {t("(required)")}
        </Text>
      )}
    </Flex>
  );
};
