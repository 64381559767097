import { addDays, differenceInDays } from "date-fns";

import { API_ENDPOINT, API_ROOT } from "config";
import { calcRecur } from "./util";
import { ACTIONS, STORE } from "store";
import { TASKS } from "crud";

export const deleteTask = (task) => {
  STORE.dispatch(ACTIONS.events.send("Task deleted", task));
  STORE.dispatch(
    ACTIONS.fetch.delete(
      "tasks",
      `${API_ENDPOINT}/task/${task.id}`,
      ACTIONS.tasks.deleteTask
    )
  );
};

export const startTimer = (task) => {
  const ws = STORE.getState().ws.ws;

  STORE.dispatch(ACTIONS.events.send("Task started", task));

  let status = {
    emojiCode: "1f680",
    message: task.subject
  };

  ws &&
    ws.send(
      JSON.stringify({
        type: "spaces",
        action: "setStatus",
        data: { status }
      })
    );

  STORE.dispatch(ACTIONS.account.setPreference("status", status));

  STORE.dispatch(
    ACTIONS.fetch.patch(
      "tasks",
      `${API_ROOT}/task/${task.id}/start_timer`,
      ACTIONS.tasks.updateTimer
    )
  );
};

export const pauseTimer = (task) => {
  const ws = STORE.getState().ws.ws;
  STORE.dispatch(ACTIONS.events.send("Task paused", task));

  let status = {
    emojiCode: null,
    message: null
  };

  ws &&
    ws.send(
      JSON.stringify({
        type: "spaces",
        action: "setStatus",
        data: { status }
      })
    );

  STORE.dispatch(ACTIONS.account.setPreference("status", status));

  STORE.dispatch(
    ACTIONS.fetch.patch(
      "tasks",
      `${API_ROOT}/task/${task.id}/pause_timer`,
      ACTIONS.tasks.updateTimer
    )
  );
};

export const loadTask = (taskId) => {
  // STORE.dispatch(ACTIONS.state.setAttribute("tasks", "loadingObjId", taskId));
  TASKS.getOne(taskId);
};

export const doneTask = (task) => {
  let repeat = {};
  let nextDate;

  let fromDate = task.type_id === 2 ? task.start_date : task.deadline;

  if (task.rrule.freq) {
    nextDate = calcRecur(task.rrule, fromDate);
    repeat.order = task.order;

    if (task.type_id === 2) {
      repeat.nextStartDate = nextDate;
      if (task.deadline) {
        let days = differenceInDays(
          new Date(task.deadline),
          new Date(task.start_date)
        );
        repeat.nextDueDate = addDays(new Date(nextDate), days);
      }
    } else {
      repeat.nextDueDate = nextDate;
    }
  }

  STORE.dispatch(
    ACTIONS.fetch.patch(
      "tasks",
      `${API_ROOT}/task/${task.id}/done`,
      ACTIONS.tasks.done,
      { ...repeat }
    )
  );
};

export const undoneTask = (task) => {
  STORE.dispatch(ACTIONS.events.send("Task undone", task));
  STORE.dispatch(
    ACTIONS.fetch.patch(
      "tasks",
      `${API_ROOT}/task/${task.id}/undone`,
      ACTIONS.tasks.undoneTask
    )
  );
};
export default doneTask;
