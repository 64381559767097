import React from "react";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Chip, Dropdown, Flex, FlexAction, Icon, Switch, Text } from "tomato";

import { ACTIONS, defaultFilters } from "store";

import {
  ASSIGNMENT,
  ASSIGNMENT_OPTIONS,
  DUE,
  DUE_OPTIONS,
  STATUS,
  STATUS_OPTIONS,
  PERIOD,
  PERIOD_OPTIONS,
  PRIORITIES,
  PRIORITIES_OPTIONS,
  TYPE,
  TYPE_OPTIONS
} from "../constants";

export const FiltersOptions = ({ selectedFilters, view, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeUsers = useSelector((state) => state["spaces"].activeUsers);
  let filter = view === "calendar" ? "calendarFilters" : "filters";
  const filters = useSelector((state) => state["tasks"][filter]);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const users = useSelector((state) => state["spaces"].users);

  let members = [];

  if (filters.spaceId) {
    let space = spaces[filters.spaceId];
    members = space.members
      .filter((user) => !users[user.id].is_deleted && users[user.id].is_active)
      .map((user) => users[user.id]);
  } else {
    members = activeUsers;
  }

  const usersOptions = members.map((member) => {
    return { id: member.id, value: member.name };
  });

  const setOption = (type, option) => {
    if (!option) return;
    dispatch(ACTIONS.tasks.setFilter(type, option.id, view));
  };

  if (!selectedFilters)
    selectedFilters = ["Type", "Stats", "Due", "Priority", "Assignment"];

  // Apenas para traduzir
  const getOptions = (options) =>
    options.map((option) => {
      return { id: option.id, value: t(option.value) };
    });

  // Apenas para traduzir
  const getOption = (option) => {
    return { id: option.id, value: t(option.value) };
  };

  let filtersApplied =
    JSON.stringify(filters) !== JSON.stringify(defaultFilters);

  return (
    <Flex flexDirection="column" {...props} width="45rem">
      <Flex alignItems="center">
        <Text textStyle="subtitle">{t("Filters")}</Text>
        {false && filtersApplied && (
          <FlexAction
            ml="1rem"
            onClick={() => {
              dispatch(ACTIONS.tasks.resetFilters(view));
            }}
          >
            {t("Restore defaults")}
          </FlexAction>
        )}
      </Flex>

      <Flex mt="1rem">
        <Flex flexDirection="column" width={1 / 3}>
          <Dropdown
            label={t("Type")}
            options={getOptions(TYPE_OPTIONS)}
            selected={getOption(TYPE[filters.type])}
            onSelect={(option) => setOption("type", option)}
          />
          <Dropdown
            label={t("Priority")}
            options={getOptions(PRIORITIES_OPTIONS)}
            selected={getOption(PRIORITIES[filters.priority])}
            mt="1rem"
            onSelect={(option) => setOption("priority", option)}
          />
        </Flex>

        <Flex mx="1rem" flexDirection="column" width={1 / 3}>
          <Dropdown
            label={t("Status")}
            options={getOptions(STATUS_OPTIONS)}
            selected={getOption(STATUS[filters.status])}
            onSelect={(option) => setOption("status", option)}
          />
          {filters.status !== "CLOSED" && (
            <Dropdown
              label={t("Due date")}
              options={getOptions(DUE_OPTIONS)}
              selected={getOption(DUE[filters.due])}
              mt="1rem"
              onSelect={(option) => setOption("due", option)}
            />
          )}
          {filters.status === "CLOSED" && (
            <>
              <Dropdown
                label={t("Done date")}
                options={getOptions(PERIOD_OPTIONS)}
                selected={getOption(PERIOD[filters.period])}
                mt="1rem"
                onSelect={(option) => setOption("period", option)}
              />
            </>
          )}
        </Flex>

        <Flex flexDirection="column" width={1 / 3}>
          <Dropdown
            label={t("Assignee")}
            options={getOptions(ASSIGNMENT_OPTIONS)}
            selected={getOption(ASSIGNMENT[filters.assignment])}
            onSelect={(option) => setOption("assignment", option)}
          />

          {filters.assignment === "SPECIFIC_USER" && (
            <Dropdown
              mt="1rem"
              label={t("User")}
              options={usersOptions}
              placeholder={t("Choose a user")}
              selected={
                filters.assigneeId
                  ? {
                      id: filters.assigneeId,
                      value: users[filters.assigneeId].name
                    }
                  : null
              }
              onSelect={(option) => setOption("assigneeId", option)}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Filters = ({ view }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let filter = view === "calendar" ? "calendarFilters" : "filters";
  const filters = useSelector((state) => state["tasks"][filter]);

  const hideEmptyLists = useSelector(
    (state) => state["tasks"].ui.hideEmptyLists
  );

  const search = useSelector((state) => state["tasks"].filters.search);
  const tasksUi = useSelector((state) => state["tasks"].ui);
  const users = useSelector((state) => state["spaces"].users);

  const hideLists = () => {
    localStorage.setItem(
      "tasksUi",
      JSON.stringify({ ...tasksUi, hideEmptyLists: !hideEmptyLists })
    );

    dispatch(ACTIONS.tasks.updateUi("hideEmptyLists", !hideEmptyLists));
  };

  return (
    <Flex alignItems="center" mt="0.25rem" width="fit-content">
      <Tippy
        placement={view === "calendar" ? "bottom" : "bottom-start"}
        trigger="click"
        interactive={true}
        offset={[-8, 8]}
        render={(attrs) => (
          <Flex
            bg="l0"
            p="1.5rem"
            pt="1rem"
            borderRadius="0.5rem"
            boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
          >
            <FiltersOptions view={view} />
          </Flex>
        )}
      >
        <FlexAction p="0rem">
          <Icon.Filter my="-0.25rem" mx="-0.5rem" />
        </FlexAction>
      </Tippy>

      {filters.search && (
        <Chip
          ml="0.5rem"
          enableDelete={true}
          selected={true}
          onDelete={() => {
            dispatch(ACTIONS.tasks.setFilter("search", null, view));
          }}
          color="red"
          size="small"
        >
          {search}
        </Chip>
      )}

      {filters.type !== defaultFilters.type && (
        <Chip
          ml="0.5rem"
          enableDelete={true}
          selected={true}
          onDelete={() => {
            dispatch(
              ACTIONS.tasks.setFilter("type", defaultFilters.type, view)
            );
          }}
          color="green"
          size="small"
        >
          {TYPE[filters.type].value}
        </Chip>
      )}

      {filters.priority !== defaultFilters.priority && (
        <Chip
          ml="0.5rem"
          enableDelete={true}
          selected={true}
          onDelete={() => {
            dispatch(
              ACTIONS.tasks.setFilter("priority", defaultFilters.priority, view)
            );
          }}
          color="green"
          size="small"
        >
          {PRIORITIES[filters.priority].value}
        </Chip>
      )}

      {filters.status !== defaultFilters.status && (
        <Chip
          ml="0.5rem"
          enableDelete={true}
          selected={true}
          onDelete={() => {
            dispatch(
              ACTIONS.tasks.setFilter("status", defaultFilters.status, view),
              view
            );
          }}
          color="green"
          size="small"
        >
          {STATUS[filters.status].value}
        </Chip>
      )}

      {filters.status !== "CLOSED" && filters.due !== defaultFilters.due && (
        <Chip
          ml="0.5rem"
          enableDelete={true}
          selected={true}
          onDelete={() => {
            dispatch(ACTIONS.tasks.setFilter("due", defaultFilters.due, view));
          }}
          color="green"
          size="small"
        >
          {DUE[filters.due].value}
        </Chip>
      )}

      {filters.status === "CLOSED" && filters.period !== defaultFilters.period && (
        <Chip
          ml="0.5rem"
          enableDelete={false}
          selected={true}
          onDelete={() => {
            dispatch(
              ACTIONS.tasks.setFilter("period", defaultFilters.period, view)
            );
          }}
          color="green"
          size="small"
        >
          {PERIOD[filters.period].value}
        </Chip>
      )}

      {filters.assignment !== defaultFilters.assignment &&
        filters.assignment !== "SPECIFIC_USER" && (
          <Chip
            ml="0.5rem"
            enableDelete={true}
            selected={true}
            onDelete={() => {
              dispatch(
                ACTIONS.tasks.setFilter(
                  "assignment",
                  defaultFilters.assignment,
                  view
                )
              );
            }}
            color="green"
            size="small"
          >
            {ASSIGNMENT[filters.assignment].value}
          </Chip>
        )}

      {filters.assignment === "SPECIFIC_USER" && (
        <>
          <Chip
            ml="0.5rem"
            enableDelete={true}
            selected={true}
            onDelete={() => {
              dispatch(
                ACTIONS.tasks.setFilter(
                  "assignment",
                  defaultFilters.assignment,
                  view
                )
              );
            }}
            color="green"
            size="small"
          >
            {filters.assigneeId
              ? users[filters.assigneeId].name
              : ASSIGNMENT[filters.assignment].value}
          </Chip>
        </>
      )}

      {view !== "calendar" && (
        <Switch
          size="SMALL"
          ml="0.5rem"
          checked={hideEmptyLists}
          onCheck={hideLists}
          label={t("Hide empty lists")}
        />
      )}
    </Flex>
  );
};

export default Filters;
