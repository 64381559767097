import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Action,
  Avatar,
  Button,
  Column,
  Editor,
  Flex,
  formatDate,
  Icon,
  Image,
  orderObjects,
  Text,
  useLocalStorage,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { AddTask } from "modules";
import { API_ROOT } from "config";
import { DEFAULT_MARGIN } from "constant";
import { Conversations } from "modules/Conversations";
import { MainWindow, SideMenu } from "components";
import { SendMessage } from "./SendMessage";
import { SpacesMenu } from "modules";
import { t } from "i18n";

const OpenTask = ({ draft }) => {
  const [newTask, setNewTask] = useState();

  return (
    <>
      {newTask && (
        <AddTask
          full={true}
          typeId={1}
          description={draft}
          ownerId={newTask.ownerId}
          setOpen={setNewTask}
          allowMultiples={false}
          // onSave={onSave}
        />
      )}
      <Action onClick={() => setNewTask(true)}>Abrir tarefa</Action>
    </>
  );
};

const getStatusMessage = (users, user) => users[user.id].status;

const User = ({ index, user, bg, setOpenChat, userStatus }) => {
  const dispatch = useDispatch();

  const activeUser = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  let statusMessage;

  let acc =
    activeUser.id < user.id
      ? `D.${activeUser.id}.${user.id}`
      : `D.${user.id}.${activeUser.id}`;

  const status = users[user.id].status;

  const startConversation = () => {
    setOpenChat(true);
    // dispatch(
    //   ACTIONS.events.send("Start dm conversation", {
    //     acc,
    //     fromId: user.id,
    //     toId: activeUser.id
    //   })
    // );

    dispatch(
      ACTIONS.conversations.startConversation({
        acc,
        toId: activeUser.id,
        type: "D"
      })
    );
  };

  if (status) {
    if (status.message) {
      statusMessage = status.message;
    } else {
      if (userStatus === "away") {
        statusMessage = t("Away");
      } else if (userStatus === "free") {
        statusMessage = t("Free");
      } else if (userStatus === "offline") {
        statusMessage = formatDate(
          user.last_interaction_date,
          activeUser.locale,
          activeUser.timezone
        ).distance();
      }
    }
  } else {
    statusMessage = formatDate(
      user.last_interaction_date,
      activeUser.locale,
      activeUser.timezone
    ).distance();
  }

  return (
    <Flex
      px="1rem"
      py="0.5rem"
      mr="1rem"
      mb="1rem"
      flexDirection="column"
      minWidth="10rem"
      bg={bg ? bg : "l2"}
      borderRadius="0.5rem"
      style={{ cursor: activeUser.id !== user.id ? "pointer" : "" }}
      onClick={activeUser.id !== user.id ? startConversation : null}
      // boxShadow="0px 0px 6px 0px rgba(0,0,0,0.12)"
    >
      <Flex alignItems="center" mb="0.5rem">
        <Avatar
          online={onlineUsersIds.includes(user.id)}
          showOnline={false}
          src={user.avatar}
          size="large"
        />

        <Column ml="0.5rem">
          <Text fontSize="0.975rem" fontWeight="semibold" color="t3">
            {user.nickname}
          </Text>
          {false && (
            <Text fontSize="0.75rem" color="t3">
              {user.email}
            </Text>
          )}
        </Column>
      </Flex>

      <Flex alignItems="center" height="2rem">
        {status && status.emojiCode && (
          <Image
            width="2rem"
            src={`https://static.elefante.com/emojis/${status.emojiCode}.png`}
          />
        )}
        <Text fontSize="0.75rem" color="t3" ml="0.5rem" mr="0.25rem">
          {statusMessage}
        </Text>

        {activeUser.id !== user.id && (
          <Flex style={{ cursor: "pointer" }} onClick={startConversation}>
            <Icon.MessageCircle style={{ strokeWidth: "0px" }} fill="green" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

// const rooms = [
//   {
//     id: 1,
//     name: "Master room",
//     description: "aqualquer coisa",
//     locked: true,
//     temporary: false,
//     members: [6, 7, 9],
//     guests: [6]
//   },
//   {
//     id: 2,
//     name: "Master room",
//     description: "aqualquer coisa",
//     locked: true,
//     temporary: false,
//     members: [6, 7, 9],
//     guests: []
//   }
// ];

// export const VirtualRoomOld = () => {
//   const dispatch = useDispatch();
//   const { t } = useTranslation();

//   const activeConversation = useSelector(
//     (state) => state["conversations"].activeConversation
//   );

//   const user = useSelector((state) => state["account"].user);
//   const users = useSelector((state) => state["spaces"].users);
//   // const ws = useSelector((state) => state["ws"].ws);

//   useEffect(() => {
//     document.title = t("Status");
//     return () => (document.title = "Elefante");
//   }, [t]);

//   const orderedUsers = orderObjects(users, "name", "asc").filter(
//     (user) => !user.is_deleted
//   );

//   let acc = activeConversation.split("R.")[1];

//   const joinRoom = (room) => {
//     // dispatch(
//     //   ACTIONS.events.send("Start dm conversation", {
//     //     acc,
//     //     fromId: userId,
//     //     toId: user.id
//     //   })
//     // );

//     dispatch(
//       ACTIONS.conversations.startConversation({
//         acc: "R." + room.id,
//         toId: user.id,
//         type: "R"
//       })
//     );
//   };

//   if (acc) return <Room roomId={acc} />;

//   return (
//     <Flex
//       width={1}
//       height="100%"
//       flexDirection="column"
//       p="1.5rem"
//       overflow="auto"
//     >
//       {false && (
//         <Flex mt="1rem">
//           {rooms.map((room, index) => (
//             <Card
//               flexDirection="column"
//               height="10rem"
//               width="20rem"
//               justifyContent="space-between"
//               ml={index ? "2rem" : 0}
//               mb="2rem"
//             >
//               <Flex justifyContent="space-between">
//                 <Flex flexDirection="column">
//                   <Text fontSize="1rem" fontWeight="semibold">
//                     {room.name}
//                   </Text>
//                   <Text>{room.description}</Text>
//                 </Flex>

//                 {room.locked && <Icon.Lock />}
//               </Flex>

//               {!room.locked ||
//                 (room.locked && room.guests.includes(user.id) && (
//                   <FlexAction mx="auto" onClick={() => joinRoom(room)}>
//                     Join
//                   </FlexAction>
//                 ))}

//               <Flex flexWrap="wrap">
//                 {room.members.map((userId, index) => (
//                   <Avatar
//                     key={index}
//                     ml={index ? 1 : 0}
//                     src={users[userId].avatar}
//                   />
//                 ))}
//               </Flex>
//             </Card>
//           ))}
//           <FlexAction ml="1rem" my="auto">
//             New room
//           </FlexAction>
//         </Flex>
//       )}
//     </Flex>
//   );
// };

const RenderStatus = ({ save, setOpenChat }) => {
  const { t } = useTranslation();

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const spaceId = useSelector((state) => state["spaces"].spaceId);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const users = useSelector((state) => state["spaces"].users);

  let orderedUsers = orderObjects(users, "name", "asc").filter(
    (user) => !users[user.id].is_deleted && users[user.id].is_active
  );

  if (spaceId) {
    const space = spaces[spaceId];
    const members = space.members.map((member) => member.id);
    orderedUsers = orderedUsers.filter((user) => members.includes(user.id));
  }

  let busy = [];
  let free = [];
  let away = [];
  let dontDisturb = [];
  let offline = [];

  orderedUsers.forEach((user) => {
    const userStatus = getStatusMessage(users, user);
    if (!userStatus || !onlineUsersIds.includes(user.id)) {
      offline.push(user);
    } else if (userStatus.do_not_disturb) {
      dontDisturb.push(user);
    } else if (userStatus.away) {
      away.push(user);
    } else if (userStatus.message) {
      busy.push(user);
    } else {
      free.push(user);
    }
  });

  return (
    <Column>
      {free.length > 0 && (
        <>
          <Text textStyle="subtitle" mt="1.5rem">
            {t("Free")}
          </Text>
          <Flex flexWrap="wrap" mt="1rem">
            {free.map((user, index) => (
              <User
                key={user.id}
                userStatus="free"
                user={user}
                index={index}
                setOpenChat={setOpenChat}
                bg="lightGreen"
              />
            ))}
          </Flex>
        </>
      )}

      {dontDisturb.length > 0 && (
        <>
          <Text textStyle="subtitle" mt="1.5rem">
            {t("Do not disturb")}
          </Text>
          <Flex flexWrap="wrap" mt="1rem">
            {dontDisturb.map((user, index) => (
              <User
                key={user.id}
                user={user}
                index={index}
                userStatus="busy"
                setOpenChat={setOpenChat}
                bg="red"
              />
            ))}
          </Flex>
        </>
      )}

      {busy.length > 0 && (
        <>
          <Text textStyle="subtitle" mt="1.5rem">
            {t("Busy")}
          </Text>
          <Flex flexWrap="wrap" mt="1rem">
            {busy.map((user, index) => (
              <User
                key={user.id}
                user={user}
                index={index}
                userStatus="busy"
                setOpenChat={setOpenChat}
                bg="lightRed"
              />
            ))}
          </Flex>
        </>
      )}

      {away.length > 0 && (
        <>
          <Text textStyle="subtitle" mt="1.5rem">
            {t("Away")}
          </Text>
          <Flex flexWrap="wrap" mt="1rem">
            {away.map((user, index) => (
              <User
                key={user.id}
                userStatus="away"
                user={user}
                index={index}
                setOpenChat={setOpenChat}
                bg="lightOrange"
              />
            ))}
          </Flex>
        </>
      )}

      {offline.length > 0 && (
        <>
          <Text textStyle="subtitle" mt="1.5rem">
            {t("Unavailable")}
          </Text>
          <Flex flexWrap="wrap" mt="1rem">
            {offline.map((user, index) => (
              <User
                key={user.id}
                userStatus="offline"
                user={user}
                setOpenChat={setOpenChat}
                index={index}
                bg="l1"
              />
            ))}
          </Flex>
        </>
      )}
    </Column>
  );
};

export const VirtualRoom = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const preferences = useSelector((state) => state["account"].preferences);
  const user = useSelector((state) => state["account"].user);
  const ws = useSelector((state) => state["ws"].ws);

  const [draft, setDraft] = useLocalStorage("draft", []);
  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {
    document.title = t("Status");
    return () => (document.title = "Elefante");
  }, [t]);

  const save = (message) => {
    const status = {
      ...user.preferences.status,
      ...message,
      expireDate: new Date()
    };

    ws &&
      ws.send(
        JSON.stringify({
          type: "spaces",
          action: "setStatus",
          data: { status }
        })
      );

    dispatch(ACTIONS.account.setPreference("status", status));

    dispatch(
      ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
        preferences: { ...preferences, status }
      })
    );
  };

  return (
    <Flex width={1} height="100%" label="People">
      <SideMenu p={DEFAULT_MARGIN}>
        <Text textStyle="title">{t("People")}</Text>

        <SpacesMenu
          mt={DEFAULT_MARGIN}
          // menu={tree}
          module="people"
          showPublic={false}
          selectFirst={false}
          // onSelect={setSelected}
          // onUnSelect={setSelected}
          levels={0}
          // spacesActions={spacesActions}
          placement="right"
        />
      </SideMenu>

      <MainWindow>
        <Flex overflow="auto" mt={DEFAULT_MARGIN}>
          <Column width={openChat ? 1 / 2 : 1} overflow="auto">
            <RenderStatus save={save} setOpenChat={setOpenChat} />
          </Column>

          {openChat && (
            <Column width={1 / 2} maxWidth="50rem" ml="2rem" overflow="auto">
              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  {openChat ? (
                    <Text textStyle="subtitle">{t("Chat")}</Text>
                  ) : (
                    <Text textStyle="subtitle">{t("Draft")}</Text>
                  )}
                </Flex>
                {openChat && (
                  <Button
                    variant="actionEnabled"
                    value={t("Close")}
                    onClick={() => setOpenChat(false)}
                  />
                )}
              </Flex>

              {openChat ? (
                <Conversations update={true} />
              ) : (
                <Column key={uuidv4} mt="1rem">
                  <Editor
                    enableHint={true}
                    editorStyle={{
                      style: {
                        position: "relative",
                        padding: "1rem",
                        lineHeight: 1.5,
                        paddingTop: "3rem"
                      }
                    }}
                    mt="1rem"
                    position="relative"
                    placeholder={t(
                      "Write your tasks, notes, toughts, anything here... for later use"
                    )}
                    value={draft}
                    enableExit={false}
                    autoSave={(content) => setDraft(content)}
                    debounceTime={800}
                    enableToolbar={true}
                  />

                  <Flex mt="0.5rem">
                    {false && <Action>Postar no fórum</Action>}
                    <SendMessage draft={draft} setOpenChat={setOpenChat} />
                    <OpenTask draft={draft} />
                    {false && (
                      <Action
                        onClick={() => {
                          setDraft([]);
                        }}
                      >
                        {t("Clear")}
                      </Action>
                    )}
                  </Flex>
                </Column>
              )}
            </Column>
          )}
        </Flex>
      </MainWindow>
    </Flex>
  );
};
