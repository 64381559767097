import React from "react";
import { Node, Transforms } from "slate";

import { Upload } from "../../Upload";

export const insertFile = (editor, files) => {
  let content = files[0];

  const text = { text: "" };

  let type = "file";
  if (content.type.split("/")[0] === "image") type = "image";

  const file = {
    type: type,
    mimeType: content.type,
    name: content.name,
    url: URL.createObjectURL(content),
    children: [text],
    loading: true
  };

  Transforms.insertNodes(editor, file);
};

export const File = ({ editor, uploadEndpoint, uploadId, children }) => {
  const onAdd = (files) => {
    insertFile(editor, files);
  };

  const endAction = (response) => {
    let file = Array.isArray(response) ? response[0] : response;

    for (let [element, path] of Node.elements(editor)) {
      if (element.name === file.name && element.loading === true) {
        Transforms.setNodes(
          editor,
          {
            id: file.id,
            creationDate: file.creation_date,
            loading: false,
            size: file.size,
            url: `${file.filename}`
          },
          {
            at: path
          }
        );
        Transforms.unsetNodes(editor, "loading", {
          at: path
        });
        break;
      }
    }
  };

  return (
    <Upload
      maxFiles={5}
      maxFileSize={10}
      endpoint={uploadEndpoint}
      id={uploadId}
      onAdd={onAdd}
      endAction={endAction}
      dropzone={false}
    >
      {children}
    </Upload>
  );
};
