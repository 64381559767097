import React from "react";
import { differenceInDays } from "date-fns";
import { Cell, ResponsiveContainer, PieChart, Pie } from "recharts";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Line,
  LineChart,
  CartesianGrid,
  ComposedChart,
  Tooltip,
  Legend,
  YAxis,
  XAxis
} from "recharts";

import {
  Avatar,
  Card,
  Flex,
  formatDate,
  Icon,
  Image,
  orderObjects,
  Text,
  toTimestamp,
  useLocalStorage
} from "tomato";

import { calcTime } from "../util";
import { Dropdown } from "components";
import { PERIOD, PERIOD_OPTIONS } from "../constants";
import { Widget } from "components";

const COLORS = ["#00C49F", "lightGrey"];

const Donut = ({ stat }) => {
  const data = [
    { name: "done", value: stat ? stat.done : 0 },
    { name: "not done", value: stat ? stat.total - stat.done : 0 }
  ];

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          fill="#8884d8"
          innerRadius={15}
          outerRadius={20}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const UserStats = ({ stats }) => {
  const users = useSelector((state) => state["spaces"].users);

  return (
    <Flex flexDirection="column" mt="1rem">
      {Object.keys(stats).map((userId) => {
        let stat = stats[userId];
        return (
          <Card flexDirection="column" mb="2rem">
            <Text textStyle="subtitle" mb="0.5rem">
              {users[userId].name}
            </Text>
            {Object.keys(stat).map((type) => (
              <Text>
                {type} {stat[type]}
              </Text>
            ))}
          </Card>
        );
      })}
    </Flex>
  );
};

export const Dashboard = () => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state["tasks"].filters);
  const tasks = useSelector((state) => state["tasks"].objs);
  const users = useSelector((state) => state["spaces"].users);

  let stats = {
    done: 0,
    overdue: 0,
    notDone: 0,
    total: 0,
    timeSpent: 0,
    hours: 0,
    ageOpen: 0,
    ageDone: 0,
    evolutionDates: {}
  };

  let now = new Date().getTime();

  return null;
  let userStats = {};
  Object.values(users)
    .filter((user) => user.is_active && !user.is_deleted)
    .forEach((user) => {
      userStats[user.id] = {
        assignedToOthers: 0,
        assignedFromOthers: 0,
        created: 0,
        done: 0,
        doneAfterDue: 0,
        open: 0,
        overdue: 0,
        timeSpent: 0,
        hours: 0,
        abandoned: 0,
        ageOpen: 0,
        ageDone: 0
      };
    });

  let abandonedAge = 7 * 24 * 60 * 60 * 1000;

  Object.values(tasks).forEach((task) => {
    if (filters.spaceId && filters.spaceId !== task.space_id) return;
    if (filters.projectId && filters.projectId !== task.project_id) return;
    if (filters.listId && filters.listId !== task.list_id) return;

    let ownerId = task.owner_id;
    let createdById = task.created_by_id;
    let doneById = task.done_by_id;
    let age;

    stats.total += 1;

    // Prever tasks cridas por automações
    if (!createdById) return;

    console.log(createdById);
    //
    if (ownerId && ownerId !== createdById) {
      userStats[createdById].assignedToOthers += 1;
      userStats[ownerId].assignedFromOthers += 1;
    }

    if (task.done_date) {
      stats.done += 1;
      userStats[doneById].done += 1;
      age = toTimestamp(task.done_date) - toTimestamp(task.creation_date);

      if (task.deadline && task.deadline < task.done_date) {
        if (ownerId) {
          userStats[ownerId].doneAfterDue += 1;
          userStats[ownerId].ageDone += age;
        } else {
          userStats[createdById].doneAfterDue += 1;
          userStats[createdById].ageDone += age;
        }
      }
    } else {
      stats.notDone += 1;
      age = now - toTimestamp(task.creation_date);

      if (ownerId) {
        userStats[ownerId].open += 1;
        userStats[ownerId].ageOpen += age;
        if (age > abandonedAge) userStats[ownerId].abandoned += 1;
      } else {
        userStats[createdById].open += 1;
        userStats[createdById].ageOpen += age;
        if (age > abandonedAge) userStats[createdById].abandoned += 1;
      }

      if (task.deadline && toTimestamp(task.deadline) < now) {
        stats.overdue += 1;

        if (ownerId) {
          userStats[ownerId].overdue += 1;
        } else {
          userStats[createdById].overdue += 1;
        }
      }
    }

    userStats[createdById].created += 1;
  });

  console.log(userStats);

  return (
    <Flex flexDirection="column" overflow="auto">
      <Card width={1}>
        <Flex justifyContent="space-between" width={1}>
          <Flex width={1 / 4} flexDirection="column" alignItems="center">
            <Text fontSize="1.75rem" color="t3" fontWeight="semibold">
              {stats.done}
            </Text>

            <Text color="t4">{t("Completed")}</Text>
          </Flex>

          <Flex width={1 / 4} flexDirection="column" alignItems="center">
            <Text fontSize="1.75rem" color="t3" fontWeight="semibold">
              {stats.notDone}
            </Text>

            <Text color="t4">{t("Pending")}</Text>
          </Flex>

          <Flex width={1 / 4} flexDirection="column" alignItems="center">
            <Text fontSize="1.75rem" color="t3" fontWeight="semibold">
              {stats.overdue}
            </Text>

            <Text color="t4">{t("Overdue")}</Text>
          </Flex>

          <Flex width={1 / 4} flexDirection="column" alignItems="center">
            <Text fontSize="1.75rem" color="t3" fontWeight="semibold">
              {stats.total}
            </Text>

            <Text color="t4">{t("Total")}</Text>
          </Flex>
        </Flex>
      </Card>

      {false && <UserStats stats={userStats} />}
    </Flex>
  );
};
