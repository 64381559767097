import React from "react";
import styled, { css, keyframes } from "styled-components";

import { Flex } from "tomato";

const particles = 45;
const width = 300;
const height = 300;

const mixin = ({ property, content }) => {
  return `-webkit-${property}: ${content} 
      -moz-${property}: ${content} 
      -o-${property}: ${content} 
      -ms-${property}: ${content} 
      ${property}: ${content}`;
};

const shadow = () => {
  let shadow = "";
  for (let step = 0; step < particles; step++) {
    shadow += `${Math.random() * width - width / 2}px ${
      Math.random() * height - height / 1.2
    }px hsl(${Math.random() * 360}, 100%, 50%)`;
    if (step === particles - 1) {
      shadow += ";";
    } else {
      shadow += ",";
    }
  }
  return shadow;
};

const shadowBase = () => {
  let shadow = "";
  for (let step = 0; step < particles; step++) {
    shadow += "0 0 #fff";
    if (step === particles - 1) {
      shadow += ";";
    } else {
      shadow += ",";
    }
  }
  return shadow;
};

const transform = mixin({
  name: "transform",
  property: "transform",
  content: "translateY(200px);"
});

const bang = keyframes` 
{
  to {
    box-shadow: ${shadow()};
  }
}
`;

const gravity = keyframes`
{
  to {
    ${transform};
    opacity: 0;
  }
}
`;

const animation = (props) =>
  css`1s ${bang} ease-out 1 backwards, 1s ${gravity} ease-in 1 backwards `;

const Fire = styled(Flex)`
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: ${shadowBase()};
  animation: ${animation};
`;

export const Fireworks = ({ ...props }) => {
  return <Fire {...props} />;
};
