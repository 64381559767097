import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Card,
  Dropdown,
  Flex,
  FlexAction,
  Image,
  Input,
  Overlay,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT, ROOT_URL } from "config";
import { ColorPicker } from "components";
import { Status } from "./Status";
import { createNotificationSubscription } from "modules";

const Profiles = ({ open, setOpen }) => {
  return (
    <Overlay key="profile" open={open} setOpen={setOpen} opacity="10%">
      <Profile />
    </Overlay>
  );
};

export const ProfileMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const preferences = useSelector((state) => state["account"].preferences);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [openProfile, setOpenProfile] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const doLogout = () => {
    dispatch(
      ACTIONS.fetch.get(
        "account",
        `${ROOT_URL}/logout/`,
        ACTIONS.account.logout
      )
    );
  };

  return (
    <>
      {false && <Status open={openStatus} setOpen={setOpenStatus} />}

      <Profiles open={openProfile} setOpen={setOpenProfile} />

      <Flex
        height="fit-content"
        bg="l0"
        flexDirection="column"
        width="18.75rem"
        p="1rem"
        color="t1"
      >
        <Flex alignItems="center">
          <Avatar size="x-large" ml="0.375rem" src={users[user.id].avatar} />
          <Flex ml="0.75rem" flexDirection="column">
            <Text fontSize="1rem" fontWeight="semibold">
              {user.nickname}
            </Text>

            {preferences.status.emojiCode && (
              <Flex alignItems="center">
                <Image
                  width="1rem"
                  src={`https://static.elefante.com/emojis/${preferences.status.emojiCode}.png`}
                />
                <Text color="t2" ml="0.25rem">
                  {preferences.status.message}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        {false && (
          <Flex
            hoverColor="userLight"
            px="1rem"
            py="0.5rem"
            my="1rem"
            borderRadius="0.375rem"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenStatus(!openStatus)}
          >
            <Text fontSize="1rem">{t("Update status")}</Text>
          </Flex>
        )}

        <Flex
          mt="1rem"
          hoverColor="userLight"
          px="1rem"
          py="0.5rem"
          borderRadius="0.375rem"
          style={{ cursor: "pointer" }}
          onClick={() => setOpenProfile(!openProfile)}
        >
          <Text fontSize="1rem">{t("Edit profile")}</Text>
        </Flex>

        {false && (
          <>
            <Flex
              hoverColor="userLight"
              px="1rem"
              py="0.5rem"
              borderRadius="0.375rem"
              style={{ cursor: "pointer" }}
            >
              <Text fontSize="1rem">{t("Preferences")}</Text>
            </Flex>
          </>
        )}

        <Flex
          hoverColor="userLight"
          px="1rem"
          py="0.5rem"
          borderRadius="0.375rem"
          style={{ cursor: "pointer" }}
          onClick={doLogout}
        >
          <Text fontSize="1rem">{t("Sign out")}</Text>
        </Flex>
      </Flex>
    </>
  );
};

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);

  let preferences = { ...user.preferences };
  let notifications = { ...preferences.notifications };
  let subscriptions = [];

  let permission = Notification.permission;

  const notificationCapable =
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  const requestPermission = () => {
    createNotificationSubscription().then((subscription) => {
      let shouldUpdate = false;

      if (!notifications) {
        shouldUpdate = true;
      } else {
        subscriptions = [...notifications.subscriptions];
      }

      let subscribed = notifications.subscriptions.some(
        (sub) => sub.endpoint === subscription.endpoint
      );

      if (shouldUpdate || !subscribed) {
        subscriptions.push(subscription);

        notifications.subscriptions = subscriptions;
        preferences.notifications = notifications;

        dispatch(ACTIONS.account.setPreference("notifications", notifications));
        dispatch(
          ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
            preferences: { ...preferences, notifications }
          })
        );
      }
    });
  };

  useEffect(() => {
    if (!notificationCapable || permission === "denied") return;
    requestPermission();
  }, []);

  if (!notificationCapable) return null;

  let message;

  let style = {};
  switch (permission) {
    case "default":
      message = t("Waiting for permission");
      style.bg = "l2";
      break;

    case "denied":
      message = t(
        "We cant's send notifications to you because permissions are denied. Please unblock notifications permissions first."
      );
      style.bg = "lightRed";
      break;

    case "granted":
      message = t("Granted");
      style.bg = "lightGreen";
      break;

    default:
  }

  return (
    <Flex flexDirection="column" mt="3.5rem">
      <Text textStyle="subtitle">{t("Notifications")}</Text>

      <Flex mt="1rem" alignItems="center">
        <Text fontSize="1rem" fontWeight="semibold" color="t1" minWidth="7rem">
          {t("Status")}:
        </Text>

        <Text
          fontSize="1rem"
          borderRadius="0.5rem"
          bg={Notification.permission === "granted" ? "lightGreen" : "lightRed"}
          {...style}
          p="0.5rem"
          px="1rem"
        >
          {message}
        </Text>
      </Flex>
    </Flex>
  );
};

export const Profile = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const preferences = useSelector((state) => state["account"].user.preferences);
  const LANG = { "pt-BR": t("Portuguese"), "en-US": t("English") };

  const languages = [
    { id: "pt-BR", value: t("Portuguese") },
    { id: "en-US", value: t("English") }
  ];

  const userLang = { id: user.locale, value: LANG[user.locale] };

  const setColor = (color) => {
    dispatch(ACTIONS.account.setPreference("color", color));
    dispatch(
      ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
        preferences: { ...preferences, color }
      })
    );
  };

  const save = (stateAttr, key, value) => {
    if (!value) return;

    let data = {};
    data[key] = value;
    dispatch(ACTIONS.account.updateUser(stateAttr, value));
    dispatch(
      ACTIONS.toast.addToast({
        message: t("Profile updated!"),
        type: "success",
        autoClose: 2500
      })
    );
    dispatch(
      ACTIONS.fetch.patch("account", `${API_ROOT}/user/${user.id}/`, [], {
        ...data
      })
    );
  };

  const saveLang = (option) => {
    i18n.changeLanguage(option.id, (err, t) => {
      dispatch({ type: "changeLanguage", language: option.id });
    });
    save("locale", "language", option.id);
  };

  return (
    <Card
      height="fit-content"
      flexDirection="column"
      width="40rem"
      p="2rem"
      pt="1rem"
    >
      <Avatar size="xlarge" src={users[user.id].avatar} />

      <Text mt="2rem" textStyle="subtitle">
        {t("Your Profile")}
      </Text>
      <Flex mt="1rem" alignItems="center">
        <Text fontSize="1rem" fontWeight="semibold" minWidth="7rem">
          {t("Name")}:
        </Text>
        <Input
          width="20rem"
          value={user.name}
          onEnter={(value) => {
            save("name", "name", value);
          }}
        />
      </Flex>

      <Flex mt="1rem" alignItems="center">
        <Text fontSize="1rem" fontWeight="semibold" minWidth="7rem">
          {t("Nickname")}:
        </Text>
        <Input
          width="20rem"
          value={user.public_name}
          onBlur={(value) => {
            save("nickname", "public_name", value);
          }}
          onEnter={(value) => {
            save("nickname", "public_name", value);
          }}
        />
      </Flex>

      <Flex mt="1rem" alignItems="center">
        <Text fontSize="1rem" fontWeight="semibold" minWidth="7rem">
          {t("Language")}:
        </Text>
        <Dropdown
          width="20rem"
          options={languages}
          selected={userLang}
          onSelect={saveLang}
        />
      </Flex>

      <Flex mt="1.5rem" alignItems="center">
        <Text fontSize="1rem" fontWeight="semibold" minWidth="7rem">
          {t("Time zone")}:
        </Text>
        <Text fontSize="1rem">{user.timezone}</Text>
      </Flex>

      <Flex mt="1.5rem" alignItems="center" minHeight="3rem">
        <Text fontSize="1rem" fontWeight="semibold" minWidth="7rem">
          {t("Color")}:
        </Text>

        <ColorPicker color={preferences.color} setColor={setColor} />

        {(preferences.color.h !== 240 || preferences.color.s !== 10) && (
          <FlexAction
            ml="1rem"
            bg="hsl(240, 10%, 70%)"
            onClick={() => {
              setColor({ h: 240, s: 10, l: 70 });
            }}
          >
            <Text>{t("Restore default")}</Text>
          </FlexAction>
        )}
      </Flex>
      <Notifications />
    </Card>
  );
};
