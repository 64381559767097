import React from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { EmojiPicker, Flex, Image, Text, Tippy } from "tomato";

const Reactions_ = ({ acc, message, ...props }) => {
  const { t } = useTranslation();

  let reactions = message.reactions;

  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const ws = useSelector((state) => state["ws"].ws);

  const updateReaction = (code) => {
    let reactions = [...message.reactions];

    let reactionIndex = reactions.findIndex((emoji) => emoji.code === code);
    if (reactionIndex === -1) {
      reactions = [...reactions, { code, users: [user.id] }];
    } else {
      let reaction = { ...reactions[reactionIndex] };
      reaction.users = [...reaction.users];

      if (reaction.users.includes(user.id)) {
        let userIndex = reaction.users.findIndex(
          (userId) => userId === user.id
        );
        reaction.users.splice(userIndex, 1);

        if (reaction.users.length === 0) {
          reactions.splice(reactionIndex, 1);
        } else reactions[reactionIndex] = reaction;
      } else {
        reaction.users.push(user.id);
        reactions[reactionIndex] = reaction;
      }
    }

    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "reaction",
        data: {
          acc: acc,
          id: message.id,
          fromId: user.id,
          reactions: JSON.stringify(reactions)
        }
      })
    );
  };

  const showUsers = (reaction) => {
    let count = reaction.users.length;
    let content = "";
    let filtered = reaction.users.filter((id) => id !== user.id);
    let includesYou = reaction.users.includes(user.id);

    if (includesYou) {
      content = t("You");
    }

    filtered.map((userId, index) => {
      if (count > 1) {
        if (includesYou) {
          if (count === index + 2) {
            content += ` ${t("and")} `;
          } else {
            content += ", ";
          }
        } else {
          if (
            (count > 2 && count === index + 1) ||
            (index === 1 && count === 2)
          ) {
            content += ` ${t("and")} `;
          } else if (count > 2) {
            content += ", ";
          }
        }
      }

      content += users[userId].nickname;
      return <Text>{content}</Text>;
    });

    return (
      <Flex flexDirection="column" alignItems="center" p="1rem">
        <Image
          mx="2rem"
          mb="0.25rem"
          width="2rem"
          minWidth="2rem"
          height="2rem"
          minHeight="2rem"
          src={`https://static.elefante.com/emojis/${reaction.code}.png`}
        />
        <Text>{content}</Text>
      </Flex>
    );
  };

  return (
    <Flex {...props} alignItems="center" flexWrap="wrap" borderRadius={4}>
      {reactions.map((reaction, index) => {
        return (
          <Tippy
            key={index}
            content={showUsers(reaction)}
            borderRadius="0.5rem"
          >
            <Flex
              alignItems="center"
              key={reaction.code}
              bg={reaction.users.includes(user.id) ? "lightBlue" : "l2"}
              border={reaction.users.includes(user.id) ? "1px solid blue" : ""}
              borderRadius="1rem"
              px="0.5rem"
              py="0.25rem"
              mr={2}
              my={1}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                updateReaction(reaction.code);
              }}
            >
              <Image
                mr="0.25rem"
                width={16}
                minWidth={16}
                height={16}
                minHeight={16}
                src={`https://static.elefante.com/emojis/${reaction.code}.png`}
              />

              <Text
                fontWeight={
                  reaction.users.includes(user.id) ? "semibold" : "regular"
                }
                color={reaction.users.includes(user.id) ? "blue" : "t3"}
                fontSize="0.75rem"
              >
                {reaction.users.length}
              </Text>
            </Flex>
          </Tippy>
        );
      })}

      <Flex>
        <EmojiPicker onSelect={updateReaction} />
      </Flex>
    </Flex>
  );
};

export { Reactions_ as Reactions };
