import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

export const Undelete = ({ task }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const undeleteTask = () => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/undelete`,
        ACTIONS.tasks.undeleteTask
      )
    );
  };

  return (
    <Flex
      pt="1.5rem"
      px="1rem"
      justifyContent="center"
      borderBottom="1px solid lightGrey"
      pb="1.5rem"
    >
      <Flex flexDirection="column" mx="2rem" flexGrow="1">
        <Flex bg="lightRed" px="1rem" py="0.5rem" borderRadius="0.5rem">
          <Icon.Info stroke="red" style={{ strokeWidth: "2px" }} />
          <Text ml="0.5rem" color="red" fontSize="1rem" fontWeight="bold">
            {t("This task was deleted")}
          </Text>
        </Flex>

        <Text
          mt="1rem"
          fontSize="1.125rem"
          fontWeight="semibold"
          px="3rem"
          py="0.5rem"
          bg="l2"
          hoverColor="green"
          borderRadius="0.5rem"
          mr="1rem"
          textAlign="center"
          style={{ cursor: "pointer" }}
          onClick={undeleteTask}
        >
          {t("Undelete task")}
        </Text>
      </Flex>
    </Flex>
  );
};
