import { insertFile } from "../Helpers/File";

export const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        console.log(file);
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          const url = reader.result;
          insertFile(editor, url);
        });

        reader.readAsDataURL(file);
      }
    }
    // else if (false && isImageUrl(text)) {
    // insertImage(editor, text);
    // }
    else {
      insertData(data);
    }
  };

  return editor;
};
