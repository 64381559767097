export * from "./forms";
export * from "./history";
export * from "./invites";
export * from "./resources";

export const DEFAULT_MARGIN = "1.5rem";
export const DEFAULT_SIDEMENU_WIDTH = "18rem";
export const EMPTY_CONTENT = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];
