import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { Avatar, Flex, ToolTip, Text } from "tomato";
import { UI } from "tomato";

import { ACTIONS } from "store";
import { TASKS } from "crud";

const Event = ({ event, users }) => {
  const dispatch = useDispatch();

  const openTask = (taskId) => {
    dispatch(ACTIONS.state.setAttribute("tasks", "loadingObjId", taskId));
    TASKS.getOne(taskId);
  };

  return (
    <Flex alignItems="center" height="24px">
      <ToolTip
        location={UI.POSITION.TOP}
        content={event.owner_id ? users[event.owner_id].name : null}
      >
        <Avatar
          src={event.owner_id ? users[event.owner_id].avatar : null}
          size="small"
          mr="0.25rem"
        />
      </ToolTip>

      <Text
        fontSize="0.75rem"
        bg={event.color}
        py="0.125rem"
        px="0.25rem"
        borderRadius="0.25rem"
        style={{
          cursor: "pointer",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textDecoration: event.done ? "line-through" : ""
        }}
        onClick={() => openTask(event.id)}
      >
        {event.subject}
      </Text>
    </Flex>
  );
};

export const Events = ({ events, users }) => {
  const [max, setMax] = useState((events && events.length) || 0);

  const observer = useRef(
    new ResizeObserver((entries) => {
      // console.log(entries[0].contentRect);
      const { height } = entries[0].contentRect;
      setMax(parseInt(height / 24));
    })
  );

  // console.log(max);
  const dayRef = useCallback((node) => {
    if (node === null) return;
    observer.current.observe(node);

    return () => {
      observer.current.unobserve();
    };
  }, []);

  if (!events) return null;

  return (
    <Flex flexDirection="column" width={1} ref={dayRef} height="100%">
      {events.map((event, index) => {
        if (index + 1 > max) return null;
        return <Event key={index} event={event} users={users} />;
      })}
    </Flex>
  );
};
