import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Emoji, Flex, Text } from "tomato";

import { ACTIONS } from "store";

export const Breadcrumb = () => {
  const dispatch = useDispatch();

  const item = useSelector((state) => state["pages"].item);
  const menu = useSelector((state) => state["pages"].menu);
  const page = useSelector((state) => state["pages"].pages[item.id]);
  const pages = useSelector((state) => state["pages"].pages);
  const space = useSelector((state) => state["spaces"].spaces[page.space_id]);

  let path = [];
  let pathPage = page;

  while (pathPage.parent_id) {
    pathPage = pages[pathPage.parent_id];
    path.unshift(pathPage);
  }

  return (
    <Flex alignItems="center">
      <Text
        color="t3"
        style={{ cursor: "pointer" }}
        fontSize="0.875rem"
        onClick={() => {
          dispatch(
            ACTIONS.state.changeState("pages", (state) => {
              state.item = null;
              state.spaceId = space.id;
              return state;
            })
          );
        }}
      >
        {space.name}
      </Text>

      {path.map((page) => (
        <Fragment key={page.id}>
          <Text color="t3" fontSize="0.875rem">
            &nbsp;
            {"/"}
            &nbsp;
          </Text>

          {page.icon.type && (
            <Emoji mr="0.25rem" code={page.icon.code} size="1.125rem" />
          )}
          <Text
            color="t3"
            style={{ cursor: "pointer" }}
            fontSize="0.875rem"
            onClick={() => {
              dispatch(
                ACTIONS.state.setAttribute("pages", "item", menu[page.uuid])
              );
            }}
          >
            {page.title || "Untitled"}
          </Text>
        </Fragment>
      ))}

      <Flex alignItems="center">
        <Text color="t3" fontSize="0.875rem">
          &nbsp;/&nbsp;
        </Text>

        {page.icon.type && (
          <Emoji mr="0.25rem" code={page.icon.code} size="1.125rem" />
        )}

        <Text color="t3" fontSize="0.875rem">
          {page.title || "Untitled"}
        </Text>
      </Flex>
    </Flex>
  );
};
