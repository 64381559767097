 import { animated } from "react-spring";
import styled from "styled-components";
import {
  border,
  color,
  grid,
  layout,
  position,
  shadow,
  space,
  typography
} from "styled-system";

export const Span = styled("span")`
   box-sizing: border-box;
   ${border}
   ${color}
   ${shadow}
   ${space}
   ${typography}      
`;
            
