import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Flex, Icon, Input, Text } from "tomato";

import { ACTIONS } from "store";
import { ROOT_URL } from "config";

export const ReAuth = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const history = useHistory();

  const user = useSelector((state) => state["account"].user);
  const authenticated = useSelector(
    (state) => state["account"].user.authenticated
  );
  const needsAuth = useSelector((state) => state["account"].user.needsAuth);
  const pendingActions = useSelector(
    (state) => state["network"].pendingActions
  );

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [doingLogin, setDoingLogin] = useState(false);

  const memoizedDispatch = useCallback(
    (props) => {
      dispatch(props);
    },
    [dispatch]
  );

  useEffect(() => {
    if (authenticated && !needsAuth && pendingActions.length !== 0) {
      memoizedDispatch(ACTIONS.network.runPendingActions());
    }
  }, [memoizedDispatch, needsAuth, authenticated, pendingActions]);

  const validatePassword = (password) => {
    setPassword(password);
    if (password.length > 4) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  let savedUser = JSON.parse(localStorage.getItem("user"));
  const handleSubmit = () => {
    dispatch(
      ACTIONS.fetch.post(
        "account",
        `${ROOT_URL}/login/do_auth/`,
        ACTIONS.account.loginDone,
        { email: user.email || savedUser.email, password }
      )
    );
  };

  if (needsAuth && !(savedUser && savedUser.name && savedUser.email))
    history.push("/login");

  return (
    <Flex
      width="25rem"
      flexDirection="column"
      bg="l0"
      p="2em"
      borderRadius="1em"
    >
      {savedUser && (
        <Text fontWeight="bold" fontSize={18} mb={4}>
          {savedUser.name},
        </Text>
      )}

      <Text>
        {t(
          "For your safety, please reenter your password to confirm that is you using the app."
        )}
      </Text>

      <Flex alignItems="center" width={1} mt={32}>
        <Input
          type={showPassword ? "text" : "password"}
          placeholder={t("Enter your password")}
          onChange={validatePassword}
          rightIcon={showPassword ? "Eye" : "EyeOff"}
          rightIconClick={() => setShowPassword(!showPassword)}
          flexGrow="1"
        />
        <Icon.Ok
          minWidth={18}
          minHeight={18}
          ml={2}
          stroke={passwordValid ? "green" : "lightGrey"}
          style={{ strokeWidth: "4px" }}
        />
      </Flex>

      <Flex width={1} mt="2rem" justifyContent="flex-end">
        {doingLogin ? (
          <Flex>{t("login")}</Flex>
        ) : (
          <Button
            variant={passwordValid ? "primary" : "primary"}
            disabled={!passwordValid}
            onClick={handleSubmit}
            value={t("Authenticate")}
          />
        )}
      </Flex>
    </Flex>
  );
};
