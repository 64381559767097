import React from "react";
import ReactDOM from "react-dom";
import { Transforms } from "slate";

import { Box, Flex } from "tomato";
// import { Portal } from "../SimpleEditor";

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

export const insertMention = (editor, content, id) => {
  const mention = {
    type: "mention",
    id,
    children: [{ text: content }]
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export const MentionDropdown = React.forwardRef(({ options, index }, ref) => (
  <Portal>
    <Box
      ref={ref}
      style={{
        top: "-9999px",
        left: "-9999px",
        position: "absolute",
        zIndex: 1,
        padding: "4px",
        background: "white",
        borderRadius: "4px",
        boxShadow: "0 1px 5px rgba(0,0,0,.2)"
      }}
    >
      {options.map((option, i) => (
        <Flex
          key={option}
          style={{
            padding: "2px 4px",
            borderRadius: "4px",
            background: i === index ? "#B4D5FF" : "transparent"
          }}
        >
          {option.value}
        </Flex>
      ))}
    </Box>
  </Portal>
));

MentionDropdown.displayName = "MentionDropdown";

export const withMention = (editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  return editor;
};
