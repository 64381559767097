import { createActions, handleActions } from "redux-actions";

const modelId = "events";

const options = { prefix: "events", namespace: "::" };

export const eventActions = createActions(
  {
    SEND: (name, attributes) => ({
      module: modelId,
      name,
      attributes
    })
  },
  options
);

const reducer = handleActions(
  {
    SEND: (state, { payload: { name, attributes } }) => {
      return state;
    }
  },
  {},
  options
);

export default reducer;
