import {
  differenceInSeconds,
  differenceInCalendarDays,
  endOfToday,
  format,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isPast,
  isFuture,
  isToday,
  isTomorrow,
  isYesterday,
  parseISO
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

// import { useProvider } from "../Provider";

export const FormatDate = {};

export const Distance = (date) => {
  // let { tz } = useProvider();
  let { tz } = "America/Sao_Paulo";

  if (!date) return "";

  let newDate;
  if (typeof date === "string") {
    newDate = parseISO(date);
    newDate = utcToZonedTime(newDate, tz);
  } else {
    newDate = date;
  }

  if (isFuture(newDate)) {
    return "in " + formatDistanceToNow(newDate);
  }
  return formatDistanceToNow(newDate) + " ago";
};

export const DaysDifference = (date) => {
  if (!date) return "";
  let newDate = new Date(date);
  return differenceInCalendarDays(Date.now(), newDate);
};
export const Difference = (date) => {
  if (!date) return "";
  let newDate = new Date(date);
  return differenceInSeconds(Date.now(), newDate);
};

export const Expired = (date) => {
  if (!date) return "";
  let newDate = new Date(date);
  return isPast(newDate);
};

export const Today = (date) => {
  if (!date) return "";
  let newDate = new Date(date);
  return isToday(newDate);
};

export const Tomorrow = (date) => {
  if (!date) return "";
  let newDate = new Date(date);
  return isTomorrow(newDate);
};

export const isDueUntilToday = (date) => {
  if (!date) return false;
  let newDate = new Date(date);
  return isBefore(newDate, endOfToday());
};

export const isDueFromTomorrow = (date) => {
  if (!date) return false;
  let newDate = new Date(date);
  return isAfter(newDate, endOfToday());
};

export const isDueTomorrow = (date) => {
  if (!date) return false;
  let newDate = new Date(date);
  return isTomorrow(newDate);
};

export const dMMM = (date) => {
  if (!date) return false;
  let newDate = new Date(date);
  if (isToday(newDate)) return "Today";
  if (isTomorrow(newDate)) return "Tomorrow";
  if (isYesterday(newDate)) return "Yesterday";

  return format(newDate, "d MMM");
};

FormatDate.Distance = Distance;
FormatDate.DaysDifference = DaysDifference;
FormatDate.Difference = Difference;
FormatDate.Expired = Expired;
FormatDate.isTomorrow = Tomorrow;
FormatDate.isToday = Today;
FormatDate.isDueUntilToday = isDueUntilToday;
FormatDate.isDueFromTomorrow = isDueFromTomorrow;
FormatDate.isDueTomorrow = isDueTomorrow;
FormatDate.dMMM = dMMM;
