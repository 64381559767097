import React from "react";

import Icon from "../Icon";

const ChevronUp = props => {
  return (
    <Icon {...props}>
      <polyline points="18 15 12 9 6 15" />
    </Icon>
  );
};

export default ChevronUp;
