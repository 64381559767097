import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSpring } from "react-spring";
import styled from "styled-components";

import { AnimatedBox, Box } from "../";

// const Life = AnimatedBox()`
//   position: absolute;
//   bottom: ${props => (props.top ? '10px' : '0')};
//   left: 0px;
//   width: auto;
//   background-image: linear-gradient(130deg, #00b4e6, #00f0e0);
//   height: 5px;

let colorBlue = `
background: rgb(161, 219, 255);
background: -moz-linear-gradient(
 left,
 rgba(161, 219, 255, 1) 0%,
 rgba(203, 235, 255, 1) 53%,
 rgba(240, 249, 255, 1) 100%
);
background: -webkit-linear-gradient(
 left,
 rgba(161, 219, 255, 1) 0%,
 rgba(203, 235, 255, 1) 53%,
 rgba(240, 249, 255, 1) 100%
);
background: linear-gradient(
 to right,
 rgba(161, 219, 255, 1) 0%,
 rgba(203, 235, 255, 1) 53%,
 rgba(240, 249, 255, 1) 100%
);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a1dbff', endColorstr='#f0f9ff',GradientType=1 );
`;

let colorRed = `
background: rgb(255, 0, 0);
  background: -moz-linear-gradient(
    left,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 247, 241, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 247, 241, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 247, 241, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#fff7f1',GradientType=1 );
`;

const Animate = styled(AnimatedBox)`
  ${colorBlue}
`;

export const LoadingBar = () => {
  let open = useSelector((state) => state["network"]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open.activity && !loading) setLoading(true);
    if (!open.activity && loading) setLoading(false);
  }, [open]);

  const props = useSpring({ width: "100%", from: { width: "0" } });

  const animatedConfig = useSpring({
    config: { mass: 1.5, tension: 170, friction: 46 },
    to: [
      {
        width: loading ? "1000" : "0px",
        visibility: loading ? "visible" : "hidden"
      }
    ],
    from: {
      width: "0px"
    }
  });

  return (
    <>
      <Box
        width={1}
        position="absolute"
        top="0px"
        left="0px"
        zIndex="10000"
        label="LoadingBar"
      >
        <Animate style={{ ...animatedConfig }} height="4px" />
      </Box>
    </>
  );
};
