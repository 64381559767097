import React, { useState } from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  EmojiPicker,
  Flex,
  FlexHidden,
  FlexWrapper,
  formatDate,
  Icon,
  SimpleEditor,
  strip,
  Text,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT, ROOT_URL } from "config";
import { Reactions } from "./Reactions";

const Date = ({ date }) => {
  if (!date) return null;

  return (
    <Flex justifyContent="center" mt="1.5rem" alignItems="center">
      <Flex borderTop="1px solid lightGrey" height="1px" flexGrow="1" />
      <Text
        borderRadius="2rem"
        border="1px solid lightGrey"
        // bg="lightBlue"
        px="1rem"
        py="0.5rem"
        fontSize="0.75rem"
        color="t1"
        fontWeight="semibold"
      >
        {date}
      </Text>
      <Flex borderTop="1px solid lightGrey" height="1px" flexGrow="1" />
    </Flex>
  );
};

export const Message = ({
  acc,
  msg,
  newUser,
  read,
  type,
  date,
  index,
  addTask,
  showReply = true
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const locale = useSelector((state) => state["account"].user.locale);
  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const timezone = useSelector((state) => state["account"].user.timezone);
  const users = useSelector((state) => state["spaces"].users);
  const currentUser = useSelector((state) => state["account"].user);
  const user = users[msg.user_id];
  const ws = useSelector((state) => state["ws"].ws);

  const [enableEdit, setEnableEdit] = useState(false);

  const deleteMessage = () => {
    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "deleteMessage",
        data: {
          acc: acc,
          id: msg.id
        }
      })
    );
  };

  const loadThred = () => {
    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "getThread",
        data: {
          parentId: msg.reply_to_id || msg.id
        }
      })
    );

    dispatch(
      ACTIONS.state.setAttribute("conversations", "activeThread", {
        loading: true,
        parent: null,
        messages: null
      })
    );
  };

  const saveMessage = (message) => {
    setEnableEdit(false);
    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "updateMessage",
        data: {
          acc,
          id: msg.id,
          userId: currentUser.id,
          message: JSON.stringify(message),
          summary: strip(message.map((n) => Node.string(n)).join(" ")).slice(
            0,
            20
          )
        }
      })
    );
  };

  const updateReactions = (code) => {
    let reactions = [...msg.reactions];

    let reactionIndex = reactions.findIndex((emoji) => emoji.code === code);
    if (reactionIndex === -1) {
      reactions = [...reactions, { code, users: [currentUser.id] }];
    } else {
      let reaction = { ...reactions[reactionIndex] };
      reaction.users = [...reaction.users];

      if (reaction.users.includes(currentUser.id)) {
        let userIndex = reaction.users.findIndex(
          (userId) => userId === currentUser.id
        );
        reaction.users.splice(userIndex, 1);

        if (reaction.users.length === 0) {
          reactions.splice(reactionIndex, 1);
        } else reactions[reactionIndex] = reaction;
      } else {
        reaction.users.push(currentUser.id);
        reactions[reactionIndex] = reaction;
      }
    }

    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "reaction",
        data: {
          acc: acc,
          id: msg.id,
          fromId: user.id,
          reactions: JSON.stringify(reactions)
        }
      })
    );
  };

  let data;
  try {
    if (!msg.message) {
      data = [
        {
          type: "paragraph",
          children: [{ text: "" }]
        }
      ];
    } else {
      data = msg.message;

      if (!Array.isArray(data))
        data = [
          {
            type: "paragraph",
            children: [{ text: msg.message }]
          }
        ];
    }
  } catch (err) {
    data = [
      {
        type: "paragraph",
        children: [{ text: msg.message }]
      }
    ];
  }

  let hasReactions = msg.reactions.length > 0;

  return (
    <>
      <FlexWrapper
        mt={newUser && !date ? "2rem" : "0px"}
        // mb="0.5rem"
        hoverColor="l1"
        position="relative"
        mx="1rem"
      >
        {msg.message && (
          <FlexHidden
            mr="1rem"
            position="absolute"
            zIndex={99}
            top="-0.5rem"
            right={0}
            border="1px solid lightGrey"
            bg="l0"
            borderRadius="0.25rem"
            p="0.5rem"
            px="1rem"
            alignItems="center"
          >
            <Flex
              mr="1rem"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                addTask(msg.message, msg.user_id);
              }}
            >
              <Icon.Task style={{ strokeWidth: "2px" }} mr={1} />
            </Flex>

            {msg.user_id === currentUser.id ? (
              <>
                <Flex
                  mr="1rem"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setEnableEdit(!enableEdit);
                  }}
                >
                  <Icon.Pen style={{ strokeWidth: "2px" }} />
                </Flex>

                <Flex
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteMessage();
                  }}
                >
                  <Icon.Trash style={{ strokeWidth: "2px" }} />
                </Flex>
              </>
            ) : (
              <>
                {showReply && (
                  <Flex
                    mr="1rem"
                    style={{ cursor: "pointer" }}
                    onClick={loadThred}
                  >
                    <Icon.Reply style={{ strokeWidth: "2px" }} mr={1} />
                  </Flex>
                )}

                <Flex style={{ cursor: "pointer" }}>
                  <EmojiPicker onSelect={updateReactions} />
                </Flex>
              </>
            )}
          </FlexHidden>
        )}

        <Flex width={40} minWidth={40} justifyContent="center">
          {newUser ? (
            <Avatar
              style={{ cursor: "pointer" }}
              online={onlineUsersIds.includes(user.id)}
              size="large"
              src={user.avatar}
              onClick={() => {
                dispatch(
                  ACTIONS.state.setAttribute("conversations", "activeThread", {
                    loading: false,
                    parent: null,
                    messages: null
                  })
                );
                dispatch(
                  ACTIONS.state.setAttribute(
                    "conversations",
                    "activeProfile",
                    user
                  )
                );
              }}
            />
          ) : (
            <FlexHidden alignItems="flex-start" height="100%">
              <Text color="t4" fontSize={11}>
                {formatDate(msg.creation_date, locale, timezone).hour()}
              </Text>
            </FlexHidden>
          )}
        </Flex>

        <Flex flexDirection="column" ml="0.5rem" width={1}>
          {newUser && (
            <Flex alignItems="baseline">
              <Text color="t1" fontSize="1rem" fontWeight="bold">
                {user.nickname}
              </Text>

              <Text ml="0.5rem" color="t3" fontSize="0.875rem">
                {formatDate(msg.creation_date, locale, timezone).p()}
              </Text>
            </Flex>
          )}

          {false && msg.message && msg.reply_to_id && showReply && (
            <Flex
              style={{ cursor: "pointer" }}
              mt="0.25rem"
              // bg="l2"
              // borderLeft="2px solid green"
              // p="0.5rem"
              onClick={loadThred}
            >
              <Icon.MessageCircle style={{ strokeWidth: "0px" }} fill="green" />
              <Text color="blue">{t("replied to a thread")}</Text>
            </Flex>
          )}

          <Flex
            flexGrow="1"
            justifyContent="space-between"
            mt={enableEdit ? "0.5rem" : 0}
            mb="0.5rem"
          >
            {!msg.message ? (
              <Flex
                bg="l1"
                py="0.25rem"
                px="0.5rem"
                borderRadius="0.25rem"
                color="t3"
                alignItems="center"
              >
                <Icon.Slash
                  width="0.75rem"
                  minWidth="0.75rem"
                  mr="0.25rem"
                  stroke="grey"
                />
                <Text fontStyle="italic">{t("This message was deleted")}</Text>
              </Flex>
            ) : (
              <>
                <Flex>
                  <SimpleEditor
                    key={uuidv4()}
                    borderRadius={0}
                    style={{ workBreak: "break-all" }}
                    flexGrow="1"
                    lineHeight={1.25}
                    autofocus={false}
                    value={data}
                    fileRootUrl={`${ROOT_URL}/shared/`}
                    uploadEndpoint={`${API_ROOT}/file/upload`}
                    uploadId={{ module: "CONVERSATION", uuid: acc }}
                    renderMode={!enableEdit}
                    onEnter={saveMessage}
                    enabled={enableEdit}
                    removePadding={true}
                  />

                  {msg.message && msg.reply_to_id && showReply && (
                    <Flex
                      style={{ cursor: "pointer" }}
                      mt="0.25rem"
                      ml="0.25rem"
                      onClick={loadThred}
                    >
                      <Icon.MessageCircle
                        style={{ strokeWidth: "0px" }}
                        fill="green"
                      />
                    </Flex>
                  )}
                </Flex>

                {type === "D" && currentUser.id === user.id && (
                  <Flex mt="0.25rem">
                    <Icon.Ok
                      zIndex="5"
                      ml="0.5rem"
                      style={{ strokeWidth: "4px" }}
                      stroke={!msg.saving ? "green" : "#c4c4c4"}
                    />
                    <Icon.Ok
                      ml="-0.75rem"
                      mr="0.5rem"
                      style={{ strokeWidth: read ? "4px" : "4px" }}
                      stroke={read ? "green" : "#c4c4c4"}
                    />
                  </Flex>
                )}
              </>
            )}
          </Flex>

          {false && msg.message && msg.reply_to_id && showReply && (
            <Flex
              style={{ cursor: "pointer" }}
              mt="0.25rem"
              // bg="l2"
              // borderLeft="2px solid green"
              // p="0.5rem"
              onClick={loadThred}
            >
              <Icon.MessageCircle style={{ strokeWidth: "0px" }} fill="green" />
              <Text color="blue">{t("replied to a thread")}</Text>
            </Flex>
          )}

          {showReply && msg.replies && msg.replies.last_reply_date && (
            <Flex
              mt="0.25rem"
              mb="0.25rem"
              mr="0.5rem"
              hoverColor="l0"
              borderRadius="0.375rem"
              alignItems="center"
              style={{ cursor: "pointer" }}
              p="0.25rem"
              pl="0.5rem"
              onClick={() => {
                dispatch(
                  ACTIONS.state.setAttribute("conversations", "activeThread", {
                    loading: true,
                    parent: null,
                    messages: null
                  })
                );
                ws.send(
                  JSON.stringify({
                    type: "conversations",
                    action: "getThread",
                    data: {
                      parentId: msg.id
                    }
                  })
                );
              }}
            >
              {msg.replies.users_ids.map((userId) => (
                <Avatar
                  key={userId}
                  mr="0.5rem"
                  online={onlineUsersIds.includes(userId)}
                  border="1px solid white"
                  boxShadow="0px 0px 6px 0px hsla(240, 0%, 60%, 1)"
                  size="medium"
                  src={users[userId].avatar}
                />
              ))}
              <Text color="blue" fontSize="0.875rem">
                {msg.replies.count}{" "}
                {msg.replies.count === 1 ? t("reply") : t("replies")}
              </Text>

              <Text ml="0.5rem" color="t3" fontSize="0.875rem">
                {formatDate(
                  msg.replies.last_reply_date,
                  locale,
                  timezone
                ).distance()}
              </Text>

              <FlexHidden ml="2rem">
                <Text color="t3" fontSize="0.875rem">
                  {t("View thread")}
                </Text>
              </FlexHidden>
            </Flex>
          )}

          {hasReactions && <Reactions acc={acc} message={msg} />}
        </Flex>
      </FlexWrapper>

      <Date date={date} />
    </>
  );
};
