import styled from "styled-components";
import { textStyle } from "styled-system";

import { Box } from "../Box";

export const Text = styled(Box)`
  ${textStyle}
`;

Text.defaultProps = {
  fontSize: "0.9375rem"
};

Text.displayName = "Text";
