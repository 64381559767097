import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ClickToEdit, Confirm, Flex, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import Filters from "./Filters";

const Options = ({ project }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openArchive, setOpenArchive] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const archiveProject = () => {
    dispatch(
      ACTIONS.fetch.patch("task", `${API_ROOT}/project/${project.id}/archive`, [
        ACTIONS.tasks.deleteProject,
        ACTIONS.toast.addToast({
          message: t("Project archived!"),
          type: "success"
        })
      ])
    );
  };

  const deleteProject = () => {
    dispatch(
      ACTIONS.fetch.patch("task", `${API_ROOT}/project/${project.id}/delete`, [
        ACTIONS.tasks.deleteProject,
        ACTIONS.toast.addToast({
          message: t("Project deleted!"),
          type: "success"
        })
      ])
    );
  };

  return (
    <>
      <Confirm
        title={t("Delete project")}
        content={t(
          "Are you sure that you want to delete project {{projectName}}?",
          { projectName: project.name }
        )}
        open={openDelete}
        setOpen={setOpenDelete}
        onConfirm={deleteProject}
      />

      <Confirm
        title={t("Archive project")}
        content={t(
          "Are you sure that you want to archive project {{projectName}}?",
          { projectName: project.name }
        )}
        open={openArchive}
        setOpen={setOpenArchive}
        onConfirm={archiveProject}
        yesText={t("Yes, archive")}
      />

      <Flex
        alignItems="center"
        onMouseEnter={() => setShowOptions(true)}
        onMouseLeave={() => setShowOptions(false)}
        position="relative"
        style={{ cursor: "pointer" }}
      >
        <Icon.MoreVertical
          mr="1px"
          minWidth="16px"
          minHeight="16px"
          width="16px"
          height="16px"
        />

        {showOptions && (
          <Flex
            top={16}
            right={1}
            bg="l0"
            zIndex="50"
            position="absolute"
            borderRadius={4}
            border="1px solid lightGrey"
            flexDirection="column"
            boxShadow="2px 3px 8px -2px rgba(0,0,0,0.60)"
          >
            <Flex
              alignItems="center"
              px={4}
              py={2}
              hoverColor="lightBlue"
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap"
              }}
            >
              <Icon.Inbox
                mr={1}
                minWidth="16px"
                minHeight="16px"
                width="16px"
                height="16px"
              />
              <Text
                fontSize={15}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenArchive(true);
                }}
              >
                {t("Archive project")}
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              px={4}
              py={2}
              hoverColor="lightBlue"
              style={{
                cursor: "pointer",
                whiteSpace: "nowrap"
              }}
            >
              <Icon.Trash
                mr={1}
                minWidth="16px"
                minHeight="16px"
                width="16px"
                height="16px"
              />
              <Text
                fontSize={15}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDelete(true);
                }}
              >
                {t("Delete project")}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export const TasksHeader = ({ setView, view, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state["tasks"].filters);
  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const user = useSelector((state) => state["account"].user);

  const history = useHistory();

  const [name, setName] = useState("");

  let spaceId = filters.spaceId;

  let project;

  useEffect(() => {
    let name = t("All tasks");

    if (filters.spaceId && typeof spaces[filters.spaceId] !== "undefined")
      name = t("All tasks from {{name}}", {
        name: spaces[filters.spaceId].name
      });

    if (filters.projectId && projects[filters.projectId])
      name = projects[filters.projectId].name;

    if (filters.listId && lists[filters.listId])
      name = lists[filters.listId].name;

    setName(name);
  }, [filters, projects, spaces, t]);

  if (!projects) return null;

  if (Object.keys(lists).length !== 0 && filters.listId) {
    let listId = filters.listId;
    let projectId = lists[listId].project_id;
    project = projects[projectId];
  }

  if (
    Object.keys(projects).length !== 0 &&
    filters.projectId &&
    projects[filters.projectId]
  ) {
    project = projects[filters.projectId];
  }

  // let options = [
  //   t("clone project"),
  //   t("delete project"),
  //   t("archive project"),
  //   t("crate template"),
  //   t("add user")
  // ];

  let isAdmin =
    user.is_admin ||
    spaces[spaceId].members.some(
      (member) => member.id === user.id && member.type_id === 2
    );

  const saveProjectName = (name) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/project/${project.id}`,
        ACTIONS.tasks.updateProject,
        { name }
      )
    );
  };

  return (
    <Flex flexDirection="column" label="TasksHeader">
      <Flex
        width={1}
        alignItems="center"
        justifyContent="space-between"
        label="TasksTitle"
      >
        <ClickToEdit
          textStyle="subtitle"
          enabled={project}
          value={name}
          onBlur={saveProjectName}
          maxLength={191}
        />

        <Flex key={spaceId} alignItems="center">
          {false && (
            <Flex
              bg={view === "TASKS" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mr="0.25rem"
              style={{ cursor: "pointer" }}
              onClick={() => setView("TASKS")}
            >
              <Icon.List strokeWidth="2px" stroke="black" />
            </Flex>
          )}

          {false && (
            <Flex
              bg={view === "CALENDAR" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mr="0.25rem"
              style={{ cursor: "pointer" }}
              onClick={() => setView("CALENDAR")}
            >
              <Icon.Calendar strokeWidth="2px" stroke="black" />
            </Flex>
          )}

          {false && (
            <Flex
              bg={view === "DASHBOARD" ? "l0" : "transparent"}
              p="0.5rem"
              borderRadius="0.5rem"
              hoverColor="l4"
              mr="0.25rem"
              style={{ cursor: "pointer" }}
              onClick={() => setView("DASHBOARD")}
            >
              <Icon.Graph strokeWidth="2px" stroke="black" />
            </Flex>
          )}

          {false && (
            <Icon.Config
              mr="0.5rem"
              style={{
                cursor: "pointer",
                strokeWidth: "2px"
              }}
              size="small"
            />
          )}

          {isAdmin && filters.projectId && <Options project={project} />}
        </Flex>
      </Flex>

      <Filters />
    </Flex>
  );
};
