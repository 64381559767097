import { createActions, handleActions } from "redux-actions";

const modelId = "updates";

const options = { prefix: "updates", namespace: "::" };

export const actions = createActions(
  {
    ACKNOWLEDGE_HISTORY: (historyId) => ({
      module: modelId,
      historyId
    }),

    LOAD_UPDATES: (response) => ({
      module: modelId,
      response
    }),

    UPDATE_UPDATES: (message) => ({
      module: modelId,
      message
    })
  },
  options
);

export const reducer = handleActions(
  {
    ACKNOWLEDGE_HISTORY: (state, { payload: { historyId } }) => {
      if (Array.isArray(historyId)) {
        state.updates = state.updates.filter(
          (update) => !historyId.includes(update.id)
        );
      } else {
        state.updates = state.updates.filter(
          (update) => update.id !== historyId
        );
      }
      return state;
    },

    LOAD_UPDATES: (state, { payload: { response } }) => {
      state.updates = response.objects;
      return state;
    },

    UPDATE_UPDATES: (state, { payload: { message } }) => {
      state.updates[message.id] = message;
      return state;
    }
  },
  {},
  options
);
