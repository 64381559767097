import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { addDays } from "date-fns";

import{Flex}from "../Flex";
import{Icon}from "../Icon";

const Space = styled(Flex)`
  align-items: center;
  cursor: pointer;
  transition: all 250ms;
  padding: 4px 4px 4px 4px;
  font-size: 14px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.l0};
  &:hover {
    background-color: ${props => props.theme.colors.l3};
  }
`;

export const ShortCuts = ({ setSelectedDate, showTimeframe }) => {
  const { t } = useTranslation();
  const setDate = date => {
    setSelectedDate(date);
  };

  let iconStyle = {
    marginRight: "8px",
    width: "14px",
    height: "14px",
    minWidth: "14px",
    minHeight: "14px"
  };

  return (
    <Flex flexDirection="column" width={110}>
      <Space
        width={1}
        color="t2"
        py={1}
        fontWeight="semibold"
        style={{ cursor: "pointer" }}
        onClick={() => setDate(new Date())}
      >
        <Icon.Star {...iconStyle} />
        {t("Today")}
      </Space>

      <Space
        width={1}
        color="t2"
        py={1}
        fontWeight="semibold"
        style={{ cursor: "pointer" }}
        onClick={() => setDate(addDays(new Date(), 1))}
      >
        <Icon.Sun {...iconStyle} />
        {t("Tomorrow")}
      </Space>

      <Space
        width={1}
        color="t2"
        py={1}
        fontWeight="semibold"
        style={{ cursor: "pointer" }}
        onClick={() => setDate(addDays(new Date(), 7))}
      >
        <Icon.Calendar {...iconStyle} />
        {t("Next week")}
      </Space>

      {showTimeframe && (
        <>
          <Space
            width={1}
            color="t2"
            py={1}
            fontWeight="semibold"
            style={{ cursor: "pointer" }}
            onClick={() => setDate("ANYTIME")}
          >
            <Icon.Layers {...iconStyle} />
            {t("Anytime")}
          </Space>

          <Space
            width={1}
            color="t2"
            py={1}
            fontWeight="semibold"
            style={{ cursor: "pointer" }}
            mb={4}
            onClick={() => setDate("SOMEDAY")}
          >
            <Icon.Question {...iconStyle} />
            {t("Someday")}
          </Space>
        </>
      )}
    </Flex>
  );
};
