import { format } from "date-fns";

import { API_ENDPOINT } from "config";
import { Crud } from "./crud";

const editLoader = (state, response) => {
  response.deadlineKey =
    (response.deadline && format(new Date(response.deadline), "yyyyMMdd")) ||
    null;

  response.doneKey =
    (response.done_date && format(new Date(response.done_date), "yyyyMMdd")) ||
    null;

  state.obj = response;

  let subtasksIds = [];
  state.obj.subtasksIds = subtasksIds;

  if (response.parent_id && state.objs[response.parent_id]) {
    state.objs[response.parent_id].subtasksIds.push(response.id);
  }

  if (state.objs[response.id]) {
    subtasksIds = state.objs[response.id].subtasksIds;
  } else {
    // state.objs[response.id] = state.obj;
  }

  return state;
};

const listLoader = (state, response) => {
  let tags = [];

  Object.values(response).forEach((task) => {
    if (!response[task.id].subtasksIds) response[task.id].subtasksIds = [];

    task.tags.forEach((tag) => {
      if (!tags.includes(tag)) tags.push(tag);
    });

    try {
      if (task.parent_id) {
        if (!response[task.parent_id].subtasksIds)
          response[task.parent_id].subtasksIds = [];
        response[task.parent_id].subtasksIds.push(task.id);
      }
    } catch (err) {
      console.log(err, task);
    }

    task.deadlineKey =
      (task.deadline && format(new Date(task.deadline), "yyyyMMdd")) || null;

    task.doneKey =
      (task.done_date && format(new Date(task.done_date), "yyyyMMdd")) || null;

    // if (task.owner_id === user.id && task.timer_start) {
    //   state.inProgress = task.id;
    // }
  });
  state.objs = response;
  state.tags = tags;
  return state;
};

const loader = {
  edit: editLoader,
  list: listLoader
};

export const TASKS = new Crud("tasks", "obj", `${API_ENDPOINT}/task/`, loader);
