import { useSelector } from "react-redux";

import { THEMES } from "../themes";

// Apenas function e var podem ser acessados antes da inicialização. Sabia disso? :)
// O ideal é evitar esse tipo de comportamento e este aqui está sendo mantido por compatibilidade
function getShadow(color = "s0") {
  let userTheme = useSelector((state) => state["account"].preferences.theme) || "light";
  return { boxShadow: `0px 0px 8px 4px ${THEMES(userTheme).colors[color]}` };
};

function getColor(color){
  let userTheme = useSelector((state) => state["account"].preferences.theme) || "light";

  return THEMES(userTheme).colors[color];
};

export {getColor, getShadow}
