import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar, Button, ComboBox, Dropdown, Flex, Text } from "tomato";

import Header from "./Header";
import { MEMBER_DESCRIPTION } from "../constants";

const Reveal = () => {
  return null;
};

const Member = ({ memberId, typeId }) => {
  const { t } = useTranslation();
  let member = useSelector((state) => state["spaces"].users[memberId]);
  let userId = useSelector((state) => state["account"].user.id);

  const changeTypeId = (typeId) => {
    console.log(typeId);
    // dispatch(
    //   actions.fetch.post(
    //     "task",
    //     `${API_ROOT}/project/add_user`,
    //     actions.task.addProjectUser,
    //     { user_id: newUser, type_id: newUserRole, project_id: project.id }
    //   )
    // );
  };

  return (
    <Flex mb={4} alignItems="center" width={1} justifyContent="space-between">
      <Flex mr={4}>
        <Avatar
          width={36}
          height={36}
          minWidth={36}
          minHeight={36}
          src={member.avatar}
        />
        <Flex flexDirection="column" ml={2}>
          <Text
            fontWeight="bold"
            ml={1}
            color={member.is_deleted ? "t5" : "t3"}
          >
            {member.public_name} {member.is_deleted ? t("(deleted)") : ""}
          </Text>

          <Text color={member.is_deleted ? "t5" : "t3"}>
            {member.name} • {member.email}
          </Text>
        </Flex>
      </Flex>

      <Dropdown
        items={MEMBER_DESCRIPTION}
        onSelect={changeTypeId}
        selected={MEMBER_DESCRIPTION[typeId]}
        disabled={memberId === userId || member.is_deleted}
      />
    </Flex>
  );
};

const Members = ({ addMember, members, users }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const [adding, setAdding] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [newUserRole, setNewUserRole] = useState(false);

  const addUser = (userId) => {
    setNewUser(userId);
  };

  const addRole = (roleId) => {
    setNewUserRole(roleId);
  };

  const saveUser = () => {
    setAdding(false);
    setNewUser(null);
    setNewUserRole(null);
    // Esta mudança existe para permitir zerar o selected do combobox
    setSelected(typeof selected === "undefined" ? "" : null);

    addMember(newUser, newUserRole);
  };

  let disabled = {};
  if (!(newUser && newUserRole)) disabled = { disabled: "disabled" };

  return (
    <Flex flexDirection="column" width={1}>
      <Flex flexDirection="column">
        <Header
          adding={adding}
          setAdding={setAdding}
          title={t("Members")}
          addText={t("New member")}
        />

        <Reveal height={44} width="100%" opened={adding}>
          <Flex
            mb={4}
            alignItems="center"
            width={1}
            justifyContent="space-between"
          >
            <ComboBox
              width={1}
              items={users}
              onSelect={addUser}
              placeholder={t("Choose user")}
              selected={selected}
            />

            <Dropdown
              width={1}
              ml={4}
              items={MEMBER_DESCRIPTION}
              onSelect={addRole}
              placeholder={t("Choose role")}
              selected={selected}
            />

            <Button
              ml={2}
              onClick={saveUser}
              {...disabled}
              variant="primary"
              value={t("Add")}
            />
          </Flex>
        </Reveal>
      </Flex>

      <Flex mt={4} flexDirection="column" px={4} overflow="auto">
        {members.map((member) => (
          <Member
            key={member.id}
            memberId={member.id}
            typeId={member.type_id}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Members;
