import React from "react";

import { Flex } from "tomato";

export const Page404 = (props) => {
  return (
    <Flex
      width={1}
      height="100%"
      alignItems="center"
      justifyContent="center"
      bg="l1"
      style={{
        backgroundImage: "url('/images/http/404.svg')",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Flex
        hoverColor="l1"
        p={30}
        border="1px solid lightGrey"
        bg="white"
        borderRadius={8}
        style={{ cursor: "pointer" }}
        onClick={props.history.goBack}
        boxShadow="0px 0px 16px 0px rgba(0,0,0,0.75)"
      >
        Ohh noooo! This page does not exist! Shall we go back?
      </Flex>
    </Flex>
  );
};
