import { Node } from "slate";

export const plainTextSerializer = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export const plainTextDeserialize = (content) => {
  let children = content.split("\n").map((line) => {
    return { text: line };
  });

  return [
    {
      type: "paragraph",
      children
    }
  ];
};
