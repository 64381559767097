import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-python";

export const Code = ({ enabled, children, language = "python" }) => {
  useEffect(() => {
    if (!enabled) Prism.highlightAll();
  }, [enabled]);

  const className = `language-${language}`;

  let style = { fontSize: "0.875rem", position: "relative", display: "block" };
  if (enabled) style["color"] = "black";

  return (
    <pre
      className="line-numbers"
      style={{ margin: 0, padding: "0.25rem 0.5rem" }}
    >
      <code className={className} style={style}>
        {children}
      </code>
    </pre>
  );
};
