import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  FilesPreview,
  FormatDate,
  Icon,
  Image,
  Text,
  Upload,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT, API_ENDPOINT, ROOT_URL } from "config";

const RenderFile = ({ index, file, setPreviewIndex, taskId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let fileUrl = `${ROOT_URL}/shared/${file.filename}`;
  let type = file.mime_type.split("/");
  const users = useSelector((state) => state["spaces"].users);

  const downloadFile = () => {
    window.open(fileUrl, "_blank");
  };

  const deleteFile = () => {
    // dispatch(
    //   ACTIONS.events.send("File deleted", {
    //     task_id: taskId,
    //     files_ids: [file.id]
    //   })
    // );
    dispatch(
      ACTIONS.toast.addToast({
        message: t("File deleted"),
        type: "success",
        autoClose: 1000
      })
    );
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/file/delete`,
        ACTIONS.tasks.deleteFile,
        { task_id: taskId, files_ids: [file.id] }
      )
    );
  };

  return (
    <>
      <Flex
        width={1}
        alignItems="flex-start"
        mb={4}
        justifyContent="space-between"
      >
        <Flex
          width={80}
          onClick={() => setPreviewIndex(index)}
          alignItems="center"
          mr={4}
        >
          {type[0] === "image" ? (
            <Image
              borderRadius={4}
              width={1}
              maxHeight={60}
              bg="white"
              src={fileUrl}
              style={{
                cursor: "pointer"
              }}
            />
          ) : (
            <Flex
              bg="white"
              width={1}
              height={40}
              borderRadius={4}
              border="1px solid lightGrey"
            >
              <Text
                m="auto"
                fontSize="12px"
                fontWeight="bold"
                style={{
                  cursor: "pointer",
                  textTransform: "uppercase",
                  textAlign: "center",
                  verticalAlign: "middle"
                }}
              >
                {type[1]}
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex flexDirection="column" flexGrow="1">
          <Text
            width={200}
            color="t2"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }}
          >
            {file.name}
          </Text>

          <Text fontSize={14} color="t4">
            {users[file.created_by_id].name}
            <br />
            {FormatDate.Distance(file.creation_date)}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <Icon.Download
            onClick={downloadFile}
            width="16px"
            minWidth="16px"
            height="16px"
            minHeight="16px"
            style={{ cursor: "pointer" }}
          />
          <Icon.Trash
            onClick={deleteFile}
            width="16px"
            minWidth="16px"
            height="16px"
            minHeight="16px"
            style={{ cursor: "pointer" }}
            ml={2}
          />
        </Flex>
      </Flex>
    </>
  );
};

const Files = () => {
  const dispatch = useDispatch();

  const task = useSelector((state) => state["tasks"].obj);

  const [key, setKey] = useState(uuidv4());
  const [previewIndex, setPreviewIndex] = useState();

  const endAction = (response) => {
    dispatch(ACTIONS.tasks.addFiles(response));
    setKey(uuidv4());
  };

  return (
    <>
      <Upload
        key={key}
        maxFiles={5}
        maxFileSize={10}
        endpoint={`${API_ENDPOINT}/upload`}
        endAction={endAction}
        id={{ module: "TASK", task_id: task.id }}
      />

      <Flex width={1} mt="1.5rem" flexDirection="column">
        {task.files &&
          task.files.map((file, index) => (
            <RenderFile
              taskId={task.id}
              file={file}
              key={index}
              index={index}
              setPreviewIndex={setPreviewIndex}
            />
          ))}
      </Flex>

      <FilesPreview
        previewIndex={previewIndex}
        files={task.files}
        setPreviewIndex={setPreviewIndex}
        rootUrl={ROOT_URL + "/shared/"}
      />
    </>
  );
};

export default Files;
