import React from "react";

import { useSelected, useFocused } from "slate-react";

import { Span } from "../../Span";

export const Mention = ({ attributes, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <Span
      {...attributes}
      contentEditable={false}
      px="0.25rem"
      py="0.125rem"
      mx="0.125rem"
      bg="lightRed"
      borderRadius="0.25rem"
      boxShadow={`${selected && focused ? "0 0 0 3px #B4D5FF" : "none"}`}
      fontSize="0.9rem"
      display="inline-block"
      verticalAlign="baseline"
    >
      @{element.children[0].text}
    </Span>
  );
};
