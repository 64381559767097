import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSpring } from "react-spring";

import {
  Action,
  ClickToEdit,
  Emoji,
  Flex,
  FlexAction,
  FlexHidden,
  FlexWrapper,
  Icon,
  Text
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import AddTask from "./AddTask";
import AddTemplate from "./AddTemplate";
import { AnimatedInput } from "components/AnimatedInput";
import { ColorPicker } from "components";
import { TaskList as Task } from "./TaskList";
import NewTemplate from "./NewTemplate";

const IconHover = Icon;
const TASK = 1;
const EVENT = 2;

export const GroupTitle = ({ name, list, listId, tasksIds }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allGroupsOpen = useSelector((state) => state["tasks"].ui.allGroupsOpen);
  const filters = useSelector((state) => state["tasks"].filters);
  const groupClosed = useSelector((state) => state["tasks"].ui.groupClosed);
  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const tasks = useSelector((state) => state["tasks"].objs);

  const [openAddTask, setOpenAddTask] = useState(false);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [showTaskGroupOptions, setShowTaskGroupOptions] = useState(false);
  const [typeId, setTypeId] = useState();

  const history = useHistory();

  const addTask = (typeId) => {
    setTypeId(typeId);
    setOpenAddTask(!openAddTask);
  };

  const setColor = (color) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/projectlist/${listId}`,
        [ACTIONS.tasks.updateList],
        {
          color: color
        }
      )
    );
  };

  const deleteList = () => {
    dispatch(
      ACTIONS.fetch.delete(
        "tasks",
        `${API_ROOT}/projectlist/${listId}/delete`,
        ACTIONS.tasks.deleteList
      )
    );
  };

  let open;
  if (!groupClosed[listId]) {
    open = allGroupsOpen;
  } else {
    open = !groupClosed[listId];
  }

  const animatedConfig = useSpring({
    config: { mass: 1, tension: 170, friction: 26 },
    to: [
      {
        transform: open ? "rotate(0deg)" : "rotate(-90deg)"
      }
    ],
    from: {
      transform: "rotate(0deg)"
    }
  });

  let breadcrumb;
  let project;
  let projectId;
  let space;
  let filteredProjectId = filters.projectId;
  let filteredSpaceId = filters.spaceId;

  if (typeof lists === "undefined" || typeof listId === "undefined")
    return null;

  if (listId !== 0) {
    projectId = lists[listId].project_id;
    project = projects[projectId];
    space = spaces[project.space_id];

    if (filters.listId || filters.projectId) {
      breadcrumb = "";
    } else if (filters.spaceId) {
      breadcrumb = `${project.name}`;
    } else if (
      !filteredSpaceId ||
      (filteredProjectId && projectId !== filteredProjectId)
    ) {
      breadcrumb = `${space ? space.name : "missing space"} > ${
        project ? project.name : "missing project"
      }`;
    }
  }

  const closeGroup = () => {
    let groupStatus = { ...groupClosed };

    if (!groupClosed[listId]) {
      groupStatus[listId] = allGroupsOpen;
    } else {
      groupStatus[listId] = !groupClosed[listId];
    }

    dispatch(ACTIONS.tasks.updateUi("groupClosed", { ...groupStatus }));
  };

  const updateGroupName = (groupName) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/projectlist/${listId}`,
        ACTIONS.tasks.updateList,
        { name: groupName }
      )
    );
  };

  let enabled = true; //members[user.id].admin

  let style = {
    cursor: "pointer"
  };

  if (openAddTask) {
    style.visibility = "visible";
    style.opacity = 1;
  }

  let color;
  if (list) color = list.color;

  let order = tasksIds.length ? tasks[tasksIds[0]].order - 0.0001 : null;

  return (
    <FlexWrapper flexDirection="column">
      {false && breadcrumb && (
        <Text color="t4" ml="1.45rem" fontWeight="semibold" fontSize="0.625rem">
          {breadcrumb}
        </Text>
      )}

      <Flex
        borderRadius={4}
        alignItems="center"
        justifyContent="space-between"
        height="2em"
        minHeight="2em"
      >
        <Flex alignItems="center">
          <Icon.ChevronDown
            stroke="#FF4081"
            size="medium"
            mr="2px"
            onClick={closeGroup}
            style={{ ...animatedConfig, cursor: "pointer", strokeWidth: "2px" }}
          />

          <ClickToEdit
            fontWeight="bold"
            color="t1"
            fontSize="0.9375rem"
            enabled={enabled}
            value={name}
            placeholder={t("Enter list name...")}
            onBlur={updateGroupName}
          />

          {false && (
            <FlexAction
              bg="transparent"
              ml="0.25rem"
              onClick={() => history.push(`/zen/${listId}`)}
            >
              <Emoji size="xsmall" code="1f9d8" />
            </FlexAction>
          )}

          {breadcrumb && (
            <Text
              ml="0.5rem"
              color="t1"
              fontWeight="semibold"
              fontSize="0.75rem"
            >
              {breadcrumb}
            </Text>
          )}

          <FlexHidden
            alignItems="center"
            ml="0.5rem"
            style={{ cursor: "pointer" }}
          >
            <Action onClick={() => addTask(TASK)} fontSize="0.75rem">
              {t("new task")}
            </Action>

            <Action
              onClick={() => addTask(EVENT)}
              ml="0.25rem"
              fontSize="0.75rem"
            >
              {t("new event")}
            </Action>

            <Action
              onClick={() => setOpenAddTemplate(true)}
              ml="0.25rem"
              fontSize="0.75rem"
            >
              {t("new template")}
            </Action>
          </FlexHidden>
        </Flex>

        <FlexHidden
          alignItems="center"
          onMouseEnter={() => setShowTaskGroupOptions(true)}
          onMouseLeave={() => setShowTaskGroupOptions(false)}
          position="relative"
          style={{ cursor: "pointer" }}
        >
          <Icon.MoreVertical mr="1px" size="medium" />

          {showTaskGroupOptions && (
            <Flex
              top="1rem"
              right="0.25rem"
              bg="l0"
              zIndex="50"
              position="absolute"
              borderRadius={4}
              border="1px solid lightGrey"
              flexDirection="column"
              // boxShadow={getShadow("s1")}
            >
              <Flex ml={1} alignItems="center">
                <ColorPicker setColor={setColor} color={color} />
                <IconHover.Trash
                  onClick={deleteList}
                  style={{ cursor: "pointer" }}
                />
              </Flex>
            </Flex>
          )}
        </FlexHidden>
      </Flex>

      {openAddTemplate && (
        <AddTemplate
          open={openAddTemplate}
          setOpen={setOpenAddTemplate}
          list={list}
          listId={listId}
          order={order}
          focus={true}
          full={true}
          typeId={typeId}
          tasksIds={tasksIds}
        />
      )}

      {openAddTask && (
        <AddTask
          open={openAddTask}
          setOpen={setOpenAddTask}
          listId={listId}
          order={order}
          focus={true}
          full={true}
          typeId={typeId}
        />
      )}
    </FlexWrapper>
  );
};

const TaskDropArea = ({ dropId, list, tasksIds, orderedTasks }) => {
  const [openAddTask, setOpenAddTask] = useState(
    false && list.id === 20 ? true : false
  );
  const [typeId, setTypeId] = useState();

  const addTask = (typeId) => {
    setTypeId(typeId);
    setOpenAddTask(!openAddTask);
  };

  return (
    <>
      <Droppable droppableId={dropId} type="TASK">
        {(provided, snapshot) => (
          <Flex
            width={1}
            ref={provided.innerRef}
            flexDirection="column"
            minHeight={20}
            borderRadius={4}
            bg={snapshot.isDraggingOver ? "#ddf5ff" : ""}
            p={2}
          >
            {tasksIds.map((taskId, index) => (
              <Task index={index} key={taskId} taskId={taskId} list={list} />
            ))}

            {provided.placeholder}
          </Flex>
        )}
      </Droppable>

      {dropId !== "pinned" && (
        <>
          {openAddTask && (
            <AddTask
              open={openAddTask}
              setOpen={setOpenAddTask}
              listId={list.id}
              typeId={typeId}
              focus={true}
              full={true}
            />
          )}
        </>
      )}
    </>
  );
};

const GroupWrapper = ({ enableAddList = true, list, children, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projects = useSelector((state) => state["tasks"].projects);

  let style = {};
  let color;

  if (list && list.color && list.color.h) {
    // style.bg = `hsl(${list.color.h}, ${list.color.s}%, 95%)`;
    // style.borderLeft = `4px solid hsl(${list.color.h}, ${list.color.s}%, ${list.color.l}%)`;
    color = `hsl(${list.color.h}, ${list.color.s}%, 90%)`;
  } else if (!props.bg) {
    // style.borderLeft = `4px solid lightGrey`;
    color = `hsl(0, 0%, 94%)`;
    // style.bg = `hsl(240, 10%, 97%)`;
  }

  const [addingList, setAddingList] = useState(false);
  const [addingListFromTemplate, setAddingListFromTemplate] = useState(false);

  const addList = (name) => {
    setAddingList(false);
    if (!name) return;

    let order = list.order - 0.00001;
    let length = (Math.trunc(order) + "").length;
    order = order + 1 / 10 ** (16 - length);

    dispatch(
      ACTIONS.fetch.post(
        "tasks",
        `${API_ROOT}/projectlist`,
        ACTIONS.tasks.addList,
        {
          name,
          project_id: list.project_id,
          order: order
        }
      )
    );
  };

  let addListStyle = { style: { cursor: "pointer" } };
  if (addingList || !enableAddList) addListStyle.style.visibility = "hidden";

  let projectName;
  if (enableAddList) {
    projectName = projects[list.project_id].name;
  }

  return (
    <>
      {addingListFromTemplate && (
        <NewTemplate
          list={list}
          open={addingListFromTemplate}
          setOpen={setAddingListFromTemplate}
        />
      )}
      <FlexWrapper flexDirection="column" mt="0.125rem">
        <Flex
          flexDirection="column"
          p="0.5rem"
          {...props}
          {...style}
          bg={props.bg || color}
          borderRadius="0.5rem"
        >
          {children}
        </Flex>

        <FlexHidden alignItems="center" my="0.25em" {...addListStyle}>
          <Action fontSize="0.75rem" onClick={() => setAddingList(!addingList)}>
            {t("new list")}
          </Action>

          <Action
            ml="0.25rem"
            fontSize="0.75rem"
            onClick={() => setAddingListFromTemplate(!addingListFromTemplate)}
          >
            {t("new list from template")}
          </Action>
        </FlexHidden>

        <AnimatedInput
          mb="1rem"
          name={"New list"}
          value=""
          placeholder={t(
            "Add new list to {{projectName}}. Enter to save. Esc to exit.",
            { projectName: projectName }
          )}
          onEnter={addList}
          onEsc={() => setAddingList(false)}
          opened={addingList}
          focus={true}
          changeOpened={setAddingList}
          width={1}
          rightIcon="ReturnLeft"
          maxLength={40}
          showMaxLength={true}
        />
      </FlexWrapper>
    </>
  );
};

export const Pinned = ({ tasksIds }) => {
  if (tasksIds.length === 0) return null;

  return (
    <GroupWrapper bg="lightOrange" enableAddList={false}>
      <TaskDropArea dropId={"pinned"} tasksIds={tasksIds} />
    </GroupWrapper>
  );
};

export const GroupedWrapper = ({ listId, tasksIds }) => {
  const list = useSelector((state) => state["tasks"].lists[listId]);
  const allGroupsOpen = useSelector((state) => state["tasks"].ui.allGroupsOpen);
  const groupClosed = useSelector(
    (state) => state["tasks"].ui.groupClosed[list.id]
  );
  const hideEmptyLists = useSelector(
    (state) => state["tasks"].ui.hideEmptyLists
  );

  if (hideEmptyLists && tasksIds.length === 0) return null;

  if (typeof list === "undefined") return null;

  let open;
  if (!groupClosed) {
    open = allGroupsOpen;
  } else {
    open = !groupClosed;
  }

  return (
    <GroupWrapper list={list}>
      <GroupTitle
        list={list}
        listId={list.id}
        name={list.name}
        dragId={`list.${list.id}`}
        tasksIds={tasksIds}
      />

      {open && (
        <TaskDropArea
          dropId={`list.${list.id}`}
          list={list}
          tasksIds={tasksIds}
        />
      )}
    </GroupWrapper>
  );
};

export const Grouped = ({ groups, lists }) => {
  return (
    <Flex flexDirection="column">
      {lists.map((list, index) => (
        <GroupedWrapper
          key={index}
          listId={list.id} // passo list.id para não ter que reordenar a lista em caso de update
          tasksIds={list.id in groups ? groups[list.id] : []}
        />
      ))}
    </Flex>
  );
};
