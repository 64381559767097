import React, { useEffect, useState } from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Button,
  ClickToEdit,
  Flex,
  formatDate,
  Reactions,
  SimpleEditor,
  Spinner,
  strip,
  Switch,
  Text,
  useLocalStorage,
  uuidv4
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { Breadcrumb } from "./Breadcrumb";
import { Header } from "components";
import { Replies } from "./Replies";

import { API_ROOT, ROOT_URL } from "config";
import { EMPTY_CONTENT } from "constant";

const FirstMessage = (spaceId) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const topic = useSelector((state) => state["topic"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);
  const [uuid, setUuid] = useState(uuidv4());

  const [enableEdit, setEnableEdit] = useState(false);
  const [content, setContent] = useState();
  const [replyContent, setReplyContent] = useState(EMPTY_CONTENT);

  const updateTopic = (data) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topic/${topic.id}`,
        ACTIONS.state.loadObject,
        data,
        (state, response) => {
          state.obj = response;
          return state;
        }
      )
    );
  };

  const replyTopic = () => {
    if (!hasContent) return;
    setUuid(uuidv4());

    const summary = strip(replyContent.map((n) => Node.string(n)).join(" "));

    let data = { content: replyContent, summary };

    data.topic_id = topic.id;

    dispatch(
      ACTIONS.fetch.post(
        "topic",
        `${API_ENDPOINT}/topicreply`,
        ACTIONS.state.loadObject,
        data,
        (state, response) => {
          state.obj.replies[response.id] = response;
          return state;
        }
      )
    );

    if (!topic.followers.includes(user.id))
      dispatch(
        ACTIONS.state.changeState("topic", (state) => {
          state.obj.followers.push(user.id);
          return state;
        })
      );
    setReplyContent();
  };

  const hasContent =
    JSON.stringify(replyContent) !== JSON.stringify(EMPTY_CONTENT);

  // const category = categories.find(
  //   (category) => category.id === topic.category_id
  // );

  const breadcrumb = [
    {
      title: spaces[topic.space_id].name,
      route: `/topic/${topic.space_id}`
    },
    {
      title: topic.category__name,
      route: `/topic/${topic.space_id}/${topic.category_id}`
    }
  ];

  const saveReactions = (reactions) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topic/${topic.id}`,
        ACTIONS.state.loadObject,
        {
          reactions
        },
        (state, response) => {
          let index = state.objs.findIndex((obj) => obj.id === response.id);
          state.objs[index] = response;
          if (state.obj && state.obj.id === response.id) state.obj = response;
          return state;
        }
      )
    );
  };

  return (
    <>
      <Header>
        <Breadcrumb breadcrumb={breadcrumb} textStyle="subsubtitle" />
      </Header>

      <ClickToEdit
        minHeight={28}
        fontWeight="semibold"
        enabled={user.id === topic.created_by_id}
        fontSize="title"
        value={topic.title}
        onBlur={(title) => updateTopic({ title })}
        placeholder={t("Enter subject")}
      />
      <Flex alignItems="center" mt="0.5rem">
        <Avatar
          size="medium"
          src={users[topic.created_by_id].avatar}
          online={onlineUsersIds.includes(topic.created_by_id)}
          showOnline={true}
        />

        <Text ml="0.5rem" fontSize="0.875rem" fontWeight="semibold" color="t3">
          {users[topic.created_by_id].name}
        </Text>
        <Text ml="0.5rem" color="t4" fontWeight="semibold" fontSize="0.75rem">
          {formatDate(
            topic.creation_date,
            user.locale,
            user.timezone
          ).distance()}
        </Text>
      </Flex>

      <Flex flexDirection="column" mt="1.75rem" mb="1.75rem">
        <Flex
          onClick={() => topic.created_by_id === user.id && setEnableEdit(true)}
        >
          <SimpleEditor
            key={`t${uuid}`}
            placeholder={t("Write message...")}
            value={topic.content}
            lineHeight={1.5}
            width={1}
            enabled={enableEdit}
            autofocus={true}
            enableToolbar={true}
            fileRootUrl={`${ROOT_URL}/shared/`}
            uploadEndpoint={`${API_ROOT}/file/upload?space_id=${topic.space_id}&topic_id=${topic.id}`}
            toolbarPosition="TOP"
            renderMode={!enableEdit}
            onChange={setContent}
          />
        </Flex>

        <Reactions
          user={user}
          users={users}
          reactions={topic.reactions}
          onSelect={saveReactions}
          size={14}
          singleReactions={true}
        />

        {enableEdit && (
          <Flex mt="0.5rem" justifyContent="flex-end" mb={4}>
            <Button
              variant="secondary"
              onClick={() => {
                setContent();
                setEnableEdit(false);
                setUuid(uuidv4());
              }}
              value={t("Cancel")}
            />
            <Button
              variant="primary"
              onClick={() => {
                updateTopic({ content });
                setEnableEdit(false);
              }}
              value={t("Save")}
            />
          </Flex>
        )}
      </Flex>

      <>
        <SimpleEditor
          key={uuid}
          autoFocus={true}
          value={replyContent}
          placeholder={t("Make a comment")}
          enabled={true}
          enableToolbar={true}
          toolbarPosition="TOP"
          fileRootUrl={`${ROOT_URL}/shared/`}
          uploadEndpoint={`${API_ROOT}/file/upload?space_id=${topic.space_id}&topic_id=${topic.id}`}
          onChange={setReplyContent}
          clearOnEnter={true}
          onEnter={replyTopic}
        />
        {false && (
          <Button
            variant="primary"
            onClick={replyTopic}
            style={{
              background: hasContent ? "blue" : "#d3d3d3",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              // marginLeft: "auto",
              width: "fit-content"
            }}
            value={t("Send comment")}
          />
        )}
      </>
    </>
  );
};

export const Topic = ({ refresh, topicId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const categories = useSelector((state) => state["topic"].categories);
  const topic = useSelector((state) => state["topic"].obj);
  const loadingObjId = useSelector((state) => state["topic"].loadingObjId);
  const user = useSelector((state) => state["account"].user);

  const [lastRead, setLastRead] = useLocalStorage("topicLastRead", {});

  useEffect(() => {
    if (topicId || refresh)
      dispatch(
        ACTIONS.fetch.get(
          "topic",
          `${API_ENDPOINT}/topic/${topicId}`,
          ACTIONS.state.loadObject,
          (state, response) => {
            state.obj = response;
            return state;
          }
        )
      );
  }, [dispatch, loadingObjId, refresh]);

  useEffect(() => {
    if (!topic) return;
    document.title = topic.title;
    return () => (document.title = "Elefante");
  }, [t, topic]);

  useEffect(() => {
    return () => {
      dispatch(ACTIONS.state.setAttribute("topic", "loadingObjId", null));
      dispatch(ACTIONS.state.setAttribute("topic", "obj", null));
    };
  }, [dispatch]);

  // const deleteContent = () => {
  // dispatch(
  //   ACTIONS.fetch.delete("note", `${API_ROOT}/note/${note.id}/delete`, [
  //     ACTIONS.note.deleteNote
  //   ])
  // );
  // };

  const updateTopic = (data) => {
    dispatch(
      ACTIONS.fetch.patch(
        "topic",
        `${API_ENDPOINT}/topic/${topic.id}`,
        ACTIONS.state.loadObject,
        data,
        (state, response) => {
          state.obj = response;
          // if (state.objs) state.objs[response.id] = response;
          let index = state.objs.findIndex((obj) => obj.id === response.id);
          state.objs[index] = response;
          if (state.obj && state.obj.id === response.id) state.obj = response;

          return state;
        }
      )
    );
  };

  // if (!loadingObjId) return null;
  if (!topic) return <Spinner />;

  // const following = topic.followers.includes(user.id);
  const following = null;

  return (
    <>
      {false && (
        <Header key={topic.id}>
          <Flex alignItems="center" justifyContent="space-between">
            <ClickToEdit
              minHeight={28}
              fontWeight="semibold"
              enabled={user.id === topic.created_by_id}
              fontSize="title"
              value={topic.title}
              onBlur={(title) => updateTopic({ title })}
              placeholder={t("Enter subject")}
            />
          </Flex>
          <Switch
            label={following ? t("Following") : t("Follow")}
            reverseLabel={true}
            checked={following}
            onCheck={() => {
              if (!following) {
                updateTopic({ followers: [...topic.followers, user.id] });
              } else {
                const newFollowers = topic.followers.filter(
                  (id) => id !== user.id
                );
                updateTopic({ followers: newFollowers });
              }
            }}
          />
        </Header>
      )}

      <Flex
        key={topic.id}
        width={1}
        maxWidth="50rem"
        flexDirection="column"
        position="relative"
        mx="auto"
      >
        <FirstMessage key={`T${topic.id}`} spaceId={topic.space_id} />

        <Replies key={topic.id} lastRead={lastRead} spaceId={topic.space_id} />

        <Flex minHeight={100} />
      </Flex>
    </>
  );
};
