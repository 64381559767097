import React from "react";
import Transition from "react-transition-group/Transition";

import { Flex } from "tomato";

const ANIMATION_DURATION = 240;

export const _Transition = ({
  open,
  children,
  orientation = "HORIZONTAL",
  width = 400,
  height,
  duration,
  ...props
}) => {
  const defaultStyle = {
    transition: `all ${ANIMATION_DURATION}ms ease-in-out`,
    opacity: 0
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  if (orientation === "HORIZONTAL") {
    defaultStyle.justifyContent = "center";
    defaultStyle.width = width;
    transitionStyles.entering.width = 0;
    transitionStyles.entered.width = width;
    transitionStyles.exiting.width = 0;
    transitionStyles.exited.width = 0;
  }

  if (orientation === "VERTICAL") {
    defaultStyle.alignItems = "flex-start";
    defaultStyle.height = height;
    transitionStyles.entering.height = 0;
    transitionStyles.entered.height = height;
    transitionStyles.exiting.height = 0;
    transitionStyles.exited.height = 0;
  }

  return (
    <Transition
      timeout={duration || ANIMATION_DURATION}
      in={open}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {(state) => (
        <Flex
          style={{ ...defaultStyle, ...transitionStyles[state], ...props }}
          state={state}
        >
          {children}
        </Flex>
      )}
    </Transition>
  );
};

export { _Transition as Transition };
