import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Badge,
  Button,
  Confirm,
  DatePicker,
  DateTimePicker,
  daysDifference,
  Flex,
  formatDate,
  FormatDate,
  getShadow,
  isToday,
  Icon,
  Text,
  ToolTip,
  UI
} from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

import {
  deleteTask,
  doneTask,
  loadTask,
  startTimer,
  pauseTimer,
  undoneTask
} from "modules";
import { DueColor } from "./util";
import { Fireworks } from "components/Fireworks/Fireworks";
import TransferTask from "./TransferTask";
import { APPROVAL_STATUS, TASK_TYPE } from "./constants";

const timeSpent = (totalSeconds) => {
  let hours = parseInt(totalSeconds / 3600);
  totalSeconds = totalSeconds % 3600;

  let minutes = parseInt(totalSeconds / 60);
  totalSeconds = totalSeconds % 60;

  if (!hours) return `${minutes}m`;

  hours = ("0" + hours).slice(-2);
  minutes = ("0" + minutes).slice(-2);

  return `${hours}h:${minutes}m`;
};

export const TaskWrapper = styled(Flex)`
  cursor: pointer;
  transition: all 250ms ease-in-out;
  border-radius: 4px;

  ${(props) =>
    !props.active &&
    ` &:hover {
        background: ${props.theme.colors.l2};
	  }    
    `};

  &:hover {
    background-color: #dbefff;
    transition: background-color 350ms;
  }
`;

const Hidden = styled(Flex)`
  visibility: hidden;
  opacity: 0;
  ${TaskWrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const RenderEventDate = ({ task }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state["account"].user);

  const [instance, setInstance] = useState(null);

  const onSelect = (startDate, startTime, endDate, endTime) => {
    instance.unmount();

    let saveDate = {};
    saveDate.start_date = startDate;
    saveDate.deadline = endDate;

    dispatch(ACTIONS.tasks.updateTasks(task.id, saveDate));

    dispatch(
      ACTIONS.fetch.patch(
        "task",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { ...saveDate }
      )
    );
  };

  return (
    <Tippy
      placement="bottom-start"
      trigger="manual"
      interactive={true}
      offset={[0, 4]}
      onCreate={setInstance}
      render={(attrs) => (
        <>
          {instance ? (
            <Flex
              bg="l0"
              p={4}
              borderRadius={8}
              boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
            >
              <DateTimePicker
                onSelect={onSelect}
                onCancel={() => instance.hide()}
                range={true}
              />
            </Flex>
          ) : (
            ""
          )}
        </>
      )}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation();
          instance.show();
        }}
      >
        {task.start_date && (
          <Badge mr={1} color={DueColor(task.start_date)}>
            <Flex alignItems="center">
              {isToday(task.start_date)
                ? formatDate(task.start_date, user.locale, user.timezone).hour()
                : formatDate(
                    task.start_date,
                    user.locale,
                    user.timezone
                  ).dateHour()}
            </Flex>
          </Badge>
        )}

        {(task.rrule.freq || task.rrule.freq === 0) && (
          <Icon.Repeat
            width={12}
            minWidth={12}
            height={12}
            minHeight={12}
            mr={1}
          />
        )}
      </Flex>
    </Tippy>
  );
};

export const RenderDate = ({ task }) => {
  const dispatch = useDispatch();

  const [instance, setInstance] = useState(null);

  const setDate = (date, endDate) => {
    instance.unmount();

    if (typeof date === "undefined") return;

    if (date === "SOMEDAY") {
      dispatch(
        ACTIONS.fetch.patch(
          "task",
          `${API_ROOT}/task/${task.id}`,
          ACTIONS.tasks.updateTask,
          { start_date: null, timeframe_id: 2 }
        )
      );
    } else if (date === "ANYTIME") {
      dispatch(
        ACTIONS.fetch.patch(
          "task",
          `${API_ROOT}/task/${task.id}`,
          ACTIONS.tasks.updateTask,
          { start_date: null, timeframe_id: 1 }
        )
      );
    } else {
      let saveDate = { timeframe_id: 0 };
      // saveDate.start_date = date;
      // saveDate.deadline = endDate;
      // saveDate.start_date = date;
      saveDate.deadline = date;

      dispatch(ACTIONS.tasks.updateTasks(task.id, saveDate));

      dispatch(
        ACTIONS.fetch.patch(
          "task",
          `${API_ROOT}/task/${task.id}`,
          ACTIONS.tasks.updateTask,
          { ...saveDate }
        )
      );
    }
  };

  return (
    <Tippy
      placement="bottom-start"
      // trigger="click"
      interactive={true}
      offset={[0, 4]}
      appendTo="parent"
      render={(attrs) => (
        <>
          {instance ? (
            <Flex
              bg="l0"
              p={4}
              borderRadius={8}
              boxShadow="2px 3px 16px -2px rgba(0,0,0,0.40)"
            >
              <DatePicker
                width={280}
                startDate={task.deadline}
                saveDate={setDate}
                {...attrs}
              />
            </Flex>
          ) : (
            ""
          )}
        </>
      )}
      onCreate={setInstance}
    >
      <Flex justifyContent="center" ml={1} alignItems="center">
        {!task.deadline && !task.start_date && (
          <Hidden>
            <Icon.Calendar
              mr={1}
              minWidth="14px"
              minHeight="14px"
              width="14px"
              height="14px"
            />
          </Hidden>
        )}

        {task.type_id === 2 && task.start_date && (
          <Badge mr={1} color={DueColor(task.start_date)}>
            <Flex alignItems="center">
              <Icon.Triangle
                width={6}
                minWidth={6}
                height={6}
                minHeight={6}
                stroke="transparent"
                style={{ fill: "white", transform: "rotate(90deg)" }}
                ml={-1}
                mr={1}
              />

              {FormatDate.dMMM(task.start_date)}
            </Flex>
          </Badge>
        )}

        {task.deadline && (
          <Badge mr={1} color={DueColor(task.deadline)}>
            <Flex alignItems="center">
              {FormatDate.dMMM(task.deadline)}
              <Icon.Circle
                width={6}
                minWidth={6}
                height={6}
                minHeight={6}
                stroke="transparent"
                style={{ fill: "white" }}
                mr={-1}
                ml={1}
              />
            </Flex>
          </Badge>
        )}

        {(task.rrule.freq || task.rrule.freq === 0) && (
          <Icon.Repeat
            width={12}
            minWidth={12}
            height={12}
            minHeight={12}
            mr={1}
          />
        )}
      </Flex>
    </Tippy>
  );
};

const Ownership = ({ task, ...props }) => {
  const { t } = useTranslation();

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const users = useSelector((state) => state["spaces"].users);
  const [showMembers, setShowMembers] = useState(false);

  return (
    <Flex {...props}>
      {users[task.created_by_id] &&
        task.created_by_id &&
        task.owner_id !== task.created_by_id && (
          <Flex ml={1} mr={0}>
            <ToolTip
              content={
                users[task.created_by_id] &&
                !users[task.created_by_id].is_deleted
                  ? users[task.created_by_id].name
                  : "Inactive user"
              }
              location={UI.POSITION.TOP_RIGHT}
              deltaX={12}
            >
              <Avatar
                size="medium"
                showOnline={true}
                online={onlineUsersIds.includes(task.created_by_id)}
                src={
                  users[task.created_by_id] &&
                  !users[task.created_by_id].is_deleted &&
                  users[task.created_by_id].avatar
                }
              />
            </ToolTip>

            <Icon.ChevronRight
              size="medium"
              stroke="red"
              style={{ strokeWidth: "2px" }}
            />
          </Flex>
        )}

      <Flex
        ml={1}
        mr={0}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          setShowMembers(!showMembers);
        }}
        position="relative"
      >
        <TransferTask open={showMembers} setOpen={setShowMembers} task={task} />

        <ToolTip
          content={task.owner_id ? users[task.owner_id].name : t("Unassigned")}
          location={UI.POSITION.TOP_RIGHT}
          deltaX={12}
        >
          <Avatar
            showOnline={true}
            online={onlineUsersIds.includes(task.owner_id)}
            src={users[task.owner_id] ? users[task.owner_id].avatar : null}
          />
        </ToolTip>
      </Flex>
    </Flex>
  );
};

const TypeApproval = ({ task, list, index }) => {
  let color = "yellow";

  if (task.status_id) color = APPROVAL_STATUS[task.status_id].color;

  return (
    <>
      <Text
        color={task.status_id && task.status_id > 1 ? "white" : "t3"}
        fontSize="0.5rem"
        fontWeight="semibold"
        bg={color}
        px={2}
        py={1}
        borderRadius="0.25rem"
        textStyle="uppercase"
        style={{ textTransform: "uppercase" }}
      >
        pending approval
      </Text>

      <Text
        color="t1"
        ml={1}
        fontSize="0.9375rem"
        height="20px"
        mr={1}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {task.subject}
      </Text>

      <Flex ml="auto" mr={0}>
        <Button mr="0.5rem" variant="approval" value="Refuse" />
        <Button variant="approval" mr="0.5rem" value="Approve" />
      </Flex>

      <Ownership task={task} mr="1.25rem" />
    </>
  );
};

export const TypeEvent = ({ mainList, task, list, setOpen, index }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state["account"].user);

  const [completed, setCompleted] = useState(false);
  const [showTaskOptions, setShowTaskOptions] = useState(false);

  const completeTask = () => {
    setCompleted(true);
    dispatch(ACTIONS.events.send("Task completed", task));

    setTimeout(() => {
      doneTask(task);
    }, 150);
  };

  return (
    <>
      <Icon.Ok
        style={{ cursor: "pointer", strokeWidth: "2px" }}
        bg={task.done_date ? "green" : "white"}
        borderRadius="25%"
        border={task.done_date ? "none" : "1px solid gray"}
        size="small"
        mr="0.25rem"
        stroke="white"
        onClick={(e) => {
          e.stopPropagation();
          if (!task.done_date) {
            completeTask();
          } else {
            undoneTask(task);
          }
        }}
      />

      {completed && <Fireworks />}

      <RenderEventDate task={task} />

      <Text
        color="t1"
        ml={1}
        fontSize="0.9375rem"
        mr={1}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {task.subject}
      </Text>

      {false && task.parent_id && (
        <Badge color="blue" mr="0.25rem">
          {t("GUEST")}
        </Badge>
      )}

      {false &&
        task.tags.map((tag, index) => (
          <Text
            fontSize="0.75rem"
            color="t1"
            borderRadius={3}
            bg="l1"
            px={1}
            mr={1}
            key={index}
            border="1px solid lightGrey"
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {tag}
          </Text>
        ))}

      {task.has_description && (
        <Icon.FileText mr="2px" width="12px" height="12px" />
      )}

      {task.total_notes !== 0 && (
        <>
          <Icon.MessageSquare
            width="12px"
            minWidth="12px"
            height="12px"
            minHeight="12px"
            mr="2px"
          />
          <Text mr={1} fontSize="0.6875rem">
            {task.total_notes}
          </Text>
        </>
      )}

      {task.total_files !== 0 && (
        <>
          <Icon.File mr="2px" width="12px" height="12px" />
          <Text mr={1} fontSize="0.6875rem">
            {task.total_files}
          </Text>
        </>
      )}

      <Flex alignItems="center" mr={0} ml="auto">
        {task.timer_start && (
          <Flex
            alignItems="center"
            borderRadius="50%"
            bg="green"
            width="13px"
            height="13px"
            mr={1}
          >
            <Icon.Activity
              m="auto"
              width="10px"
              height="10px"
              minWidth="10px"
              minHeight="10px"
              style={{ strokeWidth: "3px", stroke: "white" }}
            />
          </Flex>
        )}

        {false && task.time_spent > 60 && (
          <Badge color="blue">
            <Flex alignItems="center">
              <Icon.Clock
                size="small"
                mr="0.25rem"
                stroke="white"
                style={{ strokeWidth: "2px" }}
              />
              <Text fontSize="0.5625rem">{timeSpent(task.time_spent)}</Text>
            </Flex>
          </Badge>
        )}

        <Ownership task={task} />

        <Hidden
          alignItems="center"
          onMouseEnter={() => setShowTaskOptions(true)}
          onMouseLeave={() => setShowTaskOptions(false)}
          position="relative"
          style={{ cursor: "pointer" }}
        >
          <Icon.MoreVertical mr="1px" size="medium" />

          {showTaskOptions && (
            <Flex
              top="1rem"
              right="0.25rem"
              bg="l0"
              zIndex="50"
              position="absolute"
              borderRadius={4}
              border="1px solid lightGrey"
              flexDirection="column"
            >
              {mainList && (
                <Flex
                  alignItems="center"
                  px="1rem"
                  py="0.5rem"
                  hoverColor="lightBlue"
                  style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <Icon.Trash mr="0.5rem" size="small" />
                  <Text fontSize="0.9375rem">{t("Delete task")}</Text>
                </Flex>
              )}

              {(task.owner_id === user.id || !task.owner_id) && (
                <>
                  {task.timer_start ? (
                    <Flex
                      alignItems="center"
                      px={4}
                      py={2}
                      hoverColor="lightBlue"
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        pauseTimer(task);
                      }}
                    >
                      <Icon.Pause mr="0.5rem" size="small" />
                      <Text fontSize="0.9375rem">{t("Pause task")}</Text>
                    </Flex>
                  ) : (
                    <Flex
                      alignItems="center"
                      px={4}
                      py={2}
                      hoverColor="lightBlue"
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        startTimer(task);
                      }}
                    >
                      <Icon.Play mr="0.5rem" size="small" />
                      <Text fontSize="0.9375rem">{t("Start task")}</Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          )}
        </Hidden>
      </Flex>
    </>
  );
};

export const TypeTask = ({ mainList, task, list, setOpen, index }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onlineUsersIds = useSelector((state) => state["ws"].onlineUsersIds);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const [showTaskOptions, setShowTaskOptions] = useState(false);
  const [completed, setCompleted] = useState(false);

  const completeTask = () => {
    setCompleted(true);
    dispatch(ACTIONS.events.send("Task completed", task));

    setTimeout(() => {
      doneTask(task);
    }, 150);
  };

  // const startTimer = (task) => {
  //   dispatch(ACTIONS.events.send("Task started", task));

  //   let status = {
  //     emojiCode: "1f680",
  //     message: task.subject,
  //     clearId: 1
  //   };
  //   ws.send(
  //     JSON.stringify({
  //       type: "spaces",
  //       action: "setStatus",
  //       data: {
  //         status
  //       }
  //     })
  //   );
  //   dispatch(ACTIONS.account.setPreference("status", status));

  //   dispatch(
  //     ACTIONS.fetch.patch(
  //       "tasks",
  //       `${API_ROOT}/task/${task.id}/start_timer`,
  //       ACTIONS.tasks.updateTimer
  //     )
  //   );
  // };

  // const pauseTimer = (e) => {
  //   e.stopPropagation();
  //   dispatch(ACTIONS.events.send("Task paused", task));

  //   let status = {
  //     emojiCode: null,
  //     message: null,
  //     clearId: null
  //   };
  //   ws.send(
  //     JSON.stringify({
  //       type: "spaces",
  //       action: "setStatus",
  //       data: {
  //         status
  //       }
  //     })
  //   );
  //   dispatch(ACTIONS.account.setPreference("status", status));

  //   dispatch(
  //     ACTIONS.fetch.patch(
  //       "tasks",
  //       `${API_ROOT}/task/${task.id}/pause_timer`,
  //       ACTIONS.tasks.updateTimer
  //     )
  //   );
  // };

  let flagStyle = {};
  const colors = {
    1: { stroke: "#00ce03", fill: "#00ce03" },
    2: { stroke: "#ffa100", fill: "#ffa100" },
    3: { stroke: "#ff0000", fill: "#ff0000" }
  };
  flagStyle = colors[task.priority_id];

  return (
    <>
      <Icon.Ok
        style={{ cursor: "pointer", strokeWidth: "2px" }}
        bg={task.done_date ? "green" : "white"}
        borderRadius="25%"
        border={task.done_date ? "none" : "1px solid gray"}
        size="small"
        // ml={task.parent_id && mainList ? "1rem" : "0px"}
        mr={1}
        stroke="white"
        onClick={(e) => {
          e.stopPropagation();
          if (!task.done_date) {
            completeTask();
          } else {
            undoneTask(task);
          }
        }}
      />

      {completed && <Fireworks />}

      {daysDifference(task.creation_date, task.update_date) === 0 &&
        !task.done_date && (
          <Badge color="red">
            <Text fontSize="0.5625rem">{t("new")}</Text>
          </Badge>
        )}

      {task.done_date && (
        <Badge mr={1} color="green">
          <Flex alignItems="center">{FormatDate.dMMM(task.done_date)}</Flex>
        </Badge>
      )}

      {task.priority_id && (
        <Icon.Flag
          style={{ strokeWidth: "2px" }}
          width={14}
          minWidth={14}
          height={14}
          minHeight={14}
          {...flagStyle}
        />
      )}

      <Text
        color="t1"
        ml={1}
        fontSize="0.9375rem"
        mr={1}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {task.subject}
      </Text>

      {task.parent_id && mainList && (
        <Badge color="orange" mr="0.25rem">
          ST
        </Badge>
      )}

      {task.subtasksIds && task.subtasksIds.length !== 0 && mainList && (
        <Badge color="blue" mr="0.25rem">
          PT
        </Badge>
      )}

      {false &&
        task.tags.map((tag, index) => (
          <Text
            fontSize="0.75rem"
            color="t1"
            borderRadius={3}
            bg="l1"
            px={1}
            mr={1}
            key={index}
            border="1px solid lightGrey"
            style={{
              whiteSpace: "nowrap"
            }}
          >
            {tag}
          </Text>
        ))}

      {task.has_description && (
        <Icon.FileText mr="2px" width="12px" height="12px" />
      )}

      {task.total_notes !== 0 && (
        <>
          <Icon.MessageSquare
            width="12px"
            minWidth="12px"
            height="12px"
            minHeight="12px"
            mr="2px"
          />
          <Text mr={1} fontSize="0.6875rem">
            {task.total_notes}
          </Text>
        </>
      )}

      {task.total_files !== 0 && (
        <>
          <Icon.File mr="2px" width="12px" height="12px" />
          <Text mr={1} fontSize="0.6875rem">
            {task.total_files}
          </Text>
        </>
      )}

      <Flex alignItems="center" mr={0} ml="auto">
        {task.timer_start && (
          <Flex
            alignItems="center"
            borderRadius="50%"
            bg="green"
            width="13px"
            height="13px"
            mr={1}
          >
            <Icon.Activity
              m="auto"
              width="10px"
              height="10px"
              minWidth="10px"
              minHeight="10px"
              style={{ strokeWidth: "3px", stroke: "white" }}
            />
          </Flex>
        )}

        {false && task.time_spent > 60 && (
          <Badge color="blue">
            <Flex alignItems="center">
              <Icon.Clock
                size="small"
                mr="0.25rem"
                stroke="white"
                style={{ strokeWidth: "2px" }}
              />
              <Text fontSize="0.5625rem">{timeSpent(task.time_spent)}</Text>
            </Flex>
          </Badge>
        )}

        <RenderDate task={task} />

        {false &&
          task.owner_id &&
          task.created_by_id &&
          task.owner_id !== task.created_by_id && (
            <>
              <ToolTip
                content={users[task.created_by_id].name}
                location={UI.POSITION.RIGHT}
                deltaY={-8}
              >
                <Avatar
                  online={onlineUsersIds.includes(task.created_by_id)}
                  size="medium"
                  src={users[task.created_by_id].avatar}
                />
              </ToolTip>

              <Icon.ChevronRight
                width={12}
                minWidth={12}
                height={12}
                minHeight={12}
              />
            </>
          )}

        {!task.created_by_id && (
          <Flex ml={1} mr={0}>
            <ToolTip
              content={t("Automation")}
              location={UI.POSITION.TOP_RIGHT}
              deltaX={12}
            >
              <Icon.Automation size="medium" />
            </ToolTip>

            <Icon.ChevronRight
              size="medium"
              stroke="red"
              style={{ strokeWidth: "2px" }}
            />
          </Flex>
        )}

        <Ownership task={task} />

        <Hidden
          alignItems="center"
          onMouseEnter={() => setShowTaskOptions(true)}
          onMouseLeave={() => setShowTaskOptions(false)}
          position="relative"
          style={{ cursor: "pointer" }}
        >
          <Icon.MoreVertical mr="1px" size="medium" />

          {showTaskOptions && (
            <Flex
              top="1rem"
              right="0.25rem"
              bg="l0"
              zIndex="50"
              position="absolute"
              borderRadius={4}
              border="1px solid lightGrey"
              flexDirection="column"
            >
              {mainList && (
                <Flex
                  alignItems="center"
                  px="1rem"
                  py="0.5rem"
                  hoverColor="lightBlue"
                  style={{
                    cursor: "pointer",
                    whiteSpace: "nowrap"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <Icon.Trash mr="0.5rem" size="small" />
                  <Text fontSize="0.9375rem">{t("Delete task")}</Text>
                </Flex>
              )}

              {(task.owner_id === user.id || !task.owner_id) && (
                <>
                  {task.timer_start ? (
                    <Flex
                      alignItems="center"
                      px={4}
                      py={2}
                      hoverColor="lightBlue"
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        pauseTimer(task);
                      }}
                    >
                      <Icon.Pause mr="0.5rem" size="small" />
                      <Text fontSize="0.9375rem">{t("Pause task")}</Text>
                    </Flex>
                  ) : (
                    <Flex
                      alignItems="center"
                      px={4}
                      py={2}
                      hoverColor="lightBlue"
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        startTimer(task);
                      }}
                    >
                      <Icon.Play mr="0.5rem" size="small" />
                      <Text fontSize="0.9375rem">{t("Start task")}</Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          )}
        </Hidden>
      </Flex>
    </>
  );
};

export const TaskList = ({ list, taskId, index, mainList = true }) => {
  const { t } = useTranslation();

  const projects = useSelector((state) => state["tasks"].projects);
  const task = useSelector((state) => state["tasks"].objs[taskId]);
  const users = useSelector((state) => state["spaces"].users);

  const [open, setOpen] = useState(false);

  if (!task || Object.keys(projects).length === 0) return null;

  let taskBackground = "l1";
  if (task.pinned) taskBackground = "#fff6ce";
  if (task.priority && task.priority_id === 3) taskBackground = "#ffd6d6";
  if (task.timer_start) taskBackground = "#ffdbfc";

  // let enabled = task.created_by_id === user.id || task.owner_id === user.id; //||  member.admin)

  if (Object.keys(users) === 0) return null;
  if (Object.keys(projects) === 0) return null;

  let style = {};
  if (list && list.color.h) {
    style.bg = `hsl(${list.color.h}, ${list.color.s}%, 98%)`;
  } else {
    style.bg = `hsl(240, 20%, 100%)`;
  }

  return (
    <>
      <Confirm
        title={t("Delete task")}
        content={t(
          "Are you sure that you want to delete task {{taskSubject}}?",
          { taskSubject: task.subject }
        )}
        open={open}
        setOpen={setOpen}
        onConfirm={() => deleteTask(task)}
        yesText={t("Yes, delete")}
        noText={t("No, take me back")}
      />

      <Draggable draggableId={`task.${task.id}`} index={index}>
        {(provided, snapshot) => (
          <Flex
            width={1}
            ref={provided.innerRef}
            borderRadius="0.25rem"
            key={task.id}
            alignItems="center"
            mb="0.375rem"
            opacity={snapshot.isDragging ? 0.8 : 1}
            bg={snapshot.isDragging ? "l3" : taskBackground}
            onClick={() => loadTask(taskId)}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TaskWrapper
              width={1}
              alignItems="center"
              pl={2}
              {...style}
              height={28}
              minWidth="0px"
              position="relative"
            >
              {task.type_id === TASK_TYPE.TASK && (
                <TypeTask mainList={mainList} setOpen={setOpen} task={task} />
              )}

              {task.type_id === TASK_TYPE.EVENT && (
                <TypeEvent mainList={mainList} setOpen={setOpen} task={task} />
              )}

              {task.type_id === TASK_TYPE.APPROVAL && (
                <TypeApproval setOpen={setOpen} task={task} />
              )}
            </TaskWrapper>
          </Flex>
        )}
      </Draggable>
    </>
  );
};

// {task.deletion_date ? (
//  <DateChip date={task.deletion_date} color="red" mr={2} />
// ) : (
//  <>
//    {task.done_date && !task.deletion_date ? (
//      <DateChip date={task.done_date} color="green" mr={2} />
//    ) : (
//      <Icon.Ok
//        style={{ cursor: "pointer", strokeWidth: "2px" }}
//        bg={task.done_date ? "green" : "white"}
//        borderRadius="25%"
//        border={task.done_date ? "none" : "1px solid gray"}
//        width={12}
//        minWidth={12}
//        height={12}
//        mr={2}
//        stroke="white"
//        onClick={e => {
//          e.stopPropagation();
//          if (enableActions) doneAction();
//        }}
//      />
//    )}
//  </>
// )}
//
// let selectedTaskId;

// const doneTask = () => {
//   let repeat = {};
//   let startDate;

//   if (task.rrule.freq) {
//     let rule = new RRule({ ...task.rrule, count: 2 });
//     startDate = rule.all()[1];
//     repeat.order = task.order;

//     if (task.start_date || !task.deadline) {
//       repeat.nextStartDate = startDate;

//       if (task.deadline) {
//         let days = differenceInDays(
//           new Date(task.deadline),
//           new Date(task.start_date)
//         );
//         repeat.nextDueDate = addDays(new Date(startDate), days);
//       }
//     } else {
//       repeat.nextDueDate = startDate;
//     }
//   }

//   dispatch(
//     ACTIONS.fetch.patch(
//       "tasks",
//       `${API_ROOT}/task/${task.id}/done`,
//       ACTIONS.tasks.done,
//       { ...repeat }
//     )
//   );
// };

// const updateSubject = subject => {
//   dispatch(
//     fetchActions.patch(
//       "tasks",
//       `${API_ROOT}/task/${task.id}`,
//       taskActions.updateTask,
//       { subject: subject }
//     )
//   );
// };

// onClick={deleteTask}
// {task.priority_id === 3 && (
//   <Icon.Flag
//     style={{ strokeWidth: "2px" }}
//     width="16px"
//     minWidth="16px"
//     height="16px"
//     minHeight="16px"
//     mr={1}
//     p="1px"
//     stroke="#ff0000"
//     fill="#ff0000"
//   />
// )}

// <Hidden alignItems="center" width={80} minWidth={80}>
//   <Icon.MoreVertical
//     ml={2}
//     minWidth="16px"
//     minHeight="16px"
//     width="16px"
//     height="16px"
//     onClick={deleteTask}
//   />
//   <Icon.Trash
//     ml={2}
//     minWidth="16px"
//     minHeight="16px"
//     width="16px"
//     height="16px"
//     onClick={deleteTask}
//   />
//   {(task.owner_id === user.id || !task.owner_id) && (
//     <>
//       {task.timer_start ? (
//         <Icon.Pause
//           ml={2}
//           minWidth="16px"
//           minHeight="16px"
//           width="16px"
//           height="16px"
//           onClick={pauseTimer}
//         />
//       ) : (
//         <Icon.Play
//           ml={2}
//           minWidth="16px"
//           minHeight="16px"
//           width="16px"
//           height="16px"
//           onClick={startTimer}
//         />
//       )}
//     </>
//   )}
// </Hidden>
