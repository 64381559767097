import React, { Fragment, useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";
import styled from "styled-components";
import Tippy from "@tippyjs/react/headless";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ACTIONS } from "../../store/reducers/actions";
import { Box } from "../Box";
import { Icon } from "../Icon";
import { Input } from "../Input";
import { Flex } from "../Flex";
import { Spinner } from "../Spinner";
import { Text } from "../Text";
import { uuidv4 } from "../../util/uuidv4";

const InputWrapper = styled(Input)`
  ${(props) =>
    props.disableTyping && props.value
      ? `color: transparent;  text-shadow: 0 0 0 ${props.theme.colors.t2};`
      : ""}
  ${(props) => (props.disabled ? "cursor: not-allowed;" : "cursor: poiter;")}
`;

let searchOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["value"]
};

const Label = ({ disabled, required, label }) => {
  const { t } = useTranslation();

  if (!label) return null;

  return (
    <Flex mb="0.25rem" ml="0.25rem" alignItems="center">
      <Text textStyle="label">{t(label)}</Text>

      {!disabled && !required && (
        <Text textStyle="label" color="t4" ml={1}>
          {t("(optional)")}
        </Text>
      )}
    </Flex>
  );
};

const InputBox = ({ disabled, width, children }) => {
  <Flex alignItems="center" width={width} position="relative">
    {disabled && (
      <Flex>
        <Icon.Lock
          ml="6px"
          zIndex="1"
          width={14}
          minWidth={14}
          height={14}
          minHeight={14}
        />
      </Flex>
    )}

    {children}

    {!disabled && (
      <Flex position="absolute" right="74px" top="8px" zIndex="10">
        <Chevron
          height="1.25em"
          width="1.25em"
          minHeight="1.25em"
          minWidth="1.25em"
          style={{
            cursor: disabled ? "not-allowed" : "pointer",
            strokeWidth: 2
          }}
          onClick={(e) => {
            // e.stopPropagation();
            // setIsOpen(!isOpen);
            setFocus(e);
          }}
          stroke="red"
        />

        <Close />
      </Flex>
    )}
  </Flex>;
};

const Options = ({ disabled, loading, maxHeight, options, style }) => {
  const { t } = useTranslation();

  if (disabled) return null;

  // console.log(loading, options);
    return null
  // console.log(options.length, style);

  return (
    <Flex
      bg="l0"
      flexDirection="column"
      border="1px solid lightGrey"
      borderRadius={4}
      boxShadow="2px 3px 4px -2px rgba(0,0,0,0.50)"
      maxHeight={maxHeight}
      overflow="auto"
      width={1}
    >
      {options.map((option, index) => (
        <Fragment key={index}>
          <Text
            color="t1"
            p={2}
            key={index}
            // hoverColor={option.id !== inputValue.id ? "lightBlue" : ""}
            // {...getStyle(option.id === inputValue.id, selectedIndex === index)}
            // style={{
            //   cursor: option.id === inputValue.id ? "" : "pointer",
            //   overflow: "hidden",
            //   textOverflow: "ellipsis",
            //   whiteSpace: "nowrap"
            // }}
            // onMouseDown={() =>
            //   option.id !== inputValue.id ? selectItem(option) : {}
            // }
          >
            {option.value}
          </Text>
        </Fragment>
      ))}

      {loading && (
        <Flex height={32} justifyContent="center" alignItems="center">
          <Spinner width={18} height={18} />
        </Flex>
      )}

      {!loading && options.length === 0 && (
        <Text p={2} color="t2" fontSize="0.875rem" width={1}>
          {t("No options")}
        </Text>
      )}
    </Flex>
  );
};

export const ComboBox = ({
  disabled = false,
  // disableBlur,
  disableTyping,
  enableClear = false,
  enableCreation = false,
  endpoint,
  field,
  label,
  maxHeight = 200,
  open = false,
  options,
  onSelect,
  placeholder,
  required,
  selected,
  width = 1,
  // value,
  // position,
  ...props
}) => {
  // const dispatch = useDispatch();

  // const { t } = useTranslation();

  // const idRef = useRef(uuidv4());
  // const results = useSelector((state) => state["combobox"][idRef.current]);

  // const [selectedOption, setSelectedOption] = useState(selected);

  // console.log(selected, options);

  const [selectedIndex, setSelectedIndex] = useState();

  const [filteredOptions, setFilteredOptions] = useState(options);

  const [inputValue, setInputValue] = useState(
    (selected && selected.value) || ""
  );
  const [instance, setInstance] = useState(null);
  const [isOpen, setIsOpen] = useState(open);
  const [loading, setLoading] = useState(false);

  // const inputRef = useRef();
  // const ref = useClickOutside(() => setIsOpen(false));

  // useEffect(() => {
  //   dispatch(ACTIONS.combobox.setOptions(idRef.current, items));
  //   return () => dispatch(ACTIONS.combobox.cleanOptions(idRef.current));
  // }, []);

  // useEffect(() => {
  //   if (!loading) return;

  //   dispatch(
  //     ACTIONS.fetch.get("combobox", endpoint, ACTIONS.combobox.loadResults, {
  //       field: "name",
  //       uuid: idRef.current
  //     })
  //   );
  // }, [loading]);

  let fuse = new Fuse(options, searchOptions);

  // useEffect(() => {
  //   if (typeof results === "undefined") return;
  //   setOptions(results);
  //   setLoading(false);
  // }, [results]);

  // Feito para permitir que os valores sejam zerados a partir da origem
  // useEffect(() => {
  // setInputValue(selected || "");
  // }, [selected]);

  // useEffect(() => {
  // setFilteredOptions(options);
  // }, [options]);

  const filterOptions = (value) => {
    if (value) {
      let filtered = fuse.search(value);
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  };

  const onChange = (value) => {
    // console.log(e);
    // e.stopPropagation();
    // if (disableTyping) return;
    // let value = e.target.value;
    // setInputValue(value);
    // setIsOpen(true);
    // filterOptions(value);
  };

  const selectItem = (value) => {
    setInputValue(value);

    if (endpoint) {
      let result = { id: value.id };
      result[field] = value.value;
      onSelect(result);
    } else onSelect(value);

    setSelectedIndex();
  };

  const setFocus = (e) => {
    // console.log("Focus");
    e.stopPropagation();
    setIsOpen(true);

    if (endpoint) {
      setLoading(true);
      // ?operator=icontains&search=
    }
  };

  const onBlur = () => {
    // setIsOpen(false);
  };

  const clearValue = () => {
    onSelect(null);
    // setIsOpen(false);
    setInputValue({ id: null, value: "" });
    setSelectedIndex();
  };

  let keyCode;
  const handleKeyPress = (e) => {
    e.stopPropagation();

    keyCode = e.keyCode || e.charCode;

    if (keyCode === 27) {
      // Escape
      // inputRef.current.blur();
      // setInputValue("");
      // setIsOpen(false);
    }

    if (keyCode === 13) {
      // Enter
      if (typeof selectedIndex !== "undefined") {
        selectItem(options[selectedIndex]);
      } else {
        let exist =
          typeof Object.keys(items).find((key) => items[key] === inputValue) !==
          "undefined";

        if (inputValue !== selected) {
          if (!exist && enableCreation) {
            onSelect(inputValue);
          } else {
            clearValue();
          }
        } else {
          // setIsOpen(false);
        }
      }
      if (!inputValue) setIsOpen(!isOpen);
    }

    if (keyCode === 9) {
      // Tab
      if (selectedIndex || selectedIndex === 0) {
        e.preventDefault();
        selectItem(options[selectedIndex]);
      }
    }

    if (keyCode === 38) {
      // Up
      let index = selectedIndex;
      if (!index && index !== 0) setSelectedIndex(0);
      index -= 1;
      if (index < 0) return;
      setSelectedIndex(index);
    }

    if (keyCode === 40) {
      // Down
      setIsOpen(true);
      let index = selectedIndex;
      if (!index && index !== 0) {
        setSelectedIndex(0);
        return;
      }
      index += 1;
      if (index >= options.length) return;
      setSelectedIndex(index);
    }
  };

  // let enableClose = inputValue.id && !disableBlur;

  // const Close = () => {
  //   if (!enableClose) return null;

  //   return (
  //     <Flex alignItems="center" ml="-44px">
  //       <Icon.Close
  //         style={{ cursor: "pointer", strokeWidth: 2 }}
  //         stroke="lightGrey"
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           clearValue();
  //         }}
  //         minWidth={16}
  //         minHeight={16}
  //       />
  //       <Flex ml="4px" height={16} border="1px solid lightGrey" />
  //     </Flex>
  //   );
  // };

  // let Chevron = !disabled && isOpen ? Icon.ChevronUp : Icon.ChevronDown;

  // let marginLeft = disabled ? "-20px" : 0;

  // const getStyle = (active, index) => {
  //   let style = {};
  //   if (active) {
  //     style.bg = "l3";
  //     return style;
  //   }
  //   if (index) style.bg = "lightBlue";
  //   return style;
  // };

  // let style = {};
  // if (position === "TOP") {
  //   style.bottom = 40;
  // } else style.top = label ? 64 : 40;
  // console.log(isOpen);

  // let style = {
  //   ml: "-1.5em",
  //   height: "1.25em",
  //   width: "1.25em",
  //   minHeight: "1.25em",
  //   minWidth: "1.25em",
  //   style: {
  //     cursor: disabled ? "not-allowed" : "pointer",
  //     strokeWidth: 2
  //   },
  //   stroke: "red"
  // };
  // console.log("inputvalue >", inputValue);

  // if (instance) instance.show();

  const [show, setShow] = useState(false);

  useEffect(() => {
    // console.log(instance);
    // console.log(instance && instance.state);
    // console.log(instance && instance.state.isMounted);
  }, [show]);

  // console.log(show);
  return (
    <Flex flexDirection="column" {...props} width={width}>
      <Tippy
        placement="bottom-start"
        trigger="click"
        interactive={true}
        offset={[0, -12]}
        onShow={() => setShow(true)}
        onHidden={() => setShow(false)}
        render={(attrs) => (
          <Flex width={1}>
            {instance ? (
              <Options
                maxHeight={maxHeight}
                disabled={disabled}
                loading={loading}
                {...attrs}
                // setSelectedOption={setSelectedOption}
                // selectedOption={selectedOption}
                onChange={onChange}
                options={filteredOptions}
                hide={instance.hide}
                show={instance.show}
              />
            ) : (
              ""
            )}
          </Flex>
        )}
        onCreate={setInstance}
      >
        <Flex flexDirection="column" width={width}>
          <Label disabled={disabled} required={required} label={label} />

          <InputWrapper
            // ref={inputRef}
            autoFocus={true}
            value={inputValue}
            placeholder={placeholder || ""}
            onChange={(e) => onChange(e)}
            onClick={(e) => setFocus(e)}
            onKeyDown={handleKeyPress}
            onBlur={onBlur}
            onFocus={setFocus}
            variant="default"
            width={width}
            disabled={disabled}
            disableTyping={disableTyping}
            // pr={enableClose ? 50 : 20}
          />
        </Flex>
      </Tippy>
    </Flex>
  );
};

// inputValue === "" ? (
//                 ) : null}
