import { accountActions } from "./account";
import { comboboxActions } from "./combobox";
import { actions as crud } from "./crud";
import { fetchActions } from "./fetch";
import { actions as graphql } from "./graphql";
import { listActions } from "./list";
import { modalActions } from "./modal";
import { networkActions } from "./network";
// import { redirectActions } from "store/reducers/redirect";
// import { shortcutActions } from "store/reducers/shortcut";
import { stateActions } from "./state";
import { toastActions } from "./toast";
import { wsActions } from "./ws";

// import MODULES_ACTIONS from "store/actions";

export const ACTIONS = {
  account: accountActions,
  combobox: comboboxActions,
  crud,
  fetch: fetchActions,
  graphql,
  list: listActions,
  modal: modalActions,
  network: networkActions,
  // redirect: redirectActions,
  // shortcut: shortcutActions,
  state: stateActions,
  toast: toastActions,
  ws: wsActions

  // ...MODULES_ACTIONS
};

// export default ACTIONS;
