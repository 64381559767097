import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ClickToEdit,
  Confirm,
  Flex,
  FlexAction,
  Icon,
  Text,
  Tags,
  ToolTip,
  UI
} from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT, API_ROOT } from "config";

import doneTask from "../doneTask";

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allTags = useSelector((state) => state["tasks"].tags);
  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);
  const users = useSelector((state) => state["spaces"].users);

  const history = useHistory();

  const [openConfirm, setOpenConfirm] = useState(false);

  const undoneTask = () => {
    dispatch(ACTIONS.events.send("Task undone", task));
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/undone`,
        ACTIONS.tasks.undoneTask
      )
    );
  };

  const askForUpdate = () => {
    dispatch(ACTIONS.events.send("Task asked for update", task));
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}/update_requested`,
        ACTIONS.toast.addToast({
          message: t("Request sent!"),
          type: "success"
        }),
        { task_id: task.id }
      )
    );
  };

  const deleteTask = () => {
    // dispatch(
    //   ACTIONS.fetch.delete(
    //     "tasks",
    //     `${API_ENDPOINT}/task/${task.id}`,
    //     ACTIONS.tasks.deleteTask
    //   )
    // );
    // dispatch(ACTIONS.state.setAttribute("tasks", "task", null));
    // history.push(`/tasks`);
    // dispatch(ACTIONS.events.send("Task deleted", task));
    // dispatch(
    //   ACTIONS.fetch.patch(
    //     "tasks",
    //     `${API_ROOT}/task/${task.id}/delete`,
    //     ACTIONS.tasks.deleteTask
    //   )
    // );
  };

  const updateSubject = (subject) => {
    dispatch(ACTIONS.events.send("Task subject updated", task));
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { subject: subject }
      )
    );
  };

  const copyToClipboard = () => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "dummy_id");

    let url = window.location.protocol + "://" + window.location.hostname;
    let port = window.location.port;
    if (port) url += ":" + port;

    dummy.setAttribute("value", url + `/tasks#${task.id}`);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  if (Object.keys(users).length === 0) return null;

  let flagStyle = {};
  const colors = {
    1: { stroke: "#00ce03", fill: "#00ce03" },
    2: { stroke: "#ffa100", fill: "#ffa100" },
    3: { stroke: "#ff0000", fill: "#ff0000" }
  };
  flagStyle = colors[task.priority_id];

  const setRedFlag = () => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { priority_id: task.priority_id === 3 ? null : 3 }
      )
    );
  };

  let pinStyle = {};
  if (task.pinned) {
    pinStyle.stroke = "orange";
    pinStyle.fill = "orange";
  }

  const pinTask = () => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { pinned: !task.pinned }
      )
    );
  };

  const setTags = (tags) => {
    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/${task.id}`,
        ACTIONS.tasks.updateTask,
        { tags }
      )
    );
  };

  return (
    <Flex flexDirection="column" width={1} pt="1.5rem" px="2rem">
      <Confirm
        title={t("Delete task")}
        content={t(
          "Are you sure that you want to delete task {{taskSubject}}?",
          { taskSubject: task.subject }
        )}
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={deleteTask}
        yesText={t("Yes, delete")}
        noText={t("No, take me back")}
      />
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <ClickToEdit
            fontSize="1.25rem"
            fontWeight="bold"
            color="t1"
            maxLength={80}
            placeholder={t("Enter task subject...")}
            enabled={
              task.owner_id === user.id || task.created_by_id === user.id
            }
            value={task.subject}
            onBlur={updateSubject}
          />
        </Flex>

        <Flex alignItems="center">
          <ToolTip content={t("Copy to clipboard")} location={UI.POSITION.LEFT}>
            <Icon.Link
              ml="auto"
              style={{ cursor: "pointer" }}
              mr={2}
              onClick={copyToClipboard}
            />
          </ToolTip>

          <Icon.Trash
            ml="auto"
            mr="0"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenConfirm(true)}
          />
        </Flex>
      </Flex>

      <Flex alignItems="center" mt="1rem">
        <Flex
          bg="lightBlue"
          alignItems="center"
          px="1rem"
          py="0.5rem"
          borderRadius="0.375rem"
          width="fit-content"
        >
          <Text>{task.type_id === 1 ? t("Task") : t("Event")}</Text>
        </Flex>

        <FlexAction
          ml="1rem"
          bg={task.done_date ? "green" : "l2"}
          onClick={task.done_date ? undoneTask : () => doneTask(task)}
        >
          <Icon.Ok
            mr="0.25rem"
            stroke={task.done_date ? "white" : "green"}
            style={{ strokeWidth: "2px" }}
          />
          <Text
            width="fit-content"
            fontSize="0.9375rem"
            color={task.done_date ? "t0" : "t3"}
          >
            {task.done_date ? t("Done") : t("Mark as done")}
          </Text>
        </FlexAction>

        {task.type_id === 1 && task.owner_id !== user.id && (
          <FlexAction ml="1rem" onClick={askForUpdate}>
            <Icon.Question
              mr="0.5rem"
              stroke="red"
              style={{ strokeWidth: "2px" }}
            />
            <Text width="fit-content" fontSize="0.9375rem" color="t3">
              {t("Ask for an update")}
            </Text>
          </FlexAction>
        )}
      </Flex>

      {false && task.type_id === 1 && (
        <>
          <Flex alignItems="center" mt="1rem">
            <Flex
              alignItems="center"
              hoverColor="l2"
              borderRadius="1rem"
              style={{ cursor: "pointer" }}
              onClick={pinTask}
              py="0.25rem"
              px="0.5rem"
            >
              <Icon.Pin mr="0.25rem" {...pinStyle} />

              <Text color="t3" mr={2} fontSize="0.9375rem">
                {task.pinned ? t("Remove pin") : t("Pin")}
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              hoverColor="l2"
              borderRadius="1rem"
              style={{ cursor: "pointer" }}
              onClick={setRedFlag}
              ml={4}
              py="0.25rem"
              px="0.5rem"
            >
              <Icon.Flag {...flagStyle} />

              <Text color="t3" ml={1} fontSize="0.9375rem">
                {t("Mark as")} {task.priority_id === 3 && <>{t("not")}&nbsp;</>}
                {t("urgent")}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
      <Tags mt="1rem" tags={task.tags} options={allTags} onChange={setTags} />
    </Flex>
  );
};

export default Header;
