import React from "react";
import { Node } from "slate";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Flex, Icon, SimpleEditor, Spinner, strip, Text, uuidv4 } from "tomato";

import { AddTask } from "modules";
import { ACTIONS } from "store";
import { API_ROOT, ROOT_URL } from "config";
import { Message } from "./Message";

export const Thread = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeConversation = useSelector(
    (state) => state["conversations"].activeConversation
  );

  const activeThread = useSelector(
    (state) => state["conversations"].activeThread
  );

  const spaces = useSelector((state) => state["spaces"].spaces);
  const users = useSelector((state) => state["spaces"].users);
  const ws = useSelector((state) => state["ws"].ws);

  if (activeThread.loading)
    return (
      <Flex height="100%" overflow="auto" width={1}>
        <Spinner />
      </Flex>
    );
  if (!activeThread.parent) return null;

  const { parent, messages } = activeThread;
  const user = users[parent.user_id];
  const replyToId = parent.id;
  const acc = parent.uuid;

  let thread = "";
  if (acc.startsWith("D")) {
  } else if (acc.startsWith("S")) {
    let [, spaceId] = acc.split(".");
    thread = spaces[spaceId].name;
  }

  const saveMessage = (message, mentions) => {
    ws.send(
      JSON.stringify({
        type: "conversations",
        action: "message",
        data: {
          acc: acc,
          userId: user.id,
          message: JSON.stringify(message),
          summary: strip(message.map((n) => Node.string(n)).join(" ")).slice(
            0,
            20
          ),
          fromName: user.name,
          mentions,
          replyToId
        }
      })
    );
  };

  return (
    <Flex height="100%" overflow="auto" width={1} flexDirection="column">
      <Flex
        borderLeft="1px solid lightGrey"
        flexDirection="column"
        width={1}
        pt="1rem"
      >
        <Flex
          width={1}
          justifyContent="space-between"
          borderBottom="1px solid lightGrey"
          px="1rem"
          pb="1rem"
        >
          <Flex flexDirection="column">
            <Text textStyle="subtitle">Thread</Text>
            <Flex alignItems="center" mt="0.25rem">
              <Icon.Lock size="small" />
              <Text ml="0.25rem">{thread}</Text>
            </Flex>
          </Flex>

          <Flex
            borderRadius="2rem"
            p="0.5rem"
            style={{ cursor: "pointer" }}
            hoverColor="l2"
            height="fit-content"
            onClick={() =>
              dispatch(
                ACTIONS.state.setAttribute("conversations", "activeThread", {
                  messages: null,
                  parent: null,
                  loading: false
                })
              )
            }
          >
            <Icon.Close />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flexDirection="column"
        overflow="auto"
        borderLeft="1px solid lightGrey"
        flexGrow="1"
      >
        <Flex mt="1rem" mb="0.5rem" flexDirection="column" overflow="auto">
          <Message acc={acc} msg={parent} newUser={true} showReply={false} />

          {parent.replies.count && (
            <Flex ml="1rem" mt="0.5rem" mb="0.25rem" alignItems="center">
              <Text color="t3">
                {parent.replies.count}{" "}
                {parent.replies.count === 1 ? t("reply") : t("replies")}
              </Text>
              <Flex
                ml="0.25rem"
                flexGrow="1"
                height="2px"
                borderBottom="1px solid lightGrey"
              />
            </Flex>
          )}

          {messages &&
            Object.values(messages).map((message) => (
              <Message
                key={message.id}
                acc={acc}
                msg={message}
                newUser={true}
                showReply={false}
              />
            ))}

          <Flex mt="1rem" mx="1.5rem">
            <SimpleEditor
              key={uuidv4()}
              autoFocus={false}
              flexGrow="1"
              placeholder={t("Reply...")}
              maxHeight="10rem"
              enabled={true}
              enableToolbar={true}
              onEnter={saveMessage}
              toolbarPosition="BOTTOM"
              fileRootUrl={`${ROOT_URL}/shared/`}
              uploadEndpoint={`${API_ROOT}/file/upload/?uuid=${activeConversation}`}
              clearOnEnter={true}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
