import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { Flex, Overlay } from "tomato";

import ListsAdmin from "./Lists";
import onDragEnd from "../onDragEnd";
import ProjectsAdmin from "./Projects";
import SpaceAdmin from "./Space";
import SpacesAdmin from "./Spaces";

const VIEWS = {
  SPACE: SpaceAdmin,
  SPACES: SpacesAdmin,
  PROJECT: ProjectsAdmin,
  LISTS: ListsAdmin
};

const Admin = () => {
  let adminView = useSelector((state) => state["tasks"].adminView);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (adminView) setOpen(true);
  }, [adminView]);

  if (!adminView.view) return null;

  let View = VIEWS[adminView.view];

  return (
    <Overlay open={open} setOpen={() => setOpen(false)}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex
          flexDirection="column"
          bg="l0"
          borderRadius={8}
          width={900}
          height={600}
          p={4}
        >
          <View id={adminView["id"]} />
        </Flex>
      </DragDropContext>
    </Overlay>
  );
};

export default Admin;
