import { useEffect, useRef, useState } from "react";
import Dexie from "dexie";
import { useSelector } from "react-redux";

export const db = new Dexie("tasks");

db.version(1).stores({
  conversations: "++id",
  lists: "++id",
  projects: "++id",
  spaces: "++id",
  tasks: "++id",
  updates: "++id",
  users: "++id",
  control: "++id"
});

const initialControlState = {
  loaded: false,
  conversations: { updateDate: null, lastId: null },
  lists: { updateDate: null, lastId: null },
  projects: { updateDate: null, lastId: null },
  spaces: { updateDate: null, lastId: null },
  tasks: { updateDate: null, lastId: null },
  updates: { updateDate: null, lastId: null },
  users: { updateDate: null, lastId: null }
};

// export const Storage = () => {
//   return null;
// };

export const Storage = () => {
  const conversations = useSelector(
    (state) => state["conversations"].conversations
  );
  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const ready = useSelector((state) => state["app"].ready);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const tasks = useSelector((state) => state["tasks"].objs);
  const updates = useSelector((state) => state["updates"].updates);
  const users = useSelector((state) => state["spaces"].users);

  const [control, setControl] = useState({ ...initialControlState });

  const loadedRef = useRef({
    conversations: false,
    lists: false,
    projects: false,
    spaces: false,
    tasks: false,
    updates: false,
    users: false
  });

  const updatingRef = useRef({
    conversations: false,
    lists: false,
    projects: false,
    spaces: false,
    tasks: false,
    updates: false,
    users: false
  });

  let updating = updatingRef.current;
  let loaded = loadedRef.current;

  useEffect(() => {
    // db.control.get(1, (control) => {
    //   if (!control) {
    //     console.log("Creating control record");
    //     db.control
    //       .add({
    //         id: 1,
    //         ...initialControlState
    //       })
    //       .then(() => setControl({ ...initialControlState, loaded: true }));
    //   } else setControl({ ...control, loaded: true });
    // });
  }, []);

  const updateTable = (table, objects) => {
    // console.log(`Updating ${table} in background`);
    // updating[table] = true;
    // setControl({ ...control });
    // let objectsToStore = Object.values(objects).map((obj) => {
    //   return { ...obj };
    // });
    // db[table]
    //   .bulkPut(objectsToStore)
    //   .then((lastKey) => {
    //     console.log(`Done updating ${table}, Last id: ${lastKey}`);
    //     updating[table] = false;
    //     control[table].lastId = lastKey;
    //     setControl({ ...control });
    //     db.control.update(1, { ...control });
    //   })
    //   .catch(Dexie.BulkError, function (e) {
    //     console.error("Error:", e);
    //     updating[table] = false;
    //     setControl({ ...control });
    //   });
  };

  const checkTable = (tableName, objects) => {
    if (!ready || !control.loaded) return;
    if (updating[tableName]) return;
    if (!control[tableName].lastId || !loaded[tableName]) {
      updateTable(tableName, objects);
      loaded[tableName] = true;
      return;
    }
    updateTable(tableName, objects);
  };

  // useEffect(() => {
  //   checkTable("conversations", conversations);
  // }, [checkTable, control.loaded, control.conversations, conversations]);

  // useEffect(() => {
  //   checkTable("lists", lists);
  // }, [checkTable, control.loaded, control.lists, lists]);

  // useEffect(() => {
  //   checkTable("projects", projects);
  // }, [checkTable, control.loaded, control.projects, projects]);

  // useEffect(() => {
  //   checkTable("spaces", spaces);
  // }, [checkTable, control.loaded, control.spaces, spaces]);

  // useEffect(() => {
  //   checkTable("tasks", tasks);
  // }, [checkTable, control.loaded, control.tasks, tasks]);

  // useEffect(() => {
  //   checkTable("updates", updates);
  // }, [checkTable, control.loaded, control.updates, updates]);

  // useEffect(() => {
  //   checkTable("users", users);
  // }, [checkTable, control.loaded, control.users, users]);

  return null;
};
