import React, { Fragment } from "react";
import { useSlate } from "slate-react";
import { useEditor } from "slate-react";

import { Flex } from "../../Flex";
import { Icon } from "../../Icon";
import { Text } from "../../Text";
import { ToolTip } from "../../ToolTip";
import { UI } from "../../../constants";

import { toggleBlock, isBlockActive } from "./Block";
import { toggleMark, isMarkActive } from "./Mark";
import { insertLink } from "../Helpers/Link";
import { Emoji } from "../Helpers/Emoji";
import { File } from "../Helpers/File";

const fileOptions = [
  // {
  //   icon: "Mic",
  //   name: "audio",
  //   format: "audio",
  //   type: "audio",
  //   render: Audio
  // },
  {
    icon: "Image",
    name: "file",
    format: "file",
    type: "file",
    render: File
  },
  {
    icon: "Smile",
    name: "emoji",
    format: "emoji",
    type: "emoji",
    render: Emoji
  }
];

const typeOptions = [
  { icon: "Bold", name: "Bold", format: "bold", type: "mark" },
  { icon: "Italic", name: "Italic", format: "italic", type: "mark" },
  { icon: "Underline", name: "Underline", format: "underline", type: "mark" },
  { icon: "Strike", name: "Strike", format: "strike", type: "mark" },
  // { icon: "Code", name: "Code", format: "code", type: "mark" },
  { name: "h1", format: "h1", type: "block" },
  { name: "h2", format: "h2", type: "block" },
  {
    icon: "List",
    name: "List",
    format: "bulleted-list",
    type: "block",
    strokeWidth: "1px",
    fill: "#4c4c4c"
  },
  // { name: "al", format: "Left align", type: "block" },
  // { name: "ar", format: "Right alingn", type: "block" },
  // { name: "ac", format: "Center align", type: "block" },
  // { name: "js", format: "Justify", type: "block" },
  {
    icon: "NumberedList",
    name: "Numbered list",
    format: "numbered-list",
    type: "block",
    strokeWidth: "1px",
    fill: "#4c4c4c"
  },
  {
    icon: "Quote",
    name: "Quote",
    format: "block-quote",
    type: "block",
    fill: "#4c4c4c"
  },
  { icon: "Code", name: "Code", format: "code-block", type: "block" },
  { icon: "Link", name: "Link", format: "link", type: "link" }
  // {
  //   icon: "Image",
  //   name: "file",
  //   format: "file",
  //   type: "file",
  //   render: File
  // }
  // {
  //   icon: "Smile",
  //   name: "emoji",
  //   format: "emoji",
  //   type: "emoji",
  //   render: Emoji
  // }
];

const Option = ({ option }) => {
  const editor = useSlate();

  const setOption = () => {
    if (option.type === "link") {
      let url = window.prompt("Enter the URL of the link:");
      if (!url) return;
      insertLink(editor, url);
    } else if (option.type === "mark") {
      toggleMark(editor, option.format);
    } else if (option.type === "block") toggleBlock(editor, option.format);
  };

  const isActive = () => {
    if (option.type === "mark") return isMarkActive(editor, option.format);
    return isBlockActive(editor, option.format);
  };

  let RenderIcon;
  if (option.icon) RenderIcon = Icon[option.icon];

  let style = { cursor: "pointer" };
  if (isActive(editor, option.format)) style = { bg: "l4" };

  return (
    <ToolTip content={option.name} location={UI.POSITION.BOTTOM}>
      <Flex
        mr="0.25rem"
        onMouseDown={(e) => {
          e.preventDefault();
          setOption();
        }}
        borderRadius="0.25rem"
        p="0.25rem"
        // mx="0.125rem"
        {...style}
        style={{ cursor: "pointer" }}
      >
        {RenderIcon ? (
          <RenderIcon
            width="1rem"
            minWidth="1rem"
            stroke="grey"
            style={{
              strokeWidth: option.strokeWidth ? option.strokeWidth : "2px"
            }}
            fill={option.fill ? option.fill : "transparent"}
          />
        ) : (
          <Text>{option.name}</Text>
        )}
      </Flex>
    </ToolTip>
  );
};

export const Toolbar = ({ fileRootUrl, uploadEndpoint, uploadId }) => {
  const editor = useEditor();

  return (
    <Flex
      alignItems="center"
      flexGrow="1"
      justifyContent="space-between"
      maxWidth="30rem"
    >
      {false && <Icon.Undo fill="#4c4c4c" />}
      {false && <Icon.Redo fill="#4c4c4c" mx="1rem" />}

      {typeOptions.map((option, index) => {
        let Render = Fragment;

        if (option.render) {
          Render = option.render;
        }

        return (
          <Render key={index}>
            <Option option={option} editor={editor} />
          </Render>
        );
      })}

      {fileOptions.map((option, index) => {
        let Render = Fragment;

        if (option.render) {
          Render = option.render;
        }

        let style = {};
        if (!uploadEndpoint && ["file", "audio"].includes(option.type))
          return null;

        if (["file", "audio"].includes(option.type))
          style = { fileRootUrl, uploadEndpoint, uploadId };

        return (
          <Render key={index} {...style} editor={editor}>
            <Option option={option} />
          </Render>
        );
      })}
    </Flex>
  );
};
