import { orderObjects, orderObjectsList } from "tomato";

import { API_ROOT } from "config";
import { ACTIONS, STORE } from "store";
import { filter } from "./filter";

const onDragEnd = (props) => {
  let source = props.source;
  let destination = props.destination;
  if (!destination) return;
  let type = props.type;

  let fromIndex = source.index;
  let toIndex = destination.index || 0;

  let count;

  if (type === "TASK") {
    const filters = STORE.getState()["tasks"].filters;
    const tasks = STORE.getState()["tasks"].objs;
    const user = STORE.getState()["account"].user;

    let [, taskId] = props.draggableId.split(".");
    let [type, toId] = props.destination.droppableId.split(".");
    let [, from_list_id] = source.droppableId.split(".");
    let [, to_list_id] = destination.droppableId.split(".");

    to_list_id = parseInt(to_list_id);
    from_list_id = parseInt(from_list_id);

    if (type === "list") {
      let order;
      let same_list = from_list_id === to_list_id;

      let firstOrder = orderObjects(tasks, "order", "asc").filter(
        (task) => task.list_id === to_list_id
      );
      const filtered = filter(filters, firstOrder, user);
      let orderedTasks = filtered.all;
      // console.log(orderedTasks);

      //       const filtered = filter(filters, tasks, user);
      //       let orderedTasks = orderObjects(filtered.all, "order", "asc").filter(
      //         (task) => task.list_id === to_list_id
      //       );

      let goingUp = fromIndex > toIndex;

      if (same_list) {
        if (toIndex === 0) {
          let destTask = orderedTasks[toIndex];
          order = destTask.order - 0.001;
        } else if (toIndex === orderedTasks.length - 1) {
          let destTask = orderedTasks[toIndex];
          order = destTask.order + 0.001;
        } else if (goingUp) {
          let firstTask = orderedTasks[toIndex - 1];
          let nextTask = orderedTasks[toIndex];
          order = firstTask.order + (nextTask.order - firstTask.order) / 2;
        } else {
          let firstTask = orderedTasks[toIndex];
          let nextTask = orderedTasks[toIndex + 1];
          order = firstTask.order + (nextTask.order - firstTask.order) / 2;
        }
      } else {
        if (toIndex === 0) {
          let destTask = orderedTasks[toIndex];
          if (destTask) order = destTask.order - 0.001;
        } else if (toIndex === orderedTasks.length) {
          let destTask = orderedTasks[toIndex - 1];
          order = destTask.order + 0.001;
        } else {
          let firstTask = orderedTasks[toIndex - 1];
          let nextTask = orderedTasks[toIndex];
          order = firstTask.order + (nextTask.order - firstTask.order) / 2;
        }
      }

      STORE.dispatch(ACTIONS.tasks.updateTaskOrder(taskId, to_list_id, order));
      STORE.dispatch(
        ACTIONS.fetch.patch(
          "tasks",
          `${API_ROOT}/task/${taskId}`,
          ACTIONS.tasks.updateTask,
          { group_id: to_list_id, order }
        )
      );
    } else if (type === "pinned") {
      STORE.dispatch(
        ACTIONS.fetch.patch(
          "tasks",
          `${API_ROOT}/task/${taskId}`,
          ACTIONS.task.updateTask,
          { pinned: true }
        )
      );
    }
  }

  if (type === "GROUP") {
    // let [, toProjectId] = props.destination.droppableId.split(".");
    let [, fromProjectId] = props.source.droppableId.split(".");

    let project = STORE.getState()["tasks"]["projects"][fromProjectId];
    let lists = STORE.getState()["tasks"]["lists"];

    let orderedLists = project.lists_ids.map((projectId) => {
      return lists[projectId];
    });

    orderedLists = orderObjectsList(orderedLists, "order", "asc");

    let fromList = orderedLists[fromIndex];
    let toList = orderedLists[toIndex];
    let order;
    let positionDelta = fromIndex < toIndex ? 0 : -1;

    count = orderedLists.length;

    if (toIndex === count - 1) {
      order = toList.order - 0.001;
    } else if (toIndex === 0) {
      order = toList.order + 0.001;
    } else {
      let firstList = orderedLists[toIndex + positionDelta];
      let lastList = orderedLists[toIndex + 1 + positionDelta];
      order = firstList.order - (firstList.order - lastList.order) / 2;
    }

    STORE.dispatch(ACTIONS.task.reorderList(fromList.id, order));
    STORE.dispatch(
      ACTIONS.fetch.patch("task", `${API_ROOT}/task/reorder_list`, [], {
        group_id: fromList.id,
        order
      })
    );
  }

  if (type === "PROJECT") {
    // let [, toSpaceId] = props.destination.droppableId.split(".");
    let [, fromSpaceId] = props.source.droppableId.split(".");

    let space = STORE.getState()["task"]["spaces"][fromSpaceId];
    let projects = STORE.getState()["task"]["projects"];

    let orderedProjects = space.projects_ids.map((projectId) => {
      return projects[projectId];
    });
    orderedProjects = orderObjectsList(orderedProjects, "order", "asc");

    let fromProject = orderedProjects[fromIndex];
    let toProject = orderedProjects[toIndex];
    let order;
    let positionDelta = fromIndex < toIndex ? 0 : -1;

    count = orderedProjects.length;
    if (toIndex === count - 1) {
      order = toProject.order - 0.001;
    } else if (toIndex === 0) {
      order = toProject.order + 0.001;
    } else {
      let firstProject = orderedProjects[toIndex + positionDelta];
      let lastProject = orderedProjects[toIndex + 1 + positionDelta];
      order = firstProject.order - (firstProject.order - lastProject.order) / 2;
    }

    STORE.dispatch(ACTIONS.task.reorderProject(fromProject.id, order));
    STORE.dispatch(
      ACTIONS.fetch.patch("task", `${API_ROOT}/task/reorder_project`, [], {
        project_id: fromProject.id,
        order
      })
    );
  }

  if (type === "SPACE") {
    let spaces = STORE.getState()["space"]["spaces"];

    let orderedSpaces = orderObjects(spaces, "order", "desc");

    let fromSpace = orderedSpaces[fromIndex];
    let toSpace = orderedSpaces[toIndex];

    let order;
    let positionDelta = fromIndex < toIndex ? 0 : -1;

    count = orderedSpaces.length;
    if (toIndex === count - 1) {
      order = toSpace.order - 0.001;
    } else if (toIndex === 0) {
      order = toSpace.order + 0.001;
    } else {
      let firstSpace = orderedSpaces[toIndex + positionDelta];
      let lastSpace = orderedSpaces[toIndex + 1 + positionDelta];
      order = firstSpace.order - (firstSpace.order - lastSpace.order) / 2;
    }

    STORE.dispatch(ACTIONS.space.reorderSpace(fromSpace.id, order));
    STORE.dispatch(
      ACTIONS.fetch.patch("task", `${API_ROOT}/space/reorder_space`, [], {
        space_id: fromSpace.id,
        order
      })
    );
  }
};

export default onDragEnd;
