import React, { cloneElement, useState } from "react";
import PropTypes from "prop-types";

import { ArrowBox, Box, Positioner } from "../";
import { UI } from "../../constants";
// import Flex from "../Flex";
// import UI from "../../constants/ui";
// import Positioner from "../Positioner";

const POSITION = UI.POSITION;

export const ToolTip = ({
  content,
  children,
  deltaX,
  deltaY,
  location,
  enabled = true,
  ...props
}) => {
  const [isOver, setIsOver] = useState(false);

  const clone = cloneElement(children, {
    // onMouseEnter: () => setIsOver(true),
    // onMouseLeave: () => setIsOver(false),
    // style: { ...children.props.style, cursor: "pointer" }
  });

  /**
   * Map com as referencias para o posicionamento da ArrowBox na construção do ToolTip.
   * Sendo a chave posicao do ToolTip, e o valor a posicao da ArrowBox
   */
  const arrowPosition = new Map([
    [POSITION.BOTTOM, POSITION.TOP],
    [POSITION.BOTTOM_LEFT, POSITION.TOP_LEFT],
    [POSITION.BOTTOM_RIGHT, POSITION.TOP_RIGHT],
    [POSITION.TOP, POSITION.BOTTOM],
    [POSITION.TOP_LEFT, POSITION.BOTTOM_LEFT],
    [POSITION.TOP_RIGHT, POSITION.BOTTOM_RIGHT],
    [POSITION.LEFT, POSITION.RIGHT],
    [POSITION.LEFT_TOP, POSITION.RIGHT_TOP],
    [POSITION.LEFT_BOTTOM, POSITION.RIGHT_BOTTOM],
    [POSITION.RIGHT, POSITION.LEFT],
    [POSITION.RIGHT_TOP, POSITION.LEFT_TOP],
    [POSITION.RIGHT_BOTTOM, POSITION.LEFT_BOTTOM]
  ]);

  const Target = () =>
    enabled && (
      <ArrowBox fontSize={13} location={arrowPosition.get(location)} {...props}>
        {content}
      </ArrowBox>
    );

  if (!content) return null;

  return (
    <Positioner
      target={Target()}
      isShown={enabled && isOver}
      location={location}
      deltaY={deltaY}
      deltaX={deltaX}
    >
      <Box
        label="ToolTip"
        width="fit-content"
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
      >
        {children}
      </Box>
    </Positioner>
  );
};

ToolTip.propTypes = {
  /**
   * O conteúdo a ser exibido na ToolTip
   */
  content: PropTypes.string.isRequired,
  /**
   * A anchor da ToolTip
   */
  children: PropTypes.node.isRequired,
  /**
   * Se a ToolTip deve ser invocada
   */
  enabled: PropTypes.bool,
  /**
   * A posição da ToolTip
   */
  location: PropTypes.oneOf(Object.keys(POSITION).map((key) => POSITION[key]))
    .isRequired
};
