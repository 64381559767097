import { createActions, handleActions } from "redux-actions";

const modelId = "reminders";

const options = { prefix: "reminders", namespace: "::" };

export const actions = createActions(
  {
    ADD: ({ uuid, title, expireDate }) => ({
      module: modelId,
      uuid,
      title,
      expireDate
    }),

    DELETE: ({ uuid }) => ({
      module: modelId,
      uuid
    }),

    LIST: ({ reminders }) => ({
      module: modelId,
      reminders
    }),

    NOTIFY: (reminder) => ({
      module: modelId,
      reminder
    }),

    UPDATE: (id, title, expireDate) => ({
      module: modelId,
      id,
      title,
      expireDate
    })
  },
  options
);

export const reducer = handleActions(
  {
    ADD: (state, { payload: { uuid, title, expireDate } }) => {
      state.reminders.push({ uuid, title, expireDate });
      return state;
    },

    DELETE: (state, { payload: { uuid } }) => {
      state.reminders = state.reminders.filter((rmd) => uuid !== rmd.uuid);

      state.notify = state.notify.filter((notify) => notify.uuid !== uuid);
      localStorage.setItem("reminders", JSON.stringify([...state.notify]));
      return state;
    },

    LIST: (state, { payload: { reminders } }) => {
      state.reminders = reminders;
      return state;
    },

    NOTIFY: (state, { payload: { reminder } }) => {
      state.notify.push(reminder);
      localStorage.setItem("reminders", JSON.stringify(state.notify));
      state.reminders = state.reminders.filter(
        (rmd) => reminder.uuid !== rmd.uuid
      );
      return state;
    },

    UPDATE: (state, { payload: { uuid, title, expireDate } }) => {
      state.reminders[uuid] = { uuid, title, expireDate };
      return state;
    }
  },
  {},
  options
);
