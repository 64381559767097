import React from "react";

import { Input } from "tomato";

import { Transition } from "components/Transition";

export const AnimatedInput = ({ opened, ...props }) => {
  return (
    <Transition
      height={48}
      alignItems="center"
      open={opened}
      orientation="VERTICAL"
    >
      <Input {...props} />
    </Transition>
  );
};
