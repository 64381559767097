import React from "react";

import Icon from "../Icon";

const Condition = props => {
  return (
    <Icon {...props}>
      <rect rx="2" ry="2" width="12" height="12" transform="rotate(-45 16 0)" />
    </Icon>
  );
};

export default Condition;
