import React, { useEffect, useRef, useState, useCallback } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import { orderObjects } from "tomato";

import { ACTIONS } from "store";
import { API_ENDPOINT } from "config";
import { db } from "storage";

const LoadFromDB = () => {
  const dispatch = useDispatch();
  const loaded = useRef(false);
  // Carrego os dados a partir do cache no Indexdb
  // só na carga do sistema
  useEffect(() => {
    if (loaded.current) return;

    // db.tasks.toArray((objs) => {
    //   let result = {};
    //   objs.forEach((obj) => {
    //     result[obj.id] = obj;
    //   });

    //   if (Object.keys(result).length === 0) return;

    //   dispatch(
    //     ACTIONS.state.loadObject(result, "tasks", (state, result) => {
    //       state.objs = result;
    //       return state;
    //     })
    //   );
    // });

    // db.lists.toArray((objs) => {
    //   let result = {};
    //   objs.forEach((obj) => {
    //     result[obj.id] = obj;
    //   });

    //   if (Object.keys(result).length === 0) return;

    //   dispatch(
    //     ACTIONS.state.loadObject(result, "tasks", (state, result) => {
    //       state.lists = result;
    //       state.orderedLists = orderObjects(result, "order", "asc");
    //       return state;
    //     })
    //   );
    // });

    // db.projects.toArray((objs) => {
    //   let result = {};
    //   objs.forEach((obj) => {
    //     result[obj.id] = obj;
    //   });

    //   if (Object.keys(result).length === 0) return;

    //   dispatch(
    //     ACTIONS.state.loadObject(result, "tasks", (state, result) => {
    //       state.projects = result;
    //       return state;
    //     })
    //   );
    // });

    // db.updates.toArray((objs) => {
    //   let result = {};
    //   objs.forEach((obj) => {
    //     result[obj.id] = obj;
    //   });

    //   if (Object.keys(result).length === 0) return;

    //   dispatch(
    //     ACTIONS.state.loadObject(result, "updates", (state, result) => {
    //       state.updates = result;
    //       return state;
    //     })
    //   );
    // });

    // db.users.toArray((objs) => {
    //   let result = {};
    //   objs.forEach((obj) => {
    //     result[obj.id] = obj;
    //   });

    //   if (Object.keys(result).length === 0) return;

    //   dispatch(
    //     ACTIONS.state.loadObject(result, "spaces", (state, result) => {
    //       state.users = result;
    //       return state;
    //     })
    //   );
    // });

    // db.spaces.toArray((spaces) => {
    //   let result = {};
    //   spaces.forEach((space) => {
    //     result[space.id] = space;
    //   });

    //   if (Object.keys(result).length === 0) return;

    //   dispatch(
    //     ACTIONS.state.loadObject(result, "spaces", (state, result) => {
    //       state.spaces = result;
    //       return state;
    //     })
    //   );
    // });
    loaded.current = true;
  }, [dispatch]);
};

export const RefreshData = () => {
  const dispatch = useDispatch();

  const conversations = useSelector(
    (state) => state["conversations"].conversations
  );
  const lists = useSelector((state) => state["tasks"].lists);
  const projects = useSelector((state) => state["tasks"].projects);
  const ready = useSelector((state) => state["app"].ready);
  const spaces = useSelector((state) => state["spaces"].spaces);
  const tasks = useSelector((state) => state["tasks"].objs);
  const user = useSelector((state) => state["account"].user);

  const shouldRefresh = useSelector((state) => state["app"].shouldRefresh);

  const [loadTasks, setLoadTasks] = useState(false);

  const appLoaded = useRef(false);

  useEffect(() => {
    dispatch(ACTIONS.events.send("Load app", {}));
  }, [dispatch]);

  useEffect(() => {
    if (shouldRefresh) setLoadTasks(false);
  }, [shouldRefresh]);

  const memoizedDispatch = useCallback(
    (props) => {
      dispatch(props);
    },
    [dispatch]
  );

  const transformTasks = useCallback(
    (state, response) => {
      let tags = [];
      const tasks = {};

      Object.values(response.objects).forEach((task) => {
        tasks[task.id] = task;
        if (!task.subtasksIds) task.subtasksIds = [];

        task.tags.forEach((tag) => {
          if (!tags.includes(tag)) tags.push(tag);
        });

        if (task.parent_id && tasks[task.parent_id]) {
          if (!tasks[task.parent_id].subtasksIds)
            tasks[task.parent_id].subtasksIds = [];
          tasks[task.parent_id].subtasksIds.push(task.id);
        }

        task.deadlineKey =
          (task.deadline && format(new Date(task.deadline), "yyyyMMdd")) ||
          null;

        task.doneKey =
          (task.done_date && format(new Date(task.done_date), "yyyyMMdd")) ||
          null;

        if (task.list_id && lists[task.list_id]) {
          task.project_id = lists[task.list_id].project_id;
        } else {
          task.project_id = null;
        }

        if (task.project_id && projects[task.project_id]) {
          task.space_id = projects[task.project_id].space_id;
        } else {
          task.space_id = null;
        }
        task.space_id = task.project_id
          ? projects[task.project_id].space_id
          : null;

        if (task.owner_id === user.id && task.timer_start) {
          state.inProgress = task.id;
        }
      });
      state.objs = tasks;
      state.tags = tags;
      return state;
    },
    [lists, projects, user.id]
  );

  useEffect(() => {
    if (lists && projects && spaces && !loadTasks) {
      setLoadTasks(true);

      memoizedDispatch(
        ACTIONS.fetch.get(
          "tasks",
          `${API_ENDPOINT}/tasks?limit=0&done_date__isnull=True&deleted_by_id__isnull=False`,
          [ACTIONS.state.loadObjects, ACTIONS.app.loadedResource("tasks")],
          transformTasks
        )
      );
    }
  }, [lists, loadTasks, memoizedDispatch, transformTasks, projects, spaces]);

  useEffect(() => {
    if (tasks && conversations && !ready) {
      memoizedDispatch(ACTIONS.state.setAttribute("app", "ready", true));
    }
  }, [ready, tasks, conversations, memoizedDispatch]);

  useEffect(() => {
    if (appLoaded.current && !shouldRefresh) return;

    const loadSpaces = (state, response) => {
      const spaces = response.objects.spaces;

      state.spaces = spaces;
      const users = response.objects.users;
      state.users = users;

      state.activeUsers = Object.values(users).filter(
        (usr) => !usr.is_deleted && usr.is_active && usr.i !== user.id
      );
      state.orderedSpaces = orderObjects(spaces, "order", "desc");

      return state;
    };

    memoizedDispatch(
      ACTIONS.fetch.get("tasks", `${API_ENDPOINT}/project/`, [
        ACTIONS.tasks.loadProjects,
        ACTIONS.app.loadedResource("projects")
      ])
    );

    memoizedDispatch(
      ACTIONS.fetch.get("account", `${API_ENDPOINT}/user/me`, [
        ACTIONS.account.loadUser,
        ACTIONS.app.loadedResource("user")
      ])
    );

    memoizedDispatch(
      ACTIONS.fetch.get(
        "spaces",
        `${API_ENDPOINT}/spaces/`,
        [ACTIONS.state.loadObject, ACTIONS.app.loadedResource("spaces")],
        loadSpaces
      )
    );

    memoizedDispatch(
      ACTIONS.fetch.get("updates", `${API_ENDPOINT}/history/`, [
        ACTIONS.updates.loadUpdates,
        ACTIONS.app.loadedResource("updates")
      ])
    );

    memoizedDispatch(
      ACTIONS.fetch.get("conversations", `${API_ENDPOINT}/conversation`, [
        ACTIONS.conversations.loadConversations,
        ACTIONS.app.loadedResource("conversations")
      ])
    );

    appLoaded.current = true;
  }, [memoizedDispatch, shouldRefresh, user]);

  return null;
};
