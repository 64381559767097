import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Flex, Input, Icon, Text } from "tomato";

import { ACTIONS } from "store";
import { API_ROOT } from "config";

//     WAITING = 1
//     APPROVED = 2
//     REFUSED = 3

export const Approval = ({ update }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const task = useSelector((state) => state["tasks"].obj);
  const user = useSelector((state) => state["account"].user);

  const [openReason, setOpenReason] = useState(false);
  const [reply, setReply] = useState();

  const hasApproval =
    ![2, 3, 4].includes(task.approval_status_id) &&
    !task.done_by_id &&
    !task.deleted_by_id &&
    task.owner_id === user.id &&
    task.created_by_id !== user.id;

  if (!hasApproval) return null;

  const acceptTask = () => {
    let history = {};
    if (update) history.history_id = update.id;

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/accept/${task.id}`,
        [
          ACTIONS.tasks.updateAttribute(task.id, "approval_status_id", 2),
          ACTIONS.toast.addToast({
            message: t("Task accepted!"),
            type: "success"
          })
        ],
        {
          ...history
        }
      )
    );
    if (update) dispatch(ACTIONS.updates.acknowledgeHistory(update.id));
  };

  const refuseTask = () => {
    let history = {};
    if (update) history.history_id = update.id;

    dispatch(
      ACTIONS.fetch.patch(
        "tasks",
        `${API_ROOT}/task/refuse/${task.id}`,
        [
          ACTIONS.tasks.updateAttribute(task.id, "approval_status_id", 3),
          ACTIONS.toast.addToast({
            message: t("Task rejected!"),
            type: "error"
          })
        ],
        {
          reason: reply,
          ...history
        }
      )
    );
    if (update) dispatch(ACTIONS.updates.acknowledgeHistory(update.id));
  };

  return (
    <Flex
      pt="1.5rem"
      px="1rem"
      justifyContent="center"
      borderBottom="1px solid lightGrey"
      pb="1.5rem"
    >
      <Flex flexDirection="column" mx="2rem" flexGrow="1">
        <Flex bg="lightRed" px="1rem" py="0.5rem" borderRadius="0.5rem">
          <Icon.Info stroke="red" style={{ strokeWidth: "2px" }} />
          <Text ml="0.5rem" color="red" fontSize="1rem" fontWeight="bold">
            {t("This task needs your approval")}
          </Text>
        </Flex>

        {!openReason && (
          <Flex mt="1rem" flexGrow="1" justifyContent="space-around">
            <Text
              fontSize="1.125rem"
              fontWeight="semibold"
              px="3rem"
              py="0.5rem"
              bg="l2"
              hoverColor="red"
              borderRadius="0.5rem"
              textAlign="center"
              onClick={() => setOpenReason(true)}
              style={{ cursor: "pointer" }}
            >
              {t("Refuse")}
            </Text>

            <Text
              fontSize="1.125rem"
              fontWeight="semibold"
              px="3rem"
              py="0.5rem"
              bg="l2"
              hoverColor="green"
              borderRadius="0.5rem"
              mr="1rem"
              textAlign="center"
              onClick={acceptTask}
              style={{ cursor: "pointer" }}
            >
              {t("Accept")}
            </Text>
          </Flex>
        )}

        {openReason && (
          <Flex
            mt="1rem"
            flexDirection="column"
            bg="l0"
            borderRadius="0.5rem"
            p="1rem"
          >
            <Text fontSize="1rem" fontWeight="semibold">
              {t("Reason")}
            </Text>

            <Flex flexGrow="1" mt="0.5rem" justifyContent="flex-end">
              <Input
                value=""
                placeholder={t("Explain why are you rejecting it")}
                onChange={setReply}
                focus={true}
                onEnter={refuseTask}
                rightIcon="ReturnLeft"
              />

              <Button
                mx="0.5rem"
                height="fit-content"
                variant="secondary"
                onClick={() => setOpenReason(false)}
                value={t("Cancel")}
              />

              <Button
                height="fit-content"
                variant="primary"
                onClick={refuseTask}
                disabled={!reply}
                value={t("Send")}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
