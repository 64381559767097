import React, { useCallback, useEffect, useRef, useState } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { useTranslation } from "react-i18next";

import { Action, Column, Flex, Text, useDebounce } from "tomato";

import { getInitialValue } from "./constants";
import { Element, Leaf } from "./Element";
import { withLinks } from "./Helpers/Link";
import { withImages } from "./Helpers/Image";
import { withEmoji } from "./Helpers/Emoji";

import { Toolbar } from "./Toolbar";
import { HoveringToolbar } from "./HoveringToolbar";

const Editor_ = ({
  autoSave,
  debounceTime,
  enableHint,
  toolBarStyle = {
    position: "absolute",
    width: 1,
    padding: "0 1rem ",
    top: 0,
    left: 0,
    bg: "userLight"
  },
  editorStyle = {
    style: {
      margin: "1rem 1rem 1rem 1rem",
      lineHeight: 1.5
    }
  },

  autoFocus = true,
  enabled = true,
  enableToolbar = true,
  enableExit = false,
  fileRootUrl,
  uploadEndpoint,
  uploadId,
  onChange,
  placeholder,
  renderMode = false,
  value,
  onSave,
  ...props
}) => {
  const { t } = useTranslation();

  const [content, setContent] = useState(getInitialValue(value));
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!saving) return;
    setTimeout(() => {
      setSaving(false);
    }, 1000);
  }, [saving]);

  const debounceValue = useDebounce(content, debounceTime);

  useEffect(() => {
    if (!(autoSave && enabled)) return;
    if (JSON.stringify(value) === JSON.stringify(content)) return;
    setSaving(true);
    autoSave(debounceValue);
  }, [debounceValue]);

  const save = () => {
    onSave && onSave(content);
  };

  const changeContent = (content) => {
    setContent(content);
    onChange && onChange(content);
  };

  const editorRef = useRef();
  if (!editorRef.current)
    editorRef.current = withEmoji(
      withImages(withLinks(withHistory(withReact(createEditor()))))
    );
  const editor = editorRef.current;

  // const editor = useMemo(() => {
  //   let editor = withEmoji(
  //     withImages(withLinks(withHistory(withReact(createEditor()))))
  //   );
  //   return editor;
  // }, []);

  const renderElement = useCallback(
    (props) => (
      <Element enabled={enabled} fileRootUrl={fileRootUrl} {...props} />
    ),
    [enabled]
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  if (renderMode) {
    editorBorder.padding = "0px";
  }

  // let line = 1;
  // let column = 1;

  // if (editor.selection) {
  //   line = editor.selection.anchor.path[0] + 1;
  //   column = editor.selection.anchor.offset + 1;
  // }

  // {false && enabled && (
  //  <Flex my="1rem">
  //    Line: {line}
  //    {"    "}Column: {column}
  //  </Flex>
  // )}

  const sameContent = JSON.stringify(value) === JSON.stringify(content);

  return (
    <Column
      width={1}
      borderRadius="0.25rem"
      border={enabled ? "1px solid lightGrey": ""}
      height="fit-content"
      //    {...props}
      p="1rem"
      pt={enabled && enableToolbar ? "3rem" : ""}
      {...editorStyle}
    >
      <Slate editor={editor} value={content} onChange={changeContent}>
        {enabled && <HoveringToolbar />}

        <Editable
          autoFocus={autoFocus}
          key={`${enabled}`}
          readOnly={enabled ? false : true}
          placeholder={placeholder || t("Enter some text…")}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          //         {...editorStyle}
        />

        {enabled && enableToolbar && (
          <Flex
            {...toolBarStyle}
            minHeight="2.5rem"
            height="fit-content"
            justifyContent="space-between"
            borderBottom="1px solid lightGrey"
            mb="1rem"
            alignItems="center"
          >
            <Toolbar
              enabled={enabled}
              uploadEndpoint={uploadEndpoint}
              uploadId={uploadId}
            />

            <Flex alignItems="center">
              {enableHint && saving && (
                <Text
                  bg="lightOrange"
                  width="5rem"
                  fontSize="0.75rem"
                  py="0.125rem"
                  px="0.25rem"
                  ml="1rem"
                  mr="0.5rem"
                  borderRadius="0.25rem"
                  fontWeight="semibold"
                  textAlign="center"
                >
                  saving...
                </Text>
              )}
              {enableHint && !saving && (
                <Text
                  bg={sameContent ? "lightGreen" : "lightRed"}
                  width="5rem"
                  fontSize="0.75rem"
                  py="0.125rem"
                  px="0.25rem"
                  ml="1rem"
                  mr="0.5rem"
                  borderRadius="0.25rem"
                  textAlign="center"
                >
                  {sameContent ? t("atualizado") : t("modificado")}
                </Text>
              )}
              {enableExit && sameContent && (
                <Action onClick={save}>{t("Exit")}</Action>
              )}

              {onSave && !sameContent && (
                <Action onClick={save}>{t("Save")}</Action>
              )}
            </Flex>
          </Flex>
        )}
      </Slate>
    </Column>
  );
};

export { Editor_ as Editor };
