import React from "react";
import twemoji from "twemoji";

import { Code } from "./Code";
import { File } from "./File";
import { Flex, Image as Emoji, Text } from "../../";
import { Mention } from "./Mention";

export const Element = ({
  enabled,
  attributes,
  children,
  element,
  fileRootUrl
}) => {
  switch (element.type) {
    case "audio":
      return (
        <audio controls controlsList="nodownload" src={element.blob}>
          Erro no áudio
        </audio>
      );

    case "code-block":
      return (
        <Code enabled={enabled} language="python">
          {children}
        </Code>
      );

    case "block-quote":
      return (
        <Flex
          {...attributes}
          bg="lightBlue"
          borderRadius="0.25rem"
          px="1rem"
          py="0.5rem"
        >
          {children}
        </Flex>
      );

    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;

    case "h1":
      return (
        <Text
          fontSize="1.25rem"
          color="t2"
          my="0.5rem"
          fontWeight="bold"
          borderBottom="2px solid lightGrey"
          {...attributes}
        >
          {children}
        </Text>
      );

    case "h2":
      return (
        <Text fontSize="1rem" my="0.25rem" fontWeight="bold" {...attributes}>
          {children}
        </Text>
      );

    case "emoji":
      let emoji = twemoji.convert.fromCodePoint(element.id);

      return <span>{`${emoji}`} </span>;

      return (
        <Emoji
          mb="-6px"
          mx="2px"
          width="22px"
          height="22px"
          src={"https://static.elefante.com/emojis/" + element.id + ".png"}
        />
      );

    case "image":
      return (
        <File
          attributes={attributes}
          element={element}
          fileRootUrl={fileRootUrl}
        >
          {children}
        </File>
      );

    case "file":
      return (
        <File
          attributes={attributes}
          element={element}
          fileRootUrl={fileRootUrl}
        >
          {children}
        </File>
      );

    case "link":
      return (
        <a
          {...attributes}
          style={{ cursor: "pointer" }}
          href={element.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      );

    case "list-item":
      return <li {...attributes}>{children}</li>;

    case "mention":
      return (
        <Mention attributes={attributes} element={element}>
          {children}
        </Mention>
      );

    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;

    default:
      return (
        <p style={{ marginBottom: "1rem" }} {...attributes}>
          {children}
        </p>
      );
  }
};
