import React from "react";

import { Image } from "../Image";

export const Emoji = ({ code, size = "medium", ...props }) => {
  const SIZES = {
    xsmall: "1rem",
    small: "1.25rem",
    medium: "1.5rem",
    large: "1.75rem",
    xlarge: "2rem"
  };

  let style = {};

  if (size in SIZES) {
    style = {
      width: SIZES[size],
      height: SIZES[size]
    };
  } else if (size) {
    style = {
      width: size,
      height: size
    };
  }

  return (
    <Image
      {...props}
      style={style}
      src={`https://static.elefante.com/emojis/${code}.png`}
    />
  );
};
