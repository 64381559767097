import { Admin } from "modules/Admin";
import {
  Calendar,
  Conversations,
  Files,
  Forms,
  Pages,
  Profile,
  Reports,
  Tasks,
  Topic,
  Topics,
  Updates,
  Zen
} from "modules";
import { Clear } from "./Clear";
import { Dev } from "./Dev";
import { Documents } from "modules/Documents";
import { Forbidden } from "./Forbidden";
import { Page404 } from "./Page404";
import { Saas as Dashboard } from "modules";
// import { Space, Spaces } from "../modules/Spaces/View";
import { Welcome } from "modules";
import { VirtualRoom } from "../modules/VirtualRoom";
// export * from "./New";

const VIEWS = {
  Admin,
  Calendar,
  Clear,
  Conversations,
  Dashboard,
  Dev,
  Documents,
  Files,
  Forms,
  Forbidden,
  Pages,
  Page404,
  Profile,
  Reports,
  // Space,
  // Spaces,
  Tasks,
  Topic,
  Topics,
  Welcome,
  Updates,
  VirtualRoom,
  Zen
};

export { VIEWS };
