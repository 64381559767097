import styled, { keyframes } from "styled-components";

import { Box } from "../Box";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

let border = "3px";
let color = "#9bcff7";

export const Spinner = styled(Box)`
  animation: ${rotate360} 0.5s linear infinite;
  transform: translateZ(0);
  border-top: ${border} solid ${color};
  border-right: ${border} solid ${color};
  border-bottom: ${border} solid ${color};
  border-left: ${border} solid #1480d6;
  background: transparent;
  border-radius: 50%;
  z-index: 50;
  pointer-events: none;
`;
