import React from "react";

import { Dropdown, Flex } from "tomato";

import { daysOptions, monthsOptions } from "./config";

const RenderYearly = ({ rrule, updateRRule }) => {
  let newRRule = { ...rrule };

  const setMonth = (option) => {
    newRRule.bymonth = [option.id];
    updateRRule(newRRule);
  };

  const setMonthDay = (option) => {
    newRRule.bymonthday = [option.id];
    updateRRule(newRRule);
  };

  return (
    <Flex>
      <Dropdown
        options={monthsOptions}
        selected={monthsOptions[rrule.bymonth[0] - 1]}
        onSelect={setMonth}
        mr={2}
      />

      <Dropdown
        options={daysOptions}
        selected={{ id: rrule.bymonthday[0], value: rrule.bymonthday[0] }}
        onSelect={setMonthDay}
      />
    </Flex>
  );
};

export default RenderYearly;
