import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Checkbox, Flex, Input, Text } from "../";

export const RenderFormItem = ({
  properties,
  setFormState,
  data,
  value = ""
}) => {
  let options =
    properties.disabled || properties.readonly
      ? {
          variant: "disabled",
          disabled: "disabled",
          border: 0,
          borderBottom: "1px solid #e2e2e2"
        }
      : { variant: "enabled" };

  const { t } = useTranslation();

  const handleChange = (value, maxLength, type) => {
    if (type === "integer" && isNaN(value)) return;
    setFormState(properties.name, value);
  };

  const handleCheckBoxChange = (e) => {
    setFormState(properties.name, e.target.checked);
  };

  const reducer = (result, condition) => {
    return result && data[condition.key] === condition.value;
  };

  if (properties.conditions) {
    let condition = properties.conditions.reduce(reducer, true);
    if (!condition) return null;
  }

  if (properties.type === "boolean") {
    return (
      <Flex ml={2} height="30px" my="25px" alignItems="center">
        <Checkbox checked={value} onChange={handleCheckBoxChange} />
        <Text ml={2}>{t(properties.label)}</Text>
      </Flex>
    );
  }

  return (
    <Input
      {...options}
      autoFoces={true}
      width={1}
      mb={4}
      maxWidth={450}
      type={properties.type}
      name={properties.label}
      value={value}
      required={properties.required}
      maxLength={properties.maxLength}
      label={properties.label}
      placeholder={properties.placeholder}
      onChange={(value) =>
        handleChange(value, properties.maxLength, properties.type)
      }
    />
  );
};

function mapProperties(data, properties, callback) {
  return Object.keys(properties).map(function (key) {
    return callback(key, properties[key], data[key]);
  });
}

const RenderSchema = ({ data, schema, setFormState }) => {
  return (
    <Box mt="1rem">
      {mapProperties(data, schema.properties, function (
        key,
        properties,
        value
      ) {
        return (
          <RenderFormItem
            key={key}
            properties={properties}
            setFormState={setFormState}
            data={data}
            value={value}
          />
        );
      })}
    </Box>
  );

  // for (let [objectKey, objectValue] of Object.entries(schema.properties)) {
  //   if (objectValue.type === "object") {
  //     console.log("Object");
  //   } else {
  //       console.log(objectKey, objectValue);

  // return form;
  // block["properties"][objectValue.name] = objectValue.default;
  // }

  // for (let i = 0; i < schema.properties.length; i++) {
  //   key = schema.properties[i];

  //   if (schema.properties[key].type === "object") {
  //     // console.log(schema.schema.properties[key].properties);
  //     for (let [objectKey, objectValue] of Object.entries(
  //       schema.schema.properties[key].properties
  //     )) {
  //       // console.log(objectKey, objectValue);
  //       block["properties"][objectValue.name] = objectValue.default;
  //     }
  //   } else {
  //     block["properties"][schema.schema.properties[key].name] =
  //       schema.schema.properties[key].default;
  //   }
  // }
};

export const Form = ({
  schema,
  handleCancel,
  handleSubmit,
  data = {},
  autoSave = false,
  saveText,
  cancelText,
  title,
  ...props
}) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState(data);

  const requiredKeys = useMemo(() => {
    return Object.values(schema.properties)
      .filter((value) => value.required)
      .map((field) => field.name);
  }, []);

  useEffect(() => {
    if (!autoSave || formState === data || !formState) return;
    handleSubmit(formState);
  }, [formState]);

  const handleChange = (key, value) => {
    formState[key] = value;
    setFormState({ ...formState });
  };

  if (!formState) return null;

  const enabled = requiredKeys.length
    ? requiredKeys.filter((key) => formState[key]).length ===
      requiredKeys.length
    : true;

  return (
    <Flex
      flexDirection="column"
      p="1.5rem"
      borderRadius="0.5rem"
      bg="l0"
      {...props}
      width={400}
      maxWidth={500}
    >
      <Text textStyle="subtitle" mb="1rem">
        {t(title || schema.title)}
      </Text>

      <RenderSchema
        data={formState}
        schema={schema}
        setFormState={handleChange}
      />

      {!autoSave && (
        <Flex mt="1.5rem" justifyContent="flex-end">
          {typeof handleCancel === "function" && (
            <Button
              variant="secondary"
              onClick={handleCancel}
              mr="14px"
              value={t("Cancel")}
            />
          )}

          <Button
            disabled={!enabled}
            variant="primary"
            onClick={() => handleSubmit(formState)}
            value={t(saveText || "Save")}
          />
        </Flex>
      )}

      {autoSave && (
        <Button
          variant="primary"
          onClick={handleCancel}
          mr="14px"
          value={t(cancelText || "Cancel")}
        />
      )}
    </Flex>
  );
};
