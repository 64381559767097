import React from "react";

import { Icon, Image } from "../";
import { SIZES } from '../../constants'
import { Spinner } from "./Spinner"; 

const _Spinner = ({ ...props }) => {
  let size = props.size ? SIZES[props.size] : SIZES.medium

  return (
      <Spinner
      m='auto'
      {...props}
      {...size}
    />
  );
};    

export {_Spinner as Spinner}
