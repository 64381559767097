import { color, space, typography } from "styled-system";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)(
  {
    display: "block",
    color: "#333333",
    textDecoration: "none",
    "&:hover": {
      color: "#2f80ed"
    }
  },
  space,
  color,
  typography
);
